import type { SyntheticEvent } from 'react';
import React, { Component } from 'react';

import classNames from 'classnames';

import { asideMenuCssClasses, validBreakpoints } from '@crac/core/helpers/classes';
import { toggleClasses } from '@crac/core/helpers/toggle-classes';

import { checkBreakpoint } from '../layout-helper';

type AsideToggleProps = {
	className?: string;
	defaultOpen?: boolean;
	display?: string;
	mobile?: boolean;
};

export class AsideToggle extends Component<AsideToggleProps> {
	static defaultProps = {
		defaultOpen: false,
		display: 'lg',
		mobile: false,
	};

	constructor(props: AsideToggleProps) {
		super(props);
		this.state = {};
	}

	componentDidMount(): void {
		const { defaultOpen } = this.props;
		this.toggle(defaultOpen);
	}

	toggle = (force?: boolean): void => {
		const { display, mobile } = this.props;

		let [cssClass] = asideMenuCssClasses;
		if (!mobile && display && checkBreakpoint(cssClass, validBreakpoints)) {
			cssClass = `aside-menu-${display}-show`;
		}

		toggleClasses(cssClass, asideMenuCssClasses, force);
	};

	handleAsideToggle = (event: SyntheticEvent<HTMLButtonElement>): void => {
		event.preventDefault();
		this.toggle();
	};

	render(): JSX.Element {
		const { className } = this.props;
		return (
			<button
				className={classNames('navbar-toggler mx-3', className)}
				onClick={this.handleAsideToggle}
				type="button"
			>
				<span className="navbar-toggler-icon" />
			</button>
		);
	}
}
