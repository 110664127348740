import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const SaleOrder = lazyWithRetry(() => import('~/features/fleet/saleOrder/pages/SaleOrder'));

const SaleOrderNew = lazyWithRetry(() => import('~/features/fleet/saleOrder/pages/SaleOrderNew'));

const SaleOrderModify = lazyWithRetry(() => import('~/features/fleet/saleOrder/pages/SaleOrderModify'));

const SaleOrderLineInsert = lazyWithRetry(() => import('~/features/fleet/saleOrder/pages/SaleOrderLineInsert'));

export const saleOrderRoutes: { [key: number]: IRouteConfig } = {};

saleOrderRoutes[routesIdMap.FleetSaleOrder] = {
	component: SaleOrder,
	exact: true,
	id: routesIdMap.FleetSaleOrder,
	name: 'Sale orders',
	menuText: 'Sale orders',
	path: '/fleet/sale-orders',
	permissions: [PermissionsType.SaleOrder],
	childrens: [],
	icon: 'shopping-bag',
};

saleOrderRoutes[routesIdMap.FleetSaleOrderNew] = {
	component: SaleOrderNew,
	exact: true,
	id: routesIdMap.FleetSaleOrderNew,
	name: 'New sale orders',
	menuText: 'New sale orders',
	path: '/fleet/sale-orders/new',
	permissions: [PermissionsType.SaleOrder],
	childrens: [],
	icon: 'plus',
};

saleOrderRoutes[routesIdMap.FleetSaleOrderModify] = {
	component: SaleOrderModify,
	exact: true,
	id: routesIdMap.FleetSaleOrderModify,
	name: 'Modify sale orders',
	path: '/fleet/sale-orders/:id',
	permissions: [PermissionsType.SaleOrder],
	childrens: [],
	icon: 'plus',
};

saleOrderRoutes[routesIdMap.FleetSaleOrderLineInsert] = {
	component: SaleOrderLineInsert,
	exact: true,
	id: routesIdMap.FleetSaleOrderLineInsert,
	name: 'Insert sale orders lines',
	menuText: 'Insert sale orders lines',
	path: '/fleet/sale-orders/:id/lines-insert',
	permissions: [PermissionsType.SaleOrder],
	childrens: [],
	icon: 'plus',
};
