import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	ticketManagementAddComment,
	ticketManagementClear,
	ticketManagementClose,
	ticketManagementGetByBooking,
	ticketManagementGetByBranch,
	ticketManagementGetByCustomer,
	ticketManagementNew,
} from '@crac/core/redux/actions/TicketManagementActions';
import type { ITicketReducerState } from '@crac/core/redux-store/reducersState/ticket';
const initialState: ITicketReducerState = {
	ticketAddCommentRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	ticketCloseRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	ticketNewRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	tickets: [],
	ticketsRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const ticketSlice = createSlice({
	name: 'TicketSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// NEW
		builder
			.addCase(ticketManagementNew.pending, (state) => {
				return {
					...state,
					ticketNewRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(ticketManagementNew.fulfilled, (state, action) => {
				return {
					...state,
					ticketNewRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					tickets: [...state.tickets, action.payload],
				};
			})
			.addCase(ticketManagementNew.rejected, (state, action) => {
				return {
					...state,
					ticketNewRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// ADD COMMENT
		builder
			.addCase(ticketManagementAddComment.pending, (state) => {
				return {
					...state,
					ticketAddCommentRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(ticketManagementAddComment.fulfilled, (state, action) => {
				return {
					...state,
					ticketAddCommentRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					tickets: state.tickets.map((ticket) => {
						if (ticket.id === action.payload.ticketId) {
							return {
								...ticket,
								comments: [...ticket.comments, action.payload],
							};
						}
						return ticket;
					}),
				};
			})
			.addCase(ticketManagementAddComment.rejected, (state, action) => {
				return {
					...state,
					ticketAddCommentRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLOSE
		builder
			.addCase(ticketManagementClose.pending, (state) => {
				return {
					...state,
					ticketCloseRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(ticketManagementClose.fulfilled, (state, action) => {
				return {
					...state,
					ticketCloseRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					tickets: [...state.tickets.filter((ticket) => ticket.id !== action.payload.id), action.payload],
				};
			})
			.addCase(ticketManagementClose.rejected, (state, action) => {
				return {
					...state,
					ticketCloseRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR
		 * CLEAR CACHE
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, ticketManagementClear), () => {
			return initialState;
		});

		/**
		 * GET BY CUSTOMER
		 * GET BY BOOKING
		 * GET BY BRANCH
		 */
		builder
			.addMatcher(
				isAnyOf(
					ticketManagementGetByCustomer.pending,
					ticketManagementGetByBooking.pending,
					ticketManagementGetByBranch.pending,
				),
				(state) => {
					return {
						...state,
						tickets: [],
						ticketsRequest: {
							inProgress: true,
							messages: [],
							ok: true,
						},
					};
				},
			)
			.addMatcher(
				isAnyOf(
					ticketManagementGetByCustomer.fulfilled,
					ticketManagementGetByBooking.fulfilled,
					ticketManagementGetByBranch.fulfilled,
				),
				(state, action) => {
					return {
						...state,
						tickets: action.payload,
						ticketsRequest: {
							inProgress: false,
							messages: [],
							ok: true,
						},
					};
				},
			)
			.addMatcher(
				isAnyOf(
					ticketManagementGetByCustomer.rejected,
					ticketManagementGetByBooking.rejected,
					ticketManagementGetByBranch.rejected,
				),
				(state, action) => {
					return {
						...state,
						ticketsRequest: {
							inProgress: false,
							messages: action.payload || [],
							ok: false,
						},
					};
				},
			);
	},
});

export const ticketsReducer = ticketSlice.reducer;
