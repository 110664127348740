import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { IService } from '@crac/core/models/entities/Service';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { employeeLoginAndLoadCachedData } from '@crac/core/redux/actions/EmployeeActions';
import { servicePriceGetAll } from '@crac/core/redux/actions/ServicePriceActions';
import { servicesGetAll } from '@crac/core/redux/actions/ServicesActions';
import type { IServicesReducerState } from '@crac/core/redux-store/reducersState/services';
export const servicesStorageKey = 'services';
const { setItem, getItem, removeItem } = managerLocalStorage();

const initialState: IServicesReducerState = {
	services: (getItem(servicesStorageKey) as IService[]) || [],
	servicesPrice: [],
	servicesPriceRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	servicesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const servicesSlice = createSlice({
	name: 'ServicesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, (state) => {
			removeItem(servicesStorageKey);
			return {
				...state,
				services: [],
			};
		});
		// GET ALL
		builder
			.addCase(servicesGetAll.pending, (state) => {
				return {
					...state,
					servicesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(servicesGetAll.fulfilled, (state, action) => {
				setItem(servicesStorageKey, action.payload);
				return {
					...state,
					services: deepCopy(action.payload),
					servicesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(servicesGetAll.rejected, (state, action) => {
				return {
					...state,
					servicesRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			})
			.addCase(employeeLoginAndLoadCachedData.fulfilled, (state, action) => {
				setItem(servicesStorageKey, action.payload.services);
				return {
					...state,
					services: deepCopy(action.payload.services),
				};
			});
		// GET PRICE
		builder
			.addCase(servicePriceGetAll.pending, (state) => {
				return {
					...state,
					servicesPriceRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(servicePriceGetAll.fulfilled, (state, action) => {
				return {
					...state,
					servicesPrice: action.payload,
					servicesPriceRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(servicePriceGetAll.rejected, (state, action) => {
				return {
					...state,
					servicesPriceRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const servicesReducer = servicesSlice.reducer;
