import type { IRentRateType } from '../../models/entities/RentRateType';
import type { IRentRateTypeInsertParams, IRentRateTypesSearchParams } from '../../models/serviceParams/RentParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { RentRateTypeEndPoints } from '../endPoints/RentRateTypeEndPoints';

// RentRateType
export const rentRateTypeSearchService = (
	model: IRentRateTypesSearchParams,
): Promise<ServiceResponse<IRentRateType[]>> => {
	return Api.get<IRentRateType[], IRentRateTypesSearchParams>(RentRateTypeEndPoints.SEARCH, model);
};
export const rentRateTypeInsertService = (
	model: IRentRateTypeInsertParams,
): Promise<ServiceResponse<IRentRateType>> => {
	return Api.post<IRentRateType, IRentRateTypeInsertParams>(RentRateTypeEndPoints.INSERT, model);
};
export const rentRateTypeModifyService = (
	model: IRentRateTypeInsertParams,
): Promise<ServiceResponse<IRentRateType>> => {
	return Api.post<IRentRateType, IRentRateTypeInsertParams>(RentRateTypeEndPoints.MODIFY, model);
};
