import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Fines = lazyWithRetry(() => import('./pages/Fines'));

export const finesRoutes: { [key: number]: IRouteConfig } = {};

finesRoutes[routesIdMap.VehiclesDetailFine] = {
	component: Fines,
	exact: true,
	id: routesIdMap.VehiclesDetailFine,
	name: 'Fines',
	path: '/vehicles/detail/:id/fines',
	permissions: [PermissionsType.VehicleDetail],
	icon: 'cab',
};

finesRoutes[routesIdMap.VehiclesDetailFineDetail] = {
	component: Fines,
	exact: true,
	id: routesIdMap.VehiclesDetailFineDetail,
	name: 'Fine detail',
	path: '/vehicles/detail/:id/fines/:fine',
	permissions: [PermissionsType.VehicleDetail],
	icon: 'cab',
};
