/* eslint-disable react/no-unused-prop-types */
import type { FC } from 'react';
import React, { useEffect } from 'react';

import { Nav } from '@crac/components/external/reactstrap/nav';
import { NavbarToggler } from '@crac/components/external/reactstrap/navbar';
import { AsideToggle } from '@crac/components/intranet/shared/layout/aside/AsideToggle';
import type { IEmployee } from '@crac/core/models/entities/Employee';

import sygnet from '~/assets/img/gc-logo-symbol.png';
import logo from '~/assets/img/logo.svg';

import { HeaderDropdown } from './HeaderDropdown';
// import { MenuOffCanvas } from '../MenuOffcanvas';
import { NavBarBrand } from '../navbarBrand';

interface IHeaderProps {
	employee: IEmployee;
	locale?: string;
	setLocale?: (locale: string) => void;
	fixed?: boolean;
}

export const AppHeader: FC<IHeaderProps> = ({ employee, fixed = true }): JSX.Element => {
	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const sidebarToggle = (event: React.MouseEvent<HTMLAnchorElement>): void => {
		event.preventDefault();
		document.body.classList.toggle('sidebar-lg-show');
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const sidebarMinimize = (event: React.MouseEvent<HTMLButtonElement>): void => {
		event.preventDefault();
		document.body.classList.toggle('sidebar-minimized');
	};

	const mobileSidebarToggle = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>): void => {
		event.preventDefault();
		document.body.classList.toggle('sidebar-show');
	};

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const asideToggle = (event: React.MouseEvent<HTMLButtonElement>): void => {
		event.preventDefault();
		document.body.classList.toggle('aside-menu-hidden');
	};

	useEffect(() => {
		const isFixed = () => {
			if (fixed) {
				document.body.classList.add('header-fixed');
			}
		};

		isFixed();
	}, [fixed]);

	useEffect(() => {
		document.body.classList.remove('sidebar-show');
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.pathname]);

	return (
		<header className="app-header navbar">
			<NavbarToggler className="d-lg-none ms-3" data-sidebar-toggler="true" onClick={mobileSidebarToggle}>
				<span className="navbar-toggler-icon" />
			</NavbarToggler>
			<div className="d-none d-lg-block">
				<NavBarBrand
					full={{
						alt: 'Centauro Rent a Car',
						height: 50,
						src: logo,
						width: 160,
					}}
					minimized={{
						alt: 'Centauro Rent a Car',
						height: 30,
						src: sygnet,
						width: 30,
					}}
				/>
			</div>

			{/* <MenuOffCanvas /> */}

			<Nav className="ms-auto" navbar tag="div">
				<div className="me-md-3">
					<HeaderDropdown employee={employee} />
				</div>
			</Nav>
			<AsideToggle />
		</header>
	);
};
