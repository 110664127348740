import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Services = lazyWithRetry(() => import('./pages/Services'));

export const servicesRoutes: { [key: number]: IRouteConfig } = {};

servicesRoutes[routesIdMap.BookingsPickUpServices] = {
	component: Services,
	exact: true,
	id: routesIdMap.BookingsPickUpServices,
	name: 'Services',
	path: '/booking/pick-up/:id/services',
	permissions: [PermissionsType.BookingPickUpProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: 'sign-in',
};
