import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const FineSearch = lazyWithRetry(() => import('./pages/FineSearch'));
const FineNew = lazyWithRetry(() => import('./pages/FineNew'));
const FineModify = lazyWithRetry(() => import('./pages/FineModify'));

export const finesRoutes: { [key: number]: IRouteConfig } = {};

// FINE
finesRoutes[routesIdMap.FleetFine] = {
	component: FineSearch,
	exact: true,
	id: routesIdMap.FleetFine,
	name: 'Fines',
	path: '/fleet/fine',
	permissions: [PermissionsType.Fine],
	childrens: [routesIdMap.FleetFineNew, routesIdMap.FleetFineModify],
	icon: 'cab',
	menuText: 'Fines',
};

finesRoutes[routesIdMap.FleetFineNew] = {
	component: FineNew,
	exact: true,
	id: routesIdMap.FleetFineNew,
	name: 'New fine',
	path: '/fleet/fine/new',
	permissions: [PermissionsType.FineManagement],
	isChild: true,
	icon: 'plus',
	menuText: 'New fine',
};

finesRoutes[routesIdMap.FleetFineModify] = {
	component: FineModify,
	exact: true,
	id: routesIdMap.FleetFineModify,
	name: 'Modify fine',
	path: '/fleet/fine/modify/:code',
	permissions: [PermissionsType.FineManagement],
	isChild: true,
	icon: 'pencil',
};
