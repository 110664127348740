import { MetaModelEndpoints } from './MetaModelEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IMetaModel } from '../entities/MetaModel';

export interface IMetaModelDeleteParams extends IServiceBaseParams {
	id: number;
}

export const metaModelDeleteService = (model: IMetaModelDeleteParams) => {
	return Api.post<IMetaModel, IMetaModelDeleteParams>(MetaModelEndpoints.DELETE, model);
};
