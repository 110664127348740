import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import type { ISpending } from '@crac/core/models/entities/Spending';
import { linkGetBySpendingLine } from '@crac/core/redux/actions/LinkActions';
import { spendingAdd, spendingDelete, spendingGetByCashLine } from '@crac/core/redux/actions/SpendingActions';
import type { ICashLineSpendingsReducerState } from '@crac/core/redux-store/reducersState/cash/SpendingsReducerState';

const initialState: ICashLineSpendingsReducerState = {
	add: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	getByCashLine: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	getBySpending: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	links: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
	remove: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	spendings: [],
};

const spendingSlice = createSlice({
	name: 'cashLineSpendingSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// ADD
		builder
			.addCase(spendingAdd.pending, (state) => {
				return {
					...state,
					add: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(spendingAdd.fulfilled, (state, action) => {
				return {
					...state,
					add: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					spendings: action.payload ? [...state.spendings, action.payload as ISpending] : state.spendings,
				};
			})
			.addCase(spendingAdd.rejected, (state, action) => {
				return {
					...state,
					add: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY CASH
		builder
			.addCase(spendingGetByCashLine.pending, (state) => {
				return {
					...state,
					getByCashLine: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					spendings: [],
				};
			})
			.addCase(spendingGetByCashLine.fulfilled, (state, action) => {
				return {
					...state,
					getByCashLine: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					spendings: deepCopy(action.payload),
				};
			})
			.addCase(spendingGetByCashLine.rejected, (state, action) => {
				return {
					...state,
					getByCashLine: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
					spendings: [],
				};
			});
		// DELETE
		builder
			.addCase(spendingDelete.pending, (state) => {
				return {
					...state,
					remove: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(spendingDelete.fulfilled, (state, action) => {
				return {
					...state,
					remove: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					spendings: state.spendings
						? state.spendings.filter((spending) => spending.number !== action.payload.number)
						: [],
				};
			})
			.addCase(spendingDelete.rejected, (state, action) => {
				return {
					...state,
					remove: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// LINK_GET_BY_SPENDING_LINE
		builder
			.addCase(linkGetBySpendingLine.pending, (state) => {
				return {
					...state,
					links: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetBySpendingLine.fulfilled, (state, action) => {
				return {
					...state,
					links: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetBySpendingLine.rejected, (state, action) => {
				return {
					...state,
					links: {
						data: state.links ? state.links.data : [],
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const spendingReducer = spendingSlice.reducer;
