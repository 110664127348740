export enum InvoiceableServiceConfigurationEndPoints {
	/**
	 * Deactivate service invoiceable configuration
	 * @host `/invoiceableServiceConfiguration/deactivate`
	 */
	DEACTIVATE = '/invoiceableServiceConfiguration/deactivate',
	/**
	 * Get service invoiceable configuration
	 * @host `/invoiceableServiceConfiguration/get`
	 */
	INSERT = '/invoiceableServiceConfiguration/insert',
	/**
	 * Modify service invoiceable configuration
	 * @host `/invoiceableServiceConfiguration/modify`
	 */
	MODIFY = '/invoiceableServiceConfiguration/modify',
	/**
	 * Search service invoiceable configuration
	 * @host `/invoiceableServiceConfiguration/search`
	 */
	SEARCH = '/invoiceableServiceConfiguration/search',
}
