import {
	vehicleGroupGetAvailabilityService,
	vehicleGroupGetPriceService,
} from '../../data/services/VehicleGroupAvailabilityService';
import type { IVehicleGroupAvailability } from '../../models/entities/VehicleGroupAvailability';
import type { IVehicleGroupsPrice } from '../../models/entities/VehicleGroupPrice';
import type {
	IVehicleGroupGetAvailabilityParams,
	IVehicleGroupGetPriceParams,
} from '../../models/serviceParams/VehicleGroupAvailabilityParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves the availability of vehicle groups based on specified parameters.
 *
 * @param {IVehicleGroupAvailability[]} payload - The availability information for the vehicle groups.
 * @param {IVehicleGroupGetAvailabilityParams} params - Parameters for identifying the availability of vehicle groups, such as location and time period.
 * @returns {Promise<void>} - A promise that resolves to an array of vehicle group availability information.
 */
export const vehicleGroupGetAvailability = createAsyncAction<
	IVehicleGroupAvailability[],
	IVehicleGroupGetAvailabilityParams
>('vehicleGroup/getAvailability', vehicleGroupGetAvailabilityService);

/**
 * Retrieves the price information for vehicle groups based on specified parameters.
 *
 * @param {IVehicleGroupsPrice} payload - The price information for the vehicle groups.
 * @param {IVehicleGroupGetPriceParams} params - Parameters for identifying the price information of vehicle groups, such as location, time period, and specific group details.
 * @returns {Promise<void>} - A promise that resolves to the price information for the vehicle groups.
 */
export const vehicleGroupGetPrice = createAsyncAction<IVehicleGroupsPrice, IVehicleGroupGetPriceParams>(
	'vehicleGroup/getPrice',
	vehicleGroupGetPriceService,
);
