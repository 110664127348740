import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import {
	vehicleGroupGetAvailability,
	vehicleGroupGetPrice,
} from '@crac/core/redux/actions/VehicleGroupAvailabilityActions';
import type { IVehicleGroupAvailabilityReducerState } from '@crac/core/redux-store/reducersState/vehicleGroupAvailability';

const initialState: IVehicleGroupAvailabilityReducerState = {
	vehicleGroupsGetAvailability: [],
	vehicleGroupsGetAvailabilityRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupsGetPrice: { days: 0, groups: [] },
	vehicleGroupsGetPriceRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const vehicleGroupAvailabilitySlice = createSlice({
	name: 'VehicleGroupAvailabilitySlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GROUP GET AVAILABILITY
		builder
			.addCase(vehicleGroupGetAvailability.pending, (state) => {
				return {
					...state,
					vehicleGroupsGetAvailabilityRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupGetAvailability.fulfilled, (state, action) => {
				return {
					...state,
					vehicleGroupsGetAvailability: action.payload,
					vehicleGroupsGetAvailabilityRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupGetAvailability.rejected, (state, action) => {
				return {
					...state,
					vehicleGroupsGetAvailabilityRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// GET PRICE
		builder
			.addCase(vehicleGroupGetPrice.pending, (state) => {
				return {
					...state,
					vehicleGroupsGetPriceRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupGetPrice.fulfilled, (state, action) => {
				return {
					...state,
					vehicleGroupsGetPrice: action.payload ? deepCopy(action.payload) : { days: 0, groups: [] },
					vehicleGroupsGetPriceRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupGetPrice.rejected, (state, action) => {
				return {
					...state,
					vehicleGroupsGetPriceRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const vehicleGroupAvailabilityReducer = vehicleGroupAvailabilitySlice.reducer;
