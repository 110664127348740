export enum MaximumBookingDurationEndPoints {
	/**
	 * @host `/maximumBookingDuration/search`
	 */
	SEARCH = '/maximumBookingDuration/search',
	/**
	 * @host `/maximumBookingDuration/insert`
	 */
	INSERT = '/maximumBookingDuration/insert',

	/**
	 * @host `/maximumBookingDuration/deactivate`
	 */
	DEACTIVATE = '/maximumBookingDuration/deactivate',
}
