import type { FC } from 'react';
import React from 'react';

import { FormGroup } from '@crac/components/external/reactstrap/form/FormGroup';
import { Input } from '@crac/components/external/reactstrap/input';

interface IPermissionToolSelectProps {
	onChange?: (rol: string) => void;
	selected?: string;
}

export const PermissionToolSelect: FC<IPermissionToolSelectProps> = ({ onChange, selected }) => {
	const handleOnChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
		const { currentTarget } = event;
		if (onChange) {
			onChange(currentTarget.value);
		}
	};

	return (
		<FormGroup>
			<Input
				id="sectionPermission"
				name="sectionPermission"
				onChange={handleOnChange}
				type="select"
				value={selected}
			>
				<option value="all">All</option>
				<option value="booking">Booking</option>
				<option value="company">Companies</option>
				<option value="customer">Customer</option>
				<option value="franchise">Franchise</option>
				<option value="management">Management</option>
				<option value="payment">Payment</option>
				<option value="vehicle">Vehicles</option>
				<option value="pricing">Pricing</option>
				<option value="fleet">Fleet</option>
			</Input>
		</FormGroup>
	);
};
