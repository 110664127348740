import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const InvoiceTo = lazyWithRetry(() => import('./pages/InvoiceTo'));
const InvoiceToCompanyNew = lazyWithRetry(() => import('./pages/InvoiceToCompanyNew'));
const InvoiceToCompanySearch = lazyWithRetry(() => import('./pages/InvoiceToCompanySearch'));
const InvoiceToCustomerNew = lazyWithRetry(() => import('./pages/InvoiceToCustomerNew'));
const InvoiceToCustomerSearch = lazyWithRetry(() => import('./pages/InvoiceToCustomerSearch'));

export const invoiceToRoutes: { [key: number]: IRouteConfig } = {};

invoiceToRoutes[routesIdMap.BookingsDetailInvoiceTo] = {
	childrens: [
		routesIdMap.BookingsDetailInvoiceToCustomerNew,
		routesIdMap.BookingsDetailInvoiceToCustomerSearch,
		routesIdMap.BookingsDetailInvoiceToCompanyNew,
		routesIdMap.BookingsDetailInvoiceToCompanySearch,
		routesIdMap.BookingsDetailInvoiceToCustomerDetail,
		routesIdMap.BookingsDetailInvoiceToCompanyDetail,
	],
	component: InvoiceTo,
	exact: true,
	id: routesIdMap.BookingsDetailInvoiceTo,
	name: 'Invoice to',
	path: '/bookings/detail/:id/invoice-to',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: 'file-text',
};

invoiceToRoutes[routesIdMap.BookingsDetailInvoiceToCustomerSearch] = {
	component: InvoiceToCustomerSearch,
	exact: true,
	isChild: true,
	id: routesIdMap.BookingsDetailInvoiceToCustomerSearch,
	name: 'Search customer',
	path: '/bookings/detail/:id/invoice-to/customer-search',
	permissions: [PermissionsType.BookingDetail, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: 'search',
};

invoiceToRoutes[routesIdMap.BookingsDetailInvoiceToCustomerNew] = {
	component: InvoiceToCustomerNew,
	exact: true,
	isChild: true,
	id: routesIdMap.BookingsDetailInvoiceToCustomerNew,
	name: 'New customer',
	path: '/bookings/detail/:id/invoice-to/customer-new',
	permissions: [PermissionsType.BookingDetail, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: 'plus',
};

invoiceToRoutes[routesIdMap.BookingsDetailInvoiceToCustomerDetail] = {
	component: InvoiceToCustomerNew,
	exact: true,
	isChild: true,
	id: routesIdMap.BookingsDetailInvoiceToCustomerDetail,
	name: 'Check customer data',
	path: '/bookings/detail/:id/invoice-to/:customer/customer-detail',
	permissions: [PermissionsType.BookingDetail, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: 'plus',
};

invoiceToRoutes[routesIdMap.BookingsDetailInvoiceToCompanySearch] = {
	component: InvoiceToCompanySearch,
	exact: true,
	isChild: true,
	id: routesIdMap.BookingsDetailInvoiceToCompanySearch,
	name: 'Search company',
	path: '/bookings/detail/:id/invoice-to/company-search',
	permissions: [PermissionsType.BookingDetail, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: 'search',
};

invoiceToRoutes[routesIdMap.BookingsDetailInvoiceToCompanyNew] = {
	component: InvoiceToCompanyNew,
	exact: true,
	isChild: true,
	id: routesIdMap.BookingsDetailInvoiceToCompanyNew,
	name: 'New company',
	path: '/bookings/detail/:id/invoice-to/company-new',
	permissions: [PermissionsType.BookingDetail, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: 'plus',
};

invoiceToRoutes[routesIdMap.BookingsDetailInvoiceToCompanyDetail] = {
	component: InvoiceToCompanyNew,
	exact: true,
	isChild: true,
	id: routesIdMap.BookingsDetailInvoiceToCompanyDetail,
	name: 'Check company data',
	path: '/bookings/detail/:id/invoice-to/:company/company-detail',
	permissions: [PermissionsType.BookingDetail, PermissionsType.BookingChangeInvoiceToCustomer],
	icon: 'plus',
};
