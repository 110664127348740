import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const InvoiceableServiceConfiguration = lazyWithRetry(() => import('./pages/InvoiceableServiceConfiguration'));
const InvoiceableServiceConfigurationNew = lazyWithRetry(() => import('./pages/InvoiceableServiceConfigurationNew'));
const InvoiceableServiceConfigurationModify = lazyWithRetry(
	() => import('./pages/InvoiceableServiceConfigurationModify'),
);

export const invoiceableServiceConfigurationRoutes: { [key: number]: IRouteConfig } = {};

// INVOICEABLE SERVICES
invoiceableServiceConfigurationRoutes[routesIdMap.PricingInvoiceableServiceConfiguration] = {
	component: InvoiceableServiceConfiguration,
	exact: true,
	id: routesIdMap.PricingInvoiceableServiceConfiguration,
	name: 'Invoiceable services configuration',
	menuText: 'Invoiceable services',
	path: '/pricing/invoiceable-services',
	permissions: getPricingPermission([PermissionsType.PricingInvoiceableServiceConfiguration]),
	childrens: [
		routesIdMap.PricingInvoiceableServiceConfigurationNew,
		routesIdMap.PricingInvoiceableServiceConfigurationModify,
	],
	icon: 'file-text-o',
};

invoiceableServiceConfigurationRoutes[routesIdMap.PricingInvoiceableServiceConfigurationNew] = {
	component: InvoiceableServiceConfigurationNew,
	exact: true,
	id: routesIdMap.PricingInvoiceableServiceConfigurationNew,
	name: 'New invoiceable service',
	path: '/pricing/invoiceable-services/new',
	permissions: getPricingPermission([PermissionsType.PricingInvoiceableServiceConfiguration]),
	icon: 'plus',
	menuText: 'New invoiceable service',
};

invoiceableServiceConfigurationRoutes[routesIdMap.PricingInvoiceableServiceConfigurationModify] = {
	component: InvoiceableServiceConfigurationModify,
	exact: true,
	id: routesIdMap.PricingInvoiceableServiceConfigurationModify,
	name: 'Invoiceable services modify',
	path: '/pricing/invoiceable-services/:id',
	permissions: getPricingPermission([PermissionsType.PricingInvoiceableServiceConfiguration]),
	icon: 'pencil',
};
