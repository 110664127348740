import { createSlice } from '@reduxjs/toolkit';

import {
	rentRateVariationDeactivate,
	rentRateVariationInsert,
	rentRateVariationReset,
	rentRateVariationsSearch,
} from '@crac/core/redux/actions/RentRateVariationActions';
import type { IRentRateVariationReducerState } from '@crac/core/redux-store/reducersState/pricing/RentRateVariationReducerState';

const initialState: IRentRateVariationReducerState = {
	rentRateVariation: null,
	rentRateVariations: [],
	rentRateVariationInsertRequest: { inProgress: false, ok: true, messages: [] },
	rentRateVariationsSearchRequest: { inProgress: false, ok: true, messages: [] },
	rentRateVariationDeactivateRequest: { inProgress: false, ok: true, messages: [] },
};

const rentRateVariationSlice = createSlice({
	name: 'rentRateVariation',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(rentRateVariationReset, (state) => {
			return {
				...state,
				rentRateVariations: [],
			};
		});
		builder.addCase(rentRateVariationsSearch.pending, (state) => {
			return {
				...state,
				rentRateVariationsSearchRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateVariationsSearch.rejected, (state, action) => {
			return {
				...state,
				rentRateVariationsSearchRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateVariationsSearch.fulfilled, (state, action) => {
			return {
				...state,
				rentRateVariationsSearchRequest: { inProgress: false, ok: true, messages: [] },
				rentRateVariations: action.payload,
			};
		});
		builder.addCase(rentRateVariationInsert.pending, (state) => {
			return {
				...state,
				rentRateVariationInsertRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateVariationInsert.rejected, (state, action) => {
			return {
				...state,
				rentRateVariationInsertRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateVariationInsert.fulfilled, (state) => {
			return {
				...state,
				rentRateVariationInsertRequest: { inProgress: false, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateVariationDeactivate.pending, (state) => {
			return {
				...state,
				rentRateVariationDeactivateRequest: { inProgress: true, messages: [], ok: true },
			};
		});
		builder.addCase(rentRateVariationDeactivate.rejected, (state, action) => {
			return {
				...state,
				rentRateVariationDeactivateRequest: { inProgress: false, messages: action.payload || [], ok: false },
			};
		});
		builder.addCase(rentRateVariationDeactivate.fulfilled, (state, action) => {
			return {
				...state,
				rentRateVariationDeactivateRequest: { inProgress: false, messages: [], ok: true },
				rentRateVariations: [
					...state.rentRateVariations.filter(({ id }) => !action.payload.map((item) => item.id).includes(id)),
					...action.payload,
				],
			};
		});
	},
});

export const rentRateVariationReducer = rentRateVariationSlice.reducer;
