import type { ICoupon } from '../../models/entities/Coupon';
import type {
	ICouponCheckParams,
	ICouponGetByBookingNumberParams,
	ICouponGetByCustomerCodeParams,
	ICouponInsertCallBackParams,
	ICouponInsertParams,
} from '../../models/serviceParams/CouponParams';
import type { ICouponCheckResponse } from '../../models/serviceResponse/CouponCheckResponse';
import type { ICouponInsertResponse } from '../../models/serviceResponse/CouponResponse';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { CouponEndPoint } from '../endPoints/CouponEndPoints';

export const couponServiceCheck = (model: ICouponCheckParams): Promise<ServiceResponse<ICouponCheckResponse>> => {
	return Api.get<ICouponCheckResponse, ICouponCheckParams>(CouponEndPoint.CHECK, model);
};
/**
 * Get by customerCode
 * @returns  Promise<ServiceResponse<ICoupon[]>>
 */
export const couponServiceGetByCustomerCode = (
	model: ICouponGetByCustomerCodeParams,
): Promise<ServiceResponse<ICoupon[]>> => {
	return Api.get<ICoupon[], ICouponGetByCustomerCodeParams>(CouponEndPoint.COUPON_GET_BY_CUSTOMER_CODE, model);
};

/**
 * Get by BookingNumber
 * @returns {Promise} `ResponsePropType`
 */
export const couponServiceGetByBookingNumber = (
	model: ICouponGetByBookingNumberParams,
): Promise<ServiceResponse<ICoupon[]>> => {
	return Api.get<ICoupon[], ICouponGetByBookingNumberParams>(CouponEndPoint.COUPON_BY_BOOKING_NUMBER, model);
};

/**
 * Insert customer coupon
 * @param params ICouponInsertParams
 * @returns
 */
export const couponServiceInsert = (params: ICouponInsertParams): Promise<ServiceResponse<ICouponInsertResponse>> =>
	Api.post<ICouponInsertResponse, ICouponInsertParams>(CouponEndPoint.COUPON_INSERT, params);

/**
 * Insert callback customer coupon
 * @param params ICouponInsertCallBackParams
 * @returns
 */
export const couponServiceInsertCallBack = (
	params: ICouponInsertCallBackParams,
): Promise<ServiceResponse<ICouponInsertResponse>> =>
	Api.post<ICouponInsertResponse, ICouponInsertCallBackParams>(CouponEndPoint.COUPON_INSERT_CALLBACK, params);
