import { EnumEnText, EnumEsText } from './enumTexts';
import { paymentTypesEN, paymentTypesES } from './paymentTypes';

export const translations = {
	en: {
		...EnumEnText,
		...paymentTypesEN,
		Blank: 'N/A',
		PayAgency: 'Pay agency',
		acceptEthicalCode: 'By accessing you accept the',
		acceptPrivacyPolicy: 'By accessing you accept the',
		add: 'Add',
		addExtraDriver: 'Add extra driver',
		address: 'Address',
		addressNoFound: 'Delivery Service is not available for this address',
		agency: 'Agency',
		agencyAmount: 'Agency amount',
		amount: 'Amount',
		amountPerDay: 'Amount per day',
		amountPerHour: 'Amount per hour',
		amountPerMonth: 'Amount per month',
		amountPerMinute: 'Amount per minute',
		amountToPay: 'Amount to pay',
		amounts: 'Amounts',
		amountsDetail: 'Amounts detail',
		applyDiscount: 'Apply discount',
		back: 'Back',
		bags: 'Bags',
		between: 'Between',
		birthDate: 'Birth date',
		booking: 'Booking',
		bookingLinesModifiedOk: 'Booking lines modified correctly',
		bookingNumber: 'Booking number',
		branch: 'Branch',
		carModel: 'Car model',
		changeVehicleReason: 'Change vehicle reason',
		close: 'Close',
		code: 'Code',
		concepts: 'Concepts',
		continue: 'Continue',
		contractPendingAmount: 'Contract pending amount',
		current: 'Current',
		customer: 'Customer',
		customerAge: 'Customer age',
		customerAmount: 'Customer amount',
		customerInvoice: 'Customer invoice',
		daily: 'Daily',
		hourly: 'Hourly',
		monthly: 'Monthly',
		perMinute: 'Per minute',
		date: 'Date',
		day: 'Day',
		days: 'Days',
		hour: 'Hour',
		hours: 'Hours',
		month: 'Month',
		months: 'Months',
		minute: 'Minute',
		minutes: 'Minutes',
		deposit: 'Deposit',
		description: 'Description',
		detail: 'Detail',
		discount: 'Discount',
		documentNoDocumentsToShow: 'There are no documents to show',
		documents: 'Documents',
		doors: 'Doors',
		driverAge: "Driver's age",
		dropOff: 'Drop off',
		dropOffAddress: 'Drop off address',
		dropOffBranch: 'Drop off branch',
		dropOffData: 'Drop off data',
		dropOffDate: 'Drop off date',
		dropOffHour: 'Drop off hour',
		email: 'Email',
		english: 'English',
		ethicalCode: 'Code of Ethics for Suppliers and Partners',
		ethicalCodeLink:
			'https://cdn.centauro.net/web/Code_of_Ethics_for_Suppliers_and_Partners_reduced_12f87a6a5f.pdf',
		excess: 'Excess',
		expedient: 'Expedient',
		fineDate: 'Fine date',
		fineNumber: 'Nº fine',
		flight: 'Flight',
		fuel: 'Fuel',
		fuelType: 'Fuel type',
		hybrid: 'Hybrid',
		inCompleteAddress: 'Incomplete address',
		included: 'Included',
		invoice: 'Invoice',
		invoiceTo: 'Invoice to',
		invoiceToAgency: 'Invoiceable to agency',
		kmExcess: 'Km Excess',
		licenseExpedition: 'License expedition',
		licenseNumber: 'License number',
		location: 'Location',
		login: 'Login',
		managementAmount: 'Management amount',
		managementInvoice: 'Management invoice',
		modify: 'Modify',
		name: 'Name',
		noDataRecordered: 'No data recorded',
		noExtraDriversRegister: 'There are no extra drivers registered',
		noGroupsAvailable: 'No groups available',
		noRates: 'No rates',
		noRegisteredFine: 'No fines registered',
		noServicesAvaiable: 'There are no services available',
		noVehicleChangeRegistered: 'No vehicle changes registered',
		notAvailable: 'Not available',
		num: 'Nº',
		number: 'Number',
		ok: 'Ok',
		open: 'Open',
		orSimilar: 'Or Similar',
		package: 'Package',
		paid: 'Paid',
		paymentDateTime: 'Payment date time',
		pending: 'Pending',
		phoneNumber: 'Phone number',
		pickDate: 'Pick a date',
		pickUp: 'Pick Up',
		pickUpAddress: 'Pick up address',
		pickUpBranch: 'Pick up branch',
		pickUpData: 'Pick up data',
		pickUpDate: 'Pick up date',
		pickUpHour: 'Pick up hour',
		plateNumber: 'Plate number',
		points: 'Points',
		print: 'Print',
		privacyPolicy: 'Conditions of use.',
		quoteDate: 'Quote date',
		quoteTime: 'Quote time',
		reason: 'Reason',
		registered: 'Registered',
		registeredDate: 'Registered date',
		remove: 'Remove',
		rent: 'Rent',
		rentAmount: 'Rent amount',
		required: 'Required',
		search: 'Search',
		seats: 'Seats',
		select: 'Select',
		selectOneOption: 'Select one option',
		selectVehicleGroup: 'Select vehicle group',
		services: 'Services',
		servicesIncludedInPrice: 'Services included in the price',
		spanish: 'Spanish',
		state: 'State',
		success: 'Success!',
		theMinValueIs: 'The minimum value is',
		toPay: 'To pay',
		total: 'Total',
		train: 'Train',
		transmitter: 'Transmitter',
		type: 'Type',
		user: 'User',
		vehicle: 'Vehicle',
		viewDetail: 'View detail',
		viewInGoogleMap: 'View location in Google Maps',
		voucherNumber: 'Voucher number',
		years: 'Years',
		youHaveMaximumDiscount: 'You have a maximum discount of',
		edit: 'Edit',
		checkAvailability: 'Check availability',
		invoiceToCustomer: 'Invoice to customer',
		actualDropOffDate: 'Actual drop off date',
		disabledOptionsCustomerEmail: "Option disabled because the customer's email is empty",
		newDropOffDate: 'New drop off date',
		newDropOffTime: 'New drop off time',
		cancel: 'Cancel',
		checkExtendAvailability: 'Check extend availability',
		change: 'Change',
	},
	es: {
		...paymentTypesES,
		...EnumEsText,
		Blank: 'N/A',
		PayAgency: 'Pay agency',
		acceptEthicalCode: 'Al acceder aceptas el',
		acceptPrivacyPolicy: 'Al acceder aceptas las',
		add: 'Añadir',
		addExtraDriver: 'Añadir conductor',
		address: 'Dirección',
		addressNoFound: 'Servicio a domicilio no disponible para esta dirección',
		agency: 'Agencia',
		agencyAmount: 'Importes de agencia',
		amount: 'Importe',
		amountPerDay: 'Importe por día',
		amountPerHour: 'Importe por hora',
		amountPerMonth: 'Importe por mes',
		amountPerMinute: 'Importe por minuto',
		amountToPay: 'Importe a pagar',
		amounts: 'Importes',
		amountsDetail: 'Detalle de importes',
		applyDiscount: 'Aplicar descuento',
		back: 'Atrás',
		bags: 'Maletas',
		between: 'Entre',
		birthDate: 'Fecha nacimiento',
		booking: 'Reserva',
		bookingLinesModifiedOk: 'Lineas de reserva modificadas correctamente',
		bookingNumber: 'Número reserva',
		branch: 'Sucursal',
		carModel: 'Modelo vehículo',
		changeVehicleReason: 'Motivo del cambio',
		close: 'Cerrar',
		code: 'Código',
		concepts: 'Conceptos',
		continue: 'Continuar',
		contractPendingAmount: 'Contratos con pendiente',
		current: 'Actual',
		customer: 'Cliente',
		customerAge: 'Edad del cliente',
		customerAmount: 'Importes de cliente',
		customerInvoice: 'Factura del cliente',
		daily: 'Diario',
		hourly: 'Por hora',
		monthly: 'Mensual',
		perMinute: 'Por minuto',
		date: 'Fecha',
		day: 'Día',
		days: 'Días',
		hour: 'Hora',
		hours: 'Horas',
		month: 'Mes',
		months: 'Meses',
		minute: 'Minuto',
		minutes: 'Minutes',
		deposit: 'Depósito',
		description: 'Descripción',
		detail: 'Detalle',
		discount: 'Descuento',
		documentNoDocumentsToShow: 'No hay documentos para mostrar',
		documents: 'Documentos',
		doors: 'Puertas',
		driverAge: 'Edad del conductor',
		dropOff: 'Devolución',
		dropOffAddress: 'Dirección de devolución',
		dropOffBranch: 'Sucursal de devolución',
		dropOffData: 'Datos de devolución',
		dropOffDate: 'Fecha de devolución',
		dropOffHour: 'Hora de devolución',
		email: 'E-Mail',
		english: 'Inglés',
		ethicalCode: 'Código Ético de Proveedores y Colaboradores',
		ethicalCodeLink: 'https://cdn.centauro.net/web/Codigo_Etico_Proveedores_reduced_2daefd5cd1.pdf',
		excess: 'Franquicias',
		expedient: 'Expediente',
		fineDate: 'Fecha multa',
		fineNumber: 'Nº multa',
		flight: 'Vuelo',
		fuel: 'Combustible',
		fuelType: 'Tipo combustible',
		hybrid: 'Híbrido',
		inCompleteAddress: 'Dirección incompleta',
		included: 'Incluido',
		invoice: 'Facturar',
		invoiceTo: 'Facturar a',
		invoiceToAgency: 'Facturar a la agencia',
		kmExcess: 'Km Exceso',
		licenseExpedition: 'Fecha expedición',
		licenseNumber: 'Número',
		location: 'Ubicación',
		login: 'Acceder',
		managementAmount: 'Import de gestión',
		managementInvoice: 'Factura de gestión',
		modify: 'Modificar',
		name: 'Nombre',
		noDataRecordered: 'No hay datos registrados',
		noExtraDriversRegister: 'No hay conductores adicionales registrados',
		noGroupsAvailable: 'No hay grupos disponibles',
		noRates: 'Sin tarifas',
		noRegisteredFine: 'No hay multas registradas',
		noServicesAvaiable: 'No hay servicios disponibles',
		noVehicleChangeRegistered: 'No hay cambios de vehículos registrados',
		notAvailable: 'No disponible',
		num: 'Nº',
		number: 'Número',
		ok: 'Vale',
		open: 'Abrir',
		orSimilar: 'O Similar',
		package: 'Paquete',
		paid: 'Pagado',
		paymentDateTime: 'Fecha de pago',
		pending: 'Pendiente',
		phoneNumber: 'Número de teléfono',
		pickDate: 'Selecciona una fecha',
		pickUp: 'Recogida',
		pickUpAddress: 'Dirección de recogida',
		pickUpBranch: 'Sucursal de recogida',
		pickUpData: 'Datos de recogida',
		pickUpDate: 'Fecha de recogida',
		pickUpHour: 'Hora de recogida',
		plateNumber: 'Matrícula',
		points: 'Puntos',
		print: 'Imprimir',
		privacyPolicy: ' Condiciones de uso.',
		quoteDate: 'Fecha de registro',
		quoteTime: 'Hora de registro',
		reason: 'Razón',
		registered: 'Registrado',
		registeredDate: 'Fecha de registro',
		remove: 'Eliminar',
		rent: 'Alquiler',
		rentAmount: 'Importe alquiler',
		required: 'Obligatorio',
		search: 'Buscar',
		seats: 'Plazas',
		select: 'Seleccionar',
		selectOneOption: 'Selecciona una opción',
		selectVehicleGroup: 'Selecciona un grupo de vehículo',
		services: 'Servicios',
		servicesIncludedInPrice: 'Servicios incluido en el precio',
		spanish: 'Español',
		state: 'Estado',
		success: '¡Exito!',
		theMinValueIs: 'El valor mínimo es',
		toPay: 'Pagar',
		total: 'Total',
		train: 'Tren',
		transmitter: 'Gestor',
		type: 'Tipo',
		user: 'Usuario',
		vehicle: 'Vehículo',
		viewDetail: 'Ver detalles',
		viewInGoogleMap: 'Ver ubicación en Google Maps',
		voucherNumber: 'Número de bono',
		years: 'Años',
		youHaveMaximumDiscount: 'Tienes un descuento máximo de',
		edit: 'Editar',
		checkAvailability: 'Comprobar disponibilidad',
		invoiceToCustomer: 'Facturar al cliente',
		actualDropOffDate: 'Fecha de devolución actual',
		disabledOptionsCustomerEmail: 'Opción deshabilitada porque el e-mail del cliente esta vacío',
		newDropOffDate: 'Nueva fecha de devolución',
		newDropOffTime: 'Nueva hora de devolución',
		cancel: 'Cancelar',
		checkExtendAvailability: 'Comprobar disponibilidad',
		change: 'Cambiar',
	},
};
