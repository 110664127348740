import { SaleMarginEndPoints } from './SaleMarginEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleMargin } from '../entities/SaleMargin';

export interface ISaleMarginReactivateParams extends IServiceBaseParams {
	ids: number[];
}

export const saleMarginReactivateService = (model: ISaleMarginReactivateParams) => {
	return Api.post<ISaleMargin[], ISaleMarginReactivateParams>(SaleMarginEndPoints.REACTIVATE, model);
};
