import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Comments = lazyWithRetry(() => import('./pages/Comments'));

export const commentsRoutes: { [key: number]: IRouteConfig } = {};

commentsRoutes[routesIdMap.BookingsDetailComments] = {
	component: Comments,
	exact: true,
	id: routesIdMap.BookingsDetailComments,
	name: 'Comments',
	path: '/bookings/detail/:id/comments',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: 'comments',
};
