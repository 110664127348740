import {
	vehicleServiceChangeAgreedRepurchaseDate,
	vehicleServiceChangeBrandParking,
	vehicleServiceChangeCarrier,
	vehicleServiceChangeDeFleetDate,
	vehicleServiceChangeFleetTypology,
	vehicleServiceChangeNextITVDate,
	vehicleServiceChangeRegistrationDate,
	vehicleServiceChangeRentEndDate,
	vehicleServiceChangeRentStartDate,
	vehicleServiceChangeSaleAmount,
	vehicleServiceChangeUnregisterTrafficDate,
	vehicleServiceChangeVehicleGroup,
	vehicleServiceSearch,
} from '../../data/services/VehicleService';
import type {
	IVehicleChangeAgreedRepurchaseDateParams,
	IVehicleChangeBrandParkingParams,
	IVehicleChangeCarrierParams,
	IVehicleChangeDeFleetDateParams,
	IVehicleChangeFleetTypologyParams,
	IVehicleChangeNextITVDateParams,
	IVehicleChangeRegistrationDateParams,
	IVehicleChangeRentEndDateParams,
	IVehicleChangeRentStartDateParams,
	IVehicleChangeSaleAmountParams,
	IVehicleChangeUnregisterTrafficDateParams,
	IVehicleChangeVehicleGroupParams,
	IVehicleSearchParams,
} from '../../models/serviceParams/VehicleParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IVehicle } from '../../modules/vehicle/entities/Vehicle';
/**
 * Searches for vehicles based on search parameters.
 * @param {IVehicleSearchParams} params The parameters used for searching vehicles.
 * @returns {Promise<void>} A promise that resolves when the search operation is complete.
 */
export const fleetVehicleModifySearch = createAsyncAction<IVehicle[], IVehicleSearchParams>(
	'fleet/VehicleModify/search',
	vehicleServiceSearch,
);

/**
 * Changes the vehicle group of a specified vehicles.
 * @param {IVehicleChangeVehicleGroupParams} params The parameters for changing the vehicle group.
 * @returns {Promise<void>} A promise that resolves when the vehicle group change operation is complete.
 */
export const fleetVehicleModifyVehicleGroup = createAsyncAction<IVehicle[], IVehicleChangeVehicleGroupParams>(
	'fleet/VehicleModify/changeVehicleGroup',
	vehicleServiceChangeVehicleGroup,
);

/**
 * Changes the de-fleet date of a specified vehicles.
 * @param {IVehicleChangeDeFleetDateParams} params The parameters for changing the de-fleet date.
 * @returns {Promise<void>} A promise that resolves when the de-fleet date change operation is complete.
 */
export const fleetVehicleModifyChangeDeFleetDate = createAsyncAction<IVehicle[], IVehicleChangeDeFleetDateParams>(
	'fleet/VehicleModify/changeDefleetDate',
	vehicleServiceChangeDeFleetDate,
);

/**
 * Agrees on a repurchase date for a specified vehicles.
 * @param {IVehicleChangeAgreedRepurchaseDateParams} params The parameters for agreeing on a repurchase date.
 * @returns {Promise<void>} A promise that resolves when the repurchase date agreement operation is complete.
 */
export const fleetVehicleModifyChangeAgreedRepurchaseDate = createAsyncAction<
	IVehicle[],
	IVehicleChangeAgreedRepurchaseDateParams
>('fleet/VehicleModify/agreeRepurchaseDate', vehicleServiceChangeAgreedRepurchaseDate);

/**
 * Changes the next ITV (Inspección Técnica de Vehículos) date for a specified vehicles.
 * @param {IVehicleChangeNextITVDateParams} params The parameters for changing the next ITV date.
 * @returns {Promise<void>} A promise that resolves when the ITV date change operation is complete.
 */
export const fleetVehicleModifyChangeNextITVDate = createAsyncAction<IVehicle[], IVehicleChangeNextITVDateParams>(
	'fleet/VehicleModify/ChangeNextITV',
	vehicleServiceChangeNextITVDate,
);

/**
 * Changes the rent start date of a specified vehicles.
 * @param {IVehicleChangeRentStartDateParams} params The parameters for changing the rent start date.
 * @returns {Promise<void>} A promise that resolves when the rent date change operation is complete.
 */
export const fleetVehicleModifyChangeRentStartDate = createAsyncAction<IVehicle[], IVehicleChangeRentStartDateParams>(
	'fleet/VehicleModify/changeRentStartDate',
	vehicleServiceChangeRentStartDate,
);

/**
 * Changes the rent end date of a specified vehicles.
 * @param {IVehicleChangeRentEndDateParams} params The parameters for changing the rent end date.
 * @returns {Promise<void>} A promise that resolves when the rent date change operation is complete.
 */
export const fleetVehicleModifyChangeRentEndDate = createAsyncAction<IVehicle[], IVehicleChangeRentEndDateParams>(
	'fleet/VehicleModify/changeRentEndDate',
	vehicleServiceChangeRentEndDate,
);

/**
 * Changes the unregister traffic date of a specified vehicles.
 * @param {IVehicleChangeUnregisterTrafficDateParams} params The parameters for changing the unregister traffic date.
 * @returns {Promise<void>} A promise that resolves when the unregister traffic date change operation is complete.
 */
export const fleetVehicleModifyChangeUnregisterTrafficDate = createAsyncAction<
	IVehicle[],
	IVehicleChangeUnregisterTrafficDateParams
>('fleet/VehicleModify/changeUnregisterTrafficDate', vehicleServiceChangeUnregisterTrafficDate);

/**
 * Changes the fleet typology of the specified vehicles
 * @param {IVehicleChangeFleetTypologyParams} params The parameters for changing the fleet typology.
 * @returns {Promise<void>} A promise that resolves when the fleet typology change operation is complete.
 */
export const fleetVehicleModifyChangeFleetTypology = createAsyncAction<IVehicle[], IVehicleChangeFleetTypologyParams>(
	'fleet/VehicleModify/changeFleetTypology',
	vehicleServiceChangeFleetTypology,
);

/**
 * Change the supplierCode of the selected vehicles
 * @param {IVehicleChangeCarrierParams} params The parameter for changing the supplierCode
 * @returns {Promise<void>} A promise that resolves when the supplierCode change operation is complete
 */
export const fleetVehicleModifyChangeCarrier = createAsyncAction<IVehicle[], IVehicleChangeCarrierParams>(
	'fleet/VehicleModify/changeCarrier',
	vehicleServiceChangeCarrier,
);

/**
 * It changes the registration date of an array of vehicles
 * @param {IVehicleChangeRegistrationDateParams} params The parameter for changing the registration date
 * @returns {Promise<void>} A promise that resolves when the registrationDate change operation is complete
 */
export const fleetVehicleModifyChangeRegistrationDate = createAsyncAction<
	IVehicle[],
	IVehicleChangeRegistrationDateParams
>('fleet/VehicleModify/changeRegistrationDate', vehicleServiceChangeRegistrationDate);

/**
 * It changes the brand parking of an array of vehicles
 * @param {IVehicleChangeBrandParkingParams} params The parameters for changing the brand parking
 * @returns {Promise<void>} A promise that resolves when the brandParking change operation is complete
 */
export const fleetVehicleModifyChangeBrandParking = createAsyncAction<IVehicle[], IVehicleChangeBrandParkingParams>(
	'fleet/VehicleModify/changeBrandParking',
	vehicleServiceChangeBrandParking,
);

/**
 * It changes the sale amount of an array of vehicles
 * @param {IVehicleChangeSaleAmountParams} params The parameters for changing the sale amount
 * @returns {Promise<void>} A promise that resolves when the brandParking change operation is complete
 */
export const fleetVehicleModifyChangeSaleAmount = createAsyncAction<IVehicle[], IVehicleChangeSaleAmountParams>(
	'fleet/VehicleModify/changeSaleAmount',
	vehicleServiceChangeSaleAmount,
);

/**
 * Clears the vehicle modification data from the state.
 */
export const fleetVehicleModifyClear = createSyncAction('fleet/VehicleModify/clear');
