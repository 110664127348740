import { sumAmounts } from '../../helpers/numbers';
import type { IVehicleGroupAvailabilityAndPrice } from '../../models/entities/Availability';
import type { IBookingLine } from '../../models/entities/BookingLine';
import type { IBookingPackage } from '../../models/entities/BookingPackage';
import type { IProvider } from '../../models/entities/Provider';
import type { IService } from '../../models/entities/Service';
import type { IServicePrice } from '../../models/entities/ServicePrice';
import { BookingLineType } from '../../models/types/BookingLineType';
import { BookingPackageType } from '../../models/types/BookingPackageType';
import { BookingType } from '../../models/types/BookingType';
import { CreationMethod } from '../../modules/shared/types/CreationMethod';
import type { IBookingAmount } from '../types/IBookingAllAmount';

/**
 * Return list services include in package
 */
export const getServicesFromPackage = (group: IVehicleGroupAvailabilityAndPrice, packageCode: string): IService[] => {
	const { packages, services } = group;
	if (packages && packages.length > 0 && services.length) {
		const currentPackage = packages.find((item) => item.code === packageCode);

		let packageService = [];
		if (currentPackage) {
			const serviceCodes = currentPackage.services.map((serv) => serv.code);

			packageService = services
				.filter((service) => serviceCodes.includes(service.code))
				.map((serv) => ({ ...serv, included: true }));

			if (packageService.filter((item) => item.maximumQuantity > 0).length === currentPackage.services.length) {
				return packageService;
			}
		}
	}

	return [];
};

/**
 * Comprueba si es necesario añadir CR
 * Se añade el CR si está configurado en el proveedor y no tiene ya añadido un extra ff
 * @returns `null` Si no está configurado el `CR` en el proveedor
 * @returns `IService` Si está configurado el `CR` en el proveedor
 */
export const getCRService = (
	group: IVehicleGroupAvailabilityAndPrice,
	currentServices: IService[],
	provider?: IProvider,
): IService | null => {
	if (!provider) {
		return null;
	}

	const crService = group.services.find(
		(service) => service.code === provider.refuelServiceCode && service.maximumQuantity,
	);

	if (crService) {
		const haveFForCR = currentServices.some((service) => service.ff || service.code === provider.refuelServiceCode);

		const haveFF = currentServices.some((service) => service.ff);
		const haveCR = currentServices.some((service) => service.code === provider.refuelServiceCode);

		if (!haveCR && !haveFF && !haveFForCR) {
			return {
				...crService,
				minimumQuantity: 1,
			};
		}
	}

	return null;
};

/**
 * Sum amount of mandatory services
 */
/**
 * Sum amount of mandatory services
 */
export const getMandatoryServiceTotalAmount = (services: IService[]): IBookingAmount => {
	const filteredServices = services.filter((service) => service.minimumQuantity > 0 && service.maximumQuantity > 0);

	const retailAmount = sumAmounts(filteredServices, 'retailAmount');
	const netAmount = sumAmounts(filteredServices, 'netAmount');

	return {
		retail: retailAmount,
		net: netAmount,
	};
};

/**
 * Return the service invoiceable to agency based on bookingType
 */
export const getServicesByBookingType = (services: IService[], bookingType: BookingType): IService[] => {
	if ([BookingType.PREBOOKING, BookingType.COMPANY, BookingType.GUIDE].includes(bookingType)) {
		return services.filter((service) => service.invoiceableQuantity > 0);
	}
	return services.filter((services) => services.invoiceableQuantity === 0);
};

/**
 * Filter service with rate, includes and minimumQuantity >= 1
 * @param group Vehicle group
 * @example
 * service.minimumQuantity > 0 && service.maximumQuantity > 0
 */
export const getMandatoryServicesFromGroup = (group: IVehicleGroupAvailabilityAndPrice): IService[] => {
	const mandatoryServices: IService[] = [];
	group.services.forEach((service: IService) => {
		for (let index = 0; index < service.minimumQuantity && service.maximumQuantity > 0; index += 1) {
			mandatoryServices.push(service);
		}
	});
	return mandatoryServices;
};

/**
 * Filter initial services
 * - Package services
 * - Required services
 * - Include service
 *
 * @param provider Current use provider configuration
 * @param group Vehicle group
 * @param packageCode Package code selected (optional)
 */
export const getInitialServices = (
	provider: IProvider,
	group: IVehicleGroupAvailabilityAndPrice,
	packageCode?: string,
	currentServices: IService[] = [],
): IService[] => {
	// MANDATORY AND INCLUDES services
	let mandatoryServices: IService[] = [];
	currentServices
		.filter((current) => current.minimumQuantity === 0 && current.included === false && current.maximumQuantity > 0)
		// eslint-disable-next-line array-callback-return
		.map((service) => {
			const serviceToAdd = group.services.find((groupService) => groupService.code === service.code);
			if (serviceToAdd) {
				mandatoryServices.push(serviceToAdd);
			}
		});

	mandatoryServices = [...mandatoryServices, ...getMandatoryServicesFromGroup(group)];

	// SERVICES FROM PACKAGE
	if (packageCode) {
		const packageServices = getServicesFromPackage(group, packageCode);

		mandatoryServices = [
			...mandatoryServices.filter(
				(service) => !packageServices.some((pService) => pService.code === service.code),
			),
		];

		mandatoryServices = [...mandatoryServices, ...packageServices];
	}

	// CR BY PROVIDER CONFIG
	const crService = getCRService(group, mandatoryServices, provider);
	if (crService) {
		mandatoryServices.push(crService);
	}

	return mandatoryServices;
};

/**
 * Modify the services, marking the choosable ones
 *
 * @param services Services selected group
 * @param bookingPackage Selected package
 * @param provider Booking provider
 * @param creationMethod Booking creationMethod
 */
export const checkChoosableServices = (
	services: IService[],
	provider: IProvider,
	creationMethod: CreationMethod,
	bookingPackage?: IBookingPackage | null,
): IService[] => {
	let filterServices = services.map((service) => ({
		...service,
		choosable: service.maximumQuantity > 0 && service.choosable,
	}));

	// Exclude package services width maximumQuantity > 1
	if (bookingPackage) {
		const packageServicesCode = bookingPackage.services.map((serv) => serv.code);

		/**
		 * No servicios del paquete no son seleccionables, a menos que maximumQuantity > 1
		 */
		filterServices = filterServices.map((service) => ({
			...service,
			choosable:
				service.choosable &&
				(!packageServicesCode.includes(service.code) ||
					(packageServicesCode.includes(service.code) && service.maximumQuantity >= 1)),
		}));

		// FK y SK  solo seleccionable en premium
		const fkService = filterServices.find((service) => service.code === provider.fastKey);
		const skService = filterServices.find((service) => service.code === provider.smartKey);

		if (bookingPackage.code !== BookingPackageType.Premium) {
			const updateServices: IService[] = [];

			if (fkService) {
				updateServices.push({ ...fkService, choosable: false });
			}

			if (skService) {
				updateServices.push({ ...skService, choosable: false });
			}

			filterServices = [
				...filterServices.filter((service) => ![provider.fastKey, provider.smartKey].includes(service.code)),
				...updateServices,
			];
		}
	} else {
		const fkService = filterServices.find((service) => service.code === provider.fastKey);
		if (fkService && fkService.choosable) {
			filterServices = [
				...filterServices.filter((service) => service.code !== fkService.code),
				{ ...fkService, choosable: false },
			];
		}

		const skService = filterServices.find((service) => service.code === provider.smartKey);
		if (skService && skService.choosable) {
			filterServices = [
				...filterServices.filter((service) => service.code !== skService.code),
				{ ...skService, choosable: false },
			];
		}
	}

	// El SC no es seleccionable si creationMethod es WEB o APP
	const scService = filterServices.find((service) => service.code === provider.smartCover);
	if (scService && [CreationMethod.WEB, CreationMethod.APP].includes(creationMethod)) {
		filterServices = [
			...filterServices.filter((service) => service.code !== provider.smartCover),
			{ ...scService, choosable: false },
		];
	}

	return filterServices;
};

/**
 * Modify the extra driver service max quantity if in the booking package there is a EDFREE extra
 * @param services Services selected group
 * @param bookingPackage Selected package
 * @returns List of services with extra driver max quantity changed
 */
export const changeEDMaxQuantityServices = (services: IService[], bookingPackage?: IBookingPackage): IService[] => {
	if (!services || !services.length) {
		return [];
	}

	const filteredList = [...services];
	if (bookingPackage) {
		const bookingPackageServiceCodes = bookingPackage.services.map((serv) => serv.code);

		const packageServices = services.filter((service) => bookingPackageServiceCodes.includes(service.code));
		const extraDriverPackageServices = packageServices.filter(
			(service) => service.ed && service.maximumQuantity > 0,
		);

		// Si el paquete tiene estra driver incluido, se cambia la cantidad máxima de ED
		if (extraDriverPackageServices.length > 0) {
			// Con uno de los servicios para saber la cantidad máxima que pueden añadirse
			const [edService] = extraDriverPackageServices;

			// Cantidad máxima de ED se calcula restando los ED que ya están en el paquete
			const maximumQuantityEdServices = edService.maximumQuantity - extraDriverPackageServices.length;

			// Modificamos la cantidad máxima de ED en los servicios, solo si
			return filteredList.map((service) => {
				if (service.ed) {
					return {
						...service,
						maximumQuantity:
							service.maximumQuantity > maximumQuantityEdServices
								? maximumQuantityEdServices
								: service.maximumQuantity,
					};
				}

				return service;
			});
		}
	}

	return filteredList;
};

/**
 * Merge list of services and services amount
 * @param services Service list
 * @param servicesPrice Service price list
 * @returns List of services with update amount
 */
export const mergeServiceAndServicePrice = (services: IService[], servicesPrice: IServicePrice[]): IService[] =>
	services.map((service) => ({
		...service,
		...servicesPrice.find((servicePrice) => servicePrice.code === service.code),
	}));

/**
 * List of services corresponding to the list of lines of the booking
 * @param bookingLines Current booking lines
 * @param vehicleGroup Current booking vehicle group
 */

/**
 * Adds name and price to a service list (services only have the code)
 * @param servicesCode List of services to merge
 * @param servicesPrice Service price list
 * @param services List of all services
 * @returns List of services with update amount and name
 */
export const mergeServiceCodeAndServicePriceAndServiceName = (
	servicesCode: IService[],
	servicesPrice: IServicePrice[],
	services: IService[],
): IService[] =>
	servicesCode.map((service) => ({
		...service,
		...services.find((servicePrice) => servicePrice.code === service.code),
		...servicesPrice.find((servicePrice) => servicePrice.code === service.code),
	}));

/**
 * List of services corresponding to the list of lines of the booking
 * @param bookingLines Current booking lines
 * @param vehicleGroup Current booking vehicle group
 */

export const getServicesFromBookingLines = (
	bookingLines: IBookingLine[],
	vehicleGroup: IVehicleGroupAvailabilityAndPrice,
): IService[] => {
	if (vehicleGroup && vehicleGroup.services.length > 0) {
		const { services } = vehicleGroup;
		const currentServices: IService[] = [];

		const bookingLinesCode = bookingLines
			.filter((line) => line.bookingLineType === BookingLineType.Service)
			.map((line) => line.code);

		bookingLinesCode.forEach((code) => {
			const currentService = services.find((service) => service.code === code);

			if (currentService) {
				currentServices.push(currentService);
			}
		});

		return currentServices;
	}

	return [];
};
