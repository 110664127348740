import type { FC } from 'react';

import { Field, Form as FormContainer } from 'react-final-form';
import { Link } from 'react-router-dom';

import { isRequired } from '@crac/core/helpers/formValidators/isRequired';
import type { TextsTranlationType } from '@crac/i18n';
import { getStaticTexts } from '@crac/i18n';

import { LoginAcceptPrivacyPolicy, LoginTitle } from './Login.styled';
import { LoginContainer } from './LoginContainer';
import { LoginSwitchLanguage } from './LoginSwitchLanguage';
import type { IInitialStateProps, ILoginProps, ISubmitButtonConfig } from './types';
import { Button } from '../../../external/reactstrap/button';
import { Form } from '../../../external/reactstrap/form';
import { Label } from '../../../external/reactstrap/label';
import { BaseComponentValues } from '../../../types/IComponentBaseProps';
import { InputFormField } from '../form/inputFormField';
import { Logo } from '../logo';

const defaultSubmitButton = (texts: TextsTranlationType): ISubmitButtonConfig => ({
	align: 'end',
	block: false,
	text: texts.login,
});

export const Login: FC<ILoginProps> = ({
	inputs,
	onForgotPassword,
	onShowPrivacyPolicy,
	onSubmit,
	showLogo = true,
	submitButton,
	subtitle,
	renderCustomElement,
	locale = BaseComponentValues.locale as string,
	onChangeLanguage,
	showSwitchLanguage,
	isLoading,
	className,
	renderAfterSubmitButton,
	initialState,
}) => {
	// Const texts = getStaticTexts(locale);
	const texts = getStaticTexts(locale as string);

	const submitButtonProps: ISubmitButtonConfig = {
		...defaultSubmitButton(texts),
		...submitButton,
	};

	const { inputPassword, inputUserName } = inputs;
	const formInitialState: IInitialStateProps = {
		acceptPrivacyPolicy: initialState?.acceptPrivacyPolicy ?? false,
		ethicalCode: initialState?.ethicalCode ?? false,
		[inputPassword ? inputPassword.name : 'password']: initialState?.password ?? undefined,
		[inputUserName.name]: initialState?.userName ?? undefined,
	};

	const showPrivacy = (): JSX.Element => (
		<Label htmlFor="acceptPrivacyPolicy">
			{texts.acceptPrivacyPolicy}&nbsp;
			<Button className="m-0 p-0 pb-1 border-0" color="link" onClick={onShowPrivacyPolicy}>
				{`${texts.privacyPolicy}`.toLocaleLowerCase()}
			</Button>
		</Label>
	);

	const ethicalCode = (): JSX.Element => (
		<Label htmlFor="ethicalCode">
			{texts.acceptEthicalCode}&nbsp;
			<Link target="_blank" to={texts.ethicalCodeLink}>
				{texts.ethicalCode}
			</Link>
		</Label>
	);

	if (!inputs) {
		return <p className="text-danger">Debes configurar el input username y password</p>;
	}

	return (
		<LoginContainer className={className} data-test="login">
			{showLogo ? <Logo /> : null}
			{subtitle ? <LoginTitle className="text-muted">{subtitle}</LoginTitle> : null}
			<FormContainer
				initialValues={formInitialState}
				onSubmit={onSubmit}
				subscription={{ invalid: true, pristine: true, submitting: true, values: true }}
			>
				{({ handleSubmit, submitting, pristine, invalid }) => {
					return (
						<Form
							aria-label="login-form"
							autoComplete="off"
							data-test="form"
							disabled={submitting || isLoading}
							onSubmit={handleSubmit}
						>
							<>
								{inputUserName ? (
									<Field
										autofocus
										component={InputFormField}
										data-test="field-userName"
										id={inputUserName.name}
										label={inputUserName.label}
										name={inputUserName.name}
										placeholder={inputUserName.placeholder}
										required
										type="text"
										validate={isRequired()}
									/>
								) : null}
								{inputPassword ? (
									<Field
										autoComplete="new-password"
										component={InputFormField}
										data-test="field-password"
										id={inputPassword.name}
										label={inputPassword.label}
										name={inputPassword.name}
										placeholder={inputPassword.placeholder}
										required
										type="password"
										validate={isRequired()}
									/>
								) : null}
								{onForgotPassword ? (
									<Button className="mb-1 p-0" color="link" onClick={onForgotPassword}>
										Forgot password?
									</Button>
								) : null}

								{onShowPrivacyPolicy ? (
									<LoginAcceptPrivacyPolicy className="policy-check">
										<div className="mb-1">
											<Field
												className="mb-0 mt-1"
												component={InputFormField}
												id="acceptPrivacyPolicy"
												label={showPrivacy}
												name="acceptPrivacyPolicy"
												required
												type="checkbox"
												validate={isRequired(texts.required, false)}
											/>
										</div>
										<Field
											className="mt-0 mt-1"
											component={InputFormField}
											id="ethicalCode"
											label={ethicalCode}
											name="ethicalCode"
											required
											type="checkbox"
											validate={isRequired(texts.required, false)}
										/>
									</LoginAcceptPrivacyPolicy>
								) : null}

								{renderCustomElement ? renderCustomElement() : null}

								<div className={`d-flex justify-content-${submitButtonProps.align}`}>
									<Button
										color="primary"
										data-test="button-submit"
										isBlock={submitButtonProps.block}
										isDisabled={invalid || pristine || submitting || isLoading}
										type="submit"
									>
										{submitButtonProps.icon ? (
											<i className={`fa fa-fw fa-${submitButtonProps.icon}`} />
										) : null}
										{submitButtonProps.text ? submitButtonProps.text : texts.login}
										{submitting || isLoading ? (
											<i className="fa fa-fw fa-pulse fa-spinner" />
										) : null}
									</Button>
								</div>
								{renderAfterSubmitButton ?? null}
								{showSwitchLanguage ? (
									<LoginSwitchLanguage currentLocale={locale as string} onChange={onChangeLanguage} />
								) : null}
							</>
						</Form>
					);
				}}
			</FormContainer>
		</LoginContainer>
	);
};
