import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const ExcessRate = lazyWithRetry(() => import('./pages/ExcessRate'));
const ExcessRateNew = lazyWithRetry(() => import('./pages/ExcessRateNew'));
const ExcessRateModify = lazyWithRetry(() => import('./pages/ExcessRateModify'));

export const excessRateRoutes: { [key: number]: IRouteConfig } = {};

excessRateRoutes[routesIdMap.WorkshopExcessRate] = {
	component: ExcessRate,
	exact: true,
	id: routesIdMap.WorkshopExcessRate,
	name: 'Excess rate',
	path: '/workshop/excess-rate',
	permissions: [PermissionsType.ExcessRate],
	icon: 'bolt',
	childrens: [routesIdMap.WorkshopExcessRateNew, routesIdMap.WorkshopExcessRateModify],
	menuText: 'Excess rate',
};

excessRateRoutes[routesIdMap.WorkshopExcessRateNew] = {
	component: ExcessRateNew,
	exact: true,
	id: routesIdMap.WorkshopExcessRateNew,
	name: 'New excess rate',
	path: '/workshop/excess-rate/new',
	permissions: [PermissionsType.ExcessRate],
	isChild: true,
	menuText: 'New excess rate',
	icon: 'plus',
};

excessRateRoutes[routesIdMap.WorkshopExcessRateModify] = {
	component: ExcessRateModify,
	exact: true,
	id: routesIdMap.WorkshopExcessRateModify,
	name: 'Modify excess rate',
	path: '/workshop/excess-rate/modify',
	permissions: [PermissionsType.ExcessRate],
	isChild: true,
	icon: 'pencil',
};
