/* eslint-disable sort-keys */
export const EnumEnText = {
	// BookingPackageType
	Basic: 'Basic',
	Comfort: 'Comfort',
	Premium: 'Premium',

	// BookingRegularizationConceptType
	Deposit: 'Deposit',
	Discount: 'Discount',
	Franchise: 'Franchise',
	Fuel: 'Fuel',
	KM: 'KM',
	Rent: 'Rent',
	Service: 'Service',

	// BookingStateType
	Draft: 'Draft',
	Confirmed: 'Confirmed',
	Processing: 'Processing',
	OnHire: 'On hire',
	Pending: 'Pending',
	Liquidated: 'Liquidated',
	Cancelled: 'Cancelled',
	CancelledDueToUnavailability: 'Cancelled due to unavailability',
	NoShow: 'No show',
	StopSale: 'Stop sale',
	Denied: 'Denied',

	// CustomerType
	Conflicted: 'Conflicted',
	GoldClub: 'GoldClub',
	Guide: 'Guide',
	Normal: 'Normal',
	Sale: 'Sale',

	// DocumentType
	Contract: 'Contract',
	Documentation: 'Documentation',
	Insurance: 'Insurance',
	Invoice: 'Invoice',
	CreditNote: 'Credit note',
	InitialContract: 'Initial contract',

	// DocumentPendingType
	PAYMENT: 'Payment',
	INVOICE: 'Invoice',
	CREDITMEMO: 'Credit memo',
	FINANCECHARGEMEMO: 'Finance charge memo',
	REMINDER: 'Reminder',
	BILL: 'Bill',

	// FineStateType
	Charged: 'Charged',
	AttemptToCharge: 'Attempt to charge',
	DoNotChargeCliente: "Don't charge customer",

	Petrol: 'Petrol',
	Diesel: 'Diesel',
	Electric: 'Electric',
};

export const EnumEsText = {
	// BookingPackageType
	Basic: 'Basic',
	Comfort: 'Comfort',
	Premium: 'Premium',

	// BookingRegularizationConceptType
	Deposit: 'Deposito',
	Discount: 'Descuento',
	Franchise: 'Franquicia',
	Fuel: 'Combustible',
	KM: 'KM',
	Rent: 'Alquiler',
	Service: 'Servicio',

	// BookingStateType
	Draft: 'Borrador',
	Confirmed: 'Confirmada',
	Processing: 'En proceso',
	OnHire: 'En alquiler',
	Pending: 'Pendiente',
	Liquidated: 'Liquidada',
	Cancelled: 'Cancelada',
	CancelledDueToUnavailability: 'Cancelada por no disponibilidad',
	NoShow: 'No show',
	StopSale: 'Parada de venta',
	Denied: 'Denegada',

	// CustomerType
	Conflicted: 'Conflictivo',
	GoldClub: 'Gold club',
	Guide: 'Guía',
	Normal: 'Normal',
	Sale: 'Venta vehículo',

	// DocumentType
	Contract: 'Contrato',
	Documentation: 'Documentación',
	Insurance: 'Seguro',
	Invoice: 'Factura',
	CreditNote: 'Nota de crédito',
	InitialContract: 'Contrato inicial',

	// DocumentPendingType
	PAYMENT: 'Pago',
	INVOICE: 'Factura',
	CREDITMEMO: 'Nota de crédito',
	FINANCECHARGEMEMO: 'Cargo financiero',
	REMINDER: 'Recordatorio',
	BILL: 'Cuenta',

	// FineStateType

	Charged: 'Cargado',
	AttemptToCharge: 'Intento de cobro',
	DoNotChargeCliente: 'No cobrar al cliente',

	Petrol: 'Gasolina',
	Diesel: 'Diesel',
	Electric: 'Eléctrico',
};
