export enum InsuranceRateEndPoints {
	/**
	 * Insurance Rate search
	 * @host `/insuranceRate/search/`
	 */
	SEARCH = '/insuranceRate/search/',
	/**
	 * Insurance Rate insert
	 * @host `/insuranceRate/insert/`
	 */
	INSERT = '/insuranceRate/insert/',
	/**
	 * Insurance Rate modify
	 * @host `/insuranceRate/modify/`
	 */
	MODIFY = '/insuranceRate/modify/',
	/**
	 * Insurance Rate deactivate
	 * @host `/insuranceRate/deactivate/`
	 */
	DEACTIVATE = '/insuranceRate/deactivate/',
}
