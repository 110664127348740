import type { IMaximumBookingDuration } from '../../models/entities/MaximumBookingDuration';
import type {
	IMaximumBookingDurationDeactivateParams,
	IMaximumBookingDurationInsertParams,
	IMaximumBookingDurationSearchParams,
} from '../../models/serviceParams/MaximumBookingDurationParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { MaximumBookingDurationEndPoints } from '../endPoints/MaximumBookingDurationEndPoints';

/**
 * Search maximum booking durations
 */
export const maximumBookingDurationServiceSearch = (
	model: IMaximumBookingDurationSearchParams,
): Promise<ServiceResponse<IMaximumBookingDuration[]>> => {
	return Api.get<IMaximumBookingDuration[], IMaximumBookingDurationSearchParams>(
		MaximumBookingDurationEndPoints.SEARCH,
		model,
	);
};

/**
 * Insert maximum booking durations
 */
export const maximumBookingDurationServiceInsert = (
	model: IMaximumBookingDurationInsertParams,
): Promise<ServiceResponse<IMaximumBookingDuration[]>> => {
	return Api.post<IMaximumBookingDuration[], IMaximumBookingDurationInsertParams>(
		MaximumBookingDurationEndPoints.INSERT,
		model,
	);
};

/**
 * Deactivate maximum booking durations
 */
export const maximumBookingDurationServiceDeactivate = (
	model: IMaximumBookingDurationDeactivateParams,
): Promise<ServiceResponse<IMaximumBookingDuration[]>> => {
	return Api.post<IMaximumBookingDuration[], IMaximumBookingDurationDeactivateParams>(
		MaximumBookingDurationEndPoints.DEACTIVATE,
		model,
	);
};
