import type { FC } from 'react';
import React from 'react';

import {
	FullPageLoaderContainer,
	FullPageLoaderContent,
	FullPageLoaderIcon,
	FullPageLoaderMessage,
} from './FullPageLoader.styled';

interface IFullPageLoaderProps {
	isVisible: boolean;
	message?: string;
	icon?: string;
}

export const FullPageLoader: FC<IFullPageLoaderProps> = ({ isVisible, message = '', icon = 'fa-spinner fa-pulse' }) => {
	if (isVisible) {
		return (
			<FullPageLoaderContainer data-testid="fullPageLoader">
				<FullPageLoaderContent>
					<FullPageLoaderIcon>
						<i className={`fa ${icon} fa-5x fa-fw`} />
					</FullPageLoaderIcon>
					{message ? <FullPageLoaderMessage>{message}</FullPageLoaderMessage> : null}
				</FullPageLoaderContent>
			</FullPageLoaderContainer>
		);
	}

	return null;
};
