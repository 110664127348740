import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Accident = lazyWithRetry(() => import('./pages/Accidents'));

export const accidentsRoutes: { [key: number]: IRouteConfig } = {};

accidentsRoutes[routesIdMap.BookingsDetailAccident] = {
	component: Accident,
	exact: true,
	id: routesIdMap.BookingsDetailAccident,
	name: 'Accident reports',
	path: '/bookings/detail/:id/accidents',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: 'file-text-o',
};

accidentsRoutes[routesIdMap.BookingsDetailAccidentDetail] = {
	component: Accident,
	exact: true,
	id: routesIdMap.BookingsDetailAccidentDetail,
	name: 'Accident reports detail',
	path: '/bookings/detail/:id/accidents/:document',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: 'file-text-o',
};
