import { combineReducers } from '@reduxjs/toolkit';

import { accidentReducer as Accidents } from './detail/accidents/state/slice';
import { bookingsReducer as Bookings } from './detail/bookings/state/slice';
import { availablesReducer as Availables } from './detail/detail/state/slices/availables';
import { changeStateReducer as ChangeState } from './detail/detail/state/slices/changeState';
import { detailReducer as Detail } from './detail/detail/state/slices/detail';
import { linesReducer as Lines } from './detail/detail/state/slices/lines';
import { expedientsReducer as Expedients } from './detail/expedients/state/slice';
import { insurancesReducer as Insurances } from './detail/insurances/state/slice';
import { tollsReducer as Tolls } from './detail/tolls/state/slice';
import { keyChainsLabelReducer as KeyChainsLabels } from './keyChainsLabels/state/slice';
import { lockerReducer as Lockers } from './lockers/state/slice';
import { preparationReducer as Preparation } from './preparation/state/slice';
import { receptionReducer as Reception } from './reception/state/slice';
import { returnReducer as Return } from './return/state/slice';
import { revisionReducer as Revision } from './revision/state/slice';
import { searchReducer as Search } from './search/state/slice';

export const vehicleReducer = combineReducers({
	Accidents,
	Availables,
	Bookings,
	ChangeState,
	Detail,
	Expedients,
	Insurances,
	KeyChainsLabels,
	Lines,
	Lockers,
	Preparation,
	Reception,
	Return,
	Revision,
	Search,
	Tolls,
});
