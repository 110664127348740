import { createSlice } from '@reduxjs/toolkit';

import {
	connectedVehicleClear,
	connectedVehicleSearch,
	vehicleEnroll,
	vehicleUnEnroll,
	vehiclesGetTelemetry,
	vehiclesGetTelemetryClear,
} from '@crac/core/modules/vehicle/state/actions/ConnectedVehiclesActions';
import type { IConnectedVehiclesStateType } from '@crac/core/modules/vehicle/state/stateType/ConnectedVehiclesStateType';

const initialState: IConnectedVehiclesStateType = {
	connectedVehiclesSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	connectedVehiclesGetTelemetryRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicles: [],
	vehicleTelemetry: null,
	vehicleEnrollRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleUnEnrollRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const connectedVehiclesSlice = createSlice({
	name: 'connectedVehiclesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder
			.addCase(connectedVehicleClear, (state) => {
				return {
					...state,
					vehicles: [],
					connectedVehiclesSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehiclesGetTelemetryClear, (state) => {
				return {
					...state,
					vehicleTelemetry: null,
				};
			});

		// SEARCH
		builder
			.addCase(connectedVehicleSearch.pending, (state) => {
				return {
					...state,
					connectedVehiclesSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(connectedVehicleSearch.fulfilled, (state, action) => {
				return {
					...state,
					vehicles: action.payload,
					connectedVehiclesSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(connectedVehicleSearch.rejected, (state, action) => {
				return {
					...state,
					connectedVehiclesSearchRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});

		// TELEMETRY
		builder
			.addCase(vehiclesGetTelemetry.pending, (state) => {
				return {
					...state,
					vehicleTelemetry: null,
					connectedVehiclesGetTelemetryRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehiclesGetTelemetry.fulfilled, (state, action) => {
				return {
					...state,
					vehicleTelemetry: action.payload,
					connectedVehiclesGetTelemetryRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehiclesGetTelemetry.rejected, (state, action) => {
				return {
					...state,
					connectedVehiclesGetTelemetryRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});

		// UNROLL
		builder
			.addCase(vehicleEnroll.pending, (state) => {
				return {
					...state,
					vehicleEnrollRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleEnroll.rejected, (state, action) => {
				return {
					...state,
					vehicleEnrollRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(vehicleEnroll.fulfilled, (state, action) => {
				const plateNumbers = action.payload.map((vehicle) => vehicle.plateNumber);

				return {
					...state,
					vehicles: [
						...state.vehicles.filter((vehicle) => !plateNumbers.includes(vehicle.plateNumber)),
						...action.payload,
					],
					vehicleEnrollRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			});

		// UNENROLL
		builder
			.addCase(vehicleUnEnroll.pending, (state) => {
				return {
					...state,
					vehicleUnEnrollRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleUnEnroll.rejected, (state, action) => {
				return {
					...state,
					vehicleUnEnrollRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(vehicleUnEnroll.fulfilled, (state, action) => {
				const plateNumbers = action.payload.map((vehicle) => vehicle.plateNumber);

				return {
					...state,
					vehicles: [
						...state.vehicles.filter((vehicle) => !plateNumbers.includes(vehicle.plateNumber)),
						...action.payload,
					],
					vehicleUnEnrollRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			});
	},
});

export const connectedVehiclesReducer = connectedVehiclesSlice.reducer;
