import { countryServiceGetAll, countryServiceGetByCode } from '../../data/services/CountryService';
import type { ICountry } from '../../models/entities/Country';
import type { ICountryGetByCodeParams } from '../../models/serviceParams/CountryParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Retrieves a country by its code.
 *
 * @param {ICountry} payload - The country to be retrieved.
 * @param {ICountryGetByCodeParams} params - Parameters to identify the country by its code.
 * @returns {Promise<void>} - A promise that resolves to the country matching the given code.
 */
export const countryGetByCode = createAsyncAction<ICountry, ICountryGetByCodeParams>(
	'country/getByCode',
	countryServiceGetByCode,
);

/**
 * Retrieves all countries.
 *
 * @param {ICountry[]} payload - The countries to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters, if any are needed.
 * @returns {Promise<void>} - A promise that resolves to an array of all countries.
 */
export const countryGetAll = createAsyncAction<ICountry[], IServiceBaseParams>('country/getAll', countryServiceGetAll);
