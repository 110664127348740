import { createSlice } from '@reduxjs/toolkit';

import {
	serviceRateTypeClear,
	serviceRateTypeInsert,
	serviceRateTypeModify,
	serviceRateTypesSearch,
} from '@crac/core/redux/actions/ServiceRateActions';
import type { IServiceRateTypeReducerState } from '@crac/core/redux-store/reducersState/pricing/ServiceRateTypeReducerState';

const initialState: IServiceRateTypeReducerState = {
	serviceRateTypeInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	serviceRateTypeModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	serviceRateTypes: [],
	serviceRateTypesSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const serviceRateSlice = createSlice({
	name: 'serviceRate',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(serviceRateTypeClear, (state) => {
			return {
				...state,
				serviceRateTypes: [],
			};
		});
		builder.addCase(serviceRateTypesSearch.pending, (state) => {
			return {
				...state,
				serviceRateTypesSearchRequest: {
					ok: true,
					inProgress: true,
					messages: [],
				},
			};
		});
		builder.addCase(serviceRateTypesSearch.rejected, (state, action) => {
			return {
				...state,
				serviceRateTypesSearchRequest: {
					ok: false,
					inProgress: false,
					messages: action.payload || [],
				},
			};
		});
		builder.addCase(serviceRateTypesSearch.fulfilled, (state, action) => {
			return {
				...state,
				serviceRateTypesSearchRequest: {
					ok: true,
					inProgress: false,
					messages: [],
				},
				serviceRateTypes: action.payload,
			};
		});
		builder.addCase(serviceRateTypeInsert.pending, (state) => {
			return {
				...state,
				serviceRateTypeInsertRequest: {
					ok: true,
					inProgress: true,
					messages: [],
				},
			};
		});
		builder.addCase(serviceRateTypeInsert.rejected, (state, action) => {
			return {
				...state,
				serviceRateTypeInsertRequest: {
					ok: false,
					inProgress: false,
					messages: action.payload || [],
				},
			};
		});
		builder.addCase(serviceRateTypeInsert.fulfilled, (state) => {
			return {
				...state,
				serviceRateTypeInsertRequest: {
					ok: true,
					inProgress: false,
					messages: [],
				},
			};
		});
		builder.addCase(serviceRateTypeModify.pending, (state) => {
			return {
				...state,
				serviceRateTypeModifyRequest: {
					ok: true,
					inProgress: true,
					messages: [],
				},
			};
		});
		builder.addCase(serviceRateTypeModify.rejected, (state, action) => {
			return {
				...state,
				serviceRateTypeModifyRequest: {
					ok: false,
					inProgress: false,
					messages: action.payload || [],
				},
			};
		});
		builder.addCase(serviceRateTypeModify.fulfilled, (state, action) => {
			return {
				...state,
				serviceRateTypes: state.serviceRateTypes.map((serviceRateType) => {
					if (serviceRateType.code === action.payload.code) {
						return action.payload;
					}
					return serviceRateType;
				}),
				serviceRateTypeModifyRequest: {
					ok: true,
					inProgress: false,
					messages: [],
				},
			};
		});
	},
});

export const serviceRateTypeReducer = serviceRateSlice.reducer;
