import type { FC } from 'react';
import React from 'react';

import { NavItem as RSNavItem } from 'reactstrap';
import type { NavItemProps as RSNavItemProps } from 'reactstrap/types/lib/NavItem';

export type NavItemProps = RSNavItemProps;

export const NavItem: FC<NavItemProps> = ({ children, ...props }): JSX.Element => {
	return <RSNavItem {...props}>{children}</RSNavItem>;
};
