import { combineReducers } from 'redux';

import { feedBackReducer as FavoriteRoutes } from './FavoriteRoutes';
import { feedBackReducer as Feedback } from './FeedBack';
import { loaderReducer as Loader } from './Loader';
import { permissionsToolReducer as Permissions } from './Permissions';

export const commonReducers = combineReducers({
	Feedback,
	Loader,
	Permissions,
	FavoriteRoutes,
});
