import { repairTypeServiceGetAll } from '../../data/services/RepairTypeService';
import type { IRepairType } from '../../models/entities/RepairType';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Retrieves all repair types.
 *
 * @param {IRepairType[]} payload - The repair types to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters, potentially used for filtering or configuration.
 * @returns {Promise<void>} - A promise that resolves to an array of all repair types.
 */
export const repairTypeGetAll = createAsyncAction<IRepairType[], IServiceBaseParams>(
	'repairType/getAll',
	repairTypeServiceGetAll,
);
