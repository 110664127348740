export const colors = {
	bgLight: '#f0f3f5',
	bgLightBorderColor: '#dee2e6',
	blue: '#001e96',
	blueLow: '#0021a5',
	border: '#eef3f5b3',
	danger: '#f86c6b',
	disabled: '#c1c1c1',
	gbLightBorder: '#dee8ec',
	textMuted: '#536c79',
	warning: '#ffc107',
	yellow: '#ffc82e',
	yellowLow: '#ffe396',
};

/*
 * Export const GlobalStyle = (props: typeof CustomDarkTheme) => {
 * 	const { colors, typography } = props;
 * 	return createGlobalStyle`
 * 		body {
 * 			font-family: ${typography.fontFamilyBase};
 * 			color: ${colors.primary};
 * 		}
 * 	`;
 * };
 */

export enum breakpoints {
	xs = 0,
	sm = '576px',
	md = '768px',
	lg = '992px',
	xl = ' 1200px',
}

export const mediaQueries = (key: keyof typeof breakpoints) => {
	return (style: TemplateStringsArray | string): string => `@media (min-width: ${breakpoints[key]}) { ${style} }`;
};
