import { createAction } from '@reduxjs/toolkit';

import {
	cashLineServiceGetByCode,
	cashLineServiceGetByDay,
	cashLineServiceGetCurrent,
	cashLineServiceGetPending,
	cashLineServiceModify,
	cashLineServiceSign,
} from '../../data/services/CashLineService';
import type { ICashLine } from '../../models/entities/CashLine';
import type {
	ICashLineGetByCodeParams,
	ICashLineGetByDayParams,
	ICashLineModifyParams,
	ICashLineSignParams,
} from '../../models/serviceParams/CashLineParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Retrieves the current cash line.
 *
 * @param {ICashLine} payload - The current cash line to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters.
 * @returns {Promise<void>} - A promise that resolves to the current cash line.
 */
export const cashLineGetCurrent = createAsyncAction<ICashLine, IServiceBaseParams | undefined>(
	'cashLine/getCurrent',
	cashLineServiceGetCurrent,
);

/**
 * Retrieves pending cash lines.
 *
 * @param {ICashLine[]} payload - The pending cash lines to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters.
 * @returns {Promise<void>} - A promise that resolves to an array of pending cash lines.
 */
export const cashLineGetPending = createAsyncAction<ICashLine[], IServiceBaseParams | undefined>(
	'cashLine/getPending',
	cashLineServiceGetPending,
);

/**
 * Retrieves cash lines for a specific day.
 *
 * @param {ICashLine[]} payload - The cash lines of the specified day to be retrieved.
 * @param {ICashLineGetByDayParams} params - Parameters to identify the day for which cash lines are to be retrieved.
 * @returns {Promise<void>} - A promise that resolves to an array of cash lines for the specified day.
 */
export const cashLineGetByDay = createAsyncAction<ICashLine[], ICashLineGetByDayParams>(
	'cashLine/getByDay',
	cashLineServiceGetByDay,
);

/**
 * Retrieves a specific cash line by its code.
 *
 * @param {ICashLine} payload - The cash line to be retrieved.
 * @param {ICashLineGetByCodeParams} params - Parameters to identify the cash line by its code.
 * @returns {Promise<void>} - A promise that resolves to the specified cash line.
 */
export const cashLineGetByCode = createAsyncAction<ICashLine, ICashLineGetByCodeParams>(
	'cashLine/getByCode',
	cashLineServiceGetByCode,
);

/**
 * Modifies an existing cash line.
 *
 * @param {ICashLine} payload - The cash line to be modified.
 * @param {ICashLineModifyParams} params - Parameters for modifying the cash line.
 * @returns {Promise<void>} - A promise that resolves after the cash line is modified.
 */
export const cashLineModify = createAsyncAction<ICashLine, ICashLineModifyParams>(
	'cashLine/modify',
	cashLineServiceModify,
);

/**
 * Signs a cash line.
 *
 * @param {ICashLine} payload - The cash line to be signed.
 * @param {ICashLineSignParams} params - Parameters for signing the cash line.
 * @returns {Promise<void>} - A promise that resolves after the cash line is signed.
 */
export const cashLineSign = createAsyncAction<ICashLine, ICashLineSignParams>('cashLine/sign', cashLineServiceSign);

/**
 *Clears the cash line state.
 */
export const cashLineClear = createAction('cashLine/clear');
