export enum TollEndPoints {
	/**
	 * Get by booking
	 * @host `/toll/getbybooking/`
	 */
	GET_BY_BOOKING = '/toll/getbybooking/',

	/**
	 * Get by plateNumber
	 * @host `/toll/getbyplatenumber/`
	 */
	GET_BY_PLATENUMBER = '/toll/getbyplatenumber/',
}
