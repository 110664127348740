import { createAction } from '@reduxjs/toolkit';

import {
	couponServiceGetByBookingNumber,
	couponServiceGetByCustomerCode,
	couponServiceInsert,
	couponServiceInsertCallBack,
} from '../../data/services/CouponService';
import type { ICoupon } from '../../models/entities/Coupon';
import type {
	ICouponGetByBookingNumberParams,
	ICouponGetByCustomerCodeParams,
	ICouponInsertCallBackParams,
	ICouponInsertParams,
} from '../../models/serviceParams/CouponParams';
import type { ICouponInsertResponse } from '../../models/serviceResponse/CouponResponse';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves coupons associated with a specific customer code.
 *
 * @param {ICoupon[]} payload - The coupons to be retrieved.
 * @param {ICouponGetByCustomerCodeParams} params - Parameters to identify coupons related to a specific customer code.
 * @returns {Promise<void>} - A promise that resolves to an array of coupons.
 */
export const couponGetByCustomerCode = createAsyncAction<ICoupon[], ICouponGetByCustomerCodeParams>(
	'coupon/getByCustomerCode',
	couponServiceGetByCustomerCode,
);

/**
 * Retrieves coupons associated with a specific booking number.
 *
 * @param {ICoupon[]} payload - The coupons to be retrieved.
 * @param {ICouponGetByBookingNumberParams} params - Parameters to identify coupons related to a specific booking number.
 * @returns {Promise<void>} - A promise that resolves to an array of coupons.
 */
export const couponGetByBookingNumber = createAsyncAction<ICoupon[], ICouponGetByBookingNumberParams>(
	'coupon/getByBookingNumber',
	couponServiceGetByBookingNumber,
);

/**
 * Inserts a new coupon.
 *
 * @param {ICouponInsertResponse} payload - The response after inserting the coupon.
 * @param {ICouponInsertParams} params - Parameters detailing the coupon to be inserted.
 * @returns {Promise<void>} - A promise that resolves with the details of the inserted coupon.
 */
export const customerCouponInsert = createAsyncAction<ICouponInsertResponse, ICouponInsertParams>(
	'coupon/insert',
	couponServiceInsert,
);

/**
 * Handles the callback after inserting a coupon.
 *
 * @param {ICouponInsertResponse} payload - The response from the callback.
 * @param {ICouponInsertCallBackParams} params - Parameters from the insert callback.
 * @returns {Promise<void>} - A promise that resolves with the outcome of the callback process.
 */
export const customerCouponInsertCallBack = createAsyncAction<ICouponInsertResponse, ICouponInsertCallBackParams>(
	'coupon/insertCallBack',
	couponServiceInsertCallBack,
);

/**
 * Resets any errors related to coupon insertion.
 */
export const customerCouponInsertResetErrors = createAction('coupon/insertResetErrors');
