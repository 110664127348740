import type { FC } from 'react';
import React from 'react';

import type { ModalSize } from '.';

import { GcModalClose, GcModalContent, GcModalOverlay, GcModalWraper } from './styles';

interface IModalSlideProps {
	isOpen?: boolean;
	onClose: () => void;
	children?: React.ReactNode;
	size: ModalSize;
}

export const ModalSlide: FC<IModalSlideProps> = ({ children, isOpen, onClose, size }) => {
	if (!isOpen) {
		return null;
	}

	const handleOnClose = (): void => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<div className="gc-modal--slider">
			<GcModalOverlay className="gc-modal__overlay" onClick={handleOnClose} />
			<GcModalWraper
				className="gc-modal__wraper p-3 ms-auto position-relative"
				lg={size.lg}
				md={size.md}
				sm={size.sm}
			>
				<GcModalClose
					aria-label="close-modal"
					className="gc-modal__close"
					color="primary"
					onClick={handleOnClose}
				>
					<i className="fa fa-remove" />
				</GcModalClose>
				<GcModalContent className="gc-modal__content">
					<div className="gc-modal__content-inner">
						<div className="gc-modal__content-main">{children}</div>
					</div>
				</GcModalContent>
			</GcModalWraper>
		</div>
	);
};
