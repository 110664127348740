'use client';
import React from 'react';
import type { FC } from 'react';

import classNames from 'classnames';
import { Button as RSButton } from 'reactstrap';
import type { ButtonProps as RSButtonProps } from 'reactstrap/types/lib/Button';

export type ButtonProps = Exclude<RSButtonProps, 'outline' | 'disabled' | 'active' | 'block'> & {
	isDisabled?: boolean;
	isRounded?: boolean;
	isOutLine?: boolean;
	isBlock?: boolean;
	isActive?: boolean;
};

export const Button: FC<ButtonProps> = ({
	children,
	isDisabled = false,
	isOutLine = false,
	isRounded = false,
	isBlock = false,
	isActive = false,
	size = 'md',
	className,
	...props
}) => {
	return (
		<RSButton
			{...props}
			active={isActive}
			block={isBlock}
			className={classNames(className, {
				rounded: isRounded,
			})}
			disabled={isDisabled}
			outline={isOutLine}
			size={size}
		>
			{children}
		</RSButton>
	);
};
