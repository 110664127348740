import {
	maximumExcessRateServiceDeactivate,
	maximumExcessRateServiceInsert,
	maximumExcessRateServiceSearch,
} from '../../data/services/MaximumExcessRateService';
import type { IMaximumExcessRate } from '../../models/entities/MaximumExcessRate';
import type {
	IMaximumExcessRateDeactivateParams,
	IMaximumExcessRateInsertParams,
	IMaximumExcessRateSearchParams,
} from '../../models/serviceParams/MaximumExcessRateParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

export const maximumExcessRateSearch = createAsyncAction<IMaximumExcessRate[], IMaximumExcessRateSearchParams>(
	'pricing/maximumExcessRate/search',
	maximumExcessRateServiceSearch,
);

export const maximumExcessRateInsert = createAsyncAction<IMaximumExcessRate[], IMaximumExcessRateInsertParams>(
	'pricing/maximumExcessRate/insert',
	maximumExcessRateServiceInsert,
);

export const maximumExcessRateDeactivate = createAsyncAction<IMaximumExcessRate[], IMaximumExcessRateDeactivateParams>(
	'pricing/maximumExcessRate/Deactivate',
	maximumExcessRateServiceDeactivate,
);

export const maximumExcessRateClear = createSyncAction('pricing/maximumExcessRate/clear');
