import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import {
	supplierClear,
	supplierGetAll,
	supplierInsertCarrier,
	supplierInsertWorkshop,
	supplierModifyWorkshop,
	supplierSearch,
} from '@crac/core/redux/actions/SupplierActions';
import type { ISupplierReducerState } from '@crac/core/redux-store/reducersState/suppliers';

const initialState: ISupplierReducerState = {
	suppliers: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
	supplierInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	supplierModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	supplierSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	workshops: [],
};

const supplierSlice = createSlice({
	name: 'SupplierSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET ALL
		builder
			.addCase(supplierGetAll.pending, (state) => {
				return {
					...state,
					suppliers: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierGetAll.fulfilled, (state, action) => {
				return {
					...state,
					suppliers: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierGetAll.rejected, (state, action) => {
				return {
					...state,
					suppliers: {
						data: [],
						inProgress: false,
						messages: deepCopy(action.payload) ?? [],
						ok: false,
					},
				};
			});
		// SEARCH
		builder
			.addCase(supplierSearch.pending, (state) => {
				return {
					...state,
					supplierSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierSearch.fulfilled, (state, action) => {
				return {
					...state,
					supplierSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					workshops: action.payload,
				};
			})
			.addCase(supplierSearch.rejected, (state, action) => {
				return {
					...state,
					supplierSearchRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(supplierInsertWorkshop.pending, (state) => {
				return {
					...state,
					supplierInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierInsertWorkshop.fulfilled, (state, action) => {
				return {
					...state,
					supplierInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					workshops: [...state.workshops, action.payload].sort((acc, curr) =>
						acc.code.localeCompare(curr.code),
					),
				};
			})
			.addCase(supplierInsertWorkshop.rejected, (state, action) => {
				return {
					...state,
					supplierInsertRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(supplierModifyWorkshop.pending, (state) => {
				return {
					...state,
					supplierModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierModifyWorkshop.fulfilled, (state, action) => {
				return {
					...state,
					supplierModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					workshops: state.workshops.map((item) =>
						item.code === action.payload.code ? action.payload : item,
					),
				};
			})
			.addCase(supplierModifyWorkshop.rejected, (state, action) => {
				return {
					...state,
					supplierModifyRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
		// INSERT CARRIER
		builder
			.addCase(supplierInsertCarrier.pending, (state) => {
				return {
					...state,
					supplierInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(supplierInsertCarrier.fulfilled, (state, action) => {
				return {
					...state,
					supplierInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					workshops: [...state.workshops, action.payload].sort((acc, curr) =>
						acc.code.localeCompare(curr.code),
					),
				};
			})
			.addCase(supplierInsertCarrier.rejected, (state, action) => {
				return {
					...state,
					supplierInsertRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(supplierClear, (state) => {
			return {
				...state,
				workshops: [],
			};
		});
	},
});

export const supplierReducer = supplierSlice.reducer;
