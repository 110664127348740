import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { cashLineClear, cashLineModify } from '@crac/core/redux/actions/CashLineActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { ICashLineModifyReducerState } from '@crac/core/redux-store/reducersState/cash/ModifyReducerState';
const initialState: ICashLineModifyReducerState = {
	modify: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const modifySlice = createSlice({
	name: 'CashLineModifySlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// MODIFY
		builder
			.addCase(cashLineModify.pending, (state) => {
				return {
					...state,
					modify: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cashLineModify.fulfilled, (state) => {
				return {
					...state,
					modify: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cashLineModify.rejected, (state, action) => {
				return {
					...state,
					modify: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR CACHE
		 * CASH LINE CLEAR
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, cashLineClear), () => {
			return initialState;
		});
	},
});

export const modifyReducer = modifySlice.reducer;
