import { invoiceLineServiceGetByInvoiceAndVehicle } from '../../data/services/InvoiceLineService';
import type { IInvoiceLine } from '../../models/entities/InvoiceLine';
import type { IInvoiceLineGetByInvoiceAndVehicleParams } from '../../models/serviceParams/InvoiceLineParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
/**
 *	Get invoice lines by invoice and vehicle.
 *
 * @param {IInvoiceLine[]} payload - The invoice lines.
 * @param {IInvoiceLineGetByInvoiceAndVehicleParams} params - The parameters to get the invoice lines.
 * @returns {Promise<void>} - A promise that resolves to an array of invoice lines.
 */
export const invoiceLineGetByInvoiceAndVehicle = createAsyncAction<
	IInvoiceLine[],
	IInvoiceLineGetByInvoiceAndVehicleParams
>('invoiceLine/getByInvoiceAndVehicle', invoiceLineServiceGetByInvoiceAndVehicle);

/**
 * Clear the invoice lines.
 */
export const invoiceLineClear = createSyncAction('invoiceLine/clear');
