import React from 'react';

import classNames from 'classnames';

import { Input } from '../../../../external/reactstrap/input';
import { romanize } from '../../../../external/transliterate';
import { formField } from '../formField';
import type { IFormFieldInputProps, InputType } from '../types/FormElements';

export const InputFormField = formField((props: IFormFieldInputProps) => {
	const {
		autoComplete,
		autofocus,
		className,
		cols,
		disabled,
		id,
		input,
		max,
		min,
		onBlur,
		onChange,
		onlyDigits,
		pattern,
		placeholder,
		required,
		rows,
		step,
		tabIndex,
		wrap,
		innerRef,
		onFocus,
		bsSize,
		...rest
	} = props;
	const { type } = input;
	let inputStep: string | number = 1;

	if (step) {
		inputStep = step.toString();
	}

	const setOnChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
		// TODO: QUITAR CUANDO SE PONGA NAV 2018
		if (type === 'text') {
			event.currentTarget.value = romanize(event.currentTarget.value);
		}

		if (onlyDigits) {
			const newValue = event.currentTarget.value.replace(/\D/gu, '').trim();
			event.currentTarget.value = newValue;
		}

		input.onChange(event);
		if (onChange) {
			onChange(event);
		}
		if (type === 'checkbox') {
			input.onBlur();
		}
	};

	const handleOnBlur = (event: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>): void => {
		let tempValue: string | number = event.currentTarget.value;

		switch (type) {
			case 'radio':
			case 'checkbox':
			case 'file':
				break;
			default:
				tempValue = event.currentTarget.value;

				if (type === 'number' && inputStep) {
					// SI EL STEP NO ES DECIMAL, EL VALOR SERÁ ENTERO
					if (inputStep && !`${inputStep}`.includes('.') && tempValue !== '') {
						tempValue = Math.floor(Number(event.currentTarget.value));
						const rest = tempValue % (inputStep as number);
						if (inputStep && rest > 0) {
							event.currentTarget.value = ((tempValue as number) - rest).toString();
							input.onChange(event as React.ChangeEvent<HTMLInputElement>);
							break;
						}

						event.currentTarget.value = tempValue.toString();
						input.onChange(event as React.ChangeEvent<HTMLInputElement>);
						break;
					} else if (!Number.isNaN(Number(tempValue)) && tempValue !== '') {
						tempValue = Number(tempValue).toFixed(2);
					}
				}

				if (type === 'text') {
					tempValue = event.currentTarget.value.replace(/\s{2,}/giu, ' ').trim();
				}

				event.currentTarget.value = tempValue;
				input.onChange(event as React.ChangeEvent<HTMLInputElement>);
				break;
		}

		input.onBlur(event as any);
		input.onFocus(event as any);
		if (onBlur) {
			onBlur(event as React.FocusEvent<HTMLInputElement>);
		}

		if (onFocus) {
			onFocus(event as React.FocusEvent<HTMLInputElement>);
		}
	};

	return (
		<Input
			aria-label={rest['aria-label'] || input.name || undefined}
			aria-required={required}
			autoComplete={autoComplete}
			autoFocus={autofocus}
			bsSize={bsSize}
			checked={Boolean(input.checked)}
			className={classNames(className)}
			cols={cols}
			disabled={disabled}
			id={id || input.name}
			innerRef={innerRef}
			max={max}
			min={min}
			name={input.name}
			onBlur={handleOnBlur}
			onChange={setOnChange}
			pattern={pattern}
			placeholder={placeholder}
			rows={rows}
			step={inputStep}
			tabIndex={tabIndex}
			type={type as InputType}
			value={input.value}
			wrap={wrap}
		/>
	);
});
