import type { FC } from 'react';
import React, { Suspense, useEffect, useRef } from 'react';

import classNames from 'classnames';

import { Button } from '@crac/components/external/reactstrap/button';
import { gCSwal } from '@crac/components/external/sweet-alert';
import { Modal } from '@crac/components/intranet/shared/modal';
import type { IBooking } from '@crac/core/models/entities/Booking';
import type { IBranch } from '@crac/core/models/entities/Branch';
import type { IEmployee } from '@crac/core/models/entities/Employee';
import type { IRequestState } from '@crac/core/modules/shared/types/RequestStateType';
import type { emailSendFeedback } from '@crac/core/redux/actions/EmailActions';

import { useComputerSelector } from '~/features/management/computer/state/selectors/computer';

import type { FeedBackFormState } from './types';

const FeedBackForm = React.lazy(() => import('~/features/shared/components/layout/feedBack/FeedBackForm'));

interface IFeedBackProps {
	booking?: IBooking | null;
	employee: IEmployee;
	isOpen: boolean;
	onToggleFeedback: (isOpen: boolean) => void;
	sendFeedBack: typeof emailSendFeedback;
	sendFeedBackRequest: IRequestState;
}

export const FeedBack: FC<IFeedBackProps> = ({
	employee,
	sendFeedBack,
	sendFeedBackRequest,
	booking,
	isOpen,
	onToggleFeedback,
}) => {
	const { hostname } = useComputerSelector();
	const sendFeedBackRequestRef = useRef(sendFeedBackRequest);

	useEffect(() => {
		sendFeedBackRequestRef.current = sendFeedBackRequest;
	}, [sendFeedBackRequest]);

	const handleClose = () => {
		onToggleFeedback(false);
	};

	const handleOpen = () => {
		onToggleFeedback(true);
	};

	const handleSubmit = async (values: FeedBackFormState): Promise<void> => {
		if (!sendFeedBackRequestRef.current.inProgress) {
			const { userName, name, allowedBranches, branchCode, token } = employee;
			const currentBranch = allowedBranches.find((branch) => branch.code === branchCode) as IBranch;
			const { files, message } = values;

			const json = JSON.stringify({
				allowedBranches: allowedBranches.map((branch) => branch.code),
				bookingNumber: booking ? booking.bookingNumber : undefined,
				branchCode,
				branchEmail: currentBranch.email,
				branchName: currentBranch.name,
				dropOffDateTime: booking ? booking.dropOffDateTime : undefined,
				dropOffFuelPercentage: booking ? booking.dropOffFuelPercentage : undefined,
				dropOffKM: booking ? booking.dropOffKM : undefined,
				message,
				name,
				token,
				url: location.href,
				userName,
				hostname,
			});

			sendFeedBack({
				files,
				json,
			});

			if (sendFeedBackRequestRef.current.ok) {
				onToggleFeedback(false);
				await gCSwal('Sent!', 'Feedback has been sent.', 'success');
			} else {
				await gCSwal('Error!', sendFeedBackRequestRef.current.messages.map((msg) => msg.message).join(', '));
			}
		}
	};

	return (
		<div className="FeedBack">
			<Button className={classNames('d-none d-sm-block feedback-button')} onClick={handleOpen} tabIndex={-1}>
				<i className="fa fa-exclamation-triangle" />
			</Button>
			<Modal className="max-z-index" isOpen={isOpen} maxIndex onClose={handleClose} type="slide">
				<Suspense fallback={null}>
					<FeedBackForm onCancel={handleClose} onSubmit={handleSubmit} />
				</Suspense>
			</Modal>
		</div>
	);
};
