import { expedientServiceAddToVehicle, expedientServiceGetByVehicle } from '../../data/services/ExpedientService';
import type { IExpedient } from '../../models/entities/Expedient';
import type {
	IExpedientAddToVehicleParams,
	IExpedientGetByVehicleParams,
} from '../../models/serviceParams/ExpedientParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Adds an expedient to a specific vehicle.
 *
 * @param {IExpedient} payload - The expedient to be added.
 * @param {IExpedientAddToVehicleParams} params - Parameters detailing the expedient and the vehicle to which it is added.
 * @returns {Promise<void>} - A promise that resolves after the expedient is added to the vehicle.
 */
export const expedientAdd = createAsyncAction<IExpedient, IExpedientAddToVehicleParams>(
	'expedient/add',
	expedientServiceAddToVehicle,
);

/**
 * Retrieves expedients associated with a specific vehicle.
 *
 * @param {IExpedient[]} payload - The expedients to be retrieved.
 * @param {IExpedientGetByVehicleParams} params - Parameters to identify expedients related to a specific vehicle.
 * @returns {Promise<void>} - A promise that resolves to an array of expedients associated with the vehicle.
 */
export const expedientGetByVehicle = createAsyncAction<IExpedient[], IExpedientGetByVehicleParams>(
	'expedient/getByVehicle',
	expedientServiceGetByVehicle,
);
