import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { bookingDetailClear } from '@crac/core/redux/actions/BookingActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	paymentLineDeleteChargeBack,
	paymentLineGetByBooking,
	paymentLineInsert,
	paymentLineUpdateChargeBack,
} from '@crac/core/redux/actions/PaymentLineActions';
import type { IBookingPaymentLineReducerState } from '@crac/core/redux-store/reducersState/booking/PaymentLinesReducerState';
const initialState: IBookingPaymentLineReducerState = {
	paymentLineUpdateChargeBackRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	paymentLineDeleteChargeBackRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	paymentLines: [],
	paymentLinesInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	paymentLinesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const paymentLinesSlice = createSlice({
	name: 'BookingPaymentLinesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET_BY_BOOKING
		builder
			.addCase(paymentLineGetByBooking.pending, (state) => {
				return {
					...state,
					paymentLines: [],
					paymentLinesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentLineGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					paymentLines: [...action.payload],
					paymentLinesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentLineGetByBooking.rejected, (state, action) => {
				return {
					...state,
					paymentLinesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(paymentLineInsert.pending, (state) => {
				return {
					...state,
					paymentLinesInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentLineInsert.fulfilled, (state, action) => {
				return {
					...state,
					paymentLines: [...state.paymentLines, action.payload],
					paymentLinesInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentLineInsert.rejected, (state, action) => {
				return {
					...state,
					paymentLinesInsertRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// UPDATE CHARGE BACK
		builder
			.addCase(paymentLineUpdateChargeBack.pending, (state) => {
				return {
					...state,
					paymentLineUpdateChargeBackRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentLineUpdateChargeBack.fulfilled, (state, action) => {
				return {
					...state,
					paymentLine: action.payload,
					paymentLineUpdateChargeBackRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					paymentLines: [
						...state.paymentLines.filter((line) => line.number !== action.payload.number),
						action.payload,
					],
				};
			})
			.addCase(paymentLineUpdateChargeBack.rejected, (state, action) => {
				return {
					...state,
					paymentLineUpdateChargeBackRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// DELETE CHARGE BACK
		builder
			.addCase(paymentLineDeleteChargeBack.pending, (state) => {
				return {
					...state,
					paymentLineDeleteChargeBackRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentLineDeleteChargeBack.fulfilled, (state, action) => {
				return {
					...state,
					paymentLine: action.payload,
					paymentLineDeleteChargeBackRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					paymentLines: [
						...state.paymentLines.filter((line) => line.number !== action.payload.number),
						action.payload,
					],
				};
			})
			.addCase(paymentLineDeleteChargeBack.rejected, (state, action) => {
				return {
					...state,
					paymentLineDeleteChargeBackRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR
		 * CLEAR CACHE
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, bookingDetailClear), () => {
			return initialState;
		});
	},
});

export const paymentLinesReducer = paymentLinesSlice.reducer;
