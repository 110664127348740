import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const FleetMovementRestrictionSearch = lazyWithRetry(() => import('./pages/MovementRestrictionSearch'));

export const movementRestrictionRoutes: { [key: number]: IRouteConfig } = {};

// MOVEMENT RESTRICTION
movementRestrictionRoutes[routesIdMap.FleetMovementRestriction] = {
	component: FleetMovementRestrictionSearch,
	exact: true,
	id: routesIdMap.FleetMovementRestriction,
	name: 'Movement restriction',
	path: '/fleet/movement-restriction',
	permissions: [PermissionsType.VehicleManageRestrictionMovement],
	icon: 'hand-stop-o',
	menuText: 'Movement restriction',
};
