import { availabilityRoutes } from './availability/routes';
import { confirmationRoutes } from './confirmation/routes';
import { customerRoutes } from './customer/routes';
import { groupsRoutes } from './groups/routes';
import { invoiceToRoutes } from './invoiceTo/routes';
import { partnerRoutes } from './partner/routes';
import { servicesRoutes } from './services/routes';

export const newRoutes = {
	...availabilityRoutes,
	...confirmationRoutes,
	...customerRoutes,
	...groupsRoutes,
	...invoiceToRoutes,
	...partnerRoutes,
	...servicesRoutes,
};
