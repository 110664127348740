import type { IRentRateVariation } from '../../models/entities/RentRateVariation';
import type {
	IRentRateVariationDeactivateParams,
	IRentRateVariationInsertParams,
	IRentRateVariationsSearchParams,
} from '../../models/serviceParams/RentParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { RentRateMarginEndPoints } from '../endPoints/RentRateMarginEndPoints';

export const rentRateMarginSearchService = (
	model: IRentRateVariationsSearchParams,
): Promise<ServiceResponse<IRentRateVariation[]>> => {
	return Api.get<IRentRateVariation[], IRentRateVariationsSearchParams>(RentRateMarginEndPoints.SEARCH, model);
};

export const rentRateMarginInsertService = (
	model: IRentRateVariationInsertParams,
): Promise<ServiceResponse<IRentRateVariation[]>> => {
	return Api.post<IRentRateVariation[], IRentRateVariationInsertParams>(RentRateMarginEndPoints.INSERT, model);
};

export const rentRateMarginDeactivateService = (
	model: IRentRateVariationDeactivateParams,
): Promise<ServiceResponse<IRentRateVariation[]>> => {
	return Api.post<IRentRateVariation[], IRentRateVariationDeactivateParams>(
		RentRateMarginEndPoints.DEACTIVATE,
		model,
	);
};
