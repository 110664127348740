import { BookingStateType } from '../../models/types/BookingStateType';

export const RefundValidStates = [
	BookingStateType.Processing,
	BookingStateType.Confirmed,
	BookingStateType.Cancelled,
	BookingStateType.CancelledDueToUnavailability,
	BookingStateType.StopSale,
	BookingStateType.Denied,
];

export const CancelledStates = [
	BookingStateType.Cancelled,
	BookingStateType.NoShow,
	BookingStateType.StopSale,
	BookingStateType.CancelledDueToUnavailability,
	BookingStateType.Denied,
];

export const ProcessPreauthorizationValidStates = [BookingStateType.Processing, BookingStateType.OnHire];
