import { DateTime, Info } from 'luxon';

import { capitalize } from './commons';
import type { UnitTimeDurationsType } from './unitTime';
import { getUnitTimeDurationText } from './unitTime';
import { UnitTime } from '../models/types/UnitTime';
import { ONE_HOUR_EQUALS_DAY } from '../modules/shared/Constants';

const MINUTE = 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;
const NOON = DAY / 2;

/**
 * Calculate difference from now until `date` param
 */
export const calculateAge = (date: Date): number => {
	const now = DateTime.utc();
	const birthDate = DateTime.fromJSDate(date);
	const { years } = now.diff(birthDate, ['years']);

	return Math.trunc(years);
};

/**
 * Calculate Years base in customer birth and booking pick up
 */
export const calculateAgeToPickUp = (pickup: string | DateTime, birth: string | DateTime): number => {
	let pickUpDate: string | DateTime = pickup;
	let birthDate: string | DateTime = birth;

	if (typeof pickUpDate === 'string') {
		pickUpDate = DateTime.fromISO(pickUpDate);
	}

	if (typeof birthDate === 'string') {
		birthDate = DateTime.fromISO(birthDate);
	} else {
		birthDate = DateTime.fromJSDate(new Date(birthDate.toISODate() as string));
	}

	const { years } = pickUpDate.diff(birthDate, ['years']);
	return Math.ceil(years);
};

/**
 * Une un objeto Date y la hora, basado en la zona horaria.
 * @param date Date object, siempre en hora local del navegador
 * @param time time in string format
 * @param zone time zone, default is UTC
 */
export const combineDateTimeAndZone = (date: Date, time: string, zone: string): Date => {
	const [hour, minutes] = time.split(':');

	const currentHour = parseInt(hour, 10);
	const currentMinute = parseInt(minutes, 10);

	return DateTime.fromObject(
		{
			day: date.getDate(),
			hour: currentHour,
			minute: currentMinute,
			month: date.getMonth() + 1,
			year: date.getFullYear(),
		},
		{ zone },
	).toJSDate();
};

/**
 * Convierte un datetime en formato ISO a un objeto Date, sin hora
 * @param dateTime DateTime en formato ISO
 * @param zone zona horaria
 */
export const getLocalDateFromISO = (dateTime: string, zone: string): Date => {
	return DateTime.fromISO(dateTime).setZone(zone).startOf('day').setZone('local', { keepLocalTime: true }).toJSDate();
};

export const getLocalTimeFromISO = (dateTime: string, zone: string, format = 'HH:mm'): string => {
	return DateTime.fromISO(dateTime).setZone(zone).setZone('local', { keepLocalTime: true }).toFormat(format);
};

export const getStringDateFromISO = (dateTime: string): string => DateTime.fromISO(dateTime).toFormat('dd/MM/yyyy');

export const getUtcDateFromJsDate = (dateTime: Date): Date =>
	DateTime.fromJSDate(dateTime).setZone('utc').startOf('day').setZone('utc', { keepLocalTime: true }).toJSDate();

export const getLocalDateFromJsDate = (dateTime: Date): Date => {
	return DateTime.fromJSDate(dateTime)
		.setZone('local')
		.startOf('day')
		.setZone('local', { keepLocalTime: true })
		.toJSDate();
};

export const getLocalDateTimeFromJsDate = (dateTime: Date, zone = 'local'): Date => {
	return DateTime.fromJSDate(dateTime).setZone(zone).setZone('local', { keepLocalTime: true }).toJSDate();
};

/**
 * Retorna la hora en el formato especificado, basado en la fecha ISO
 * @param date Date object
 * @param format format time, default HH:mm
 */
export const getTimeFromISODateTime = (date: string, format = 'HH:mm'): string => {
	return DateTime.fromISO(date, { zone: 'utc' }).toFormat(format);
};

/**
 * Retorna la hora en el formato especificado, basado en la Date object
 * @param date Date object
 * @param format format time, default HH:mm
 */
export const getTimeFromDateTime = (date: Date, format = 'HH:mm'): string => {
	return DateTime.fromJSDate(date).toFormat(format);
};

interface IHourOptions {
	label: string;
	value: string | number;
	default?: boolean;
}

export const getListHours = (timeZone?: string): Array<IHourOptions> => {
	const quarterHours = ['00', '15', '30', '45'];
	const times: IHourOptions[] = [];
	let currentDate = DateTime.local();

	if (timeZone && DateTime.local().setZone(timeZone).isValid) {
		currentDate = DateTime.local().setZone(timeZone) as DateTime<true>;
	}

	for (let index = 0; index <= 23; index += 1) {
		for (let jIndex = 0; jIndex < 4; jIndex += 1) {
			times.push({
				default: index === currentDate.hour,
				label: `${index.toString().padStart(2, '0')}:${quarterHours[jIndex]}`,
				value: `${index.toString().padStart(2, '0')}:${quarterHours[jIndex]}`,
			});
		}
	}

	return times.sort((accomuled, currentValue) => accomuled.label.localeCompare(currentValue.label));
};

export const getListHours24 = (timeZone?: string): Array<IHourOptions> => {
	const times: IHourOptions[] = [];
	let currentDate = DateTime.utc();

	if (timeZone && DateTime.local().setZone(timeZone).isValid) {
		currentDate = DateTime.local().setZone(timeZone) as DateTime<true>;
	}

	for (let index = 0; index <= 23; index += 1) {
		for (let jIndex = 0; jIndex < 60; jIndex += 1) {
			times.push({
				default: index === currentDate.hour && jIndex === currentDate.minute,
				label: `${index.toString().padStart(2, '0')}:${jIndex.toString().padStart(2, '0')}`,
				value: `${index.toString().padStart(2, '0')}:${jIndex.toString().padStart(2, '0')}`,
			});
		}
	}

	return times.sort((accomuled, currentValue) => accomuled.label.localeCompare(currentValue.label));
};

export const getMonthList = (locale = 'es'): { label: string; value: number }[] => {
	return Info.months('long', { locale }).map((month, index) => ({
		label: capitalize(month),
		value: index + 1,
	}));
};

export const zeroPrefix = (num: number): string => (num < 10 ? `0${num}` : `${num}`);

export const getTimeStringFromDateTime = (dateTime: Date): string => {
	const date = DateTime.fromJSDate(dateTime);
	return `${zeroPrefix(date.hour)}:${zeroPrefix(date.minute)}`;
};

export const getToday = (): Date => {
	const today = new Date();
	today.setHours(0, 0, 0, 0);
	return today;
};

export const clearTimeOnDate = (date: string | Date): Date => {
	const dateToReturn = new Date(date);
	dateToReturn.setHours(0, 0, 0, 0);
	return dateToReturn;
};

export const addTimeToDate = (
	dateTime: Date,
	definedValues: { days?: number; hours?: number; minutes?: number },
): Date => {
	const date = DateTime.fromJSDate(dateTime);
	return date.plus(definedValues).toJSDate();
};

const buildHourOptions = (seconds: number, format?: '12' | '24', timeZone?: string): IHourOptions => {
	// eslint-disable-next-line prefer-const
	let { hour, minute } = DateTime.fromSeconds(seconds);
	const currentDateTime = DateTime.local();

	if (timeZone) {
		currentDateTime.setZone(timeZone);
	}

	const defaultOption = hour === currentDateTime.hour && minute >= currentDateTime.minute;

	if (format === '12') {
		const isAfterNoon = seconds >= NOON;
		if (isAfterNoon) {
			hour -= 12;
		}

		if (hour === 0) {
			hour = 12;
		}

		return {
			default: defaultOption,
			label: `${hour}:${zeroPrefix(minute)} ${isAfterNoon ? 'PM' : 'AM'}`,
			value: `${hour}:${zeroPrefix(minute)}`,
		};
	}

	return {
		default: defaultOption,
		label: `${zeroPrefix(hour)}:${zeroPrefix(minute)}`,
		value: `${zeroPrefix(hour)}:${zeroPrefix(minute)}`,
	};
};

/**
 * Steps build by minutes
 * @param step segundos. 900 seg = 15 min
 */
const buildSteps = (step: number): number[] => {
	const options = [];
	for (let index = 0; index < DAY; index += step) {
		options.push(index);
	}

	return options;
};

export const getHourListSecondValue = (timeZone?: string, format?: '12' | '24', step = 900): Array<IHourOptions> => {
	const steps = buildSteps(step);

	return steps.map((second) => buildHourOptions(second, '24', timeZone));
};

export const getSecondsFromDateTime = (dateTime: Date): number =>
	dateTime.getHours() * 60 * 60 + dateTime.getMinutes() * 60;

export const getOnlyDateFromJsDate = (date: Date | string): string => {
	let currentDate = DateTime.local();
	if (typeof date === 'string' && DateTime.fromISO(date).isValid) {
		currentDate = DateTime.fromISO(date) as DateTime<true>;
	} else if (typeof date === 'object' && DateTime.fromJSDate(date).isValid) {
		currentDate = DateTime.fromJSDate(date) as DateTime<true>;
	}

	if (currentDate.isValid) {
		return `${currentDate.year}-${zeroPrefix(currentDate.month)}-${zeroPrefix(currentDate.day)}T00:00:00.000Z`;
	}

	return '';
};
export const createDateWithDateAndTime = (date: string | Date, time = '00:00:00') => {
	const auxDate = new Date(date);
	if (time) {
		const [hours, minutes, seconds] = time.split(':');
		auxDate.setHours(Number(hours));
		auxDate.setMinutes(Number(minutes));
		if (seconds) {
			auxDate.setSeconds(Number(seconds));
		}
	}
	return auxDate;
};

export const formatISODateForInput = (date: string, zone = 'utc') => {
	const dateTime = DateTime.fromISO(date, { zone });
	return dateTime.toFormat('yyyy-MM-dd');
};

export const getMaxDateTimeByMonth = (maxValue?: Date) => {
	if (!maxValue) {
		return undefined;
	}
	const dateTime = DateTime.fromJSDate(maxValue);
	const result = dateTime.set({ day: dateTime.daysInMonth });
	return result;
};

export const getPureDate = (date?: Date | string | DateTime): string => {
	let currentDate = DateTime.local();
	if (typeof date === 'string' && DateTime.fromISO(date).isValid) {
		currentDate = DateTime.fromISO(date) as DateTime<true>;
	} else {
		currentDate = DateTime.fromJSDate(date as Date, { zone: undefined }) as DateTime<true>;
	}
	const { year, month, day, hour, minute, second } = date instanceof DateTime ? date : currentDate;

	if (currentDate.isValid) {
		return `${year}-${zeroPrefix(month)}-${zeroPrefix(day)}T${zeroPrefix(hour)}:${zeroPrefix(minute)}:${zeroPrefix(
			second,
		)}.000Z`;
	}

	return '';
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const getDaysOfMonth = (month?: number): number[] => {
	const current = DateTime.local();
	const maxDays = current.get('daysInMonth');

	return Array.from({ length: maxDays }, (value, index) => index + 1);
};

/**
 * Generate list of years
 * @param from Year from
 * @param numOfYears Number of years to generate
 * @returns Input select options
 */
export const getYearList = (
	numOfYears = DateTime.local().plus({ years: 10 }).year - DateTime.local().year,
	from = DateTime.local().year,
): number[] => {
	// eslint-disable-next-line id-length
	const years = Array.from({ length: numOfYears }, (_, i) => i + from);
	return years;
};

export const combineDateAndTime = (date: Date, time: string, zone = 'utc'): Date => {
	const [hour, minutes] = time.split(':');

	const currentHour = parseInt(hour, 10);
	const currentMinute = parseInt(minutes, 10);

	return DateTime.fromObject(
		{
			day: date.getDate(),
			hour: currentHour,
			minute: currentMinute,
			month: date.getMonth() + 1,
			year: date.getFullYear(),
		},
		{
			zone,
		},
	).toJSDate();
};

/**
 * Convert ISO date to Date Object, using exact values from ISO string
 * @param dateTime ISO date time
 * @returns Date object with UTC time
 */
export const getUtcDateTimeFromISO = (dateTime: string): Date => {
	// Split date and time from ISO string
	const [date, time] = dateTime.split('T');

	// Split time to get hour, minute and second
	const [hour, minute] = time.split(':');

	// Get date object from date string
	const dateObject = new Date(date);

	// Return date object
	return DateTime.fromObject({
		hour: Number(hour),
		minute: Number(minute),
		second: 0,
		year: dateObject.getFullYear(),
		month: dateObject.getMonth() + 1,
		day: dateObject.getDate(),
	}).toJSDate();
};

export const isDateGreaterThanToday = (date: Date | string): boolean => {
	const today = getToday();
	let dt = today;
	if (typeof date === 'string') {
		dt = DateTime.fromISO(date).toJSDate();
	} else {
		dt = date;
	}
	return dt.getTime() >= today.getTime();
};

export const getUnitTimeBetweenDates = (first: Date | string, second: Date | string, unitTime?: UnitTime): number => {
	const firstDate = typeof first === 'string' ? DateTime.fromISO(first) : DateTime.fromJSDate(first);
	const secondDate = typeof second === 'string' ? DateTime.fromISO(second) : DateTime.fromJSDate(second);

	const durationUnit = getUnitTimeDurationText(unitTime, 2);

	const diff = secondDate.diff(firstDate, [durationUnit]);
	return diff[durationUnit as UnitTimeDurationsType];
};

export const getDatePlusDuration = (date: Date | string, duration: number, unitTime = UnitTime.Day) => {
	const dateTime = typeof date === 'string' ? DateTime.fromISO(date) : DateTime.fromJSDate(date);
	const unitDuration = getUnitTimeDurationText(unitTime, duration);

	return dateTime.plus({ [unitDuration]: duration }).toJSDate();
};

export const getDaysFromHours = (hours = 1): number => Math.round(hours * ONE_HOUR_EQUALS_DAY);

export const getISOFromJSDate = (
	date: Date,
	options: { timeZone?: string; keepLocalTime?: boolean } = {
		timeZone: 'utc',
		keepLocalTime: false,
	},
) => {
	const { timeZone = 'utc', keepLocalTime = false } = options;
	return DateTime.fromJSDate(date).setZone(timeZone, { keepLocalTime }).toUTC().toISO();
};
