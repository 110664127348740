export enum CouponConfigurationEndPoints {
	/**
	 * Deactivate service invoiceable configuration
	 * @host `/couponApplicationConfiguration/deactivate`
	 */
	DEACTIVATE = '/couponApplicationConfiguration/deactivate',
	/**
	 * Get service included configuration
	 * @host `/couponApplicationConfiguration/insert`
	 */
	INSERT = '/couponApplicationConfiguration/insert',
	/**
	 * Modify service included configuration
	 * @host `/couponApplicationConfiguration/modify`
	 */
	MODIFY = '/couponApplicationConfiguration/modify',
	/**
	 * Search service included configuration
	 * @host `/couponApplicationConfiguration/search`
	 */
	SEARCH = '/couponApplicationConfiguration/search',
}
