import { createSlice } from '@reduxjs/toolkit';

import {
	couponGetByBookingNumber,
	couponGetByCustomerCode,
	customerCouponInsert,
	customerCouponInsertCallBack,
	customerCouponInsertResetErrors,
} from '@crac/core/redux/actions/CouponActions';
import type { ICouponReducerState } from '@crac/core/redux-store/reducersState/customer/CouponsReducerState';
const initialState: ICouponReducerState = {
	couponInsertCallBackRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	couponInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	coupons: [],
	couponsGetByBookingNumberRequest: {
		inProgress: false,
		messages: [],
		ok: false,
	},
	couponsGetByCustomerRequest: {
		inProgress: false,
		messages: [],
		ok: false,
	},
	transaction: null,
};

const couponsSlice = createSlice({
	name: 'CustomerCouponsSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET_BY_CUSTOMER_CODE
		builder
			.addCase(couponGetByCustomerCode.pending, (state) => {
				return {
					...state,
					couponsGetByCustomerRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(couponGetByCustomerCode.fulfilled, (state, action) => {
				return {
					...state,
					coupons: action.payload,
					couponsGetByCustomerRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(couponGetByCustomerCode.rejected, (state, action) => {
				return {
					...state,
					couponsGetByCustomerRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET_BY_BOOKING_NUMBER
		builder
			.addCase(couponGetByBookingNumber.pending, (state) => {
				return {
					...state,
					couponsGetByBookingNumberRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(couponGetByBookingNumber.fulfilled, (state, action) => {
				return {
					...state,
					coupons: action.payload,
					couponsGetByBookingNumberRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(couponGetByBookingNumber.rejected, (state, action) => {
				return {
					...state,
					couponsGetByBookingNumberRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(customerCouponInsert.pending, (state) => {
				return {
					...state,
					couponInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					transaction: null,
				};
			})
			.addCase(customerCouponInsert.fulfilled, (state, action) => {
				return {
					...state,
					couponInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					coupons: action.payload.coupons,
					transaction: action.payload.transaction,
				};
			})
			.addCase(customerCouponInsert.rejected, (state, action) => {
				return {
					...state,
					couponInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
					coupons: null,
					transaction: null,
				};
			});
		// INSERT CALLBACK
		builder
			.addCase(customerCouponInsertCallBack.pending, (state) => {
				return {
					...state,
					couponInsertCallBackRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					transaction: null,
				};
			})
			.addCase(customerCouponInsertCallBack.fulfilled, (state, action) => {
				return {
					...state,
					couponInsertCallBackRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					coupons: action.payload.coupons,
					transaction: action.payload.transaction,
				};
			})
			.addCase(customerCouponInsertCallBack.rejected, (state, action) => {
				return {
					...state,
					couponInsertCallBackRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
					coupons: null,
					transaction: null,
				};
			});
		// COUPON_INSERT_RESET
		builder.addCase(customerCouponInsertResetErrors, (state) => {
			return {
				...state,
				couponInsertCallBackRequest: {
					inProgress: false,
					messages: [],
					ok: false,
				},
				couponInsertRequest: {
					inProgress: false,
					messages: [],
					ok: false,
				},
				coupons: null,
				transaction: null,
			};
		});
	},
});

export const couponsReducer = couponsSlice.reducer;
