import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { vehicleReturn } from '@crac/core/redux/actions/VehicleActions';
import type { IReturnReducerState } from '@crac/core/redux-store/reducersState/vehicle/ReturnReducerState';
const initialState: IReturnReducerState = {
	return: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const returnSlice = createSlice({
	name: 'VehicleReturnSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// RETURN
		builder
			.addCase(vehicleReturn.pending, (state) => {
				return {
					...state,
					return: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleReturn.fulfilled, (state) => {
				return {
					...state,
					return: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleReturn.rejected, (state, action) => {
				return {
					...state,
					return: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const returnReducer = returnSlice.reducer;
