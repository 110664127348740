import type { ICashLine } from '../../models/entities/CashLine';
import type {
	ICashLineGetByCodeParams,
	ICashLineGetByDayParams,
	ICashLineModifyParams,
	ICashLineSignParams,
} from '../../models/serviceParams/CashLineParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { CashLineEndPoints } from '../endPoints/CashLineEndPoints';

/**
 * Get current CashLine
 * @return {Promise<CashLineType>} CashLineType
 */
export const cashLineServiceGetCurrent = (): Promise<ServiceResponse<ICashLine>> => {
	return Api.get<ICashLine, undefined>(CashLineEndPoints.GET_CURRENT);
};

/**
 * Get pending CashLines
 * @return {Promise<CashLineType>} CashLineType
 */
export const cashLineServiceGetPending = (): Promise<ServiceResponse<ICashLine[]>> => {
	return Api.get<ICashLine[], undefined>(CashLineEndPoints.GET_PENDING);
};

/**
 * Get list CashLine by day
 *  {DateTime} date CashLine DateTime
 *
 * @return {Promise<CashLineType>} List CashLine Object
 */
export const cashLineServiceGetByDay = (model: ICashLineGetByDayParams): Promise<ServiceResponse<ICashLine[]>> => {
	return Api.get<ICashLine[], ICashLineGetByDayParams>(CashLineEndPoints.GET_BY_DAY, model);
};

/**
 * Get CashLine by code
 * @param {string} number Cash line code
 *
 * @return {Promise<CashLineType>} CashLine Object
 */
export const cashLineServiceGetByCode = (model: ICashLineGetByCodeParams): Promise<ServiceResponse<ICashLine>> => {
	return Api.get<ICashLine, ICashLineGetByCodeParams>(CashLineEndPoints.GET_BY_CODE, model);
};

export const cashLineServiceModify = (model: ICashLineModifyParams): Promise<ServiceResponse<ICashLine>> => {
	return Api.post<ICashLine, ICashLineModifyParams>(CashLineEndPoints.MODIFY, model);
};

export const cashLineServiceSign = (model: ICashLineSignParams): Promise<ServiceResponse<ICashLine>> => {
	return Api.post<ICashLine, ICashLineSignParams>(CashLineEndPoints.SIGN, model);
};
