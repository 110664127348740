/* eslint-disable complexity */
import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import type { IBooking } from '@crac/core/models/entities/Booking';
import { BookingStateType } from '@crac/core/models/types/BookingStateType';
import {
	bookingAllowPending,
	bookingBillBooking,
	bookingCustomerChange,
	bookingDetailClear,
	bookingDoDirectPickUp,
	bookingGetByBookingNumber,
	bookingInvoiceableChange,
	bookingRefundPayments,
	bookingRevertToConfirmed,
	bookingSignAndRegisterContract,
} from '@crac/core/redux/actions/BookingActions';
import { bookingCancel, bookingChangeDiscount, bookingModify } from '@crac/core/redux/actions/BookingModifyActions';
import { bookingPickUpStartContract, bookingRegisterContract } from '@crac/core/redux/actions/BookingPickUpActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	pickUpCreateCustomerAndModifyBooking,
	pickUpModifyCustomerAndBooking,
} from '@crac/core/redux/actions/PickUpActions';
import type { IBookingDetailReducerState } from '@crac/core/redux-store/reducersState/booking/DetailReducerState';
const initialState: IBookingDetailReducerState = {
	booking: null,
	bookingAllowPendingRequest: { inProgress: false, messages: [], ok: true },
	bookingBillRequest: { inProgress: false, messages: [], ok: true },
	bookingCancelRequest: { inProgress: false, messages: [], ok: true },
	bookingChangeDiscountRequest: { inProgress: false, messages: [], ok: true },
	bookingCustomerChangeRequest: { inProgress: false, messages: [], ok: true },
	bookingDetailRequest: { inProgress: false, messages: [], ok: true },
	bookingInvoiceableChangeRequest: { inProgress: false, messages: [], ok: true },
	bookingModifyRequest: { inProgress: false, messages: [], ok: true },
	bookingRefundPaymentsRequest: { inProgress: false, messages: [], ok: true },
	bookingRevertToConfirmedRequest: { inProgress: false, messages: [], ok: true },
};

const detailSlice = createSlice({
	name: 'BookingDetailSlice',
	initialState,
	reducers: {
		bookingDetailSetBooking(state, action: PayloadAction<IBooking | null>) {
			return {
				...state,
				booking: action.payload,
			};
		},
	},
	extraReducers: (builder) => {
		// BOOKING_GET_BY_NUMBER
		builder
			.addCase(bookingGetByBookingNumber.pending, (state) => {
				return {
					...state,
					booking: null,
					bookingDetailRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(bookingGetByBookingNumber.fulfilled, (state, action) => {
				return {
					...state,
					booking: deepCopy(action.payload),
					bookingDetailRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(bookingGetByBookingNumber.rejected, (state, action) => {
				return {
					...state,
					booking: null,
					bookingDetailRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// BOOKING_CANCEL
		builder
			.addCase(bookingCancel.pending, (state) => {
				return {
					...state,
					bookingCancelRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(bookingCancel.fulfilled, (state) => {
				return {
					...state,
					booking: {
						...(state.booking as IBooking),
						bookingState: BookingStateType.Cancelled,
					},
					bookingCancelRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(bookingCancel.rejected, (state, action) => {
				return {
					...state,
					bookingCancelRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// BOOKING_MODIFY
		builder
			.addCase(bookingModify.pending, (state) => {
				return {
					...state,
					bookingModifyRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(bookingModify.fulfilled, (state, action) => {
				return {
					...state,
					booking: deepCopy(action.payload),
					bookingModifyRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(bookingModify.rejected, (state, action) => {
				return {
					...state,
					bookingModifyRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// BOOKING_REFUND_PAYMENTS
		builder
			.addCase(bookingRefundPayments.pending, (state) => {
				return {
					...state,
					bookingRefundPaymentsRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(bookingRefundPayments.fulfilled, (state) => {
				return {
					...state,
					bookingRefundPaymentsRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(bookingRefundPayments.rejected, (state, action) => {
				return {
					...state,
					bookingRefundPaymentsRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// BOOKING_CHANGE_DISCOUNT
		builder
			.addCase(bookingChangeDiscount.pending, (state) => {
				return {
					...state,
					bookingChangeDiscountRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(bookingChangeDiscount.fulfilled, (state, action) => {
				return {
					...state,
					booking: { ...(state.booking as IBooking), discount: action.payload },
					bookingChangeDiscountRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(bookingChangeDiscount.rejected, (state, action) => {
				return {
					...state,
					bookingChangeDiscountRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// BOOKING_BILL_BOOKING
		builder
			.addCase(bookingBillBooking.pending, (state) => {
				return {
					...state,
					bookingBillRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(bookingBillBooking.fulfilled, (state) => {
				return {
					...state,
					bookingBillRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(bookingBillBooking.rejected, (state, action) => {
				return {
					...state,
					bookingBillRequest: { inProgress: false, messages: deepCopy(action.payload) || [], ok: false },
				};
			});
		// BOOKING_REVERT_TO_CONFIRMED
		builder
			.addCase(bookingRevertToConfirmed.pending, (state) => {
				return {
					...state,
					bookingRevertToConfirmedRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingRevertToConfirmed.fulfilled, (state, action) => {
				return {
					...state,
					booking: action.payload,
					bookingRevertToConfirmedRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingRevertToConfirmed.rejected, (state, action) => {
				return {
					...state,
					bookingRevertToConfirmedRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// BOOKING_ALLOW_PENDING
		builder
			.addCase(bookingAllowPending.pending, (state) => {
				return {
					...state,
					bookingAllowPendingRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingAllowPending.fulfilled, (state, action) => {
				return {
					...state,
					booking: {
						...(state.booking as IBooking),
						allowRegisterWithPending: action.payload.allowRegisterWithPending,
					},
					bookingAllowPendingRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingAllowPending.rejected, (state, action) => {
				return {
					...state,
					bookingAllowPendingRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// BOOKING_INVOICEABLE_CHANGE
		builder
			.addCase(bookingInvoiceableChange.pending, (state) => {
				return {
					...state,
					bookingInvoiceableChangeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingInvoiceableChange.fulfilled, (state) => {
				return {
					...state,
					// Booking: action.payload,
					bookingInvoiceableChangeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingInvoiceableChange.rejected, (state, action) => {
				return {
					...state,
					bookingInvoiceableChangeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// BOOKING_CUSTOMER_CHANGE
		builder
			.addCase(bookingCustomerChange.pending, (state) => {
				return {
					...state,
					bookingCustomerChangeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingCustomerChange.fulfilled, (state, action) => {
				return {
					...state,
					booking: deepCopy(action.payload),
					bookingCustomerChangeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingCustomerChange.rejected, (state, action) => {
				return {
					...state,
					bookingCustomerChangeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// BOOKING_SIGN_AND_REGISTER
		builder
			.addCase(bookingSignAndRegisterContract.pending, (state) => {
				return {
					...state,
					bookingSignAndRegisterContractRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingSignAndRegisterContract.fulfilled, (state, action) => {
				return {
					...state,
					booking: action.payload,
					bookingSignAndRegisterContractRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingSignAndRegisterContract.rejected, (state, actions) => {
				return {
					...state,
					bookingSignAndRegisterContractRequest: {
						inProgress: false,
						messages: actions.payload ? (actions.payload as any) : [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR CACHE
		 * CLEAR
		 */
		builder.addMatcher(isAnyOf(bookingDetailClear, clearGlobalCache), () => {
			return initialState;
		});
		/**
		 * BOOKING_PICKUP_START_CONTRACT
		 * BOOKING_REGISTER_CONTRACT
		 * BOOKING_DO_DIRECT_PICKUP
		 */
		builder.addMatcher(
			isAnyOf(
				bookingPickUpStartContract.fulfilled,
				bookingRegisterContract.fulfilled,
				bookingDoDirectPickUp.fulfilled,
			),
			(state, action) => {
				return {
					...state,
					booking: deepCopy(action.payload),
				};
			},
		);
		// PICKUP MODIFY
		builder
			.addMatcher(
				isAnyOf(pickUpModifyCustomerAndBooking.pending, pickUpCreateCustomerAndModifyBooking.pending),
				(state) => {
					return {
						...state,
						bookingModifyRequest: { inProgress: true, messages: [], ok: true },
					};
				},
			)
			.addMatcher(
				isAnyOf(pickUpModifyCustomerAndBooking.fulfilled, pickUpCreateCustomerAndModifyBooking.fulfilled),
				(state, action) => {
					return {
						...state,
						booking: deepCopy(action.payload.booking) as IBooking,
						bookingModifyRequest: { inProgress: false, messages: [], ok: true },
					};
				},
			)
			.addMatcher(
				isAnyOf(pickUpModifyCustomerAndBooking.rejected, pickUpCreateCustomerAndModifyBooking.rejected),
				(state, action) => {
					return {
						...state,
						bookingModifyRequest: { inProgress: false, messages: action.payload || [], ok: false },
					};
				},
			);
	},
});

export const detailReducer = detailSlice.reducer;
export const { bookingDetailSetBooking } = detailSlice.actions;
