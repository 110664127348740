import { createSlice } from '@reduxjs/toolkit';

import {
	fleetPrevisionClear,
	fleetPrevisionGetByPrevisionCode,
	fleetPrevisionGetByPrevisionCodeClear,
	fleetPrevisionInsert,
	fleetPrevisionModify,
	fleetPrevisionSearch,
} from '@crac/core/redux/actions/FleetPrevisionActions';
import type { IFleetPrevisionReducerState } from '@crac/core/redux-store/reducersState/fleet/FleetPrevisionReducerState';

const initialState: IFleetPrevisionReducerState = {
	fleetPrevisionInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetPrevisions: [],
	fleetPrevisionSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetPrevisionModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetPrevisionGetByPrevisionCode: undefined,
	fleetPrevisionGetByPrevisionCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const previsionSlice = createSlice({
	name: 'FleetPrevision',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(fleetPrevisionSearch.pending, (state) => {
				return {
					...state,
					fleetPrevisionSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetPrevisionSearch.fulfilled, (state, action) => {
				return {
					...state,
					fleetPrevisionSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetPrevisions: action.payload,
				};
			})
			.addCase(fleetPrevisionSearch.rejected, (state, action) => {
				return {
					...state,
					fleetPrevisionSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(fleetPrevisionInsert.pending, (state) => {
				return {
					...state,
					fleetPrevisionInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetPrevisionInsert.fulfilled, (state, action) => {
				return {
					...state,
					fleetPrevisionInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetPrevisions: [...state.fleetPrevisions, ...action.payload],
				};
			})
			.addCase(fleetPrevisionInsert.rejected, (state, action) => {
				return {
					...state,
					fleetPrevisionInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(fleetPrevisionModify.pending, (state) => {
				return {
					...state,
					fleetPrevisionModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetPrevisionModify.fulfilled, (state, action) => {
				return {
					...state,
					fleetPrevisionModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetPrevisions: [
						...state.fleetPrevisions.map((item) =>
							item.previsionCode === action.payload.previsionCode ? action.payload : item,
						),
					],
				};
			})
			.addCase(fleetPrevisionModify.rejected, (state, action) => {
				return {
					...state,
					fleetPrevisionModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(fleetPrevisionClear, (state) => {
			return {
				...state,
				fleetPrevisions: [],
			};
		});
		// GET BY OPERATION NUMBER
		builder
			.addCase(fleetPrevisionGetByPrevisionCode.pending, (state) => {
				return {
					...state,
					fleetPrevisionGetByPrevisionCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetPrevisionGetByPrevisionCode.fulfilled, (state, action) => {
				return {
					...state,
					fleetPrevisionGetByPrevisionCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetPrevisionGetByPrevisionCode: action.payload,
				};
			})
			.addCase(fleetPrevisionGetByPrevisionCode.rejected, (state, action) => {
				return {
					...state,
					fleetPrevisionGetByPrevisionCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR GET BY OPERATION NUMBER
		builder.addCase(fleetPrevisionGetByPrevisionCodeClear, (state) => {
			return {
				...state,
				fleetPrevisionGetByPrevisionCode: undefined,
			};
		});
	},
});

export const previsionReducer = previsionSlice.reducer;
