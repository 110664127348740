import type { IFineTransmitter } from '../../models/entities/FineTransmitter';
import type {
	IFineTransmitterGetByCodeParams,
	IFineTransmitterInsertParams,
	IFineTransmitterModifyParams,
	IFineTransmitterSearchParams,
} from '../../models/serviceParams/FineTransmitterParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { FineTransmitterEndPoints } from '../endPoints/FineTransmitterEndPoints';

/**
 * Search a fine transmitter
 * @return {Promise<IFineTransmitter[]>} `IFineTransmitter[]`
 */
export const fineTransmitterServiceSearch = (
	model: IFineTransmitterSearchParams,
): Promise<ServiceResponse<IFineTransmitter[]>> => {
	return Api.get<IFineTransmitter[], IFineTransmitterSearchParams>(FineTransmitterEndPoints.SEARCH, model);
};

/**
 * Insert a fine transmitter
 * @return {Promise<FineTransmitterType>} `FineTransmitterType`
 */
export const fineTransmitterServiceInsert = (
	model: IFineTransmitterInsertParams,
): Promise<ServiceResponse<IFineTransmitter>> => {
	return Api.post<IFineTransmitter, IFineTransmitterInsertParams>(FineTransmitterEndPoints.INSERT, model);
};

/**
 * Modify a fine transmitter
 * @return {Promise<FineTransmitterType>} `FineTransmitterType`
 */
export const fineTransmitterServiceModify = (
	model: IFineTransmitterModifyParams,
): Promise<ServiceResponse<IFineTransmitter>> => {
	return Api.post<IFineTransmitter, IFineTransmitterModifyParams>(FineTransmitterEndPoints.MODIFY, model);
};

/**
 * Get all fine transmitter
 * @return {Array<FineTransmitter>} List FineTransmitter object
 */
export const fineTransmitterServiceGetAll = (): Promise<ServiceResponse<IFineTransmitter[]>> => {
	return Api.get<IFineTransmitter[], undefined>(FineTransmitterEndPoints.GET_ALL);
};

/**
 * Get a fine transmitter by code
 * @param {string} code FineTransmitter code
 * @return {FineTransmitterPropType} FineTransmitter
 */
export const fineTransmitterServiceGetByCode = (
	model: IFineTransmitterGetByCodeParams,
): Promise<ServiceResponse<IFineTransmitter>> => {
	return Api.get<IFineTransmitter, IFineTransmitterGetByCodeParams>(FineTransmitterEndPoints.GET_BY_CODE, model);
};
