import { createAction } from '@reduxjs/toolkit';

import { tollServiceGetByBooking, tollServiceGetByPlateNumber } from '../../data/services/TollService';
import type { IToll } from '../../models/entities/Toll';
import type { ITollGetByBookingParams, ITollGetByPlateNumberParams } from '../../models/serviceParams/TollParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves tolls associated with a specific booking.
 *
 * @param {IToll[]} payload - The tolls to be retrieved.
 * @param {ITollGetByBookingParams} params - Parameters for identifying tolls related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of tolls.
 */
export const tollGetByBooking = createAsyncAction<IToll[], ITollGetByBookingParams>(
	'toll/getByBooking',
	tollServiceGetByBooking,
);

/**
 * Retrieves tolls associated with a specific plate number.
 *
 * @param {IToll[]} payload - The tolls to be retrieved.
 * @param {ITollGetByPlateNumberParams} params - Parameters for identifying tolls related to a specific plate number.
 * @returns {Promise<void>} - A promise that resolves to an array of tolls.
 */
export const tollGetByPlateNumber = createAsyncAction<IToll[], ITollGetByPlateNumberParams>(
	'toll/getByPlateNumber',
	tollServiceGetByPlateNumber,
);

/**
 * Clears the toll state.
 */
export const tollClear = createAction('toll/clear');
