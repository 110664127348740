import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { IGroup, IVehicleGroupByProvider } from '@crac/core/models/entities/VehicleGroup';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { employeeLoginAndLoadCachedData } from '@crac/core/redux/actions/EmployeeActions';
import { vehicleGroupGetByBranch, vehicleGroupGetByProvider } from '@crac/core/redux/actions/VehicleGroupActions';
import type { IVehicleGroupReducerState } from '@crac/core/redux-store/reducersState/vehicleGroup';

export const vehicleGroupByProviderStorageKey = 'vehicleGroupsByProvider';
export const vehicleGroupByBranchStorageKey = 'vehicleGroupsByBranch';
const { getItem, setItem, removeItem } = managerLocalStorage();

const initialState: IVehicleGroupReducerState = {
	vehicleGroup: null,
	vehicleGroupRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupsByBranch: (getItem(vehicleGroupByBranchStorageKey) as IGroup[]) || [],
	vehicleGroupsByBranchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupsByProvider: (getItem(vehicleGroupByProviderStorageKey) as IVehicleGroupByProvider[]) || [],
	vehicleGroupsByProviderRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const vehicleGroupSlice = createSlice({
	name: 'VehicleGroupSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, (state) => {
			removeItem(vehicleGroupByProviderStorageKey);
			removeItem(vehicleGroupByBranchStorageKey);
			return {
				...state,
				vehicleGroupsByBranch: [],
				vehicleGroupsByProvider: [],
			};
		});
		// GET BY BRANCH
		builder
			.addCase(vehicleGroupGetByBranch.pending, (state) => {
				return {
					...state,
					vehicleGroupsByBranchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupGetByBranch.fulfilled, (state, action) => {
				setItem(vehicleGroupByBranchStorageKey, action.payload);
				return {
					...state,
					vehicleGroupsByBranch: deepCopy(action.payload),
					vehicleGroupsByBranchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupGetByBranch.rejected, (state, action) => {
				return {
					...state,
					vehicleGroupsByBranchRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// GROUP GET BY PROVIDER
		builder
			.addCase(vehicleGroupGetByProvider.pending, (state) => {
				return {
					...state,
					vehicleGroupsByProviderRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupGetByProvider.fulfilled, (state, action) => {
				setItem(vehicleGroupByProviderStorageKey, action.payload);
				return {
					...state,
					vehicleGroupsByProvider: deepCopy(action.payload),
					vehicleGroupsByProviderRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupGetByProvider.rejected, (state, action) => {
				return {
					...state,
					vehicleGroupsByProviderRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			})
			.addCase(employeeLoginAndLoadCachedData.fulfilled, (state, action) => {
				setItem(vehicleGroupByProviderStorageKey, action.payload.vehicleGroups);
				return {
					...state,
					vehicleGroupsByProvider: deepCopy(action.payload.vehicleGroups),
				};
			});
	},
});

export const vehicleGroupReducer = vehicleGroupSlice.reducer;
