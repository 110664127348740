export enum InvoiceEndPoints {
	/**
	 * get invoices by plate number
	 * @host `/invoice/getByPlateNumber/`
	 */
	GET_BY_PLATE_NUMBER = '/invoice/getByPlateNumber/',

	/**
	 * get invoice by plate number and invoice code
	 * @host `/invoice/getByVehicleAndCode/`
	 */
	GET_BY_VEHICLE_AND_CODE = '/invoice/getByVehicleAndCode/',
	/**
	 * get link by id
	 * @host `/invoice/getLinkbyId/`
	 */
	GET_LINK_BY_ID = '/invoice/getLinkbyId/',
}
