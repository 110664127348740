import type { IBranch } from '../../models/entities/Branch';
import type { IBookingGetAvailabilityParams } from '../../models/serviceParams/BookingParams';
import type {
	IBranchGetByCountryAndPostCodeParams,
	IBranchGetByPartnerCodeAndTypeParams,
} from '../../models/serviceParams/BranchParams';
import { Api } from '../../modules/shared/api';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { BranchEndPoints } from '../endPoints/BranchEndPoints';

/**
 * Get all branch
 * @return {Array<Branch>} List Branch object
 */
export const branchServiceGetAll = (params?: IServiceBaseParams): Promise<ServiceResponse<IBranch[]>> => {
	return Api.get<IBranch[], IServiceBaseParams>(BranchEndPoints.GET_ALL, params);
};

/**
 * Retrieves branches by partner code and type.
 *
 * @param params - The parameters for the request.
 * @returns A promise that resolves to an array of strings.
 */
export const branchServiceGetByPartnerCodeAndType = (params: IBranchGetByPartnerCodeAndTypeParams) =>
	Api.get<string[], IBranchGetByPartnerCodeAndTypeParams>(BranchEndPoints.GET_BY_PARTNER_CODE_AND_TYPE, params);

/**
 * Retrieves a branch by country and postal code.
 * @param params - The parameters for the request.
 * @returns A promise that resolves to the branch data.
 */
export const branchServiceGetByCountryAndPostCode = (params: { country: string; postCode: string }) =>
	Api.get<IBranch, IBranchGetByCountryAndPostCodeParams>(BranchEndPoints.GET_BY_COUNTRY_AND_POSTCODE, params);

/**
 * Retrieves nearby branches with availability.
 *
 * @param params - The parameters for retrieving availability.
 * @returns A promise that resolves to an array of strings representing nearby branches.
 */
export const branchServicesGetNearbyBranchesWithAvailability = (params: IBookingGetAvailabilityParams) =>
	Api.get<string[], IBookingGetAvailabilityParams>(BranchEndPoints.GET_NEARBY_BRANCHES_WITH_AVAILABILITY, params);
