import { createSlice } from '@reduxjs/toolkit';

import {
	stopSaleClear,
	stopSalesDeactivate,
	stopSalesInsert,
	stopSalesSearch,
} from '@crac/core/redux/actions/StopSaleActions';
import type { IStopSalesReducerState } from '@crac/core/redux-store/reducersState/pricing/StopSalesReducerState';

const initialState: IStopSalesReducerState = {
	stopSales: [],
	stopSalesInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	stopSalesSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	stopSalesDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const stopSalesSlice = createSlice({
	name: 'stopSales',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(stopSaleClear, (state) => {
			return {
				...state,
				stopSales: [],
			};
		});
		builder.addCase(stopSalesInsert.pending, (state) => {
			return {
				...state,
				stopSalesInsertRequest: {
					ok: true,
					inProgress: true,
					messages: [],
				},
			};
		});
		builder.addCase(stopSalesInsert.rejected, (state, action) => {
			return {
				...state,
				stopSalesInsertRequest: {
					ok: false,
					inProgress: false,
					messages: action.payload || [],
				},
			};
		});
		builder.addCase(stopSalesInsert.fulfilled, (state) => {
			return {
				...state,
				stopSalesInsertRequest: {
					ok: true,
					inProgress: false,
					messages: [],
				},
			};
		});
		builder.addCase(stopSalesSearch.pending, (state) => {
			return {
				...state,
				stopSalesSearchRequest: {
					ok: true,
					inProgress: true,
					messages: [],
				},
			};
		});
		builder.addCase(stopSalesSearch.rejected, (state, action) => {
			return {
				...state,
				stopSalesSearchRequest: {
					ok: false,
					inProgress: false,
					messages: action.payload || [],
				},
			};
		});
		builder.addCase(stopSalesSearch.fulfilled, (state, action) => {
			return {
				...state,
				stopSalesSearchRequest: {
					ok: true,
					inProgress: false,
					messages: [],
				},
				stopSales: action.payload,
			};
		});
		builder.addCase(stopSalesDeactivate.pending, (state) => {
			return {
				...state,
				stopSalesDeactivateRequest: {
					ok: true,
					inProgress: true,
					messages: [],
				},
			};
		});
		builder.addCase(stopSalesDeactivate.rejected, (state, action) => {
			return {
				...state,
				stopSalesDeactivateRequest: {
					ok: false,
					inProgress: false,
					messages: action.payload || [],
				},
			};
		});
		builder.addCase(stopSalesDeactivate.fulfilled, (state, action) => {
			return {
				...state,
				stopSalesDeactivateRequest: {
					ok: true,
					inProgress: false,
					messages: [],
				},
				stopSales: [
					...state.stopSales.filter(({ id }) => !action.payload.map((stopSale) => stopSale.id).includes(id)),
					...action.payload,
				],
			};
		});
	},
});

export const stopSalesReducer = stopSalesSlice.reducer;
