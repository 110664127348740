import { SaleMarginEndPoints } from './SaleMarginEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleMargin } from '../entities/SaleMargin';

export interface ISaleMarginDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

export const saleMarginDeactivateService = (model: ISaleMarginDeactivateParams) => {
	return Api.post<ISaleMargin[], ISaleMarginDeactivateParams>(SaleMarginEndPoints.DEACTIVATE, model);
};
