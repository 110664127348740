import { ComponentConsumersType } from '@crac/core/models/types/ComponentConsumersType';

export interface IBaseComponentProps {
	consumer?: ComponentConsumersType;
	locale?: string;
}

export const BaseComponentValues: IBaseComponentProps = {
	consumer: ComponentConsumersType.RAC,
	locale: 'en',
};
