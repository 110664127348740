import { bankServiceGetAll } from '../../data/services/BankService';
import type { IBank } from '../../models/entities/Bank';
import type { IBankGetAllParams } from '../../models/serviceParams/BankParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for banks based on the provided search parameters.
 *
 * @params {IBank[]} payload - The banks to be returned.
 * @param {IBankGetAllParams} params - The search parameters.
 * @returns {Promise<void>} - A promise that resolves to an array of banks.
 */
export const bankGetAll = createAsyncAction<IBank[], IBankGetAllParams>('bank/getAll', bankServiceGetAll);
