import type { ICountry } from '../../models/entities/Country';
import type { ICountryGetByCodeParams } from '../../models/serviceParams/CountryParams';
import { Api } from '../../modules/shared/api';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { CountryEndPoints } from '../endPoints/CountryEndPoints';

export const countryServiceGetByCode = (model: ICountryGetByCodeParams): Promise<ServiceResponse<ICountry>> => {
	return Api.get<ICountry, ICountryGetByCodeParams>(CountryEndPoints.GET_BY_CODE, model);
};

export const countryServiceGetAll = (model: IServiceBaseParams): Promise<ServiceResponse<ICountry[]>> => {
	return Api.get<ICountry[], IServiceBaseParams>(CountryEndPoints.GET_ALL, model);
};
