import { combineReducers } from '@reduxjs/toolkit';

import { bookingsReducer as Bookings } from './detail/bookings/state/slice';
import { commentsReducer as Comments } from './detail/comments/state/slice';
import { couponsReducer as Coupons } from './detail/coupons/state/slice';
import { addressReducer as Address } from './detail/data/state/slices/address';
import { detailReducer as Detail } from './detail/data/state/slices/detail';
import { pendingsReducer as Pendings } from './detail/pendings/state/slice';
import { customerPaymentLinesReducer as PaymentLines } from './payment/state/slice';
import { searchReducer as Search } from './search/state/slice';

export const customerReducer = combineReducers({
	Address,
	Bookings,
	Comments,
	Coupons,
	Detail,
	PaymentLines,
	Pendings,
	Search,
});
