export enum AgencyEndPoints {
	/**
	 * @host `/agency/search/`
	 */
	SEARCH = '/agency/search/',

	/**
	 * @host `/agency/getbycode/`
	 */
	GET_BY_CODE = '/agency/getbycode/',
}
