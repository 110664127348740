export enum RevisionTypeEndPoints {
	/**
	 * Revision type search
	 * @host `/revisionType/search/`
	 */
	SEARCH = '/revisionType/search/',
	/**
	 * Revision type insert
	 * @host `/revisionType/insert/`
	 */
	INSERT = '/revisionType/insert/',
	/**
	 * Revision type modify
	 * @host `/revisionType/modify/`
	 */
	MODIFY = '/revisionType/modify/',
	/**
	 * Revision type getAll
	 * @host `/revisionType/getAll/`
	 */
	GET_ALL = '/revisionType/getAll/',
	/**
	 * Revision type deactivate
	 * @host `/revisionType/deactivate`
	 */
	DEACTIVATE = '/revisionType/deactivate',
	/**
	 * Revision type reactivate
	 * @host `/revisionType/reactivate`
	 */
	REACTIVATE = '/revisionType/reactivate',
}
