import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const DepositRate = lazyWithRetry(() => import('./pages/DepositRate'));
const DepositRateNew = lazyWithRetry(() => import('./pages/DepositRateNew'));
const DepositRateEdit = lazyWithRetry(() => import('./pages/DepositRateEdit'));

export const depositRateRoutes: { [key: number]: IRouteConfig } = {};

// DEPOSIT
depositRateRoutes[routesIdMap.DepositRate] = {
	component: DepositRate,
	exact: true,
	id: routesIdMap.DepositRate,
	name: 'Deposit rates',
	menuText: 'Deposit',
	path: '/pricing/deposit-rate',
	permissions: getPricingPermission([PermissionsType.PricingDepositRate]),
	childrens: [routesIdMap.DepositRateNew, routesIdMap.DepositRateEdit],
	icon: 'money',
};

depositRateRoutes[routesIdMap.DepositRateNew] = {
	component: DepositRateNew,
	exact: true,
	isChild: true,
	id: routesIdMap.DepositRateNew,
	name: 'New deposit rate',
	path: '/pricing/deposit-rate/new',
	permissions: getPricingPermission([PermissionsType.PricingDepositRate]),
	icon: 'plus',
	menuText: 'New deposit rate',
};

depositRateRoutes[routesIdMap.DepositRateEdit] = {
	component: DepositRateEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.DepositRateEdit,
	name: 'Modify deposit rates',
	path: '/pricing/deposit-rate/modify/:id',
	permissions: getPricingPermission([PermissionsType.PricingDepositRate]),
	icon: 'pencil',
};
