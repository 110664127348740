import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { ISpendingType } from '@crac/core/models/entities/SpendingType';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { employeeLoginAndLoadCachedData } from '@crac/core/redux/actions/EmployeeActions';
import { spendingTypeGetAll } from '@crac/core/redux/actions/SpendingTypeActions';
import type { ISpendingTypeReducerState } from '@crac/core/redux-store/reducersState/spendingType';

export const spendingTypesStorageKey = 'spendingTypes';
const { setItem, getItem, removeItem } = managerLocalStorage();

const initialState: ISpendingTypeReducerState = {
	spendingTypes: (getItem(spendingTypesStorageKey) as ISpendingType[]) || [],
	spendingTypesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const spendingTypeSlice = createSlice({
	name: 'SpendingTypeSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, (state) => {
			removeItem(spendingTypesStorageKey);
			return {
				...state,
				spendingTypes: [],
			};
		});
		// GET ALL
		builder
			.addCase(spendingTypeGetAll.pending, (state) => {
				return {
					...state,
					spendingTypesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(spendingTypeGetAll.fulfilled, (state, action) => {
				setItem(spendingTypesStorageKey, action.payload);
				return {
					...state,
					spendingTypes: deepCopy(action.payload),
					spendingTypesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(spendingTypeGetAll.rejected, (state, action) => {
				return {
					...state,
					spendingTypesRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			})
			.addCase(employeeLoginAndLoadCachedData.fulfilled, (state, action) => {
				setItem(spendingTypesStorageKey, action.payload.spendingTypes);
				return {
					...state,
					spendingTypes: deepCopy(action.payload.spendingTypes),
				};
			});
	},
});

export const spendingTypeReducer = spendingTypeSlice.reducer;
