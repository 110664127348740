import { createAction } from '@reduxjs/toolkit';

import {
	serviceChoosableServiceConfigurationDeactivate,
	serviceChoosableServiceConfigurationInsert,
	serviceChoosableServiceConfigurationModify,
	serviceChoosableServiceConfigurationSearch,
} from '../../data/services/ChoosableServiceConfigurationService';
import type { IChoosableServiceConfiguration } from '../../models/entities/ChoosableServiceConfiguration';
import type {
	IChoosableServiceConfigurationDeactivateParams,
	IChoosableServiceConfigurationInsertParams,
	IChoosableServiceConfigurationModifyParams,
	IChoosableServiceConfigurationSearchParams,
} from '../../models/serviceParams/ChoosableServiceConfigurationParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for choosable service configurations based on specified parameters.
 *
 * @param {IChoosableServiceConfiguration[]} payload - The service configurations to be returned.
 * @param {IChoosableServiceConfigurationSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of choosable service configurations.
 */
export const choosableServiceConfigurationSearch = createAsyncAction<
	IChoosableServiceConfiguration[],
	IChoosableServiceConfigurationSearchParams
>('choosableServiceConfiguration/search', serviceChoosableServiceConfigurationSearch);

/**
 * Inserts new choosable service configurations.
 *
 * @param {IChoosableServiceConfiguration[]} payload - The service configurations to be inserted.
 * @param {IChoosableServiceConfigurationInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the service configurations are inserted.
 */
export const choosableServiceConfigurationInsert = createAsyncAction<
	IChoosableServiceConfiguration[],
	IChoosableServiceConfigurationInsertParams
>('choosableServiceConfiguration/insert', serviceChoosableServiceConfigurationInsert);

/**
 * Modifies an existing choosable service configuration.
 *
 * @param {IChoosableServiceConfiguration} payload - The service configuration to be modified.
 * @param {IChoosableServiceConfigurationModifyParams} params - Parameters for the modification.
 * @returns {Promise<void>} - A promise that resolves after the service configuration is modified.
 */
export const choosableServiceConfigurationModify = createAsyncAction<
	IChoosableServiceConfiguration,
	IChoosableServiceConfigurationModifyParams
>('choosableServiceConfiguration/modify', serviceChoosableServiceConfigurationModify);

/**
 * Removes deactivated choosable service configurations from the state.
 *
 * @param {number} payload - The ID of the service configuration to be removed.
 */
export const choosableServiceConfigurationRemoveDeactivated = createAction<number>(
	'choosableServiceConfiguration/removeDeactivated',
);

/**
 * Deactivates choosable service configurations.
 *
 * @param {IChoosableServiceConfiguration[]} payload - The service configurations to be deactivated.
 * @param {IChoosableServiceConfigurationDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the service configurations are deactivated.
 */
export const choosableServiceConfigurationDeactivate = createAsyncAction<
	IChoosableServiceConfiguration[],
	IChoosableServiceConfigurationDeactivateParams
>('choosableServiceConfiguration/deactivate', serviceChoosableServiceConfigurationDeactivate);

/**
 * Clears the choosable service configuration state.
 */
export const choosableServiceConfigurationClear = createAction('choosableServiceConfiguration/clear');
