import { invoiceServiceGetByPlateNumber, invoiceServiceGetByVehicleAndCode } from '../../data/services/InvoiceService';
import type { IInvoice } from '../../models/entities/Invoice';
import type {
	IInvoiceGetByPlateNumberParams,
	IInvoiceGetByVehicleAndCodeParams,
} from '../../models/serviceParams/InvoiceParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
/**
 * Searches for invoices based on specified parameters.
 *
 * @param {IInvoice[]} payload - The invoices to be returned.
 * @param {IInvoiceGetByPlateNumberParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of invoices.
 */
export const invoiceGetByPlateNumber = createAsyncAction<IInvoice[], IInvoiceGetByPlateNumberParams>(
	'invoice/getByPlateNumber',
	invoiceServiceGetByPlateNumber,
);
/**
 * Gets an invoice by vehicle and code.
 *
 * @param {IInvoice} payload - The invoice to be returned.
 * @param {IInvoiceGetByVehicleAndCodeParams} params - Parameters detailing the invoice.
 * @returns {Promise<void>} - A promise that resolves to the invoice.
 */
export const invoiceGetByVehicleAndCode = createAsyncAction<IInvoice, IInvoiceGetByVehicleAndCodeParams>(
	'invoice/getByVehicleAndCode',
	invoiceServiceGetByVehicleAndCode,
);

/**
 * Clears the invoice.
 */
export const invoiceClear = createSyncAction('invoice/clear');
