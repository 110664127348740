export enum OneWayEndPoints {
	/**
	 * One way search
	 * @host `/oneway/search/`
	 */
	SEARCH = '/oneway/search',

	/**
	 * One way insert
	 * @host `/oneway/insert/`
	 */
	INSERT = '/oneway/insert',

	/**
	 * One way modify
	 * @host `/oneway/modify/`
	 */
	MODIFY = '/oneway/modify',

	/**
	 * One way delete
	 * @host `/oneway/delete/`
	 */
	DELETE = '/oneway/delete',
}
