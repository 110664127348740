import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const BranchSchedule = lazyWithRetry(() => import('./pages/BranchSchedule'));
const BranchScheduleNew = lazyWithRetry(() => import('./pages/BranchScheduleNew'));
const BranchScheduleEdit = lazyWithRetry(() => import('./pages/BranchScheduleEdit'));

export const branchScheduleRoutes: { [key: number]: IRouteConfig } = {};

// BRAND SCHEDULE
branchScheduleRoutes[routesIdMap.BranchSchedule] = {
	component: BranchSchedule,
	exact: true,
	id: routesIdMap.BranchSchedule,
	name: 'Branch schedules',
	path: '/pricing/branch-schedule/',
	permissions: getPricingPermission([PermissionsType.PricingBranchSchedule]),
	childrens: [routesIdMap.BranchScheduleNew, routesIdMap.BranchScheduleEdit],
	icon: 'building',
	menuText: 'Branch schedules',
};

branchScheduleRoutes[routesIdMap.BranchScheduleNew] = {
	component: BranchScheduleNew,
	exact: true,
	isChild: true,
	id: routesIdMap.BranchScheduleNew,
	name: 'New branch schedule',
	path: '/pricing/branch-schedule/new',
	permissions: getPricingPermission([PermissionsType.PricingBranchSchedule]),
	icon: 'plus',
	menuText: 'New branch schedule',
};

branchScheduleRoutes[routesIdMap.BranchScheduleEdit] = {
	component: BranchScheduleEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.BranchScheduleEdit,
	name: 'Modify branch schedule',
	path: '/pricing/branch-schedule/modify/:id',
	permissions: getPricingPermission([PermissionsType.PricingBranchSchedule]),
	icon: 'pencil',
};
