import { useEffect, useMemo } from 'react';

import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { Button } from '@crac/components/external/reactstrap/button';
import { Container } from '@crac/components/external/reactstrap/container';
import { gCSwal } from '@crac/components/external/sweet-alert';
import { FullPageLoader } from '@crac/components/intranet/shared/fullPageLoader';
import { Aside } from '@crac/components/intranet/shared/layout/aside/Aside';
import { AppSidebar } from '@crac/components/intranet/shared/layout/sidebar';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import { setHostName } from '@crac/core/redux/actions/ComputerActions';
import { emailSendFeedback } from '@crac/core/redux/actions/EmailActions';
import { employeeUpdate } from '@crac/core/redux/actions/EmployeeActions';
import { toggleFeedBackForm } from '@crac/core/redux/actions/FeedBackActions';
import { toggleEnabledPermissions, updateUserPermissions } from '@crac/core/redux/actions/PermissionsActions';

import { REAL_TIME_SCRIPT_CODES } from '~/constants/RealTimeEventNames';
import { AppContext } from '~/context/AppContext';
import { RacSignalContext } from '~/context/RacSignalContext';
import { useBookingDetailSelector } from '~/features/booking/detail/detail/state/selector';
import {
	useEmployeeBranchSelector,
	useEmployeeProviderSelector,
	useEmployeeSelector,
} from '~/features/employee/state/selectors';
import { FeedBack } from '~/features/shared/components/layout/feedBack';
import { PermissionTool } from '~/features/shared/components/layout/PermissionTool';
import { RingCentralIntegration } from '~/features/shared/components/layout/ringcentralIntegration';
import { getNavigationItems } from '~/features/shared/components/layout/utils/menuItems';
import { useAlertMessage } from '~/features/shared/hooks/useAlertMessage';
import { useBindAndMemoizeActions } from '~/features/shared/hooks/useBindAndMemoizeActions';
import {
	useFeedbackSelector,
	useLoaderSelector,
	usePermissionsSelector,
} from '~/features/shared/state/selectors/CommonsSelectors';
import { useEmailSelector } from '~/features/shared/state/selectors/EmailSelectors';
import { removeBrowserCache } from '~/serviceWorker';

import { BreadCrumbs } from './Breadcrumbs';
import { AppHeader } from './header';
import { useSetHostName } from './hooks/useSetHostName';
import { useSignalR } from './hooks/useSignalR';

const actionsImported = {
	emailSendFeedback,
	employeeUpdate,
	setHostName,
	toggleFeedBack: toggleFeedBackForm,
	togglePermissions: toggleEnabledPermissions,
	updatePermissions: updateUserPermissions,
};

export const LayoutComponent = () => {
	const location = useLocation();
	const history = useNavigate();

	const actions = useBindAndMemoizeActions(actionsImported);
	const { emailSendFeedback, employeeUpdate, setHostName, toggleFeedBack, togglePermissions, updatePermissions } =
		actions;

	const { booking } = useBookingDetailSelector();
	const employee = useEmployeeSelector();
	const { isOpen: feedBackIsOpen } = useFeedbackSelector();
	const loader = useLoaderSelector();
	const permissionsTool = usePermissionsSelector();
	const { sendFeedBackRequest } = useEmailSelector();

	useSetHostName(setHostName);
	const hubConnection = useSignalR(employee.token);
	// const { handleLoadCachedData, isSubmitted, isLoading } = useLoadCachedData(employee, true);
	const { showAlert } = useAlertMessage();

	const updateAllBrowsers = () => {
		window.location.reload();
	};

	const refreshEmployeeData = (data: any) => {
		employeeUpdate({
			...employee,
			branchCode: data.branchCode,
			name: data.name,
			permissions: data.permissions,
			token: data.token,
			userName: data.userName,
		});
	};

	const beforeUpdatingBrowser = async () => {
		await showAlert({
			title: 'We have updated the application!',
			message: 'It is necessary to refresh the browser.',
			type: 'warning',
		});

		updateAllBrowsers();
	};

	const launchScript = (response: any): void => {
		if (response.ok) {
			switch (response.script) {
				case 'F5':
					beforeUpdatingBrowser();
					break;
				case 'CLOSE_SESSION':
					history('/login');
					break;
				case 'DELETE_BROWSER_CACHES':
					removeBrowserCache();
					setTimeout(() => {
						window.location.reload();
						window.location.reload();
					}, 0);
					break;
				case REAL_TIME_SCRIPT_CODES.UPDATE_ALL_CACHED_DATA:
					// handleLoadCachedData();
					break;
				default:
					break;
			}
		}
	};

	const showMessage = async (res: any) => {
		if (res.ok) {
			await gCSwal(res.message, '', 'info');
		}
	};

	const showGlobalMessage = async (res: any) => {
		if (res.ok) {
			await gCSwal(res.message, '', 'info');
		}
	};

	useEffect(() => {
		if (hubConnection) {
			hubConnection.addListener('onLaunchScript', launchScript);
			hubConnection.addListener('onRefreshEmployeeData', refreshEmployeeData);
			hubConnection.addListener('onShowPrivateMessage', showMessage);
			hubConnection.addListener('onShowGlobalMessage', showGlobalMessage);
		}

		/*
		 * REFRESH WINDOW WHEN CACHED DATA FINISH LOADING
		 * if (isSubmitted && !isLoading) {
		 * 	updateAllBrowsers();
		 * }
		 */

		return () => {
			hubConnection.removeListener('onLaunchScript');
			hubConnection.removeListener('onRefreshEmployeeData');
			hubConnection.removeListener('onShowPrivateMessage');
			hubConnection.removeListener('onShowGlobalMessage');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [hubConnection]);

	const permissions =
		permissionsTool.isEnabled && employee.permissions.includes(PermissionsType.IT)
			? (permissionsTool.permissions as string[])
			: employee.permissions;
	const employeeProvider = useEmployeeProviderSelector();
	const employeeBranch = useEmployeeBranchSelector();

	const valuesEmployee = useMemo(
		() => ({
			employeeBranch,
			employeeProvider,
			permissions,
			user: { ...employee, permissions },
		}),
		[employee, employeeBranch, employeeProvider, permissions],
	);

	return (
		<RacSignalContext.Provider value={hubConnection}>
			<AppContext.Provider value={valuesEmployee}>
				<div className="app">
					<FullPageLoader
						icon={loader.icon}
						isVisible={loader.isVisible as boolean}
						message={loader.message}
					/>
					<AppHeader employee={employee} />
					<section className="app-body">
						<AppSidebar
							display="lg"
							fixed
							items={getNavigationItems(permissions)}
							location={location}
							minimized
							permissions={permissions}
							renderSidebarHeader={() => (
								<Button
									color="danger"
									isBlock
									onClick={() => {
										document.body.classList.remove('sidebar-show');
										toggleFeedBack(true);
									}}
								>
									<i className="fa fa-fw fa-exclamation-triangle" /> Send feedBack
								</Button>
							)}
						/>
						<main className="main">
							<BreadCrumbs />
							<Container fluid>
								<Outlet />
							</Container>
						</main>
						{permissions.includes(PermissionsType.IT) && (
							<Aside>
								<PermissionTool
									employeePermissions={employee.permissions}
									isEnabled={permissionsTool.isEnabled}
									permissions={permissionsTool.permissions as string[]}
									togglePermissions={togglePermissions}
									updatePermissions={updatePermissions}
								/>
							</Aside>
						)}
					</section>
					{permissions.includes(PermissionsType.BookingNewExternal) && (
						<RingCentralIntegration history={history} />
					)}
					<FeedBack
						booking={booking}
						employee={employee}
						isOpen={feedBackIsOpen}
						onToggleFeedback={toggleFeedBack}
						sendFeedBack={emailSendFeedback}
						sendFeedBackRequest={sendFeedBackRequest}
					/>
				</div>
			</AppContext.Provider>
		</RacSignalContext.Provider>
	);
};

export const Layout = LayoutComponent;
