import { createSlice } from '@reduxjs/toolkit';

import { reducerStorage } from '@crac/core/helpers/reducerStorage';
import { toggleEnabledPermissions, updateUserPermissions } from '@crac/core/redux/actions/PermissionsActions';

import { PERMISSIONS_TOOL_KEY } from '~/constants/LocalStorageKey';

export interface IPermissionsToolState {
	isEnabled: boolean;
	permissions: string[] | null;
}

const initialState: IPermissionsToolState = {
	isEnabled: false,
	permissions: [],
};

const { getStorage, removeStorage, updateStorage } = reducerStorage<IPermissionsToolState>(
	PERMISSIONS_TOOL_KEY,
	initialState,
	'sessionStorage',
);

const PermissionsSlice = createSlice({
	name: 'Permissions',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(toggleEnabledPermissions, (state, action) => {
			const currentState = state ? state : getStorage();
			if (action.payload) {
				return updateStorage(currentState, {
					...currentState,
					isEnabled: action.payload.isEnabled,
					permissions: action.payload.permissions,
				});
			}

			removeStorage();
			return initialState;
		});
		builder.addCase(updateUserPermissions, (state, action) => {
			const currentState = state ? state : getStorage();
			return updateStorage(currentState, {
				...currentState,
				permissions: action.payload,
			});
		});
	},
});

export const permissionsToolReducer = PermissionsSlice.reducer;
