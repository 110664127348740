import { createSlice } from '@reduxjs/toolkit';

import {
	accidentClear,
	accidentClearBooking,
	accidentClearCustomer,
	accidentClearVehicle,
	accidentDelete,
	accidentGetByAccidentPart,
	accidentGetByBooking,
	accidentGetByVehicle,
	accidentGetPartData,
	accidentInsert,
	accidentModify,
	accidentSearch,
} from '@crac/core/redux/actions/AccidentActions';
import type { IAccidentReducerState } from '@crac/core/redux-store/reducersState/accident';

const initialState: IAccidentReducerState = {
	accidentDetail: { data: [], inProgress: false, messages: [], ok: true },
	accidents: { data: [], inProgress: false, messages: [], ok: true },
	accidentSearchRequest: { inProgress: false, messages: [], ok: true },
	accidentInsertRequest: { inProgress: false, messages: [], ok: true },
	accidentModifyRequest: { inProgress: false, messages: [], ok: true },
	accidentDeleteRequest: { inProgress: false, messages: [], ok: true },
	booking: null,
	customer: null,
	vehicle: null,
	accidentPartDataRequest: { inProgress: false, messages: [], ok: true },
};

const accidentSlice = createSlice({
	name: 'AccidentSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(accidentClear, () => {
			return initialState;
		});
		// GET BY ACCIDENT PART
		builder
			.addCase(accidentGetByAccidentPart.pending, (state) => {
				return {
					...state,
					accidentDetail: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByAccidentPart.fulfilled, (state, action) => {
				return {
					...state,
					accidentDetail: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByAccidentPart.rejected, (state) => {
				return {
					...state,
					accidentDetail: {
						data: state.accidentDetail ? state.accidentDetail.data : [],
						inProgress: false,
						messages: [],
						ok: false,
					},
				};
			});
		// GET BY VEHICLE
		builder
			.addCase(accidentGetByVehicle.pending, (state) => {
				return {
					...state,
					accidents: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByVehicle.fulfilled, (state, action) => {
				return {
					...state,
					accidents: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByVehicle.rejected, (state, action) => {
				return {
					...state,
					accidents: {
						data: state.accidents ? state.accidents.data : [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY BOOKING
		builder
			.addCase(accidentGetByBooking.pending, (state) => {
				return {
					...state,
					accidentDetail: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					accidentDetail: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByBooking.rejected, (state) => {
				return {
					...state,
					accidentDetail: {
						data: state.accidentDetail ? state.accidentDetail.data : [],
						inProgress: false,
						messages: [],
						ok: false,
					},
				};
			});
		// SEARCH
		builder
			.addCase(accidentSearch.pending, (state) => {
				return {
					...state,
					accidentSearchRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(accidentSearch.fulfilled, (state, action) => {
				return {
					...state,
					accidents: { data: action.payload, inProgress: false, messages: [], ok: true },
					accidentSearchRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(accidentSearch.rejected, (state, action) => {
				return {
					...state,
					accidentSearchRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// INSERT
		builder
			.addCase(accidentInsert.pending, (state) => {
				return {
					...state,
					accidentInsertRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(accidentInsert.fulfilled, (state, action) => {
				return {
					...state,
					accidents: {
						data: [...state.accidents.data, action.payload],
						inProgress: false,
						messages: [],
						ok: true,
					},
					accidentInsertRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(accidentInsert.rejected, (state, action) => {
				return {
					...state,
					accidentInsertRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// MODIFY
		builder
			.addCase(accidentModify.pending, (state) => {
				return {
					...state,
					accidentModifyRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(accidentModify.fulfilled, (state, action) => {
				const { plateNumber, documentNumber, key } = action.payload;
				const currentAccidents = state.accidents?.data.map((accident) =>
					accident.plateNumber === plateNumber &&
					accident.documentNumber === documentNumber &&
					accident.key === key
						? action.payload
						: accident,
				);

				return {
					...state,
					accidents: {
						data: currentAccidents ?? [],
						inProgress: false,
						messages: [],
						ok: true,
					},
					accidentModifyRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(accidentModify.rejected, (state, action) => {
				return {
					...state,
					accidentModifyRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// DELETE
		builder
			.addCase(accidentDelete.pending, (state) => {
				return {
					...state,
					accidentDeleteRequest: { inProgress: true, messages: [], ok: true },
				};
			})
			.addCase(accidentDelete.fulfilled, (state, action) => {
				const { plateNumber, documentNumber, key } = action.payload;
				const currentAccidents = state.accidents?.data.filter(
					(accident) =>
						!(
							accident.plateNumber === plateNumber &&
							accident.documentNumber === documentNumber &&
							accident.key === key
						),
				);

				return {
					...state,
					accidents: { data: currentAccidents ?? [], inProgress: false, messages: [], ok: true },
					accidentDeleteRequest: { inProgress: false, messages: [], ok: true },
				};
			})
			.addCase(accidentDelete.rejected, (state, action) => {
				return {
					...state,
					accidentDeleteRequest: { inProgress: false, messages: action.payload || [], ok: false },
				};
			});
		// ACCIDENT GET PART DATA
		builder.addCase(accidentGetPartData.pending, (state) => {
			return {
				...state,
				accidentPartDataRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(accidentGetPartData.rejected, (state, action) => {
			return {
				...state,
				accidentPartDataRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(accidentGetPartData.fulfilled, (state, action) => {
			return {
				...state,
				accidentPartDataRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				booking: action.payload?.booking,
				customer: action.payload?.customer,
				vehicle: action.payload?.vehicle,
			};
		});
		// CLEAR BOOKING
		builder.addCase(accidentClearBooking, (state) => {
			return {
				...state,
				booking: undefined,
			};
		});
		// CLEAR CUSTOMER
		builder.addCase(accidentClearCustomer, (state) => {
			return {
				...state,
				customer: undefined,
			};
		});
		// CLEAR VEHICLE
		builder.addCase(accidentClearVehicle, (state) => {
			return {
				...state,
				vehicle: undefined,
			};
		});
	},
});

export const accidentReducer = accidentSlice.reducer;
