import {
	excessTypeServiceDelete,
	excessTypeServiceInsert,
	excessTypeServiceModify,
	excessTypeServiceSearch,
} from '../../data/services/ExcessTypeService';
import type { IExcessType } from '../../models/entities/ExcessType';
import type {
	IExcessTypeDeleteParams,
	IExcessTypeSaveParams,
	IExcessTypeSearchParams,
} from '../../models/serviceParams/ExcessTypeParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/*
 * Clear excess type state
 */
export const excessTypeClear = createSyncAction('accessType/Clear');

/**
 * Searches for excess type based on specified parameters.
 *
 * @param {IExcessType[]} payload - The excess type to be returned.
 * @param {IExcessTypeSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of excess type.
 */
export const excessTypeSearch = createAsyncAction<IExcessType[], IExcessTypeSearchParams>(
	'accessType/Search',
	excessTypeServiceSearch,
);

/**
 * Inserts new excess type into the system.
 *
 * @param {IExcessType} payload - The excess type to be inserted.
 * @param {IExcessTypeSaveParams} params - Parameters detailing the excess type to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the excess type is successfully inserted.
 */
export const excessTypeInsert = createAsyncAction<IExcessType, IExcessTypeSaveParams>(
	'accessType/insert',
	excessTypeServiceInsert,
);

/**
 * Modifies an existing excess type.
 *
 * @param {IExcessType} payload - The excess type to be modified.
 * @param {IExcessTypeSaveParams} params - Parameters detailing the modifications to be made on the excess type.
 * @returns {Promise<void>} - A promise that resolves after the excess type is successfully modified.
 */
export const excessTypeModify = createAsyncAction<IExcessType, IExcessTypeSaveParams>(
	'accessType/modify',
	excessTypeServiceModify,
);

/**
 * Deletes an existing excess type.
 *
 * @param {IExcessType} payload - The excess type to be deleted.
 * @param {IExcessTypeSaveParams} params - Parameters detailing the excess type to be deleted.
 * @returns {Promise<void>} - A promise that resolves after the excess type is successfully deleted.
 */
export const excessTypeDelete = createAsyncAction<IExcessType, IExcessTypeDeleteParams>(
	'accessType/delete',
	excessTypeServiceDelete,
);
