import { createAction } from '@reduxjs/toolkit';

import {
	franchiseLineServiceAddLinks,
	franchiseLineServiceGetLinesByBooking,
	franchiseLineServiceGetLinesByVehicle,
	franchiseLineServiceInsert,
	franchiseLineServiceRemove,
	franchiseLineServiceRepair,
	franchiseLineServiceSearch,
} from '../../data/services/FranchiseLineService';
import type { IFranchiseLine } from '../../models/entities/FranchiseLine';
import type { IFranchiseLineRepair } from '../../models/entities/FranchiseLineRepair';
import type { ILink } from '../../models/entities/Link';
import type {
	IFranchiseLineAddLinksParams,
	IFranchiseLineGetByBookingParams,
	IFranchiseLineGetByVehicleParams,
	IFranchiseLineInsertParams,
	IFranchiseLineRemoveParams,
	IFranchiseLineRepairParams,
	IFranchiseLineSearchParams,
} from '../../models/serviceParams/FranchiseLineParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Sets the damages of a franchise line.
 */
export const franchiseLineSetDamages = createSyncAction('franchiseLine/setDamages');

/**
 * Clears the repair state of a franchise line.
 */
export const franchiseLineRepairClear = createSyncAction('franchiseLine/repairClear');

/**
 * Searches for franchise lines based on the provided search parameters.
 *
 * @param {IFranchiseLineRepair[]} payload - The franchise lines that match the search parameters.
 * @param {IFranchiseLineSearchParams} params - The parameters to search for franchise lines.
 * @returns {Promise<void>} - A promise that resolves to an array of franchise lines.
 */
export const franchiseLineSearch = createAsyncAction<IFranchiseLineRepair[], IFranchiseLineSearchParams>(
	'franchiseLine/search',
	franchiseLineServiceSearch,
);

/**
 * Retrieves franchise lines associated with a specific booking.
 *
 * @param {IFranchiseLine[]} payload - The franchise lines to be retrieved.
 * @param {IFranchiseLineGetByBookingParams} params - Parameters to identify franchise lines related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of franchise lines.
 */
export const franchiseLineGetByBooking = createAsyncAction<IFranchiseLine[], IFranchiseLineGetByBookingParams>(
	'franchiseLine/getByBooking',
	franchiseLineServiceGetLinesByBooking,
);

/**
 * Retrieves franchise lines associated with a specific vehicle's plate number.
 *
 * @param {IFranchiseLine[]} payload - The franchise lines to be retrieved.
 * @param {IFranchiseLineGetByVehicleParams} params - Parameters to identify franchise lines related to a specific vehicle's plate number.
 * @returns {Promise<void>} - A promise that resolves to an array of franchise lines.
 */
export const franchiseLineGetByPlateNumber = createAsyncAction<IFranchiseLine[], IFranchiseLineGetByVehicleParams>(
	'franchiseLine/getByPlateNumber',
	franchiseLineServiceGetLinesByVehicle,
);

/**
 * Inserts new franchise lines into the system.
 *
 * @param {IFranchiseLine[]} payload - The franchise lines to be inserted.
 * @param {IFranchiseLineInsertParams} params - Parameters detailing the franchise lines to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the franchise lines are successfully inserted.
 */
export const franchiseLineInsert = createAsyncAction<IFranchiseLine[], IFranchiseLineInsertParams>(
	'franchiseLine/insert',
	franchiseLineServiceInsert,
);

/**
 * Removes a specific franchise line.
 *
 * @param {boolean} payload - The result of the removal operation.
 * @param {IFranchiseLineRemoveParams} params - Parameters for the franchise line to be removed.
 * @returns {Promise<void>} - A promise that resolves after the franchise line is removed.
 */
export const franchiseLineRemove = createAsyncAction<boolean, IFranchiseLineRemoveParams>(
	'franchiseLine/remove',
	franchiseLineServiceRemove,
);

/**
 * Repairs a specific franchise line.
 *
 * @param {IFranchiseLine} payload - The repaired franchise line.
 * @param {IFranchiseLineRepairParams} params - Parameters detailing the repair of the franchise line.
 * @returns {Promise<void>} - A promise that resolves with the details of the repaired franchise line.
 */
export const franchiseLineRepair = createAsyncAction<IFranchiseLine, IFranchiseLineRepairParams>(
	'franchiseLine/repair',
	franchiseLineServiceRepair,
);

/**
 * Adds links to a specific franchise line.
 *
 * @param {ILink[]} payload - The links to be added.
 * @param {IFranchiseLineAddLinksParams} params - Parameters detailing the links to be added to the franchise line.
 * @returns {Promise<void>} - A promise that resolves after the links are added to the franchise line.
 */
export const franchiseLineAddLinks = createAsyncAction<ILink[], IFranchiseLineAddLinksParams>(
	'franchiseLine/addLinks',
	franchiseLineServiceAddLinks,
);

/**
 * Updates the list of franchise lines in the state.
 *
 * @param {IFranchiseLine[]} payload - The updated list of franchise lines.
 */
export const franchiseLineUpdateList = createAction<IFranchiseLine[]>('franchiseLine/updateList');
