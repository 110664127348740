import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Tolls = lazyWithRetry(() => import('./pages/Tolls'));

export const tollsRoutes: { [key: number]: IRouteConfig } = {};

tollsRoutes[routesIdMap.VehiclesDetailTolls] = {
	component: Tolls,
	exact: true,
	id: routesIdMap.VehiclesDetailTolls,
	name: 'Tolls',
	path: '/vehicles/detail/:id/tolls',
	permissions: [PermissionsType.VehicleDetail],
	icon: 'road',
};
