import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Lockers = lazyWithRetry(() => import('./pages/Lockers'));
const LockerManagement = lazyWithRetry(() => import('./pages/LockerManagement'));

export const lockersRoutes: { [key: number]: IRouteConfig } = {};

lockersRoutes[routesIdMap.Lockers] = {
	component: Lockers,
	exact: true,
	id: routesIdMap.Lockers,
	name: 'Lockers',
	path: '/vehicle/lockers',
	permissions: [PermissionsType.LockerView],
	icon: 'table',
	menuText: 'Lockers',
};

lockersRoutes[routesIdMap.LockersManagement] = {
	component: LockerManagement,
	exact: true,
	id: routesIdMap.LockersManagement,
	name: 'Lockers management',
	path: '/vehicle/set-locker-cell',
	permissions: [PermissionsType.LockerManagement],
	icon: 'table',
	menuText: 'Lockers management',
};
