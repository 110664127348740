import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Detail = lazyWithRetry(() => import('./pages/Detail'));
const ChangeCarrier = lazyWithRetry(() => import('./pages/ChangeCarrier'));
const ChangeKeysLocation = lazyWithRetry(() => import('./pages/ChangeKeysLocation'));
const ChangeState = lazyWithRetry(() => import('./pages/ChangeState'));

export const detailRoutes: { [key: number]: IRouteConfig } = {};

detailRoutes[routesIdMap.VehiclesDetail] = {
	component: Detail,
	exact: true,
	id: routesIdMap.VehiclesDetail,
	name: 'Detail',
	path: '/vehicles/detail/:id',
	permissions: [PermissionsType.VehicleDetail],
	childrens: [routesIdMap.VehiclesDetailKeysLocation, routesIdMap.VehiclesDetailChangeCarrier],
	icon: 'car',
};

detailRoutes[routesIdMap.VehiclesDetailChangeCarrier] = {
	component: ChangeCarrier,
	exact: true,
	id: routesIdMap.VehiclesDetailChangeCarrier,
	name: 'Change carrier',
	path: '/vehicles/detail/:id/change-carrier',
	permissions: [PermissionsType.VehicleChangeData],
	isChild: true,
	icon: 'pencil',
};

detailRoutes[routesIdMap.VehiclesDetailKeysLocation] = {
	component: ChangeKeysLocation,
	exact: true,
	id: routesIdMap.VehiclesDetailKeysLocation,
	name: 'Detail',
	path: '/vehicles/detail/:id/update-keys-location',
	permissions: [PermissionsType.VehicleDetail],
	icon: 'key',
};

detailRoutes[routesIdMap.VehiclesDetailChangeState] = {
	component: ChangeState,
	exact: true,
	id: routesIdMap.VehiclesDetailChangeState,
	name: 'Change state',
	path: '/vehicles/detail/:id/change-state',
	permissions: [PermissionsType.VehicleDetail],
	icon: 'pencil',
};
