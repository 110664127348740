import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const IncludedServiceConfiguration = lazyWithRetry(() => import('./pages/IncludedServiceConfiguration'));
const IncludedServiceConfigurationNew = lazyWithRetry(() => import('./pages/IncludedServiceConfigurationNew'));
const IncludedServiceConfigurationModify = lazyWithRetry(() => import('./pages/IncludedServiceConfigurationModify'));

export const includedServiceConfigurationRoutes: { [key: number]: IRouteConfig } = {};

// INCLUDED SERVICES
includedServiceConfigurationRoutes[routesIdMap.PricingIncludedServiceConfiguration] = {
	component: IncludedServiceConfiguration,
	exact: true,
	id: routesIdMap.PricingIncludedServiceConfiguration,
	name: 'Included services configuration',
	menuText: 'Included services',
	path: '/pricing/included-services',
	permissions: getPricingPermission([PermissionsType.PricingIncludedServiceConfiguration]),
	childrens: [
		routesIdMap.PricingIncludedServiceConfigurationNew,
		routesIdMap.PricingIncludedServiceConfigurationModify,
	],
	icon: 'cart-arrow-down',
};

includedServiceConfigurationRoutes[routesIdMap.PricingIncludedServiceConfigurationNew] = {
	component: IncludedServiceConfigurationNew,
	exact: true,
	id: routesIdMap.PricingIncludedServiceConfigurationNew,
	name: 'New included service',
	path: '/pricing/included-services/new',
	permissions: getPricingPermission([PermissionsType.PricingIncludedServiceConfiguration]),
	icon: 'plus',
	menuText: 'New included service',
};

includedServiceConfigurationRoutes[routesIdMap.PricingIncludedServiceConfigurationModify] = {
	component: IncludedServiceConfigurationModify,
	exact: true,
	id: routesIdMap.PricingIncludedServiceConfigurationModify,
	name: 'Included services modify',
	path: '/pricing/included-services/:id',
	permissions: getPricingPermission([PermissionsType.PricingIncludedServiceConfiguration]),
	icon: 'pencil',
};
