import type { IMaximumExcessRate } from '../../models/entities/MaximumExcessRate';
import type {
	IMaximumExcessRateDeactivateParams,
	IMaximumExcessRateInsertParams,
	IMaximumExcessRateSearchParams,
} from '../../models/serviceParams/MaximumExcessRateParams';
import { Api } from '../../modules/shared/api';
import { MaximumExcessRateEndPoints } from '../endPoints/MaximumExcessRateEndPoints';

/**
 * Search excess rates
 * @param model IMaximumExcessRateSearchParams
 * @returns IMaximumExcessRate[]
 */
export const maximumExcessRateServiceSearch = (model: IMaximumExcessRateSearchParams) =>
	Api.get<IMaximumExcessRate[], IMaximumExcessRateSearchParams>(MaximumExcessRateEndPoints.SEARCH, model);

/**
 * Insert excess rates
 * @param model IMaximumExcessRateInsertParams
 * @returns IMaximumExcessRate[]
 */
export const maximumExcessRateServiceInsert = (model: IMaximumExcessRateInsertParams) =>
	Api.post<IMaximumExcessRate[], IMaximumExcessRateInsertParams>(MaximumExcessRateEndPoints.INSERT, model);

/**
 * Deactivate excess rate
 * @param model IMaximumExcessRateDeactivateParams
 * @returns IMaximumExcessRate[]
 */
export const maximumExcessRateServiceDeactivate = (model: IMaximumExcessRateDeactivateParams) =>
	Api.post<IMaximumExcessRate[], IMaximumExcessRateDeactivateParams>(MaximumExcessRateEndPoints.DEACTIVATE, model);
