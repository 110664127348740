export enum LinkEndPoints {
	/**
	 * Get link by id
	 * @host '/link/getbyid/'
	 */
	GET_BY_ID = '/link/getbyid/',

	/**
	 * Get link by accident part
	 * @host '/link/getbyaccidentpart/'
	 */
	GET_BY_ACCIDENT_PART = '/link/getbyaccidentpart/',

	/**
	 * Get link by vehicle
	 * @host '/link/getbyvehicle/'
	 */
	GET_BY_VEHICLE = '/link/getbyvehicle/',

	/**
	 * Get link by insurance
	 * @host '/link/getbyinsurance/'
	 */
	GET_BY_INSURANCE = '/link/getbyinsurance/',

	/**
	 * Get link by vehicle line number
	 * @host '/link/getbyvehiclelinenumber/'
	 */
	GET_BY_VEHICLE_LINE = '/link/getbyvehiclelinenumber/',

	/**
	 * Get link by franchise line number
	 * @host '/link/getbyfranchiselinenumbe/'
	 */
	GET_BY_FRANCHISE_LINE = '/link/getbyfranchiselinenumber/',

	/**
	 * Get link by spending line
	 * @host '/link/getbyspendingline/'
	 */
	GET_BY_SPENDING_LINE = '/link/getbyspendingline/',

	/**
	 * Get link by fine number
	 * @host '/link/getbyfinenumber/'
	 */
	GET_BY_FINE_NUMBER = '/link/getbyfinenumber/',
	/**
	 * Get fine notification
	 * @host '/link/getfinenotification/'
	 */
	GET_FINE_NOTIFICATION = '/link/getfinenotification/',
	/**
	 * Get link by invoice
	 * @host '/link/getByInvoice'
	 */
	GET_BY_INVOICE = '/link/getByInvoice',
	/**
	 * Get link by budget
	 * @host '/link/getByBudget/'
	 */
	GET_BY_BUDGET = '/link/getByBudget/',

	/**
	 * Get fine file by id
	 * @host `/link/getfinefile/`
	 */
	GET_FINE_FILE = '/link/getfinefile/',

	/**
	 * Add link to insurance
	 * @host `/link/addToInsurance`
	 */
	ADD_TO_INSURANCE = '/link/addToInsurance/',

	/**
	 * Get vehicle provisional documentation
	 * @host `/link/getVehicleProvisionalDocumentation`
	 */
	GET_VEHICLE_PROVISIONAL_DOCUMENTATION = '/link/getVehicleProvisionalDocumentation',

	/**
	 * Get vehicle documentation
	 * @host `/link/getVehicleDocumentation`
	 */
	GET_VEHICLE_DOCUMENTATION = '/link/getVehicleDocumentation',

	/**
	 * Adds links to an accident
	 * @host `/link/addToAccident`
	 */
	ADD_TO_ACCIDENT = '/link/addToAccident',

	/**
	 * Get all vehicle documents
	 * @host `/link/getdocumentbyvehicle`
	 */
	GET_DOCUMENT_BY_VEHICLE = '/link/getdocumentbyvehicle',

	/**
	 * Add documents to vehicle
	 * @host `/link/addtovehicle`
	 */
	ADD_TO_VEHICLE = '/link/addtovehicle',

	/**
	 * Downloads vehicles documentation
	 * @host `/link/downloadDocumentation`
	 */
	DOWNLOAD_DOCUMENTATION = '/link/downloadDocumentation',
}
