export enum RentRateEndPoints {
	/**
	 * @host `/RentRate/Search/`
	 */
	SEARCH = '/RentRate/Search/',

	/**
	 * @host `/rentRate/insert/`
	 */
	INSERT = '/rentRate/insert/',
}
