import { dashboardRoutes } from './dashboard/routes';
import { detailRoutes } from './detail/routes';
import { dropOffRoutes } from './dropOff/routes';
import { newRoutes } from './new/routes';
import { pickUpRoutes } from './pickUp/routes';
import { receptionRoutes } from './reception/routes';
import { regularizationsRoutes } from './regularizations/routes';
import { searchRoutes } from './search/routes';

export const bookingRoutes = {
	...dashboardRoutes,
	...detailRoutes,
	...dropOffRoutes,
	...newRoutes,
	...pickUpRoutes,
	...receptionRoutes,
	...regularizationsRoutes,
	...searchRoutes,
};
