import type { HTMLAttributes } from 'react';
import React from 'react';

import { createPortal } from 'react-dom';

interface IModalContainerProps extends HTMLAttributes<HTMLDivElement> {
	isModal?: boolean;
	children?: React.ReactNode;
}

export class ModalContainer extends React.PureComponent<IModalContainerProps> {
	constructor(props: IModalContainerProps) {
		super(props);

		this.modalElement = document.createElement('div');
		this.rootPortal = document.getElementById('root-portal');
	}

	componentDidMount(): void {
		const { isModal, ...rest } = this.props;
		if (isModal) {
			document.body.classList.add('open-modal');
		}

		if (rest.className) {
			this.modalElement.classList.add(rest.className);
		}

		if (this.rootPortal) {
			this.rootPortal.appendChild(this.modalElement);
		}
	}

	componentWillUnmount(): void {
		const { isModal } = this.props;
		if (isModal) {
			document.body.classList.remove('open-modal');
		}
		if (this.rootPortal) {
			this.rootPortal.removeChild(this.modalElement);
		}
	}

	private modalElement: HTMLElement;

	private rootPortal: HTMLElement | null;

	render(): React.ReactPortal {
		const { children } = this.props;
		return createPortal(children, this.modalElement);
	}
}
