import { accidentsRoutes } from './accidents/routes';
import { amountsRoutes } from './amounts/routes';
import { commentsRoutes } from './comments/routes';
import { detailRoutes as detailDetailRoutes } from './detail/routes';
import { documentsRoutes } from './documents/routes';
import { excessRoutes } from './excess/routes';
import { extendRoutes } from './extend/routes';
import { extraDriversRoutes } from './extraDrivers/routes';
import { finesRoutes } from './fines/routes';
import { invoiceToRoutes } from './invoiceTo/routes';
import { regularizationsRoutes } from './regularizations/routes';
import { servicesRoutes } from './services/routes';
import { tollsRoutes } from './tolls/routes';
import { vehiclesRoutes } from './vehicles/routes';

export const detailRoutes = {
	...accidentsRoutes,
	...amountsRoutes,
	...commentsRoutes,
	...detailDetailRoutes,
	...documentsRoutes,
	...excessRoutes,
	...extendRoutes,
	...extraDriversRoutes,
	...finesRoutes,
	...invoiceToRoutes,
	...regularizationsRoutes,
	...servicesRoutes,
	...tollsRoutes,
	...vehiclesRoutes,
};
