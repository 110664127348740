import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { vehicleRevision, vehicleRevisionClear } from '@crac/core/redux/actions/VehicleActions';
import type { IRevisionReducerState } from '@crac/core/redux-store/reducersState/vehicle/RevisionReducerState';
const initialState: IRevisionReducerState = {
	vehicleRevisionRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};
const revisionSlice = createSlice({
	name: 'VehicleRevisionSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR STATE
		builder.addCase(vehicleRevisionClear, (state) => {
			return {
				...state,
				vehicleRevisionRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});

		// REVISION STATE
		builder
			.addCase(vehicleRevision.pending, (state) => {
				return {
					...state,
					vehicleRevisionRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleRevision.fulfilled, (state) => {
				return {
					...state,
					vehicleRevisionRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleRevision.rejected, (state, action) => {
				return {
					...state,
					vehicleRevisionRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const revisionReducer = revisionSlice.reducer;
