import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	customerBirthCitiesGetByBirthProvince,
	customerBirthProvincesGetByBirthCountry,
	customerCitiesGetByCountryAndPostCode,
	customerClear,
	customerGetBirthProvinceByCode,
	customerGetDetailData,
	customerGetProvinceByCode,
	customerPostCodesGetByCountryAndText,
	customerProvincesGetByCountryAndPostCode,
} from '@crac/core/redux/actions/CustomerActions';
import type { ICustomerAddressesReducerState } from '@crac/core/redux-store/reducersState/customer/AddressesReducerState';
const initialState: ICustomerAddressesReducerState = {
	birthCities: [],
	birthCitiesRequest: { inProgress: false, messages: [], ok: true },
	birthProvince: null,
	birthProvinceRequest: { inProgress: false, messages: [], ok: true },
	birthProvinces: [],
	birthProvincesRequest: { inProgress: false, messages: [], ok: true },
	cities: [],
	citiesRequest: { inProgress: false, messages: [], ok: true },
	postalCodes: [],
	postalCodesRequest: { inProgress: false, messages: [], ok: true },
	province: null,
	provinceRequest: { inProgress: false, messages: [], ok: true },
	provinces: [],
	provincesRequest: { inProgress: false, messages: [], ok: true },
};

const addressSlice = createSlice({
	name: 'CustomerAddressSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET DETAIL DATA
		builder
			.addCase(customerGetDetailData.pending, (state) => {
				return {
					...state,
					birthProvinces: [],
					birthProvincesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					provinces: [],
					provincesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					cities: [],
					citiesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					birthCities: [],
					birthCitiesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetDetailData.fulfilled, (state, { payload }) => {
				return {
					...state,
					birthProvinces: payload?.birthProvinces || [],
					birthProvincesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					provinces: payload?.provinces || [],
					provincesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					cities: payload?.cities || [],
					citiesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					birthCities: payload?.birthCities || [],
					birthCitiesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetDetailData.rejected, (state) => {
				return {
					...state,
					birthProvincesRequest: {
						inProgress: false,
						messages: [],
						ok: false,
					},
					provincesRequest: {
						inProgress: false,
						messages: [],
						ok: false,
					},
					citiesRequest: {
						inProgress: false,
						messages: [],
						ok: false,
					},
					birthCitiesRequest: {
						inProgress: false,
						messages: [],
						ok: false,
					},
				};
			});
		// GET_BIRTH_PROVINCES_BY_BIRTH_COUNTRY
		builder
			.addCase(customerBirthProvincesGetByBirthCountry.pending, (state) => {
				return {
					...state,
					birthProvinces: [],
					birthProvincesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerBirthProvincesGetByBirthCountry.fulfilled, (state, action) => {
				return {
					...state,
					birthProvinces: deepCopy(action.payload),
					birthProvincesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerBirthProvincesGetByBirthCountry.rejected, (state, action) => {
				return {
					...state,
					birthProvincesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET_BIRTH_PROVINCE_BY_CODE
		builder
			.addCase(customerGetBirthProvinceByCode.pending, (state) => {
				return {
					...state,
					birthProvince: null,
					birthProvinceRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetBirthProvinceByCode.fulfilled, (state, action) => {
				return {
					...state,
					birthProvince: action.payload,
					birthProvinceRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetBirthProvinceByCode.rejected, (state, action) => {
				return {
					...state,
					birthProvinceRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET_PROVINCE_BY_CODE
		builder
			.addCase(customerGetProvinceByCode.pending, (state) => {
				return {
					...state,
					province: null,
					provinceRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetProvinceByCode.fulfilled, (state, action) => {
				return {
					...state,
					province: action.payload,
					provinceRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetProvinceByCode.rejected, (state, action) => {
				return {
					...state,
					provinceRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET_BIRTH_CITIES_BY_BIRTH_PROVINCE
		builder
			.addCase(customerBirthCitiesGetByBirthProvince.pending, (state) => {
				return {
					...state,
					birthCities: [],
					birthCitiesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerBirthCitiesGetByBirthProvince.fulfilled, (state, action) => {
				return {
					...state,
					birthCities: action.payload,
					birthCitiesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerBirthCitiesGetByBirthProvince.rejected, (state, action) => {
				return {
					...state,
					birthCitiesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET_PROVINCES_BY_COUNTRY_AND_POST_CODE
		builder
			.addCase(customerProvincesGetByCountryAndPostCode.pending, (state) => {
				return {
					...state,
					provinces: [],
					provincesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerProvincesGetByCountryAndPostCode.fulfilled, (state, action) => {
				return {
					...state,
					provinces: action.payload,
					provincesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerProvincesGetByCountryAndPostCode.rejected, (state, action) => {
				return {
					...state,
					provincesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET_CITIES_BY_COUNTRY_AND_POST_CODE
		builder
			.addCase(customerCitiesGetByCountryAndPostCode.pending, (state) => {
				return {
					...state,
					cities: [],
					citiesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerCitiesGetByCountryAndPostCode.fulfilled, (state, action) => {
				return {
					...state,
					cities: action.payload,
					citiesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerCitiesGetByCountryAndPostCode.rejected, (state, action) => {
				return {
					...state,
					citiesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET_POSTAL_CODES_BY_COUNTRY_AND_TEXT
		builder
			.addCase(customerPostCodesGetByCountryAndText.pending, (state) => {
				return {
					...state,
					postalCodes: [],
					postalCodesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerPostCodesGetByCountryAndText.fulfilled, (state, action) => {
				return {
					...state,
					postalCodes: action.payload,
					postalCodesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerPostCodesGetByCountryAndText.rejected, (state, action) => {
				return {
					...state,
					postalCodesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR CACHE
		 * CLEAR
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, customerClear), () => {
			return initialState;
		});
	},
});

export const addressReducer = addressSlice.reducer;
