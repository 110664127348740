import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { IPostCode } from '@crac/core/models/entities/PostCode';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { postCodeGetByCountryAndText } from '@crac/core/redux/actions/PostCodeActions';
import type { IPostCodeReducerState } from '@crac/core/redux-store/reducersState/postCode';
export const postCodesStorageKey = 'postCodes';
const { getItem, setItem, removeItem } = managerLocalStorage();

const initialState: IPostCodeReducerState = {
	postCodeGetByCountryAndTextRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	postCodes: (getItem(postCodesStorageKey) as IPostCode[]) || [],
};

const postCodeSlice = createSlice({
	name: 'PostCodeSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			removeItem(postCodesStorageKey);
			return initialState;
		});
		// POST CODE GET BY COUNTRY AND TEXT
		builder
			.addCase(postCodeGetByCountryAndText.pending, (state) => {
				return {
					...state,
					postCodeGetByCountryAndTextRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					postCodes: [],
				};
			})
			.addCase(postCodeGetByCountryAndText.fulfilled, (state, action) => {
				setItem(postCodesStorageKey, action.payload);
				return {
					...state,
					postCodeGetByCountryAndTextRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					postCodes: deepCopy(action.payload),
				};
			})
			.addCase(postCodeGetByCountryAndText.rejected, (state, action) => {
				return {
					...state,
					postCodeGetByCountryAndTextRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const postCodeReducer = postCodeSlice.reducer;
