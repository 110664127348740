import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { IEmployee } from '@crac/core/models/entities/Employee';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	turnAddOnTV,
	turnClear,
	turnGetNextTurnByQueue,
	turnGetTurnsByBranch,
	turnInsert,
	turnLiberateTurnsByQueue,
	turnRemoveOnTV,
	turnSetToCall,
	turnUpdate,
	turnUpdateList,
} from '@crac/core/redux/actions/TurnActions';
import type { ITurnsReducerState } from '@crac/core/redux-store/reducersState/turns';

import { setOfficeDateTime } from '../../utils/setOfficeDateTime';

const { getItem } = managerLocalStorage();

const employee = getItem('employee') as IEmployee;

const initialState: ITurnsReducerState = {
	addOnTv: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	getByBranch: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	getNext: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	insert: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	liberateTurnsByQueue: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	previousTurn: undefined,
	removeOnTv: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	setToCall: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	turn: undefined,
	turns: [],
	update: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	getByBooking: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	getByCheckIn: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	insertWithCheckIn: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const turnsSlice = createSlice({
	name: 'TurnSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET TURNS BY BRANCH
		builder
			.addCase(turnGetTurnsByBranch.pending, (state) => {
				return {
					...state,
					getByBranch: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					turns: [],
				};
			})
			.addCase(turnGetTurnsByBranch.fulfilled, (state, action) => {
				return {
					...state,
					getByBranch: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					turns: deepCopy(action.payload),
				};
			})
			.addCase(turnGetTurnsByBranch.rejected, (state, action) => {
				return {
					...state,
					getByBranch: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
					turns: [],
				};
			});
		// GET NEXT TURN BY QUEUE
		builder
			.addCase(turnGetNextTurnByQueue.pending, (state) => {
				return {
					...state,
					getNext: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					previousTurn: state.turn ? state.turn : undefined,
				};
			})
			.addCase(turnGetNextTurnByQueue.fulfilled, (state, action) => {
				return {
					...state,
					getNext: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					previousTurn: action.payload ? state.turn : undefined,
					turn: deepCopy(action.payload),
				};
			})
			.addCase(turnGetNextTurnByQueue.rejected, (state, action) => {
				return {
					...state,
					getNext: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// LIBERATE TURNS BY QUEUE
		builder
			.addCase(turnLiberateTurnsByQueue.pending, (state) => {
				return {
					...state,
					liberateTurnsByQueue: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(turnLiberateTurnsByQueue.fulfilled, (state) => {
				return {
					...state,
					liberateTurnsByQueue: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(turnLiberateTurnsByQueue.rejected, (state, action) => {
				return {
					...state,
					liberateTurnsByQueue: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(turnInsert.pending, (state) => {
				return {
					...state,
					insert: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(turnInsert.fulfilled, (state, action) => {
				return {
					...state,
					insert: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					turn: deepCopy(action.payload),
					turns: [...state.turns, action.payload],
				};
			})
			.addCase(turnInsert.rejected, (state, action) => {
				return {
					...state,
					insert: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// UPDATE
		builder
			.addCase(turnUpdate.pending, (state) => {
				return {
					...state,
					update: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(turnUpdate.fulfilled, (state, action) => {
				return {
					...state,
					turn: deepCopy(action.payload),
					turns: [...state.turns.filter((item) => item.id !== action.payload.id), action.payload],
					update: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(turnUpdate.rejected, (state, action) => {
				return {
					...state,
					update: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// UPDATE LIST
		builder.addCase(turnUpdateList, (state, action) => {
			return {
				...state,
				turns: action.payload
					? [...state.turns.filter((turn) => turn.id !== action.payload.id), action.payload]
					: setOfficeDateTime(state.turns, employee),
			};
		});
		// ADD ON TV
		builder
			.addCase(turnAddOnTV.pending, (state) => {
				return {
					...state,
					addOnTv: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(turnAddOnTV.fulfilled, (state) => {
				return {
					...state,
					addOnTv: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(turnAddOnTV.rejected, (state, action) => {
				return {
					...state,
					addOnTv: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// REMOVE ON TV
		builder
			.addCase(turnRemoveOnTV.pending, (state) => {
				return {
					...state,
					removeOnTv: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(turnRemoveOnTV.fulfilled, (state) => {
				return {
					...state,
					removeOnTv: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(turnRemoveOnTV.rejected, (state, action) => {
				return {
					...state,
					removeOnTv: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// SET TO CALL
		builder
			.addCase(turnSetToCall.pending, (state) => {
				return {
					...state,
					setToCall: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(turnSetToCall.fulfilled, (state) => {
				return {
					...state,
					setToCall: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(turnSetToCall.rejected, (state, action) => {
				return {
					...state,
					setToCall: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR CACHE
		 * CLEAR
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, turnClear), (state) => {
			return {
				...initialState,
				turns: state.turns,
			};
		});
	},
});

export const turnsReducer = turnsSlice.reducer;
