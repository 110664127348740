import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { IBrandParking } from '../../entities/BrandParking';
import type { IBrandParkingDeactivateParams } from '../../services/BrandParkingDeactivateService';
import { brandParkingDeactivateService } from '../../services/BrandParkingDeactivateService';
import type { IBrandParkingInsertParams } from '../../services/BrandParkingInsertService';
import { brandParkingInsertService } from '../../services/BrandParkingInsertService';
import type { IBrandParkingModifyParams } from '../../services/BrandParkingModifyService';
import { brandParkingModifyService } from '../../services/BrandParkingModifyService';
import type { IBrandParkingActivateParams } from '../../services/BrandParkingReactivateService';
import { brandParkingActivateService } from '../../services/BrandParkingReactivateService';
import type { IBrandParkingSearchParams } from '../../services/BrandParkingSearchService';
import { brandParkingSearchService } from '../../services/BrandParkingSearchService';

//* SEARCH
export const brandParkingSearch = createAsyncAction<IBrandParking[], IBrandParkingSearchParams>(
	'brandParking/search',
	brandParkingSearchService,
);

//* INSERT
export const brandParkingInsert = createAsyncAction<IBrandParking, IBrandParkingInsertParams>(
	'brandParking/insert',
	brandParkingInsertService,
);

//* MODIFY
export const brandParkingModify = createAsyncAction<IBrandParking, IBrandParkingModifyParams>(
	'brandParking/modify',
	brandParkingModifyService,
);

//* DEACTIVATE
export const brandParkingDeactivate = createAsyncAction<IBrandParking, IBrandParkingDeactivateParams>(
	'brandParking/deactivate',
	brandParkingDeactivateService,
);

//* ACTIVATE
export const brandParkingActivate = createAsyncAction<IBrandParking, IBrandParkingActivateParams>(
	'brandParking/activate',
	brandParkingActivateService,
);

//* CLEAR
export const brandParkingsClear = createSyncAction('brandParkings/clear');
