export enum ProviderEndPoints {
	/**
	 * Get all providers
	 * @host `/provider/getall/`
	 */
	GET_ALL = '/provider/getall/',

	/**
	 * Get by branch
	 * @host `/provider/getbybranch/`
	 */
	GET_BY_BRANCH = '/provider/getbybranch/',
}
