import { createAction } from '@reduxjs/toolkit';

import {
	serviceCouponConfigurationDeactivate,
	serviceCouponConfigurationInsert,
	serviceCouponConfigurationModify,
	serviceCouponConfigurationSearch,
} from '../../data/services/CouponConfigurationService';
import type { ICouponConfiguration } from '../../models/entities/CouponConfiguration';
import type {
	ICouponConfigurationDeactivateParams,
	ICouponConfigurationInsertParams,
	ICouponConfigurationModifyParams,
	ICouponConfigurationSearchParams,
} from '../../models/serviceParams/CouponConfigurationParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for coupon configurations based on specified parameters.
 *
 * @param {ICouponConfiguration[]} payload - The coupon configurations to be returned.
 * @param {ICouponConfigurationSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of coupon configurations.
 */
export const couponConfigurationSearch = createAsyncAction<ICouponConfiguration[], ICouponConfigurationSearchParams>(
	'couponConfiguration/search',
	serviceCouponConfigurationSearch,
);

/**
 * Inserts new coupon configurations.
 *
 * @param {ICouponConfiguration[]} payload - The coupon configurations to be inserted.
 * @param {ICouponConfigurationInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the coupon configurations are inserted.
 */
export const couponConfigurationInsert = createAsyncAction<ICouponConfiguration[], ICouponConfigurationInsertParams>(
	'couponConfiguration/insert',
	serviceCouponConfigurationInsert,
);

/**
 * Modifies an existing coupon configuration.
 *
 * @param {ICouponConfiguration} payload - The coupon configuration to be modified.
 * @param {ICouponConfigurationModifyParams} params - Parameters for the modification.
 * @returns {Promise<void>} - A promise that resolves after the coupon configuration is modified.
 */
export const couponConfigurationModify = createAsyncAction<ICouponConfiguration, ICouponConfigurationModifyParams>(
	'couponConfiguration/modify',
	serviceCouponConfigurationModify,
);

/**
 * Deactivates coupon configurations.
 *
 * @param {ICouponConfiguration[]} payload - The coupon configurations to be deactivated.
 * @param {ICouponConfigurationDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the coupon configurations are deactivated.
 */
export const couponConfigurationDeactivate = createAsyncAction<
	ICouponConfiguration[],
	ICouponConfigurationDeactivateParams
>('couponConfiguration/deactivate', serviceCouponConfigurationDeactivate);

/**
 * Removes deactivated coupon configurations from the state.
 *
 * @param {number} payload - The ID of the coupon configuration to be removed.
 */
export const couponConfigurationRemoveDeactivated = createAction<number>('couponConfiguration/removeDeactivated');

/**
 * Clears the coupon configuration state.
 */
export const couponConfigurationClear = createAction('couponConfiguration/clear');
