import type { IRentRateTypeRelation } from '../../models/entities/RentRateTypeRelation';
import type {
	IRentRateTypeRelationDeleteParams,
	IRentRateTypeRelationSaveParams,
	IRentRateTypeRelationSearchParams,
} from '../../models/serviceParams/RentRateTypeRelationParams';
import { Api } from '../../modules/shared/api';
import { RentRateTypeRelationEndPoints } from '../endPoints/RentRateTypeRelationEndPoints';

/**
 * Search rent rate relation
 * @param model IIRentRateTypeRelationSearchParams
 * @returns IRentRateTypeRelation[]
 */
export const rentRateTypeRelationSearchService = (model: IRentRateTypeRelationSearchParams) =>
	Api.get<IRentRateTypeRelation[], IRentRateTypeRelationSearchParams>(RentRateTypeRelationEndPoints.SEARCH, model);

/**
 * Inser rent rate relation
 * @param model IRentRateTypeRelationSaveParams
 * @returns IRentRateTypeRelation[]
 */
export const rentRateTypeRelationInsertService = (model: IRentRateTypeRelationSaveParams) =>
	Api.post<IRentRateTypeRelation[], IRentRateTypeRelationSaveParams>(RentRateTypeRelationEndPoints.INSERT, model);

/**
 * Modify rent rate relation
 * @param model IRentRateTypeRelationSaveParams
 * @returns IRentRateTypeRelation[]
 */
export const rentRateTypeRelationModifyService = (model: IRentRateTypeRelationSaveParams) =>
	Api.post<IRentRateTypeRelation[], IRentRateTypeRelationSaveParams>(RentRateTypeRelationEndPoints.MODIFY, model);

/**
 * Delete rent rate relation
 * @param model IRentRateTypeRelationDeleteParams
 * @returns IRentRateTypeRelation
 */
export const rentRateTypeRelationDeleteService = (model: IRentRateTypeRelationDeleteParams) =>
	Api.post<IRentRateTypeRelation, IRentRateTypeRelationDeleteParams>(RentRateTypeRelationEndPoints.DELETE, model);
