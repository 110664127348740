import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { insuranceGetByPlateNumber, insuranceSendRegistrationRequest } from '@crac/core/redux/actions/InsuranceActions';
import type { IInsuranceReducerState } from '@crac/core/redux-store/reducersState/insurance';

const initialState: IInsuranceReducerState = {
	insurances: [],
	insurancesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	insuranceSendRegistrationRequestRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	insuranceInsert: [],
};

const insurancesSlice = createSlice({
	name: 'VehicleInsurancesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY PLATE NUMBER
		builder
			.addCase(insuranceGetByPlateNumber.pending, (state) => {
				return {
					...state,
					insurancesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(insuranceGetByPlateNumber.fulfilled, (state, action) => {
				return {
					...state,
					insurances: deepCopy(action.payload),
					insurancesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(insuranceGetByPlateNumber.rejected, (state, action) => {
				return {
					...state,
					insurancesRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// SEND REGISTRATION REQUEST
		builder
			.addCase(insuranceSendRegistrationRequest.pending, (state) => {
				return {
					...state,
					insuranceSendRegistrationRequestRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(insuranceSendRegistrationRequest.fulfilled, (state) => {
				return {
					...state,
					insuranceSendRegistrationRequestRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(insuranceSendRegistrationRequest.rejected, (state, action) => {
				return {
					...state,
					insuranceSendRegistrationRequestRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const insurancesReducer = insurancesSlice.reducer;
