import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
	emailSendBookingAgency,
	emailSendCouponByCustomerCode,
	emailSendDocument,
	emailSendFeedback,
} from '@crac/core/redux/actions/EmailActions';
import type { IEmailsReducerState } from '@crac/core/redux-store/reducersState/emails';

const initialState: IEmailsReducerState = {
	sendCouponByCustomerCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	sendDocumentRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	sendFeedBackRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const emailsSlice = createSlice({
	name: 'EmailsSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEND FEEDBACK
		builder
			.addCase(emailSendFeedback.pending, (state) => {
				return {
					...state,
					sendFeedBackRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(emailSendFeedback.fulfilled, (state) => {
				return {
					...state,
					sendFeedBackRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(emailSendFeedback.rejected, (state, action) => {
				return {
					...state,
					sendFeedBackRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SEND COUPON BY CUSTOMER CODE
		builder
			.addCase(emailSendCouponByCustomerCode.pending, (state) => {
				return {
					...state,
					sendCouponByCustomerCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(emailSendCouponByCustomerCode.fulfilled, (state) => {
				return {
					...state,
					sendCouponByCustomerCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(emailSendCouponByCustomerCode.rejected, (state, action) => {
				return {
					...state,
					sendCouponByCustomerCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/*
		 * SEND DOCUMENT
		 * SEND BOOKING AGENCY
		 */
		builder
			.addMatcher(isAnyOf(emailSendDocument.pending, emailSendBookingAgency.pending), (state) => {
				return {
					...state,
					sendDocumentRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addMatcher(isAnyOf(emailSendDocument.fulfilled, emailSendBookingAgency.fulfilled), (state, action) => {
				return {
					...state,
					sendDocumentRequest: {
						inProgress: false,
						messages: [],
						ok: action.payload,
					},
				};
			})
			.addMatcher(isAnyOf(emailSendDocument.rejected, emailSendBookingAgency.rejected), (state, action) => {
				return {
					...state,
					sendDocumentRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const emailsReducer = emailsSlice.reducer;
