import { useEffect } from 'react';

import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

import type { setHostName } from '@crac/core/redux/actions/ComputerActions';

export const useSetHostName = (callBack: typeof setHostName): void => {
	const history = useNavigate();
	const location = useLocation();

	useEffect(() => {
		const { hostname } = queryString.parse(location.search);
		if (hostname) {
			callBack(hostname as string);
			history(location.pathname);
		}
	}, [callBack, history, location.pathname, location.search]);
};
