import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Search = lazyWithRetry(() => import('./pages/Search'));

export const searchRoutes: { [key: number]: IRouteConfig } = {};

searchRoutes[routesIdMap.BookingsPickUp] = {
	component: Search,
	exact: true,
	id: routesIdMap.BookingsPickUp,
	name: 'Pick up',
	path: '/booking/pick-up',
	permissions: [PermissionsType.BookingPickUp],
	icon: 'sign-in',
	menuText: 'Pick up',
};
