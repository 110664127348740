import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Bookings = lazyWithRetry(() => import('./pages/Bookings'));

export const bookingsRoutes: { [key: number]: IRouteConfig } = {};

bookingsRoutes[routesIdMap.VehiclesDetailBookings] = {
	component: Bookings,
	exact: true,
	id: routesIdMap.VehiclesDetailBookings,
	name: 'Bookings',
	path: '/vehicles/detail/:id/bookings',
	permissions: [PermissionsType.VehicleDetail],
	icon: 'calendar',
};
