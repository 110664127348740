import type { ITurn } from '../../models/entities/Turn';
import type {
	ITurnAddOnTVParams,
	ITurnGetNextTurnByQueueParams,
	ITurnGetTurnByBookingParams,
	ITurnGetTurnByCheckInParams,
	ITurnGetTurnParams,
	ITurnGetTurnsByBranchParams,
	ITurnInsertParams,
	ITurnLiberateTurnsByQueueParams,
	ITurnRemoveOnTVParams,
	ITurnSetToCallParams,
	ITurnUpdateParams,
} from '../../models/serviceParams/TurnParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { TurnEndPoints } from '../endPoints/TurnEndPoints';

/**
 * Get nex turn by queue
 *  {int} queueId Queue ID
 * @return {Promise} `TurnPropType`
 */
export const turnServiceGetNextTurnByQueue = (
	model: ITurnGetNextTurnByQueueParams,
): Promise<ServiceResponse<ITurn>> => {
	return Api.get<ITurn, ITurnGetNextTurnByQueueParams>(TurnEndPoints.GET_NEXT_TURN_BY_QUEUE, model);
};

/**
 * Insert turn
 *  {Turn} TurnPropType Turn Object
 * @return {Turn} Turn Object
 * @return {Promise} `TurnPropType`
 */
export const turnServiceInsert = (model: ITurnInsertParams): Promise<ServiceResponse<ITurn>> => {
	return Api.post<ITurn, ITurnInsertParams>(TurnEndPoints.INSERT, model);
};

/**
 * Update Turn
 *  {Turn} TurnPropType Turn Object
 * @return {Promise} `TurnPropType`
 */
export const turnServiceUpdate = (model: ITurnUpdateParams): Promise<ServiceResponse<ITurn>> => {
	return Api.post<ITurn, ITurnUpdateParams>(TurnEndPoints.UPDATE, model);
};

/**
 * Get turn by ID
 *  {int} id ID Turn
 * @return {Promise} `TurnPropType`
 */
export const turnServiceGetTurn = (model: ITurnGetTurnParams): Promise<ServiceResponse<ITurn>> => {
	return Api.get<ITurn, ITurnGetTurnParams>(TurnEndPoints.GET_TURN, model);
};

/**
 * Get turns by branch
 *  {string} branchCode Branch code
 * @return {Promise} `TurnPropType`
 */
export const turnServiceGetTurnsByBranch = (model: ITurnGetTurnsByBranchParams): Promise<ServiceResponse<ITurn[]>> => {
	return Api.get<ITurn[], ITurnGetTurnsByBranchParams>(TurnEndPoints.GET_TURNS_BY_BRANCH, model);
};

/**
 * Liberate turns by Queue
 *  {int} queueId Queue ID
 * @return {Promise} `void`
 */
export const turnServiceLiberateTurnsByQueue = (
	model: ITurnLiberateTurnsByQueueParams,
): Promise<ServiceResponse<ITurn>> => {
	return Api.post<ITurn, ITurnLiberateTurnsByQueueParams>(TurnEndPoints.LIBERATE_TURNS_BY_QUEUE, model);
};

export const turnServiceAddOnTV = (model: ITurnAddOnTVParams): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, ITurnAddOnTVParams>(TurnEndPoints.ADD_ON_TV, model);
};

export const turnServiceRemoveOnTV = (model: ITurnRemoveOnTVParams): Promise<ServiceResponse<boolean>> => {
	return Api.get<boolean, ITurnRemoveOnTVParams>(TurnEndPoints.REMOVE_ON_TV, model);
};

export const turnServiceSetToCall = (model: ITurnSetToCallParams): Promise<ServiceResponse<ITurn>> => {
	return Api.post<ITurn, ITurnSetToCallParams>(TurnEndPoints.FINISH_RECEPTION_SET_TURNS_TO_CALL, model);
};

/**
 * Get turn by booking number
 *  {string} bookingNumber Booking number
 * @return {Promise} `TurnPropType`
 */
export const turnServiceGetTurnByBooking = (model: ITurnGetTurnByBookingParams): Promise<ServiceResponse<ITurn>> => {
	return Api.get<ITurn, ITurnGetTurnByBookingParams>(TurnEndPoints.GET_TURN_BY_BOOKING, model);
};

/**
 * Get turn by checkIn
 * @param model `Turn get by checkIn params`
 * @returns {Promise} `Turn`
 */
export const turnServiceGetTurnByCheckIn = (model: ITurnGetTurnByCheckInParams): Promise<ServiceResponse<ITurn>> => {
	return Api.get<ITurn, ITurnGetTurnByCheckInParams>(TurnEndPoints.GET_TURN_BY_CHECKIN, model);
};
