import { createSyncAction } from '../../modules/shared/state/createAction';

export interface IPermissionsToggleParams {
	isEnabled: boolean;
	permissions: string[];
}

export const updateUserPermissions = createSyncAction<string[]>('permissions/updateUserPermissions');

export const toggleEnabledPermissions = createSyncAction<IPermissionsToggleParams>(
	'permissions/toggleEnabledPermissions',
);
