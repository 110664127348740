/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */
import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	franchiseLineAddLinks,
	franchiseLineGetByBooking,
	franchiseLineGetByPlateNumber,
	franchiseLineInsert,
	franchiseLineRemove,
	franchiseLineRepair,
	franchiseLineRepairClear,
	franchiseLineSearch,
	franchiseLineSetDamages,
	franchiseLineUpdateList,
} from '@crac/core/redux/actions/FranchiseLineActions';
import { linkGetByFranchiseLine } from '@crac/core/redux/actions/LinkActions';
import type { IFranchiseReducerState } from '@crac/core/redux-store/reducersState/franchise';
const initialState: IFranchiseReducerState = {
	franchiseLineInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	franchiseLineRemoveRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	franchiseLineRepairRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	franchiseLineRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	franchiseLines: [],
	franchises: [],
	linkRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	links: [],
	linksInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	franchiseLineSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	franchiseLineRepairs: [],
	franchiseLinesToLink: [],
};

const franchiseSlice = createSlice({
	name: 'FranchiseSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// GET BY BOOKING
		builder
			.addCase(franchiseLineGetByBooking.pending, (state) => {
				return {
					...state,
					franchiseLineRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					franchiseLines: [],
				};
			})
			.addCase(franchiseLineGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					franchiseLineRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					franchiseLines: action.payload,
				};
			})
			.addCase(franchiseLineGetByBooking.rejected, (state, action) => {
				return {
					...state,
					franchiseLineRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY PLATENUMBER
		builder
			.addCase(franchiseLineGetByPlateNumber.pending, (state) => {
				return {
					...state,
					franchiseLineRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					franchiseLines: [],
				};
			})
			.addCase(franchiseLineGetByPlateNumber.fulfilled, (state, action) => {
				return {
					...state,
					franchiseLineRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					franchiseLines: action.payload,
				};
			})
			.addCase(franchiseLineGetByPlateNumber.rejected, (state, action) => {
				return {
					...state,
					franchiseLineRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET LINK BY FRANCHISE LINE
		builder
			.addCase(linkGetByFranchiseLine.pending, (state) => {
				return {
					...state,
					linkRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					links: [],
				};
			})
			.addCase(linkGetByFranchiseLine.fulfilled, (state, action) => {
				return {
					...state,
					linkRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					links: action.payload,
				};
			})
			.addCase(linkGetByFranchiseLine.rejected, (state, action) => {
				return {
					...state,
					linkRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// ADD LINK
		builder
			.addCase(franchiseLineAddLinks.pending, (state) => {
				return {
					...state,
					linksInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(franchiseLineAddLinks.fulfilled, (state, action) => {
				return {
					...state,
					links: [...state.links, ...action.payload],
					linksInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(franchiseLineAddLinks.rejected, (state, action) => {
				return {
					...state,
					linksInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(franchiseLineInsert.pending, (state) => {
				return {
					...state,
					franchiseLineInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(franchiseLineInsert.fulfilled, (state, action) => {
				return {
					...state,
					franchiseLineInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					franchiseLines: [...state.franchiseLines, ...action.payload],
				};
			})
			.addCase(franchiseLineInsert.rejected, (state, action) => {
				return {
					...state,
					franchiseLineInsertRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// FRANCHISE LINE REMOVE
		builder
			.addCase(franchiseLineRemove.pending, (state) => {
				return {
					...state,
					franchiseLineRemoveRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(franchiseLineRemove.fulfilled, (state) => {
				return {
					...state,
					franchiseLineRemoveRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(franchiseLineRemove.rejected, (state, action) => {
				return {
					...state,
					franchiseLineRemoveRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// FRANCHISE LINE REPAIR
		builder
			.addCase(franchiseLineRepair.pending, (state) => {
				return {
					...state,
					franchiseLineRepairRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(franchiseLineRepair.fulfilled, (state, action) => {
				return {
					...state,
					franchiseLineRepairRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					franchiseLines: [
						...state.franchiseLines.filter((line) => line.lineNumber !== action.payload.lineNumber),
						action.payload,
					],
				};
			})
			.addCase(franchiseLineRepair.rejected, (state, action) => {
				return {
					...state,
					franchiseLineRepairRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// UPDATE LIST
		builder.addCase(franchiseLineUpdateList, (state, action) => {
			return {
				...state,
				franchiseLines: deepCopy(action.payload),
			};
		});
		// SEARCH
		builder
			.addCase(franchiseLineSearch.pending, (state) => {
				return {
					...state,
					franchiseLineSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(franchiseLineSearch.fulfilled, (state, action) => {
				return {
					...state,
					franchiseLineSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					franchiseLineRepairs: action.payload,
				};
			})
			.addCase(franchiseLineSearch.rejected, (state, action) => {
				return {
					...state,
					franchiseLineSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// REPAIR CLEAR
		builder.addCase(franchiseLineRepairClear, (state) => {
			return {
				...state,
				franchiseLineRepairs: [],
				franchiseLinesToLink: [],
			};
		});
		// SET DAMAGES
		builder.addCase(franchiseLineSetDamages, (state, action) => {
			return {
				...state,
				franchiseLinesToLink: action.payload || [],
			};
		});
	},
});

export const franchiseReducer = franchiseSlice.reducer;
