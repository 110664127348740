import { createAction } from '@reduxjs/toolkit';

import {
	rentRateFormulaDeleteService,
	rentRateFormulaInsertService,
	rentRateFormulaModifyService,
	rentRateFormulaSearchService,
} from '../../data/services/RentRateFormulaService';
import type { IRentRateFormula } from '../../models/entities/RentRateFormula';
import type {
	IRentRateFormulaDeleteParams,
	IRentRateFormulaInsertParams,
	IRentRateFormulaModifyParams,
	IRentRateFormulasSearchParams,
} from '../../models/serviceParams/RentParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for rent rate formulas based on specified parameters.
 *
 * @param {IRentRateFormula[]} payload - The rent rate formulas to be retrieved.
 * @param {IRentRateFormulasSearchParams} params - Parameters for the search of rent rate formulas.
 * @returns {Promise<void>} - A promise that resolves to an array of rent rate formulas.
 */
export const rentRateFormulasSearch = createAsyncAction<IRentRateFormula[], IRentRateFormulasSearchParams>(
	'rentRateFormula/search',
	rentRateFormulaSearchService,
);

/**
 * Inserts new rent rate formulas.
 *
 * @param {IRentRateFormula[]} payload - The rent rate formulas to be inserted.
 * @param {IRentRateFormulaInsertParams} params - Parameters for the insertion of rent rate formulas.
 * @returns {Promise<void>} - A promise that resolves after the rent rate formulas are inserted.
 */
export const rentRateFormulaInsert = createAsyncAction<IRentRateFormula[], IRentRateFormulaInsertParams>(
	'rentRateFormula/insert',
	rentRateFormulaInsertService,
);

/**
 * Modifies existing rent rate formulas.
 *
 * @param {IRentRateFormula[]} payload - The rent rate formulas to be modified.
 * @param {IRentRateFormulaModifyParams} params - Parameters for the modification of rent rate formulas.
 * @returns {Promise<void>} - A promise that resolves after the rent rate formulas are modified.
 */
export const rentRateFormulaModify = createAsyncAction<IRentRateFormula[], IRentRateFormulaModifyParams>(
	'rentRateFormula/modify',
	rentRateFormulaModifyService,
);

/**
 * Deletes a specific rent rate formula.
 *
 * @param {IRentRateFormula} payload - The rent rate formula to be deleted.
 * @param {IRentRateFormulaDeleteParams} params - Parameters for the deletion of the rent rate formula.
 * @returns {Promise<void>} - A promise that resolves after the rent rate formula is deleted.
 */
export const rentRateFormulaDelete = createAsyncAction<IRentRateFormula, IRentRateFormulaDeleteParams>(
	'rentRateFormula/delete',
	rentRateFormulaDeleteService,
);

/**
 * Resets the rent rate formula state.
 */
export const rentRateFormulasReset = createAction('rentRateFormula/reset');
