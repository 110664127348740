import {
	fleetMovementRestrictionServiceRemove,
	fleetMovementRestrictionServiceUpdate,
} from '../../data/services/FleetMovementRestrictionService';
import type {
	IFleetMovementRestrictionRemoveParams,
	IFleetMovementRestrictionUpdateParams,
} from '../../models/serviceParams/FleetMovementRestrictionParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IVehicle } from '../../modules/vehicle/entities/Vehicle';

/**
 * Updates fleet movement restrictions.
 *
 * @param {IVehicle[]} payload - The updated list of fleet movement restrictions.
 * @param {IFleetMovementRestrictionUpdateParams} params - Parameters detailing the updates to be made.
 * @returns {Promise<void>} - A promise that resolves after the movement restrictions are updated.
 */
export const fleetMovementRestrictionUpdate = createAsyncAction<IVehicle[], IFleetMovementRestrictionUpdateParams>(
	'fleetMovementRestriction/update',
	fleetMovementRestrictionServiceUpdate,
);

/**
 * Removes fleet movement restrictions.
 *
 * @param {IVehicle} payload - The identifiers of the fleet movement restrictions to be removed.
 * @param {IFleetMovementRestrictionRemoveParams} params - Parameters detailing the restrictions to be removed.
 * @returns {Promise<void>} - A promise that resolves after the movement restrictions are removed.
 */
export const fleetMovementRestrictionRemove = createAsyncAction<IVehicle[], IFleetMovementRestrictionRemoveParams>(
	'fleetMovementRestriction/remove',
	fleetMovementRestrictionServiceRemove,
);
