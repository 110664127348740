import type { ICity } from '../../models/entities/City';
import type {
	ICityGetByCountryAndPostCodeParams,
	ICityGetByProvinceParams,
} from '../../models/serviceParams/CityParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { CityEndPoints } from '../endPoints/CityEndPoints';

/**
 * Get by province
 *  {string} provinceCode Province code
 * @return {Array<City>} List City
 */
export const cityServiceGetByProvince = (model: ICityGetByProvinceParams): Promise<ServiceResponse<ICity[]>> => {
	return Api.get<ICity[], ICityGetByProvinceParams>(CityEndPoints.GET_BY_PROVINCE, model);
};

/**
 * Get by postal code
 *  {string} countryCode Country code
 *  {string} postCode Postal code
 * @return {Array<City>} List City
 */
export const cityServiceGetByCountryAndPostCode = (
	model: ICityGetByCountryAndPostCodeParams,
): Promise<ServiceResponse<ICity[]>> => {
	return Api.get<ICity[], ICityGetByCountryAndPostCodeParams>(CityEndPoints.GET_BY_POST_CODE_AND_COUNTRY, model);
};
