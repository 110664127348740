import type { IFranchiseLine } from '../../models/entities/FranchiseLine';
import type { IFranchiseLineRepair } from '../../models/entities/FranchiseLineRepair';
import type { ILink } from '../../models/entities/Link';
import type {
	IFranchiseLineAddLinksParams,
	IFranchiseLineGetByBookingParams,
	IFranchiseLineGetByVehicleParams,
	IFranchiseLineInsertParams,
	IFranchiseLineRemoveParams,
	IFranchiseLineRepairParams,
	IFranchiseLineSearchParams,
} from '../../models/serviceParams/FranchiseLineParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { FranchiseEndPoints } from '../endPoints/FranchiseEndPoints';

/**
 * Insert franchise in vehicle or contract
 * @return {Promise} `FranchiseLinePropType`
 */
export const franchiseLineServiceInsert = (
	model: IFranchiseLineInsertParams,
): Promise<ServiceResponse<IFranchiseLine[]>> => {
	return Api.post<IFranchiseLine[], IFranchiseLineInsertParams>(FranchiseEndPoints.FRANCHISE_LINE_INSERT, model);
};

/**
 * Check repair franchise in contract o vehicle
 * @returns {Promise} `Boolean`
 */
export const franchiseLineServiceRepair = (
	model: IFranchiseLineRepairParams,
): Promise<ServiceResponse<IFranchiseLine>> => {
	return Api.post<IFranchiseLine, IFranchiseLineRepairParams>(FranchiseEndPoints.FRANCHISE_LINE_REPAIR, model);
};

/**
 * Get list FranchiseLine by booking number
 *  {string} bookingNumber Booking number
 * @returns {Promise} `Array<FranchiseLinePropType>`
 */
export const franchiseLineServiceGetLinesByBooking = (
	model: IFranchiseLineGetByBookingParams,
): Promise<ServiceResponse<IFranchiseLine[]>> => {
	return Api.get<IFranchiseLine[], IFranchiseLineGetByBookingParams>(
		FranchiseEndPoints.FRANCHISE_LINE_GET_BY_BOOKING,
		model,
	);
};

/**
 * Get franchise by plate number
 *  {string} plateNumber Plate number
 * @returns {Promise} `Array<FranchiseLinePropType>`
 */
export const franchiseLineServiceGetLinesByVehicle = (
	model: IFranchiseLineGetByVehicleParams,
): Promise<ServiceResponse<IFranchiseLine[]>> => {
	return Api.get<IFranchiseLine[], IFranchiseLineGetByVehicleParams>(
		FranchiseEndPoints.FRANCHISE_LINE_GET_BY_PLATENUMBER,
		model,
	);
};

/**
 * Get franchise by plate number
 *  {string} plateNumber Plate number
 * @returns {Promise} `Array<FranchiseLinePropType>`
 */
export const franchiseLineServiceRemove = (model: IFranchiseLineRemoveParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, IFranchiseLineRemoveParams>(FranchiseEndPoints.FRANCHISE_LINE_REMOVE, model);
};

/**
 * Get franchise by plate number
 *  {string} plateNumber Plate number
 * @returns {Promise} `Array<FranchiseLinePropType>`
 */
export const franchiseLineServiceAddLinks = (
	model: IFranchiseLineAddLinksParams,
): Promise<ServiceResponse<ILink[]>> => {
	return Api.post<ILink[], IFranchiseLineAddLinksParams>(FranchiseEndPoints.ADD_LINK, model);
};

/**
 * Search franchise lines
 * @param `IFranchiseLineSearchParams`
 * @returns {Promise} `Array<IFranchiseLineRepair>`
 */
export const franchiseLineServiceSearch = (
	model: IFranchiseLineSearchParams,
): Promise<ServiceResponse<IFranchiseLineRepair[]>> => {
	return Api.get<IFranchiseLineRepair[], IFranchiseLineSearchParams>(FranchiseEndPoints.SEARCH, model);
};
