import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { IPaymentMethod } from '@crac/core/models/entities/PaymentMethod';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { employeeLoginAndLoadCachedData } from '@crac/core/redux/actions/EmployeeActions';
import { paymentMethodGetAll } from '@crac/core/redux/actions/PaymentMethodActions';
import type { IPaymentMethodReducerState } from '@crac/core/redux-store/reducersState/paymentMethod';
const { getItem, setItem, removeItem } = managerLocalStorage();

const PAYMENT_METHOD_GET_ALL_KEY = 'paymentMethods';

const initialState: IPaymentMethodReducerState = {
	paymentMethods: (getItem(PAYMENT_METHOD_GET_ALL_KEY) as IPaymentMethod[]) || [],
	paymentMethodGetAllRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const paymentMethodSlice = createSlice({
	name: 'PaymentMethodSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			removeItem(PAYMENT_METHOD_GET_ALL_KEY);
			return { ...initialState, paymentMethods: [] };
		});
		// GET ALL
		builder
			.addCase(paymentMethodGetAll.pending, (state) => {
				return {
					...state,
					paymentMethods: [],
					paymentMethodGetAllRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentMethodGetAll.fulfilled, (state, action) => {
				setItem(PAYMENT_METHOD_GET_ALL_KEY, action.payload);
				return {
					...state,
					paymentMethods: deepCopy(action.payload),
					paymentMethodGetAllRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentMethodGetAll.rejected, (state, action) => {
				return {
					...state,
					paymentMethodGetAllRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(employeeLoginAndLoadCachedData.fulfilled, (state, action) => {
				setItem(PAYMENT_METHOD_GET_ALL_KEY, action.payload.paymentMethods);
				return {
					...state,
					paymentMethods: deepCopy(action.payload.paymentMethods),
				};
			});
	},
});

export const paymentMethodReducer = paymentMethodSlice.reducer;
