import { SaleServiceEndpoints } from './SaleServiceEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleService } from '../entities/SaleService';

export interface ISaleServiceDeleteParams extends IServiceBaseParams {
	id: number;
}

export const saleServiceDeleteService = (model: ISaleServiceDeleteParams) => {
	return Api.post<ISaleService, ISaleServiceDeleteParams>(SaleServiceEndpoints.DELETE, model);
};
