import type { IBudgetLine } from '../../models/entities/BudgetLine';
import type {
	IBudgetLineGetByBudgetAndVehicleParams,
	IBudgetLineGetByBudgetsAndVehicleParams,
} from '../../models/serviceParams/BudgetLineParams';
import { BudgetState } from '../../models/types/BudgetState';
import { Api } from '../../modules/shared/api';
import { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { BudgetLineEdnPoints } from '../endPoints/BudgetLineEndPoints';

/**
 * GetByBooking
 * @param {string} budgetCode Budget code
 * @param {string} plateNumber vehicle plateNumber
 * @return {Promise<ServiceResponse<IBudgetLine[]>>} `Promise<ApiDataResponseType<IBudgetLine[]>>`
 */
export const budgetLineServiceGetByBudgetAndVehicle = (
	model: IBudgetLineGetByBudgetAndVehicleParams,
): Promise<ServiceResponse<IBudgetLine[]>> => {
	return Api.get<IBudgetLine[], IBudgetLineGetByBudgetAndVehicleParams>(
		BudgetLineEdnPoints.GET_BY_BUDGET_AND_VEHICLE,
		model,
	);
};

export const budgetLineServiceGetByBudgetsAndVehicle = async ({
	budgets,
	plateNumber,
}: IBudgetLineGetByBudgetsAndVehicleParams): Promise<ServiceResponse<IBudgetLine[]>> => {
	if (budgets.length === 0) {
		return {
			errors: [],
			ok: true,
			data: [],
		};
	}

	const filteredBudgets = budgets.filter(({ state }) => state !== BudgetState.Rejected);
	const tasks: any[] = [];
	filteredBudgets.forEach(({ id }) => {
		tasks.push(budgetLineServiceGetByBudgetAndVehicle({ budgetId: id, plateNumber }));
	});

	const requestResponses: ServiceResponse<IBudgetLine[]>[] = await Promise.all(tasks);

	const payload: IBudgetLine[] = [];
	requestResponses.forEach((response) => {
		if (response.ok && response.data && response.data.length > 0) {
			payload.push(...response.data);
		}
	});

	return new ServiceResponse<IBudgetLine[]>(true, payload, []);
};
