import type { FC } from 'react';
import React from 'react';

import classNames from 'classnames';

import { ModalAlert } from './ModalAlert';
import { ModalContainer } from './ModalContainer';
import { ModalSlide } from './ModalSlide';
import { GcModal } from './styles';

export type ModalSize = {
	sm?: '4' | '6' | '8' | '10';
	md?: '4' | '6' | '8' | '10';
	lg?: '4' | '6' | '8' | '10';
};

interface IModalProps {
	isOpen?: boolean;
	onClose?: () => void;
	type?: 'slide' | 'alert';
	className?: string;
	maxIndex?: boolean;
	children?: React.ReactNode;
	/**
	 * Default values: { sm: 10, md: 8, lg: 6 }
	 */
	size?: ModalSize;
}

export const Modal: FC<IModalProps> = ({
	children,
	isOpen,
	type = 'slide',
	onClose,
	className,
	maxIndex = false,
	size,
}): JSX.Element | null => {
	if (!isOpen) {
		return null;
	}

	const handleClose = (): void => {
		if (onClose) {
			onClose();
		}
	};

	const modalSize: ModalSize = {
		sm: size?.sm ? size.sm : '10',
		md: size?.md ? size.md : '8',
		lg: size?.lg ? size.lg : '6',
	};

	return (
		<ModalContainer data-testid="modal" isModal>
			<GcModal
				$maxIndex={maxIndex}
				className={classNames(className, 'gc-modal', {
					'gc-modal--open': isOpen,
				})}
			>
				{type === 'slide' ? (
					<ModalSlide isOpen={isOpen} onClose={handleClose} size={modalSize}>
						{children}
					</ModalSlide>
				) : (
					<ModalAlert isOpen={isOpen} onClose={handleClose}>
						{children}
					</ModalAlert>
				)}
			</GcModal>
		</ModalContainer>
	);
};
