import type { IServiceRate } from '../../models/entities/ServiceRate';
import type { IServiceRateType } from '../../models/entities/ServiceRateType';
import type {
	IServiceRateTypeInsertParams,
	IServiceRateTypesSearchParams,
	IServiceRatesInsertParams,
	IServiceRatesSearchParams,
} from '../../models/serviceParams/ServiceRateParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { ServiceRateEndPoints, ServiceRateTypeEndPoints } from '../endPoints/ServiceRateEndPoints';

// ServiceRateType
export const serviceRateTypeSearchService = (
	model: IServiceRateTypesSearchParams,
): Promise<ServiceResponse<IServiceRateType[]>> => {
	return Api.get<IServiceRateType[], IServiceRateTypesSearchParams>(ServiceRateTypeEndPoints.SEARCH, model);
};
export const serviceRateTypeInsertService = (
	model: IServiceRateTypeInsertParams,
): Promise<ServiceResponse<IServiceRateType>> => {
	return Api.post<IServiceRateType, IServiceRateTypeInsertParams>(ServiceRateTypeEndPoints.INSERT, model);
};
export const serviceRateTypeModifyService = (
	model: IServiceRateTypeInsertParams,
): Promise<ServiceResponse<IServiceRateType>> => {
	return Api.post<IServiceRateType, IServiceRateTypeInsertParams>(ServiceRateTypeEndPoints.MODIFY, model);
};

// ServiceRates

export const serviceRateSearchService = (
	model: IServiceRatesSearchParams,
): Promise<ServiceResponse<IServiceRate[]>> => {
	return Api.get<IServiceRate[], IServiceRatesSearchParams>(ServiceRateEndPoints.SEARCH, model);
};

export const serviceRatesInsertService = (
	model: IServiceRatesInsertParams,
): Promise<ServiceResponse<IServiceRate[]>> => {
	return Api.post<IServiceRate[], IServiceRatesInsertParams>(ServiceRateEndPoints.INSERT, model);
};
