import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const RentRate = lazyWithRetry(() => import('./pages/RentRate'));
const RentRateUpdater = lazyWithRetry(() => import('./pages/RentRateUpdater'));
const RentRateUpdaterEdit = lazyWithRetry(() => import('./pages/RentRateUpdaterEdit'));
const RentRateUpdaterFormula = lazyWithRetry(() => import('../rentRateFormula/pages/RentRateFormulaPanel'));

export const rentRateRoutes: { [key: number]: IRouteConfig } = {};

rentRateRoutes[routesIdMap.RentRates] = {
	component: RentRate,
	exact: true,
	id: routesIdMap.RentRates,
	menuText: 'Rates',
	name: 'Rent rates',
	path: '/pricing/rent-rates',
	permissions: getPricingPermission([PermissionsType.PricingRentRate]),
	icon: 'percent',
};

// UPDATERS
rentRateRoutes[routesIdMap.RentRateUpdaters] = {
	component: RentRateUpdater,
	exact: true,
	id: routesIdMap.RentRateUpdaters,
	name: 'Rent rate updaters',
	path: '/pricing/rent-rate-updaters',
	permissions: getPricingPermission([PermissionsType.PricingRentRate]),
	menuText: 'Rent rate updaters',
	icon: 'pencil-square-o',
};

rentRateRoutes[routesIdMap.RentRateUpdatersEdit] = {
	component: RentRateUpdaterEdit,
	exact: true,
	id: routesIdMap.RentRateUpdatersEdit,
	name: 'Rent rate updaters edit',
	path: '/pricing/rent-rate-updaters/modify',
	permissions: getPricingPermission([PermissionsType.PricingRentRate]),
	childrens: [routesIdMap.RentRateUpdatersFormulas],
	icon: 'pencil',
};

rentRateRoutes[routesIdMap.RentRateUpdatersFormulas] = {
	component: RentRateUpdaterFormula,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateUpdatersFormulas,
	name: 'Rent rate updaters formulas',
	path: '/pricing/rent-rate-updaters/modify/formulas',
	permissions: getPricingPermission([PermissionsType.PricingRentRate]),
	icon: 'pencil',
};
