import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { documentGetByBooking } from '@crac/core/redux/actions/DocumentActions';
import type { IDocumentReducerState } from '@crac/core/redux-store/reducersState/booking/DocumentReducerState';
const initialState: IDocumentReducerState = {
	documents: [],
	documentsRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const documentsSlice = createSlice({
	name: 'DocumentSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// DOCUMENT_GET_BY_BOOKING
		builder
			.addCase(documentGetByBooking.pending, (state) => {
				return {
					...state,
					documents: [],
					documentsRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(documentGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					documents: deepCopy(action.payload),
					documentsRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(documentGetByBooking.rejected, (state, action) => {
				return {
					...state,
					documents: [],
					documentsRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const documentsReducer = documentsSlice.reducer;
