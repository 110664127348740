import { createSlice } from '@reduxjs/toolkit';

import {
	includedServiceConfigurationClear,
	includedServiceConfigurationDeactivate,
	includedServiceConfigurationInsert,
	includedServiceConfigurationModify,
	includedServiceConfigurationRemoveDeactivated,
	includedServiceConfigurationSearch,
} from '@crac/core/redux/actions/IncludedServiceConfigurationActions';
import type { IncludedServiceConfigurationReducerState } from '@crac/core/redux-store/reducersState/pricing/IncludedServiceConfigurationReducerState';
const initialState: IncludedServiceConfigurationReducerState = {
	includedServiceConfigurationInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	includedServiceConfigurationModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	includedServiceConfigurationSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	includedServiceConfigurationDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	includedServicesConfigurations: [],
};

const includedServiceConfigurationSlice = createSlice({
	name: 'IncludedServiceConfigurationSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(includedServiceConfigurationSearch.pending, (state) => {
				return {
					...state,
					includedServiceConfigurationSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(includedServiceConfigurationSearch.fulfilled, (state, action) => {
				return {
					...state,
					includedServiceConfigurationSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					includedServicesConfigurations: action.payload,
				};
			})
			.addCase(includedServiceConfigurationSearch.rejected, (state, action) => {
				return {
					...state,
					includedServiceConfigurationSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(includedServiceConfigurationInsert.pending, (state) => {
				return {
					...state,
					includedServiceConfigurationInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(includedServiceConfigurationInsert.fulfilled, (state, action) => {
				return {
					...state,
					includedServiceConfigurationInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					includedServicesConfigurations: action.payload,
				};
			})
			.addCase(includedServiceConfigurationInsert.rejected, (state, action) => {
				return {
					...state,
					includedServiceConfigurationInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(includedServiceConfigurationModify.pending, (state) => {
				return {
					...state,
					includedServiceConfigurationModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(includedServiceConfigurationModify.fulfilled, (state, action) => {
				return {
					...state,
					includedServiceConfigurationModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					includedServicesConfigurations: [
						...state.includedServicesConfigurations.filter((item) => item.id !== action.payload.id),
						action.payload,
					],
				};
			})
			.addCase(includedServiceConfigurationModify.rejected, (state, action) => {
				return {
					...state,
					includedServiceConfigurationModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// REMOVE DEACTIVATED
		builder.addCase(includedServiceConfigurationRemoveDeactivated, (state, action) => {
			return {
				...state,
				includedServicesConfigurations: state.includedServicesConfigurations.filter(
					({ id }) => id !== action.payload,
				),
			};
		});
		// DEACTIVATE
		builder
			.addCase(includedServiceConfigurationDeactivate.pending, (state) => {
				return {
					...state,
					includedServiceConfigurationDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(includedServiceConfigurationDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					includedServiceConfigurationDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					includedServicesConfigurations: [
						...state.includedServicesConfigurations.filter(
							({ id }) => !action.payload.map((item) => item.id).includes(id),
						),
						...action.payload,
					],
				};
			})
			.addCase(includedServiceConfigurationDeactivate.rejected, (state, action) => {
				return {
					...state,
					includedServiceConfigurationDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: true,
					},
				};
			});
		// CLEAR
		builder.addCase(includedServiceConfigurationClear, () => {
			return initialState;
		});
	},
});

export const includedServiceConfigurationReducer = includedServiceConfigurationSlice.reducer;
