import {
	fleetVehicleBrandServiceDelete,
	fleetVehicleBrandServiceInsert,
	fleetVehicleBrandServiceModify,
	fleetVehicleBrandServiceSearch,
} from '../../data/services/FleetVehicleBrandService';
import type { IVehicleBrand } from '../../models/entities/VehicleBrand';
import type {
	IFleetVehicleBrandDeleteParams,
	IFleetVehicleBrandInsertParams,
	IFleetVehicleBrandModifyParams,
	IFleetVehicleBrandSearchParams,
} from '../../models/serviceParams/FleetVehicleBrandParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Clears the vehicle brand data from the state.
 */
export const fleetVehicleBrandClear = createSyncAction('fleet/vehicleBrandClear');

/**
 * Searches for vehicle brands based on search parameters.
 * @param {IVehicleBrand[]} payload `IVehicleBrand[]` The payload for the action.
 * @param {IFleetVehicleBrandSearchParams} IFleetVehicleBrandSearchParams The parameters for retrieving vehicle brands.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of vehicle brands.
 */
export const fleetVehicleBrandSearch = createAsyncAction<IVehicleBrand[], IFleetVehicleBrandSearchParams>(
	'fleet/vehicleBrand/search',
	fleetVehicleBrandServiceSearch,
);

/**
 * Inserts a new vehicle brand into the fleet.
 * @param {IVehicleBrand} payload `IVehicleBrand` The vehicle brand to be inserted.
 * @param {IFleetVehicleBrandInsertParams} IFleetVehicleBrandInsertParams The parameters for inserting a new vehicle brand.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the vehicle brand has been inserted.
 */
export const fleetVehicleBrandInsert = createAsyncAction<IVehicleBrand, IFleetVehicleBrandInsertParams>(
	'fleet/vehicleBrand/insert',
	fleetVehicleBrandServiceInsert,
);

/**
 * Modifies an existing vehicle brand in the fleet.
 * @param {IVehicleBrand} payload `IVehicleBrand` The vehicle brand to be modified.
 * @param {IFleetVehicleBrandModifyParams} IFleetVehicleBrandModifyParams The parameters for modifying an existing vehicle brand.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the vehicle brand has been modified.
 */
export const fleetVehicleBrandModify = createAsyncAction<IVehicleBrand, IFleetVehicleBrandModifyParams>(
	'fleet/vehicleBrand/modify',
	fleetVehicleBrandServiceModify,
);

/**
 * Deletes a vehicle brand from the fleet.
 * @param {IVehicleBrand} payload `IVehicleBrand` The vehicle brand to be deleted.
 * @param {IFleetVehicleBrandDeleteParams} IFleetVehicleBrandDeleteParams The parameters for deleting a vehicle brand.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the vehicle brand has been deleted.
 */
export const fleetVehicleBrandDelete = createAsyncAction<IVehicleBrand, IFleetVehicleBrandDeleteParams>(
	'fleet/vehicleBrand/delete',
	fleetVehicleBrandServiceDelete,
);
