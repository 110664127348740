import type { IBranchSchedule } from '../../models/entities/BranchSchedule';
import type {
	IBranchScheduleDeleteParams,
	IBranchScheduleInsertParams,
	IBranchScheduleModifyParams,
	IBranchScheduleSearchParams,
} from '../../models/serviceParams/BranchScheduleParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { BranchScheduleEndPoints } from '../endPoints/BranchScheduleEndPoints';

export const branchScheduleServiceSearch = (
	model: IBranchScheduleSearchParams,
): Promise<ServiceResponse<IBranchSchedule[]>> => {
	return Api.get<IBranchSchedule[], IBranchScheduleSearchParams>(BranchScheduleEndPoints.SEARCH, model);
};

export const branchScheduleServiceInsert = (
	model: IBranchScheduleInsertParams,
): Promise<ServiceResponse<IBranchSchedule[]>> => {
	return Api.post<IBranchSchedule[], IBranchScheduleInsertParams>(BranchScheduleEndPoints.INSERT, model);
};

export const branchScheduleServiceModify = (
	model: IBranchScheduleModifyParams,
): Promise<ServiceResponse<IBranchSchedule>> => {
	return Api.post<IBranchSchedule, IBranchScheduleModifyParams>(BranchScheduleEndPoints.MODIFY, model);
};

export const branchScheduleServiceDelete = (model: IBranchScheduleDeleteParams): Promise<ServiceResponse<number>> => {
	return Api.post<number, IBranchScheduleDeleteParams>(BranchScheduleEndPoints.DELETE, model);
};
