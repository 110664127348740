'use client';
import type { FC } from 'react';
import React from 'react';

import { ButtonGroup as RSButtonGroup } from 'reactstrap';
import type { ButtonGroupProps as RSButtonGroupProps } from 'reactstrap/types/lib/ButtonGroup';

export type ButtonGroupProps = RSButtonGroupProps;

export const ButtonGroup: FC<ButtonGroupProps> = ({ children, ...props }) => {
	return <RSButtonGroup {...props}>{children}</RSButtonGroup>;
};
