import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Revision = lazyWithRetry(() => import('./pages/Revision'));

export const revisionRoutes: { [key: number]: IRouteConfig } = {};

revisionRoutes[routesIdMap.VehiclesRevision] = {
	component: Revision,
	exact: true,
	id: routesIdMap.VehiclesRevision,
	name: 'Vehicle revision',
	path: '/vehicle/revision',
	permissions: [PermissionsType.VehicleRevision],
	icon: 'check',
	menuText: 'Revision',
};
