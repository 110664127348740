import { cashRoutes } from './cash/routes';
import { computerRoutes } from './computer/routes';
import { dashboardRoutes } from './dashboard/routes';
import { queuesRoutes } from './queues/routes';

export const managementRoutes = {
	...cashRoutes,
	...computerRoutes,
	...dashboardRoutes,
	...queuesRoutes,
};
