import { createAction } from '@reduxjs/toolkit';

import { kmRateServiceDeactivate, kmRateServiceInsert, kmRateServiceSearch } from '../../data/services/KmRateService';
import type { IKmRate } from '../../models/entities/KmRate';
import type {
	IKmRateDeactivateParams,
	IKmRateInsertParams,
	IKmRateSearchParams,
} from '../../models/serviceParams/KmRateParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for kilometer rates based on specified parameters.
 *
 * @param {IKmRate[]} payload - The kilometer rates to be returned.
 * @param {IKmRateSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of kilometer rates.
 */
export const kmRateSearch = createAsyncAction<IKmRate[], IKmRateSearchParams>('kmRate/search', kmRateServiceSearch);

/**
 * Inserts new kilometer rates into the system.
 *
 * @param {IKmRate[]} payload - The kilometer rates to be inserted.
 * @param {IKmRateInsertParams} params - Parameters detailing the kilometer rates to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the kilometer rates are successfully inserted.
 */
export const kmRateInsert = createAsyncAction<IKmRate[], IKmRateInsertParams>('kmRate/insert', kmRateServiceInsert);

/**
 * Clears the kilometer rate state.
 */
export const kmRateClear = createAction('kmRate/clear');

/**
 * Deactivates specified kilometer rates.
 *
 * @param {IKmRate[]} payload - The kilometer rates to be deactivated.
 * @param {IKmRateDeactivateParams} params - Parameters for deactivating the kilometer rates.
 * @returns {Promise<void>} - A promise that resolves after the kilometer rates are deactivated.
 */
export const kmRateDeactivate = createAsyncAction<IKmRate[], IKmRateDeactivateParams>(
	'kmRate/deactivate',
	kmRateServiceDeactivate,
);
