import type { IFineReason } from '../../models/entities/FineReason';
import type {
	IFineReasonGetByCodeParams,
	IFineReasonInsertParams,
	IFineReasonModifyParams,
	IFineReasonSearchParams,
} from '../../models/serviceParams/FineReasonParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { FineReasonEndPoints } from '../endPoints/FineReasonEndPoints';

/**
 * Search a fine reason
 * @return {Promise<FineReasonType>} `FineReasonType`
 */
export const fineReasonServiceSearch = (model: IFineReasonSearchParams) => {
	return Api.get<IFineReason[], IFineReasonSearchParams>(FineReasonEndPoints.SEARCH, model);
};

/**
 * Insert a fine reason
 * @return {Promise<FineReasonType>} `FineReasonType`
 */
export const fineReasonServiceInsert = (model: IFineReasonInsertParams): Promise<ServiceResponse<IFineReason>> => {
	return Api.post<IFineReason, IFineReasonInsertParams>(FineReasonEndPoints.INSERT, model);
};

/**
 * Modify a fine reason
 * @return {Promise<FineReasonType>} `FineReasonType`
 */
export const fineReasonServiceModify = (model: IFineReasonModifyParams): Promise<ServiceResponse<IFineReason>> => {
	return Api.post<IFineReason, IFineReasonModifyParams>(FineReasonEndPoints.MODIFY, model);
};

/**
 * Get all fine reason
 * @return {Array<FineReason>} List FineReason object
 */
export const fineReasonServiceGetAll = (): Promise<ServiceResponse<IFineReason[]>> => {
	return Api.get<IFineReason[], undefined>(FineReasonEndPoints.GET_ALL);
};

/**
 * Get a fine reason by code
 * @param {string} code FineReason code
 * @return {FineReasonPropType} FineReason
 */
export const fineReasonServiceGetByCode = (
	model: IFineReasonGetByCodeParams,
): Promise<ServiceResponse<IFineReason>> => {
	return Api.get<IFineReason, IFineReasonGetByCodeParams>(FineReasonEndPoints.GET_BY_CODE, model);
};
