import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Agency = lazyWithRetry(() => import('./pages/Agency'));
const Company = lazyWithRetry(() => import('./pages/Company'));
const Vendor = lazyWithRetry(() => import('./pages/Vendor'));

export const partnerRoutes: { [key: number]: IRouteConfig } = {};

partnerRoutes[routesIdMap.BookingsNew] = {
	component: Agency,
	exact: true,
	id: routesIdMap.BookingsNew,
	name: 'New booking',
	path: '/booking/new',
	permissions: [PermissionsType.BookingNew],
	menuText: 'New',
	icon: 'plus',
};

partnerRoutes[routesIdMap.BookingsNewCompany] = {
	component: Company,
	exact: true,
	id: routesIdMap.BookingsNewCompany,
	name: 'Booking company',
	path: '/booking/new/company',
	permissions: [PermissionsType.BookingNew],
	icon: 'file-o',
};

partnerRoutes[routesIdMap.BookingsNewVendor] = {
	component: Vendor,
	exact: true,
	id: routesIdMap.BookingsNewVendor,
	name: 'Booking vendor',
	path: '/booking/new/vendor',
	permissions: [PermissionsType.BookingNew],
	icon: 'file-o',
};
