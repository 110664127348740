import { MetaModelEndpoints } from './MetaModelEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IMetaModel } from '../entities/MetaModel';

export interface IMetaModelSaveImageParams extends IServiceBaseParams {
	id: number;
	image?: string;
}

export const metaModelSaveImageService = (model: IMetaModelSaveImageParams) => {
	return Api.post<IMetaModel, IMetaModelSaveImageParams>(MetaModelEndpoints.SAVE_IMAGE, model);
};
