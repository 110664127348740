import { SaleServiceEndpoints } from './SaleServiceEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleService } from '../entities/SaleService';

export interface ISaleServiceModifyParams extends IServiceBaseParams {
	id: number;
	name: string;
}

export const saleServiceModifyService = (model: ISaleServiceModifyParams) => {
	return Api.post<ISaleService, ISaleServiceModifyParams>(SaleServiceEndpoints.MODIFY, model);
};
