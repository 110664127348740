import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { ISaleServiceRate } from '../../entities/SaleServiceRate';
import type { ISaleServiceRateDeactivateParams } from '../../services/SaleServiceRateDeactivateService';
import { saleServiceRateDeactivateService } from '../../services/SaleServiceRateDeactivateService';
import type { ISaleServiceRateGetServiceRateParams } from '../../services/SaleServiceRateGetServiceRateService';
import { saleServiceRateGetServiceRateService } from '../../services/SaleServiceRateGetServiceRateService';
import type { ISaleServiceRateInsertParams } from '../../services/SaleServiceRateInsertService';
import { saleServiceRateInsertService } from '../../services/SaleServiceRateInsertService';
import type { ISaleServiceRateReactivateParams } from '../../services/SaleServiceRateReactivateService';
import { saleServiceRateReactivateService } from '../../services/SaleServiceRateReactivateService';
import type { ISaleServiceRateSearchParams } from '../../services/SaleServiceRateSearchService';
import { saleServiceRateSearchService } from '../../services/SaleServiceRateSearchService';

//* GET SERVICE RATE
export const saleServiceRateGetServiceRate = createAsyncAction<ISaleServiceRate, ISaleServiceRateGetServiceRateParams>(
	'saleServiceRate/getServiceRate',
	saleServiceRateGetServiceRateService,
);

//* SEARCH
export const saleServiceRateSearch = createAsyncAction<ISaleServiceRate[], ISaleServiceRateSearchParams>(
	'saleServiceRate/search',
	saleServiceRateSearchService,
);

//* INSERT
export const saleServiceRateInsert = createAsyncAction<ISaleServiceRate[], ISaleServiceRateInsertParams>(
	'saleServiceRate/insert',
	saleServiceRateInsertService,
);

//* DEACTIVATE
export const saleServiceRateDeactivate = createAsyncAction<ISaleServiceRate[], ISaleServiceRateDeactivateParams>(
	'saleServiceRate/deactivate',
	saleServiceRateDeactivateService,
);

//* REACTIVATE
export const saleServiceRateReactivate = createAsyncAction<ISaleServiceRate[], ISaleServiceRateReactivateParams>(
	'saleServiceRate/reactivate',
	saleServiceRateReactivateService,
);

//* CLEAR
export const saleServiceRatesClear = createSyncAction('saleServiceRates/clear');
