import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const PriceViewer = lazyWithRetry(() => import('./pages/PriceViewer'));

export const priceViewerRoutes: { [key: number]: IRouteConfig } = {};

// PRICE VIEWER
priceViewerRoutes[routesIdMap.RentRatePriceViewer] = {
	component: PriceViewer,
	exact: true,
	id: routesIdMap.RentRatePriceViewer,
	name: 'Price viewer',
	path: '/pricing/viewer',
	permissions: getPricingPermission([PermissionsType.Pricing]),
	icon: 'eye',
	menuText: 'Price viewer',
};
