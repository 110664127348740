import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const RentRateValidation = lazyWithRetry(() => import('./pages/RentRateValidation'));
const RentRateValidationNew = lazyWithRetry(() => import('./pages/RentRateValidationNew'));
const RentRateValidationModify = lazyWithRetry(() => import('./pages/RentRateValidationModify'));

export const rentRateValidationRoutes: { [key: number]: IRouteConfig } = {};

// VALIDATION
rentRateValidationRoutes[routesIdMap.RentRateValidation] = {
	component: RentRateValidation,
	exact: true,
	id: routesIdMap.RentRateValidation,
	menuText: 'Validations',
	name: 'Rent rate validations',
	path: '/pricing/rent-rates/validations',
	permissions: getPricingPermission([PermissionsType.PricingRentRateValidation]),
	childrens: [routesIdMap.RentRateValidationNew, routesIdMap.RentRateValidationModify],
	icon: 'check-square-o',
};

rentRateValidationRoutes[routesIdMap.RentRateValidationNew] = {
	component: RentRateValidationNew,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateValidationNew,
	name: 'New rent rate validation',
	path: '/pricing/rent-rates/validations/new',
	permissions: getPricingPermission([PermissionsType.PricingRentRateValidation]),
	menuText: 'New validation',
	icon: 'plus',
};

rentRateValidationRoutes[routesIdMap.RentRateValidationModify] = {
	component: RentRateValidationModify,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateValidationModify,
	name: 'Modify rent rate validation',
	path: '/pricing/rent-rates/validations/:id',
	permissions: getPricingPermission([PermissionsType.PricingRentRateValidation]),
	icon: 'pencil',
};
