import { zoneServiceGetAll } from '../../data/services/ZoneService';
import type { IZone } from '../../models/entities/Zone';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Retrieves all zones.
 *
 * @param {IZone[]} payload - The zones to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters, potentially used for filtering or configuration.
 * @returns {Promise<void>} - A promise that resolves to an array of all zones.
 */
export const zoneGetAll = createAsyncAction<IZone[], IServiceBaseParams | undefined>('zone/getAll', zoneServiceGetAll);
