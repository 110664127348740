import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Comments = lazyWithRetry(() => import('./pages/Comments'));

export const commentsRoutes: { [key: number]: IRouteConfig } = {};

commentsRoutes[routesIdMap.CustomersDetailComments] = {
	component: Comments,
	exact: true,
	id: routesIdMap.CustomersDetailComments,
	name: 'Comments',
	path: '/customers/detail/:id/comments',
	permissions: [PermissionsType.CustomerDetail],
	icon: 'comments',
};
