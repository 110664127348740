import type { IAvailabilityGroup } from '../../models/entities/Availability';
import type {
	IAvailabilityConfigurationDeleteParams,
	IAvailabilityConfigurationInsertParams,
	IAvailabilityConfigurationSearchParams,
} from '../../models/serviceParams/AvailabilityConfigurationParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { AvailabilityConfigurationEndPoints } from '../endPoints/AvailabilityConfigurationEndPoints';
/**
 * Search group availability
 */
export const availabilityConfigSearchService = (
	model: IAvailabilityConfigurationSearchParams,
): Promise<ServiceResponse<IAvailabilityGroup[]>> => {
	return Api.get<IAvailabilityGroup[], IAvailabilityConfigurationSearchParams>(
		AvailabilityConfigurationEndPoints.SEARCH,
		model,
	);
};

/**
 * New group availability
 */
export const availabilityConfigInsertService = (
	model: IAvailabilityConfigurationInsertParams,
): Promise<ServiceResponse<IAvailabilityGroup[]>> => {
	return Api.post<IAvailabilityGroup[], IAvailabilityConfigurationInsertParams>(
		AvailabilityConfigurationEndPoints.INSERT,
		model,
	);
};

/**
 * Modify group availability
 */
export const availabilityConfigModifyService = (
	model: IAvailabilityConfigurationInsertParams,
): Promise<ServiceResponse<IAvailabilityGroup[]>> => {
	return Api.post<IAvailabilityGroup[], IAvailabilityConfigurationInsertParams>(
		AvailabilityConfigurationEndPoints.MODIFY,
		model,
	);
};

/**
 * Delete group availability
 */
export const availabilityConfigDeleteService = (
	model: IAvailabilityConfigurationDeleteParams,
): Promise<ServiceResponse<IAvailabilityGroup>> => {
	return Api.post<IAvailabilityGroup, IAvailabilityConfigurationDeleteParams>(
		AvailabilityConfigurationEndPoints.DELETE,
		model,
	);
};
