import { createSlice } from '@reduxjs/toolkit';

import type { IWorkshopAssignment } from '@crac/core/models/entities/WorkshopAssignment';
import {
	workshopAssignmentClear,
	workshopAssignmentDelete,
	workshopAssignmentInsert,
	workshopAssignmentModify,
	workshopAssignmentSearch,
} from '@crac/core/redux/actions/WorkshopAssignmentActions';
import type { IWorkshopAssignmentReducerState } from '@crac/core/redux-store/reducersState/workshop/WorkshopAssignmentReducerState';

const initialState: IWorkshopAssignmentReducerState = {
	workshopAssignmentInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	workshopAssignments: [],
	workshopAssignmentSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	workshopAssignmentModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	workshopAssignmentDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const assignmentSlice = createSlice({
	name: 'assignmentSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		/* SEARCH */
		builder
			.addCase(workshopAssignmentSearch.pending, (state) => {
				return {
					...state,
					workshopAssignmentSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(workshopAssignmentSearch.fulfilled, (state, action) => {
				return {
					...state,
					workshopAssignmentSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					workshopAssignments: action.payload,
				};
			})
			.addCase(workshopAssignmentSearch.rejected, (state, action) => {
				return {
					...state,
					workshopAssignmentSearchRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
		// CLEAR SEARCH
		builder.addCase(workshopAssignmentClear, (state) => {
			return {
				...state,
				workshopAssignments: [],
			};
		});
		// INSERT
		builder
			.addCase(workshopAssignmentInsert.pending, (state) => {
				return {
					...state,
					workshopAssignmentInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(workshopAssignmentInsert.fulfilled, (state, action) => {
				return {
					...state,
					workshopAssignmentInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					workshopAssignments: [...state.workshopAssignments, action.payload],
				};
			})
			.addCase(workshopAssignmentInsert.rejected, (state, action) => {
				return {
					...state,
					workshopAssignmentInsertRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(workshopAssignmentModify.pending, (state) => {
				return {
					...state,
					workshopAssignmentModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(workshopAssignmentModify.fulfilled, (state, action) => {
				const { id } = action.payload as IWorkshopAssignment;
				const workshopAssignments = state.workshopAssignments?.map((workshopAssignment) =>
					workshopAssignment.id === id ? { ...action.payload } : workshopAssignment,
				);
				return {
					...state,
					workshopAssignments,
					workshopAssignmentModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(workshopAssignmentModify.rejected, (state, action) => {
				return {
					...state,
					workshopAssignmentModifyRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
		// DELETE
		builder
			.addCase(workshopAssignmentDelete.pending, (state) => {
				return {
					...state,
					workshopAssignmentDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(workshopAssignmentDelete.fulfilled, (state, action) => {
				const { id } = action.payload as IWorkshopAssignment;
				const workshopAssignments = state.workshopAssignments.filter(
					(workshopAssignment) => workshopAssignment.id !== id,
				);
				return {
					...state,
					workshopAssignments,
					workshopAssignmentDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(workshopAssignmentDelete.rejected, (state, action) => {
				return {
					...state,
					workshopAssignmentDeleteRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
	},
});

export const assignmentReducer = assignmentSlice.reducer;
