/* eslint-disable no-console */
import { useCallback, useEffect, useState } from 'react';

import type { HubConnection } from '@aspnet/signalr';
import { HttpTransportType, HubConnectionBuilder, HubConnectionState } from '@aspnet/signalr';

import { REALTIME_ENDPOINT } from '@crac/core/modules/shared/api/ApiEndPoint';

// TODO: change library to @microsoft/signalr

interface ISignalrConnection {
	hubConnection: HubConnection | null;
	addListener: (event: string, callback: (...args: any[]) => void) => void;
	removeListener: (event: string) => void;
	invoke: (method: string, ...args: any[]) => void;
	disconnect: () => void;
	connect: (token: string) => void;
}

export const useSignalR = (token: string): ISignalrConnection => {
	const [hubConnection, setHubConnection] = useState<HubConnection | null>(null);

	// Set the initial SignalR Hub Connection.
	const createHubConnection = useCallback(async (token: string) => {
		// Build new Hub Connection, url is currently hard coded.
		const hubConnect = new HubConnectionBuilder()
			.withUrl(REALTIME_ENDPOINT, {
				accessTokenFactory: () => token,
				transport: HttpTransportType.WebSockets,
			})
			.build();

		try {
			await hubConnect.start();
			console.info('Connection successful!');
		} catch (err) {
			console.error(err);
		}
		setHubConnection(hubConnect);
	}, []);

	useEffect(() => {
		if (!hubConnection && token) {
			createHubConnection(token);
		}
	}, [hubConnection, token, createHubConnection]);

	const addListener = (event: string, callback: any): void => {
		if (hubConnection) {
			hubConnection.on(event, callback);
		}
	};

	const removeListener = (event: string): void => {
		if (hubConnection) {
			hubConnection.off(event);
		}
	};

	const invoke = (method: string, ...args: any[]): void => {
		if (hubConnection && hubConnection.state === HubConnectionState.Connected) {
			hubConnection.invoke(method, ...args);
		}
	};

	const disconnect = async (): Promise<void> => {
		if (hubConnection) {
			await hubConnection.stop();
		}
	};

	const connect = async (token: string): Promise<void> => {
		await createHubConnection(token);
	};

	return {
		addListener,
		connect,
		disconnect,
		hubConnection,
		invoke,
		removeListener,
	};
};
