import { createAction } from '@reduxjs/toolkit';

import { agencyServiceGetByCode, agencyServiceSearch } from '../../data/services/AgencyService';
import type { IAgency } from '../../models/entities/Agency';
import type { IAgencyGetByCodeParams, IAgencySearchParams } from '../../models/serviceParams/AgencyParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for agencies based on the provided search parameters.
 *
 * @params {IAgency[]} payload - The agencies to be returned.
 * @param {IAgencySearchParams} params - The search parameters.
 * @returns {Promise<void>} - A promise that resolves to an array of agencies.
 */
export const agencySearch = createAsyncAction<IAgency[], IAgencySearchParams>('agency/search', agencyServiceSearch);

/**
 * Retrieves an agency by its code.
 *
 * @params {IAgency} payload - The agency to be returned.
 * @param {IAgencyGetByCodeParams} params - The parameters for retrieving the agency.
 * @returns {Promise<void>} - A promise that resolves to the retrieved agency.
 */
export const agencyGetByCode = createAsyncAction<IAgency, IAgencyGetByCodeParams>(
	'agency/getByCode',
	agencyServiceGetByCode,
);

/**
 * Clears the agency state.
 */
export const agencyClear = createAction('agency/clear');

/**
 * Clears the agency search state.
 */
export const agencyClearSearch = createAction('agency/clearSearch');
