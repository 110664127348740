import type { FC } from 'react';
import React from 'react';

import { styled } from 'styled-components';

import { LogoSymbol } from './LogoSymbol';

interface ILogoProps {
	type?: 'symbol' | 'normal';
}

const Container = styled.div`
	margin: auto;
	max-width: 230px;
`;

export const Logo: FC<ILogoProps> = ({ type = 'normal' }) => {
	return (
		<Container data-test="logo">
			{type === 'normal' && (
				<svg preserveAspectRatio="xMinYMax meet" viewBox="0 0 180 50" xmlns="http://www.w3.org/2000/svg">
					<g fill="#253081">
						<path d="M36,29.91c.3-4.72-2.23-9.12-7.46-9.12-4.66,0-7.84,3.51-7.84,8.11s3,8.08,7.84,8.08a6.91,6.91,0,0,0,7.19-5.17H32a3.47,3.47,0,0,1-3.33,2c-2.35,0-3.65-1.22-3.77-3.89ZM24.9,27.24A3.32,3.32,0,0,1,28.4,24c2,0,3,1.13,3.36,3.27Z" />
						<path d="M60.1,16.6H55.88v4.6H53.32V24h2.56v9.06c0,3.06,2.26,3.65,4.84,3.65a16.26,16.26,0,0,0,2.47-.15V33.3a6.46,6.46,0,0,1-1.31.12c-1.43,0-1.78-.36-1.78-1.78V24h3.09V21.21H60.1Z" />
						<path d="M48.75,36.57V28.31a3.57,3.57,0,1,0-7.14,0v8.26H37.67V28.31a7.52,7.52,0,0,1,15-.09v8.35Z" />
						<path d="M86.31,21.21v8.25a3.57,3.57,0,1,0,7.14,0V21.21H97.4v8.25a7.52,7.52,0,0,1-15,.09V21.21Z" />
						<path d="M13.63,20.81c2.7,0,4.48,1.13,6.3,3L17.3,26.4a4.81,4.81,0,0,0-3.67-1.86,4.36,4.36,0,1,0,0,8.72,4.81,4.81,0,0,0,3.66-1.85L19.92,34C18.1,35.86,16.32,37,13.63,37a8.09,8.09,0,1,1,0-16.17Z" />
						<path d="M107.46,25.06A3.92,3.92,0,0,0,103.63,29v8H99.46V29a8,8,0,0,1,8-8Z" />
						<path d="M113.06,29.07A4.07,4.07,0,1,0,117.13,25,4.07,4.07,0,0,0,113.06,29.07Zm-4.06,0a8.13,8.13,0,1,1,8.13,8.13A8.13,8.13,0,0,1,109,29.07Z" />
						<path d="M72.11,33.06a4.32,4.32,0,1,0-4.32-4.32A4.32,4.32,0,0,0,72.11,33.06Zm0-12.32a8,8,0,0,1,8,8v8H76.43V35.49a8,8,0,1,1-4.32-14.75Z" />
						<path d="M136.16,30.36a2.38,2.38,0,1,0-4.72-.31,2.31,2.31,0,0,0,2.42,2.49,2.13,2.13,0,0,0,2.22-1.59h-1.14a1.07,1.07,0,0,1-1,.61c-.72,0-1.13-.38-1.16-1.2Zm-3.42-.82a1,1,0,0,1,1.08-1c.63,0,.92.35,1,1Z" />
						<path d="M144,26.25h-1.3v1.42h-.79v.87h.79v2.79c0,.94.7,1.13,1.49,1.13a5,5,0,0,0,.76,0v-1a2,2,0,0,1-.4,0c-.44,0-.55-.11-.55-.55V28.55h1v-.87h-1Z" />
						<path d="M131.07,28.78A1.19,1.19,0,0,0,129.9,30v2.43h-1.27V30a2.43,2.43,0,0,1,2.43-2.43Z" />
						<path d="M140.31,32.38V29.86a1.09,1.09,0,1,0-2.18,0v2.52h-1.2V29.86a2.29,2.29,0,0,1,4.59,0v2.55Z" />
						<path d="M158.29,27.55a2.58,2.58,0,0,1,1.95.91l-.81.81a1.49,1.49,0,0,0-1.13-.58,1.35,1.35,0,0,0,0,2.69,1.49,1.49,0,0,0,1.13-.57l.81.81a2.58,2.58,0,0,1-1.94.91,2.5,2.5,0,0,1,0-5Z" />
						<path d="M168.91,28.78a1.19,1.19,0,0,0-1.16,1.2v2.43h-1.27V30a2.43,2.43,0,0,1,2.43-2.43Z" />
						<path d="M150.08,31.29A1.31,1.31,0,1,0,148.77,30,1.31,1.31,0,0,0,150.08,31.29Zm0-3.74A2.43,2.43,0,0,1,152.51,30v2.43h-1.12V32a2.43,2.43,0,1,1-1.31-4.48Z" />
						<path d="M163,31.29A1.31,1.31,0,1,0,161.67,30,1.31,1.31,0,0,0,163,31.29Zm0-3.74A2.43,2.43,0,0,1,165.41,30v2.43h-1.12V32A2.43,2.43,0,1,1,163,27.55Z" />
						<g fill="#ffe3a4">
							<path d="M156.38,18.41a10,10,0,0,0-2.72,2.5H148a14.65,14.65,0,0,1,1.39-2.5Z" />
							<path d="M130.65,12.74h30.89a14,14,0,1,1-11.07,22.69l-.05-.06h0l2.76-2.19a10.51,10.51,0,0,0,18.87-6.38,10.53,10.53,0,0,0-9.93-10.49h-28Z" />
							<path d="M155,25.75H140.73l-3.29-3.39H155a1.69,1.69,0,1,1,0,3.39Z" />
						</g>
					</g>
				</svg>
			)}

			{type === 'symbol' && <LogoSymbol />}
		</Container>
	);
};
