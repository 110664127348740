import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const ConnectedVehicles = lazyWithRetry(() => import('~/features/fleet/connectedVehicles/pages/ConnectedVehicles'));
const ConnectedVehicleTelemetry = lazyWithRetry(
	() => import('~/features/fleet/connectedVehicles/pages/ConnectedVehicleTelemetry'),
);

export const connectedVehiclesRoutes: { [key: number]: IRouteConfig } = {};

connectedVehiclesRoutes[routesIdMap.ConnectedVehicles] = {
	component: ConnectedVehicles,
	exact: true,
	id: routesIdMap.ConnectedVehicles,
	name: 'Connected vehicles',
	path: '/fleet/connected-vehicles',
	permissions: [PermissionsType.IT],
	menuText: 'Connected vehicles',
	icon: 'wifi',
	childrens: [routesIdMap.ConnectedVehicleTelemetry],
};

connectedVehiclesRoutes[routesIdMap.ConnectedVehicleTelemetry] = {
	component: ConnectedVehicleTelemetry,
	exact: true,
	id: routesIdMap.ConnectedVehicleTelemetry,
	name: 'Connected vehicles',
	path: '/fleet/connected-vehicles/:plateNumber/telemetry',
	permissions: [PermissionsType.IT],
	icon: 'wifi',
};
