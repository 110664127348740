import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Accidents = lazyWithRetry(() => import('./pages/Accidents'));

export const accidentsRoutes: { [key: number]: IRouteConfig } = {};

accidentsRoutes[routesIdMap.VehiclesDetailAccidents] = {
	component: Accidents,
	exact: true,
	id: routesIdMap.VehiclesDetailAccidents,
	name: 'Accidents',
	path: '/vehicles/detail/:id/accidents',
	permissions: [PermissionsType.VehicleDetail],
	icon: 'file-text-o',
};

accidentsRoutes[routesIdMap.VehiclesDetailAccidentsDetail] = {
	component: Accidents,
	exact: true,
	id: routesIdMap.VehiclesDetailAccidentsDetail,
	name: 'Accident detail',
	path: '/vehicles/detail/:id/accidents/:document',
	permissions: [PermissionsType.VehicleDetail],
	icon: 'file-text-o',
};
