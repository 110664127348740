import type { IProvider } from '../../models/entities/Provider';
import type { IProviderGetByBranchParams } from '../../models/serviceParams/ProviderParams';
import { Api } from '../../modules/shared/api';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { ProviderEndPoints } from '../endPoints/ProviderEndPoints';

/**
 * Get all provider
 * @returns {Promise} `Array<ProviderPropType>`
 */
export const providerServiceGetAll = (model: IServiceBaseParams): Promise<ServiceResponse<IProvider[]>> => {
	return Api.get<IProvider[], IServiceBaseParams>(ProviderEndPoints.GET_ALL, model);
};

/**
 * Get provider by branch
 * @returns {Promise} `Array<ProviderPropType>`
 */
export const providerServiceGetByBranch = (model: IProviderGetByBranchParams): Promise<ServiceResponse<IProvider>> => {
	return Api.get<IProvider, IProviderGetByBranchParams>(ProviderEndPoints.GET_BY_BRANCH, model);
};
