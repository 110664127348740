import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Search = lazyWithRetry(() => import('./pages/Search'));

export const searchRoutes: { [key: number]: IRouteConfig } = {};

searchRoutes[routesIdMap.BookingsDropOff] = {
	component: Search,
	exact: true,
	id: routesIdMap.BookingsDropOff,
	name: 'Drop off',
	path: '/booking/drop-off',
	permissions: [PermissionsType.BookingDropOff],
	menuText: 'Drop off',
	icon: 'sign-out',
};
