import { mapPackageServices } from './BookingPackages';
import { getChoosableGroups } from './BookingVehicleGroups';
import type { IVehicleGroupAvailabilityAndPrice } from '../../models/entities/Availability';
import { CreationMethod } from '../../modules/shared/types/CreationMethod';

/**
 * Map content and filter vehicle groups availability
 * @param groups
 * @param creationMethod
 * @returns
 */
export const getMappedAndFilterVehicleGroupsAvailability = (
	groups: IVehicleGroupAvailabilityAndPrice[],
	creationMethod: CreationMethod,
	disabledFilter?: boolean,
) => {
	const vehicleGroupsAvailability = mapPackageServices(groups);

	// Filter no choosable group on the web and app
	if (!disabledFilter && [CreationMethod.WEB, CreationMethod.APP].includes(creationMethod)) {
		return getChoosableGroups(vehicleGroupsAvailability).filter((group) => group.packages.length > 0);
	}

	return vehicleGroupsAvailability;
};
