import { budgetLineServiceGetByBudgetsAndVehicle } from './BudgetLineService';
import { revisionLineServiceGetByBudgets } from './RevisionLineService';
import type { IBudget } from '../../models/entities/Budget';
import type { IRevisionCost, IRevisionCostLoadData } from '../../models/entities/RevisionCost';
import type {
	IRevisionCostLoadDataParams,
	IRevisionCostSaveParams,
	IRevisionCostSearchParams,
} from '../../models/serviceParams/RevisionCostParams';
import { BudgetState } from '../../models/types/BudgetState';
import { Api } from '../../modules/shared/api';
import { MessageType } from '../../modules/shared/types/MessageType';
import { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { RevisionCostEndPoints } from '../endPoints/RevisionCostEndPoints';

export const revisionCostServiceSearch = (
	model: IRevisionCostSearchParams,
): Promise<ServiceResponse<IRevisionCost[]>> => {
	return Api.get<IRevisionCost[], IRevisionCostSearchParams>(RevisionCostEndPoints.SEARCH, model);
};

export const revisionCostServiceSave = (model: IRevisionCostSaveParams): Promise<ServiceResponse<IRevisionCost[]>> => {
	return Api.post<IRevisionCost[], IRevisionCostSaveParams>(RevisionCostEndPoints.SAVE, model);
};

const loadRevisionCosts = async ({ budgets }: { budgets: IBudget[] }): Promise<ServiceResponse<IRevisionCost[]>> => {
	if (budgets.length === 0) {
		return {
			errors: [],
			ok: true,
			data: [],
		};
	}

	const tasks: any[] = [];
	const filteredBudgets = budgets.filter(({ state }) => state !== BudgetState.Rejected);
	filteredBudgets.forEach(({ id }) => {
		tasks.push(revisionCostServiceSearch({ budgetId: id }));
	});

	const requestResponses: ServiceResponse<IRevisionCost[]>[] = await Promise.all(tasks);

	const payload: IRevisionCost[] = [];
	requestResponses.forEach((response) => {
		if (response.ok && response.data && response.data.length > 0) {
			payload.push(...response.data);
		}
	});

	return new ServiceResponse<IRevisionCost[]>(true, payload, []);
};

/**
 * Load the revisionLines, revisionCosts, budgetLines and links of all the budgets provided
 * @param params `IRevisionCostLoadDataParams` - the array of budgets and the plateNumber
 * @returns `Promise<{ budgetLines: IBudgetLine[], revisionLines: IRevisionLine[], revisionCosts: IRevisionCost[], links: ILink[] }>`
 *
 */
export const revisionCostServiceLoadData = async ({
	plateNumber,
	budgets,
}: IRevisionCostLoadDataParams): Promise<ServiceResponse<IRevisionCostLoadData>> => {
	if (budgets.length > 0) {
		const [budgetLinesRequest, revisionLinesRequest, revisionCostsRequest] = await Promise.all([
			budgetLineServiceGetByBudgetsAndVehicle({ budgets, plateNumber }),
			revisionLineServiceGetByBudgets({ budgets }),
			loadRevisionCosts({ budgets }),
		]);

		return new ServiceResponse<IRevisionCostLoadData>(
			true,
			{
				budgetLines: budgetLinesRequest.ok && budgetLinesRequest.data ? budgetLinesRequest.data : [],
				revisionLines: revisionLinesRequest.ok && revisionLinesRequest.data ? revisionLinesRequest.data : [],
				revisionCosts: revisionCostsRequest.ok && revisionCostsRequest.data ? revisionCostsRequest.data : [],
			},
			[],
		);
	}

	return {
		ok: true,
		data: null,
		errors: [new MessageType('There are no budgets loaded')],
	};
};
