import {
	budgetServiceChangeWorkType,
	budgetServiceGetByPlateNumber,
	budgetServiceGetByVehicleAndId,
	budgetServiceGetByVehicleLine,
	budgetServiceImport,
	budgetServiceReject,
} from '../../data/services/BudgetService';
import type { IBudget } from '../../models/entities/Budget';
import type {
	IBudgetChangeWorkTypeParams,
	IBudgetGetByPlateNumberParams,
	IBudgetGetByVehicleAndIdParams,
	IBudgetGetByVehicleLineParams,
	IBudgetImportParams,
	IBudgetRejectParams,
} from '../../models/serviceParams/BudgetParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Gets a budget by plate number.
 * @param {IBudget[]} payload - The budget to be returned.
 * @param {IBudgetGetByPlateNumberParams} params - Parameters detailing the budget.
 * @returns {Promise<void>} - A promise that resolves to an array of budgets.
 */
export const budgetGetByPlateNumber = createAsyncAction<IBudget[], IBudgetGetByPlateNumberParams>(
	'budget/getByPlateNumber',
	budgetServiceGetByPlateNumber,
);

/**
 * Gets a budget by vehicle and id.
 * @param {IBudget} payload - The budget to be returned.
 * @param {IBudgetGetByVehicleAndIdParams} params - Parameters detailing the budget.
 * @returns {Promise<void>} - A promise that resolves to the budget.
 */
export const budgetGetByVehicleAndId = createAsyncAction<IBudget, IBudgetGetByVehicleAndIdParams>(
	'budget/GetByVehicleAndCode',
	budgetServiceGetByVehicleAndId,
);

/**
 * Clears the budget.
 * @returns {void}
 */
export const budgetClear = createSyncAction('budget/clear');

/**
 * Imports a budget.
 * @param {IBudget} payload - The budget to be imported.
 * @param {IBudgetImportParams} params - Parameters detailing the budget.
 * @returns {Promise<void>} - A promise that resolves after the budget is imported.
 */
export const budgetImport = createAsyncAction<IBudget, IBudgetImportParams>('budget/import', budgetServiceImport);

export const budgetGetByVehicleLine = createAsyncAction<IBudget[], IBudgetGetByVehicleLineParams>(
	'budget/getByVehicleLine',
	budgetServiceGetByVehicleLine,
);

/**
 * Change budget workType
 * @param `budgetId` number
 * @returns `Promise<IBudget>` returns the provided budget
 */
export const budgetChangeWorkType = createAsyncAction<IBudget, IBudgetChangeWorkTypeParams>(
	'budget/changeWorkType',
	budgetServiceChangeWorkType,
);

/**
 * Reject budget
 * @param {number}`budgetId` number
 * @returns <Promise<IBudget> returns the provided budget rejected
 */
export const budgetReject = createAsyncAction<IBudget, IBudgetRejectParams>('budget/reject', budgetServiceReject);
