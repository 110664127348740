import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Expedients = lazyWithRetry(() => import('./pages/Expedients'));

export const expedientsRoutes: { [key: number]: IRouteConfig } = {};

expedientsRoutes[routesIdMap.VehiclesDetailExpedients] = {
	component: Expedients,
	exact: true,
	id: routesIdMap.VehiclesDetailExpedients,
	name: 'Expedients',
	path: '/vehicles/detail/:id/expedients',
	permissions: [PermissionsType.VehicleDetail],
	icon: 'file',
};
