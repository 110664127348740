import { useEffect, useState } from 'react';

import { Navigate, useLocation } from 'react-router-dom';

import { RefreshToken } from '~/features/login/components/RefreshToken';
import { Layout } from '~/features/shared/components/layout';

/**
 * Check if customer is logged
 * > On this components only check if the customer is logged in
 * @returns Private component
 */
const Private = () => {
	const location = useLocation();
	const [isLogged, setIsLogged] = useState(() => {
		try {
			const storedEmployee = localStorage.getItem('employee');
			const storedToken = localStorage.getItem('token');

			return storedEmployee && storedToken;
		} catch {
			return false;
		}
	});

	/**
	 * DETECT LOCAL STORAGE CHANGES
	 * - If the user is logged in and the local storage is empty, the user is redirected to the login page
	 */
	useEffect(() => {
		const handleStorageChange = () => {
			const storedEmployee = localStorage.getItem('employee');
			const storedToken = localStorage.getItem('token');

			if (!storedEmployee || !storedToken) {
				setIsLogged(false);
			}
		};

		window.addEventListener('storage', handleStorageChange);

		return () => {
			window.removeEventListener('storage', handleStorageChange);
		};
	}, [isLogged]);

	if (!isLogged) {
		return <Navigate state={{ from: location }} to="/login" />;
	}

	return (
		<>
			<RefreshToken />
			<Layout />;
		</>
	);
};
export const PrivateRoute = Private;
