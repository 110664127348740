import type { IAgency } from '../../models/entities/Agency';
import type { IAgencyGetByCodeParams, IAgencySearchParams } from '../../models/serviceParams/AgencyParams';
import { Api } from '../../modules/shared/api';
import { AgencyEndPoints } from '../endPoints/AgencyEndPoints';

/**
 * Searches for agencies based on the provided search parameters.
 * @param model - The search parameters.
 * @returns `Promise<ServiceResponse<Agency[]>>` A promise that resolves to an array of agencies.
 */
export const agencyServiceSearch = (model: IAgencySearchParams) => {
	return Api.get<IAgency[], IAgencySearchParams>(AgencyEndPoints.SEARCH, model);
};

/**
 * Retrieves an agency by its code.
 * @param model - The parameters for retrieving the agency.
 * @returns `Promise<ServiceResponse<Agency[]>>` A promise that resolves to the agency object.
 */
export const agencyServiceGetByCode = (model: IAgencyGetByCodeParams) => {
	return Api.get<IAgency, IAgencyGetByCodeParams>(AgencyEndPoints.GET_BY_CODE, model);
};
