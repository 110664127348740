import { createSlice } from '@reduxjs/toolkit';

import { connectedCallStatusAdd, incomingCallStatusAdd } from '@crac/core/redux/actions/CallLogActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { ICallLogReducerState } from '@crac/core/redux-store/reducersState/callLog';

const initialState: ICallLogReducerState = {
	connectedCallStatusAddRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	incomingCallStatusAddRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const callLogSlice = createSlice({
	name: 'CallLogSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// CONNECTED CALL STATUS ADD
		builder
			.addCase(connectedCallStatusAdd.pending, (state) => {
				return {
					...state,
					connectedCallStatusAddRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(connectedCallStatusAdd.rejected, (state, action) => {
				return {
					...state,
					connectedCallStatusAddRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(connectedCallStatusAdd.fulfilled, (state) => {
				return {
					...state,
					connectedCallStatusAddRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			});
		// INCOMING CALL
		builder
			.addCase(incomingCallStatusAdd.pending, (state) => {
				return {
					...state,
					incomingCallStatusAddRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(incomingCallStatusAdd.fulfilled, (state) => {
				return {
					...state,
					connectedCallStatusAddRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(incomingCallStatusAdd.rejected, (state, action) => {
				return {
					...state,
					connectedCallStatusAddRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const callLogReducer = callLogSlice.reducer;
