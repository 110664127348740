import type { ICouponConfiguration } from '../../models/entities/CouponConfiguration';
import type {
	ICouponConfigurationDeactivateParams,
	ICouponConfigurationInsertParams,
	ICouponConfigurationModifyParams,
	ICouponConfigurationSearchParams,
} from '../../models/serviceParams/CouponConfigurationParams';
import { Api } from '../../modules/shared/api';
import { CouponConfigurationEndPoints } from '../endPoints/CouponConfigurationEndPoints';

/**
 * Search coupon configuration
 * @param params `ICouponConfigurationSearchParams`
 * @returns `Promise<ServiceResponse<ICouponConfiguration[]>>`
 */
export const serviceCouponConfigurationSearch = (params: ICouponConfigurationSearchParams) => {
	return Api.get<ICouponConfiguration[], ICouponConfigurationSearchParams>(
		CouponConfigurationEndPoints.SEARCH,
		params,
	);
};

/**
 * Insert coupon configuration
 * @param params `ICouponConfigurationInsertParams`
 * @returns `Promise<ServiceResponse<ICouponConfiguration[]>>`
 */
export const serviceCouponConfigurationInsert = (params: ICouponConfigurationInsertParams) => {
	return Api.post<ICouponConfiguration[], ICouponConfigurationInsertParams>(
		CouponConfigurationEndPoints.INSERT,
		params,
	);
};

/**
 * Modify coupon configuration
 * @param params `ICouponConfigurationModifyParams`
 * @returns `Promise<ServiceResponse<ICouponConfiguration>>`
 */
export const serviceCouponConfigurationModify = (params: ICouponConfigurationModifyParams) => {
	return Api.post<ICouponConfiguration, ICouponConfigurationModifyParams>(
		CouponConfigurationEndPoints.MODIFY,
		params,
	);
};

/**
 * Deactivate coupon configuration
 * @param params `ICouponConfigurationDeactivateParams`
 * @returns `Promise<ServiceResponse<ICouponConfiguration[]>>`
 */
export const serviceCouponConfigurationDeactivate = (params: ICouponConfigurationDeactivateParams) => {
	return Api.post<ICouponConfiguration[], ICouponConfigurationDeactivateParams>(
		CouponConfigurationEndPoints.DEACTIVATE,
		params,
	);
};
