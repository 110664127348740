import type { ILanguage } from '../../models/entities/Language';
import { Api } from '../../modules/shared/api';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { LanguageEndPoints } from '../endPoints/LanguageEndPoints';

/**
 * Get all languages
 * @return {Promise} `LanguagePropType` Return LanguagePropType
 */
export const languageServiceGetAll = (params: IServiceBaseParams): Promise<ServiceResponse<ILanguage[]>> => {
	return Api.get<ILanguage[], IServiceBaseParams>(LanguageEndPoints.GET_ALL, params);
};
