import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { vehicleReception } from '@crac/core/redux/actions/VehicleActions';
import type { IReceptionReducerState } from '@crac/core/redux-store/reducersState/vehicle/ReceptionReducerState';

const initialState: IReceptionReducerState = {
	reception: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const receptionSlice = createSlice({
	name: 'VehicleReceptionSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// VEHICLE RECEPTION
		builder
			.addCase(vehicleReception.pending, (state) => {
				return {
					...state,
					reception: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleReception.fulfilled, (state) => {
				return {
					...state,
					reception: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleReception.rejected, (state, action) => {
				return {
					...state,
					reception: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const receptionReducer = receptionSlice.reducer;
