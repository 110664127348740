import type { IOccupancy } from '../../models/entities/Occupancy';
import type {
	IOccupancyDeactivateParams,
	IOccupancyInsertParams,
	IOccupancySearchParams,
} from '../../models/serviceParams/OccupancyParams';
import { Api } from '../../modules/shared/api';
import { OccupancyEndPoints } from '../endPoints/OccupancyEndPoints';

/**
 * Search occupancy
 */
export const occupancyServiceSearch = (model: IOccupancySearchParams) => {
	return Api.get<IOccupancy[], IOccupancySearchParams>(OccupancyEndPoints.SEARCH, model);
};

/**
 * Insert occupancy
 */
export const occupancyServiceInsert = (model: IOccupancyInsertParams) => {
	return Api.post<IOccupancy[], IOccupancyInsertParams>(OccupancyEndPoints.INSERT, model);
};

/**
 * Deactivate occupancy
 */
export const occupancyServiceDeactivate = (model: IOccupancyDeactivateParams) => {
	return Api.post<IOccupancy[], IOccupancyDeactivateParams>(OccupancyEndPoints.DEACTIVATE, model);
};
