export enum BudgetEndPoints {
	/**
	 * get budget by plateNumber
	 * @host `/budget/getByPlateNumber/`
	 */
	GET_BY_PLATE_NUMBER = '/budget/getByPlateNumber/',

	/**
	 * get budget by plate number and budget code
	 */
	GET_BY_VEHICLE_AND_ID = '/budget/getByVehicleAndId/',
	/**
	 * get budget by id
	 * @host `/budget/getById/`
	 */
	GET_LINK_BY_ID = '/budget/getLinkById/',

	/**
	 * Import budgets
	 * @host `/budget/import/`
	 */
	IMPORT = '/budget/import/',

	/**
	 * get by vehicle line
	 * @host `/budget/getByVehicleLine`
	 */
	GET_BY_VEHICLE_LINE = '/budget/getByVehicleLine',

	/**
	 * change budget workType
	 * @host `/budget/changeWorkType`
	 */
	CHANGE_WORK_TYPE = '/budget/changeWorkType',

	/**
	 * reject budget
	 * @host `/budget/reject`
	 */
	REJECT = '/budget/reject',
}
