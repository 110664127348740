import { createSlice } from '@reduxjs/toolkit';

import { chargeBackGetReasons } from '@crac/core/redux/actions/ChargebackActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IChargeBackReducerState } from '@crac/core/redux-store/reducersState/chargeBack';

const initialState: IChargeBackReducerState = {
	chargebackReasons: [],
	chargebackReasonsRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const chargeBackSlice = createSlice({
	name: 'ChargeSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// CHARGE BACK GET REASONS
		builder
			.addCase(chargeBackGetReasons.pending, (state) => {
				return {
					...state,
					chargebackReasonsRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(chargeBackGetReasons.fulfilled, (state, action) => {
				return {
					...state,
					chargebackReasons: action.payload,
					chargebackReasonsRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(chargeBackGetReasons.rejected, (state, action) => {
				return {
					...state,
					chargebackReasonsRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const chargebackReducer = chargeBackSlice.reducer;
