import type { IBank } from '../../models/entities/Bank';
import type { IBankGetAllParams } from '../../models/serviceParams/BankParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { BankEndPoints } from '../endPoints/BankEndPoints';

/**
 * Get agency by code
 */
export const bankServiceGetAll = (model: IBankGetAllParams): Promise<ServiceResponse<IBank[]>> => {
	return Api.get<IBank[], IBankGetAllParams>(BankEndPoints.GET_ALL, model);
};
