import type { IKmRate } from '../../models/entities/KmRate';
import type {
	IKmRateDeactivateParams,
	IKmRateInsertParams,
	IKmRateSearchParams,
} from '../../models/serviceParams/KmRateParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { KmRateEndPoints } from '../endPoints/KmRateEndPoints';

/**
 * Search km rates
 * @param model IKmRateSearchParams
 * @returns IKmRate[]
 */
export const kmRateServiceSearch = (model: IKmRateSearchParams): Promise<ServiceResponse<IKmRate[]>> => {
	return Api.get<IKmRate[], IKmRateSearchParams>(KmRateEndPoints.SEARCH, model);
};
/**
 * Insert km rates
 * @param model IKmRateInsertParams
 * @returns IKmRate[]
 */
export const kmRateServiceInsert = (model: IKmRateInsertParams): Promise<ServiceResponse<IKmRate[]>> => {
	return Api.post<IKmRate[], IKmRateInsertParams>(KmRateEndPoints.INSERT, model);
};

/**
 * Deactivate km rates
 * @param ids number[]
 * @returns IKmRate[]
 */
export const kmRateServiceDeactivate = (model: IKmRateDeactivateParams): Promise<ServiceResponse<IKmRate[]>> => {
	return Api.post<IKmRate[], IKmRateDeactivateParams>(KmRateEndPoints.DEACTIVATE, model);
};
