import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { queueDelete, queueGetByBranch, queueInsert, queueUpdate } from '@crac/core/redux/actions/QueueActions';
import type { IQueuesReducerState } from '@crac/core/redux-store/reducersState/queues';

export const queuesStorageKey = 'queues';

const initialState: IQueuesReducerState = {
	delete: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	queue: undefined,
	queues: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
	save: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const queuesSlice = createSlice({
	name: 'QueueSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, (state) => {
			return {
				...state,
				queues: {
					data: [],
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});
		// QUEUE_GET_BY_BRANCH
		builder
			.addCase(queueGetByBranch.pending, (state) => {
				return {
					...state,
					queues: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(queueGetByBranch.fulfilled, (state, action) => {
				return {
					...state,
					queues: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(queueGetByBranch.rejected, (state, action) => {
				return {
					...state,
					queues: {
						data: [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// QUEUE_UPDATE
		builder
			.addCase(queueUpdate.pending, (state) => {
				return {
					...state,
					queue: undefined,
					save: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(queueUpdate.fulfilled, (state, action) => {
				return {
					...state,
					queue: deepCopy(action.payload),
					save: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(queueUpdate.rejected, (state, action) => {
				return {
					...state,
					queue: undefined,
					save: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// QUEUE_DELETE
		builder
			.addCase(queueDelete.pending, (state) => {
				return {
					...state,
					delete: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					queue: undefined,
				};
			})
			.addCase(queueDelete.fulfilled, (state, action) => {
				return {
					...state,
					delete: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					queues: {
						data: state.queues.data.filter((queue) => queue.id !== action.payload.id),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(queueDelete.rejected, (state, action) => {
				return {
					...state,
					delete: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
					queue: undefined,
				};
			});
		// QUEUE_INSERT
		builder
			.addCase(queueInsert.pending, (state) => {
				return {
					...state,
					queue: undefined,
					save: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(queueInsert.fulfilled, (state, action) => {
				return {
					...state,
					queue: deepCopy(action.payload),
					save: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(queueInsert.rejected, (state, action) => {
				return {
					...state,
					queue: undefined,
					save: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const queuesReducer = queuesSlice.reducer;
