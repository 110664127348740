import type { IOneWay } from '../../models/entities/OneWay';
import type {
	IOneWayDeleteParams,
	IOneWayInsertParams,
	IOneWayModifyParams,
	IOneWaySearchParams,
} from '../../models/serviceParams/OneWayParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { OneWayEndPoints } from '../endPoints/OneWayEndPoints';

/**
 * Search one ways
 * @param model IOneWaySearchParams
 * @returns IOneWay[]
 */
export const oneWayServiceSearch = (model: IOneWaySearchParams): Promise<ServiceResponse<IOneWay[]>> => {
	return Api.get<IOneWay[], IOneWaySearchParams>(OneWayEndPoints.SEARCH, model);
};
/**
 * Insert one ways
 * @param model IOneWayInsertParams
 * @returns IOneWay[]
 */
export const oneWayServiceInsert = (model: IOneWayInsertParams): Promise<ServiceResponse<IOneWay[]>> => {
	return Api.post<IOneWay[], IOneWayInsertParams>(OneWayEndPoints.INSERT, model);
};

/**
 * Modify one way
 * @param model IOneWayModifyParams
 * @returns IOneWay
 */
export const oneWayServiceModify = (model: IOneWayModifyParams): Promise<ServiceResponse<IOneWay>> => {
	return Api.post<IOneWay, IOneWayModifyParams>(OneWayEndPoints.MODIFY, model);
};

export const oneWayDeleteService = (model: IOneWayDeleteParams): Promise<ServiceResponse<IOneWay>> => {
	return Api.post<IOneWay, IOneWayDeleteParams>(OneWayEndPoints.DELETE, model);
};
