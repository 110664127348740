import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Return = lazyWithRetry(() => import('./pages/Return'));

export const returnRoutes: { [key: number]: IRouteConfig } = {};

returnRoutes[routesIdMap.VehiclesReturn] = {
	component: Return,
	exact: false,
	id: routesIdMap.VehiclesReturn,
	name: 'Return',
	path: '/vehicle/return',
	permissions: [PermissionsType.VehicleReturn],
	icon: 'sign-out',
	menuText: 'Return',
};

returnRoutes[routesIdMap.VehiclesReturnDetail] = {
	component: Return,
	exact: true,
	id: routesIdMap.VehiclesReturnDetail,
	name: 'Detail',
	path: '/vehicle/return/detail',
	permissions: [PermissionsType.VehicleReturn],
	icon: 'sign-out',
};
