'use client';
import type { FC } from 'react';
import React from 'react';

import { Modal as RSModal } from 'reactstrap';
import type { ModalProps as RSModalProps } from 'reactstrap/types/lib/Modal';

export type ModalProps = RSModalProps;

export const Modal: FC<ModalProps> = ({ children, ...props }): JSX.Element => {
	return <RSModal {...props}>{children}</RSModal>;
};
