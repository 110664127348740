import { DateTime } from 'luxon';

import {
	accidentServiceDelete,
	accidentServiceGetByAccidentPart,
	accidentServiceGetByBooking,
	accidentServiceGetByVehicle,
	accidentServiceInsert,
	accidentServiceModify,
	accidentServiceSearch,
} from '../../data/services/AccidentService';
import { bookingServiceGetByAccidentPartData } from '../../data/services/BookingService';
import { customerServiceGetByCode } from '../../data/services/CustomerService';
import { linkServiceAddToAccident } from '../../data/services/LinkService';
import { vehicleServiceGetByPlateNumber } from '../../data/services/VehicleService';
import type { IAccident } from '../../models/entities/Accident';
import type { IBooking } from '../../models/entities/Booking';
import type { ICustomer } from '../../models/entities/Customer';
import type {
	IAccidentDeleteParams,
	IAccidentGetByAccidentPartParams,
	IAccidentGetByBookingParams,
	IAccidentGetByVehicleParams,
	IAccidentGetPartDataParams,
	IAccidentInsertParams,
	IAccidentModifyParams,
	IAccidentSearchParams,
} from '../../models/serviceParams/AccidentParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import type { IVehicle } from '../../modules/vehicle/entities/Vehicle';

/**
 * Retrieves accidents by booking.
 * @param {IAccident[]} payload `IAccident[]` The payload for the action.
 * @param {IAccidentGetByBookingParams} IAccidentGetByBookingParams The parameters for retrieving accidents.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of accidents.
 */
export const accidentGetByBooking = createAsyncAction<IAccident[], IAccidentGetByBookingParams>(
	'accident/getByBooking',
	accidentServiceGetByBooking,
);

/**
 * Retrieves accidents associated with a specific vehicle.
 *
 * @param {IAccident[]} payload `IAccident[]` The payload for the action.
 * @param {IAccidentGetByVehicleParams} IAccidentGetByVehicleParams - The parameters for retrieving accidents by vehicle.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of accidents.
 */
export const accidentGetByVehicle = createAsyncAction<IAccident[], IAccidentGetByVehicleParams>(
	'accident/getByVehicle',
	accidentServiceGetByVehicle,
);
/**
 * Retrieves accidents by accident part.
 *
 * @param {IAccident[]} payload `IAccident[]` The payload for the action.
 * @param {IAccidentGetByAccidentPartParams} IAccidentGetByAccidentPartParams - The parameters for retrieving accidents.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of accidents.
 */
export const accidentGetByAccidentPart = createAsyncAction<IAccident[], IAccidentGetByAccidentPartParams>(
	'accident/getByAccidentPart',
	accidentServiceGetByAccidentPart,
);

/**
 * Clears the accident data.
 */
export const accidentClear = createSyncAction('accident/clear');

/**
 * Searches for accidents based on search parameters.
 * @param {IAccident[]} payload `IAccident[]` The payload for the action.
 * @param {IAccidentSearchParams} IAccidentSearchParams The parameters for retrieving accidents.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of accidents.
 */
export const accidentSearch = createAsyncAction<IAccident[], IAccidentSearchParams>(
	'accident/search',
	accidentServiceSearch,
);

/**
 * Inserts a new accident into the fleet.
 * @param {IAccident} payload `IAccident` The accident to be inserted.
 * @param {IAccidentInsertParams} IAccidentInsertParams The parameters for inserting a new accident.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the accident has been inserted.
 */
export const accidentInsert = createAsyncAction<IAccident, IAccidentInsertParams>(
	'accident/insert',
	async (params: IAccidentInsertParams) => {
		const insertResponse = await accidentServiceInsert({ ...params, documents: undefined });

		if (insertResponse.ok && insertResponse.data) {
			if (params.documents) {
				const linkResponse = await linkServiceAddToAccident({
					documentNumber: insertResponse.data.documentNumber,
					files: Array.from(params.documents),
					key: insertResponse.data.key,
					plateNumber: insertResponse.data.plateNumber,
				});

				if (!linkResponse.ok || !linkResponse.data) {
					return new ServiceResponse<IAccident>(false, null, linkResponse.errors);
				}
			}
			return new ServiceResponse<IAccident>(true, insertResponse.data, []);
		}
		return new ServiceResponse<IAccident>(false, null, insertResponse.errors);
	},
);

/**
 * Modifies an existing accident in the fleet.
 * @param {IAccident} payload `IAccident` The accident to be modified.
 * @param {IAccidentModifyParams} IAccidentModifyParams The parameters for modifying an existing accident.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the accident has been modified.
 */
export const accidentModify = createAsyncAction<IAccident, IAccidentModifyParams>(
	'accident/modify',
	async (params: IAccidentModifyParams) => {
		const modifyResponse = await accidentServiceModify({ ...params, documents: undefined });

		if (modifyResponse.ok && modifyResponse.data) {
			if (params.documents) {
				const linkResponse = await linkServiceAddToAccident({
					documentNumber: modifyResponse.data.documentNumber,
					files: Array.from(params.documents),
					key: modifyResponse.data.key,
					plateNumber: modifyResponse.data.plateNumber,
				});

				if (!linkResponse.ok || !linkResponse.data) {
					return new ServiceResponse<IAccident>(false, null, linkResponse.errors);
				}
			}
			return new ServiceResponse<IAccident>(true, modifyResponse.data, []);
		}
		return new ServiceResponse<IAccident>(false, null, modifyResponse.errors);
	},
);

/**
 * Deletes a accident from the fleet.
 * @param {IAccident} payload `IAccident` The accident to be deleted.
 * @param {IAccidentDeleteParams} IAccidentDeleteParams The parameters for deleting a accident.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the accident has been deleted.
 */
export const accidentDelete = createAsyncAction<IAccident, IAccidentDeleteParams>(
	'accident/delete',
	accidentServiceDelete,
);

type AccidentPartDataType = {
	booking: IBooking;
	customer: ICustomer;
	vehicle: IVehicle;
};

export const accidentGetPartData = createAsyncAction<AccidentPartDataType | null, IAccidentGetPartDataParams>(
	'accident/getPartData',
	async (params: IAccidentGetPartDataParams) => {
		const { branches, accidentDateTime, ...model } = params;
		const vehicleResponse = await vehicleServiceGetByPlateNumber({
			plateNumber: model.plateNumber,
		});

		if (vehicleResponse.ok && vehicleResponse.data) {
			const timeZone = branches.find(({ code }) => code === vehicleResponse.data?.currentBranchCode)?.timeZone;
			if (timeZone) {
				const bookingResponse = await bookingServiceGetByAccidentPartData({
					...model,
					accidentDateTime: DateTime.fromJSDate(accidentDateTime).setZone(timeZone).toJSDate(),
				});

				if (bookingResponse.ok && bookingResponse.data) {
					if (!bookingResponse.data.customerCode) {
						return new ServiceResponse<AccidentPartDataType>(false, null, [
							{ message: 'Missing customer in booking.' },
						]);
					}
					const customerResponse = await customerServiceGetByCode({
						customerCode: bookingResponse.data.customerCode,
					});

					if (!customerResponse.ok || !customerResponse.data) {
						return new ServiceResponse<AccidentPartDataType>(false, null, customerResponse.errors);
					}

					return {
						data: {
							booking: bookingResponse.data,
							customer: customerResponse.data,
							vehicle: vehicleResponse.data,
						},
						ok: true,
						errors: [],
					};
				}
				return new ServiceResponse<AccidentPartDataType>(false, null, bookingResponse.errors);
			}
		}
		return new ServiceResponse<AccidentPartDataType>(false, null, vehicleResponse.errors);
	},
);

export const accidentClearBooking = createSyncAction('accident/clearBooking');

export const accidentClearCustomer = createSyncAction('accident/clearCustomer');

export const accidentClearVehicle = createSyncAction('accident/clearVehicle');
