export enum RentRateTypeEndPoints {
	/**
	 * @host `/rentRateType/search/`
	 */
	SEARCH = '/rentRateType/search/',

	/**
	 * @host `/rentRateType/insert/`
	 */
	INSERT = '/rentRateType/insert/',

	/**
	 * @host `/rentRateType/modify/`
	 */
	MODIFY = '/rentRateType/modify/',
}
