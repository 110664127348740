import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Prevision = lazyWithRetry(() => import('~/features/fleet/prevision/pages/Prevision'));
const PrevisionDetail = lazyWithRetry(() => import('~/features/fleet/prevision/pages/PrevisionDetail'));
const PrevisionNew = lazyWithRetry(() => import('~/features/fleet/prevision/pages/PrevisionNew'));
const PrevisionModify = lazyWithRetry(() => import('~/features/fleet/prevision/pages/PrevisionModify'));
const PrevisionDistribute = lazyWithRetry(() => import('~/features/fleet/prevision/pages/PrevisionDistribute'));
const PrevisionImportPreviousRegistration = lazyWithRetry(
	() => import('~/features/fleet/prevision/pages/PrevisionImportPreviousRegistration'),
);

export const previsionRoutes: { [key: number]: IRouteConfig } = {};

previsionRoutes[routesIdMap.FleetPrevision] = {
	component: Prevision,
	exact: true,
	id: routesIdMap.FleetPrevision,
	name: 'Prevision',
	path: '/fleet/prevision',
	permissions: [PermissionsType.FleetPrevision],
	icon: 'forward',
	childrens: [
		routesIdMap.FleetPrevisionDetail,
		routesIdMap.FleetPrevisionModify,
		routesIdMap.FleetPrevisionImportPreviousRegistration,
	],
	menuText: 'Prevision',
};

previsionRoutes[routesIdMap.FleetPrevisionNew] = {
	component: PrevisionNew,
	exact: true,
	id: routesIdMap.FleetPrevisionNew,
	name: 'New prevision',
	path: '/fleet/prevision/new',
	permissions: [PermissionsType.FleetPrevision],
	menuText: 'New prevision',
	icon: 'plus',
};

previsionRoutes[routesIdMap.FleetPrevisionDetail] = {
	component: PrevisionDetail,
	exact: true,
	id: routesIdMap.FleetPrevisionDetail,
	name: 'Prevision detail',
	path: '/fleet/prevision/:code',
	permissions: [PermissionsType.FleetPrevision],
	isChild: true,
	icon: 'forward',
};

previsionRoutes[routesIdMap.FleetPrevisionModify] = {
	component: PrevisionModify,
	exact: true,
	id: routesIdMap.FleetPrevisionModify,
	name: 'Prevision modify',
	path: '/fleet/prevision/modify/:code',
	permissions: [PermissionsType.FleetPrevision],
	isChild: true,
	icon: 'pencil',
};

previsionRoutes[routesIdMap.FleetPrevisionDistribute] = {
	component: PrevisionDistribute,
	exact: true,
	id: routesIdMap.FleetPrevisionDistribute,
	name: 'Prevision distribute',
	path: '/fleet/prevision/:code/distribute',
	permissions: [PermissionsType.FleetPrevision],
	icon: 'code-fork',
};

previsionRoutes[routesIdMap.FleetPrevisionImportPreviousRegistration] = {
	component: PrevisionImportPreviousRegistration,
	exact: true,
	id: routesIdMap.FleetPrevisionImportPreviousRegistration,
	name: 'Prevision import previous registration',
	path: '/fleet/prevision/import/:prevision',
	permissions: [PermissionsType.FleetPrevision],
	isChild: true,
	icon: 'plus',
};
