import { createAction } from '@reduxjs/toolkit';

import {
	rentRateVariationDeactivateService,
	rentRateVariationInsertService,
	rentRateVariationSearchService,
} from '../../data/services/RentRateVariationService';
import type { IRentRateVariation } from '../../models/entities/RentRateVariation';
import type {
	IRentRateVariationDeactivateParams,
	IRentRateVariationInsertParams,
	IRentRateVariationsSearchParams,
} from '../../models/serviceParams/RentParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for rent rate variations based on specified parameters.
 *
 * @param {IRentRateVariation[]} payload - The rent rate variations to be retrieved.
 * @param {IRentRateVariationsSearchParams} params - Parameters for the search of rent rate variations.
 * @returns {Promise<void>} - A promise that resolves to an array of rent rate variations.
 */
export const rentRateVariationsSearch = createAsyncAction<IRentRateVariation[], IRentRateVariationsSearchParams>(
	'rentRateVariations/search',
	rentRateVariationSearchService,
);

/**
 * Inserts new rent rate variations.
 *
 * @param {IRentRateVariation[]} payload - The rent rate variations to be inserted.
 * @param {IRentRateVariationInsertParams} params - Parameters for the insertion of rent rate variations.
 * @returns {Promise<void>} - A promise that resolves after the rent rate variations are inserted.
 */
export const rentRateVariationInsert = createAsyncAction<IRentRateVariation[], IRentRateVariationInsertParams>(
	'rentRateVariation/insert',
	rentRateVariationInsertService,
);

/**
 * Deactivates specific rent rate variations.
 *
 * @param {IRentRateVariation[]} payload - The rent rate variations to be deactivated.
 * @param {IRentRateVariationDeactivateParams} params - Parameters for the deactivation of rent rate variations.
 * @returns {Promise<void>} - A promise that resolves after the rent rate variations are deactivated.
 */
export const rentRateVariationDeactivate = createAsyncAction<IRentRateVariation[], IRentRateVariationDeactivateParams>(
	'rentRateVariation/deactivate',
	rentRateVariationDeactivateService,
);

/**
 * Resets the rent rate variation state.
 */
export const rentRateVariationReset = createAction('rentRateVariation/reset');
