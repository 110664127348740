import type { IPartnerCategory } from '../../models/entities/PartnerCategory';
import type { IPartnerGroup } from '../../models/entities/PartnerGroup';
import type {
	IPartnerGroupGetAllParams,
	IPartnerGroupGetByCodeParams,
	IPartnerGroupInsertParams,
	IPartnerGroupModifyParams,
	IPartnerGroupSearchParams,
} from '../../models/serviceParams/PartnerGroupParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { PartnerGroupEndPoints } from '../endPoints/PartnerGroupEndPoints';

export const partnerGroupServiceGetAll = (
	model: IPartnerGroupGetAllParams,
): Promise<ServiceResponse<IPartnerGroup[]>> => {
	return Api.get<IPartnerCategory[], IPartnerGroupGetAllParams>(PartnerGroupEndPoints.GET_ALL, model);
};

export const partnerGroupServiceGetByCode = (
	model: IPartnerGroupGetByCodeParams,
): Promise<ServiceResponse<IPartnerGroup>> => {
	return Api.get<IPartnerGroup, IPartnerGroupGetByCodeParams>(PartnerGroupEndPoints.GET_BY_CODE, model);
};

export const partnerGroupServiceSearch = (
	model: IPartnerGroupSearchParams,
): Promise<ServiceResponse<IPartnerGroup[]>> => {
	return Api.get<IPartnerGroup[], IPartnerGroupSearchParams>(PartnerGroupEndPoints.SEARCH, model);
};

export const partnerGroupServiceInsert = (
	model: IPartnerGroupInsertParams,
): Promise<ServiceResponse<IPartnerGroup>> => {
	return Api.post<IPartnerGroup, IPartnerGroupInsertParams>(PartnerGroupEndPoints.INSERT, model);
};

export const partnerGroupServiceModify = (
	model: IPartnerGroupModifyParams,
): Promise<ServiceResponse<IPartnerGroup>> => {
	return Api.post<IPartnerGroup, IPartnerGroupModifyParams>(PartnerGroupEndPoints.MODIFY, model);
};
