import { VehicleEndPoints } from './VehicleEndPoints';
import type { IVehicleSearchParams } from '../../../models/serviceParams/VehicleParams';
import { Api } from '../../../modules/shared/api';
import type { IServiceBaseParams } from '../../shared/types/ServiceBaseParams';
import type { IVehicle } from '../entities/Vehicle';
import type { IVehicleTelemetry } from '../entities/VehicleConnectedTelemetry';

/*
 * Connected vehicles search
 * =================================================================================================
 */

export type IConnectedVehicleSearchParams = IVehicleSearchParams;

/**
 * Searches for connected vehicles based on the provided search parameters.
 *
 * @param model - The search parameters for the connected vehicles.
 * @returns A promise that resolves to an array of vehicles that match the search criteria.
 */
export const vehicleServiceConnectedVehicleSearch = (model: IConnectedVehicleSearchParams) => {
	return Api.get<IVehicle[], IConnectedVehicleSearchParams>(VehicleEndPoints.SEARCH, model);
};

/*
 * Vehicle telemetry
 * =================================================================================================
 */

/**
 * Represents the parameters for getting telemetry data of a vehicle.
 */
export interface IVehicleGetTelemetryParams extends IServiceBaseParams {
	plateNumber: string;
}

/**
 * Retrieves telemetry data for a vehicle.
 * @param model - The parameters for retrieving telemetry.
 * @returns A promise that resolves to the telemetry data of the vehicle.
 */
export const vehicleServiceGetTelemetry = (model: IVehicleGetTelemetryParams) => {
	return Api.get<IVehicleTelemetry, IVehicleGetTelemetryParams>(VehicleEndPoints.CHANGE_GET_TELEMETRY, model);
};

/*
 * Enroll
 * =================================================================================================
 */

/**
 * Represents the parameters for enrolling vehicles.
 */
export interface IVehicleEnrollParams extends IServiceBaseParams {
	plateNumbers: string[];
	sKProvider: string;
	qrCode?: string;
	profileId?: string;
}

/**
 * Enrolls vehicles.
 * @param IVehicleEnrollParams Vehicle enroll parameters
 * @returns A promise that resolves to an array of vehicles that match the search criteria.
 */
export const vehicleServiceEnroll = (model: IVehicleEnrollParams) => {
	return Api.post<IVehicle[], IVehicleEnrollParams>(VehicleEndPoints.ENROLL, model);
};

/*
 * Un Enroll
 * =================================================================================================
 */

/**
 * Represents the parameters for un-enrolling vehicles.
 */
export interface IVehicleUnEnrollParams extends IServiceBaseParams {
	plateNumbers: string[];
}

/**
 * Un-enrolls vehicles.
 * @param IVehicleUnEnrollParams Vehicle un-enroll parameters
 * @returns A promise that resolves to an array of vehicles that match the search criteria.
 */
export const vehicleServiceUnEnroll = (model: IVehicleUnEnrollParams) => {
	return Api.post<IVehicle[], IVehicleUnEnrollParams>(VehicleEndPoints.UN_ENROLL, model);
};
