import type { ISpending } from '../../models/entities/Spending';
import type {
	ISpendingAddParams,
	ISpendingDeleteParams,
	ISpendingGetByCashLineParams,
	ISpendingGetByCodeParams,
} from '../../models/serviceParams/SpendingParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { SpendingEndPoints } from '../endPoints/SpendingEndPoints';

export const spendingServiceAdd = (model: ISpendingAddParams): Promise<ServiceResponse<ISpending>> => {
	return Api.post<ISpending, ISpendingAddParams>(SpendingEndPoints.ADD, model);
};

/**
 * Delete spending by number
 *  {string} number Spending number
 * @returns {Promise} `void`
 */
export const spendingServiceDelete = (model: ISpendingDeleteParams): Promise<ServiceResponse<ISpending>> => {
	return Api.post<ISpending, ISpendingDeleteParams>(SpendingEndPoints.DELETE, model);
};

/**
 * Get spending by code
 *  {string} number Spening number
 * @return {Promise} `Array<SpendingPropType>`
 */
export const spendingServiceGetByCode = (model: ISpendingGetByCodeParams): Promise<ServiceResponse<ISpending>> => {
	return Api.get<ISpending, ISpendingGetByCodeParams>(SpendingEndPoints.GET_BY_CODE, model);
};

/**
 * Get spending by cash line number
 *  {string} number Cash line number
 * @return {Promise} `Array<SpendingPropType>`
 */
export const spendingServiceGetByCashLine = (
	model: ISpendingGetByCashLineParams,
): Promise<ServiceResponse<ISpending[]>> => {
	return Api.get<ISpending[], ISpendingGetByCashLineParams>(SpendingEndPoints.GET_BY_CASH_LINE, model);
};
