import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import type { IRevisionType } from '@crac/core/models/entities/RevisionType';
import {
	revisionTypeClear,
	revisionTypeDeactivate,
	revisionTypeGetAll,
	revisionTypeInsert,
	revisionTypeModify,
	revisionTypeReactivate,
	revisionTypeSearch,
} from '@crac/core/redux/actions/WorkshopRevisionTypeActions';
import type { IRevisionTypeReducerState } from '@crac/core/redux-store/reducersState/workshop/RevisionTypeReducerState';

const initialState: IRevisionTypeReducerState = {
	revisionTypeInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	revisionTypes: [],
	revisionTypeSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	revisionTypeModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	revisionTypeDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	revisionTypeReactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const revisionTypeSlice = createSlice({
	name: 'revisionTypeSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR SEARCH
		builder.addCase(revisionTypeClear, (state) => {
			return {
				...state,
				revisionTypes: [],
			};
		});
		// INSERT
		builder
			.addCase(revisionTypeInsert.pending, (state) => {
				return {
					...state,
					revisionTypeInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(revisionTypeInsert.fulfilled, (state, action) => {
				return {
					...state,
					revisionTypeInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					revisionTypes: [...state.revisionTypes, action.payload],
				};
			})
			.addCase(revisionTypeInsert.rejected, (state, action) => {
				return {
					...state,
					revisionTypeInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(revisionTypeModify.pending, (state) => {
				return {
					...state,
					revisionTypeModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(revisionTypeModify.fulfilled, (state, action) => {
				const { code } = action.payload as IRevisionType;
				const revisionTypes = state.revisionTypes?.map((revisionType) =>
					revisionType.code === code ? { ...action.payload } : revisionType,
				);
				return {
					...state,
					revisionTypes,
					revisionTypeModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(revisionTypeModify.rejected, (state, action) => {
				return {
					...state,
					revisionTypeModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DEACTIVATE
		builder
			.addCase(revisionTypeDeactivate.pending, (state) => {
				return {
					...state,
					revisionTypeDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(revisionTypeDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					revisionTypeDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					revisionTypes: state.revisionTypes.map((item) =>
						item.code === action.payload.code ? action.payload : item,
					),
				};
			})
			.addCase(revisionTypeDeactivate.rejected, (state, action) => {
				return {
					...state,
					revisionTypeDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// REACTIVATE
		builder
			.addCase(revisionTypeReactivate.pending, (state) => {
				return {
					...state,
					revisionTypeReactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(revisionTypeReactivate.fulfilled, (state, action) => {
				return {
					...state,
					revisionTypeReactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					revisionTypes: state.revisionTypes.map((item) =>
						item.code === action.payload.code ? action.payload : item,
					),
				};
			})
			.addCase(revisionTypeReactivate.rejected, (state, action) => {
				return {
					...state,
					revisionTypeReactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * SEARCH
		 * GET ALL
		 */
		builder
			.addMatcher(isAnyOf(revisionTypeGetAll.pending, revisionTypeSearch.pending), (state) => {
				return {
					...state,
					revisionTypeSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addMatcher(isAnyOf(revisionTypeGetAll.fulfilled, revisionTypeSearch.fulfilled), (state, action) => {
				return {
					...state,
					revisionTypeSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					revisionTypes: action.payload,
				};
			})
			.addMatcher(isAnyOf(revisionTypeGetAll.rejected, revisionTypeSearch.rejected), (state, action) => {
				return {
					...state,
					revisionTypeSearchRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
	},
});

export const revisionTypeReducer = revisionTypeSlice.reducer;
