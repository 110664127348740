/* eslint-disable react/display-name */
import { useMemo } from 'react';

import { translations } from './StaticTexts';

export type LocaleAcceptType = 'es' | 'en';

export type TextsTranlationType = (typeof translations)['en'];

/**
 * Get the texts for the current locale
 * @param locale current locale
 * @returns texts for the current locale
 */
export const useTraslatorTexts = (locale = 'en'): { texts: TextsTranlationType } => ({
	texts: useMemo(() => translations[locale as LocaleAcceptType], [locale]),
});

export { translations };
/**
 * Get the texts for the current locale
 * @param locale current cocale
 * @returns texts for the current locale
 */
export const getStaticTexts = (locale: string): TextsTranlationType => translations[locale as LocaleAcceptType];

/**
 * Translate a text key
 * @param locale current locale
 * @param key text key
 * @returns translated text
 */
export const tranlateEnumKey = (locale: string, key: string): string => {
	const texts = translations[locale as LocaleAcceptType];
	const translateText = texts[key as keyof typeof texts];
	return translateText ? translateText : key;
};
