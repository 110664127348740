import { paymentMethodServiceGetAll } from '../../data/services/PaymentMethodService';
import type { IPaymentMethod } from '../../models/entities/PaymentMethod';
import type { IPaymentMethodGetAllParams } from '../../models/serviceParams/PaymentMethodParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Get all payment methods.
 *
 * @param {IPaymentMethod[]} payload - The payment methods to be returned.
 * @param {IPaymentMethodGetAllParams} params - Parameters detailing the payment methods to be returned.
 * @returns {Promise<void>} - A promise that resolves to an array of payment methods.
 */
export const paymentMethodGetAll = createAsyncAction<IPaymentMethod[], IPaymentMethodGetAllParams>(
	'paymentMethod/getAll',
	paymentMethodServiceGetAll,
);
