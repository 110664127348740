/* eslint-disable react/no-unused-prop-types */
/* eslint-disable @typescript-eslint/await-thenable */
import { Component } from 'react';

import type { Action, Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';
import queryString from 'query-string';
import { connect } from 'react-redux';
import type { NavigateFunction } from 'react-router-dom';

import type { ICustomer } from '@crac/core/models/entities/Customer';
import { isProduction } from '@crac/core/modules/shared/api/ApiEndPoint';
import { connectedCallStatusAdd, incomingCallStatusAdd } from '@crac/core/redux/actions/CallLogActions';
import { customerClear, customerClearSearch, customerSearch } from '@crac/core/redux/actions/CustomerActions';

import { routesIdMap } from '~/config/routesIdMap';
import { routes } from '~/features/routes';
import { ZammadAPI } from '~/features/shared/components/layout/ringcentralIntegration/ZammadAPI';
import type { AppStateType } from '~/redux';

import { formatPhoneNumber, isExternalNumber } from './helpers';
import type { RingCentralCallData, RingCentralEvent } from './types';
import { RingCentralEventType } from './types';

type componentPropsType = {
	history: NavigateFunction;
};

const actions = {
	connectedCallStatusAdd,
	customerClear,
	customerClearSearch,
	customerSearch,
	incomingCallStatusAdd,
};

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapDispatchToProps = (dispatch: Dispatch<Action>) =>
	bindActionCreators(
		{
			connectedCallStatusAdd: actions.connectedCallStatusAdd,
			customerClear: actions.customerClear,
			customerClearSearch: actions.customerClearSearch,
			customerSearch: actions.customerSearch,
			incomingCallStatusAdd: actions.incomingCallStatusAdd,
		},
		dispatch,
	);

// eslint-disable-next-line @typescript-eslint/explicit-function-return-type
const mapStateToProps = (state: AppStateType) => ({
	countries: state.Country.countries,
	customers: state.Customer.Search.customers,
	customersRequest: state.Customer.Search.customersRequest,
});

type mapStateToPropsType = ReturnType<typeof mapStateToProps>;
type mapDispatchToPropsType = ReturnType<typeof mapDispatchToProps>;
export type RingCentralProps = componentPropsType & mapStateToPropsType & mapDispatchToPropsType;

interface RingCentralState {
	incomingCalls: RingCentralCallData[];
	connectedCall: RingCentralCallData | null;
	isActiveCall: boolean;
}
class RingCentral extends Component<RingCentralProps, RingCentralState> {
	constructor(props: RingCentralProps) {
		super(props);

		this.state = {
			connectedCall: null,
			incomingCalls: [],
			isActiveCall: false,
		};

		this.customerDetailRoute = routes[routesIdMap.CustomersDetailOverviews].path;
		this.customerSearchRoute = routes[routesIdMap.Customers].path;
	}

	componentDidMount(): void {
		this.handleAddConfigScript();
		window.addEventListener('message', this.handleCallEvents);
	}

	componentWillUnmount(): void {
		window.removeEventListener('message', this.handleCallEvents, false);
	}

	customerDetailRoute: string;

	customerSearchRoute: string;

	handleUpdateIncomingCalls = (call: RingCentralCallData) => {
		const { incomingCalls } = this.state;
		this.setState({ incomingCalls: [...incomingCalls, call] });
	};

	handleUpdateConnectedCall = (connectedCall: RingCentralCallData | null) => {
		this.setState({ connectedCall });
	};

	handleEndCall = async (): Promise<void> => {
		const { incomingCallStatusAdd, connectedCallStatusAdd } = this.props;

		const { incomingCalls, connectedCall } = this.state;

		if (connectedCall) {
			const incomingStatusCallPromises = incomingCalls.map((incomingCall) =>
				incomingCallStatusAdd({
					...incomingCall,
					callId: connectedCall.callId,
					callInfo: incomingCall.callInfo ? JSON.stringify(incomingCall.callInfo) : undefined,
					direction: incomingCall.direction,
					from: incomingCall.from ? JSON.stringify(incomingCall.from) : undefined,
					fromPhoneNumber: incomingCall.from ? incomingCall.from.phoneNumber : undefined,
					id: undefined,
					partyId: incomingCall.partyId,
					sessionId: incomingCall.sessionId,
					startTime: incomingCall?.startTime ? new Date(incomingCall.startTime) : undefined,
					to: incomingCall.to ? JSON.stringify(incomingCall.to) : undefined,
					toPhoneNumber: incomingCall.to ? incomingCall.to.phoneNumber : undefined,
				}),
			);

			const connectedCallPromise = connectedCallStatusAdd({
				...connectedCall,
				callId: connectedCall?.callId || undefined,
				callStatus: connectedCall?.callStatus,
				creationTime: connectedCall.creationTime ? new Date(connectedCall.creationTime) : undefined,
				direction: connectedCall?.direction,
				endTime: connectedCall.endTime ? new Date(connectedCall.endTime) : undefined,
				from: connectedCall.from ? JSON.stringify(connectedCall.from) : undefined,
				id: undefined,
				lastActiveTime: connectedCall.lastActiveTime ? new Date(connectedCall.lastActiveTime) : undefined,
				partyData: connectedCall.partyData ? JSON.stringify(connectedCall.partyData) : undefined,
				partyId: connectedCall.partyData ? connectedCall.partyData?.partyId : undefined,
				sessionId: connectedCall.partyData ? connectedCall.partyData?.sessionId : undefined,
				startTime: connectedCall.startTime ? new Date(connectedCall.startTime) : undefined,
				to: connectedCall.to ? JSON.stringify(connectedCall.to) : undefined,
			});

			await Promise.all([incomingStatusCallPromises, connectedCallPromise]);
		}

		this.setState({
			connectedCall: null,
			incomingCalls: [],
			isActiveCall: false,
		});
	};

	// eslint-disable-next-line class-methods-use-this
	handleAddConfigScript = () => {
		let APP_KEY = 'u7tdHSRtRESZXDkNlyXakA';
		let APP_SECRET = 'MKUAzvyqQFSwnhI7BZl9gQPcM8Y018S6KuKnpe82fkXA';
		let APP_SERVER = 'https://platform.devtest.ringcentral.com';
		const RC_SCRIPT_SRC = 'https://apps.ringcentral.com/integration/ringcentral-embeddable/1.9.1/adapter.js';
		const RING_CENTRAL_SDK = RC_SCRIPT_SRC;

		if (isProduction) {
			APP_KEY = 'qWW4mkNjTUu3lC10iirp_g';
			APP_SECRET = '5B3yZZZjTSWw0YpSwzSVYg6dsyVZ4-QiKPaPKJ4JbA0Q';
			APP_SERVER = 'https://platform.ringcentral.com';
		}

		const widgetConfig = {
			appServer: APP_SERVER,
			clientId: APP_KEY,
			clientSecret: APP_SECRET,
			defaultCallWith: 'browser',
			disableConferenceCall: true,
			disconnectInactiveWebphone: 1,
			enableFromNumberSetting: 1,
		};

		const rcs = document.createElement('script');
		rcs.src = `${RING_CENTRAL_SDK}?${queryString.stringify(widgetConfig)}`;
		const [rcs0] = Array.from(document.getElementsByTagName('script'));

		rcs0?.parentNode?.insertBefore(rcs, rcs0);
	};

	handleCallEvents = (event: RingCentralEvent) => {
		const { data } = event;

		if (data && data.call) {
			this.setCallLog(data);

			if (data.type === RingCentralEventType.RC_ACTIVE_CALL_NOTIFY) {
				this.handleCheckIsCustomer(data.call);
			}
		}
	};

	setCallLog = (data: any): void => {
		const { call, type } = data;
		const { incomingCalls, connectedCall } = this.state;

		if (call && call.direction === 'Inbound') {
			const canAddIncomingStatus =
				!incomingCalls.some((incomingCall) => incomingCall.telephonyStatus === call.telephonyStatus) &&
				connectedCall;
			const canAddConnectedLog = JSON.stringify(call) !== JSON.stringify(connectedCall);
			switch (type) {
				case RingCentralEventType.RC_CALL_RING_NOTIFY:
					if (!incomingCalls.some((incomingCall) => incomingCall.telephonyStatus === 'Ringing')) {
						this.handleUpdateConnectedCall(call);
					}
					break;
				case RingCentralEventType.RC_CALL_START_NOTIFY:
				case RingCentralEventType.RC_CALL_MUTE_NOTIFY:
				case RingCentralEventType.RC_CALL_HOLD_NOTIFY:
				case RingCentralEventType.RC_CALL_RESUME_NOTIFY:
					if (canAddConnectedLog) {
						this.handleUpdateConnectedCall(call);
					}
					break;
				case RingCentralEventType.RC_CALL_END_NOTIFY:
					if (canAddConnectedLog) {
						this.handleUpdateConnectedCall(call);
					}
					setTimeout(async () => {
						await this.handleEndCall();
					}, 3000);
					break;
				case RingCentralEventType.RC_ACTIVE_CALL_NOTIFY:
					if (call && !call.callId) {
						if (canAddIncomingStatus) {
							this.handleUpdateIncomingCalls(call);
						}
					}
					break;
				default:
					break;
			}
		}
	};

	handleCheckIsCustomer = async (callData: RingCentralCallData): Promise<void> => {
		const { isActiveCall } = this.state;
		const { customerClearSearch, customerSearch, customersRequest, countries, history, customerClear } = this.props;
		const { telephonyStatus, direction, from } = callData;

		if (
			telephonyStatus === 'CallConnected' &&
			direction === 'Inbound' &&
			isExternalNumber(callData) &&
			!isActiveCall
		) {
			customerClearSearch();
			customerClear();
			this.setState({ isActiveCall: true });
			const numberToSearch = formatPhoneNumber(from.phoneNumber, countries);

			if (!customersRequest.inProgress) {
				await customerSearch({
					loader: {
						active: false,
					},
					phoneNumber: numberToSearch,
				});

				const { customers } = this.props;

				const ticket = await this.handleCreateZammaTicket(callData, customers);
				const ticketParams = ticket && ticket !== undefined ? `ticketId=${ticket.id}` : null;
				if (customers && customers.length === 1) {
					const [customer] = customers;
					const route = `${this.customerDetailRoute.replace(/:id/gu, customer.code)}${
						ticketParams ? `?${ticketParams}` : ''
					}`;
					history(route);
				} else {
					history(
						`${this.customerSearchRoute}?phoneNumber=${numberToSearch}${
							ticketParams ? `&${ticketParams}` : ''
						}`,
					);
				}
			}
		}
	};

	handleCreateZammaTicket = async (callData: RingCentralCallData, customers: ICustomer[]): Promise<any> => {
		const { connectedCall } = this.state;
		let ticket = null;
		if (callData.telephonyStatus === 'CallConnected' && connectedCall) {
			ticket = await ZammadAPI.manageTickets(callData, customers);

			if (ticket) {
				window.open(`https://service.centauro.net/#ticket/zoom/${ticket.id}`);
			}
		}

		return ticket;
	};

	// eslint-disable-next-line class-methods-use-this
	render(): null {
		return null;
	}
}

export const RingCentralIntegration = connect<
	mapStateToPropsType,
	mapDispatchToPropsType,
	componentPropsType,
	AppStateType
>(
	mapStateToProps,
	mapDispatchToProps,
)(RingCentral);
