import {
	bookingRegularizationServiceGetByBooking,
	bookingRegularizationServiceGetByCode,
	bookingRegularizationServiceGetPendingByUser,
	bookingRegularizationServiceInsert,
	bookingRegularizationServiceUpdate,
} from '../../data/services/BookingRegularizationService';
import type { IRegularization } from '../../models/entities/Regularization';
import type {
	IRegularizationGetByBookingParams,
	IRegularizationGetByCodeParams,
	IRegularizationInsertParams,
	IRegularizationUpdateParams,
} from '../../models/serviceParams/RegularizationParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Retrieves a specific regularization by its code.
 *
 * @param {IRegularization} payload - The regularization to be retrieved.
 * @param {IRegularizationGetByCodeParams} params - Parameters for identifying the regularization by code.
 * @returns {Promise<void>} - A promise that resolves to the specified regularization.
 */
export const bookingRegularizationGetByCode = createAsyncAction<IRegularization, IRegularizationGetByCodeParams>(
	'bookingRegularization/getByCode',
	bookingRegularizationServiceGetByCode,
);

/**
 * Retrieves regularizations associated with a specific booking.
 *
 * @param {IRegularization[]} payload - The regularizations to be retrieved.
 * @param {IRegularizationGetByBookingParams} params - Parameters to identify regularizations associated with a booking.
 * @returns {Promise<void>} - A promise that resolves to an array of regularizations.
 */
export const bookingRegularizationGetByBooking = createAsyncAction<
	IRegularization[],
	IRegularizationGetByBookingParams
>('bookingRegularization/getByBooking', bookingRegularizationServiceGetByBooking);

/**
 * Retrieves pending regularizations for a user.
 *
 * @param {IRegularization[]} payload - The pending regularizations to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters for identifying the user.
 * @returns {Promise<void>} - A promise that resolves to an array of pending regularizations.
 */
export const bookingRegularizationGetPendingByUser = createAsyncAction<IRegularization[], IServiceBaseParams>(
	'bookingRegularization/getPendingByUser',
	bookingRegularizationServiceGetPendingByUser,
);

/**
 * Inserts a new regularization.
 *
 * @param {IRegularization} payload - The regularization to be inserted.
 * @param {IRegularizationInsertParams} params - Parameters for inserting the regularization.
 * @returns {Promise<void>} - A promise that resolves after the regularization is inserted.
 */
export const bookingRegularizationInsert = createAsyncAction<IRegularization, IRegularizationInsertParams>(
	'bookingRegularization/insert',
	bookingRegularizationServiceInsert,
);

/**
 * Updates an existing regularization.
 *
 * @param {IRegularization} payload - The regularization to be updated.
 * @param {IRegularizationUpdateParams} params - Parameters for updating the regularization.
 * @returns {Promise<void>} - A promise that resolves after the regularization is updated.
 */
export const bookingRegularizationUpdate = createAsyncAction<IRegularization, IRegularizationUpdateParams>(
	'bookingRegularization/update',
	bookingRegularizationServiceUpdate,
);
