import { createAction } from '@reduxjs/toolkit';

import {
	preauthorizationRateServiceDeactivate,
	preauthorizationRateServiceInsert,
	preauthorizationRateServiceSearch,
} from '../../data/services/PreauthorizationRateService';
import type { IPreauthorizationRate } from '../../models/entities/PreauthorizationRate';
import type {
	IPreauthorizationRateDeactivateParams,
	IPreauthorizationRateInsertParams,
	IPreauthorizationRateSearchParams,
} from '../../models/serviceParams/PreauthorizationRateParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for preauthorization rates based on specified parameters.
 *
 * @param {IPreauthorizationRate[]} payload - The preauthorization rates to be retrieved.
 * @param {IPreauthorizationRateSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of preauthorization rates.
 */
export const preauthorizationRateSearch = createAsyncAction<IPreauthorizationRate[], IPreauthorizationRateSearchParams>(
	'preauthorizationRate/search',
	preauthorizationRateServiceSearch,
);

/**
 * Inserts new preauthorization rates.
 *
 * @param {IPreauthorizationRate[]} payload - The preauthorization rates to be inserted.
 * @param {IPreauthorizationRateInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the preauthorization rates are inserted.
 */
export const preauthorizationRateInsert = createAsyncAction<IPreauthorizationRate[], IPreauthorizationRateInsertParams>(
	'preauthorizationRate/insert',
	preauthorizationRateServiceInsert,
);

/**
 * Deactivates specific preauthorization rates.
 *
 * @param {IPreauthorizationRate[]} payload - The preauthorization rates to be deactivated.
 * @param {IPreauthorizationRateDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the preauthorization rates are deactivated.
 */
export const preauthorizationRateDeactivate = createAsyncAction<
	IPreauthorizationRate[],
	IPreauthorizationRateDeactivateParams
>('preauthorizationRate/deactivate', preauthorizationRateServiceDeactivate);

/**
 * Clears the preauthorization rate state.
 */
export const preauthorizationRateClear = createAction('preauthorizationRate/clear');
