import type { IRentRate } from '../../models/entities/RentRate';
import type { IRentRateSaveParams, IRentRatesSearchParams } from '../../models/serviceParams/RentParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { RentRateEndPoints } from '../endPoints/RentRateEndPoints';

// RentRate
export const rentRateSearchService = (model: IRentRatesSearchParams): Promise<ServiceResponse<IRentRate[]>> => {
	return Api.get<IRentRate[], IRentRatesSearchParams>(RentRateEndPoints.SEARCH, model);
};
export const rentRateSaveService = (model: IRentRateSaveParams): Promise<ServiceResponse<IRentRate[]>> => {
	return Api.post<IRentRate[], IRentRateSaveParams>(RentRateEndPoints.INSERT, model);
};
