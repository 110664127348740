import type { IBudget } from '../../models/entities/Budget';
import type { ILink } from '../../models/entities/Link';
import type {
	ILinkAddToAccidentParams,
	ILinkAddToInsuranceParams,
	ILinkAddToVehicleParams,
	ILinkDownloadDocumentation,
	ILinkGetByAccidentPartParams,
	ILinkGetByBudgetParams,
	ILinkGetByFineNumberParams,
	ILinkGetByFranchiseLineParams,
	ILinkGetByIdParams,
	ILinkGetByInsuranceParams,
	ILinkGetByInvoiceParams,
	ILinkGetBySpendingLineParams,
	ILinkGetByVehicleLineParams,
	ILinkGetByVehicleParams,
	ILinkGetFineNotificationParams,
} from '../../models/serviceParams/LinkParams';
import { BudgetState } from '../../models/types/BudgetState';
import { Api } from '../../modules/shared/api';
import { API_ENDPOINT } from '../../modules/shared/api/ApiEndPoint';
import { getFormData, getToken } from '../../modules/shared/api/helpers';
import { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { LinkEndPoints } from '../endPoints/LinkEndPoints';

/**
 * Get Link By ID
 *  {int} id Link ID
 * @return {Promise} `LinkPropType` Return LinkPropType
 */
export const linkServiceGetById = (model: ILinkGetByIdParams) => {
	return Api.get<ILink, ILinkGetByIdParams>(LinkEndPoints.GET_BY_ID, model);
};

/**
 * Get Links by accident part
 *  {string} AccidentPart Link
 * @return {Promise} `Array<LinkPropType>` Return List LinkPropType
 */
export const linkServiceGetByAccidentPart = (
	model: ILinkGetByAccidentPartParams,
): Promise<ServiceResponse<ILink[]>> => {
	return Api.get<ILink[], ILinkGetByAccidentPartParams>(LinkEndPoints.GET_BY_ACCIDENT_PART, model);
};

/**
 * Get links by franchise line ID
 *  {string} franchiseLineNumber Franchise line
 *  {string} plateNumber vehicle plate number
 *  {string} documentNumber document number
 */
export const linkServiceGetByFranchiseLine = (
	model: ILinkGetByFranchiseLineParams,
): Promise<ServiceResponse<ILink[]>> => {
	return Api.get<ILink[], ILinkGetByFranchiseLineParams>(LinkEndPoints.GET_BY_FRANCHISE_LINE, model);
};

/**
 * Get Links by vehicle line number
 *  {int} vehicleLineNumber Link line ID
 * @return {Promise} `Array<LinkPropType>` Return List LinkPropType
 */
export const linkServiceGetByVehicleLine = (model: ILinkGetByVehicleLineParams): Promise<ServiceResponse<ILink[]>> => {
	return Api.get<ILink[], ILinkGetByVehicleLineParams>(LinkEndPoints.GET_BY_VEHICLE_LINE, model);
};

/**
 * Get Links by spending number
 *  {int} number Link line ID
 * @return {Promise} `Array<LinkPropType>` Return List LinkPropType
 */
export const linkServiceGetBySpendingLine = (
	model: ILinkGetBySpendingLineParams,
): Promise<ServiceResponse<ILink[]>> => {
	return Api.get<ILink[], ILinkGetBySpendingLineParams>(LinkEndPoints.GET_BY_SPENDING_LINE, model);
};

/**
 * Get Links by spending number
 * @param {int} number Link ID
 * @return {Promise} `Array<LinkPropType>` Return List LinkPropType
 */
export const linkServiceGetByFineNumber = (model: ILinkGetByFineNumberParams): Promise<ServiceResponse<ILink[]>> => {
	return Api.get<ILink[], ILinkGetByFineNumberParams>(LinkEndPoints.GET_BY_FINE_NUMBER, model);
};

/**
 * Get fine notifications
 * @param {string} fineNumber fine.code
 * @return {Promise} `Array<LinkPropType>` Return List LinkPropType
 */
export const linkServiceGetFineNotification = (
	model: ILinkGetFineNotificationParams,
): Promise<ServiceResponse<ILink[]>> => {
	return Api.get<ILink[], ILinkGetFineNotificationParams>(LinkEndPoints.GET_FINE_NOTIFICATION, model);
};

/**
 * Get Links by vehicle
 *  {string} plateNumber Link
 * @return {Promise} `Array<LinkPropType>` Return List LinkPropType
 */
export const linkServiceGetByVehicle = (model: ILinkGetByVehicleParams): Promise<ServiceResponse<ILink[]>> => {
	return Api.get<ILink[], ILinkGetByVehicleParams>(LinkEndPoints.GET_BY_VEHICLE, model);
};

/**
 * Get Links by insurance
 *  {string} plateNumber Link
 * @return {Promise} `Array<LinkPropType>` Return List LinkPropType
 */
export const linkServiceGetByInsurance = (model: ILinkGetByInsuranceParams): Promise<ServiceResponse<ILink[]>> => {
	return Api.get<ILink[], ILinkGetByInsuranceParams>(LinkEndPoints.GET_BY_INSURANCE, model);
};

/**
 * Get Links by budget
 */
export const linkServiceGetByBudget = (model: ILinkGetByBudgetParams): Promise<ServiceResponse<ILink>> => {
	return Api.get<ILink, ILinkGetByBudgetParams>(LinkEndPoints.GET_BY_BUDGET, model);
};

/**
 * Get Links by invoice
 */
export const linkServiceGetByInvoice = (model: ILinkGetByInvoiceParams): Promise<ServiceResponse<ILink[]>> => {
	return Api.get<ILink[], ILinkGetByInvoiceParams>(LinkEndPoints.GET_BY_INVOICE, model);
};

/**
 * Add links to insurance
 */
export const linkServiceAddToInsurance = (model: ILinkAddToInsuranceParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ILinkAddToInsuranceParams>(LinkEndPoints.ADD_TO_INSURANCE, model);
};

/**
 * Adds links to an accident
 */
export const linkServiceAddToAccident = (model: ILinkAddToAccidentParams): Promise<ServiceResponse<ILink[]>> => {
	return Api.post<ILink[], ILinkAddToAccidentParams>(LinkEndPoints.ADD_TO_ACCIDENT, model);
};

/**
 * Adds links to a vehicle
 */
export const linkServiceAddToVehicle = (model: ILinkAddToVehicleParams): Promise<ServiceResponse<ILink[]>> => {
	return Api.post<ILink[], ILinkAddToVehicleParams>(LinkEndPoints.ADD_TO_VEHICLE, model);
};

export const linkServiceGetByBudgets = async (budgets: IBudget[]): Promise<ServiceResponse<ILink[]>> => {
	if (budgets.length === 0) {
		return {
			errors: [],
			ok: true,
			data: [],
		};
	}

	const filteredBudgets = budgets.filter(({ state }) => state !== BudgetState.Rejected);
	const tasks: any[] = [];

	filteredBudgets.forEach(({ id }) => {
		tasks.push(linkServiceGetByBudget({ budgetId: id }));
	});

	const requestResponses: ServiceResponse<ILink>[] = await Promise.all(tasks);

	const payload: ILink[] = [];
	requestResponses.forEach((response) => {
		if (response.ok && response.data && response.data) {
			payload.push(response.data);
		}
	});

	return new ServiceResponse<ILink[]>(true, payload, []);
};

/**
 * Downloads vehicles documentation
 */
export const linkServiceDownloadDocumentation = async (
	model: ILinkDownloadDocumentation,
): Promise<ServiceResponse<null>> => {
	let errorMessage = 'Error downloading documentation.';

	const { plateNumbers } = model;
	const token = model.token ?? getToken();
	const headers = new Headers();
	headers.append('Content-Language', 'en-GB');
	headers.append('Authorization', `Bearer ${token}`);

	const requestConfig: RequestInit = {
		body: getFormData({ plateNumbers }),
		headers,
		method: 'POST',
	};

	if (!plateNumbers.length) {
		return {
			ok: false,
			errors: [{ message: 'At least one plate number must be informed.' }],
		};
	}

	try {
		const request = await fetch(`${API_ENDPOINT}${LinkEndPoints.DOWNLOAD_DOCUMENTATION}`, requestConfig);

		if (!request.ok) {
			const jsonResponse = (await request.json()) as ServiceResponse<null>;
			return {
				ok: false,
				errors: jsonResponse.errors || [{ message: errorMessage }],
			};
		}

		const response = await request.blob();

		const link = document.createElement('a');

		link.href = window.URL.createObjectURL(response);
		link.download = 'documentation';

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		return { ok: true, errors: [] };
	} catch (error) {
		if (error instanceof Error) {
			const { message } = error;
			errorMessage = message;
		}

		return {
			ok: false,
			errors: [{ message: errorMessage }],
		};
	}
};
