export enum DiscountCouponEndPoint {
	/**
	 * Search discount coupons
	 * @host `/discountCoupon/search/`
	 */
	SEARCH = '/discountCoupon/search',

	/**
	 * Insert discount coupon
	 * @host `/discountCoupon/insert/`
	 */
	INSERT = '/discountCoupon/insert',

	/**
	 * Deactivate discount coupon
	 * @host `/discountCoupon/deactivate/`
	 */
	DEACTIVATE = '/discountCoupon/deactivate/',
}
