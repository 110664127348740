import { createAction } from '@reduxjs/toolkit';

import {
	provinceServiceGetByCountry,
	provinceServiceGetByCountryAndPostCode,
} from '../../data/services/ProvinceService';
import type { IProvince } from '../../models/entities/Province';
import type {
	IProvinceGetByCountryAndPostCodeParams,
	IProvinceGetByCountryParams,
} from '../../models/serviceParams/ProvinceParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves provinces based on a specific country.
 *
 * @param {IProvince[]} payload - The provinces to be retrieved.
 * @param {IProvinceGetByCountryParams} params - Parameters to identify provinces in a specific country.
 * @returns {Promise<void>} - A promise that resolves to an array of provinces for the specified country.
 */
export const provinceGetByCountry = createAsyncAction<IProvince[], IProvinceGetByCountryParams>(
	'province/getByCountry',
	provinceServiceGetByCountry,
);

/**
 * Retrieves provinces based on a specific country and postal code.
 *
 * @param {IProvince[]} payload - The provinces to be retrieved.
 * @param {IProvinceGetByCountryAndPostCodeParams} params - Parameters to identify provinces in a specific country and postal code area.
 * @returns {Promise<void>} - A promise that resolves to an array of provinces for the specified country and postal code.
 */
export const provinceGetByCountryAndPostCode = createAsyncAction<IProvince[], IProvinceGetByCountryAndPostCodeParams>(
	'province/getByCountryAndPostCode',
	provinceServiceGetByCountryAndPostCode,
);

/**
 * Resets the province state.
 */
export const provinceReset = createAction('province/reset');

/**
 * Updates the province state with a new array of provinces.
 *
 * @param {IProvince[]} payload - The new array of provinces to update the state.
 */
export const provinceUpdateState = createAction<IProvince[]>('province/updateState');
