export enum VehicleColorEndPoints {
	/**
	 * Vehicle color search
	 * @host `/vehicleColor/search/`
	 */
	SEARCH = '/vehicleColor/search/',
	/**
	 * Vehicle color insert
	 * @host `/vehicleColor/insert/`
	 */
	INSERT = '/vehicleColor/insert/',
	/**
	 * Vehicle color modify
	 * @host `/vehicleColor/modify/`
	 */
	MODIFY = '/vehicleColor/modify/',
	/**
	 * Vehicle color delete
	 * @host `/vehicleColor/delete/`
	 */
	DELETE = '/vehicleColor/delete/',
}
