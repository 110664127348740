import { createSlice } from '@reduxjs/toolkit';

import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { vehicleGetReadyByCurrentBranch } from '@crac/core/redux/actions/VehicleActions';
import type { IVehicleAvailablesReducerState } from '@crac/core/redux-store/reducersState/vehicle/AvailablesReducerState';
const initialState: IVehicleAvailablesReducerState = {
	vehiclesAvailables: [],
	vehiclesAvailablesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const availablesSlice = createSlice({
	name: 'VehicleAvailablesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => initialState);
		// GET READY BY CURRENT BRANCH
		builder
			.addCase(vehicleGetReadyByCurrentBranch.pending, (state) => {
				return {
					...state,
					vehiclesAvailablesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGetReadyByCurrentBranch.fulfilled, (state, action) => {
				return {
					...state,
					vehiclesAvailables: action.payload,
					vehiclesAvailablesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGetReadyByCurrentBranch.rejected, (state, action) => {
				return {
					...state,
					vehiclesAvailablesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const availablesReducer = availablesSlice.reducer;
