import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { tollGetByBooking } from '@crac/core/redux/actions/TollActions';
import type { ITollReducerState } from '@crac/core/redux-store/reducersState/toll';
const initialState: ITollReducerState = {
	tolls: [],
	tollsRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const tollsSlice = createSlice({
	name: 'TollSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY BOOKING
		builder
			.addCase(tollGetByBooking.pending, (state) => {
				return {
					...state,
					tollsRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(tollGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					tolls: deepCopy(action.payload),
					tollsRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(tollGetByBooking.rejected, (state, action) => {
				return {
					...state,
					tollsRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const tollsReducer = tollsSlice.reducer;
