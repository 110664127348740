import type { IPaymentMethod } from '../../models/entities/PaymentMethod';
import type { IPaymentMethodGetAllParams } from '../../models/serviceParams/PaymentMethodParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { PaymentMethodEndPoints } from '../endPoints/PaymentMethodEndPoints';

export const paymentMethodServiceGetAll = (
	model: IPaymentMethodGetAllParams,
): Promise<ServiceResponse<IPaymentMethod[]>> => {
	return Api.get<IPaymentMethod[], IPaymentMethodGetAllParams>(PaymentMethodEndPoints.GET_ALL, model);
};
