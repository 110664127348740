import { amountsRoutes } from './amounts/routes';
import { endRoutes } from './end/routes';
import { excessRoutes } from './excess/routes';
import { searchRoutes } from './search/routes';
import { servicesRoutes } from './services/routes';
import { startRoutes } from './start/routes';

export const dropOffRoutes = {
	...amountsRoutes,
	...endRoutes,
	...excessRoutes,
	...searchRoutes,
	...servicesRoutes,
	...startRoutes,
};
