import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Reception = lazyWithRetry(() => import('./pages/Reception'));

export const receptionRoutes: { [key: number]: IRouteConfig } = {};

receptionRoutes[routesIdMap.BookingReception] = {
	component: Reception,
	exact: false,
	id: routesIdMap.BookingReception,
	name: 'Turns',
	path: '/booking/reception',
	permissions: [PermissionsType.BookingDetail, PermissionsType.BookingTurns, PermissionsType.BookingTurnsFrontDesk],
	menuText: 'Turns',
	icon: 'ticket',
};

receptionRoutes[routesIdMap.BookingReceptionShuttleBus] = {
	component: Reception,
	exact: true,
	id: routesIdMap.BookingReceptionShuttleBus,
	name: 'Shuttle bus',
	path: '/booking/reception/shuttle-bus',
	permissions: [PermissionsType.BookingTurnsBus],
	icon: 'subway',
};

receptionRoutes[routesIdMap.BookingReceptionShuttleBusConfirmed] = {
	component: Reception,
	exact: true,
	id: routesIdMap.BookingReceptionShuttleBusConfirmed,
	name: 'Confirmed',
	path: '/booking/reception/shuttle-bus/confirmed',
	permissions: [PermissionsType.BookingTurnsBus],
	icon: 'subway',
};

receptionRoutes[routesIdMap.BookingReceptionMeetingPoint] = {
	component: Reception,
	exact: true,
	id: routesIdMap.BookingReceptionMeetingPoint,
	name: 'Meeting point',
	path: '/booking/reception/meeting-point',
	permissions: [PermissionsType.BookingTurnsMeeting],
	icon: 'flag',
};

receptionRoutes[routesIdMap.BookingReceptionMeetingPointConfirmed] = {
	component: Reception,
	exact: true,
	id: routesIdMap.BookingReceptionMeetingPointConfirmed,
	name: 'Confirmed',
	path: '/booking/reception/meeting-point/confirmed',
	permissions: [PermissionsType.BookingTurnsMeeting],
	icon: 'flag',
};
