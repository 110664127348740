export enum BookingStateType {
	/**
	 * En Borrador
	 */
	Draft = 0,

	/**
	 * Reserva confirmada.
	 */
	Confirmed = 1,

	/**
	 * Despues de StartContract
	 */
	Processing = 2,

	/**
	 * Despues de RegisterContract - Reserva en contrato, en curso.
	 */
	OnHire = 3,

	/**
	 * Tiene algo pendiente de pagar como por ejemplo una multa o te han puesto un daño desde RAC
	 */
	Pending = 4,

	/**
	 * Todo pagado
	 */
	Liquidated = 5,

	/**
	 * Cancelada
	 */
	Cancelled = 6,

	/**
	 * Cancelada por no disponibilidad. SOLO XML
	 */
	CancelledDueToUnavailability = 7,

	/**
	 * No show
	 */
	NoShow = 8,

	/**
	 * Parada de ventas
	 */
	StopSale = 9,

	/**
	 * Denegada por Centauro por motivos como sin carnet de conducir o
	 * sin tarjeta o no acepta condiciones. Se debería poner un comentario en esta reserva.
	 */
	Denied = 10,
}
