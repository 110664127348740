import type { IPostCode } from '../../models/entities/PostCode';
import type {
	IPostCodeGetBProvinceParams,
	IPostCodeGetByCountryAndTextParams,
} from '../../models/serviceParams/PostCodeParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { PostCodeEndPoints } from '../endPoints/PostCodeEndPoints';

/**
 * Get by country and postal code
 *  {string} countryCode Country code
 *  {string} text Postal code
 * @return {Array<PostCode>} List PostCode
 */
export const postCodeServiceGetByCountryAndText = (
	model: IPostCodeGetByCountryAndTextParams,
): Promise<ServiceResponse<IPostCode[]>> => {
	return Api.get<IPostCode[], IPostCodeGetByCountryAndTextParams>(PostCodeEndPoints.GET_BY_COUNTRY_AND_TEXT, model);
};
export const postCodeServiceGetByProvince = (
	model: IPostCodeGetBProvinceParams,
): Promise<ServiceResponse<IPostCode[]>> => {
	return Api.get<IPostCode[], IPostCodeGetBProvinceParams>(PostCodeEndPoints.GET_BY_PROVINCE, model);
};
