import { makeRequest } from './makeRequest';
import { HttpMethod } from '../types/HttpMethodsType';
import type { IServiceBaseParams } from '../types/ServiceBaseParams';
import type { ServiceResponse } from '../types/ServiceResponse';

interface IApiService {
	get<T, P extends IServiceBaseParams | undefined>(endpoint: string, data?: P): Promise<ServiceResponse<T>>;
	post<T, P extends IServiceBaseParams | undefined>(endpoint: string, data?: P): Promise<ServiceResponse<T>>;
	put<T, P extends IServiceBaseParams | undefined>(endpoint: string, data?: P): Promise<ServiceResponse<T>>;
	delete<T, P extends IServiceBaseParams | undefined>(endpoint: string, data?: P): Promise<ServiceResponse<T>>;
	head<T, P extends IServiceBaseParams | undefined>(endpoint: string, data?: P): Promise<ServiceResponse<T>>;
}

/**
 * Make HTTP request
 */
export const Api: IApiService = {
	/**
	 * Request DELETE type
	 * @param {string} string endpoint
	 * @param {data} object request params
	 */
	delete: <T, P extends IServiceBaseParams | undefined>(endpoint: string, data?: P): Promise<ServiceResponse<T>> => {
		return makeRequest<T, P>(HttpMethod.DELETE, endpoint, data);
	},

	/**
	 * Request GET type
	 * @param {endpoint} string endpoint
	 * @param {data} object request params
	 * @returns {T} Object response
	 */
	get: <T, P extends IServiceBaseParams | undefined>(endpoint: string, data?: P): Promise<ServiceResponse<T>> => {
		return makeRequest<T, P>(HttpMethod.GET, endpoint, data);
	},

	/**
	 *
	 * Request HEAD type
	 * @param {endpoint} string endpoint
	 * @param {data} object request params
	 */
	head<T, P extends IServiceBaseParams | undefined>(endpoint: string, data?: P): Promise<ServiceResponse<T>> {
		return makeRequest<T, P>(HttpMethod.HEAD, endpoint, data);
	},

	/**
	 * Request POST type
	 * @param {endpoint} string endpoint
	 * @param {data} object request params
	 * @returns {T} Object response
	 */
	post: <T, P extends IServiceBaseParams | undefined>(endpoint: string, data?: P): Promise<ServiceResponse<T>> => {
		return makeRequest<T, P>(HttpMethod.POST, endpoint, data);
	},

	/**
	 * Request PUT type
	 * @param string string endpoint
	 * @param {data} object request params
	 * @returns {T} Object response
	 */
	put: <T, P extends IServiceBaseParams | undefined>(endpoint: string, data?: P): Promise<ServiceResponse<T>> => {
		return makeRequest<T, P>(HttpMethod.PUT, endpoint, data);
	},
};
