import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const VehicleGroup = lazyWithRetry(() => import('~/features/fleet/vehicleGroup/pages/VehicleGroup'));
const VehicleGroupNew = lazyWithRetry(() => import('~/features/fleet/vehicleGroup/pages/VehicleGroupNew'));
const VehicleGroupModify = lazyWithRetry(() => import('~/features/fleet/vehicleGroup/pages/VehicleGroupModify'));
const VehicleGroupDetail = lazyWithRetry(() => import('~/features/fleet/vehicleGroup/pages/VehicleGroupDetail'));

export const vehicleGroupRoutes: { [key: number]: IRouteConfig } = {};

vehicleGroupRoutes[routesIdMap.FleetVehicleGroup] = {
	component: VehicleGroup,
	exact: true,
	id: routesIdMap.FleetVehicleGroup,
	name: 'Vehicle group',
	path: '/fleet/vehicle-group',
	permissions: [PermissionsType.FleetVehicleGroup],
	icon: 'tags',
	menuText: 'Groups',
	childrens: [
		routesIdMap.FleetVehicleGroupNew,
		routesIdMap.FleetVehicleGroupModify,
		routesIdMap.FleetVehicleGroupDetail,
	],
};

vehicleGroupRoutes[routesIdMap.FleetVehicleGroupNew] = {
	component: VehicleGroupNew,
	exact: true,
	id: routesIdMap.FleetVehicleGroupNew,
	name: 'New vehicle group',
	path: '/fleet/vehicle-group/new',
	permissions: [PermissionsType.FleetVehicleGroup],
	icon: 'plus',
	menuText: 'New group',
	isChild: true,
};

vehicleGroupRoutes[routesIdMap.FleetVehicleGroupModify] = {
	component: VehicleGroupModify,
	exact: true,
	id: routesIdMap.FleetVehicleGroupModify,
	name: 'Modify vehicle group',
	path: '/fleet/vehicle-group/modify/:id',
	permissions: [PermissionsType.FleetVehicleGroupManage],
	isChild: true,
	icon: 'pencil',
};

vehicleGroupRoutes[routesIdMap.FleetVehicleGroupDetail] = {
	component: VehicleGroupDetail,
	exact: true,
	id: routesIdMap.FleetVehicleGroupDetail,
	name: 'Vehicle group detail',
	path: '/fleet/vehicle-group/:id',
	permissions: [PermissionsType.FleetVehicleGroup],
	isChild: true,
	icon: 'tags',
};
