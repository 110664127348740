export enum WorkshopAssignmentEndPoints {
	/**
	 * Vehicle model search
	 * @host `/workshopAssignment/search/`
	 */
	SEARCH = '/workshopAssignment/search/',
	/**
	 * Vehicle model insert
	 * @host `/workshopAssignment/insert/`
	 */
	INSERT = '/workshopAssignment/insert/',
	/**
	 * Vehicle model modify
	 * @host `/workshopAssignment/modify/`
	 */
	MODIFY = '/workshopAssignment/modify/',
	/**
	 * Vehicle model delete
	 * @host `/workshopAssignment/delete/`
	 */
	DELETE = '/workshopAssignment/delete/',
}
