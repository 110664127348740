import { useEffect, useRef, useState } from 'react';

import type { ICountry } from '@crac/core/models/entities/Country';
import type { IRequestState } from '@crac/core/modules/shared/types/RequestStateType';

import type { RingCentralCallData, RingCentralEvent, RingCentralEventData } from './types';

/**
 * Listened all ring central event
 */
export const useRingCentralEventListener = (callback: (data: RingCentralCallData) => void): void => {
	const [eventData, setEventData] = useState<RingCentralEventData | null>(null);
	const MSG_EVENT = 'message';

	useEffect(() => {
		const handleCallEvents = (event: RingCentralEvent) => {
			if (event.data) {
				setEventData(event.data);
			}
		};

		window.addEventListener(MSG_EVENT, handleCallEvents);

		return () => {
			window.removeEventListener(MSG_EVENT, handleCallEvents, false);
		};
	}, []);

	if (eventData) {
		callback(eventData.call as RingCentralCallData);
	}
};

export const useRequestState = (request: IRequestState): { request: IRequestState } => {
	const ref = useRef<IRequestState>(request);

	useEffect(() => {
		ref.current = request;
	}, [request]);

	return { request };
};

export const isExternalNumber = (callData: RingCentralCallData): boolean => {
	const { from } = callData;
	if (from) {
		return Boolean(from.phoneNumber && from.phoneNumber.length > 5);
	}

	return false;
};

export const formatPhoneNumber = (phoneNumber: string, countries: ICountry[]): string => {
	const updateCountries = countries.map((country) => ({
		...country,
		telephoneRCPrefix: `+${country.telephonePrefix}`.trim().replace(' ', ''),
	}));

	const numbers = updateCountries.filter((country) => phoneNumber.includes(country.telephoneRCPrefix));

	if (numbers.length > 0) {
		return phoneNumber.replace(numbers[0].telephoneRCPrefix, '');
	}
	return phoneNumber;
};
