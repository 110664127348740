import { BrandParkingEndpoints } from './BrandParkingEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBrandParking } from '../entities/BrandParking';

export interface IBrandParkingDeactivateParams extends IServiceBaseParams {
	id: number;
}

export const brandParkingDeactivateService = (model: IBrandParkingDeactivateParams) => {
	return Api.post<IBrandParking, IBrandParkingDeactivateParams>(BrandParkingEndpoints.DEACTIVATE, model);
};
