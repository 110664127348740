export enum RentRateValidationEndPoints {
	/**
	 * @host `/RentRateValidations/Search/`
	 */
	SEARCH = '/RentRateValidations/Search/',
	/**
	 * @host `/RentRateValidations/insert/`
	 */
	INSERT = '/RentRateValidations/insert/',
	/**
	 * @host `/RentRateValidations/modify/`
	 */
	MODIFY = '/RentRateValidations/modify/',
	/**
	 * @host `/RentRateValidations/delete/`
	 */
	DELETE = '/RentRateValidations/delete/',
}
