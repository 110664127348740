import { createSlice } from '@reduxjs/toolkit';

import {
	brandParkingActivate,
	brandParkingDeactivate,
	brandParkingInsert,
	brandParkingModify,
	brandParkingSearch,
	brandParkingsClear,
} from '@crac/core/modules/fleet/brandParking/state/actions/BrandParkingActions';
import type { IBrandParkingStateType } from '@crac/core/modules/fleet/brandParking/state/stateType/BrandParkingStateType';

const initialState: IBrandParkingStateType = {
	brandParkings: [],
	brandParkingSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	brandParkingInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	brandParkingModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	brandParkingDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	brandParkingActivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const brandParkinSlice = createSlice({
	name: 'BrandParkingSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		//* SEARCH
		builder.addCase(brandParkingSearch.pending, (state) => {
			return {
				...state,
				brandParkings: [],
				brandParkingSearchRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(brandParkingSearch.rejected, (state, action) => {
			return {
				...state,
				brandParkingSearchRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(brandParkingSearch.fulfilled, (state, action) => {
			return {
				...state,
				brandParkings: action.payload,
				brandParkingSearchRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* DEACTIVATE
		builder.addCase(brandParkingDeactivate.pending, (state) => {
			return {
				...state,
				brandParkingDeactivateRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(brandParkingDeactivate.rejected, (state, action) => {
			return {
				...state,
				brandParkingDeactivateRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(brandParkingDeactivate.fulfilled, (state, action) => {
			return {
				...state,
				brandParkings: state.brandParkings.map((brandParking) =>
					brandParking.id === action.payload.id ? action.payload : brandParking,
				),
				brandParkingDeactivateRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* ACTIVATE
		builder.addCase(brandParkingActivate.pending, (state) => {
			return {
				...state,
				brandParkingActivateRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(brandParkingActivate.rejected, (state, action) => {
			return {
				...state,
				brandParkingActivateRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(brandParkingActivate.fulfilled, (state, action) => {
			return {
				...state,
				brandParkings: state.brandParkings.map((brandParking) =>
					brandParking.id === action.payload.id ? action.payload : brandParking,
				),
				brandParkingActivateRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* INSERT
		builder.addCase(brandParkingInsert.pending, (state) => {
			return {
				...state,
				brandParkingInsertRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(brandParkingInsert.rejected, (state, action) => {
			return {
				...state,
				brandParkingInsertRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(brandParkingInsert.fulfilled, (state, action) => {
			return {
				...state,
				brandParkings: [...state.brandParkings, action.payload],
				brandParkingInsertRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* MODIFY
		builder.addCase(brandParkingModify.pending, (state) => {
			return {
				...state,
				brandParkingModifyRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(brandParkingModify.rejected, (state, action) => {
			return {
				...state,
				brandParkingModifyRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(brandParkingModify.fulfilled, (state, action) => {
			return {
				...state,
				brandParkings: state.brandParkings.map((brandParking) =>
					brandParking.id === action.payload.id ? action.payload : brandParking,
				),
				brandParkingModifyRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* CLEAR
		builder.addCase(brandParkingsClear, (state) => {
			return {
				...state,
				brandParkings: [],
			};
		});
	},
});

export const brandParkingReducer = brandParkinSlice.reducer;
