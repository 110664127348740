import type { FC } from 'react';
import React from 'react';

import { ListGroupItem } from '@crac/components/external/reactstrap/listGroup';
import { useWindowSize } from '@crac/components/hooks/useWindowSize';
import { splitCamelCase } from '@crac/core/helpers/commons';

interface IPermissionToolListProps {
	permissions: string[];
	userPermissions: string[];
	onChange: (event: React.SyntheticEvent<HTMLInputElement>) => void;
}

export const PermissionToolList: FC<IPermissionToolListProps> = ({ permissions, userPermissions, onChange }) => {
	const size = useWindowSize();
	const listHeight = size.height ? `${size.height - 220}px` : `70%`;

	return (
		<div style={{ height: listHeight, overflow: 'auto' }}>
			{permissions.map((permission) => (
				<ListGroupItem action className="text-wrap" key={permission} style={{ cursor: 'pointer' }} tag="label">
					<input
						checked={userPermissions.includes(permission)}
						id={permission}
						name={permission}
						onChange={onChange}
						type="checkbox"
					/>{' '}
					{splitCamelCase(permission)}
				</ListGroupItem>
			))}
		</div>
	);
};
