import type { IBank } from '../../models/entities/Bank';
import type { IChargeBackGetReasonsParams } from '../../models/serviceParams/ChargeBackParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { ChargeBackEndPoints } from '../endPoints/ChargeBackEndPoints';

/**
 * Get agency by code
 */
export const chargeBackServiceGetReasons = (model: IChargeBackGetReasonsParams): Promise<ServiceResponse<IBank[]>> => {
	return Api.get<IBank[], IChargeBackGetReasonsParams>(ChargeBackEndPoints.GET_REASONS, model);
};
