import { bookingLineServiceInsert } from './BookingLineService';
import { getOnlyDateFromJsDate } from '../../helpers/date-times';
import type { IBookingLine } from '../../models/entities/BookingLine';
import type { IExtraDriver } from '../../models/entities/ExtraDriver';
import type { IService } from '../../models/entities/Service';
import type { IBookingLineInsertParams } from '../../models/serviceParams/BookingLineParams';
import type {
	IExtraDriverGetByBookingParams,
	IExtraDriverGetRemoveServicesByBookingParams,
	IExtraDriverGetRemoveServicesParams,
	IExtraDriverGetServicesByBookingParams,
	IExtraDriverGetServicesParams,
	IExtraDriverRemoveParams,
	IExtraDriverSaveParams,
	IExtraDriverServiceSaveWithServicesParams,
} from '../../models/serviceParams/ExtraDriverParams';
import type { IExtraDriverSaveWithServicesResponse } from '../../models/serviceResponse/ExtraDriverResponse';
import { BookingLineType } from '../../models/types/BookingLineType';
import { Api } from '../../modules/shared/api';
import type { CreationMethod } from '../../modules/shared/types/CreationMethod';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { ExtraDriverEndPoints } from '../endPoints/ExtraDriverEndPoints';

/**
 * GetByBooking
 * @param {string} bookingNumber ExtraDriver bookingNumber
 * @return {Promise<ServiceResponse<ExtraDriver[]>>} `Promise<ServiceResponse<ExtraDriver[]>>`
 */
export const extraDriverServiceGetByBooking = (
	model: IExtraDriverGetByBookingParams,
): Promise<ServiceResponse<IExtraDriver[]>> => {
	return Api.get<IExtraDriver[], IExtraDriverGetByBookingParams>(ExtraDriverEndPoints.GET_BY_BOOKING, model);
};

/**
 * GetServices
 * @return {Promise<ServiceResponse<ExtraDriver[]>>} `Promise<ServiceResponse<ExtraDriver[]>>`
 */
export const extraDriverServiceGetServices = (
	model: IExtraDriverGetServicesParams,
): Promise<ServiceResponse<IService[]>> => {
	return Api.get<IService[], IExtraDriverGetServicesParams>(ExtraDriverEndPoints.GET_SERVICES, model);
};

/**
 * Save
 * @return {Promise<ServiceResponse<ExtraDriver>>} `Promise<ServiceResponse<ExtraDriver>>`
 */
export const extraDriverServiceSave = (model: IExtraDriverSaveParams): Promise<ServiceResponse<IExtraDriver>> => {
	return Api.post<IExtraDriver, IExtraDriverSaveParams>(ExtraDriverEndPoints.SAVE, model);
};

/**
 * Remove
 * @return {Promise<ServiceResponse<ExtraDriver>>} `Promise<ServiceResponse<ExtraDriver>>`
 */
export const extraDriverServiceRemove = (model: IExtraDriverRemoveParams): Promise<ServiceResponse<IExtraDriver>> => {
	return Api.post<IExtraDriver, IExtraDriverRemoveParams>(ExtraDriverEndPoints.REMOVE, model);
};

/**
 * Return all required services SD, YD, or ED, if it's necessary
 * @return {Promise<ServiceResponse<EService[]>>} `Promise<ServiceResponse<ExtraDriver>>`
 */
export const extraDriverServiceGetServicesByBooking = (
	model: IExtraDriverGetServicesByBookingParams,
): Promise<ServiceResponse<IService[]>> => {
	return Api.get<IService[], IExtraDriverGetServicesByBookingParams>(
		ExtraDriverEndPoints.GET_SERVICES_BY_BOOKING,
		model,
	);
};

/**
 * GetRemoveServices -> DELETES SERVICES
 * @return {Promise<ServiceResponse<ExtraDriver[]>>} `Promise<ServiceResponse<ExtraDriver[]>>`
 */
export const extraDriverServiceGetRemoveServices = (
	model: IExtraDriverGetRemoveServicesParams,
): Promise<ServiceResponse<IService[]>> => {
	return Api.get<IService[], IExtraDriverGetRemoveServicesParams>(
		ExtraDriverEndPoints.GET_SERVICES_REMOVE_SERVICES,
		model,
	);
};

/**
 * GetRemoveServicesByBooking -> GETS SERVICES
 * @return {Promise<ServiceResponse<IService>>} `Promise<ServiceResponse<IService>>`
 */
export const extraDriverServiceGetRemoveServicesByBooking = (model: IExtraDriverGetRemoveServicesByBookingParams) =>
	Api.get<IService, IExtraDriverGetRemoveServicesByBookingParams>(
		ExtraDriverEndPoints.GET_SERVICES_REMOVE_SERVICES_BY_BOOKING,
		model,
	);

/**
 * Insert ExtraDriver and check if is necessary to insert SD, YD, or ED
 */
/**
 *
 * @param model
 * @returns IExtraDriverSaveWithServicesResponse
 */
export const extraDriverServiceSaveWithServices = async (
	model: IExtraDriverServiceSaveWithServicesParams,
): Promise<ServiceResponse<IExtraDriverSaveWithServicesResponse>> => {
	const { token, creationMethod, locale, booking, extraDriver } = model;

	/*
	 * // TODO: Mover toda esta lógica al servidor API/Navision
	 * Deberíamos de poder llamar la función de insertar un extraDriver y no tener que gestionar los servicios
	 */

	const extraDriverModel: IExtraDriverSaveParams = {
		birthDate: extraDriver.birthDate,
		bookingNumber: booking.bookingNumber,
		creationMethod,
		extraDriverNumber: extraDriver.extraDriverNumber,
		licenseAuthority: extraDriver.licenseAuthority,
		licenseCountry: extraDriver.licenseCountry,
		licenseExpeditionDate: extraDriver.licenseExpeditionDate,
		licenseExpirationDate: extraDriver.licenseExpirationDate,
		licenseNumber: extraDriver.licenseNumber,
		locale,
		name: extraDriver.name,
		token,
	};

	// Save ExtraDriver
	const saveResponse = await extraDriverServiceSave(extraDriverModel);
	let requireServicesResponse: ServiceResponse<IService[] | null> | null = null;
	let bookingLinesResponse: ServiceResponse<IBookingLine[] | null> | null = null;

	if (saveResponse.ok) {
		// GET NECESSARY SERVICES
		requireServicesResponse = await extraDriverServiceGetServicesByBooking({
			bookingNumber: booking.bookingNumber,
			creationMethod,
			locale,
			token,
		});

		// If has services, insert bookingLine by each service
		if (requireServicesResponse.ok && requireServicesResponse.data && requireServicesResponse.data.length > 0) {
			const { data } = requireServicesResponse;
			const bookingLineList: IBookingLineInsertParams[] = data.map((service) => ({
				automatic: service.minimumQuantity > 0,
				bookingLineType: BookingLineType.Service,
				bookingNumber: booking.bookingNumber,
				code: service.code,
				creationMethod: creationMethod as CreationMethod,
				invoiceTo: booking.invoiceToCustomerCode,
				invoiceToAgency: false,
				name: service.name,
				package: false,
				quoteDateTime: getOnlyDateFromJsDate(new Date()),
			}));

			bookingLinesResponse = await bookingLineServiceInsert({
				bookingLineList,
				creationMethod,
				locale,
				token,
			});

			// TODO: Que hacemos si falla la insercion de bookingLines?
		}
	}

	return {
		data: {
			bookingLines: bookingLinesResponse?.data || [],
			extraDriver: saveResponse.data as IExtraDriver,
		},
		errors: saveResponse.errors,
		ok: saveResponse.ok,
	};
};
