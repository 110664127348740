import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { printerGetByBranch } from '@crac/core/redux/actions/PrinterActions';
import type { IPrinterReducerState } from '@crac/core/redux-store/reducersState/printer';

export const printersStorageKey = 'printers';
const { setItem, removeItem } = managerLocalStorage();

const initialState: IPrinterReducerState = {
	printers: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const printerSlice = createSlice({
	name: 'PrinterSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, (state) => {
			removeItem(printersStorageKey);
			return {
				...state,
				printers: {
					data: [],
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});
		// PRINTER_GET_BY_BRANCH
		builder
			.addCase(printerGetByBranch.pending, (state) => {
				return {
					...state,
					printers: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(printerGetByBranch.fulfilled, (state, action) => {
				setItem(printersStorageKey, action.payload);
				return {
					...state,
					printers: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(printerGetByBranch.rejected, (state, action) => {
				return {
					...state,
					printers: {
						data: [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const printerReducer = printerSlice.reducer;
