import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Assignment = lazyWithRetry(() => import('./pages/AssignmentSearch'));
const AssignmentNew = lazyWithRetry(() => import('./pages/AssignmentNew'));
const AssignmentModify = lazyWithRetry(() => import('./pages/AssignmentModify'));

export const assignmentRoutes: { [key: number]: IRouteConfig } = {};

assignmentRoutes[routesIdMap.WorkshopAssignment] = {
	component: Assignment,
	exact: true,
	id: routesIdMap.WorkshopAssignment,
	name: 'Assignment',
	path: '/workshop/assignment',
	icon: 'check-circle-o',
	permissions: [PermissionsType.FleetWorkshopAssignment],
	childrens: [routesIdMap.WorkshopAssignmentNew, routesIdMap.WorkshopAssignmentModify],
	menuText: 'Assignment',
};

assignmentRoutes[routesIdMap.WorkshopAssignmentNew] = {
	component: AssignmentNew,
	exact: true,
	id: routesIdMap.WorkshopAssignmentNew,
	name: 'New workshop assignment',
	path: '/workshop/assignment/new',
	permissions: [PermissionsType.FleetWorkshopAssignment],
	isChild: true,
	menuText: 'New assignment',
	icon: 'plus',
};

assignmentRoutes[routesIdMap.WorkshopAssignmentModify] = {
	component: AssignmentModify,
	exact: true,
	id: routesIdMap.WorkshopAssignmentModify,
	name: 'Modify Workshop Assignment',
	path: '/workshop/assignment/modify/:id',
	permissions: [PermissionsType.FleetWorkshopAssignment],
	isChild: true,
	icon: 'pencil',
};
