import type { FC } from 'react';
import React from 'react';

import { Modal } from '../../../external/reactstrap/modal/Modal';

interface IModalAlertProps {
	isOpen: boolean;
	onClose: () => void;
	children?: React.ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const ModalAlert: FC<IModalAlertProps> = ({ children, isOpen, onClose }) => {
	if (!isOpen) {
		return null;
	}

	return (
		<div className="gc-modal--alert">
			<Modal isOpen={isOpen}>{children}</Modal>
		</div>
	);
};
