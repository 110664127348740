import { createSlice } from '@reduxjs/toolkit';

import { invoiceLineClear, invoiceLineGetByInvoiceAndVehicle } from '@crac/core/redux/actions/InvoiceLineActions';
import type { IInvoiceLineReducerState } from '@crac/core/redux-store/reducersState/invoiceLine';

const initialState: IInvoiceLineReducerState = {
	invoiceLineGetByInvoiceAndVehicleRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	invoiceLines: [],
};

const invoiceLineSlice = createSlice({
	name: 'InvoiceLine',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY INVOICE AND VEHICLE
		builder
			.addCase(invoiceLineGetByInvoiceAndVehicle.pending, (state) => {
				return {
					...state,
					invoiceLineGetByInvoiceAndVehicleRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(invoiceLineGetByInvoiceAndVehicle.fulfilled, (state, action) => {
				return {
					...state,
					invoiceLineGetByInvoiceAndVehicleRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					invoiceLines: action.payload,
				};
			})
			.addCase(invoiceLineGetByInvoiceAndVehicle.rejected, (state, action) => {
				return {
					...state,
					invoiceLineGetByInvoiceAndVehicleRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(invoiceLineClear, (state) => {
			return {
				...state,
				invoiceLines: [],
			};
		});
	},
});

export const invoiceLineReducer = invoiceLineSlice.reducer;
