export enum FleetPrevisionEndPoints {
	/**
	 * Fleet prevision search
	 * @host `/fleetPrevision/search/`
	 */
	SEARCH = '/fleetPrevision/search/',
	/**
	 * Fleet prevision insert
	 * @host `/fleetPrevision/insert/`
	 */
	INSERT = '/fleetPrevision/insert/',
	/**
	 * Fleet prevision modify
	 * @host `/fleetPrevision/modify/`,
	 */
	MODIFY = '/fleetPrevision/modify',
	/**
	 * Fleet prevision getByPrevisionCode
	 * @host `/fleetPrevision/getByPrevisionCode/`
	 */
	GET_BY_PREVISION_CODE = '/fleetPrevision/getByPrevisionCode',
}
