import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const FineReasonSearch = lazyWithRetry(() => import('./pages/FineReasonSearch'));
const FineReasonNew = lazyWithRetry(() => import('./pages/FineReasonNew'));
const FineReasonModify = lazyWithRetry(() => import('./pages/FineReasonModify'));

export const fineReasonRoutes: { [key: number]: IRouteConfig } = {};

// REASON
fineReasonRoutes[routesIdMap.FleetFineReason] = {
	component: FineReasonSearch,
	exact: true,
	id: routesIdMap.FleetFineReason,
	name: 'Fine reason',
	menuText: 'Fine reasons',
	path: '/fleet/fine-reason',
	permissions: [PermissionsType.FineReason],
	childrens: [routesIdMap.FleetFineReasonNew, routesIdMap.FleetFineReasonModify],
	icon: 'file-text-o',
};

fineReasonRoutes[routesIdMap.FleetFineReasonNew] = {
	component: FineReasonNew,
	exact: true,
	id: routesIdMap.FleetFineReasonNew,
	name: 'New fine reason',
	path: '/fleet/fine-reason/new',
	permissions: [PermissionsType.FineReason],
	isChild: true,
	icon: 'plus',
	menuText: 'New fine reason',
};

fineReasonRoutes[routesIdMap.FleetFineReasonModify] = {
	component: FineReasonModify,
	exact: true,
	id: routesIdMap.FleetFineReasonModify,
	name: 'Modify fine reason',
	path: '/fleet/fine-reason/modify/:code',
	permissions: [PermissionsType.FineReason],
	isChild: true,
	icon: 'pencil',
};
