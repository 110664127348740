import { createSlice } from '@reduxjs/toolkit';

import { sortVehicleGroupsByCode } from '@crac/core/business/ListSorts';
import {
	vehicleGroupClear,
	vehicleGroupDelete,
	vehicleGroupInsert,
	vehicleGroupModify,
	vehicleGroupSearch,
} from '@crac/core/redux/actions/VehicleGroupActions';
import type { IFleetVehicleGroupReducerState } from '@crac/core/redux-store/reducersState/fleet/FleetVehicleGroupReducerState';
const initialState: IFleetVehicleGroupReducerState = {
	fleetVehicleGroupDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleGroupInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleGroupModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleGroups: [],
	fleetVehicleGroupSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const vehicleGroupSlice = createSlice({
	name: 'FleetVehicleGroup',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(vehicleGroupSearch.pending, (state) => {
				return {
					...state,
					fleetVehicleGroupSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupSearch.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleGroupSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetVehicleGroups: action.payload,
				};
			})
			.addCase(vehicleGroupSearch.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleGroupSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(vehicleGroupClear, (state) => {
			return {
				...state,
				fleetVehicleGroups: [],
			};
		});
		// INSERT
		builder
			.addCase(vehicleGroupInsert.pending, (state) => {
				return {
					...state,
					fleetVehicleGroupInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupInsert.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleGroupInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetVehicleGroups: [...state.fleetVehicleGroups, action.payload].sort(sortVehicleGroupsByCode),
				};
			})
			.addCase(vehicleGroupInsert.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleGroupInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(vehicleGroupModify.pending, (state) => {
				return {
					...state,
					fleetVehicleGroupModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupModify.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleGroupModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetVehicleGroups: [
						...state.fleetVehicleGroups.filter(({ code }) => code !== action.payload.code),
						action.payload,
					].sort(sortVehicleGroupsByCode),
				};
			})
			.addCase(vehicleGroupModify.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleGroupModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DELETE
		builder
			.addCase(vehicleGroupDelete.pending, (state) => {
				return {
					...state,
					fleetVehicleGroupDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGroupDelete.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleGroupDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetVehicleGroups: state.fleetVehicleGroups.filter(({ code }) => code !== action.payload.code),
				};
			})
			.addCase(vehicleGroupDelete.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleGroupDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const vehicleGroupReducer = vehicleGroupSlice.reducer;
