import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
	accidentClear,
	accidentGetByAccidentPart,
	accidentGetByBooking,
} from '@crac/core/redux/actions/AccidentActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { linkGetByAccidentPart } from '@crac/core/redux/actions/LinkActions';
import type { IAccidentReducerState } from '@crac/core/redux-store/reducersState/accident';
const initialState: IAccidentReducerState = {
	accidentDetail: { data: [], inProgress: false, messages: [], ok: true },
	accidents: { data: [], inProgress: false, messages: [], ok: true },
	links: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const accidentsSlice = createSlice({
	name: 'BookingAccidentPart',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(accidentGetByAccidentPart.pending, (state) => {
				return {
					...state,
					accidentDetail: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByAccidentPart.fulfilled, (state, action) => {
				return {
					...state,
					accidentDetail: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByAccidentPart.rejected, (state) => {
				return {
					...state,
					accidentDetail: {
						data: state.accidentDetail ? state.accidentDetail.data : [],
						inProgress: false,
						messages: [],
						ok: false,
					},
				};
			});
		// GET BY BOOKING
		builder
			.addCase(accidentGetByBooking.pending, (state) => {
				return {
					...state,
					accidents: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					accidents: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(accidentGetByBooking.rejected, (state, action) => {
				return {
					...state,
					accidents: {
						data: state.accidents ? state.accidents.data : [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY ACCIDENT PART
		builder
			.addCase(linkGetByAccidentPart.pending, (state) => {
				return {
					...state,
					links: {
						data: state.links ? state.links.data : [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByAccidentPart.fulfilled, (state, action) => {
				return {
					...state,
					links: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByAccidentPart.rejected, (state, action) => {
				return {
					...state,
					links: {
						data: state.links ? state.links.data : [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR
		 * CLEAR CACHE
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, accidentClear), () => {
			return initialState;
		});
	},
});

export const accidentsReducer = accidentsSlice.reducer;
