export enum BookingEndPoints {
	/**
	 * Add booking comment
	 * @host `/booking/addcomment/`
	 */
	ADD_COMMENT = '/booking/addcomment/',

	/**
	 * Send booking invoice
	 * @host `/booking/sendcheckininvoiceemailbybookingnumber/`
	 */
	BOOKING_SEND_CHECKIN_INVOICE_EMAIL_BY_NUMBER = '/booking/sendcheckininvoiceemailbybookingnumber/',

	BOOKING_HAS_UPGRADE = '/booking/hasupgrade',

	/**
	 * Cacel booking
	 * @host `/booking/cancel/`
	 */
	CANCEL = '/booking/cancel/',

	/**
	 * Change booking invoice customer
	 * @host `/booking/changeinvoicablecustomer/`
	 */
	CHANGE_INVOICABLE_CUSTOMER = '/booking/changeinvoicablecustomer/',

	/**
	 * Change vehicle on contract
	 * @host `/booking/changevehicleoncontract/`
	 */
	CHANGE_VEHICLE_CONTRACT = '/booking/changevehicleoncontract/',

	/**
	 * Dropoff add data
	 * @host `/booking/adddropoffdata/`
	 */
	DROPOFF_ADD_DATA = '/booking/adddropoffdata/',

	/**
	 * Change branch on contract
	 * @host `/booking/changedropoffbranchoncontract/`
	 */
	DROPOFF_CHANGE_BRANCH_ON_CONTRACT = '/booking/changedropoffbranchoncontract/',

	/**
	 * End dropoff
	 * @host `/booking/enddropoff/`
	 */
	DROPOFF_END = '/booking/enddropoff/',

	/**
	 * Get pending dropoff
	 * @host `/booking/getpendingdropoffsbyplatenumberandbranchcode/`
	 */
	DROPOFF_GET_PENDING_BY_PLATE_AND_BRANCH = '/booking/getpendingdropoffsbyplatenumberandbranchcode/',

	/**
	 * Init dropoff
	 * @host `/booking/initdropoff/`
	 */
	DROPOFF_INIT = '/booking/initdropoff/',

	/**
	 * Send invoice
	 * @host `/booking/senddropoffinvoice/`
	 */
	DROPOFF_SEND_INVOICE = '/booking/senddropoffinvoice/',

	/**
	 * Start dropoff
	 * @host `/booking/startdropoff/`
	 */
	DROPOFF_START = '/booking/startdropoff/',

	/**
	 * Get availability
	 * @host `/bookingavailability/getavailability/`
	 */
	GET_AVAILABILITY = '/bookingavailability/getavailability/',

	/**
	 * Get bookings by customer
	 * @host `/booking/getbycustomer/`
	 */
	GET_BY_CUSTOMER = '/booking/getbycustomer/',

	/**
	 * Get booking by number
	 * @host `/booking/getbybookingnumber/`
	 */
	GET_BY_NUMBER = '/booking/getbybookingnumber/',

	/**
	 * Get bookings by vehicle
	 * @host `/booking/getbyvehicle/`
	 */
	GET_BY_VEHICLE = '/booking/getbyvehicle/',

	/**
	 * Get pending pickup by branch
	 * @host `/booking/getpendingpickupsbybranch/`
	 */
	GET_PENDING_PICKUP_BY_BRANCH = '/booking/getpendingpickupsbybranch/',

	/**
	 * Get booking by accident
	 * @host `/booking/getbyaccidentpartdata/`
	 */
	GET_BY_ACCIDENT_PART_DATA = '/booking/getbyaccidentpartdata/',

	/**
	 * Insert new booking
	 * @host `/booking/insert/`
	 */
	INSERT = '/booking/insert/',

	/**
	 * Booking modify
	 * @host `/booking/modify/`
	 */
	MODIFY = '/booking/modify/',

	/**
	 * Booking modify
	 * @host `/booking/getopensbybranch/`
	 */
	PENDINGS = '/booking/getopensbybranch/',

	/**
	 * Gegister contract
	 * @host `/booking/registercontract/`
	 */
	REGISTER_CONTRACT = '/booking/registercontract/',

	/**
	 * Remove booking comment
	 * @host `/booking/addcomment/`
	 */
	// eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
	REMOVE_COMMENT = '/booking/addcomment/',

	/**
	 * Booking search
	 * @host `/booking/search/`
	 */
	SEARCH = '/booking/search/',

	/**
	 * Booking sign
	 * @host `/booking/sign/`
	 */
	SIGN = '/booking/sign/',

	/**
	 * Booking start contract
	 * @host `/booking/startcontract/`
	 */
	START_CONTRACT = '/booking/startcontract/',

	/**
	 * Booking start change of vehicle
	 * @host `/booking/startchangeofvehicle/`
	 */
	START_CHANGE_OF_VEHICLE = '/booking/startchangeofvehicle/',

	/**
	 * Booking cancel change of vehicle
	 * @host `/booking/cancelchangeofvehicle/`
	 */
	CANCEL_CHANGE_OF_VEHICLE = '/booking/cancelchangeofvehicle/',

	/**
	 * Booking confirm change of vehicle
	 * @host `/booking/confirmchangeofvehicle/`
	 */
	CONFIRM_CHANGE_OF_VEHICLE = '/booking/confirmchangeofvehicle',

	/**
	 * Booking get extend availability
	 * @host `/booking/getextendavailability/`
	 */
	GET_EXTEND_AVAILABILITY = '/booking/getextendavailability',

	/**
	 * Booking extend
	 * @host `/booking/extend/`
	 */
	EXTEND = '/booking/extend',

	/**
	 * Booking send extend payment email
	 * @host `/booking/sendextendpaymentemail/`
	 */
	SEND_EXTEND_PAYMENT_EMAIL = '/booking/sendextendpaymentemail',

	/**
	 * Booking bill booking
	 * @host `/booking/billbooking/`
	 */
	BILL_BOOKING = '/booking/billbooking',

	/**
	 * Booking refund payments
	 * @host `/booking/refundpayments/`
	 */
	REFUND_PAYMENTS = '/booking/refundpayments',

	/**
	 * Booking change discount
	 * @host `/booking/changediscount/`
	 */
	CHANGE_DISCOUNT = '/booking/changediscount',

	/**
	 * Booking check can do direct pick up
	 * @host `/booking/dodirectpickup`
	 */
	CAN_DO_DIRECT_PICKUP = '/booking/candodirectpickup',

	/**
	 * Booking do direct pick up
	 * @host `/booking/dodirectpickup`
	 */
	DO_DIRECT_PICKUP = '/booking/dodirectpickup',

	/**
	 * Booking revert to confirmed
	 * @host `/booking/reverttoconfirmed`
	 */
	REVERT_TO_CONFIRMED = '/booking/reverttoconfirmed',
	/**
	 * Booking allow pending
	 * @host `/booking/allowpending`
	 */
	ALLOW_PENDING = '/booking/allowpending',

	INVOICEABLE_CHANGE = '/booking/invoiceablechange',
	/**
	 * Booking change customer
	 * @host `/booking/customerchange`
	 */
	CHANGE_CUSTOMER = '/booking/customerchange',

	/**
	 * Insert callback payment
	 * @host `/booking/insertCallBack/`
	 */
	INSERT_CALLBACK = '/booking/insertCallback',
	/**
	 * Booking extend
	 * @host `/booking/extendCallBack/`
	 */
	EXTEND_CALLBACK = '/booking/extendCallBack',
	/**
	 * Booking payment
	 * @host '/booking/payment/'
	 */
	PAYMENT = '/booking/payment',
	/**
	 * Booking payment callBack
	 * @host '/booking/paymentCallBack'
	 */
	PAYMENT_CALLBACK = '/booking/paymentCallBack',
	/**
	 * Booking start (and register) contract with smartkey
	 */
	START_CONTRAT_SMARTKEY = '/booking/startContractWithSmartKey',

	/**
	 * Cancel booking
	 * @host `/booking/cancelSubscription/`
	 */
	CANCEL_SUBSCRIPTION = '/booking/cancelSubscription',
	/**
	 * Booking set drop off data
	 * @host `/booking/bookingSetDropOffData/`
	 */
	SET_DROP_OFF_DATA = '/booking/setDropOffData',

	/**
	 * Booking checkin start / login
	 * @host `/booking/login`
	 */
	AUTOLOGIN = '/booking/login',
}
