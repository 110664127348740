import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const End = lazyWithRetry(() => import('./pages/End'));

export const endRoutes: { [key: number]: IRouteConfig } = {};

endRoutes[routesIdMap.BookingsDropOffEnd] = {
	component: End,
	exact: true,
	id: routesIdMap.BookingsDropOffEnd,
	name: 'Register',
	path: '/booking/drop-off/:id/:plateNumber/sign',
	permissions: [PermissionsType.BookingDropOffProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: 'sign-out',
};
