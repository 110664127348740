import { SaleServiceRateEndpoints } from './SaleServiceRateEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleRateSearchParams } from '../../shared/entities/SaleRateBaseEntity';
import type { ISaleServiceRate } from '../entities/SaleServiceRate';

export interface ISaleServiceRateSearchParams extends IServiceBaseParams, ISaleRateSearchParams {
	amount?: number;
	saleServiceId?: number;
}

export const saleServiceRateSearchService = (model: ISaleServiceRateSearchParams) => {
	return Api.get<ISaleServiceRate[], ISaleServiceRateSearchParams>(SaleServiceRateEndpoints.SEARCH, model);
};
