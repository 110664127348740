import type { IRentRateVariation } from '../../models/entities/RentRateVariation';
import type {
	IRentRateVariationDeactivateParams,
	IRentRateVariationInsertParams,
	IRentRateVariationsSearchParams,
} from '../../models/serviceParams/RentParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { RentRateVariationEndPoints } from '../endPoints/RentRateVariationEndPoints';

export const rentRateVariationSearchService = (
	model: IRentRateVariationsSearchParams,
): Promise<ServiceResponse<IRentRateVariation[]>> => {
	return Api.get<IRentRateVariation[], IRentRateVariationsSearchParams>(RentRateVariationEndPoints.SEARCH, model);
};

export const rentRateVariationInsertService = (
	model: IRentRateVariationInsertParams,
): Promise<ServiceResponse<IRentRateVariation[]>> => {
	return Api.post<IRentRateVariation[], IRentRateVariationInsertParams>(RentRateVariationEndPoints.INSERT, model);
};

export const rentRateVariationDeactivateService = (
	model: IRentRateVariationDeactivateParams,
): Promise<ServiceResponse<IRentRateVariation[]>> => {
	return Api.post<IRentRateVariation[], IRentRateVariationDeactivateParams>(
		RentRateVariationEndPoints.DEACTIVATE,
		model,
	);
};
