import type { IVendor } from '../../models/entities/Vendor';
import type { IVendorGetByCodeParams, IVendorSearchParams } from '../../models/serviceParams/VendorParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { VendorEndPoints } from '../endPoints/VendorEndPoints';

/**
 * Search vendor
 *  {string} code Vendor code
 *  {string} name Vendor name
 * @return {Promise} `Array<VendorPropType>`
 */
export const vendorServiceSearch = (model: IVendorSearchParams): Promise<ServiceResponse<IVendor[]>> => {
	return Api.get<IVendor[], IVendorSearchParams>(VendorEndPoints.SEARCH, model);
};

/**
 * Get vendor by code
 *  {string} code Vendor code
 * @return {Promise} `VendorPropType`
 */
export const vendorServiceGetByCode = (model: IVendorGetByCodeParams): Promise<ServiceResponse<IVendor>> => {
	return Api.get<IVendor, IVendorGetByCodeParams>(VendorEndPoints.GET_BY_CODE, model);
};
