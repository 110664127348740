export enum TicketManagementEndPoints {
	/**
	 * Ticket management Contact Centre
	 * @host `/TicketManagement/ContactCentre/`
	 */
	CONTACT_CENTRE = '/ticketManagement/contactCentre/',
	/**
	 * New ticket
	 * @host `/ticketManagement/new/`
	 */
	NEW = '/ticketManagement/new/',
	/**
	 * Add commment to ticket
	 */
	ADD_COMMENT = '/ticketManagement/addComment/',
	/**
	 * Get ticket by booking
	 */
	GET_BY_BOOKING = '/ticketManagement/getByBooking/',
	/**
	 * Get ticket by customer
	 */
	GET_BY_CUSTOMER = '/ticketManagement/getByCustomer/',
	/**
	 * Get ticket by branch
	 */
	GET_BY_BRANCH = '/ticketManagement/getByBranch/',
	/**
	 * Close ticket
	 */
	CLOSE = '/ticketManagement/close',
	/**
	 * Get comment file
	 */
	GET_COMMENT_FILE = '/ticketManagement/getCommentFile',
}
