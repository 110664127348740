import { useMemo } from 'react';

import type { IBranch } from '@crac/core/models/entities/Branch';
import type { IEmployee } from '@crac/core/models/entities/Employee';
import type { IProvider } from '@crac/core/models/entities/Provider';

import { useAppSelector } from '~/redux/hooks';

export const useEmployeeState = () => useAppSelector((state) => state.Employee);

export const useEmployeeSelector = (): IEmployee => useAppSelector((state) => state.Employee.employee as IEmployee);

export const useEmployeeBranchSelector = (): IBranch => {
	const employee = useAppSelector((state) => state.Employee.employee);
	const branches = useAppSelector((state) => state.Branch.branches);
	return useMemo(() => {
		const currentEmployee: IEmployee = employee as IEmployee;

		return branches.find((branch) => branch.code === currentEmployee.branchCode) as IBranch;
	}, [branches, employee]);
};

export const useEmployeeProviderSelector = (): IProvider => {
	const employee = useAppSelector((state) => state.Employee.employee);
	const branches = useAppSelector((state) => state.Branch.branches);
	const providers = useAppSelector((state) => state.Provider.providers);
	return useMemo(() => {
		const currentEmployee: IEmployee = employee as IEmployee;
		const employeeBranch = branches.find((branch) => branch.code === currentEmployee.branchCode) as IBranch;

		return providers.find((provider) => provider.name === employeeBranch?.provider) as IProvider;
	}, [branches, employee, providers]);
};
