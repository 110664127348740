import type { IPrinter } from '../../models/entities/Printer';
import type { IPrinterGetByBranchParams } from '../../models/serviceParams/PrinterParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { PrinterEndPoints } from '../endPoints/PrinterEndPoints';

/**
 * Get by branch
 *  {string} branchCode Branch code
 * @return {Array<Printer>} List Printer
 */
export const printerServiceGetByBranch = (model: IPrinterGetByBranchParams): Promise<ServiceResponse<IPrinter[]>> => {
	return Api.get<IPrinter[], IPrinterGetByBranchParams>(PrinterEndPoints.GET_BY_BRANCH, model);
};
