import { createAction } from '@reduxjs/toolkit';

import {
	depositRateServiceDeactivate,
	depositRateServiceInsert,
	depositRateServiceSearch,
} from '../../data/services/DepositRateService';
import type { IDepositRate } from '../../models/entities/DepositRate';
import type {
	IDepositRateDeactivateParams,
	IDepositRateInsertParams,
	IDepositRateSearchParams,
} from '../../models/serviceParams/DepositRateParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for deposit rates based on specified parameters.
 *
 * @param {IDepositRate[]} payload - The deposit rates to be returned.
 * @param {IDepositRateSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of deposit rates.
 */
export const depositRateSearch = createAsyncAction<IDepositRate[], IDepositRateSearchParams>(
	'depositRate/search',
	depositRateServiceSearch,
);

/**
 * Inserts new deposit rates into the system.
 *
 * @param {IDepositRate[]} payload - The deposit rates to be inserted.
 * @param {IDepositRateInsertParams} params - Parameters detailing the deposit rates to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the deposit rates are successfully inserted.
 */
export const depositRateInsert = createAsyncAction<IDepositRate[], IDepositRateInsertParams>(
	'depositRate/insert',
	depositRateServiceInsert,
);

/**
 * Deactivates specified deposit rates.
 *
 * @param {IDepositRate[]} payload - The deposit rates to be deactivated.
 * @param {IDepositRateDeactivateParams} params - Parameters for deactivating the deposit rates.
 * @returns {Promise<void>} - A promise that resolves after the deposit rates are deactivated.
 */
export const depositRateDeactivate = createAsyncAction<IDepositRate[], IDepositRateDeactivateParams>(
	'depositRate/deactivate',
	depositRateServiceDeactivate,
);

/**
 * Clears the deposit rate state.
 */
export const depositRateClear = createAction('depositRate/clear');
