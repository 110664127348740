import { createSlice } from '@reduxjs/toolkit';

import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	vehicleGroupIncludedConfigurationClear,
	vehicleGroupIncludedConfigurationDeactivate,
	vehicleGroupIncludedConfigurationInsert,
	vehicleGroupIncludedConfigurationModify,
	vehicleGroupIncludedConfigurationRemoveDeactivated,
	vehicleGroupIncludedConfigurationSearch,
} from '@crac/core/redux/actions/VehicleGroupIncludedConfigurationActions';
import type { IVehicleGroupIncludedConfigurationReducerState } from '@crac/core/redux-store/reducersState/pricing/VehicleGroupIncludedConfigurationReducerState';

const initialState: IVehicleGroupIncludedConfigurationReducerState = {
	vehicleGroupIncludedConfiguration: [],
	vehicleGroupIncludedConfigurationDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupIncludedConfigurationInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupIncludedConfigurationModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupIncludedConfigurationSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const vehicleGroupIncludedConfigurationSlice = createSlice({
	name: 'vehicleGroupIncludedConfiguration',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(clearGlobalCache, () => {
			return {
				...initialState,
				vehicleGroupIncludedConfiguration: [],
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationClear, () => {
			return {
				...initialState,
				vehicleGroupIncludedConfiguration: [],
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationSearch.pending, (state) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationSearchRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationSearch.rejected, (state, action) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationSearchRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationSearch.fulfilled, (state, action) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationSearchRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				vehicleGroupIncludedConfiguration: action.payload,
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationInsert.pending, (state) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationInsertRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationInsert.rejected, (state, action) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationInsertRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationInsert.fulfilled, (state, action) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationInsertRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				vehicleGroupIncludedConfiguration: [...state.vehicleGroupIncludedConfiguration, ...action.payload],
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationModify.pending, (state) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationModifyRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationModify.rejected, (state, action) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationModifyRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationModify.fulfilled, (state, action) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationModifyRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				vehicleGroupIncludedConfiguration: [...state.vehicleGroupIncludedConfiguration, action.payload],
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationRemoveDeactivated, (state) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationDeactivateRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationDeactivate.pending, (state) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationDeactivateRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationDeactivate.rejected, (state, action) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationDeactivateRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(vehicleGroupIncludedConfigurationDeactivate.fulfilled, (state, action) => {
			return {
				...state,
				vehicleGroupIncludedConfigurationDeactivateRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				vehicleGroupIncludedConfiguration: [
					...state.vehicleGroupIncludedConfiguration.filter(
						({ id }) => !action.payload.map((item) => item.id).includes(id),
					),
					...action.payload,
				],
			};
		});
	},
});

export const vehicleGroupIncludedConfigurationReducer = vehicleGroupIncludedConfigurationSlice.reducer;
