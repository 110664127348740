import { createSlice } from '@reduxjs/toolkit';

import { bookingGetByCustomer } from '@crac/core/redux/actions/BookingActions';
import type { IBookingReducerState } from '@crac/core/redux-store/reducersState/vehicle/BookingReducerState';

// TODO: Crear su propio estado para customer bookings
const initialState: IBookingReducerState = {
	bookings: { data: [], inProgress: false, messages: [], ok: true },
};

const bookingsSlice = createSlice({
	name: 'CustomerGetBookingsSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY CUSTOMER
		builder
			.addCase(bookingGetByCustomer.pending, (state) => {
				return {
					...state,
					bookings: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingGetByCustomer.fulfilled, (state, action) => {
				return {
					...state,
					bookings: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingGetByCustomer.rejected, (state, action) => {
				return {
					...state,
					bookings: {
						data: [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const bookingsReducer = bookingsSlice.reducer;
