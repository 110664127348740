export const checkIfHasPermissions = (permissionsToCheck: string[], employeePermissions: string[]) => {
	if (!permissionsToCheck || !employeePermissions) {
		return false;
	}
	const { hasPermission } = permissionsToCheck.reduce(
		(acc, current) => {
			if (employeePermissions.includes(current)) {
				acc.hasPermission = true;
			}
			return acc;
		},
		{ hasPermission: false } as { hasPermission: boolean },
	);

	return hasPermission;
};
