import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Dashboard = lazyWithRetry(() => import('./pages/Dashboard'));

export const dashboardRoutes: { [key: number]: IRouteConfig } = {};

dashboardRoutes[routesIdMap.CustomerDashboard] = {
	component: Dashboard,
	exact: true,
	id: routesIdMap.CustomerDashboard,
	name: 'Customer',
	path: '/customer',
	permissions: [PermissionsType.Customer],
	icon: 'users',
	menuText: 'Customer',
};
