import { splitCamelCase } from './commons';
import { UnitTime, UnitTimeDurationTexts } from '../models/types/UnitTime';

export type UnitTimeDurationsType =
	| UnitTimeDurationTexts.Days
	| UnitTimeDurationTexts.Months
	| UnitTimeDurationTexts.Hours
	| UnitTimeDurationTexts.Minutes;

export const getAmountPerUnitTimeText = (unitTime?: UnitTime) => {
	if (unitTime === UnitTime.Hour) {
		return UnitTimeDurationTexts.AmountPerHour;
	} else if (unitTime === UnitTime.Month) {
		return UnitTimeDurationTexts.AmountPerMonth;
	} else if (unitTime === UnitTime.Minute) {
		return UnitTimeDurationTexts.AmountPerMinute;
	}
	return UnitTimeDurationTexts.AmountPerDay;
};

export const getPerUnitTimeText = (unitTime?: UnitTime) => {
	if (unitTime === UnitTime.Hour) {
		return UnitTimeDurationTexts.Hourly;
	} else if (unitTime === UnitTime.Month) {
		return UnitTimeDurationTexts.Monthly;
	} else if (unitTime === UnitTime.Minute) {
		return UnitTimeDurationTexts.PerMinute;
	}
	return UnitTimeDurationTexts.Daily;
};

export const getSplitPerUnitTimeText = (unitTime?: UnitTime) => {
	return splitCamelCase(getPerUnitTimeText(unitTime));
};

export const getUnitTimeDurationText = (unitTime?: UnitTime, duration = 1) => {
	if (unitTime === UnitTime.Hour) {
		return duration > 1 ? UnitTimeDurationTexts.Hours : UnitTimeDurationTexts.Hour;
	}
	if (unitTime === UnitTime.Month) {
		return duration > 1 ? UnitTimeDurationTexts.Months : UnitTimeDurationTexts.Month;
	}
	if (unitTime === UnitTime.Minute) {
		return duration > 1 ? UnitTimeDurationTexts.Minutes : UnitTimeDurationTexts.Minute;
	}
	return duration > 1 ? UnitTimeDurationTexts.Days : UnitTimeDurationTexts.Day;
};
