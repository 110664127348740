const customerNamePaymentTypeEN = {
	cardPayment: 'Card payment',
	cardPreauthorization: 'Card preauthorization',
	cardPreauthorizationAnnulment: 'Card preauthorization annulment',
	cardProcessPreauthorization: 'Card process preauthorization',
	cardRefund: 'Card refund',
	paymentInCard: 'Payment in card',
};
const customerNamePaymentTypeES = {
	cardPayment: 'Pago con tarjeta',
	cardPreauthorization: 'Preautorización con tarjeta',
	cardPreauthorizationAnnulment: 'Anulación de preautorización con tarjeta',
	cardProcessPreauthorization: 'Procesamiento de preautorización con tarjeta',
	cardRefund: 'Devolución a tarjeta',
	paymentInCard: 'Pago en tarjeta',
};

export const paymentTypesEN = {
	...customerNamePaymentTypeEN,
	brokerPayment: 'Broker payment',
	brokerRefund: 'Broker refund',
	paymentInCash: 'Payment in cash',
	paymentInPinpadCard: 'Payment in pinpad card',
	paymentInPoints: 'Payment in points',
	preauthorizationAnnulment: 'Preauthorization annulment',
	preauthorizationInPinpadCard: 'Preauthorization in pinpad card',
	processPreauthorization: 'Process preauthorization',
	refundDueInCash: 'Refund due in cash',
	refundDueInPoints: 'Refund due in points',
	refundDueToCard: 'Refund due to card',
	webPayment: 'Web payment',
	webRefund: 'Web refund',
};

export const paymentTypesES = {
	...customerNamePaymentTypeES,
	brokerPayment: 'Pago de broker',
	brokerRefund: 'Devolución de broker',
	paymentInCash: 'Pago en efectivo',
	paymentInPinpadCard: 'Pago con tarjeta en pinpad',
	paymentInPoints: 'Pago con puntos',
	preauthorizationAnnulment: 'Anulación de preautorización',
	preauthorizationInPinpadCard: 'Preautorización en pinpad con tarjeta',
	processPreauthorization: 'Preautorización',
	refundDueInCash: 'Devolución en efectivo',
	refundDueInPoints: 'Devolución en puntos',
	refundDueToCard: 'Devolución a tarjeta',
	webPayment: 'Pago web',
	webRefund: 'Devolución web',
};
