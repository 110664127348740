import type { FC } from 'react';
import React from 'react';

import { DropdownMenu as RSDropdownMenu } from 'reactstrap';
import type { DropdownMenuProps as RSDropdownMenuProps } from 'reactstrap/types/lib/DropdownMenu';

export type DropdownMenuProps = RSDropdownMenuProps;

export const DropdownMenu: FC<DropdownMenuProps> = ({ children, ...props }) => {
	return <RSDropdownMenu {...props}>{children}</RSDropdownMenu>;
};
