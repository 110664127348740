interface RingCentralPhone {
	phoneNumber: string;
}

/*
 * MORE EVENTS
 * https://github.com/ringcentral/ringcentral-embeddable/blob/master/docs/widget-event.md#web-phone-call-event
 */
export enum RingCentralEventType {
	RC_RINGOUT_CALL_NOTIFY = 'rc-ringout-call-notify',
	RC_CALL_RING_NOTIFY = 'rc-call-ring-notify',
	RC_CALL_START_NOTIFY = 'rc-call-start-notify',
	RC_CALL_MUTE_NOTIFY = 'rc-call-mute-notify',
	RC_CALL_HOLD_NOTIFY = 'rc-call-hold-notify',
	RC_CALL_RESUME_NOTIFY = 'rc-call-resume-notify',
	RC_CALL_END_NOTIFY = 'rc-call-end-notify',
	RC_ACTIVE_CALL_NOTIFY = 'rc-active-call-notify',
}

export interface RingCentralCallData {
	callId?: string;
	action?: string;
	direction: string;
	from: RingCentralPhone;
	id?: string;
	offset: number;
	partyId: string;
	sessionId: string;
	startTime?: number;
	lastActiveTime?: number;
	creationTime?: number;
	endTime?: number;
	telephonySessionId: string;
	telephonyStatus: string;
	to: RingCentralPhone;
	toName: string;
	toUserName: string;
	uri?: string;
	callInfo?: any;
	partyData?: any;
	callStatus: any;
}

export interface RingCentralEventData {
	type: string;
	call?: RingCentralCallData;
	telephonyStatus?: string;
	userStatus?: string;
}

export interface RingCentralEvent {
	data: RingCentralEventData;
}
