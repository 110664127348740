import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Insurances = lazyWithRetry(() => import('./pages/Insurances'));

export const insurancesRoutes: { [key: number]: IRouteConfig } = {};

insurancesRoutes[routesIdMap.VehiclesDetailInsurances] = {
	component: Insurances,
	exact: true,
	id: routesIdMap.VehiclesDetailInsurances,
	name: 'Insurances',
	path: '/vehicles/detail/:id/insurances',
	permissions: [PermissionsType.VehicleDetail, PermissionsType.Fleet],
	icon: 'shield',
};
