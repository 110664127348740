'use client';
import type { FC } from 'react';
import React from 'react';

import { CardBody as RSCardBody } from 'reactstrap';
import type { CardBodyProps as RSCardBodyProps } from 'reactstrap/types/lib/CardBody';

export type CardBodyProps = RSCardBodyProps;

export const CardBody: FC<CardBodyProps> = ({ children, ...props }) => {
	return <RSCardBody {...props}>{children}</RSCardBody>;
};
