import type { IStopSale } from '../../models/entities/StopSale';
import type {
	IStopSalesDeactivateParams,
	IStopSalesInsertParams,
	IStopSalesSearchParams,
} from '../../models/serviceParams/StopSaleParams';
import { Api } from '../../modules/shared/api';
import { StopSaleEndPoints } from '../endPoints/StopSaleEndPoints';

/**
 * Search stop sales
 */
export const stopSaleServiceSearch = (model: IStopSalesSearchParams) => {
	return Api.get<IStopSale[], IStopSalesSearchParams>(StopSaleEndPoints.SEARCH, model);
};

/**
 * Insert stop sales
 */
export const stopSaleServiceInsert = (model: IStopSalesInsertParams) => {
	return Api.post<IStopSale[], IStopSalesInsertParams>(StopSaleEndPoints.INSERT, model);
};

export const stopSaleServiceDeactivate = (model: IStopSalesDeactivateParams) => {
	return Api.post<IStopSale[], IStopSalesDeactivateParams>(StopSaleEndPoints.DEACTIVATE, model);
};
