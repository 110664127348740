export enum CityEndPoints {
	/**
	 * Get city by provider code
	 * @host `/city/getbyprovincecode`
	 */
	GET_BY_PROVINCE = '/city/getbyprovincecode',

	/**
	 * Get city by post code and country code
	 * @host `/city/getbypostcodeandcountrycode`
	 */
	GET_BY_POST_CODE_AND_COUNTRY = '/city/getbypostcodeandcountrycode',
}
