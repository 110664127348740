export enum DigitizationEndPoints {
	/**
	 *
	 * @host `/customerdigitization/digitize/`
	 */
	DIGITIZATION_DIGITIZE = '/customerdigitization/digitize/',

	/**
	 *	Remove digitization
	 * @host `/customerdigitization/remove/`
	 */
	DIGITIZATION_REMOVE = '/customerDigitization/removeDigitize/',
}
