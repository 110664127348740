export enum ServiceRateEndPoints {
	/**
	 * @host `ServiceRate/Search/`
	 */
	SEARCH = '/serviceRate/Search/',

	/**
	 * @host `ServiceRate/Insert`
	 */
	INSERT = '/serviceRate/Insert/',
}

export enum ServiceRateTypeEndPoints {
	/**
	 * @host `/serviceRateType/search/`
	 */
	SEARCH = '/serviceRateType/search/',

	/**
	 * @host `/serviceRateType/insert/`
	 */
	INSERT = '/serviceRateType/insert/',

	/**
	 * @host `/serviceRateType/modify/`
	 */
	MODIFY = '/serviceRateType/modify/',
}
