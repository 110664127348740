import {
	fleetVehicleModelServiceActivate,
	fleetVehicleModelServiceDeactivate,
	fleetVehicleModelServiceInsert,
	fleetVehicleModelServiceModify,
	fleetVehicleModelServiceSearch,
} from '../../data/services/FleetVehicleModelService';
import type { IVehicleModel } from '../../models/entities/VehicleModel';
import type {
	IFleetVehicleModelDeactivateParams,
	IFleetVehicleModelInsertParams,
	IFleetVehicleModelModifyParams,
	IFleetVehicleModelSearchParams,
} from '../../models/serviceParams/FleetVehicleModelParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for fleet vehicle models based on search parameters.
 * @param {IFleetVehicleModelSearchParams} params The parameters used for searching fleet vehicle models.
 * @returns {Promise<void>} A promise that resolves when the search operation is complete.
 */
export const fleetVehicleModelSearch = createAsyncAction<IVehicleModel[], IFleetVehicleModelSearchParams>(
	'fleet/vehicleModel/search',
	fleetVehicleModelServiceSearch,
);

/**
 * Clears the fleet vehicle model data from the state.
 */
export const fleetVehicleModelClear = createSyncAction('fleet/vehicleModel/clear');

/**
 * Inserts a new fleet vehicle model.
 * @param {IFleetVehicleModelInsertParams} params The parameters for inserting a new fleet vehicle model.
 * @returns {Promise<void>} A promise that resolves when the insertion operation is complete.
 */
export const fleetVehicleModelInsert = createAsyncAction<IVehicleModel, IFleetVehicleModelInsertParams>(
	'fleet/vehicleModel/insert',
	fleetVehicleModelServiceInsert,
);

/**
 * Modifies an existing fleet vehicle model.
 * @param {IFleetVehicleModelModifyParams} params The parameters for modifying an existing fleet vehicle model.
 * @returns {Promise<void>} A promise that resolves when the modification operation is complete.
 */
export const fleetVehicleModelModify = createAsyncAction<IVehicleModel, IFleetVehicleModelModifyParams>(
	'fleet/vehicleModel/modify',
	fleetVehicleModelServiceModify,
);

/**
 * Deactivates a fleet vehicle model.
 * @param {IFleetVehicleModelDeactivateParams} params The parameters for deactivating a fleet vehicle model.
 * @returns {Promise<void>} A promise that resolves when the deactivation operation is complete.
 */
export const fleetVehicleModelDeactivate = createAsyncAction<IVehicleModel, IFleetVehicleModelDeactivateParams>(
	'fleet/vehicleModel/deactivate',
	fleetVehicleModelServiceDeactivate,
);

/**
 * Activates a fleet vehicle model.
 * @param {IFleetVehicleModelDeactivateParams} params The parameters for activating a fleet vehicle model.
 * @returns {Promise<void>} A promise that resolves when the activation operation is complete.
 */
export const fleetVehicleModelActivate = createAsyncAction<IVehicleModel, IFleetVehicleModelDeactivateParams>(
	'fleet/vehicleModel/activate',
	fleetVehicleModelServiceActivate,
);
