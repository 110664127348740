import type { IEmployee } from '../../models/entities/Employee';
import type {
	IEmployeeChangeBranchParams,
	IEmployeeChangePasswordParams,
	IEmployeeLogInParams,
} from '../../models/serviceParams/EmployeeParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { EmployeeEndPoints } from '../endPoints/EmployeeEndPoints';

/**
 * Log in a employee with its username and password.
 *  {string} username Username of the employee.
 *  {string} password Password of the employee.
 * @return {Promise}
 */
export const employeeServiceLogIn = (model: IEmployeeLogInParams): Promise<ServiceResponse<IEmployee>> => {
	return Api.post<IEmployee, IEmployeeLogInParams>(EmployeeEndPoints.LOGIN, model);
};

/**
 * Change employee to a new branch.
 *  {string} branchCode Branch code.
 * @returns {Promise} `EmployeeProopType`
 */
export const employeeServiceChangeBranch = (
	model: IEmployeeChangeBranchParams,
): Promise<ServiceResponse<IEmployee>> => {
	return Api.post<IEmployee, IEmployeeChangeBranchParams>(EmployeeEndPoints.CHANGE_BRANCH, model);
};

/**
 * Change employee password.
 *  {string} currentPassword Current password.
 *  {string} newPassword New password.
 * @returns {Promise} `EmployeeProopType`
 */
export const employeeServiceChangePassword = (
	model: IEmployeeChangePasswordParams,
): Promise<ServiceResponse<IEmployee>> => {
	return Api.post<IEmployee, IEmployeeChangePasswordParams>(EmployeeEndPoints.CHANGE_PASSWORD, model);
};

/**
 * Update all connections
 */
export const employeeServiceRefreshToken = (): Promise<ServiceResponse<IEmployee>> => {
	return Api.get<IEmployee, undefined>(EmployeeEndPoints.REFRESH_TOKEN);
};
