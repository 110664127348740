import { MetaModelEndpoints } from './MetaModelEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IMetaModel } from '../entities/MetaModel';

export interface IMetaModelInsertParams extends IServiceBaseParams, Omit<IMetaModel, 'id' | 'url'> {}

export const metaModelInsertService = (model: IMetaModelInsertParams) => {
	return Api.post<IMetaModel, IMetaModelInsertParams>(MetaModelEndpoints.INSERT, model);
};
