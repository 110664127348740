import { createAction } from '@reduxjs/toolkit';

import {
	companyServiceGetByCode,
	companyServiceInsert,
	companyServiceModify,
	companyServiceSearch,
	companyServiceValidateData,
} from '../../data/services/CompanyService';
import type { ICompany } from '../../models/entities/Company';
import type {
	ICompanyGetByCodeParams,
	ICompanyInsertParams,
	ICompanyModifyParams,
	ICompanySearchParams,
	ICompanyValidateDataParams,
} from '../../models/serviceParams/CompanyParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves a company by its code.
 *
 * @param {ICompany} payload - The company to be retrieved.
 * @param {ICompanyGetByCodeParams} params - Parameters to identify the company by its code.
 * @returns {Promise<void>} - A promise that resolves to the company matching the given code.
 */
export const companyGetByCode = createAsyncAction<ICompany, ICompanyGetByCodeParams>(
	'company/getByCode',
	companyServiceGetByCode,
);

/**
 * Retrieves the 'invoice to' company information by its code.
 *
 * @param {ICompany} payload - The company information to be retrieved for invoicing purposes.
 * @param {ICompanyGetByCodeParams} params - Parameters to identify the 'invoice to' company by its code.
 * @returns {Promise<void>} - A promise that resolves to the 'invoice to' company matching the given code.
 */
export const companyInvoiceToGetByCode = createAsyncAction<ICompany, ICompanyGetByCodeParams>(
	'company/invoiceToGetByCode',
	companyServiceGetByCode,
);

/**
 * Inserts a new company into the system.
 *
 * @param {ICompany} payload - The company to be inserted.
 * @param {ICompanyInsertParams} params - Parameters detailing the company to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the company is successfully inserted.
 */
export const companyInsert = createAsyncAction<ICompany, ICompanyInsertParams>('company/insert', companyServiceInsert);

/**
 * Modifies an existing company's details.
 *
 * @param {ICompany} payload - The company to be modified.
 * @param {ICompanyModifyParams} params - Parameters detailing the modifications to be made.
 * @returns {Promise<void>} - A promise that resolves after the company is successfully modified.
 */
export const companyModify = createAsyncAction<ICompany, ICompanyModifyParams>('company/modify', companyServiceModify);

/**
 * Searches for companies based on specified search criteria.
 *
 * @param {ICompany[]} payload - The companies matching the search criteria.
 * @param {ICompanySearchParams} params - Parameters defining the search criteria.
 * @returns {Promise<void>} - A promise that resolves to an array of companies matching the search criteria.
 */
export const companySearch = createAsyncAction<ICompany[], ICompanySearchParams>(
	'company/search',
	companyServiceSearch,
);

/**
 * Clears the company state.
 */
export const companyClear = createAction('company/clear');

/**
 * Validates company data.
 * @param payload - The parameters for validating company data.
 * @returns A boolean indicating whether the data is valid or not.
 */
export const companyValidateData = createAsyncAction<boolean, ICompanyValidateDataParams>(
	'company/validateData',
	companyServiceValidateData,
);

/**
 * Clears the company search state.
 */
export const companyClearSearch = createAction('company/clearSearch');
