import type { FC } from 'react';
import React from 'react';

import { NavbarToggler as RSNavbarToggler } from 'reactstrap';
import type { NavbarTogglerProps as RSNavbarTogglerProps } from 'reactstrap/types/lib/NavbarToggler';

export type NavbarTogglerProps = RSNavbarTogglerProps;

export const NavbarToggler: FC<NavbarTogglerProps> = ({ children, ...props }): JSX.Element => {
	return <RSNavbarToggler {...props}>{children}</RSNavbarToggler>;
};
