import type { IDiscountCoupon } from '../../models/entities/DiscountCoupon';
import type {
	IDiscountCouponDeactivateParams,
	IDiscountCouponInsertParams,
	IDiscountCouponSearchParams,
} from '../../models/serviceParams/DiscountCouponParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { DiscountCouponEndPoint } from '../endPoints/DiscountCouponEndPoints';

/**
 * Search discount coupons
 */
export const discountCouponServiceSearch = (
	model: IDiscountCouponSearchParams,
): Promise<ServiceResponse<IDiscountCoupon[]>> => {
	return Api.get<IDiscountCoupon[], IDiscountCouponSearchParams>(DiscountCouponEndPoint.SEARCH, model);
};

/**
 * Insert discount coupons
 */
export const discountCouponServiceInsert = (
	model: IDiscountCouponInsertParams,
): Promise<ServiceResponse<IDiscountCoupon[]>> => {
	return Api.post<IDiscountCoupon[], IDiscountCouponInsertParams>(DiscountCouponEndPoint.INSERT, model);
};

/**
 * Deactivate discount coupons
 * @param ids number[]
 * @returns IDiscountCoupon[]
 */
export const discountCouponServiceDeactivate = (
	model: IDiscountCouponDeactivateParams,
): Promise<ServiceResponse<IDiscountCoupon[]>> => {
	return Api.post<IDiscountCoupon[], IDiscountCouponDeactivateParams>(DiscountCouponEndPoint.DEACTIVATE, model);
};
