import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Confirmation = lazyWithRetry(() => import('./pages/Confirmation'));

export const confirmationRoutes: { [key: number]: IRouteConfig } = {};

confirmationRoutes[routesIdMap.BookingsNewConfirmation] = {
	component: Confirmation,
	exact: true,
	id: routesIdMap.BookingsNewConfirmation,
	name: 'Booking confirmation',
	path: '/booking/new/confirmation',
	permissions: [PermissionsType.BookingNew],
	icon: 'file-o',
};
