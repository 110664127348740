import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { bookingDetailClear, bookingGetPendings, bookingPendingClear } from '@crac/core/redux/actions/BookingActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IOpenByBranchReducerState } from '@crac/core/redux-store/reducersState/booking/OpenByBranchReducerState';
const initialState: IOpenByBranchReducerState = {
	bookingsOpenByBranch: [],
	bookingsOpenByBranchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const openedBookingsSlice = createSlice({
	name: 'BookinOpenByBranchSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// BOOKING_PENDING
		builder
			.addCase(bookingGetPendings.pending, (state) => {
				return {
					...state,
					bookingsOpenByBranchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingGetPendings.fulfilled, (state, action) => {
				return {
					...state,
					bookingsOpenByBranch: deepCopy(action.payload),
					bookingsOpenByBranchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingGetPendings.rejected, (state, action) => {
				return {
					...state,
					bookingsOpenByBranchRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// PENDING CLEAR
		builder.addCase(bookingPendingClear, (state) => {
			return {
				...state,
				bookingsOpenByBranch: [],
			};
		});

		/**
		 * CLEAR
		 * CLEAR CACHE
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, bookingDetailClear), () => {
			return initialState;
		});
	},
});

export const openedBookingsReducer = openedBookingsSlice.reducer;
