import { availabilityConfigRoutes } from './availabilityConfig/routes';
import { branchScheduleRoutes } from './branchSchedule/routes';
import { choosableServiceConfigurationRoutes } from './choosableServiceConfiguration/routes';
import { couponConfigurationRoutes } from './couponConfiguration/routes';
import { dashboardRoutes } from './dashboard/routes';
import { depositRateRoutes } from './depositRate/routes';
import { discountCouponRoutes } from './discountCoupon/routes';
import { includedServiceConfigurationRoutes } from './includedServiceConfiguration/routes';
import { invoiceableServiceConfigurationRoutes } from './invoiceableServiceConfiguration/routes';
import { kmRateRoutes } from './kmRate/routes';
import { maximumBookingDurationRoutes } from './maximumBookingDuration/routes';
import { maximumExcessRateRoutes } from './maximumExcessRate/routes';
import { notAllowedMovementRoutes } from './notAllowedMovement/routes';
import { occupancyRoutes } from './occupancy/routes';
import { oneWayRoutes } from './oneWay/routes';
import { packageConfigurationRoutes } from './packageConfiguration/routes';
import { partnerCategoryRoutes } from './partnerCategory/routes';
import { partnerGroupRoutes } from './partnerGroup/routes';
import { preauthorizationRateRoutes } from './preauthorizationRate/routes';
import { priceViewerRoutes } from './priceViewer/routes';
import { rentRateRoutes } from './rentRate/routes';
import { rentRateFormulaRoutes } from './rentRateFormula/routes';
import { rentRateMarginRoutes } from './rentRateMargin/routes';
import { rentRateTypeRoutes } from './rentRateType/routes';
import { rentRateTypeRelationRoutes } from './rentRateTypeRelation/routes';
import { rentRateValidationRoutes } from './rentRateValidation/routes';
import { rentRateVariationRoutes } from './rentRateVariation/routes';
import { serviceRateRoutes } from './serviceRate/routes';
import { serviceRateTypeRoutes } from './serviceRateType/routes';
import { stopSalesRoutes } from './stopSales/routes';
import { vehicleGroupIncludedConfigurationRoutes } from './vehicleGroupIncludedConfiguration/routes';
import { vehicleGroupOrderAndUpgradeRoutes } from './vehicleGroupOrderAndUpgrade/routes';

export const pricingRoutes = {
	...availabilityConfigRoutes,
	...branchScheduleRoutes,
	...choosableServiceConfigurationRoutes,
	...couponConfigurationRoutes,
	...dashboardRoutes,
	...depositRateRoutes,
	...discountCouponRoutes,
	...includedServiceConfigurationRoutes,
	...invoiceableServiceConfigurationRoutes,
	...kmRateRoutes,
	...maximumBookingDurationRoutes,
	...maximumExcessRateRoutes,
	...notAllowedMovementRoutes,
	...occupancyRoutes,
	...oneWayRoutes,
	...packageConfigurationRoutes,
	...partnerCategoryRoutes,
	...partnerGroupRoutes,
	...preauthorizationRateRoutes,
	...priceViewerRoutes,
	...rentRateFormulaRoutes,
	...rentRateMarginRoutes,
	...rentRateRoutes,
	...rentRateTypeRelationRoutes,
	...rentRateTypeRoutes,
	...rentRateValidationRoutes,
	...rentRateVariationRoutes,
	...serviceRateRoutes,
	...serviceRateTypeRoutes,
	...stopSalesRoutes,
	...vehicleGroupIncludedConfigurationRoutes,
	...vehicleGroupOrderAndUpgradeRoutes,
};
