/* eslint-disable default-param-last */
import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { employeeLoginAndLoadCachedData } from '@crac/core/redux/actions/EmployeeActions';
import { repairTypeGetAll } from '@crac/core/redux/actions/RepairTypeActions';
import type { IRepairTypeReducerState } from '@crac/core/redux-store/reducersState/repairType';

const repairTypeStorageKey = 'repairTypes';
const { getItem, setItem, removeItem } = managerLocalStorage();

const initialState: IRepairTypeReducerState = {
	repairTypes: getItem(repairTypeStorageKey),
	repairTypesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const repairTypeSlice = createSlice({
	name: 'RepairTypeSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, (state) => {
			removeItem(repairTypeStorageKey);
			return {
				...state,
				repairTypes: [],
			};
		});
		// GET ALL
		builder
			.addCase(repairTypeGetAll.pending, (state) => {
				return {
					...state,
					repairTypesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(repairTypeGetAll.fulfilled, (state, action) => {
				setItem(repairTypeStorageKey, action.payload);
				return {
					...state,
					repairTypes: deepCopy(action.payload),
					repairTypesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(repairTypeGetAll.rejected, (state, action) => {
				return {
					...state,
					repairTypesRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			})
			.addCase(employeeLoginAndLoadCachedData.fulfilled, (state, action) => {
				setItem(repairTypeStorageKey, action.payload.repairTypes);
				return {
					...state,
					repairTypes: deepCopy(action.payload.repairTypes),
				};
			});
	},
});

export const repairTypesReducer = repairTypeSlice.reducer;
