import type { IVehicleGroupAvailability } from '../../models/entities/VehicleGroupAvailability';
import type { IVehicleGroupsPrice } from '../../models/entities/VehicleGroupPrice';
import type {
	IVehicleGroupGetAvailabilityParams,
	IVehicleGroupGetPriceParams,
} from '../../models/serviceParams/VehicleGroupAvailabilityParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { VehicleGroupAvailabilityEndPoints } from '../endPoints/VehicleGroupAvailabilityEndPoints';

/**
 * Get group availability
 */

export const vehicleGroupGetAvailabilityService = (
	model: IVehicleGroupGetAvailabilityParams,
): Promise<ServiceResponse<IVehicleGroupAvailability[]>> => {
	return Api.get<IVehicleGroupAvailability[], IVehicleGroupGetAvailabilityParams>(
		VehicleGroupAvailabilityEndPoints.GET_AVAILABILITY,
		model,
	);
};

/**
 * Get groups price
 */

export const vehicleGroupGetPriceService = (
	model: IVehicleGroupGetPriceParams,
): Promise<ServiceResponse<IVehicleGroupsPrice>> => {
	return Api.get<IVehicleGroupsPrice, IVehicleGroupGetPriceParams>(
		VehicleGroupAvailabilityEndPoints.GET_PRICE,
		model,
	);
};
