import { accidentsRoutes } from './accidents/routes';
import { bookingsRoutes } from './bookings/routes';
import { damageRepairRoutes } from './damageRepair/routes';
import { damagesRoutes } from './damages/routes';
import { detailRoutes as detailDetailRoutes } from './detail/routes';
import { documentsRoutes } from './documents/routes';
import { expedientsRoutes } from './expedients/routes';
import { finesRoutes } from './fines/routes';
import { historyRoutes } from './history/routes';
import { insurancesRoutes } from './insurances/routes';
import { revisionCostRoutes } from './revisionCost/routes';
import { tollsRoutes } from './tolls/routes';

export const detailRoutes = {
	...accidentsRoutes,
	...bookingsRoutes,
	...damageRepairRoutes,
	...damagesRoutes,
	...detailDetailRoutes,
	...documentsRoutes,
	...expedientsRoutes,
	...finesRoutes,
	...historyRoutes,
	...insurancesRoutes,
	...revisionCostRoutes,
	...tollsRoutes,
};
