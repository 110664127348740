import { useMemo } from 'react';

import type { Action, Dispatch } from '@reduxjs/toolkit';
import { bindActionCreators } from '@reduxjs/toolkit';

import { useAppDispatch } from '~/redux/hooks';

const bindActions = (dispatch: Dispatch<Action>, actionsToBind: any) => bindActionCreators(actionsToBind, dispatch);

/**
 * ### bindActionCreators + useMemo
 * It binds actions with bindActionCreators and memoizes them so they can be included in useEffect dependencies.
 * @param actionsToBind Actions to be bound and memoized.
 * @returns Actions bound and memoized. Type can be specified.
 */
export const useBindAndMemoizeActions = <T>(actionsToBind: T) => {
	const dispatch = useAppDispatch();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const actions = useMemo(() => bindActions(dispatch, actionsToBind), []);

	return actions as T;
};
