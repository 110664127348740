import { createSlice } from '@reduxjs/toolkit';

import { revisionLineClear, revisionLineGetByBudget } from '@crac/core/redux/actions/WorkshopRevisionLineActions';
import type { IRevisionLineReducerState } from '@crac/core/redux-store/reducersState/workshop/RevisionLineReducerState';

const initialState: IRevisionLineReducerState = {
	revisionLines: [],
	revisionLineGetByBudgetRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const revisionLineSlice = createSlice({
	name: 'revisionLineSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY BUDGET
		builder
			.addCase(revisionLineGetByBudget.pending, (state) => {
				return {
					...state,
					revisionLineGetByBudgetRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(revisionLineGetByBudget.fulfilled, (state, action) => {
				return {
					...state,
					revisionLineGetByBudgetRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					revisionLines: action.payload,
				};
			})
			.addCase(revisionLineGetByBudget.rejected, (state, action) => {
				return {
					...state,
					revisionLineGetByBudgetRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(revisionLineClear, (state) => {
			return {
				...state,
				revisionLines: [],
			};
		});
	},
});

export const revisionLineReducer = revisionLineSlice.reducer;
