import { createAction } from '@reduxjs/toolkit';

import {
	serviceIncludedServiceConfigurationDeactivate,
	serviceIncludedServiceConfigurationInsert,
	serviceIncludedServiceConfigurationModify,
	serviceIncludedServiceConfigurationSearch,
} from '../../data/services/IncludedServiceConfigurationServices';
import type { IIncludedServiceConfiguration } from '../../models/entities/IncludedServiceConfiguration';
import type {
	IIncludedServiceConfigurationDeactivateParams,
	IIncludedServiceConfigurationInsertParams,
	IIncludedServiceConfigurationModifyParams,
	IIncludedServiceConfigurationSearchParams,
} from '../../models/serviceParams/IncludedServiceConfigurationParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for included service configurations based on specified parameters.
 *
 * @param {IIncludedServiceConfiguration[]} payload - The included service configurations to be returned.
 * @param {IIncludedServiceConfigurationSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of included service configurations.
 */
export const includedServiceConfigurationSearch = createAsyncAction<
	IIncludedServiceConfiguration[],
	IIncludedServiceConfigurationSearchParams
>('includedServiceConfiguration/search', serviceIncludedServiceConfigurationSearch);

/**
 * Inserts new included service configurations into the system.
 *
 * @param {IIncludedServiceConfiguration[]} payload - The included service configurations to be inserted.
 * @param {IIncludedServiceConfigurationInsertParams} params - Parameters detailing the included service configurations to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the included service configurations are successfully inserted.
 */
export const includedServiceConfigurationInsert = createAsyncAction<
	IIncludedServiceConfiguration[],
	IIncludedServiceConfigurationInsertParams
>('includedServiceConfiguration/insert', serviceIncludedServiceConfigurationInsert);

/**
 * Modifies an existing included service configuration.
 *
 * @param {IIncludedServiceConfiguration} payload - The included service configuration to be modified.
 * @param {IIncludedServiceConfigurationModifyParams} params - Parameters for the modification.
 * @returns {Promise<void>} - A promise that resolves after the included service configuration is modified.
 */
export const includedServiceConfigurationModify = createAsyncAction<
	IIncludedServiceConfiguration,
	IIncludedServiceConfigurationModifyParams
>('includedServiceConfiguration/modify', serviceIncludedServiceConfigurationModify);

/**
 * Removes deactivated included service configurations from the state.
 *
 * @param {number} payload - The ID of the included service configuration to be removed.
 */
export const includedServiceConfigurationRemoveDeactivated = createAction<number>(
	'includedServiceConfiguration/removeDeactivated',
);

/**
 * Deactivates included service configurations.
 *
 * @param {IIncludedServiceConfiguration[]} payload - The included service configurations to be deactivated.
 * @param {IIncludedServiceConfigurationDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the included service configurations are deactivated.
 */
export const includedServiceConfigurationDeactivate = createAsyncAction<
	IIncludedServiceConfiguration[],
	IIncludedServiceConfigurationDeactivateParams
>('includedServiceConfiguration/deactivate', serviceIncludedServiceConfigurationDeactivate);

/**
 * Clears the included service configuration state.
 */
export const includedServiceConfigurationClear = createAction('includedServiceConfiguration/clear');
