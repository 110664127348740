import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { IComputer } from '@crac/core/models/entities/Computer';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	computerGetByBranch,
	computerGetByDeviceIp,
	computerGetByDeviceName,
	computerSetData,
	setHostName as computerSetHostName,
} from '@crac/core/redux/actions/ComputerActions';
import type { IComputerReducerState } from '@crac/core/redux-store/reducersState/computer';

import { EMPLOYEE_HOSTNAME_KEY } from '~/constants/LocalStorageKey';

export const computersStorageKey = 'computers';
const { getItem, setItem, removeItem } = managerLocalStorage();

const getHostName = () => localStorage.getItem(EMPLOYEE_HOSTNAME_KEY) || undefined;
const setHostName = (hostname: string) => {
	localStorage.setItem(EMPLOYEE_HOSTNAME_KEY, hostname);
	return hostname;
};

const initialState: IComputerReducerState = {
	computerRequest: {
		data: null,
		inProgress: false,
		messages: [],
		ok: true,
	},
	computersRequest: {
		data: (getItem(computersStorageKey) as IComputer[]) || [],
		inProgress: false,
		messages: [],
		ok: true,
	},
	hostname: getHostName(),
	setData: {
		data: null,
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const computerSlice = createSlice({
	name: 'ComputerSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, (state) => {
			removeItem(computersStorageKey);
			return {
				...state,
				computersRequest: {
					data: [],
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});
		// GET BY BRANCH
		builder
			.addCase(computerGetByBranch.pending, (state) => {
				return {
					...state,
					computersRequest: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(computerGetByBranch.fulfilled, (state, action) => {
				setItem(computersStorageKey, action.payload);
				return {
					...state,
					computersRequest: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(computerGetByBranch.rejected, (state, action) => {
				return {
					...state,
					computersRequest: {
						data: [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SET DATA
		builder
			.addCase(computerSetData.pending, (state) => {
				return {
					...state,
					setData: {
						data: null,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(computerSetData.fulfilled, (state, action) => {
				setItem(computersStorageKey, action.payload);
				return {
					...state,
					setData: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(computerSetData.rejected, (state, action) => {
				return {
					...state,
					setData: {
						data: null,
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY DEVICE IP
		builder
			.addCase(computerGetByDeviceIp.pending, (state) => {
				return {
					...state,
					computerRequest: {
						data: null,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(computerGetByDeviceIp.fulfilled, (state, action) => {
				return {
					...state,
					computerRequest: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(computerGetByDeviceIp.rejected, (state, action) => {
				return {
					...state,
					computerRequest: {
						data: state.computerRequest.data,
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY DEVICE NAME
		builder
			.addCase(computerGetByDeviceName.pending, (state) => {
				return {
					...state,
					computerRequest: {
						data: null,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(computerGetByDeviceName.fulfilled, (state, action) => {
				return {
					...state,
					computerRequest: {
						data: action.payload,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(computerGetByDeviceName.rejected, (state, action) => {
				return {
					...state,
					computerRequest: {
						data: state.computerRequest.data,
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SET HOSTNAME
		builder.addCase(computerSetHostName, (state, action) => {
			return {
				...state,
				hostname: setHostName(action.payload),
			};
		});
	},
});

export const computerReducer = computerSlice.reducer;
