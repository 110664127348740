import { paymentTermServiceGetAll } from '../../data/services/PaymentTermService';
import type { IPaymentTerm } from '../../models/entities/PaymentTerm';
import type { IPaymentTermGetAllParams } from '../../models/serviceParams/PaymentTermParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Get all payment terms.
 *
 * @param {IPaymentTerm[]} payload - The payment terms to be returned.
 * @param {IPaymentTermGetAllParams} params - Parameters detailing the payment terms to be returned.
 * @returns {Promise<void>} - A promise that resolves to an array of payment terms.
 */
export const paymentTermGetAll = createAsyncAction<IPaymentTerm[], IPaymentTermGetAllParams>(
	'paymentTerm/getAll',
	paymentTermServiceGetAll,
);
