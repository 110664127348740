'use client';
import type { FC } from 'react';
import React from 'react';

import { FormGroup as RSFormGroup } from 'reactstrap';
import type { FormGroupProps as RSFormGroupProps } from 'reactstrap/types/lib/FormGroup';

export type FormGroupProps = RSFormGroupProps;

export const FormGroup: FC<FormGroupProps> = ({ children, ...props }) => (
	<RSFormGroup {...props}>{children}</RSFormGroup>
);
