import type { ISaleOrder } from '../../models/entities/SaleOrder';
import type { ISaleOrderLine } from '../../models/entities/SaleOrderLine';
import type {
	ISaleOrderGetByIdParams,
	ISaleOrderInsertParams,
	ISaleOrderLineDeleteParams,
	ISaleOrderLineGetByOrderIdParams,
	ISaleOrderLineInsertParams,
	ISaleOrderSearchParams,
} from '../../models/serviceParams/FleetSaleOrderParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { SaleOrderEndPoints } from '../endPoints/SaleOrderEndPoints';
import { SaleOrderLineEndPoints } from '../endPoints/SaleOrderLineEndPoints';

/**
 * Inserts a new vehicle sale order.
 * @param params - Parameters for the insertion of the sale order.
 * @returns A promise that resolves to the inserted sale order.
 */
export const saleOrderServiceInsert = (params: ISaleOrderInsertParams): Promise<ServiceResponse<ISaleOrder>> => {
	return Api.post<ISaleOrder, ISaleOrderInsertParams>(SaleOrderEndPoints.INSERT, params);
};

export const saleOrderServiceSearch = (params: ISaleOrderSearchParams): Promise<ServiceResponse<ISaleOrder[]>> => {
	return Api.get<ISaleOrder[], ISaleOrderSearchParams>(SaleOrderEndPoints.SEARCH, params);
};

export const saleOrderLineServiceGetByOrderId = (
	params: ISaleOrderLineGetByOrderIdParams,
): Promise<ServiceResponse<ISaleOrderLine[]>> => {
	return Api.get<ISaleOrderLine[], ISaleOrderLineGetByOrderIdParams>(SaleOrderLineEndPoints.GET_BY_ORDER_ID, params);
};

export const saleOrderLineServiceInsert = (
	params: ISaleOrderLineInsertParams,
): Promise<ServiceResponse<ISaleOrderLine[]>> => {
	return Api.post<ISaleOrderLine[], ISaleOrderLineInsertParams>(SaleOrderLineEndPoints.INSERT, params);
};

export const saleOrderLineServiceDelete = (params: ISaleOrderLineDeleteParams): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ISaleOrderLineDeleteParams>(SaleOrderLineEndPoints.DELETE, params);
};

export const saleOrderServiceGetById = (params: ISaleOrderGetByIdParams): Promise<ServiceResponse<ISaleOrder>> => {
	return Api.get<ISaleOrder, ISaleOrderGetByIdParams>(SaleOrderEndPoints.GET_BY_ID, params);
};
