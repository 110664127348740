import type { isRequiredDefaultParamsType } from './core/isRequiredDefaultParamsType';

/**
 * Returns a message if input is required.
 * @param customErrorMessage Error message to be displayed
 * @param defaultInputValue Input default value. Must be provided when the input is a checkbox.
 */

export const isRequired =
	(customErrorMessage?: string, defaultInputValue?: isRequiredDefaultParamsType) =>
	(inputValue?: isRequiredDefaultParamsType): string | undefined => {
		if (defaultInputValue !== undefined && inputValue === defaultInputValue) {
			return customErrorMessage ? (customErrorMessage as string) : 'Required';
		}
		if (inputValue === undefined) {
			return customErrorMessage ? (customErrorMessage as string) : 'Required';
		}
		return undefined;
	};
