import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Preparation = lazyWithRetry(() => import('./pages/Preparation'));

export const preparationRoutes: { [key: number]: IRouteConfig } = {};

preparationRoutes[routesIdMap.VehiclesPreparation] = {
	component: Preparation,
	exact: true,
	id: routesIdMap.VehiclesPreparation,
	name: 'Vehicle preparation',
	path: '/vehicle/preparation',
	permissions: [PermissionsType.VehiclePreparation],
	icon: 'check-square-o',
	menuText: 'Preparation',
};
