const defaultDecimalPoint = 2;

/**
 * Rounded decimal number to number decimal points pased
 *
 * @param value value to rounded
 * @param decimals Number decimal point
 */
export const round = (value: number, decimals = defaultDecimalPoint): number => {
	if (`${value}`.includes('e')) {
		return Number(Math.round(value));
	}

	return Number(`${Math.round(Number(`${value}e${decimals}`))}e-${decimals}`);
};

/**
 * Sum values from list of object, based in one property
 *
 * By default result is rounded to 2 digict
 *
 * @param list List of object
 * @param prop prop using to add
 * @example
 * const list = [{ amount: 25.2555 }, { amount: 2 }];
 * const result = sumAmounts(list, 'amount')
 * // result = 27.26
 */
export const sumAmounts = (list: Array<any>, prop: string): number => {
	if (!list || list.length === 0) {
		return 0;
	}

	const numbers: Array<number> = list.map((item) => item[prop]);
	return round(numbers.reduce((accumulator, value) => accumulator + value));
};

/**
 * List number by range
 * @param start Start number
 * @param end End number
 * @param step Step to increment
 */
export const numberRange = (start: number, end: number, step = 1): number[] => {
	const len = Math.floor((end - start) / step) + 1;
	return Array(Math.abs(len))
		.fill('')
		.map((num: any, idx: number) => start + idx);
};
