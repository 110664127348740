export enum SaleOrderLineEndPoints {
	/**
	 * Insert a new vehicle sale order line
	 * @host `/saleOrderLine/insert`
	 */
	INSERT = '/saleOrderLine/insert/',

	/**
	 * Delete a vehicle sale order line
	 * @host `/saleOrderLine/delete`
	 */
	DELETE = '/saleOrderLine/delete/',

	/**
	 * Search for vehicle sale order getByOrderId
	 * @host `/saleOrderLine/getByOrderId`
	 */
	GET_BY_ORDER_ID = '/saleOrderLine/getByOrderId/',
}
