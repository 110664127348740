import {
	callLogServiceConnectedCallStatusAdd,
	callLogServiceIncomingCallStatusAdd,
} from '../../data/services/CallLogService';
import type { IConnectedCallStatus, IIncomingCallStatus } from '../../models/entities/CallLogs';
import type {
	IConnectedCallStatusAddParams,
	IIncomingCallStatusAddParams,
} from '../../models/serviceParams/CallLogParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Adds a status for a connected call to the call log.
 *
 * @param {IConnectedCallStatus} payload - The connected call status to be added.
 * @param {IConnectedCallStatusAddParams} params - Parameters detailing the connected call status.
 * @returns {Promise<void>} - A promise that resolves after the connected call status is added to the log.
 */
export const connectedCallStatusAdd = createAsyncAction<IConnectedCallStatus, IConnectedCallStatusAddParams>(
	'connectedCall/statusAdd',
	callLogServiceConnectedCallStatusAdd,
);

/**
 * Adds a status for an incoming call to the call log.
 *
 * @param {IIncomingCallStatus} payload - The incoming call status to be added.
 * @param {IIncomingCallStatusAddParams} params - Parameters detailing the incoming call status.
 * @returns {Promise<void>} - A promise that resolves after the incoming call status is added to the log.
 */
export const incomingCallStatusAdd = createAsyncAction<IIncomingCallStatus, IIncomingCallStatusAddParams>(
	'incomingCall/statusAdd',
	callLogServiceIncomingCallStatusAdd,
);
