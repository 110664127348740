import { createAction } from '@reduxjs/toolkit';

import {
	ticketManagementServiceAddComment,
	ticketManagementServiceClose,
	ticketManagementServiceGetByBooking,
	ticketManagementServiceGetByBranch,
	ticketManagementServiceGetByCustomer,
	ticketManagementServiceNew,
} from '../../data/services/TicketManagementService';
import type { ITicket, ITicketComment } from '../../models/entities/Ticket';
import type {
	ITicketManagementAddCommentParams,
	ITicketManagementCloseParams,
	ITicketManagementGetByBookingParams,
	ITicketManagementGetByBranchParams,
	ITicketManagementGetByCustomerParams,
	ITicketManagementNewParams,
} from '../../models/serviceParams/TicketManagementParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves tickets associated with a specific booking.
 *
 * @param {ITicket[]} payload - The tickets to be retrieved.
 * @param {ITicketManagementGetByBookingParams} params - Parameters for identifying tickets related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of tickets.
 */
export const ticketManagementGetByBooking = createAsyncAction<ITicket[], ITicketManagementGetByBookingParams>(
	'ticketManagement/getByBooking',
	ticketManagementServiceGetByBooking,
);

/**
 * Retrieves tickets associated with a specific customer.
 *
 * @param {ITicket[]} payload - The tickets to be retrieved.
 * @param {ITicketManagementGetByCustomerParams} params - Parameters for identifying tickets related to a specific customer.
 * @returns {Promise<void>} - A promise that resolves to an array of tickets.
 */
export const ticketManagementGetByCustomer = createAsyncAction<ITicket[], ITicketManagementGetByCustomerParams>(
	'ticketManagement/getByCustomer',
	ticketManagementServiceGetByCustomer,
);

/**
 * Creates a new ticket.
 *
 * @param {ITicket} payload - The new ticket to be created.
 * @param {ITicketManagementNewParams} params - Parameters for creating the new ticket.
 * @returns {Promise<void>} - A promise that resolves after the new ticket is created.
 */
export const ticketManagementNew = createAsyncAction<ITicket, ITicketManagementNewParams>(
	'ticketManagement/new',
	ticketManagementServiceNew,
);

/**
 * Adds a comment to a ticket.
 *
 * @param {ITicketComment} payload - The ticket comment to be added.
 * @param {ITicketManagementAddCommentParams} params - Parameters for adding the comment to the ticket.
 * @returns {Promise<void>} - A promise that resolves after the comment is added to the ticket.
 */
export const ticketManagementAddComment = createAsyncAction<ITicketComment, ITicketManagementAddCommentParams>(
	'ticketManagement/addComment',
	ticketManagementServiceAddComment,
);

/**
 * Retrieves tickets associated with a specific branch.
 *
 * @param {ITicket[]} payload - The tickets to be retrieved.
 * @param {ITicketManagementGetByBranchParams} params - Parameters for identifying tickets related to a specific branch.
 * @returns {Promise<void>} - A promise that resolves to an array of tickets.
 */
export const ticketManagementGetByBranch = createAsyncAction<ITicket[], ITicketManagementGetByBranchParams>(
	'ticketManagement/getByBranch',
	ticketManagementServiceGetByBranch,
);

/**
 * Closes a ticket.
 *
 * @param {ITicket} payload - The ticket to be closed.
 * @param {ITicketManagementCloseParams} params - Parameters for closing the ticket.
 * @returns {Promise<void>} - A promise that resolves after the ticket is closed.
 */
export const ticketManagementClose = createAsyncAction<ITicket, ITicketManagementCloseParams>(
	'ticketManagement/close',
	ticketManagementServiceClose,
);

/**
 * Clears the ticket management state.
 */
export const ticketManagementClear = createAction('ticketManagement/clear');
