import {
	insuranceServiceGetByPlateNumber,
	insuranceServiceSendRegistrationRequest,
} from '../../data/services/InsuranceService';
import type { IInsurance } from '../../models/entities/Insurance';
import type {
	IInsuranceGetByPlateNumberParams,
	IInsuranceSendRegistrationRequestParams,
} from '../../models/serviceParams/InsuranceParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Insurance getByPlateNumber
 *
 * @param {IInsurance[]} payload - The payload to pass to the reducer.
 * @param {IInsuranceGetByPlateNumberParams} params - The parameters to pass to the service.
 * @returns {Promise<void>} - A promise that resolves when the search operation is complete.
 */

export const insuranceGetByPlateNumber = createAsyncAction<IInsurance[], IInsuranceGetByPlateNumberParams>(
	'insurance/getByPlateNumber',
	insuranceServiceGetByPlateNumber,
);

/**
 * Insurance request registration
 * @param {boolean} payload - The payload to pass to the reducer.
 * @param {IInsuranceInsertParams} params - The parameters to pass to the service.
 * @returns {Promise<void>} - A promise that resolves when the insert operation is complete.
 */
export const insuranceSendRegistrationRequest = createAsyncAction<boolean, IInsuranceSendRegistrationRequestParams>(
	'insurance/sendRegistrationRequest',
	insuranceServiceSendRegistrationRequest,
);
