import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const VehicleGroupOrderAndUpgrade = lazyWithRetry(() => import('./pages/VehicleGroupOrderAndUpgrade'));
const VehicleGroupOrderAndUpgradeNew = lazyWithRetry(() => import('./pages/VehicleGroupOrderAndUpgradeNew'));

export const vehicleGroupOrderAndUpgradeRoutes: { [key: number]: IRouteConfig } = {};

// GROUP UPGRADES
vehicleGroupOrderAndUpgradeRoutes[routesIdMap.VehicleGroupOrderAndUpgrade] = {
	component: VehicleGroupOrderAndUpgrade,
	exact: true,
	id: routesIdMap.VehicleGroupOrderAndUpgrade,
	name: 'Group upgrades',
	path: '/pricing/vehicle-group-order-and-upgrade',
	permissions: getPricingPermission([PermissionsType.PricingVehicleGroupOrderAndUpgrade]),
	childrens: [routesIdMap.VehicleGroupOrderAndUpgradeNew],
	icon: 'sort-amount-asc',
	menuText: 'Group upgrades',
};

vehicleGroupOrderAndUpgradeRoutes[routesIdMap.VehicleGroupOrderAndUpgradeNew] = {
	component: VehicleGroupOrderAndUpgradeNew,
	exact: true,
	isChild: true,
	id: routesIdMap.VehicleGroupOrderAndUpgradeNew,
	name: 'New group upgrade',
	path: '/pricing/vehicle-group-order-and-upgrade/new',
	permissions: getPricingPermission([PermissionsType.PricingVehicleGroupOrderAndUpgrade]),
	menuText: 'New group upgrade',
	icon: 'plus',
};
