import { dashboardRoutes } from './dashboard/routes';
import { detailRoutes } from './detail/routes';
import { keyChainsLabelsRoutes } from './keyChainsLabels/routes';
import { lockersRoutes } from './lockers/routes';
import { preparationRoutes } from './preparation/routes';
import { receptionRoutes } from './reception/routes';
import { returnRoutes } from './return/routes';
import { revisionRoutes } from './revision/routes';
import { searchRoutes } from './search/routes';

export const vehicleRoutes = {
	...dashboardRoutes,
	...detailRoutes,
	...keyChainsLabelsRoutes,
	...lockersRoutes,
	...preparationRoutes,
	...receptionRoutes,
	...returnRoutes,
	...revisionRoutes,
	...searchRoutes,
};
