import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const SaleServiceRate = lazyWithRetry(() => import('./pages/SaleServiceRate'));
const SaleServiceRateNew = lazyWithRetry(() => import('./pages/SaleServiceRateNew'));

export const saleServiceRateRoutes: { [key: number]: IRouteConfig } = {};

saleServiceRateRoutes[routesIdMap.FleetSaleServiceRate] = {
	component: SaleServiceRate,
	exact: true,
	id: routesIdMap.FleetSaleServiceRate,
	name: 'Sale service rates',
	menuText: 'Sale service rates',
	path: '/fleet/sale-service-rates',
	permissions: [PermissionsType.SaleService],
	childrens: [routesIdMap.FleetSaleServiceRateNew],
	icon: 'percent',
};

saleServiceRateRoutes[routesIdMap.FleetSaleServiceRateNew] = {
	component: SaleServiceRateNew,
	exact: true,
	id: routesIdMap.FleetSaleServiceRateNew,
	name: 'New sale service rate',
	menuText: 'New sale service rate',
	path: '/fleet/sale-service-rates/new',
	permissions: [PermissionsType.SaleService],
	childrens: [],
	icon: 'plus',
	isChild: true,
};
