export enum SpendingEndPoints {
	/**
	 * Spending get by code
	 * @host `/spending/getbycode/`
	 */
	GET_BY_CODE = '/spending/getbycode/',

	/**
	 * Spending get by cash line
	 * @host `/spending/getbycashline/`
	 */
	GET_BY_CASH_LINE = '/spending/getbycashline/',

	/**
	 * Spending delete
	 * @host `/spending/delete/`
	 */
	DELETE = '/spending/delete/',

	/**
	 * Spending add
	 * @host `/spending/add/`
	 */
	ADD = '/spending/add/',
}
