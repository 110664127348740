import type { IVehicleLine } from '../../models/entities/VehicleLine';
import type {
	IVehicleLineGetByBookingParams,
	IVehicleLineGetByVehicleParams,
	IVehicleLineGetExternalRepairsByVehicleParams,
	IVehicleLineGetLastByVehicleParams,
} from '../../models/serviceParams/VehicleLineParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { VehicleEndPoints } from '../endPoints/VehicleEndPoints';

/**
 * Get lines vehicle by booking
 * @param params `IVehicleLineGetByBookingParams`
 * @return `Promise<IVehicleLine[]>` Vehicle line list
 */
export const vehicleLineServiceGetByBooking = (
	params: IVehicleLineGetByBookingParams,
): Promise<ServiceResponse<IVehicleLine[]>> => {
	return Api.get<IVehicleLine[], IVehicleLineGetByBookingParams>(
		VehicleEndPoints.GET_VEHICLE_LINES_BY_BOOKING,
		params,
	);
};

/**
 * Get vehicle lines by vehicle
 * @param params `IVehicleLineGetByVehicleParams`
 * @return `Promise<IVehicleLine[]>` Vehicle line list
 */
export const vehicleLineServiceGetByVehicle = (
	params: IVehicleLineGetByVehicleParams,
): Promise<ServiceResponse<IVehicleLine[]>> => {
	return Api.get<IVehicleLine[], IVehicleLineGetByVehicleParams>(
		VehicleEndPoints.GET_VEHICLE_LINES_BY_VEHICLE,
		params,
	);
};

/**
 * Get last vehicle lines by vehicle
 * @param params `IVehicleLineServiceGetLastByVehicleParams`
 * @return `Promise<IVehicleLine>` Vehicle object
 */
export const vehicleLineServiceGetLastByVehicle = (
	params: IVehicleLineGetLastByVehicleParams,
): Promise<ServiceResponse<IVehicleLine>> => {
	return Api.get<IVehicleLine, IVehicleLineGetLastByVehicleParams>(
		VehicleEndPoints.GET_LAST_VEHICLE_LINE_BY_VEHICLE,
		params,
	);
};

/**
 * Get movements from vehicle plateNumber
 * @param params `IVehicleLineGetExternalRepairsByVehicleParams`
 * @returns `Promise<IVehicleLine[]>` Vehicle line list
 */
export const vehicleLineServiceGetExternalRepairsByVehicle = (
	params: IVehicleLineGetExternalRepairsByVehicleParams,
): Promise<ServiceResponse<IVehicleLine[]>> => {
	return Api.get<IVehicleLine[], IVehicleLineGetExternalRepairsByVehicleParams>(
		VehicleEndPoints.GET_EXTERNAL_REPAIRS_BY_VEHICLE,
		params,
	);
};
