import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	paymentLineClear,
	paymentLineSearch,
	paymentLineUpdateChargeBack,
} from '@crac/core/redux/actions/PaymentLineActions';
import type { IPaymentLineReducerState } from '@crac/core/redux-store/reducersState/paymentLine';

const initialState: IPaymentLineReducerState = {
	paymentLines: [],
	paymentLinesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const paymentLineSlice = createSlice({
	name: 'PaymentLineSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// PAYMENT LINE SEARCH
		builder
			.addCase(paymentLineSearch.pending, (state) => {
				return {
					...state,
					paymentLines: [],
					paymentLinesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentLineSearch.fulfilled, (state, action) => {
				return {
					...state,
					paymentLines: deepCopy(action.payload),
					paymentLinesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentLineSearch.rejected, (state, action) => {
				return {
					...state,
					paymentLinesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// UPDATE CHARGE BACK
		builder.addCase(paymentLineUpdateChargeBack.fulfilled, (state, action) => {
			return {
				...state,
				paymentLines: [
					...state.paymentLines.filter(
						(line) =>
							line.number !== action.payload.number &&
							line.bookingNumber !== action.payload.bookingNumber,
					),
					...state.paymentLines.filter(
						(line) =>
							line.number !== action.payload.number &&
							line.bookingNumber === action.payload.bookingNumber,
					),
					action.payload,
				],
			};
		});
		/**
		 * CLEAR CACHE
		 * CLEAR
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, paymentLineClear), () => {
			return initialState;
		});
	},
});

export const paymentLineReducer = paymentLineSlice.reducer;
