import { isServerSideRendering } from './ssr';

/* eslint-disable no-console */
interface IReducerStorageFunctions<T> {
	updateStorage: (state: T, updateState: T) => T;
	removeStorage: () => void;
	getStorage: () => T;
}

type storageType = 'localStorage' | 'sessionStorage';
interface IManagerStorageReturnType {
	setItem: (key: string, state: any) => any;
	getItem: (key: string) => any;
	removeItem: (key: string) => any;
}
export const managerLocalStorage = (
	type: 'localStorage' | 'sessionStorage' = 'localStorage',
): IManagerStorageReturnType => {
	let storage: Storage | null = null;

	if (!isServerSideRendering) {
		storage = type === 'localStorage' ? localStorage : sessionStorage;
	}

	const setItem = (key: string, state: any): void => {
		if (storage) {
			try {
				storage.setItem(key, JSON.stringify(state));
			} catch (error) {
				storage.setItem(key, state);
			}
		}
	};

	const removeItem = (key: string): any => {
		if (storage) {
			try {
				storage.removeItem(key);
			} catch (error) {
				return error;
			}
		}
		return null;
	};

	const getItem = (key: string): any => {
		if (storage) {
			const data = storage.getItem(key);
			if (data) {
				try {
					return JSON.parse(data);
				} catch {
					return data;
				}
			}
		}

		return null;
	};

	return {
		getItem,
		removeItem,
		setItem,
	};
};

export const reducerStorage = <T>(
	key: string,
	initialState: T,
	type: storageType = 'localStorage',
): IReducerStorageFunctions<T> => {
	let storage: Storage | null = null;

	if (!isServerSideRendering) {
		storage = type === 'localStorage' ? localStorage : sessionStorage;
	}

	const isObjectState = typeof initialState === 'object';
	const isArrayState = Array.isArray(initialState);

	const updateStorage = (state: T, updateState: T): T => {
		let newState = updateState;

		if (isObjectState) {
			newState = {
				...state,
				...updateState,
			};
		}

		if (isArrayState) {
			newState = updateState;
		}
		if (storage) {
			try {
				storage.setItem(key, JSON.stringify(newState));
			} catch (error) {
				console.error(error);
			}
		}

		return newState;
	};

	const removeStorage = (): void => {
		if (storage) {
			try {
				storage.removeItem(key);
			} catch (error) {
				console.error(error);
			}
		}
	};

	const getStorage = (): any => {
		if (storage) {
			try {
				const item = storage.getItem(key);
				return item ? JSON.parse(item) : initialState;
			} catch (error) {
				return initialState;
			}
		}
		return initialState;
	};

	return {
		getStorage,
		removeStorage,
		updateStorage,
	};
};
