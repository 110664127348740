export enum QueueEndPoints {
	/**
	 * Queue delete
	 * @host `/queue/delete/`
	 */
	DELETE = '/queue/delete/',

	/**
	 * Queue get by branch
	 * @host `/queue/getbybranch/`
	 */
	GET_BY_BRANCH = '/queue/getbybranch/',

	/**
	 * Queue get by id
	 * @host `/queue/getbyid/`
	 */
	GET_BY_ID = '/queue/getbyid/',

	/**
	 * Queue insert
	 * @host `/queue/insert/`
	 */
	INSERT = '/queue/insert/',

	/**
	 * Queue update
	 * @host `/queue/update/`
	 */
	UPDATE = '/queue/update/',
}
