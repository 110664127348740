import type { IRentRateValidation } from '../../models/entities/RentRateValidation';
import type {
	IRentRateValidationDeleteParams,
	IRentRateValidationSaveParams,
	IRentRateValidationSearchParams,
} from '../../models/serviceParams/RentRateValidationParams';
import { Api } from '../../modules/shared/api';
import { RentRateValidationEndPoints } from '../endPoints/RentRateValidationEndPoints';

/**
 * Search rent rate validations
 * @param model IrentRateValidationSearchParams
 * @returns IRentRateValidation[]
 */
export const rentRateValidationSearchService = (model: IRentRateValidationSearchParams) =>
	Api.get<IRentRateValidation[], IRentRateValidationSearchParams>(RentRateValidationEndPoints.SEARCH, model);

/**
 * Inser rent rate validations
 * @param model IRentRateValidationInsertParams
 * @returns IRentRateValidation
 */
export const rentRateValidationInsertService = (model: IRentRateValidationSaveParams) =>
	Api.post<IRentRateValidation[], IRentRateValidationSearchParams>(RentRateValidationEndPoints.INSERT, model);

/**
 * Modify rent rate validations
 * @param model IRentRateValidationModifyParams
 * @returns IRentRateValidation
 */
export const rentRateValidationModifyService = (model: IRentRateValidationSaveParams) =>
	Api.post<IRentRateValidation[], IRentRateValidationSaveParams>(RentRateValidationEndPoints.MODIFY, model);

/**
 * Delete rent rate validation
 * @param model IRentRateValidationDeleteParams
 * @returns IRentRateValidation
 */
export const rentRateValidationDeleteService = (model: IRentRateValidationDeleteParams) =>
	Api.post<IRentRateValidation, IRentRateValidationDeleteParams>(RentRateValidationEndPoints.DELETE, model);
