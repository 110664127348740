'use client';
import type { FC } from 'react';
import React from 'react';

import { Container as RSContainer } from 'reactstrap';
import type { ContainerProps as RSContainerProps } from 'reactstrap/types/lib/Container';

export type ContainerProps = RSContainerProps & {
	className?: string;
};

export const Container: FC<ContainerProps> = ({ children, ...props }) => {
	return <RSContainer {...props}>{children}</RSContainer>;
};
