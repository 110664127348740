import type { IExcessRate, IExcessRatePerProvider } from '../../models/entities/ExcessRate';
import type {
	IExcessRateDeleteParams,
	IExcessRateInsertParams,
	IExcessRateModifyParams,
	IExcessRateSearchParams,
} from '../../models/serviceParams/ExcessRateParams';
import { Api } from '../../modules/shared/api';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import { ExcessRateEndpoints } from '../endPoints/ExcessRateEndpoints';

export const excessRateServiceSearch = (model: IExcessRateSearchParams) => {
	return Api.get<IExcessRate[], IExcessRateSearchParams>(ExcessRateEndpoints.SEARCH, model);
};

export const excessRateServiceInsert = (model: IExcessRateInsertParams) => {
	return Api.post<IExcessRate, IExcessRateInsertParams>(ExcessRateEndpoints.INSERT, model);
};

export const excessRateServiceModify = (model: IExcessRateModifyParams) => {
	return Api.post<IExcessRate, IExcessRateModifyParams>(ExcessRateEndpoints.MODIFY, model);
};

export const excessRateServiceDelete = (model: IExcessRateDeleteParams) => {
	return Api.post<IExcessRate, IExcessRateDeleteParams>(ExcessRateEndpoints.DELETE, model);
};

export const excessRateServiceGetAll = (model: IServiceBaseParams) => {
	return Api.get<IExcessRatePerProvider[], IServiceBaseParams>(ExcessRateEndpoints.GET_ALL, model);
};
