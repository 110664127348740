'use client';
import type { FC } from 'react';
import React from 'react';

import { Col as RSCol } from 'reactstrap';
import type { ColProps as RSColProps } from 'reactstrap/types/lib/Col';

export type ColProps = RSColProps;

export const Col: FC<ColProps> = ({ children, ...props }) => {
	return <RSCol {...props}>{children}</RSCol>;
};
