export enum SaleOrderEndPoints {
	/**
	 * Insert a new vehicle sale order
	 * @host `/saleOrder/insert`
	 */
	INSERT = '/saleOrder/insert/',

	/**
	 * Search for vehicle sale orders
	 * @host `/saleOrder/search`
	 */
	SEARCH = '/saleOrder/search/',

	/**
	 * Get vehicle sale orders by id
	 * @host `/saleOrder/getById`
	 */
	GET_BY_ID = '/saleOrder/getById/',
}
