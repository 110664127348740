import { useEffect } from 'react';

import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { setHostName } from '@crac/core/redux/actions/ComputerActions';
import { employeeLogIn } from '@crac/core/redux/actions/EmployeeActions';

import { useBindAndMemoizeActions } from '~/features/shared/hooks/useBindAndMemoizeActions';

import { LoginContent } from '../components/LoginContent';

const actionsImported = {
	clearGlobalCache,
	employeeLogIn,
	setHostName,
};

export type LoginActionsType = typeof actionsImported;

export const Login = () => {
	const actions = useBindAndMemoizeActions(actionsImported);

	useEffect(() => {
		const defaultTitle = 'Centauro Rent A Car';
		document.title = `Login | ${defaultTitle}`;
	}, []);

	return (
		<LoginContent
			clearGlobalCache={actions.clearGlobalCache}
			employeeLogIn={actions.employeeLogIn}
			setHostName={actions.setHostName}
		/>
	);
};
