import {
	branchScheduleServiceDelete,
	branchScheduleServiceInsert,
	branchScheduleServiceModify,
	branchScheduleServiceSearch,
} from '../../data/services/BranchScheduleService';
import type { IBranchSchedule } from '../../models/entities/BranchSchedule';
import type {
	IBranchScheduleDeleteParams,
	IBranchScheduleInsertParams,
	IBranchScheduleModifyParams,
	IBranchScheduleSearchParams,
} from '../../models/serviceParams/BranchScheduleParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for branch schedules.
 *
 * @param {IBranchSchedule[]} payload `IBranchSchedule[]` The payload for the action.
 * @param {IBranchScheduleSearchParams} IBranchScheduleSearchParams - The search parameters.
 * @returns {Promise<void>} - The branch schedules.
 */
export const branchScheduleSearch = createAsyncAction<IBranchSchedule[], IBranchScheduleSearchParams>(
	'branchSchedule/search',
	branchScheduleServiceSearch,
);

/**
 * Modifies a branch schedule.
 * @param {IBranchSchedule} payload `IBranchSchedule` The branch schedule modified.
 * @param {IBranchScheduleModifyParams} IBranchScheduleModifyParams - Params to modify.
 * @returns {Promise<void>} A promise that resolves when the branch schedule is modified
 */
export const branchScheduleModify = createAsyncAction<IBranchSchedule, IBranchScheduleModifyParams>(
	'branchSchedule/modify',
	branchScheduleServiceModify,
);

/**
 * Deletes a branch schedule.
 *
 * @param {number} payload - The ID of the branch schedule to delete.
 * @param {IBranchScheduleDeleteParams} meta - Additional metadata for the action.
 * @returns {AsyncAction<number, IBranchScheduleDeleteParams>} The delete branch schedule action.
 */
export const branchScheduleDelete = createAsyncAction<number, IBranchScheduleDeleteParams>(
	'branchSchedule/delete',
	branchScheduleServiceDelete,
);

/**
 * Clears the branch schedule.
 */
export const branchScheduleClear = createSyncAction('branchSchedule/clear');

// ------------------------------- RANGES ------------------------------

export const branchScheduleRangeClear = createSyncAction('branch/scheduleClearRange');

export const branchScheduleRangeSearch = createAsyncAction<IBranchSchedule[], IBranchScheduleSearchParams>(
	'branch/scheduleSearchRange',
	branchScheduleServiceSearch,
);

export const branchScheduleRangeInsert = createAsyncAction<IBranchSchedule[], IBranchScheduleInsertParams>(
	'branch/scheduleInsertRange',
	branchScheduleServiceInsert,
);

export const branchScheduleRangeModify = createAsyncAction<IBranchSchedule, IBranchScheduleModifyParams>(
	'branch/scheduleModifyRange',
	branchScheduleServiceModify,
);

export const branchScheduleRangeDelete = createAsyncAction<number, IBranchScheduleDeleteParams>(
	'branch/scheduleDeleteRange',
	branchScheduleServiceDelete,
);
