import { createSlice } from '@reduxjs/toolkit';

import {
	fleetPreviousRegistrationDelete,
	fleetPreviousRegistrationImport,
	fleetPreviousRegistrationImportClear,
	fleetPreviousRegistrationModify,
	fleetPreviousRegistrationRegister,
	fleetPreviousRegistrationSearch,
	fleetPreviousRegistrationSearchClear,
} from '@crac/core/redux/actions/FleetPreviousRegistrationActions';
import type { IFleetPreviousRegistrationReducerState } from '@crac/core/redux-store/reducersState/fleet/FleetPreviousRegistrationReducerState';

const initialState: IFleetPreviousRegistrationReducerState = {
	fleetPreviousRegistrationImports: undefined,
	fleetPreviousRegistrationImportRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetPreviousRegistrations: [],
	fleetPreviousRegistrationSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetPreviousRegistrationModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetPreviousRegistrationRegisterRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetPreviousRegistrationDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const previousRegistrationSlice = createSlice({
	name: 'FleetPreviousRegistration',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(fleetPreviousRegistrationSearch.pending, (state) => {
				return {
					...state,
					fleetPreviousRegistrationSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetPreviousRegistrationSearch.fulfilled, (state, action) => {
				return {
					...state,
					fleetPreviousRegistrationSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetPreviousRegistrations: action.payload,
				};
			})
			.addCase(fleetPreviousRegistrationSearch.rejected, (state, action) => {
				return {
					...state,
					fleetPreviousRegistrationSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR SEARCH
		builder.addCase(fleetPreviousRegistrationSearchClear, (state) => {
			return {
				...state,
				fleetPreviousRegistrations: [],
			};
		});
		// CLEAR IMPORT
		builder.addCase(fleetPreviousRegistrationImportClear, (state) => {
			return {
				...state,
				fleetPreviousRegistrationImports: undefined,
			};
		});
		// IMPORT
		builder
			.addCase(fleetPreviousRegistrationImport.pending, (state) => {
				return {
					...state,
					fleetPreviousRegistrationImportRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetPreviousRegistrationImport.fulfilled, (state, action) => {
				return {
					...state,
					fleetPreviousRegistrationImportRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetPreviousRegistrationImports: action.payload,
					fleetPreviousRegistrations: [...state.fleetPreviousRegistrations, ...action.payload],
				};
			})
			.addCase(fleetPreviousRegistrationImport.rejected, (state, action) => {
				return {
					...state,
					fleetPreviousRegistrationImportRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(fleetPreviousRegistrationModify.pending, (state) => {
				return {
					...state,
					fleetPreviousRegistrationModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetPreviousRegistrationModify.fulfilled, (state, action) => {
				return {
					...state,
					fleetPreviousRegistrationModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetPreviousRegistrations: state.fleetPreviousRegistrations.map((item) =>
						item.frameNumber === action.payload.frameNumber ? action.payload : item,
					),
				};
			})
			.addCase(fleetPreviousRegistrationModify.rejected, (state, action) => {
				return {
					...state,
					fleetPreviousRegistrationModifyRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			});
		// REGISTER
		builder
			.addCase(fleetPreviousRegistrationRegister.pending, (state) => {
				return {
					...state,
					fleetPreviousRegistrationRegisterRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetPreviousRegistrationRegister.fulfilled, (state, action) => {
				return {
					...state,
					fleetPreviousRegistrationRegisterRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetPreviousRegistrations: state.fleetPreviousRegistrations.filter(
						({ plateNumber }) =>
							!action.payload.map(({ plateNumber }) => plateNumber).includes(plateNumber),
					),
				};
			})
			.addCase(fleetPreviousRegistrationRegister.rejected, (state, action) => {
				return {
					...state,
					fleetPreviousRegistrationRegisterRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DELETE
		builder
			.addCase(fleetPreviousRegistrationDelete.pending, (state) => {
				return {
					...state,
					fleetPreviousRegistrationDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetPreviousRegistrationDelete.rejected, (state, action) => {
				return {
					...state,
					fleetPreviousRegistrationDeleteRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(fleetPreviousRegistrationDelete.fulfilled, (state, action) => {
				const vehiclesDeleted = action.payload.map(({ frameNumber }) => frameNumber);
				return {
					...state,
					fleetPreviousRegistrationDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetPreviousRegistrations: state.fleetPreviousRegistrations.filter(
						({ frameNumber }) => !vehiclesDeleted.includes(frameNumber),
					),
				};
			});
	},
});

export const previousRegistrationReducer = previousRegistrationSlice.reducer;
