import { createSlice } from '@reduxjs/toolkit';

import type { IVehicleBrand } from '@crac/core/models/entities/VehicleBrand';
import {
	fleetVehicleBrandClear,
	fleetVehicleBrandDelete,
	fleetVehicleBrandInsert,
	fleetVehicleBrandModify,
	fleetVehicleBrandSearch,
} from '@crac/core/redux/actions/FleetVehicleBrandActions';
import type { IFleetVehicleBrandReducerState } from '@crac/core/redux-store/reducersState/fleet/FleetVehicleBrandReducerState';

const initialState: IFleetVehicleBrandReducerState = {
	fleetVehicleBrandInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleBrands: [],
	fleetVehicleBrandSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleBrandModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetVehicleBrandDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const vehicleBrandSlice = createSlice({
	name: 'VehicleBrand',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(fleetVehicleBrandSearch.pending, (state) => {
				return {
					...state,
					fleetVehicleBrandSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleBrandSearch.fulfilled, (state, action) => {
				return {
					...state,
					fleetVehicleBrandSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetVehicleBrands: action.payload,
				};
			})
			.addCase(fleetVehicleBrandSearch.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleBrandSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR SEARCH
		builder.addCase(fleetVehicleBrandClear, (state) => {
			return {
				...state,
				fleetVehicleBrands: [],
			};
		});
		// INSERT
		builder
			.addCase(fleetVehicleBrandInsert.pending, (state) => {
				return {
					...state,
					fleetVehicleBrandInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleBrandInsert.fulfilled, (state, { payload }) => {
				return {
					...state,
					fleetVehicleBrandInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetVehicleBrands: [...state.fleetVehicleBrands, payload],
				};
			})
			.addCase(fleetVehicleBrandInsert.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleBrandInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(fleetVehicleBrandModify.pending, (state) => {
				return {
					...state,
					fleetVehicleBrandModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleBrandModify.fulfilled, (state, { payload }) => {
				const fleetVehicleBrands = state.fleetVehicleBrands.map((item) =>
					payload.brand === item.brand ? { ...payload } : item,
				);
				return {
					...state,
					fleetVehicleBrands,
					fleetVehicleBrandModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleBrandModify.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleBrandModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DELETE
		builder
			.addCase(fleetVehicleBrandDelete.pending, (state) => {
				return {
					...state,
					fleetVehicleBrandDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleBrandDelete.fulfilled, (state, action) => {
				const { brand } = action.payload as IVehicleBrand;
				const fleetVehicleBrands = state.fleetVehicleBrands?.filter(
					(vehicleBrand) => vehicleBrand.brand !== brand,
				);
				return {
					...state,
					fleetVehicleBrands,
					fleetVehicleBrandDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleBrandDelete.rejected, (state, action) => {
				return {
					...state,
					fleetVehicleBrandDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const vehicleBrandReducer = vehicleBrandSlice.reducer;
