/* eslint-disable consistent-return */
/* eslint-disable camelcase */
import { DateTime } from 'luxon';

import type { ICustomer } from '@crac/core/models/entities/Customer';

import { routesIdMap } from '~/config/routesIdMap';
import { routes } from '~/features/routes';

const ZAMMAD_API = 'https://service.centauro.net';
const DEFAULT_TICKET_EMAIL = 'noemail@noemail.com';

const ZAMMAD_ENDPOINT = {
	ticket_search: `${ZAMMAD_API}/api/v1/tickets/search`,
	tickets: `${ZAMMAD_API}/api/v1/tickets`,
	user_search: `${ZAMMAD_API}/api/v1/users/search`,
	users: `${ZAMMAD_API}/api/v1/users`,
};

const ZAMMAD_REQUEST_HEADERS = new Headers({
	Authorization: 'Token token=pKFCmKXa_0fL-BrLptOIxH13kQAFWUFZKTGk4VSVKCziMVpyyaRxzJMt_7F3vB-G',
	'Content-Type': 'application/json; charset=utf-8',
});

const customerDetailRoute = routes[routesIdMap.CustomersDetailOverviews];

/**
 * Search user by email
 * @param email email to search
 */
const searchUsers = async (email: string): Promise<any> => {
	const response = await fetch(`${ZAMMAD_ENDPOINT.user_search}?query=${email}`.replace('+', ''), {
		headers: ZAMMAD_REQUEST_HEADERS,
		method: 'GET',
	});

	const [user] = await response.json();

	if (!user) {
		return null;
	}

	return user;
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getUsers = async (): Promise<any> => {
	try {
		const response = await fetch(`${ZAMMAD_ENDPOINT.users}`, {
			headers: ZAMMAD_REQUEST_HEADERS,
			method: 'GET',
		});
		return await response.json();
	} catch (error) {
		if (error instanceof Error) {
			Promise.reject(new TypeError(error.message));
		}
		return null;
	}
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const getTicketSearch = async (query: string, limit = 1000): Promise<any> => {
	try {
		const response = await fetch(`${ZAMMAD_ENDPOINT.ticket_search}?query=${query}&limit=${limit}`, {
			headers: ZAMMAD_REQUEST_HEADERS,
			method: 'GET',
		});

		return await response.json();
	} catch (error) {
		if (error instanceof Error) {
			Promise.reject(new TypeError(error.message));
		}
		return null;
	}
};

const createTicket = async (ticket: any): Promise<any> => {
	if (!ticket) {
		return null;
	}

	try {
		const response = await fetch(ZAMMAD_ENDPOINT.tickets, {
			body: JSON.stringify(ticket),
			headers: ZAMMAD_REQUEST_HEADERS,
			method: 'POST',
		});

		return await response.json();
	} catch (error) {
		if (error instanceof Error) {
			Promise.reject(new TypeError(error.message));
		}
		return null;
	}
};

const createUser = async (user: any): Promise<any> => {
	if (!user) {
		return null;
	}

	try {
		const response = await fetch(ZAMMAD_ENDPOINT.users, {
			body: JSON.stringify(user),
			headers: ZAMMAD_REQUEST_HEADERS,
			method: 'POST',
		});

		return await response.json();
	} catch (error) {
		if (error instanceof Error) {
			Promise.reject(new TypeError(error.message));
		}
		return null;
	}
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const deleteUser = async (id: any): Promise<any> => {
	if (!id) {
		return null;
	}

	try {
		await fetch(`${ZAMMAD_ENDPOINT.users}/${id}`, {
			headers: ZAMMAD_REQUEST_HEADERS,
			method: 'DELETE',
		});
	} catch (error) {
		Promise.reject(error);
	}
};

const getTicketData = (customer: ICustomer, { from, toName, to, partyId }: any) => ({
	article: {
		body: `
				<p><strong>Inbound call</strong> from ${from.phoneNumber} to ${toName}</p>
				<hr />
				<ul>
					${
						customer
							? `<li><strong>Customer: </strong><a title="View customer detail" target="_blank" href="https://rac.centauro.net/customers/detail/${customer.code}">${customer.code} - ${customer.name} ${customer.firstSurname}</a></li>`
							: ''
					}
					<li><strong>Phone number:</strong> ${to.phoneNumber}</li>
					<li><strong>Call date:</strong> ${new Date().toLocaleString()}</li>
				</ul>`,

		content_type: 'text/html',
		internal: true,
		subject: `Inbound call from  ${from.phoneNumber}`,
		type: 'phone',
	},
	customer: customer && customer.email ? customer.email : DEFAULT_TICKET_EMAIL,
	group: 'Llamadas',
	mobile: partyId,
	state_id: 2,
	title: `Inbound call from ${from.phoneNumber}`,
});

/**
 * Si es un cliente de centaauro.
 * 	- Buscamos en Zammad con su email o con su teléfono.
 *  - Si no existe, creamo nuevo usuario con los datos del cliente.
 *  - Si existe, creamos un ticket con los datos de este usuario.
 *
 * Si no es cliente centauro
 * 	- Buscamos el usuario genérico noemail@noemail.com
 *  - Creamos ticket con los datos del usuario genérico
 * @param {*} callData RingCentral call data
 * @param {*} customers customers result search
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const manageTickets = async (callData: any, customers: ICustomer[]): Promise<any> => {
	const { from } = callData;
	const [customer] = customers;
	const ticketData = getTicketData(customer, callData);
	let user = null;
	let defaultUser = null;

	if (customer) {
		if (customer.email) {
			user = await searchUsers(customer.email as string);
		}

		if (!user && customer.phone) {
			user = await searchUsers(customer.phone);
		}

		if (!user) {
			const userData = {
				email: customer.email || `${customer.phone}@noemail.com`,
				firstname: customer.name,
				last_login: DateTime.local().toJSDate(),
				lastname: `${customer.firstSurname} ${customer.secondSurname}`,
				note: `${customerDetailRoute.path.replace(/:id/gu, customer.code)}`,
				phone: from.phoneNumber,
			};

			user = await createUser(userData);
		}
	} else {
		defaultUser = await searchUsers(DEFAULT_TICKET_EMAIL);
	}

	// CREAMOS NUEVO TICKET POR LLAMADA
	const newUser = await createTicket({
		...ticketData,
		customer: user ? user.email : defaultUser.email,
		owner_id: user ? user.created_by_id : defaultUser.id,
	});

	return newUser;
};

export const ZammadAPI = {
	manageTickets,
	getTicketSearch,
};
