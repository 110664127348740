import { createAction } from '@reduxjs/toolkit';

import {
	discountCouponServiceDeactivate,
	discountCouponServiceInsert,
	discountCouponServiceSearch,
} from '../../data/services/DiscountCouponService';
import type { IDiscountCoupon } from '../../models/entities/DiscountCoupon';
import type {
	IDiscountCouponDeactivateParams,
	IDiscountCouponInsertParams,
	IDiscountCouponSearchParams,
} from '../../models/serviceParams/DiscountCouponParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for discount coupons based on specified parameters.
 *
 * @param {IDiscountCoupon[]} payload - The discount coupons to be returned.
 * @param {IDiscountCouponSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of discount coupons.
 */
export const discountCouponSearch = createAsyncAction<IDiscountCoupon[], IDiscountCouponSearchParams>(
	'discountCoupon/search',
	discountCouponServiceSearch,
);

/**
 * Inserts new discount coupons into the system.
 *
 * @param {IDiscountCoupon[]} payload - The discount coupons to be inserted.
 * @param {IDiscountCouponInsertParams} params - Parameters detailing the discount coupons to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the discount coupons are successfully inserted.
 */
export const discountCouponInsert = createAsyncAction<IDiscountCoupon[], IDiscountCouponInsertParams>(
	'discountCoupon/insert',
	discountCouponServiceInsert,
);

/**
 * Clears the discount coupon state.
 */
export const discountCouponClear = createAction('discountCoupon/clear');

/**
 * Deactivates specified discount coupons.
 *
 * @param {IDiscountCoupon[]} payload - The discount coupons to be deactivated.
 * @param {IDiscountCouponDeactivateParams} params - Parameters for deactivating the discount coupons.
 * @returns {Promise<void>} - A promise that resolves after the discount coupons are deactivated.
 */
export const discountCouponDeactivate = createAsyncAction<IDiscountCoupon[], IDiscountCouponDeactivateParams>(
	'discountCoupon/deactivate',
	discountCouponServiceDeactivate,
);
