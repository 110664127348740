import { combineReducers } from '@reduxjs/toolkit';

import { availabilityConfigReducer as AvailabilityConfig } from './availabilityConfig/state/slice';
import { branchScheduleReducer as BranchSchedule } from './branchSchedule/state/slice';
import { choosableServiceConfigurationReducer as ChoosableServiceConfiguration } from './choosableServiceConfiguration/state/slice';
import { couponConfigurationReducer as CouponConfiguration } from './couponConfiguration/state/slice';
import { depositRateReducer as DepositRate } from './depositRate/state/slice';
import { discountCouponReducer as DiscountCoupon } from './discountCoupon/state/slice';
import { includedServiceConfigurationReducer as IncludedServiceConfiguration } from './includedServiceConfiguration/state/slice';
import { invoiceableServiceConfigurationReducer as InvoiceableServiceConfiguration } from './invoiceableServiceConfiguration/state/slice';
import { kmRateReducer as KmRate } from './kmRate/state/slice';
import { maximumBookingDurationReducer as MaximumBookingDuration } from './maximumBookingDuration/state/slice';
import { maximumExcessRateReducer as MaximumExcessRate } from './maximumExcessRate/state/slice';
import { notAllowedMovementReducer as NotAllowedMovement } from './notAllowedMovement/state/slice';
import { occupancyReducer as OccupancyConfig } from './occupancy/state/slice';
import { oneWayReducer as OneWay } from './oneWay/state/slice';
import { packageConfigurationReducer as PackageConfiguration } from './packageConfiguration/state/slice';
import { partnerCategoryReducer as PartnerCategory } from './partnerCategory/state/slice';
import { partnerGroupReducer as PartnerGroup } from './partnerGroup/state/slice';
import { preauthorizationRateReducer as PreauthorizationRate } from './preauthorizationRate/state/slice';
import { rentRateReducer as RentRate } from './rentRate/state/slice';
import { rentRateFormulaReducer as RentRateFormula } from './rentRateFormula/state/slice';
import { rentRateMarginReducer as RentRateMargin } from './rentRateMargin/state/slice';
import { rentRateTypeReducer as RentRateType } from './rentRateType/state/slice';
import { rentRateTypeRelationReducer as RentRateTypeRelation } from './rentRateTypeRelation/state/slice';
import { rentRateValidationReducer as RentRateValidation } from './rentRateValidation/state/slice';
import { rentRateVariationReducer as RentRateVariation } from './rentRateVariation/state/slice';
import { serviceRateReducer as ServiceRate } from './serviceRate/state/slice';
import { serviceRateTypeReducer as ServiceRateType } from './serviceRateType/state/slice';
import { stopSalesReducer as StopSales } from './stopSales/state/slice';
import { vehicleGroupIncludedConfigurationReducer as VehicleGroupIncludedConfiguration } from './vehicleGroupIncludedConfiguration/state/slice';
import { vehicleGroupOrderAndUpgradeReducer as VehicleGroupOrderAndUpgrade } from './vehicleGroupOrderAndUpgrade/state/slice';

export const pricingReducer = combineReducers({
	AvailabilityConfig,
	BranchSchedule,
	ChoosableServiceConfiguration,
	CouponConfiguration,
	DepositRate,
	DiscountCoupon,
	IncludedServiceConfiguration,
	InvoiceableServiceConfiguration,
	KmRate,
	MaximumBookingDuration,
	MaximumExcessRate,
	NotAllowedMovement,
	OccupancyConfig,
	OneWay,
	PackageConfiguration,
	PartnerCategory,
	PartnerGroup,
	PreauthorizationRate,
	RentRate,
	RentRateFormula,
	RentRateMargin,
	RentRateType,
	RentRateTypeRelation,
	RentRateValidation,
	RentRateVariation,
	ServiceRate,
	ServiceRateType,
	StopSales,
	VehicleGroupIncludedConfiguration,
	VehicleGroupOrderAndUpgrade,
});
