import { createSlice } from '@reduxjs/toolkit';

import { agencyClear, agencyClearSearch, agencyGetByCode, agencySearch } from '@crac/core/redux/actions/AgencyActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IAgencyReducerState } from '@crac/core/redux-store/reducersState/agency';

const initialState: IAgencyReducerState = {
	agencies: [],
	agenciesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	agency: null,
	agencyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const agencySlice = createSlice({
	name: 'AgencySlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// CLEAR
		builder.addCase(agencyClear, () => {
			return initialState;
		});
		// CLEAR SEARCH
		builder.addCase(agencyClearSearch, (state) => {
			return {
				...state,
				agencies: [],
			};
		});
		// SEARCH
		builder
			.addCase(agencySearch.pending, (state) => {
				return {
					...state,
					agenciesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(agencySearch.fulfilled, (state, action) => {
				return {
					...state,
					agencies: action.payload,
					agenciesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(agencySearch.rejected, (state, action) => {
				return {
					...state,
					agencies: state.agencies ? state.agencies : [],
					agenciesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY CODE
		builder
			.addCase(agencyGetByCode.pending, (state) => {
				return {
					...state,
					agency: null,
					agencyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(agencyGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					agency: action.payload,
					agencyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(agencyGetByCode.rejected, (state, action) => {
				return {
					...state,
					agency: state.agency ? state.agency : null,
					agencyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const agencyReducer = agencySlice.reducer;
