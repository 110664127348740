import { revisionCostServiceSave, revisionCostServiceSearch } from '../../data/services/RevisionCostService';
import type { IRevisionCost } from '../../models/entities/RevisionCost';
import type { IRevisionCostSaveParams, IRevisionCostSearchParams } from '../../models/serviceParams/RevisionCostParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

export const revisionCostSearch = createAsyncAction<IRevisionCost[], IRevisionCostSearchParams>(
	'workshop/revisionCost/search',
	revisionCostServiceSearch,
);

export const revisionCostSave = createAsyncAction<IRevisionCost[], IRevisionCostSaveParams>(
	'workshop/revisionCost/save',
	revisionCostServiceSave,
);

export const revisionCostClear = createSyncAction('workshop/revisionCost/clear');
