import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const New = lazyWithRetry(() => import('./pages/New'));

export const newRoutes: { [key: number]: IRouteConfig } = {};

newRoutes[routesIdMap.CompaniesNew] = {
	component: New,
	exact: true,
	id: routesIdMap.CompaniesNew,
	name: 'New company',
	path: '/company/new',
	permissions: [PermissionsType.CompanyNew],
	icon: 'plus',
	menuText: 'New',
};
