import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { companyClear, companyInsert } from '@crac/core/redux/actions/CompanyActions';
import type { ICompanyDetailReducerState } from '@crac/core/redux-store/reducersState/company/DetailReducerState';
export type ICompanyNewReducerState = ICompanyDetailReducerState;

const initialState: ICompanyNewReducerState = {
	company: null,
	companySaveRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const newSlice = createSlice({
	name: 'CompanyNewSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// INSERT
		builder
			.addCase(companyInsert.pending, (state) => {
				return {
					...state,
					companySaveRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyInsert.fulfilled, (state, action) => {
				return {
					...state,
					company: deepCopy(action.payload),
					companySaveRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyInsert.rejected, (state, action) => {
				return {
					...state,
					companySaveRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR CACHE
		 * CLEAR
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, companyClear), () => initialState);
	},
});

export const newReducer = newSlice.reducer;
