import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Documentation = lazyWithRetry(() => import('~/features/fleet/documentation/pages/Documentation'));

export const documentationRoutes: { [key: number]: IRouteConfig } = {};

documentationRoutes[routesIdMap.FleetDocumentation] = {
	component: Documentation,
	exact: true,
	id: routesIdMap.FleetDocumentation,
	name: 'Vehicle documents',
	path: '/fleet/documents',
	permissions: [PermissionsType.Fleet],
	icon: 'files-o',
	menuText: 'Documents',
};
