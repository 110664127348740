export enum UnitTime {
	Minute = 1,
	Hour = 2,
	Day = 3,
	Month = 4,
}

export enum UnitTimeDurationTexts {
	Day = 'day',
	Days = 'days',
	Hour = 'hour',
	Hours = 'hours',
	Month = 'month',
	Months = 'months',
	Minute = 'minute',
	Minutes = 'minutes',
	Daily = 'daily',
	Hourly = 'hourly',
	Monthly = 'monthly',
	PerMinute = 'perMinute',
	AmountPerHour = 'amountPerHour',
	AmountPerMinute = 'amountPerMinute',
	AmountPerMonth = 'amountPerMonth',
	AmountPerDay = 'amountPerDay',
}
