import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { customerGetPendings } from '@crac/core/redux/actions/CustomerActions';
import type { ICustomerPendingReducerState } from '@crac/core/redux-store/reducersState/customer/PendingReducerState';
const initialState: ICustomerPendingReducerState = {
	pendings: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const pendingsSlice = createSlice({
	name: 'CustomerPendingsSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET PENDINGS
		builder
			.addCase(customerGetPendings.pending, (state) => {
				return {
					...state,
					pendings: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetPendings.fulfilled, (state, action) => {
				return {
					...state,
					pendings: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerGetPendings.rejected, (state, action) => {
				return {
					...state,
					pendings: {
						data: [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const pendingsReducer = pendingsSlice.reducer;
