import { bookingServiceGetByBookingNumber } from '@crac/core/data/services/BookingService';

export const bookingDetailLoader = async (bookingNumber: string) => {
	const response = await bookingServiceGetByBookingNumber({ bookingNumber });

	if (response.ok && response.data) {
		return response.data;
	}

	if (response.errors.length > 0) {
		const [error] = response.errors;
		throw Error(error.message || 'Error loading booking, press F5 to try again.');
	}

	return null;
};
