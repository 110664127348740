import type { INotAllowedMovement } from '../../models/entities/NotAllowedMovement';
import type {
	INotAllowedMovementDeleteParams,
	INotAllowedMovementInsertParams,
	INotAllowedMovementModifyParams,
	INotAllowedMovementSearchParams,
} from '../../models/serviceParams/NotAllowedMovementParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { NotAllowedMovementEndPoints } from '../endPoints/NotAllowedMovementEndPoints';

export const notAllowedMovementServiceSearch = (
	model: INotAllowedMovementSearchParams,
): Promise<ServiceResponse<INotAllowedMovement[]>> => {
	return Api.get<INotAllowedMovement[], INotAllowedMovementSearchParams>(NotAllowedMovementEndPoints.SEARCH, model);
};

export const notAllowedMovementServiceInsert = (
	model: INotAllowedMovementInsertParams,
): Promise<ServiceResponse<INotAllowedMovement[]>> => {
	return Api.post<INotAllowedMovement[], INotAllowedMovementInsertParams>(NotAllowedMovementEndPoints.INSERT, model);
};

export const notAllowedMovementServiceModify = (
	model: INotAllowedMovementModifyParams,
): Promise<ServiceResponse<INotAllowedMovement>> => {
	return Api.post<INotAllowedMovement, INotAllowedMovementModifyParams>(NotAllowedMovementEndPoints.MODIFY, model);
};

export const notAllowedMovementServiceDelete = (
	model: INotAllowedMovementDeleteParams,
): Promise<ServiceResponse<INotAllowedMovement>> => {
	return Api.post<INotAllowedMovement, INotAllowedMovementDeleteParams>(NotAllowedMovementEndPoints.DELETE, model);
};
