import type { IVehicleModel } from '../../models/entities/VehicleModel';
import type {
	IFleetVehicleModelDeactivateParams,
	IFleetVehicleModelInsertParams,
	IFleetVehicleModelModifyParams,
	IFleetVehicleModelSearchParams,
} from '../../models/serviceParams/FleetVehicleModelParams';
import { Api } from '../../modules/shared/api';
import { VehicleModelEndPoints } from '../endPoints/VehicleModelEndPoints';

export const fleetVehicleModelServiceSearch = (model: IFleetVehicleModelSearchParams) => {
	return Api.get<IVehicleModel[], IFleetVehicleModelSearchParams>(VehicleModelEndPoints.SEARCH, model);
};

export const fleetVehicleModelServiceInsert = (model: IFleetVehicleModelInsertParams) => {
	return Api.post<IVehicleModel, IFleetVehicleModelInsertParams>(VehicleModelEndPoints.INSERT, model);
};

export const fleetVehicleModelServiceModify = (model: IFleetVehicleModelModifyParams) => {
	return Api.post<IVehicleModel, IFleetVehicleModelModifyParams>(VehicleModelEndPoints.MODIFY, model);
};

export const fleetVehicleModelServiceDeactivate = (model: IFleetVehicleModelDeactivateParams) => {
	return Api.post<IVehicleModel, IFleetVehicleModelDeactivateParams>(VehicleModelEndPoints.DEACTIVATE, model);
};

export const fleetVehicleModelServiceActivate = (model: IFleetVehicleModelDeactivateParams) => {
	return Api.post<IVehicleModel, IFleetVehicleModelDeactivateParams>(VehicleModelEndPoints.ACTIVATE, model);
};
