import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const AvailabilityConfig = lazyWithRetry(() => import('./pages/AvailabilityConfig'));
const AvailabilityConfigNew = lazyWithRetry(() => import('./pages/AvailabilityConfigNew'));

export const availabilityConfigRoutes: { [key: number]: IRouteConfig } = {};

// GROUP AVAILABILITY
availabilityConfigRoutes[routesIdMap.VehicleGroupAvailability] = {
	component: AvailabilityConfig,
	exact: true,
	id: routesIdMap.VehicleGroupAvailability,
	name: 'Vehicle group availability',
	menuText: 'Group availability',
	path: '/pricing/vehicle-group-availability',
	permissions: getPricingPermission([PermissionsType.PricingAvailabilityConfiguration]),
	childrens: [routesIdMap.VehicleGroupAvailabilityNew],
	icon: 'sort-alpha-asc',
};

availabilityConfigRoutes[routesIdMap.VehicleGroupAvailabilityNew] = {
	component: AvailabilityConfigNew,
	exact: true,
	isChild: true,
	id: routesIdMap.VehicleGroupAvailabilityNew,
	name: 'New group availability',
	path: '/pricing/vehicle-group-availability/new',
	permissions: getPricingPermission([PermissionsType.PricingAvailabilityConfiguration]),
	icon: 'plus',
	menuText: 'New group availability',
};
