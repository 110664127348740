import { combineReducers } from '@reduxjs/toolkit';

import { accidentReducer as Accident } from './accident/state/slice';
import { assignmentReducer as Assignment } from './assignment/state/slice';
import { damageRepairReducer as DamageRepair } from './damageRepair/state/slice';
import { excessRateReducer as ExcessRate } from './excessRate/state/slice';
import { excessTypeReducer as ExcessType } from './excessType/state/slice';
import { revisionCostReducer as RevisionCost } from './revisionCost/state/slices/revisionCostSlice';
import { revisionLineReducer as RevisionLine } from './revisionCost/state/slices/revisionLineSlice';
import { revisionTypeReducer as RevisionType } from './revisionType/state/slice';
import { budgetReducer as Budget } from './shared/state/slices/budget';
import { budgetLinesReducer as BudgetLines } from './shared/state/slices/budgetLines';

export const workshopReducer = combineReducers({
	Accident,
	Assignment,
	Budget,
	BudgetLines,
	DamageRepair,
	ExcessRate,
	ExcessType,
	RevisionCost,
	RevisionLine,
	RevisionType,
});
