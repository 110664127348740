import { getCRService, getServicesByBookingType, getServicesFromPackage } from './BookingService';
import { round, sumAmounts } from '../../helpers/numbers';
import type { IVehicleGroupAvailabilityAndPrice } from '../../models/entities/Availability';
import type { IProvider } from '../../models/entities/Provider';
import type { IService } from '../../models/entities/Service';
import type { BookingType } from '../../models/types/BookingType';

/**
 * Suma el importe de los servicios incluidos, al importe del grupo
 * @param availability
 */
export const updateGroupsTotalAmount = (
	vehicleGroupsAvailability: IVehicleGroupAvailabilityAndPrice[],
	days: number,
	bookingType: BookingType,
	provider?: IProvider,
): IVehicleGroupAvailabilityAndPrice[] => {
	return vehicleGroupsAvailability.map((group) => {
		let serviceListToAmount: IService[] = [];
		let includeServices = group.services.filter((service) => service.included || service.minimumQuantity >= 1);

		if (provider && provider.refuelServiceCode) {
			const crService = getCRService(group, includeServices, provider);
			if (crService) {
				includeServices = [...includeServices, crService];
			}
		}

		serviceListToAmount = getServicesByBookingType(includeServices, bookingType);

		if (group.packages.length === 1) {
			const [currentPackage] = group.packages;
			const packageServices = getServicesFromPackage(group, currentPackage.code);

			// Remove CR if package have FF service
			if (provider && packageServices.some((service) => service.ff)) {
				serviceListToAmount = serviceListToAmount.filter(
					(service) => service.code !== provider.refuelServiceCode,
				);
			}
			serviceListToAmount = [...serviceListToAmount, ...packageServices];
		}

		const servicesAmount = sumAmounts(serviceListToAmount, 'amount');

		const totalAmount = round(servicesAmount + group.retailAmount);
		const amountPerDay = days > 0 ? totalAmount / days : 0;

		return {
			...group,
			amount: totalAmount,
			retailAmount: totalAmount,
			amountPerDay,
		};
	});
};

export const filterGroupServices = (services: IService[]): IService[] => {
	return services.filter((service) => {
		return service.choosable && service.maximumQuantity > 0 && !service.included;
	});
};

// MODIFICAR minimumQuantity SI maximumQuantity > 1, el minimumQuantity = includeServices.length
/**
 *
 * @param groupServices
 * @param currentServices
 */
export const updateGroupServicesMinimumQuantity = (
	groupServices: IService[],
	currentServices: IService[],
): IService[] => {
	const includeServices = currentServices
		.filter((service) => service.included || service.minimumQuantity >= 1)
		.map((item) => item.code);

	return groupServices.map((groupService) => {
		if (includeServices.includes(groupService.code)) {
			if (groupService.maximumQuantity > 1) {
				return {
					...groupService,
					minimumQuantity: includeServices.filter((incService) => incService === groupService.code).length,
				};
			}
		}

		return groupService;
	});
};

export const getChoosableGroups = (
	groups: IVehicleGroupAvailabilityAndPrice[],
): IVehicleGroupAvailabilityAndPrice[] => {
	// TODO: add provider in params
	return groups.filter((item) => {
		if (item.choosable && item.available && item.permittedTransfer && !item.noRates && item.retailAmount > 0) {
			if (item.connectCar) {
				const skService = item.services.find((service) => service.code === 'SK');
				return skService && skService.maximumQuantity > 0;
			}

			return true;
		}

		return false;
	});
};
