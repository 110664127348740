import { languageServiceGetAll } from '../../data/services/LanguageService';
import type { ILanguage } from '../../models/entities/Language';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Retrieves all languages.
 *
 * @param {ILanguage[]} payload - The languages to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters, if any are needed.
 * @returns {Promise<void>} - A promise that resolves to an array of all languages.
 */
export const languageGetAll = createAsyncAction<ILanguage[], IServiceBaseParams>(
	'language/getAll',
	languageServiceGetAll,
);
