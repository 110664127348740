export enum FineTransmitterEndPoints {
	/**
	 * FineTransmitter search
	 * @host `/finetransmitter/search/`
	 */
	SEARCH = '/finetransmitter/search/',

	/**
	 * FineTransmitter insert
	 * @host `/finetransmitter/insert/`
	 */
	INSERT = '/finetransmitter/insert/',

	/**
	 * FineTransmitter modify
	 * @host `/finetransmitter/modify/`
	 */
	MODIFY = '/finetransmitter/modify/',

	/**
	 * FineTransmitter get all
	 * @host `/finetransmitter/getall/`
	 */
	GET_ALL = '/finetransmitter/getall/',

	/**
	 * FineTransmitter get by code
	 * @host `/finetransmitter/getbycode/`
	 */
	GET_BY_CODE = '/finetransmitter/getbycode/',
}
