import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const SaleMargins = lazyWithRetry(() => import('./pages/SaleMargins'));
const SaleMarginsNew = lazyWithRetry(() => import('./pages/SaleMarginsNew'));

export const saleMarginsRoutes: { [key: number]: IRouteConfig } = {};

saleMarginsRoutes[routesIdMap.FleetSaleMargins] = {
	component: SaleMargins,
	exact: true,
	id: routesIdMap.FleetSaleMargins,
	name: 'Sale margins',
	menuText: 'Sale margins',
	path: '/fleet/sale-margins',
	permissions: [PermissionsType.SaleMargin],
	childrens: [routesIdMap.FleetSaleMarginsNew],
	icon: 'bar-chart',
};

saleMarginsRoutes[routesIdMap.FleetSaleMarginsNew] = {
	component: SaleMarginsNew,
	exact: true,
	id: routesIdMap.FleetSaleMarginsNew,
	name: 'New sale margin',
	menuText: 'New sale margin',
	path: '/fleet/sale-margins/new',
	permissions: [PermissionsType.SaleMargin],
	icon: 'plus',
	isChild: true,
};
