import type { FC } from 'react';
import React from 'react';

import classnames from 'classnames';

interface SidebarHeaderProps {
	className?: string;
	children?: React.ReactNode;
}

export const SidebarHeader: FC<SidebarHeaderProps> = ({ children, className }) => {
	return <div className={classnames('sidebar-header', className)}>{children}</div>;
};
