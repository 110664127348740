import { printerServiceGetByBranch } from '../../data/services/PrinterService';
import type { IPrinter } from '../../models/entities/Printer';
import type { IPrinterGetByBranchParams } from '../../models/serviceParams/PrinterParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves printers associated with a specific branch.
 *
 * @param {IPrinter[]} payload - The printers to be retrieved.
 * @param {IPrinterGetByBranchParams} params - Parameters to identify printers associated with a particular branch.
 * @returns {Promise<void>} - A promise that resolves to an array of printers for the specified branch.
 */
export const printerGetByBranch = createAsyncAction<IPrinter[], IPrinterGetByBranchParams>(
	'printer/getByBranch',
	printerServiceGetByBranch,
);
