import type { IBrowserInformation } from './BrowserInformation';
import type { ICardDataParams } from '../serviceParams/CardParams';

/**
 * Payment method type
 */
export enum PaymentMethodType {
	CARD = 1,
	POINTS = 2,
	CASH = 3,
	COUPON = 4,
}

/**
 * Payment channel use
 */
export enum PaymentChannelType {
	OFFICE = 1,
	WEB = 2,
	VIRTUAL = 3,
	BROKER = 4,
	PARTNERS = 5,
}

/**
 * Payment operation type
 */
export enum PaymentOperationType {
	SALE = 1,
	REFUND = 2,
	PREAUTHORIZATION = 3,
	PROCESS_PREAUTHORIZATION = 4,
	PREAUTHORIZATION_ANNULMENT = 5,
}

/**
 * Payment types with channel VIRTUAL, method CARD and operation SALE or REFUND can only be used
 * when at least exists one operation with channel OFFICE, method CARD and operation SALE.
 *
 * Payment types with channel VIRTUAL, method CARD and operation PROCESS_PREAUTHORIZATION or PREAUTHORIZATION_ANNULMENT can only be used
 * when at least exists one operation with channel OFFICE, method CARD and operation PREAUTHORIZATION.
 *
 * Payment types with channel VIRTUAL and method CARD can only be used after Processing booking state.
 *
 * Payment types with channel WEB, method CARD and operation SALE or REFUND can only be used
 * when at least exists one operation with channel WEB, method CARD and operation SALE.
 *
 * Payment types with channel OFFICE, method CASH and operation REFUND can only be used
 * when at least exists one operation with channel OFFICE, method CASH and operation SALE.
 *
 * Payment types with channel VIRTUAL, method POINTS and operation SALE can only be used
 * when booking agency is CV and customer have the mininum number of points.
 *
 * Payment types with channel VIRTUAL, method POINTS and operation REFUND can only be used
 * when at least exists one operation with channel VIRTUAL, method POINTS and operation SALE.
 *
 * Payment types with channel VIRTUAL, method POINTS and operation SALE can only have
 * a maximum amount between rent amount and customer points amount.
 *
 * Payment types with operation REFUND can only have a maximum amount of the same channel, method and SALE operation.
 *
 *
 */

export interface IPaymentType {
	Channel: PaymentChannelType;
	CustomerName: string;
	Method: PaymentMethodType;
	Name: string;
	Operation: PaymentOperationType;
}

export interface IOfficePaymentData {
	amount: number;
	computerName?: string;
	ip?: string;
	channel: PaymentChannelType;
	method: PaymentMethodType;
	operation: PaymentOperationType;
}

export interface IOnlinePaymentData {
	cardData?: ICardDataParams;
	browser?: IBrowserInformation;
}

const customerNamePaymentTypeEN = {
	cardPayment: 'Card payment',
	cardPreauthorization: 'Card preauthorization',
	cardPreauthorizationAnnulment: 'Card preauthorization annulment',
	cardProcessPreauthorization: 'Card process preauthorization',
	cardRefund: 'Card refund',
	paymentInCard: 'Payment in card',
};
const customerNamePaymentTypeES = {
	cardPayment: 'Pago con tarjeta',
	cardPreauthorization: 'Preautorización con tarjeta',
	cardPreauthorizationAnnulment: 'Anulación de preautorización con tarjeta',
	cardProcessPreauthorization: 'Procesamiento de preautorización con tarjeta',
	cardRefund: 'Devolución a tarjeta',
	paymentInCard: 'Pago en tarjeta',
};

const paymentTypesEN = {
	...customerNamePaymentTypeEN,
	brokerPayment: 'Broker payment',
	brokerRefund: 'Broker refund',
	partnersPayment: 'Partners payment',
	partnersRefund: 'Partners refund',
	paymentInCash: 'Payment in cash',
	paymentInPinpadCard: 'Payment in pinpad card',
	paymentInPoints: 'Payment in points',
	preauthorizationAnnulment: 'Preauthorization annulment',
	preauthorizationInPinpadCard: 'Preauthorization in pinpad card',
	processPreauthorization: 'Process preauthorization',
	refundDueInCash: 'Refund due in cash',
	refundDueInPoints: 'Refund due in points',
	refundDueToCard: 'Refund due to card',
	webPayment: 'Web payment',
	webRefund: 'Web refund',
};

const paymentTypesES = {
	...customerNamePaymentTypeES,
	brokerPayment: 'Pago de broker',
	brokerRefund: 'Devolución de broker',
	partnersPayment: 'Pago partners',
	partnersRefund: 'Devolución partners',
	paymentInCash: 'Pago en efectivo',
	paymentInPinpadCard: 'Pago con tarjeta en pinpad',
	paymentInPoints: 'Pago con puntos',
	preauthorizationAnnulment: 'Anulación de preautorización',
	preauthorizationInPinpadCard: 'Preautorización en pinpad con tarjeta',
	processPreauthorization: 'Preautorización',
	refundDueInCash: 'Devolución en efectivo',
	refundDueInPoints: 'Devolución en puntos',
	refundDueToCard: 'Devolución a tarjeta',
	webPayment: 'Pago web',
	webRefund: 'Devolución web',
};

export const getPaymentOptions = (locale = 'en'): IPaymentType[] => {
	const texts = locale === 'en' ? paymentTypesEN : paymentTypesES;

	return [
		{
			Channel: PaymentChannelType.OFFICE,
			CustomerName: texts.cardPayment,
			Method: PaymentMethodType.CARD,
			Name: texts.paymentInPinpadCard,
			Operation: PaymentOperationType.SALE,
		},

		{
			Channel: PaymentChannelType.OFFICE,
			CustomerName: texts.cardPreauthorization,
			Method: PaymentMethodType.CARD,
			Name: texts.preauthorizationInPinpadCard,
			Operation: PaymentOperationType.PREAUTHORIZATION,
		},
		{
			Channel: PaymentChannelType.VIRTUAL,
			CustomerName: texts.paymentInCard,
			Method: PaymentMethodType.CARD,
			Name: texts.paymentInCard,
			Operation: PaymentOperationType.SALE,
		},
		{
			Channel: PaymentChannelType.VIRTUAL,
			CustomerName: texts.cardRefund,
			Method: PaymentMethodType.CARD,
			Name: texts.refundDueToCard,
			Operation: PaymentOperationType.REFUND,
		},
		{
			Channel: PaymentChannelType.VIRTUAL,
			CustomerName: texts.cardProcessPreauthorization,
			Method: PaymentMethodType.CARD,
			Name: texts.processPreauthorization,
			Operation: PaymentOperationType.PROCESS_PREAUTHORIZATION,
		},
		{
			Channel: PaymentChannelType.VIRTUAL,
			CustomerName: texts.cardPreauthorizationAnnulment,
			Method: PaymentMethodType.CARD,
			Name: texts.preauthorizationAnnulment,
			Operation: PaymentOperationType.PREAUTHORIZATION_ANNULMENT,
		},
		{
			Channel: PaymentChannelType.VIRTUAL,
			CustomerName: texts.paymentInPoints,
			Method: PaymentMethodType.POINTS,
			Name: texts.paymentInPoints,
			Operation: PaymentOperationType.SALE,
		},
		{
			Channel: PaymentChannelType.VIRTUAL,
			CustomerName: texts.refundDueInPoints,
			Method: PaymentMethodType.POINTS,
			Name: texts.refundDueInPoints,
			Operation: PaymentOperationType.REFUND,
		},
		{
			Channel: PaymentChannelType.OFFICE,
			CustomerName: texts.paymentInCash,
			Method: PaymentMethodType.CASH,
			Name: texts.paymentInCash,
			Operation: PaymentOperationType.SALE,
		},
		{
			Channel: PaymentChannelType.OFFICE,
			CustomerName: texts.refundDueInCash,
			Method: PaymentMethodType.CASH,
			Name: texts.refundDueInCash,
			Operation: PaymentOperationType.REFUND,
		},
		{
			Channel: PaymentChannelType.WEB,
			CustomerName: texts.cardPayment,
			Method: PaymentMethodType.CARD,
			Name: texts.webPayment,
			Operation: PaymentOperationType.SALE,
		},
		{
			Channel: PaymentChannelType.WEB,
			CustomerName: texts.cardRefund,
			Method: PaymentMethodType.CARD,
			Name: texts.webRefund,
			Operation: PaymentOperationType.REFUND,
		},
		{
			Channel: PaymentChannelType.BROKER,
			CustomerName: texts.cardPayment,
			Method: PaymentMethodType.CARD,
			Name: texts.brokerPayment,
			Operation: PaymentOperationType.SALE,
		},
		{
			Channel: PaymentChannelType.BROKER,
			CustomerName: texts.brokerRefund,
			Method: PaymentMethodType.CARD,
			Name: texts.brokerRefund,
			Operation: PaymentOperationType.REFUND,
		},
		{
			Channel: PaymentChannelType.WEB,
			CustomerName: 'Coupon payment',
			Method: PaymentMethodType.COUPON,
			Name: 'Web coupon payment',
			Operation: PaymentOperationType.SALE,
		},
		{
			Channel: PaymentChannelType.WEB,
			CustomerName: 'Web coupon refund',
			Method: PaymentMethodType.COUPON,
			Name: 'Web coupon refund',
			Operation: PaymentOperationType.REFUND,
		},
		{
			Channel: PaymentChannelType.OFFICE,
			CustomerName: 'Office coupon refund',
			Method: PaymentMethodType.COUPON,
			Name: 'Office coupon payment',
			Operation: PaymentOperationType.SALE,
		},
		{
			Channel: PaymentChannelType.OFFICE,
			CustomerName: 'Office coupon refund',
			Method: PaymentMethodType.COUPON,
			Name: 'Office coupon refund',
			Operation: PaymentOperationType.REFUND,
		},
		{
			Channel: PaymentChannelType.PARTNERS,
			CustomerName: texts.cardPayment,
			Method: PaymentMethodType.CARD,
			Name: texts.partnersPayment,
			Operation: PaymentOperationType.SALE,
		},
		{
			Channel: PaymentChannelType.PARTNERS,
			CustomerName: texts.cardRefund,
			Method: PaymentMethodType.CARD,
			Name: texts.partnersRefund,
			Operation: PaymentOperationType.REFUND,
		},
	];
};

export const PaymentType: IPaymentType[] = getPaymentOptions();
