import { DateTime } from 'luxon';

import { getBookingAmounts } from '../../business/booking/BookingAmounts';
import { getTempBookingLineKey } from '../../business/booking/BookingLines';
import { round, sumAmounts } from '../../helpers/numbers';
import type { IBookingLine } from '../../models/entities/BookingLine';
import type { IDiscount } from '../../models/entities/Discount';
import type { IPaymentLine } from '../../models/entities/PaymentLine';
import { BookingLineType } from '../../models/types/BookingLineType';
import { PaymentMethodType } from '../../models/types/PaymentType';

/**
 * En rac tenemos la posibilidad de tener un descuento auto aplicado y que el empleado
 * pueda aplicar un porcentaje de descuento.
 *
 * - Por defecto, aplicaremos el descuento del grupo de vehículo seleccionado
 * - Si el empleado aplica su descuento, se aplicará a la reserva el descuento mayor.
 *
 * The `BookingDiscount` class calculates and applies discounts to booking lines based on vehicle group
 * and employee discounts.
 *
 * @example
 * ```js
 * const bookingDiscount = new BookingDiscount(bookingLines, vehicleGroupDiscount, employeeDiscount);
 * const { amount, code, percentage, updateBookingLines } = bookingDiscount.discountData;
 * ```
 */
export class BookingDiscount {
	/**
	 * The `discountBookingLine` is the booking line that represents the discount.
	 * @private
	 * @type `{IBookingLine | undefined}`
	 */
	private discountBookingLine?: IBookingLine;

	/**
	 * The `rentBookingLine` is the booking line that represents the rent.
	 *
	 * - We will use the amount of this line to calculate the discount
	 *
	 * @private
	 * @type `{IBookingLine | undefined}`
	 */
	private rentBookingLine?: IBookingLine;

	/**
	 * The `updateBookingLines` is the booking lines that will be updated with the discount.
	 *
	 * @private
	 * @type `{IBookingLine[] | undefined}`
	 */
	private updateBookingLines?: IBookingLine[];

	/**
	 * The `discountCode` is the code of the discount.
	 * - Only used if a discount arrives in the vehicle group.
	 *
	 * @private
	 * @type `{string | undefined}`
	 */
	private discountCode?: string;

	/**
	 * The `discountPercentage` is the percentage of the discount.
	 * @private
	 * @type `{number}`
	 */
	private discountPercentage = 0;

	/**
	 * The `discountAmount` is the amount of the discount.
	 * @private
	 * @type `{number}`
	 */
	private discountAmount = 0;

	/**
	 * Calculate and apply discounts to booking lines based on vehicle group and employee discounts.
	 *
	 * Creates a new instance of `BookingDiscount`.
	 * @param bookingLines The current booking lines.
	 * @param vehicleGroupDiscount The vehicle group discount to apply.
	 * @param employeeDiscount The employee discount to apply.
	 */
	constructor(
		private bookingLines: IBookingLine[],
		private vehicleGroupDiscount?: IDiscount | null,
		private employeeDiscount?: number,
	) {
		this.updateBookingLines = this.bookingLines;

		this.rentBookingLine = this.bookingLines.find(
			(bookingLine) => bookingLine.bookingLineType === BookingLineType.VehicleGroup,
		);

		this.discountBookingLine = this.bookingLines.find(
			(bookingLine) => bookingLine.bookingLineType === BookingLineType.Discount,
		);

		this.discountPercentage = this.calculatedDiscountPercentage();
		this.discountAmount = this.calculateDiscountAmount();
		this.updateBookingDiscountLine();
	}

	/**
	 * Updates the booking lines with the discount.
	 * - If the discount is 0, the discount booking line will be removed.
	 * - If the discount is higher than 0, the discount booking line will be added or updated.
	 *
	 * @private
	 */
	private updateBookingDiscountLine() {
		if (this.discountBookingLine && this.discountPercentage > 0) {
			this.updateBookingLines = [
				...this.bookingLines.filter((bookingLine) => bookingLine.bookingLineType !== BookingLineType.Discount),
				{
					...this.discountBookingLine,
					code: this.discountCode || '',
					description: `Discount (${this.discountPercentage} %)`,
					retailAmount: this.discountAmount,
					netAmount: this.discountAmount,
				},
			];
			return;
		}

		if (!this.discountBookingLine && this.discountPercentage > 0) {
			this.discountBookingLine = {
				automatic: true,
				bookingLineType: BookingLineType.Discount,
				code: this.discountCode || '',
				description: `Discount (${this.discountPercentage} %)`,
				initial: false,
				invoiceToAgency: Boolean(this.rentBookingLine?.invoiceToAgency),
				key: getTempBookingLineKey(this.bookingLines),
				package: false,
				price: this.discountAmount,
				quantity: 1,
				quoteDateTime: DateTime.utc().toJSON() as string,
				netAmount: this.discountAmount,
				retailAmount: this.discountAmount,
			};

			this.updateBookingLines = [...this.bookingLines, this.discountBookingLine];

			return;
		}

		this.updateBookingLines = this.bookingLines.filter((line) => line.bookingLineType !== BookingLineType.Discount);
		this.bookingLines = this.bookingLines.filter((line) => line.bookingLineType !== BookingLineType.Discount);
		this.discountBookingLine = undefined;
	}

	/**
	 * Calculates the discount amount.
	 *
	 * - The discount amount is calculated based on the `rentBookingLine` and the `discountPercentage`.
	 *
	 * @private
	 * @returns `number` The discount amount.
	 */
	private calculateDiscountAmount(): number {
		if (this.rentBookingLine && this.discountPercentage) {
			return round((this.rentBookingLine.retailAmount * this.discountPercentage) / 100, 2);
		}

		return 0;
	}

	/**
	 * Calculates the discount percentage.
	 * @summary
	 * - If both `vehicleGroupDiscount` and `employeeDiscount` are set, the highest discount will be applied.
	 * @private
	 * @returns The discount percentage.
	 */
	private calculatedDiscountPercentage(): number {
		this.discountCode = undefined;

		/*
		 * If there is an employee discount (`this.employeeDiscount`) but no vehicle
		 * group discount (`this.vehicleGroupDiscount`). If this condition is true, it means that only the
		 * employee discount should be applied, so the code returns the value of `this.employeeDiscount`.
		 */
		if (this.employeeDiscount && !this.vehicleGroupDiscount) {
			return this.employeeDiscount;
		}

		/*
		 * If there is a `this.vehicleGroupDiscount` but no employee discount (`this.employeeDiscount`).
		 * If this condition is true, it means that only the vehicle group discount should be applied.
		 */
		if (this.vehicleGroupDiscount && !this.employeeDiscount) {
			this.discountCode = this.vehicleGroupDiscount.code;

			return this.vehicleGroupDiscount.percentage;
		}

		/*
		 * If there is a `this.vehicleGroupDiscount` and `this.employeeDiscount`.
		 * If this condition is true, the code returns the highest discount.
		 */
		if (this.vehicleGroupDiscount && this.employeeDiscount) {
			if (this.vehicleGroupDiscount.percentage >= this.employeeDiscount) {
				this.discountCode = this.vehicleGroupDiscount.code;

				return this.vehicleGroupDiscount.percentage;
			}

			this.discountCode = undefined;
			return this.employeeDiscount;
		}

		return 0;
	}

	/**
	 * Gets the discount data.
	 * @returns The discount data.
	 */
	public get discountData() {
		return {
			amount: this.discountAmount,
			code: this.discountCode,
			percentage: this.discountPercentage,
			updateBookingLines: this.updateBookingLines || [],
		};
	}
}

/**
 * The function calculates booking points based on the rent amount and a discount.
 * @param {IBookingLine[]} bookingLines - An array of booking lines, which represent the items or
 * services being booked.
 * @param {IDiscount | null} discount - The `discount` parameter is an object that represents a
 * discount applied to a booking.
 * @returns the calculated booking points based on the rent amount and the discount.
 *
 *
 * ### Calculate by rent amount
 *
 * `amountToPointsRelation / rentAmount = bookingPontsByRentAmount Points`
 * readonly bookingPontsByRentAmount?: number;
 */
export const calculateBookingPointsByRentAmount = (
	bookingLines: IBookingLine[],
	discount: IDiscount | null,
	paymentLines: IPaymentLine[],
) => {
	const { rent } = getBookingAmounts(bookingLines);

	if (!discount) {
		return undefined;
	}

	const { amountToPointsRelation } = discount;

	if (!amountToPointsRelation) {
		return undefined;
	}

	// Recalcula el importe con las líneas de pago y lineas de puntos
	const couponPaymentLines = paymentLines.filter((paymentLine) => paymentLine.method === PaymentMethodType.COUPON);
	const pointPaymentLines = paymentLines.filter((paymentLine) => paymentLine.method === PaymentMethodType.POINTS);

	const rentAmount = rent.total.retail;
	const discountAmount = rent.discount.retail;
	const couponAmount = sumAmounts(couponPaymentLines, 'amount');
	const pointAmount = sumAmounts(pointPaymentLines, 'amount');

	const finalTotalAmount = rentAmount - discountAmount - pointAmount - couponAmount;

	if (finalTotalAmount <= 0) {
		return 0;
	}

	return round(finalTotalAmount / amountToPointsRelation, 0);
};
