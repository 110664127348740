import { createSlice } from '@reduxjs/toolkit';

import {
	branchScheduleClear,
	branchScheduleDelete,
	branchScheduleModify,
	branchScheduleRangeClear,
	branchScheduleRangeDelete,
	branchScheduleRangeInsert,
	branchScheduleRangeModify,
	branchScheduleRangeSearch,
	branchScheduleSearch,
} from '@crac/core/redux/actions/BranchScheduleActions';
import type { IBranchScheduleReducerState } from '@crac/core/redux-store/reducersState/pricing/BranchScheduleReducerState';

const initialState: IBranchScheduleReducerState = {
	branchScheduleDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	branchScheduleModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	branchSchedules: [],
	branchScheduleSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	branchScheduleRanges: [],
	branchScheduleRangeSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	branchScheduleRangeDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	branchScheduleRangeInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	branchScheduleRangeModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const branchScheduleSlice = createSlice({
	name: 'BranchScheduleSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(branchScheduleSearch.pending, (state) => {
				return {
					...state,
					branchScheduleSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(branchScheduleSearch.fulfilled, (state, action) => {
				return {
					...state,
					branchScheduleSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					branchSchedules: action.payload,
				};
			})
			.addCase(branchScheduleSearch.rejected, (state, action) => {
				return {
					...state,
					branchScheduleSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(branchScheduleModify.pending, (state) => {
				return {
					...state,
					branchScheduleModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(branchScheduleModify.fulfilled, (state, action) => {
				return {
					...state,
					branchScheduleModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					branchSchedules: [
						...state.branchSchedules.filter((schedule) => schedule.id !== action.payload.id),
						action.payload,
					],
				};
			})
			.addCase(branchScheduleModify.rejected, (state, action) => {
				return {
					...state,
					branchScheduleModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DELETE
		builder
			.addCase(branchScheduleDelete.pending, (state) => {
				return {
					...state,
					branchScheduleDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(branchScheduleDelete.fulfilled, (state, action) => {
				return {
					...state,
					branchScheduleDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					branchSchedules: state.branchSchedules.filter((schedule) => schedule.id !== action.payload),
				};
			})
			.addCase(branchScheduleDelete.rejected, (state, action) => {
				return {
					...state,
					branchScheduleDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(branchScheduleClear, (state) => {
			return {
				...state,
				branchSchedules: [],
			};
		});
		// ------------------------------------------------------- RANGES -------------------------------------------------------------

		// CLEAR RANGE
		builder.addCase(branchScheduleRangeClear, (state) => {
			return {
				...state,
				branchScheduleRanges: [],
			};
		});
		// SEARCH RANGE
		builder
			.addCase(branchScheduleRangeSearch.pending, (state) => {
				return {
					...state,
					branchScheduleRangeSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(branchScheduleRangeSearch.fulfilled, (state, action) => {
				return {
					...state,
					branchScheduleRangeSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					branchScheduleRanges: action.payload,
				};
			})
			.addCase(branchScheduleRangeSearch.rejected, (state, action) => {
				return {
					...state,
					branchScheduleRangeSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT_RANGE
		builder
			.addCase(branchScheduleRangeInsert.pending, (state) => {
				return {
					...state,
					branchScheduleRangeInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(branchScheduleRangeInsert.fulfilled, (state, action) => {
				return {
					...state,
					branchScheduleRangeInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					branchScheduleRanges: [...state.branchScheduleRanges, ...action.payload],
				};
			})
			.addCase(branchScheduleRangeInsert.rejected, (state, action) => {
				return {
					...state,
					branchScheduleRangeInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY_RANGE
		builder
			.addCase(branchScheduleRangeModify.pending, (state) => {
				return {
					...state,
					branchScheduleRangeModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(branchScheduleRangeModify.fulfilled, (state, action) => {
				return {
					...state,
					branchScheduleRangeModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					branchScheduleRanges: [
						...state.branchScheduleRanges.filter((schedule) => schedule.id !== action.payload.id),
						action.payload,
					],
				};
			})
			.addCase(branchScheduleRangeModify.rejected, (state, action) => {
				return {
					...state,
					branchScheduleRangeModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DELETE_RANGE
		builder
			.addCase(branchScheduleRangeDelete.pending, (state) => {
				return {
					...state,
					branchScheduleRangeDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(branchScheduleRangeDelete.fulfilled, (state, action) => {
				return {
					...state,
					branchScheduleRangeDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					branchScheduleRanges: state.branchScheduleRanges.filter(
						(schedule) => schedule.id !== action.payload,
					),
				};
			})
			.addCase(branchScheduleRangeDelete.rejected, (state, action) => {
				return {
					...state,
					branchScheduleRangeDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const branchScheduleReducer = branchScheduleSlice.reducer;
