import { SaleServiceRateEndpoints } from './SaleServiceRateEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleServiceRate } from '../entities/SaleServiceRate';

export interface ISaleServiceRateDeactivateParams extends IServiceBaseParams {
	ids: number[];
}

export const saleServiceRateDeactivateService = (model: ISaleServiceRateDeactivateParams) => {
	return Api.post<ISaleServiceRate[], ISaleServiceRateDeactivateParams>(SaleServiceRateEndpoints.DEACTIVATE, model);
};
