import { createSlice } from '@reduxjs/toolkit';

import {
	excessTypeClear,
	excessTypeDelete,
	excessTypeInsert,
	excessTypeModify,
	excessTypeSearch,
} from '@crac/core/redux/actions/ExcessTypeActions';
import type { IExcessTypeReducerState } from '@crac/core/redux-store/reducersState/excessType';

const initialState: IExcessTypeReducerState = {
	excessTypes: [],
	excessTypeInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	excessTypeSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	excessTypeModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	excessTypeDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const excessTypeSlice = createSlice({
	name: 'ExcessType',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		/* SEARCH */
		builder
			.addCase(excessTypeSearch.pending, (state) => {
				return {
					...state,
					excessTypeSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessTypeSearch.fulfilled, (state, action) => {
				return {
					...state,
					excessTypeSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					excessTypes: action.payload,
				};
			})
			.addCase(excessTypeSearch.rejected, (state, action) => {
				return {
					...state,
					excessTypeSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/* SEARCH CLEAR */
		builder.addCase(excessTypeClear, (state) => {
			return {
				...state,
				excessTypes: [],
			};
		});
		/* INSERT*/
		builder
			.addCase(excessTypeInsert.pending, (state) => {
				return {
					...state,
					excessTypeInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessTypeInsert.fulfilled, (state, action) => {
				return {
					...state,
					excessTypes: [...state.excessTypes, action.payload],
					excessTypeInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessTypeInsert.rejected, (state, action) => {
				return {
					...state,
					excessTypeInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/* MODIFY */
		builder
			.addCase(excessTypeModify.pending, (state) => {
				return {
					...state,
					excessTypeModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessTypeModify.fulfilled, (state, action) => {
				const { code } = action.payload;
				return {
					...state,
					excessTypes: state.excessTypes.map((excess) => (excess.code === code ? action.payload : excess)),
					excessTypeModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessTypeModify.rejected, (state, action) => {
				return {
					...state,
					excessTypeModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/* DELETE */
		builder
			.addCase(excessTypeDelete.pending, (state) => {
				return {
					...state,
					excessTypeDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessTypeDelete.fulfilled, (state, action) => {
				const { code, name } = action.payload;
				return {
					...state,
					excessTypes: state.excessTypes.filter((excess) => !(excess.code === code && excess.name === name)),
					excessTypeDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessTypeDelete.rejected, (state, action) => {
				return {
					...state,
					excessTypeDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const excessTypeReducer = excessTypeSlice.reducer;
