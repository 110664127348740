import { createSlice } from '@reduxjs/toolkit';

import { toggleFeedBackForm } from '@crac/core/redux/actions/FeedBackActions';

export interface IFeedBackState {
	isOpen: boolean;
}

const initialState: IFeedBackState = {
	isOpen: false,
};

const FeedBackSlice = createSlice({
	name: 'FeedBack',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(toggleFeedBackForm, (state, action) => {
			return {
				...state,
				isOpen: action.payload,
			};
		});
	},
});

export const feedBackReducer = FeedBackSlice.reducer;
