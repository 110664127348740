import { createSlice } from '@reduxjs/toolkit';

import type { IFine } from '@crac/core/models/entities/Fine';
import {
	fleetFineClear,
	fleetFineInsert,
	fleetFineModify,
	fleetFineSearch,
} from '@crac/core/redux/actions/FleetFineActions';
import type { IFleetFineReducerState } from '@crac/core/redux-store/reducersState/fleet/FleetFineReducerState';

const initialState: IFleetFineReducerState = {
	fleetFineInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetFines: [],
	fleetFineSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetFineModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const fineSlice = createSlice({
	name: 'FineSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(fleetFineClear, (state) => {
			return {
				...state,
				fleetFines: [],
			};
		});
		// SEARCH
		builder
			.addCase(fleetFineSearch.pending, (state) => {
				return {
					...state,
					fleetFineSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineSearch.fulfilled, (state, action) => {
				return {
					...state,
					fleetFineSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetFines: action.payload,
				};
			})
			.addCase(fleetFineSearch.rejected, (state, action) => {
				return {
					...state,
					fleetFineSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(fleetFineInsert.pending, (state) => {
				return {
					...state,
					fleetFineInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineInsert.fulfilled, (state, action) => {
				return {
					...state,
					fleetFines: [...state.fleetFines, action.payload],
					fleetFineInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineInsert.rejected, (state, action) => {
				return {
					...state,
					fleetFineInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(fleetFineModify.pending, (state) => {
				return {
					...state,
					fleetFineModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineModify.fulfilled, (state, action) => {
				const { code } = action.payload as IFine;
				const fleetFines = state.fleetFines?.map((fine) => (fine.code === code ? { ...action.payload } : fine));
				return {
					...state,
					fleetFines,
					fleetFineModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineModify.rejected, (state, action) => {
				return {
					...state,
					fleetFineModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const fineReducer = fineSlice.reducer;
