import { useEffect, useState } from 'react';

export interface IWindowSize {
	height?: number;
	width?: number;
}
export const useWindowSize = (): IWindowSize => {
	const isClient = typeof window === 'object';

	const getSize = (): IWindowSize => {
		return {
			height: isClient ? window.innerHeight : undefined,
			width: isClient ? window.innerWidth : undefined,
		};
	};

	const [windowSize, setWindowSize] = useState<IWindowSize>(getSize);

	useEffect(() => {
		const handleResize = () => {
			setWindowSize(getSize());
		};

		window.addEventListener('resize', handleResize);
		return () => window.removeEventListener('resize', handleResize);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	// Empty array ensures that effect is only run on mount and unmount

	return windowSize;
};
