import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { vehicleClear, vehicleSearch, vehicleSearchClear } from '@crac/core/redux/actions/VehicleActions';
import type { IVehicleSearchReducerState } from '@crac/core/redux-store/reducersState/vehicle/SearchReducerState';
const initialState: IVehicleSearchReducerState = {
	vehicleSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicles: [],
};

const searchSlice = createSlice({
	name: 'VehicleSearchSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		/**
		 * CLEAR SEARCH
		 */
		builder.addCase(vehicleSearchClear, (state) => {
			return {
				...state,
				vehicles: [],
			};
		});
		// SEARCH
		builder
			.addCase(vehicleSearch.pending, (state) => {
				return {
					...state,
					vehicleSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleSearch.fulfilled, (state, action) => {
				return {
					...state,
					vehicleSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: deepCopy(action.payload),
				};
			})
			.addCase(vehicleSearch.rejected, (state, action) => {
				return {
					...state,
					vehicleSearchRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR
		 * CLEAR CACHE
		 */
		builder.addMatcher(isAnyOf(vehicleClear, clearGlobalCache), () => {
			return initialState;
		});
	},
});

export const searchReducer = searchSlice.reducer;
