export enum ExpedientEndPoints {
	/**
	 * Expedient add
	 * @host `/expedient/add/`
	 */
	ADD = '/expedient/add/',

	/**
	 * Expedient get by vehicle
	 * @host `/expedient/getbyvehicle/`
	 */
	GET_BY_VEHICLE = '/expedient/getbyvehicle/',
}
