import type { ISpendingType } from '../../models/entities/SpendingType';
import { Api } from '../../modules/shared/api';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { SpendingTypeEndPoints } from '../endPoints/SpendingTypeEndPoints';

/**
 * Get all spending types
 * @return {Promise} `Array<SpendingTypePropType>`
 */
export const spendingTypeServiceGetAll = (model: IServiceBaseParams): Promise<ServiceResponse<ISpendingType[]>> => {
	return Api.get<ISpendingType[], IServiceBaseParams>(SpendingTypeEndPoints.GET_ALL, model);
};
