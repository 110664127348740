import type { IInvoice } from '../../models/entities/Invoice';
import type { ILink } from '../../models/entities/Link';
import type {
	IInvoiceGetByPlateNumberParams,
	IInvoiceGetByVehicleAndCodeParams,
	IInvoiceGetLinkByIdParams,
} from '../../models/serviceParams/InvoiceParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { InvoiceEndPoints } from '../endPoints/InvoiceEndpoints';

/**
 * get invoices by plate number
 * @param string `plateNumber`
 * @returns `Array<IInvoice>`
 */
export const invoiceServiceGetByPlateNumber = (
	model: IInvoiceGetByPlateNumberParams,
): Promise<ServiceResponse<IInvoice[]>> => {
	return Api.get<IInvoice[], IInvoiceGetByPlateNumberParams>(InvoiceEndPoints.GET_BY_PLATE_NUMBER, model);
};

/**
 * get invoices by plate number and invoice code
 * @param string `plateNumber`
 * @param string `invoiceCode`
 * @returns `Array<IInvoice>`
 */
export const invoiceServiceGetByVehicleAndCode = (
	model: IInvoiceGetByVehicleAndCodeParams,
): Promise<ServiceResponse<IInvoice>> => {
	return Api.get<IInvoice, IInvoiceGetByVehicleAndCodeParams>(InvoiceEndPoints.GET_BY_VEHICLE_AND_CODE, model);
};

export const invoiceServiceGetLinkById = (model: IInvoiceGetLinkByIdParams): Promise<ServiceResponse<ILink>> => {
	return Api.get<ILink, IInvoiceGetLinkByIdParams>(InvoiceEndPoints.GET_LINK_BY_ID, model);
};
