import { createSlice } from '@reduxjs/toolkit';

import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { ICountry } from '@crac/core/models/entities/Country';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { countryGetAll } from '@crac/core/redux/actions/CountryActions';
import { employeeLoginAndLoadCachedData } from '@crac/core/redux/actions/EmployeeActions';
import type { ICountryReducerState } from '@crac/core/redux-store/reducersState/country';
export const countriesStorageKey = 'countries';
const { getItem, setItem, removeItem } = managerLocalStorage();

const initialState: ICountryReducerState = {
	countries: (getItem(countriesStorageKey) as ICountry[]) || [],
	countriesGetAllRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const countrySlice = createSlice({
	name: 'CountrySlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			removeItem(countriesStorageKey);
			return { ...initialState, countries: [] };
		});
		// COUNTRY GET ALL
		builder
			.addCase(countryGetAll.pending, (state) => {
				return {
					...state,
					countries: [],
					countriesGetAllRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(countryGetAll.fulfilled, (state, action) => {
				setItem(countriesStorageKey, action.payload);
				return {
					...state,
					countries: action.payload,
					countriesGetAllRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(countryGetAll.rejected, (state, action) => {
				return {
					...state,
					countriesGetAllRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(employeeLoginAndLoadCachedData.fulfilled, (state, action) => {
				setItem(countriesStorageKey, action.payload.countries);
				return {
					...state,
					countries: action.payload.countries,
				};
			});
	},
});

export const countryReducer = countrySlice.reducer;
