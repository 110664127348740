import { styled } from 'styled-components';

import { Card } from '../../../external/reactstrap/card/Card';
import { CardBody } from '../../../external/reactstrap/card/CardBody';
import { Input } from '../../../external/reactstrap/input';

export const LoginStyled = styled.div`
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	// height: 100vh;
`;

export const LoginCardBody = styled(CardBody)`
	padding: 1rem;
`;

export const LoginCard = styled(Card)`
	margin-bottom: 0;
	width: 320px;

	@media (min-width: 420px) {
		width: 350px;
	}
`;

export const LoginTitle = styled.p`
	text-align: center;
`;

export const LoginAcceptPrivacyPolicy = styled.div`
	cursor: pointer;
	margin-bottom: 1rem;
	align-items: center;
	font-size: 0.8rem;

	.policy-check {
		display: flex;
		align-items: flex-start;
	}

	.badge-danger {
		width: 100%;
		text-align: flex-start;
	}

	.content {
		display: flex;
		align-items: center;
		justify-content: flex-start;
	}

	p {
		margin: 0;
		word-wrap: break-word;
	}

	.form-label {
		margin-bottom: 0;
		cursor: pointer;
	}
`;

export const LanguageSwitch = styled.div`
	cursor: pointer;

	label {
		cursor: pointer;
	}
`;

export const InputLanguageSwitch = styled(Input)`
	cursor: pointer;
`;
