'use client';
import type { FC } from 'react';
import React from 'react';

import { Dropdown as RSDropdown } from 'reactstrap';
import type { DropdownProps as RSDropdownProps } from 'reactstrap/types/lib/Dropdown';

export type DropdownProps = RSDropdownProps;

export const Dropdown: FC<DropdownProps> = ({ children, ...props }) => {
	return <RSDropdown {...props}>{children}</RSDropdown>;
};
