import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { IEmployee } from '@crac/core/models/entities/Employee';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	employeeChangeBranch,
	employeeChangePassword,
	employeeLogIn,
	employeeLoginAndLoadCachedData,
	employeeUpdate,
} from '@crac/core/redux/actions/EmployeeActions';
import type { IEmployeeReducerState } from '@crac/core/redux-store/reducersState/employee';
export const employeeStorageKey = 'employee';
export const tokenStorageKey = 'token';
const { getItem, setItem, removeItem } = managerLocalStorage();

type EmployeeReducerState = Omit<IEmployeeReducerState, 'employee'> & {
	employee: IEmployee | null;
};

const initialState: EmployeeReducerState = {
	changeBranch: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	changePassword: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	employee: (getItem(employeeStorageKey) as IEmployee) || null,
	login: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	token: (getItem(tokenStorageKey) as string) || undefined,
};

const employeeSlice = createSlice({
	name: 'EmployeeSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// LOGIN
		builder
			.addCase(employeeLogIn.pending, (state) => {
				return {
					...state,
					login: {
						...state.login,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(employeeLogIn.fulfilled, (state, action) => {
				setItem(employeeStorageKey, action.payload);
				setItem(tokenStorageKey, action.payload.token);
				return {
					...state,
					employee: deepCopy(action.payload),
					login: {
						...state.login,
						inProgress: false,
						messages: [],
						ok: true,
					},
					token: action.payload.token,
				};
			})
			.addCase(employeeLogIn.rejected, (state, action) => {
				return {
					...state,
					login: {
						...state.login,
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});

		//LOGIN AND LOAD CACHE DATA
		builder
			.addCase(employeeLoginAndLoadCachedData.pending, (state) => {
				return {
					...state,
					login: {
						...state.login,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(employeeLoginAndLoadCachedData.fulfilled, (state, action) => {
				setItem(employeeStorageKey, action.payload.employee);
				setItem(tokenStorageKey, action.payload.employee.token);
				return {
					...state,
					employee: deepCopy(action.payload.employee),
					login: {
						...state.login,
						inProgress: false,
						messages: [],
						ok: true,
					},
					token: action.payload.employee.token,
				};
			})
			.addCase(employeeLoginAndLoadCachedData.rejected, (state, action) => {
				return {
					...state,
					login: {
						...state.login,
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CHANGE BRANCH
		builder
			.addCase(employeeChangeBranch.pending, (state) => {
				return {
					...state,
					changeBranch: {
						...state.changeBranch,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(employeeChangeBranch.fulfilled, (state, action) => {
				setItem(employeeStorageKey, action.payload);
				setItem(tokenStorageKey, action.payload.token);
				return {
					...state,
					changeBranch: {
						...state.changeBranch,
						inProgress: false,
						messages: [],
						ok: true,
					},
					employee: deepCopy(action.payload),
					token: action.payload.token,
				};
			})
			.addCase(employeeChangeBranch.rejected, (state, action) => {
				return {
					...state,
					changeBranch: {
						...state.changeBranch,
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CHANGE PASSWORD
		builder
			.addCase(employeeChangePassword.pending, (state) => {
				return {
					...state,
					changePassword: {
						...state.changePassword,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(employeeChangePassword.fulfilled, (state) => {
				return {
					...state,
					changePassword: {
						...state.changePassword,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(employeeChangePassword.rejected, (state, action) => {
				return {
					...state,
					changePassword: {
						...state.changePassword,
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		//
		builder.addCase(employeeUpdate, (state, action) => {
			return {
				...state,
				employee: deepCopy(action.payload),
				token: action.payload.token,
			};
		});
		/**
		 * CLEAR CACHE
		 * UNAUTHORIZED
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache), () => {
			removeItem(employeeStorageKey);
			removeItem(tokenStorageKey);

			return initialState;
		});
	},
});

export const employeeReducer = employeeSlice.reducer;
