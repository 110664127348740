import type { ISupplier } from '../../models/entities/Supplier';
import type {
	ISupplierGetAllParams,
	ISupplierGetByCodeParams,
	ISupplierInsertCarrierParams,
	ISupplierInsertWorkshopParams,
	ISupplierModifyWorkshopParams,
	ISupplierSearchParams,
} from '../../models/serviceParams/SupplierParams';
import { Api } from '../../modules/shared/api';
import { SupplierEndPoints } from '../endPoints/SupplierEndPoints';

/**
 * Get supplier by code
 *  {string} code Supplier code
 * @return {Supplier} Supplier object
 *
 * @returns {Promise} `SupplierPropType`
 */
export const supplierServiceGetByCode = (model: ISupplierGetByCodeParams) =>
	Api.get<ISupplier, ISupplierGetByCodeParams>(SupplierEndPoints.GET_BY_CODE, model);

/**
 * Search suppliers
 * @param `ISupplierSearchParams`
 * @returns {Supplier[]} `ISupplier[]`
 */
export const supplierServiceSearch = (model: ISupplierSearchParams) =>
	Api.get<ISupplier[], ISupplierSearchParams>(SupplierEndPoints.SEARCH, model);

/**
 * Get supplier by branch code
 *  {string} branchCode Branch ccreationMethoode
 * @return {Promise} `Array<SupplierPropType>`
 */
export const supplierServiceGetAll = (model: ISupplierGetAllParams) =>
	Api.get<ISupplier[], ISupplierGetAllParams>(SupplierEndPoints.GET_ALL, model);

export const supplierServiceInsertWorkshop = (model: ISupplierInsertWorkshopParams) =>
	Api.post<ISupplier, ISupplierInsertWorkshopParams>(SupplierEndPoints.INSERT_WORKSHOP, model);

export const supplierServiceModifyWorkshop = (model: ISupplierModifyWorkshopParams) =>
	Api.post<ISupplier, ISupplierModifyWorkshopParams>(SupplierEndPoints.MODIFY_WORKSHOP, model);

export const supplierServiceInsertCarrier = (model: ISupplierInsertCarrierParams) =>
	Api.post<ISupplier, ISupplierInsertCarrierParams>(SupplierEndPoints.INSERT_CARRIER, model);
