import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const CouponConfiguration = lazyWithRetry(() => import('./pages/CouponConfiguration'));
const CouponConfigurationNew = lazyWithRetry(() => import('./pages/CouponConfigurationNew'));
const CouponConfigurationModify = lazyWithRetry(() => import('./pages/CouponConfigurationModify'));

export const couponConfigurationRoutes: { [key: number]: IRouteConfig } = {};

// COUPON CONFIGURATION
couponConfigurationRoutes[routesIdMap.PricingCouponConfiguration] = {
	component: CouponConfiguration,
	exact: true,
	id: routesIdMap.PricingCouponConfiguration,
	name: 'Partner coupon configuration',
	menuText: 'Pre-applied coupon',
	path: '/pricing/coupon-configuration',
	permissions: getPricingPermission([PermissionsType.PricingCouponConfiguration]),
	childrens: [routesIdMap.PricingCouponConfigurationModify, routesIdMap.PricingCouponConfigurationNew],
	icon: 'percent',
};

couponConfigurationRoutes[routesIdMap.PricingCouponConfigurationNew] = {
	component: CouponConfigurationNew,
	exact: true,
	id: routesIdMap.PricingCouponConfigurationNew,
	name: 'New partner coupon configuration',
	path: '/pricing/coupon-configuration/new',
	permissions: getPricingPermission([PermissionsType.PricingCouponConfiguration]),
	icon: 'plus',
	menuText: 'New partner coupon configuration',
};

couponConfigurationRoutes[routesIdMap.PricingCouponConfigurationModify] = {
	component: CouponConfigurationModify,
	exact: true,
	id: routesIdMap.PricingCouponConfigurationModify,
	name: 'Coupon configuration modify',
	path: '/pricing/coupon-configuration/:id',
	permissions: getPricingPermission([PermissionsType.PricingCouponConfiguration]),
	icon: 'pencil',
};
