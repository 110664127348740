import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import type { ISaleService } from '../../entities/SaleService';
import type { ISaleServiceDeleteParams } from '../../services/SaleServiceDeleteService';
import { saleServiceDeleteService } from '../../services/SaleServiceDeleteService';
import type { ISaleServiceInsertParams } from '../../services/SaleServiceInsertService';
import { saleServiceInsertService } from '../../services/SaleServiceInsertService';
import type { ISaleServiceModifyParams } from '../../services/SaleServiceModifyService';
import { saleServiceModifyService } from '../../services/SaleServiceModifyService';
import type { ISaleServiceSearchParams } from '../../services/SaleServiceSearchService';
import { saleServiceSearchService } from '../../services/SaleServiceSearchService';

//* SEARCH
export const saleServiceSearch = createAsyncAction<ISaleService[], ISaleServiceSearchParams>(
	'saleService/search',
	saleServiceSearchService,
);

//* INSERT
export const saleServiceInsert = createAsyncAction<ISaleService, ISaleServiceInsertParams>(
	'saleService/insert',
	saleServiceInsertService,
);

//* MODIFY
export const saleServiceModify = createAsyncAction<ISaleService, ISaleServiceModifyParams>(
	'saleService/modify',
	saleServiceModifyService,
);

//* DELETE
export const saleServiceDelete = createAsyncAction<ISaleService, ISaleServiceDeleteParams>(
	'saleService/delete',
	saleServiceDeleteService,
);

//* CLEAR
export const saleServicesClear = createSyncAction('saleServices/clear');
