import { useEffect } from 'react';

import { useRouteError } from 'react-router-dom';

import { useAlertMessage } from '~/features/shared/hooks/useAlertMessage';

export const RouteErrorBoundary = () => {
	const error = useRouteError() as Error;
	const { showAlert } = useAlertMessage();

	useEffect(() => {
		if (error && error.message) {
			showAlert({ message: error.message });
		}
	}, [error, showAlert]);

	return null;
};
