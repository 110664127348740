import type {
	IFleetMovementRestrictionRemoveParams,
	IFleetMovementRestrictionUpdateParams,
} from '../../models/serviceParams/FleetMovementRestrictionParams';
import { Api } from '../../modules/shared/api';
import type { IVehicle } from '../../modules/vehicle/entities/Vehicle';
import { FleetMovementRestrictionEndPoints } from '../endPoints/FleetMovementRestrictionEndPoints';

export const fleetMovementRestrictionServiceUpdate = (model: IFleetMovementRestrictionUpdateParams) => {
	return Api.post<IVehicle[], IFleetMovementRestrictionUpdateParams>(FleetMovementRestrictionEndPoints.UPDATE, model);
};

export const fleetMovementRestrictionServiceRemove = (model: IFleetMovementRestrictionRemoveParams) => {
	return Api.post<IVehicle[], IFleetMovementRestrictionRemoveParams>(FleetMovementRestrictionEndPoints.REMOVE, model);
};
