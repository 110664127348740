import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { vehicleChangeState } from '@crac/core/redux/actions/VehicleActions';
import type { IChangeStateReducerState } from '@crac/core/redux-store/reducersState/vehicle/ChangeStateReducerState';

const initialState: IChangeStateReducerState = {
	changeState: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicle: undefined,
};

const changeStateSlice = createSlice({
	name: 'VehicleChangeStateSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => initialState);
		// VEHICLE CHANGE STATE
		builder
			.addCase(vehicleChangeState.pending, (state) => {
				return {
					...state,
					changeState: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleChangeState.fulfilled, (state, action) => {
				return {
					...state,
					changeState: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicle: deepCopy(action.payload),
				};
			})
			.addCase(vehicleChangeState.rejected, (state, action) => {
				return {
					...state,
					changeState: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const changeStateReducer = changeStateSlice.reducer;
