import { createAction } from '@reduxjs/toolkit';

import {
	saleOrderLineServiceDelete,
	saleOrderLineServiceGetByOrderId,
	saleOrderLineServiceInsert,
	saleOrderServiceGetById,
	saleOrderServiceInsert,
	saleOrderServiceSearch,
} from '../../data/services/FleetSaleOrderService';
import type { ISaleOrder } from '../../models/entities/SaleOrder';
import type { ISaleOrderLine } from '../../models/entities/SaleOrderLine';
import type {
	ISaleOrderGetByIdParams,
	ISaleOrderInsertParams,
	ISaleOrderLineDeleteParams,
	ISaleOrderLineGetByOrderIdParams,
	ISaleOrderLineInsertParams,
	ISaleOrderSearchParams,
} from '../../models/serviceParams/FleetSaleOrderParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Inserts a new vehicle sale order.
 * @param {ISaleOrder} payload - The sale order to be inserted.
 * @param {ISaleOrderInsertParams} params - Parameters for the insertion of the sale order.
 * @returns {Promise<void>} - A promise that resolves to the inserted sale order.
 */
export const saleOrderInsert = createAsyncAction<ISaleOrder, ISaleOrderInsertParams>(
	'saleOrder/insert',
	saleOrderServiceInsert,
);

/**
 * Searches for vehicle sale orders.
 * @param {ISaleOrder[]} payload - The sale orders that match the search criteria.
 * @param {ISaleOrderSearchParams} params - Parameters for the search of sale orders.
 * @returns {Promise<void>} - A promise that resolves to the sale orders that match the search criteria.
 */
export const saleOrderSearch = createAsyncAction<ISaleOrder[], ISaleOrderSearchParams>(
	'saleOrder/search',
	saleOrderServiceSearch,
);

/**
 * Clears the vehicle sale order state.
 * @returns {void}
 */
export const saleOrderClear = createAction('saleOrder/clear');

/**
 * Gets the vehicle sale order lines by order id.
 * @param {ISaleOrderLine[]} payload - The sale order lines that match the order id.
 * @param {ISaleOrderLineGetByOrderIdParams} params - Parameters for the search of sale order lines.
 * @returns {Promise<void>} - A promise that resolves to the sale order lines that match the order id.
 */
export const saleOrderLineGetByOrderId = createAsyncAction<ISaleOrderLine[], ISaleOrderLineGetByOrderIdParams>(
	'saleOrderLines/getByOrderId',
	saleOrderLineServiceGetByOrderId,
);

/**
 * Inserts a new vehicle sale order line.
 * @param {ISaleOrderLine[]} payload - The sale order lines to be inserted.
 * @param {ISaleOrderLineInsertParams} params - Parameters for the insertion of the sale order lines.
 * @returns {Promise<void>} - A promise that resolves to the inserted sale order lines.
 */
export const saleOrderLineInsert = createAsyncAction<ISaleOrderLine[], ISaleOrderLineInsertParams>(
	'saleOrderLine/insert',
	saleOrderLineServiceInsert,
);

/**
 * Deletes a vehicle sale order line.
 * @param {boolean} payload - A boolean indicating whether the sale order line was deleted.
 * @param {ISaleOrderLineDeleteParams} params - Parameters for the deletion of the sale order line.
 * @returns {Promise<void>} - A promise that resolves to a boolean indicating whether the sale order line was deleted.
 */
export const saleOrderLineDelete = createAsyncAction<boolean, ISaleOrderLineDeleteParams>(
	'saleOrderLine/delete',
	saleOrderLineServiceDelete,
);

/**
 * Gets a vehicle sale order by id.
 * @param {ISaleOrder} payload - The sale order that matches the id.
 * @param {ISaleOrderGetByIdParams} params - Parameters for the search of the sale order.
 * @returns {Promise<void>} - A promise that resolves to the sale order that matches the id.
 */
export const saleOrderGetById = createAsyncAction<ISaleOrder, ISaleOrderGetByIdParams>(
	'saleOrder/getById',
	saleOrderServiceGetById,
);
