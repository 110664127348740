import type { IProvince } from '../../models/entities/Province';
import type {
	IProvinceGetByCodeParams,
	IProvinceGetByCountryAndPostCodeParams,
	IProvinceGetByCountryParams,
} from '../../models/serviceParams/ProvinceParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { ProvinceEndPoints } from '../endPoints/ProvinceEndPoints';

export const provinceServiceGetByCode = (model: IProvinceGetByCodeParams): Promise<ServiceResponse<IProvince>> => {
	return Api.get<IProvince, IProvinceGetByCodeParams>(ProvinceEndPoints.GET_BY_CODE, model);
};

/**
 * Get by country
 *  {string} countryCode Country code
 * @return {Array<Province>} List Provinc
 */
export const provinceServiceGetByCountry = (
	model: IProvinceGetByCountryParams,
): Promise<ServiceResponse<IProvince[]>> => {
	return Api.get<IProvince[], IProvinceGetByCountryParams>(ProvinceEndPoints.GET_BY_COUNTRY, model);
};

/**
 * Get provinces by country code and postcode
 *  {string} countryCode Country code
 *  {string} postCode Post code
 * @return {Array<Province>} List Province
 */
export const provinceServiceGetByCountryAndPostCode = (
	model: IProvinceGetByCountryAndPostCodeParams,
): Promise<ServiceResponse<IProvince[]>> => {
	return Api.get<IProvince[], IProvinceGetByCountryAndPostCodeParams>(
		ProvinceEndPoints.GET_BY_COUNTRY_AND_POSTCODE,
		model,
	);
};
