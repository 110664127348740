import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { IBranch } from '@crac/core/models/entities/Branch';
import { branchGetAll, branchGetByPartnerCodeAndType } from '@crac/core/redux/actions/BranchActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { employeeLoginAndLoadCachedData } from '@crac/core/redux/actions/EmployeeActions';
import type { IBranchReducerState } from '@crac/core/redux-store/reducersState/branch';
export const BRANCHES_GET_ALL_KEY = 'branches';
const BRANCH_BY_PARTNER_KEY = 'branchesByPartner';
const { getItem, setItem, removeItem } = managerLocalStorage();

const initialState: IBranchReducerState = {
	branchByPartnerRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	branchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	branches: (getItem(BRANCHES_GET_ALL_KEY) as IBranch[]) || [],
	branchesByPartner: getItem(BRANCH_BY_PARTNER_KEY) || [],
	nearbyBranches: [],
	nearbyBranchesAvailabilityRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const branchSlice = createSlice({
	name: 'BranchSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			removeItem(BRANCHES_GET_ALL_KEY);
			removeItem(BRANCH_BY_PARTNER_KEY);
			return { ...initialState, branches: [] };
		});
		// GET ALL
		builder
			.addCase(branchGetAll.pending, (state) => {
				return {
					...state,
					branchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(branchGetAll.fulfilled, (state, action) => {
				setItem(BRANCHES_GET_ALL_KEY, action.payload);
				return {
					...state,
					branchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					branches: deepCopy(action.payload),
				};
			})
			.addCase(branchGetAll.rejected, (state, action) => {
				return {
					...state,
					branchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(employeeLoginAndLoadCachedData.fulfilled, (state, action) => {
				setItem(BRANCHES_GET_ALL_KEY, action.payload.branches);
				return {
					...state,
					branches: deepCopy(action.payload.branches),
				};
			});

		// GET BY PARTNER
		builder
			.addCase(branchGetByPartnerCodeAndType.pending, (state) => {
				return {
					...state,
					branchByPartnerRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(branchGetByPartnerCodeAndType.fulfilled, (state, action) => {
				setItem(BRANCH_BY_PARTNER_KEY, action.payload);
				return {
					...state,
					branchByPartnerRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					branchesByPartner: deepCopy(action.payload),
				};
			})
			.addCase(branchGetByPartnerCodeAndType.rejected, (state, action) => {
				return {
					...state,
					branchByPartnerRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const branchesReducer = branchSlice.reducer;
