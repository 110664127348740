import { createAction } from '@reduxjs/toolkit';

import {
	emailServiceContactFormBuyCar,
	emailServiceSendBookingAgency,
	emailServiceSendContactFormBusiness,
	emailServiceSendCouponByCustomerCode,
	emailServiceSendDocument,
	emailServiceSendFeedBack,
	emailServiceSendFiturForm,
} from '../../data/services/EmailService';
import type { IPresent } from '../../models/entities/Present';
import type {
	IEmailSendBookingAgencyParams,
	IEmailSendContactFormBusinessParams,
	IEmailSendContactFormBuyCarParams,
	IEmailSendCouponByCustomerCodeParams,
	IEmailSendDocumentParams,
	IEmailSendFeedBackParams,
	IEmailSendFiturFormParams,
} from '../../models/serviceParams/EmailParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Sends a document via email.
 *
 * @param {boolean} payload - The result of the email sending operation.
 * @param {IEmailSendDocumentParams} params - Parameters detailing the document and recipient information.
 * @returns {Promise<void>} - A promise that resolves after the email is sent.
 */
export const emailSendDocument = createAsyncAction<boolean, IEmailSendDocumentParams>(
	'email/sendDocument',
	emailServiceSendDocument,
);

/**
 * Sends feedback via email.
 *
 * @param {boolean} payload - The result of the email sending operation.
 * @param {IEmailSendFeedBackParams} params - Parameters detailing the feedback and recipient information.
 * @returns {Promise<void>} - A promise that resolves after the email is sent.
 */
export const emailSendFeedback = createAsyncAction<boolean, IEmailSendFeedBackParams>(
	'email/sendFeedback',
	emailServiceSendFeedBack,
);

/**
 * Sends booking agency information via email.
 *
 * @param {boolean} payload - The result of the email sending operation.
 * @param {IEmailSendBookingAgencyParams} params - Parameters detailing the booking agency information and recipient.
 * @returns {Promise<void>} - A promise that resolves after the email is sent.
 */
export const emailSendBookingAgency = createAsyncAction<boolean, IEmailSendBookingAgencyParams>(
	'email/sendBookingAgency',
	emailServiceSendBookingAgency,
);

/**
 * Sends a coupon to a customer via email based on their customer code.
 *
 * @param {boolean} payload - The result of the email sending operation.
 * @param {IEmailSendCouponByCustomerCodeParams} params - Parameters detailing the coupon and customer information.
 * @returns {Promise<void>} - A promise that resolves after the email is sent.
 */
export const emailSendCouponByCustomerCode = createAsyncAction<boolean, IEmailSendCouponByCustomerCodeParams>(
	'email/sendCouponByCustomerCode',
	emailServiceSendCouponByCustomerCode,
);

/**
 * Sends a contact form for business inquiries via email.
 *
 * @param {boolean} payload - The result of the email sending operation.
 * @param {IEmailSendContactFormBusinessParams} params - Parameters detailing the business contact form information.
 * @returns {Promise<void>} - A promise that resolves after the email is sent.
 */
export const emailSendContactFormBusiness = createAsyncAction<boolean, IEmailSendContactFormBusinessParams>(
	'email/sendContactFormBusiness',
	emailServiceSendContactFormBusiness,
);

/**
 * Sends a contact form for car purchase inquiries via email.
 *
 * @param {boolean} payload - The result of the email sending operation.
 * @param {IEmailSendContactFormBuyCarParams} params - Parameters detailing the car purchase contact form information.
 * @returns {Promise<void>} - A promise that resolves after the email is sent.
 */
export const emailSendContactFormBuyCar = createAsyncAction<boolean, IEmailSendContactFormBuyCarParams>(
	'email/sendContactFormBuyCar',
	emailServiceContactFormBuyCar,
);

/**
 * Sends a FITUR (International Tourism Trade Fair) form via email.
 *
 * @param {IPresent} payload - The result of the email sending operation.
 * @param {IEmailSendFiturFormParams} params - Parameters detailing the FITUR form information.
 * @returns {Promise<void>} - A promise that resolves after the email is sent.
 */
export const emailSendFiturForm = createAsyncAction<IPresent, IEmailSendFiturFormParams>(
	'email/sendFiturForm',
	emailServiceSendFiturForm,
);

/**
 * Resets the state related to sending the FITUR form.
 */
export const emailSendFiturFormReset = createAction('email/sendFiturFormReset');
