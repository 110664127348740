import { useCallback } from 'react';

import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';

/**
 * USE ONLY IN LOGIN PAGE
 * - Redirect to the previous page after login
 * - Redirect to the hostname if the hostname is in the query string
 * - Redirect to the backUrl if the backUrl is in the query string
 * - Redirect to the previous page if the from is in the location state
 * - Redirect to the hostname if the hostname is in the location state
 * - Redirect to the backUrl if the backUrl is in the location state
 *
 * @returns handleRedirect function
 */
export const useLoginRedirect = () => {
	const history = useNavigate();
	const location = useLocation();

	const handleRedirect = useCallback(() => {
		let redirectUrl = '/';

		if (location.search) {
			const { backUrl, hostname } = queryString.parse(location.search);
			if (hostname) {
				redirectUrl = hostname as string;
			}

			if (backUrl) {
				redirectUrl = backUrl as string;
			}
		}

		const state = location.state as { from: Location };

		if (state) {
			const { search, pathname } = state.from;

			if (search) {
				redirectUrl = `${pathname}${search}`;
				const { hostname } = queryString.parse(search);
				if (hostname) {
					redirectUrl = hostname as string;
				}
			} else if (pathname) {
				redirectUrl = pathname;
			}
		}

		history(redirectUrl, { replace: true });
	}, [history, location.search, location.state]);

	return { handleRedirect };
};
