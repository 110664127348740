export enum SaleMarginEndPoints {
	/**
	 * Searches saleMargins
	 * @host `/saleMargin/search`
	 */
	SEARCH = '/saleMargin/search/',

	/**
	 * Gets margins
	 * @host `/saleMargin/getMargin`
	 */
	GET_MARGIN = '/saleMargin/getMargin/',

	/**
	 * Inserts margin
	 * @host `/saleMargin/insert`
	 */
	INSERT = '/saleMargin/insert/',

	/**
	 * Reactivates margin
	 * @host `/saleMargin/reactivate`
	 */
	REACTIVATE = '/saleMargin/reactivate/',

	/**
	 * Deactivates margin
	 * @host `/saleMargin/deactivate`
	 */
	DEACTIVATE = '/saleMargin/deactivate/',
}
