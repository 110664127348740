import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { cashLineClear, cashLineGetByDay } from '@crac/core/redux/actions/CashLineActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { ICashLineHistoryReducerState } from '@crac/core/redux-store/reducersState/cash/HistoryReducerState';
const initialState: ICashLineHistoryReducerState = {
	cashLines: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const historySlice = createSlice({
	name: 'CashLineHistorySlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY DAY
		builder
			.addCase(cashLineGetByDay.pending, (state) => {
				return {
					...state,
					cashLines: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cashLineGetByDay.fulfilled, (state, action) => {
				return {
					...state,
					cashLines: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cashLineGetByDay.rejected, (state, action) => {
				return {
					...state,
					cashLines: {
						data: [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR CACHE
		 * CASH LINE CLEAR
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, cashLineClear), () => {
			return initialState;
		});
	},
});

export const historyReducer = historySlice.reducer;
