import { createSlice } from '@reduxjs/toolkit';

import type { IFineReason } from '@crac/core/models/entities/FineReason';
import {
	fleetFineReasonClear,
	fleetFineReasonInsert,
	fleetFineReasonModify,
	fleetFineReasonSearch,
} from '@crac/core/redux/actions/FleetFineReasonActions';
import type { IFleetFineReasonReducerState } from '@crac/core/redux-store/reducersState/fleet/FleetFineReasonReducerState';

const initialState: IFleetFineReasonReducerState = {
	fleetFineReasonInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetFineReasons: [],
	fleetFineReasonSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetFineReasonModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const fineReasonSlice = createSlice({
	name: 'fineReasonSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(fleetFineReasonClear, (state) => {
			return {
				...state,
				fleetFineReasons: [],
			};
		});
		// SEARCH
		builder
			.addCase(fleetFineReasonSearch.pending, (state) => {
				return {
					...state,
					fleetFineReasonSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineReasonSearch.fulfilled, (state, action) => {
				return {
					...state,
					fleetFineReasonSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					fleetFineReasons: action.payload,
				};
			})
			.addCase(fleetFineReasonSearch.rejected, (state, action) => {
				return {
					...state,
					fleetFineReasonSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(fleetFineReasonInsert.pending, (state) => {
				return {
					...state,
					fleetFineReasonInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineReasonInsert.fulfilled, (state) => {
				return {
					...state,
					fleetFineReasonInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineReasonInsert.rejected, (state, action) => {
				return {
					...state,
					fleetFineReasonInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(fleetFineReasonModify.pending, (state) => {
				return {
					...state,
					fleetFineReasonModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineReasonModify.fulfilled, (state, action) => {
				const { code } = action.payload as IFineReason;
				const fleetFineReasons = state.fleetFineReasons?.map((fineReason) =>
					fineReason.code === code ? { ...action.payload } : fineReason,
				);
				return {
					...state,
					fleetFineReasons,
					fleetFineReasonModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetFineReasonModify.rejected, (state, action) => {
				return {
					...state,
					fleetFineReasonModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const fineReasonReducer = fineReasonSlice.reducer;
