import type { FC } from 'react';
import React from 'react';

import { ModalBody as RSModalBody } from 'reactstrap';
import type { ModalBodyProps as RSModalBodyProps } from 'reactstrap/types/lib/ModalBody';

export type ModalBodyProps = RSModalBodyProps;

export const ModalBody: FC<ModalBodyProps> = ({ children, ...props }): JSX.Element => {
	return <RSModalBody {...props}>{children}</RSModalBody>;
};
