import { createAction } from '@reduxjs/toolkit';

import { franchiseServiceGetAll, franchiseServiceGetByPlateNumber } from '../../data/services/FranchiseService';
import type { IFranchise, IFranchisePerProvider } from '../../models/entities/Franchise';
import type { IFranchiseGetByPlateNumberParams } from '../../models/serviceParams/FranchiseParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Retrieves franchises associated with a specific plate number.
 *
 * @param {IFranchise[]} payload - The franchises to be retrieved.
 * @param {IFranchiseGetByPlateNumberParams} params - Parameters to identify franchises related to a specific plate number.
 * @returns {Promise<void>} - A promise that resolves to an array of franchises associated with the plate number.
 */
export const franchiseGetFranchisesByPlateNumber = createAsyncAction<IFranchise[], IFranchiseGetByPlateNumberParams>(
	'franchise/getFranchisesByPlateNumber',
	franchiseServiceGetByPlateNumber,
);

/**
 * Retrieves all franchise data per provider.
 *
 * @param {IFranchisePerProvider[]} payload - The franchise data to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters, if any are needed.
 * @returns {Promise<void>} - A promise that resolves to an array of franchise data per provider.
 */
export const franchiseGetAll = createAsyncAction<IFranchisePerProvider[], IServiceBaseParams>(
	'franchise/getAll',
	franchiseServiceGetAll,
);

/**
 * Sets the criteria for retrieving franchise information by provider and vehicle group.
 *
 * @param {{ provider: string; vehicleGroup: string }} payload - The criteria for selecting the franchise information.
 */
export const franchiseGetByProviderAndVehicleGroup = createAction<{ provider: string; vehicleGroup: string }>(
	'franchise/getByProviderAndVehicleGroup',
);
