import { createSlice } from '@reduxjs/toolkit';

import { expedientAdd, expedientGetByVehicle } from '@crac/core/redux/actions/ExpedientActions';
import type { IExpedientsReducerState } from '@crac/core/redux-store/reducersState/vehicle/ExpedientsReducerState';
const initialState: IExpedientsReducerState = {
	insert: { inProgress: false, messages: [], ok: true },
	load: { inProgress: false, messages: [], ok: true },
	vehicleExpedients: [],
};

const expedientsSlice = createSlice({
	name: 'VehicleExpedientsSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY VEHICLE
		builder
			.addCase(expedientGetByVehicle.pending, (state) => {
				return {
					...state,
					load: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(expedientGetByVehicle.fulfilled, (state, action) => {
				return {
					...state,
					load: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicleExpedients: action.payload,
				};
			})
			.addCase(expedientGetByVehicle.rejected, (state, action) => {
				return {
					...state,
					load: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// EXPEDIENT ADD
		builder
			.addCase(expedientAdd.pending, (state) => {
				return {
					...state,
					insert: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(expedientAdd.fulfilled, (state, action) => {
				return {
					...state,
					load: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicleExpedients: [...state.vehicleExpedients, action.payload],
				};
			})
			.addCase(expedientAdd.rejected, (state, action) => {
				return {
					...state,
					insert: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const expedientsReducer = expedientsSlice.reducer;
