import type { IFleetPrevision } from '../../models/entities/FleetPrevision';
import type {
	IFleetPrevisionGetByPrevisionCodeParams,
	IFleetPrevisionInsertArrayParams,
	IFleetPrevisionModifyParams,
	IFleetPrevisionSearchParams,
} from '../../models/serviceParams/FleetPrevisionParams';
import { Api } from '../../modules/shared/api';
import { FleetPrevisionEndPoints } from '../endPoints/FleetPrevisionEndPoints';

export const fleetPrevisionServiceSearch = (model: IFleetPrevisionSearchParams) => {
	return Api.get<IFleetPrevision[], IFleetPrevisionSearchParams>(FleetPrevisionEndPoints.SEARCH, model);
};

export const fleetPrevisionServiceInsert = (model: IFleetPrevisionInsertArrayParams) => {
	return Api.post<IFleetPrevision[], IFleetPrevisionInsertArrayParams>(FleetPrevisionEndPoints.INSERT, model);
};

export const fleetPrevisionServiceModify = (model: IFleetPrevisionModifyParams) => {
	return Api.post<IFleetPrevision, IFleetPrevisionModifyParams>(FleetPrevisionEndPoints.MODIFY, model);
};

export const fleetPrevisionServiceGetByPrevisionCode = (model: IFleetPrevisionGetByPrevisionCodeParams) => {
	return Api.get<IFleetPrevision, IFleetPrevisionGetByPrevisionCodeParams>(
		FleetPrevisionEndPoints.GET_BY_PREVISION_CODE,
		model,
	);
};
