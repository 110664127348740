/**
 * Añade, elimina o hace toggle de una clase en el body
 * @param toggleClass class to toggle
 * @param classList class list valid
 * @param force fore add class.
 * - To add use force `true`
 * - To remove use force `false`
 * - To toggle class use force `undefined`
 */
export const toggleClasses = (toggleClass: string, classList: Array<string>, force?: boolean): boolean => {
	if (typeof window === 'undefined') {
		return false;
	}

	const level = classList.indexOf(toggleClass);
	const removeClassList = classList.slice(0, level);

	removeClassList.map((className) => document.body.classList.remove(className));

	if (force === true) {
		document.body.classList.add(toggleClass);
	} else if (force === false) {
		document.body.classList.remove(toggleClass);
	} else {
		document.body.classList.toggle(toggleClass);
	}

	return document.body.classList.contains(toggleClass);
};
