import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Current = lazyWithRetry(() => import('./pages/Current'));
const SpendingNew = lazyWithRetry(() => import('./pages/SpendingNew'));
const Detail = lazyWithRetry(() => import('./pages/Detail'));
const Pending = lazyWithRetry(() => import('./pages/Pending'));
const History = lazyWithRetry(() => import('./pages/History'));

export const cashRoutes: { [key: number]: IRouteConfig } = {};

// CURRENT
cashRoutes[routesIdMap.ManagementCashCurrent] = {
	component: Current,
	exact: true,
	id: routesIdMap.ManagementCashCurrent,
	name: 'Cash',
	path: '/management/cash',
	permissions: [PermissionsType.ManagementCashLine],
	icon: 'money',
	menuText: 'Cash',
};

// PENDING
cashRoutes[routesIdMap.ManagementCashPending] = {
	component: Pending,
	exact: true,
	id: routesIdMap.ManagementCashPending,
	name: 'Pending',
	path: '/management/cash/pending',
	permissions: [PermissionsType.ManagementCashLine, PermissionsType.ManagementCashLineSign],
	icon: 'check',
	menuText: 'Pending',
};

// HISTORY
cashRoutes[routesIdMap.ManagementCashHistory] = {
	component: History,
	exact: true,
	id: routesIdMap.ManagementCashHistory,
	name: 'History',
	path: '/management/cash/history',
	permissions: [PermissionsType.ManagementCashLine, PermissionsType.ManagementCashLineSign],
	icon: 'calendar',
	menuText: 'History',
};

// SPENDINGS
cashRoutes[routesIdMap.ManagementCashCurrentSpendings] = {
	component: Current,
	exact: true,
	id: routesIdMap.ManagementCashCurrentSpendings,
	name: 'Spendings',
	path: '/management/cash/spendings',
	permissions: [PermissionsType.ManagementCashLine],
	icon: 'copy',
	menuText: 'Spendings',
};

cashRoutes[routesIdMap.ManagementCashCurrentSpendingsDetail] = {
	component: Current,
	exact: true,
	id: routesIdMap.ManagementCashCurrentSpendingsDetail,
	name: 'Spendings',
	path: '/management/cash/spendings/:number',
	permissions: [PermissionsType.ManagementCashLine],
	icon: 'copy',
};

cashRoutes[routesIdMap.ManagementCashCurrentSpendingsNew] = {
	component: SpendingNew,
	exact: true,
	id: routesIdMap.ManagementCashCurrentSpendingsNew,
	name: 'New spending',
	path: '/management/cash/spendings/new',
	permissions: [PermissionsType.ManagementCashLine],
	icon: 'plus',
	menuText: 'New spending',
};

// BOOKINGS
cashRoutes[routesIdMap.ManagementCashCurrentBookings] = {
	component: Current,
	exact: true,
	id: routesIdMap.ManagementCashCurrentBookings,
	name: 'Management bookings',
	path: '/management/cash/bookings',
	permissions: [PermissionsType.ManagementCashLine],
	icon: 'car',
	menuText: 'Management bookings',
};

// PAYMENTS
cashRoutes[routesIdMap.ManagementCashCurrentPayments] = {
	component: Current,
	exact: true,
	id: routesIdMap.ManagementCashCurrentPayments,
	name: 'Management Payments',
	path: '/management/cash/payments',
	permissions: [PermissionsType.ManagementCashLine],
	icon: 'credit-card',
	menuText: 'Management payments',
};

cashRoutes[routesIdMap.ManagementCashDetail] = {
	component: Detail,
	exact: true,
	id: routesIdMap.ManagementCashDetail,
	name: 'Detail',
	path: '/management/cash/detail/:id',
	permissions: [PermissionsType.ManagementCashLine],
	icon: 'money',
};

// DETAIL
cashRoutes[routesIdMap.ManagementCashDetailSpendings] = {
	component: Detail,
	exact: true,
	id: routesIdMap.ManagementCashDetailSpendings,
	name: 'Spendings',
	path: '/management/cash/detail/:id/spendings',
	permissions: [PermissionsType.ManagementCashLine],
	icon: 'money',
};

cashRoutes[routesIdMap.ManagementCashDetailSpendingsDetail] = {
	component: Detail,
	exact: true,
	id: routesIdMap.ManagementCashDetailSpendingsDetail,
	name: 'Spendings',
	path: '/management/cash/detail/:id/spendings/:number',
	permissions: [PermissionsType.ManagementCashLine],
	icon: 'money',
};

cashRoutes[routesIdMap.ManagementCashDetailBookings] = {
	component: Detail,
	exact: true,
	id: routesIdMap.ManagementCashDetailBookings,
	name: 'Bookings',
	path: '/management/cash/detail/:id/bookings',
	permissions: [PermissionsType.ManagementCashLine],
	icon: 'money',
};

cashRoutes[routesIdMap.ManagementCashDetailPayments] = {
	component: Detail,
	exact: true,
	id: routesIdMap.ManagementCashDetailPayments,
	name: 'Payments',
	path: '/management/cash/detail/:id/payments',
	permissions: [PermissionsType.ManagementCashLine],
	icon: 'money',
};
