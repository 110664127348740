import { createAction } from '@reduxjs/toolkit';

import {
	paymentLineServiceAdd,
	paymentLineServiceDeleteChargeBack,
	paymentLineServiceGetByBooking,
	paymentLineServiceGetByCustomer,
	paymentLineServiceSearch,
	paymentLineServiceUpdateChargeBack,
} from '../../data/services/PaymentLineService';
import type { IPaymentLine } from '../../models/entities/PaymentLine';
import type {
	IPaymentLineAddParams,
	IPaymentLineDeleteChargeBackParams,
	IPaymentLineGetByBookingParams,
	IPaymentLineGetByCustomerParams,
	IPaymentLineSearchParams,
	IPaymentLineUpdateChargeBackParams,
} from '../../models/serviceParams/PaymentLineParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Inserts a new payment line.
 *
 * @param {IPaymentLine} payload - The payment line to be inserted.
 * @param {IPaymentLineAddParams} params - Parameters for the insertion of the payment line.
 * @returns {Promise<void>} - A promise that resolves after the payment line is inserted.
 */
export const paymentLineInsert = createAsyncAction<IPaymentLine, IPaymentLineAddParams>(
	'paymentLine/insert',
	paymentLineServiceAdd,
);

/**
 * Retrieves payment lines associated with a specific booking.
 *
 * @param {IPaymentLine[]} payload - The payment lines to be retrieved.
 * @param {IPaymentLineGetByBookingParams} params - Parameters to identify payment lines associated with a booking.
 * @returns {Promise<void>} - A promise that resolves to an array of payment lines.
 */
export const paymentLineGetByBooking = createAsyncAction<IPaymentLine[], IPaymentLineGetByBookingParams>(
	'paymentLine/getByBooking',
	paymentLineServiceGetByBooking,
);

/**
 * Retrieves payment lines associated with a specific customer.
 *
 * @param {IPaymentLine[]} payload - The payment lines to be retrieved.
 * @param {IPaymentLineGetByCustomerParams} params - Parameters to identify payment lines associated with a customer.
 * @returns {Promise<void>} - A promise that resolves to an array of payment lines.
 */
export const paymentLineGetByCustomer = createAsyncAction<IPaymentLine[], IPaymentLineGetByCustomerParams>(
	'paymentLine/getByCustomer',
	paymentLineServiceGetByCustomer,
);

/**
 * Searches for payment lines based on specified parameters.
 *
 * @param {IPaymentLine[]} payload - The payment lines to be returned based on the search criteria.
 * @param {IPaymentLineSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of payment lines.
 */
export const paymentLineSearch = createAsyncAction<IPaymentLine[], IPaymentLineSearchParams>(
	'paymentLine/search',
	paymentLineServiceSearch,
);

/**
 * Updates a chargeback on a specific payment line.
 *
 * @param {IPaymentLine} payload - The payment line to be updated.
 * @param {IPaymentLineUpdateChargeBackParams} params - Parameters for updating the chargeback on the payment line.
 * @returns {Promise<void>} - A promise that resolves after the chargeback is updated.
 */
export const paymentLineUpdateChargeBack = createAsyncAction<IPaymentLine, IPaymentLineUpdateChargeBackParams>(
	'paymentLine/updateChargeBack',
	paymentLineServiceUpdateChargeBack,
);

/**
 * Clears the payment line state.
 */
export const paymentLineClear = createAction('paymentLine/clear');

/**
 * Deletes a chargeback from a specific payment line.
 *
 * @param {IPaymentLine} payload - The payment line from which the chargeback is to be deleted.
 * @param {IPaymentLineDeleteChargeBackParams} params - Parameters for deleting the chargeback from the payment line.
 * @returns {Promise<void>} - A promise that resolves after the chargeback is deleted.
 */
export const paymentLineDeleteChargeBack = createAsyncAction<IPaymentLine, IPaymentLineDeleteChargeBackParams>(
	'paymentLine/deleteChargeBack',
	paymentLineServiceDeleteChargeBack,
);
