import {
	workshopAssignmentServiceDelete,
	workshopAssignmentServiceInsert,
	workshopAssignmentServiceModify,
	workshopAssignmentServiceSearch,
} from '../../data/services/WorkshopAssignmentService';
import type { IWorkshopAssignment } from '../../models/entities/WorkshopAssignment';
import type {
	IWorkshopAssignmentDeleteParams,
	IWorkshopAssignmentInsertParams,
	IWorkshopAssignmentModifyParams,
	IWorkshopAssignmentSearchParams,
} from '../../models/serviceParams/WorkshopAssignmentParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

export const workshopAssignmentSearch = createAsyncAction<IWorkshopAssignment[], IWorkshopAssignmentSearchParams>(
	'workshop/assignment/search',
	workshopAssignmentServiceSearch,
);

export const workshopAssignmentClear = createSyncAction('workshop/assignment/clear');

export const workshopAssignmentInsert = createAsyncAction<IWorkshopAssignment, IWorkshopAssignmentInsertParams>(
	'workshop/assignment/insert',
	workshopAssignmentServiceInsert,
);

export const workshopAssignmentModify = createAsyncAction<IWorkshopAssignment, IWorkshopAssignmentModifyParams>(
	'workshop/assignment/modify',
	workshopAssignmentServiceModify,
);

export const workshopAssignmentDelete = createAsyncAction<IWorkshopAssignment, IWorkshopAssignmentDeleteParams>(
	'workshop/assignment/delete',
	workshopAssignmentServiceDelete,
);
