import { createAction } from '@reduxjs/toolkit';

import {
	fineReasonServiceGetAll,
	fineReasonServiceGetByCode,
	fineReasonServiceInsert,
	fineReasonServiceModify,
} from '../../data/services/FineReasonService';
import type { IFineReason } from '../../models/entities/FineReason';
import type {
	IFineReasonGetByCodeParams,
	IFineReasonInsertParams,
	IFineReasonModifyParams,
} from '../../models/serviceParams/FineReasonParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Inserts a new fine reason into the system.
 *
 * @param {IFineReason} payload - The fine reason to be inserted.
 * @param {IFineReasonInsertParams} params - Parameters detailing the fine reason to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the fine reason is successfully inserted.
 */
export const fineReasonInsert = createAsyncAction<IFineReason, IFineReasonInsertParams>(
	'fineReason/insert',
	fineReasonServiceInsert,
);

/**
 * Modifies an existing fine reason.
 *
 * @param {IFineReason} payload - The fine reason to be modified.
 * @param {IFineReasonModifyParams} params - Parameters detailing the modifications to be made on the fine reason.
 * @returns {Promise<void>} - A promise that resolves after the fine reason is successfully modified.
 */
export const fineReasonModify = createAsyncAction<IFineReason, IFineReasonModifyParams>(
	'fineReason/modify',
	fineReasonServiceModify,
);

/**
 * Retrieves all fine reasons.
 *
 * @param {IFineReason[]} payload - The fine reasons to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters, if any are needed.
 * @returns {Promise<void>} - A promise that resolves to an array of all fine reasons.
 */
export const fineReasonGetAll = createAsyncAction<IFineReason[], IServiceBaseParams>(
	'fineReason/getAll',
	fineReasonServiceGetAll,
);

/**
 * Retrieves a fine reason by its code.
 *
 * @param {IFineReason} payload - The fine reason to be retrieved.
 * @param {IFineReasonGetByCodeParams} params - Parameters to identify the fine reason by its code.
 * @returns {Promise<void>} - A promise that resolves to the fine reason matching the given code.
 */
export const fineReasonGetByCode = createAsyncAction<IFineReason, IFineReasonGetByCodeParams>(
	'fineReason/getByCode',
	fineReasonServiceGetByCode,
);

/**
 * Clears the fine reason state.
 */
export const fineReasonClear = createAction('fineReason/clear');
