export enum RentRateVariationEndPoints {
	/**
	 * @host `/rentRateVariation/search/`
	 */
	SEARCH = '/rentRateVariation/search/',

	/**
	 * @host `/rentRateVariation/insert/`
	 */
	INSERT = '/rentRateVariation/insert/',

	/**
	 * @host `/rentRateVariation/deactivate`
	 */
	DEACTIVATE = '/rentRateVariation/deactivate',
}
