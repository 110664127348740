'use client';
import type { FC } from 'react';
import React from 'react';

import { Breadcrumb as RSBreadcrumb } from 'reactstrap';
import type { BreadcrumbProps as RSBreadcrumbProps } from 'reactstrap/types/lib/Breadcrumb';

export type BreadcrumbProps = RSBreadcrumbProps;

export const Breadcrumb: FC<BreadcrumbProps> = ({ children, ...props }) => {
	return <RSBreadcrumb {...props}>{children}</RSBreadcrumb>;
};
