'use client';
import type { FC } from 'react';
import React from 'react';

import { Input as RSInput } from 'reactstrap';
import type { InputProps as RSInputProps } from 'reactstrap/types/lib/Input';

export type InputProps = Exclude<RSInputProps, 'bsSize'> & {
	size?: RSInputProps['bsSize'];
	isDisabled?: boolean;
	className?: string;
};

export const Input: FC<InputProps> = ({ children, size, isDisabled, ...props }) => {
	return (
		<RSInput disabled={isDisabled} {...props} bsSize={size}>
			{children}
		</RSInput>
	);
};
