import type { IRegularization } from '../../models/entities/Regularization';
import type {
	IRegularizationGetByBookingParams,
	IRegularizationGetByCodeParams,
	IRegularizationInsertParams,
	IRegularizationUpdateParams,
} from '../../models/serviceParams/RegularizationParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { RegularizationEndPoints } from '../endPoints/RegularizationEndPoints';

/**
 * Get booking regularization by code
 *  {string} code Booking regularization code
 * @return {BookingRegularationPropType} BookingRegularizationPropType
 */
export const bookingRegularizationServiceGetByCode = (
	model: IRegularizationGetByCodeParams,
): Promise<ServiceResponse<IRegularization>> => {
	return Api.get<IRegularization, IRegularizationGetByCodeParams>(RegularizationEndPoints.GET_BY_CODE, model);
};

/**
 * Get booking regularization by booking
 *  {string} bookingNumber Booking number
 * @return {BookingRegularationPropType} BookingRegularizationPropType
 */
export const bookingRegularizationServiceGetByBooking = (
	model: IRegularizationGetByBookingParams,
): Promise<ServiceResponse<IRegularization[]>> => {
	return Api.get<IRegularization[], IRegularizationGetByBookingParams>(RegularizationEndPoints.GET_BY_BOOKING, model);
};

/**
 * Get pending booking regularization by user
 * @return {BookingRegularationPropType} BookingRegularizationPropType
 */
export const bookingRegularizationServiceGetPendingByUser = (): Promise<ServiceResponse<IRegularization[]>> => {
	return Api.get<IRegularization[], undefined>(RegularizationEndPoints.GET_PENDING_BY_USER);
};

/**
 *
 * Insert booking regularization
 *  {object} bookingRegularization Booking regularization
 * @return {BookingRegularationPropType} BookingRegularizationPropType
 */
export const bookingRegularizationServiceInsert = (
	model: IRegularizationInsertParams,
): Promise<ServiceResponse<IRegularization>> => {
	return Api.post<IRegularization, IRegularizationInsertParams>(RegularizationEndPoints.INSERT, model);
};

/**
 *
 * Update booking regularization
 *  {object} bookingRegularization Booking regularization
 * @return {BookingRegularationPropType} BookingRegularizationPropType
 */
export const bookingRegularizationServiceUpdate = (
	model: IRegularizationUpdateParams,
): Promise<ServiceResponse<IRegularization>> => {
	return Api.post<IRegularization, IRegularizationUpdateParams>(RegularizationEndPoints.UPDATE, model);
};
