import { styled } from 'styled-components';

export const FullPageLoaderContainer = styled.div`
	position: fixed;
	height: 100%;
	width: 100%;
	z-index: 2147483647;
	background: rgba(255, 255, 255, 0.75);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
`;

export const FullPageLoaderMessage = styled.div`
	font-size: 2em;
	font-weight: bold;
	text-align: center;
`;

export const FullPageLoaderIcon = styled.div``;

export const FullPageLoaderContent = styled.div`
	height: 100vh !important;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
`;
