import { createAction } from '@reduxjs/toolkit';

import {
	partnerCategoryServiceGetAll,
	partnerCategoryServiceGetByCode,
	partnerCategoryServiceInsert,
	partnerCategoryServiceModify,
	partnerCategoryServiceSearch,
} from '../../data/services/PartnerCategoryService';
import type { IPartnerCategory } from '../../models/entities/PartnerCategory';
import type {
	IPartnerCategoryGetAllParams,
	IPartnerCategoryGetByCodeParams,
	IPartnerCategoryInsertParams,
	IPartnerCategoryModifyParams,
	IPartnerCategorySearchParams,
} from '../../models/serviceParams/PartnerCategoryParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves all partner categories.
 *
 * @param {IPartnerCategory[]} payload - The partner categories to be retrieved.
 * @param {IPartnerCategoryGetAllParams} params - Parameters for retrieving all partner categories.
 * @returns {Promise<void>} - A promise that resolves to an array of partner categories.
 */
export const partnerCategoryGetAll = createAsyncAction<IPartnerCategory[], IPartnerCategoryGetAllParams>(
	'partnerCategory/getAll',
	partnerCategoryServiceGetAll,
);

/**
 * Retrieves partner categories by their specific code.
 *
 * @param {IPartnerCategory} payload - The partner categories to be retrieved.
 * @param {IPartnerCategoryGetByCodeParams} params - Parameters to identify the partner categories by code.
 * @returns {Promise<void>} - A promise that resolves to an array of partner categories.
 */
export const partnerCategoryGetByCode = createAsyncAction<IPartnerCategory, IPartnerCategoryGetByCodeParams>(
	'partnerCategory/getByCode',
	partnerCategoryServiceGetByCode,
);

/**
 * Searches for partner categories based on specified parameters.
 *
 * @param {IPartnerCategory[]} payload - The partner categories to be returned based on the search criteria.
 * @param {IPartnerCategorySearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of partner categories.
 */
export const partnerCategorySearch = createAsyncAction<IPartnerCategory[], IPartnerCategorySearchParams>(
	'partnerCategory/search',
	partnerCategoryServiceSearch,
);

/**
 * Inserts a new partner category.
 *
 * @param {IPartnerCategory} payload - The partner category to be inserted.
 * @param {IPartnerCategoryInsertParams} params - Parameters for the insertion of the partner category.
 * @returns {Promise<void>} - A promise that resolves after the partner category is inserted.
 */
export const partnerCategoryInsert = createAsyncAction<IPartnerCategory[], IPartnerCategoryInsertParams>(
	'partnerCategory/insert',
	partnerCategoryServiceInsert,
);

/**
 * Modifies an existing partner category.
 *
 * @param {IPartnerCategory} payload - The partner category to be modified.
 * @param {IPartnerCategoryModifyParams} params - Parameters for the modification of the partner category.
 * @returns {Promise<void>} - A promise that resolves after the partner category is modified.
 */
export const partnerCategoryModify = createAsyncAction<IPartnerCategory, IPartnerCategoryModifyParams>(
	'partnerCategory/modify',
	partnerCategoryServiceModify,
);

/**
 * Clears the partner category state.
 */
export const partnerCategoryClear = createAction('partnerCategory/clear');
