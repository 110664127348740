import { createSlice } from '@reduxjs/toolkit';

import {
	rentRateSave,
	rentRatesRelatedReset,
	rentRatesRelatedSearch,
	rentRatesReset,
	rentRatesSearch,
} from '@crac/core/redux/actions/RentRateActions';
import type { IRentRateReducerState } from '@crac/core/redux-store/reducersState/pricing/RentRateReducerState';

const initialState: IRentRateReducerState = {
	rentRate: null,
	rentRates: [],
	rentRateSaveRequest: { inProgress: false, ok: true, messages: [] },
	rentRatesSearchRequest: { inProgress: false, ok: true, messages: [] },
	rentRatesRelated: {},
	rentRatesSearchRelatedRequest: { inProgress: false, ok: true, messages: [] },
};

const rentRateSlice = createSlice({
	name: 'RentRateSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// RESET
		builder.addCase(rentRatesReset, (state) => {
			return {
				...state,
				rentRates: [],
			};
		});
		// SEARCH
		builder
			.addCase(rentRatesSearch.pending, (state) => {
				return {
					...state,
					rentRatesSearchRequest: { inProgress: true, ok: true, messages: [] },
				};
			})
			.addCase(rentRatesSearch.fulfilled, (state, action) => {
				return {
					...state,
					rentRatesSearchRequest: { inProgress: false, ok: true, messages: [] },
					rentRates: action.payload,
				};
			})
			.addCase(rentRatesSearch.rejected, (state, action) => {
				return {
					...state,
					rentRatesSearchRequest: { inProgress: false, ok: false, messages: action.payload || [] },
				};
			});
		// SAVE
		builder
			.addCase(rentRateSave.pending, (state) => {
				return {
					...state,
					rentRateSaveRequest: { inProgress: true, ok: true, messages: [] },
				};
			})
			.addCase(rentRateSave.fulfilled, (state, action) => {
				const primeRentType = action.payload[0].rentRateType;
				const groupsChanged = action.payload
					.filter(({ rentRateType }) => primeRentType === rentRateType)
					.map(({ vehicleGroup }) => vehicleGroup);
				return {
					...state,
					rentRateSaveRequest: { inProgress: false, ok: true, messages: [] },
					rentRates: [
						...state.rentRates.filter(({ vehicleGroup }) => !groupsChanged.includes(vehicleGroup)),
						...action.payload.filter(({ rentRateType }) => primeRentType === rentRateType),
					].sort((acc, curr) => (acc.vehicleGroup as string).localeCompare(curr.vehicleGroup as string)),
				};
			})
			.addCase(rentRateSave.rejected, (state, action) => {
				return {
					...state,
					rentRateSaveRequest: { inProgress: false, ok: false, messages: action.payload || [] },
				};
			});
		// RELATED SEARCH
		builder
			.addCase(rentRatesRelatedSearch.pending, (state) => {
				return {
					...state,
					rentRatesSearchRelatedRequest: { inProgress: true, ok: true, messages: [] },
				};
			})
			.addCase(rentRatesRelatedSearch.fulfilled, (state, action) => {
				return {
					...state,
					rentRatesSearchRequest: { inProgress: false, ok: true, messages: [] },
					rentRatesRelated: action.payload ?? {},
				};
			})
			.addCase(rentRatesRelatedSearch.rejected, (state, action) => {
				return {
					...state,
					rentRatesSearchRelatedRequest: { inProgress: false, ok: false, messages: action.payload || [] },
				};
			});
		// RELATED RESET
		builder.addCase(rentRatesRelatedReset, (state) => {
			return {
				...state,
				rentRatesRelated: {},
			};
		});
	},
});

export const rentRateReducer = rentRateSlice.reducer;
