import type { FC } from 'react';

import { Link } from 'react-router-dom';
import useReactRouterBreadcrumbs, { humanize } from 'use-react-router-breadcrumbs';

import { Breadcrumb, BreadcrumbItem } from '@crac/components/external/reactstrap/breadcrumb';

import { routesIdMap } from '~/config/routesIdMap';
import { routes } from '~/features/routes';

const homeRoute = routes[routesIdMap.Dashboard];

// Temporal hasta modificar las rutas de estas secciones
const excludeRoutes = [
	'/vehicles/detail',
	'/bookings/detail',
	'/customers/detail',
	'/management/cash/detail',
	'/companies/detail',
	'/check-customer',
];

/**
 * https://github.com/icd2k3/use-react-router-breadcrumbs#readme
 * humanize is is the default formatter from use-react-router-breadcrumb
 */
export const BreadCrumbs: FC = () => {
	const breadcrumbs = useReactRouterBreadcrumbs(undefined, {
		defaultFormatter: (str) => (str.length > 40 ? humanize(`${str.substring(0, 30)}...`) : humanize(str)),
	});

	if (location.pathname === homeRoute.path) {
		return null;
	}

	return (
		<Breadcrumb>
			{breadcrumbs.map(({ breadcrumb, match }) => {
				if (excludeRoutes.includes(match.pathname)) {
					return null;
				}

				if (match.pathname === location.pathname) {
					return (
						<BreadcrumbItem active key={match.pathname}>
							{match.pathname === homeRoute.path && <i className="fa fa-home fa-fw" />}
							{breadcrumb}
						</BreadcrumbItem>
					);
				}

				return (
					<Link className="breadcrumb-item" key={match.pathname} to={`${match.pathname}${location.search}`}>
						{match.pathname === homeRoute.path && <i className="fa fa-home fa-fw" />}
						{breadcrumb}
					</Link>
				);
			})}
		</Breadcrumb>
	);
};
