import { SaleServiceRateEndpoints } from './SaleServiceRateEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleServiceRate } from '../entities/SaleServiceRate';

export interface ISaleServiceRateGetServiceRateParams
	extends IServiceBaseParams,
		Omit<
			ISaleServiceRate,
			| 'amount'
			| 'kmFrom'
			| 'kmTo'
			| 'quoteFrom'
			| 'quoteTo'
			| 'registrationFrom'
			| 'registrationTo'
			| 'saleServiceId'
		> {
	km?: number;
	quote: string;
	registration?: string;
	saleServiceId?: string;
}

export const saleServiceRateGetServiceRateService = (model: ISaleServiceRateGetServiceRateParams) => {
	return Api.get<ISaleServiceRate, ISaleServiceRateGetServiceRateParams>(
		SaleServiceRateEndpoints.GET_SERVICE_RATE,
		model,
	);
};
