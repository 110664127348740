import {
	insuranceRateServiceDeactivate,
	insuranceRateServiceInsert,
	insuranceRateServiceModify,
	insuranceRateServiceSearch,
} from '../../data/services/InsuranceRateService';
import type { IInsuranceRate } from '../../models/entities/InsuranceRate';
import type {
	IInsuranceRateDeactivateParams,
	IInsuranceRateInsertParams,
	IInsuranceRateModifyParams,
	IInsuranceRateSearchParams,
} from '../../models/serviceParams/InsuranceRateParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Clears the insurance rate search results.
 */
export const insuranceRateClear = createSyncAction('insuranceRate/clear');

/**
 * Searches for insurance rates based on the given parameters.
 *
 * @param {IInsuranceRate[]} payload - The insurance rates that match the search parameters.
 * @param {IInsuranceRateSearchParams} params - The parameters to search for insurance rates.
 * @returns {Promise<void>} - A promise that resolves to the insurance rates that match the search parameters.
 */
export const insuranceRateSearch = createAsyncAction<IInsuranceRate[], IInsuranceRateSearchParams>(
	'insuranceRate/search',
	insuranceRateServiceSearch,
);

/**
 * Inserts a new insurance rate into the system.
 *
 * @param {IInsuranceRate} payload - The insurance rate to be inserted.
 * @param {IInsuranceRateInsertParams} params - Parameters detailing the insurance rate to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the insurance rate is successfully inserted.
 */
export const insuranceRateInsert = createAsyncAction<IInsuranceRate, IInsuranceRateInsertParams>(
	'insuranceRate/insert',
	insuranceRateServiceInsert,
);

/**
 * Modifies an existing insurance rate.
 *
 * @param {IInsuranceRate} payload - The insurance rate to be modified.
 * @param {IInsuranceRateModifyParams} params - Parameters detailing the modifications to be made on the insurance rate.
 * @returns {Promise<void>} - A promise that resolves after the insurance rate is successfully modified.
 */
export const insuranceRateModify = createAsyncAction<IInsuranceRate, IInsuranceRateModifyParams>(
	'insuranceRate/modify',
	insuranceRateServiceModify,
);

/**
 * Deactivates an existing insurance rate.
 *
 * @param {IInsuranceRate} payload - The insurance rate to be deactivated.
 * @param {IInsuranceRateDeactivateParams} params - Parameters detailing the insurance rate to be deactivated.
 * @returns {Promise<void>} - A promise that resolves after the insurance rate is successfully deactivated.
 */
export const insuranceRateDeactivate = createAsyncAction<IInsuranceRate, IInsuranceRateDeactivateParams>(
	'insuranceRate/deactivate',
	insuranceRateServiceDeactivate,
);
