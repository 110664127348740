import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { pinpadGetByBranch } from '@crac/core/redux/actions/PinpadActions';
import type { IPinpadReducerState } from '@crac/core/redux-store/reducersState/pinpad';
export const pinpadsStorageKey = 'pinpads';
const { setItem, removeItem } = managerLocalStorage();

const initialState: IPinpadReducerState = {
	pinpadsRequest: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const pinpadSlice = createSlice({
	name: 'PinpadSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GLOBAL CACHE
		builder.addCase(clearGlobalCache, (state) => {
			removeItem(pinpadsStorageKey);
			return {
				...state,
				pinpadsRequest: {
					data: [],
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});
		// PINPAD GET BY BRANCH
		builder
			.addCase(pinpadGetByBranch.pending, (state) => {
				return {
					...state,
					pinpadsRequest: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(pinpadGetByBranch.fulfilled, (state, action) => {
				setItem(pinpadsStorageKey, action.payload);
				return {
					...state,
					pinpadsRequest: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(pinpadGetByBranch.rejected, (state, action) => {
				return {
					...state,
					pinpadsRequest: {
						data: [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const pinpadReducer = pinpadSlice.reducer;
