import { combineReducers } from '@reduxjs/toolkit';

import { bookingReducer as Booking } from '~/features/booking/reducer';
import { companyReducer as Company } from '~/features/company/reducer';
import { customerReducer as Customer } from '~/features/customer/reducer';
import { employeeReducer as Employee } from '~/features/employee/state/slice';
import { fleetReducer as Fleet } from '~/features/fleet/reducer';
import { cashReducer as Cash } from '~/features/management/cash/reducer';
import { computerReducer as Computer } from '~/features/management/computer/state/slices/computer';
import { pinpadReducer as Pinpad } from '~/features/management/computer/state/slices/pinpad';
import { printerReducer as Printer } from '~/features/management/computer/state/slices/printer';
import { queuesReducer as Queue } from '~/features/management/queues/state/slice';
import { pricingReducer as Pricing } from '~/features/pricing/reducer';
import { agencyReducer as Agency } from '~/features/shared/state/slices/agency';
import { bankReducer as Bank } from '~/features/shared/state/slices/bank';
import { branchesReducer as Branch } from '~/features/shared/state/slices/branch';
import { callLogReducer as CallLog } from '~/features/shared/state/slices/callLog';
import { chargebackReducer as ChargeBack } from '~/features/shared/state/slices/chargeback';
import { cityReducer as City } from '~/features/shared/state/slices/city';
import { commonReducers as Commons } from '~/features/shared/state/slices/commons';
import { countryReducer as Country } from '~/features/shared/state/slices/country';
import { emailsReducer as Email } from '~/features/shared/state/slices/emails';
import { excessRateReducer as ExcessRate } from '~/features/shared/state/slices/excessRate';
import { excessTypeReducer as ExcessType } from '~/features/shared/state/slices/excessType';
import { fineReducer as Fine } from '~/features/shared/state/slices/fines';
import { franchiseReducer as Franchise } from '~/features/shared/state/slices/franchise';
import { invoiceReducer as Invoice } from '~/features/shared/state/slices/invoice';
import { invoiceLineReducer as InvoiceLine } from '~/features/shared/state/slices/invoiceLine';
import { languageReducer as Languages } from '~/features/shared/state/slices/language';
import { linkReducer as Link } from '~/features/shared/state/slices/link';
import { paymentLineReducer as PaymentLine } from '~/features/shared/state/slices/paymentLine';
import { paymentMethodReducer as PaymentMethod } from '~/features/shared/state/slices/paymentMethod';
import { paymentTermReducer as PaymentTerm } from '~/features/shared/state/slices/paymentTerm';
import { postCodeReducer as PostCode } from '~/features/shared/state/slices/postCode';
import { providerReducer as Provider } from '~/features/shared/state/slices/provider';
import { provinceReducer as Province } from '~/features/shared/state/slices/province';
import { repairTypesReducer as RepairType } from '~/features/shared/state/slices/repairType';
import { servicesReducer as Services } from '~/features/shared/state/slices/services';
import { taxOfficesReducer as TaxOffice } from '~/features/shared/state/slices/taxOffice';
import { ticketsReducer as Tickets } from '~/features/shared/state/slices/tickets';
import { vehicleGroupReducer as VehicleGroup } from '~/features/shared/state/slices/vehicleGroup';
import { vehicleGroupAvailabilityReducer as VehicleGroupAvailability } from '~/features/shared/state/slices/vehicleGroupAvailability';
import { vendorReducer as Vendor } from '~/features/shared/state/slices/vendor';
import { zoneReducer as Zone } from '~/features/shared/state/slices/zone';
import { vehicleReducer as Vehicle } from '~/features/vehicle/reducer';
import { workshopReducer as Workshop } from '~/features/workshop/reducer';

import { supplierReducer as Supplier } from './slices/supplier';

export const rootReducer = combineReducers({
	Agency,
	Bank,
	Booking,
	Branch,
	CallLog,
	Cash,
	ChargeBack,
	City,
	Commons,
	Company,
	Computer,
	Country,
	Customer,
	Email,
	Employee,
	ExcessRate,
	ExcessType,
	Fine,
	Fleet,
	Franchise,
	Invoice,
	InvoiceLine,
	Languages,
	Link,
	PaymentLine,
	PaymentMethod,
	PaymentTerm,
	Pinpad,
	PostCode,
	Pricing,
	Printer,
	Provider,
	Province,
	Queue,
	RepairType,
	Services,
	Supplier,
	TaxOffice,
	Tickets,
	Vehicle,
	VehicleGroup,
	VehicleGroupAvailability,
	Vendor,
	Workshop,
	Zone,
});
