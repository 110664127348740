'use client';
import type { FC } from 'react';
import React from 'react';

import { NavLink as RSNavLink } from 'reactstrap';
import type { NavLinkProps as RSNavLinkProps } from 'reactstrap/types/lib/NavLink';

export type NavLinkProps = RSNavLinkProps;

export const NavLink: FC<NavLinkProps> = (props) => {
	return <RSNavLink {...props} />;
};
