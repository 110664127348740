import type { IVehicleBrand } from '../../models/entities/VehicleBrand';
import type {
	IFleetVehicleBrandDeleteParams,
	IFleetVehicleBrandInsertParams,
	IFleetVehicleBrandModifyParams,
	IFleetVehicleBrandSearchParams,
} from '../../models/serviceParams/FleetVehicleBrandParams';
import { Api } from '../../modules/shared/api';
import { VehicleBrandEndPoints } from '../endPoints/VehicleBrandEndPoints';

export const fleetVehicleBrandServiceSearch = (model: IFleetVehicleBrandSearchParams) => {
	return Api.get<IVehicleBrand[], IFleetVehicleBrandSearchParams>(VehicleBrandEndPoints.SEARCH, model);
};

export const fleetVehicleBrandServiceInsert = (model: IFleetVehicleBrandInsertParams) => {
	return Api.post<IVehicleBrand, IFleetVehicleBrandInsertParams>(VehicleBrandEndPoints.INSERT, model);
};

export const fleetVehicleBrandServiceModify = (model: IFleetVehicleBrandModifyParams) => {
	return Api.post<IVehicleBrand, IFleetVehicleBrandModifyParams>(VehicleBrandEndPoints.MODIFY, model);
};

export const fleetVehicleBrandServiceDelete = (model: IFleetVehicleBrandDeleteParams) => {
	return Api.post<IVehicleBrand, IFleetVehicleBrandDeleteParams>(VehicleBrandEndPoints.DELETE, model);
};
