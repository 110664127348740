import type { ICard } from '../../models/entities/Card';
import type {
	ICardGetByCustomerParams,
	ICardSetPreferredParams,
	ILinkCardParams,
	IUnlinkCardParams,
} from '../../models/serviceParams/CardParams';
import type { IPaymentCallbackParams } from '../../models/serviceParams/PaymentParams';
import type { ILinkCardResponse } from '../../models/serviceResponse/LinkCardResponse';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { CardEndPoint } from '../endPoints/CardEndPoints';

/**
 * Get card list by customer
 * @returns Api response card list
 */
export const cardServiceGetByCustomer = (params: ICardGetByCustomerParams): Promise<ServiceResponse<ICard[]>> =>
	Api.get<ICard[], ICardGetByCustomerParams>(CardEndPoint.GET_BY_CUSTOMER, params);

/**
 * Delete customer card
 * @returns boolean
 */
export const cardServiceUnlink = (params: IUnlinkCardParams): Promise<ServiceResponse<boolean>> =>
	Api.post<boolean, IUnlinkCardParams>(CardEndPoint.UNLINK_CARD, params);

/**
 * Set customer card preferred
 * @returns boolean
 */
export const cardServiceSetPreferred = (params: ICardSetPreferredParams): Promise<ServiceResponse<ICard>> =>
	Api.post<ICard, ICardSetPreferredParams>(CardEndPoint.PREFERRED, params);

export const cardLinkService = (model: ILinkCardParams): Promise<ServiceResponse<ILinkCardResponse>> => {
	return Api.post<ILinkCardResponse, ILinkCardParams>(CardEndPoint.LINK_CARD, model);
};
export const cardLinkServiceCallBack = (model: IPaymentCallbackParams): Promise<ServiceResponse<ILinkCardResponse>> => {
	return Api.post<ILinkCardResponse, IPaymentCallbackParams>(CardEndPoint.LINK_CARD_CALL_BACK, model);
};
