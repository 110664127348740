import {
	fleetPreviousRegistrationServiceDelete,
	fleetPreviousRegistrationServiceImport,
	fleetPreviousRegistrationServiceModify,
	fleetPreviousRegistrationServiceRegister,
	fleetPreviousRegistrationServiceSearch,
} from '../../data/services/FleetPreviousRegistrationService';
import type {
	IFleetPreviousRegistrationDeleteParams,
	IFleetPreviousRegistrationImportParams,
	IFleetPreviousRegistrationModifyParams,
	IFleetPreviousRegistrationRegisterParams,
} from '../../models/serviceParams/FleetPreviousRegistrationParams';
import type { IVehicleSearchParams } from '../../models/serviceParams/VehicleParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IVehicle } from '../../modules/vehicle/entities/Vehicle';

/**
 * Clears the search results for fleet previous registration search.
 */
export const fleetPreviousRegistrationSearchClear = createSyncAction('fleet/previousRegistration/searchClear');

/**
 * Searches for fleet previous registrations based on specified parameters.
 * @param {IVehicle[]} payload - The search results.
 * @param {IVehicleSearchParams} params - The search parameters.
 * @returns {Promise<void>} A promise that resolves when the action is complete.
 */
export const fleetPreviousRegistrationSearch = createAsyncAction<IVehicle[], IVehicleSearchParams>(
	'fleet/previousRegistration/search',
	fleetPreviousRegistrationServiceSearch,
);

/**
 * Clears results for fleet previous registration import.
 */
export const fleetPreviousRegistrationImportClear = createSyncAction('fleet/previousRegistration/importClear');

/**
 * Imports fleet previous registrations.
 * @param {IVehicle[]} payload - The imported vehicles.
 * @param {IFleetPreviousRegistrationImportParams} params - The import parameters.
 * @returns {Promise<void>} A promise that resolves when the action is complete.
 */
export const fleetPreviousRegistrationImport = createAsyncAction<IVehicle[], IFleetPreviousRegistrationImportParams>(
	'fleet/previousRegistration/import',
	fleetPreviousRegistrationServiceImport,
);

/**
 * Modify previous registration
 * @params `IFleetPreviousRegistrationModifyParams` - vehicle params
 * @returns `Promise<IVehicle>` returns the modified vehicle
 */
export const fleetPreviousRegistrationModify = createAsyncAction<IVehicle, IFleetPreviousRegistrationModifyParams>(
	'fleet/previousRegistration/modify',
	fleetPreviousRegistrationServiceModify,
);

/**
 * Register previous registration
 * @params `IFleetPreviousRegistrationRegisterParams` - vehicle params
 * @returns `Promise<IVehicle[]>` returns the registered vehicles
 */
export const fleetPreviousRegistrationRegister = createAsyncAction<
	IVehicle[],
	IFleetPreviousRegistrationRegisterParams
>('fleet/previousRegistration/register', fleetPreviousRegistrationServiceRegister);

/**
 * Delete previous registration
 * @params `IFleetPreviousRegistrationDeleteParams` - delete params
 * @returns `Promise<IVehicle[]>` returns the deletes vehicles
 */
export const fleetPreviousRegistrationDelete = createAsyncAction<IVehicle[], IFleetPreviousRegistrationDeleteParams>(
	'fleet/previousRegistration/delete',
	fleetPreviousRegistrationServiceDelete,
);
