import { createAction } from '@reduxjs/toolkit';

import { vendorServiceGetByCode, vendorServiceSearch } from '../../data/services/VendorService';
import type { IVendor } from '../../models/entities/Vendor';
import type { IVendorGetByCodeParams, IVendorSearchParams } from '../../models/serviceParams/VendorParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for vendors based on specified parameters.
 *
 * @param {IVendor[]} payload - The vendors to be retrieved.
 * @param {IVendorSearchParams} params - Parameters for the search of vendors, such as name, location, and other criteria.
 * @returns {Promise<void>} - A promise that resolves to an array of vendors.
 */
export const vendorSearch = createAsyncAction<IVendor[], IVendorSearchParams>('vendor/search', vendorServiceSearch);

/**
 * Retrieves a vendor by its unique code.
 *
 * @param {IVendor} payload - The vendor to be retrieved.
 * @param {IVendorGetByCodeParams} params - Parameters for identifying the vendor by its unique code.
 * @returns {Promise<void>} - A promise that resolves to the specified vendor.
 */
export const vendorGetByCode = createAsyncAction<IVendor, IVendorGetByCodeParams>(
	'vendor/getByCode',
	vendorServiceGetByCode,
);

/**
 * Clears the vendor state.
 */
export const vendorClear = createAction('vendor/clear');
