export enum SaleServiceRateEndpoints {
	/**
	 * Searches sale service rates
	 * @host `/saleServiceRate/search`
	 */
	SEARCH = '/saleServiceRate/search/',

	/**
	 * Gets sale service rates
	 * @host `/saleServiceRate/getServiceRate`
	 */
	GET_SERVICE_RATE = '/saleServiceRate/getServiceRate/',

	/**
	 * Inserts a sale service rate
	 * @host `/saleServiceRate/insert`
	 */
	INSERT = '/saleServiceRate/insert/',

	/**
	 * Reactivates a sale service rate
	 * @host `/saleServiceRate/reactivate`
	 */
	REACTIVATE = '/saleServiceRate/reactivate/',

	/**
	 * Deactivates a sale service rate
	 * @host `/saleServiceRate/deactivate`
	 */
	DEACTIVATE = '/saleServiceRate/deactivate/',
}
