/* eslint-disable complexity */
/* eslint-disable react/display-name */
import React from 'react';

import classNames from 'classnames';

import { FormFieldLabel } from './FormFieldLabel';
import { Badge } from '../../../../external/reactstrap/badge';
import { FormGroup } from '../../../../external/reactstrap/form/FormGroup';
import type { IFormFieldInputProps } from '../types/FormElements';

export const formField =
	(render: (props: IFormFieldInputProps) => JSX.Element) =>
	(props: IFormFieldInputProps): JSX.Element => {
		const { meta, input, iconLabel, ...attributes } = props;
		const { label, required, className, id, validateOnLoad, hideMessageErrors } = attributes;
		const { type } = input;

		return (
			<FormGroup check={type === 'radio' || type === 'checkbox'} className={classNames(className)}>
				{['radio', 'checkbox'].includes(type as string) && (
					<>
						{render(props)}
						<FormFieldLabel
							className={classNames('content form-check-label', {
								'fw-bold': required,
							})}
							htmlFor={id || input.name}
							label={label}
						/>
					</>
				)}

				{!['radio', 'checkbox'].includes(type as string) && (
					<>
						{label && required ? (
							<FormFieldLabel className="fw-bold" htmlFor={id || input.name} label={label} />
						) : null}
						{label && !required ? (
							<>
								{iconLabel ? (
									<i className={`fa fa-fw fa-${iconLabel}`} data-testid={iconLabel} />
								) : null}
								<FormFieldLabel htmlFor={id || input.name} label={label} />
							</>
						) : null}
						{render(props)}
					</>
				)}
				{!hideMessageErrors && meta.invalid && (meta.touched || validateOnLoad) ? (
					<Badge className="d-block text-wrap" color="danger">
						{meta.error}
					</Badge>
				) : null}
			</FormGroup>
		);
	};
