import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import {
	bookingCancelChangeOfVehicle,
	bookingConfirmChangeOfVehicle,
	bookingStartChangeOfVehicle,
	bookingStartChangeOfVehicleAndInsertBookingLines,
} from '@crac/core/redux/actions/BookingModifyActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IChangeVehicleReducerState } from '@crac/core/redux-store/reducersState/booking/ChangeVehicleReducerState';
const initialState: IChangeVehicleReducerState = {
	changeVehicleCancelRequest: { inProgress: false, messages: [], ok: true },
	changeVehicleConfirmRequest: { inProgress: false, messages: [], ok: true },
	changeVehicleStartRequest: { inProgress: false, messages: [], ok: true },
	changeVehicleStartAndInsertBookingLinesRequest: { inProgress: false, messages: [], ok: true },
	vehicle: null,
	vehicleLines: [],
};

const vehicleChangeSlice = createSlice({
	name: 'BookingChangeVehicleSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// BOOKING START CHANGE OF VEHICLE
		builder
			.addCase(bookingStartChangeOfVehicle.pending, (state) => {
				return {
					...state,
					changeVehicleStartRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingStartChangeOfVehicle.fulfilled, (state, action) => {
				return {
					...state,
					changeVehicleStartRequest: { inProgress: false, messages: [], ok: true },
					vehicle: deepCopy(action.payload),
				};
			})
			.addCase(bookingStartChangeOfVehicle.rejected, (state, action) => {
				return {
					...state,
					changeVehicleStartRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// BOOKING_START_CHANGE_OF_VEHICLE_AND_INSERT_BOOKING_LINES
		builder
			.addCase(bookingStartChangeOfVehicleAndInsertBookingLines.pending, (state) => {
				return {
					...state,
					changeVehicleStartAndInsertBookingLinesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingStartChangeOfVehicleAndInsertBookingLines.fulfilled, (state, action) => {
				return {
					...state,
					changeVehicleStartAndInsertBookingLinesRequest: { inProgress: false, messages: [], ok: true },
					vehicle: deepCopy(action.payload),
				};
			})
			.addCase(bookingStartChangeOfVehicleAndInsertBookingLines.rejected, (state, action) => {
				return {
					...state,
					changeVehicleStartAndInsertBookingLinesRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// BOOKING_CANCEL_CHANGE_OF_VEHICLE
		builder
			.addCase(bookingCancelChangeOfVehicle.pending, (state) => {
				return {
					...state,
					changeVehicleCancelRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingCancelChangeOfVehicle.fulfilled, (state) => {
				return {
					...state,
					changeVehicleCancelRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicle: null,
				};
			})
			.addCase(bookingCancelChangeOfVehicle.rejected, (state, action) => {
				return {
					...state,
					changeVehicleCancelRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// BOOKING_CONFIRM_CHANGE_OF_VEHICLE
		builder
			.addCase(bookingConfirmChangeOfVehicle.pending, (state) => {
				return {
					...state,
					changeVehicleConfirmRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingConfirmChangeOfVehicle.fulfilled, (state, action) => {
				return {
					...state,
					changeVehicleConfirmRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicle: null,
					vehicleLines: [...state.vehicleLines, ...action.payload],
				};
			})
			.addCase(bookingConfirmChangeOfVehicle.rejected, (state, action) => {
				return {
					...state,
					changeVehicleConfirmRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const vehicleChangeReducer = vehicleChangeSlice.reducer;
