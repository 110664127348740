export enum LockerEndPoints {
	/**
	 * @host /lockercell/getbycurrentbranch
	 */
	LOCKER_GET_BY_BRANCH = '/lockercell/getbycurrentbranch',
	/**
	 * @host /lockercell/getemptybycurrentbranch
	 */
	LOCKER_GET_EMPTY_BY_BRANCH = '/lockercell/getemptybycurrentbranch',
	/**
	 * @host /lockercell/open
	 */
	LOCKER_OPEN = '/lockercell/open',
	/**
	 * @host /lockercell/close
	 */
	LOCKER_CLOSE = '/lockercell/close',
	/**
	 * @host /lockercell/cancel
	 */
	LOCKER_CANCEL = '/lockercell/cancel',
	/**
	 * @host /lockercellline/getreturnkeys
	 */
	LOCKER_CELL_LINE_GET_RETURN_KEYS = '/lockercellline/getreturnkeys',
	/**
	 * @host /lockercellline/getByPlateNumber
	 */
	LOCKER_CELL_LINE_GET_BY_PLATE_NUMBER = '/lockercellline/getByPlateNumber',
	/**
	 * @host /lockercellline/getByLockerCell
	 */
	LOCKER_CELL_LINE_GET_BY_LOCKER_CELL = '/lockercellline/getByLockerCell',
}
