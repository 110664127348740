import type { FC, SyntheticEvent } from 'react';
import React, { useState } from 'react';

import { LanguageSwitch } from './Login.styled';
import { FormGroup } from '../../../external/reactstrap/form/FormGroup';
import { Input } from '../../../external/reactstrap/input';
import { Label } from '../../../external/reactstrap/label';
import { BaseComponentValues } from '../../../types/IComponentBaseProps';

interface ILoginSwitchLanguageProps {
	onChange?: (locale: string) => void;
	currentLocale: string;
}

export const LoginSwitchLanguage: FC<ILoginSwitchLanguageProps> = ({
	onChange,
	currentLocale = BaseComponentValues.locale,
}) => {
	const [locale, setLocale] = useState(currentLocale);

	const handleChange = (event: SyntheticEvent<HTMLInputElement>) => {
		const { currentTarget } = event;
		setLocale(currentTarget.value);
		if (onChange) {
			onChange(currentTarget.value);
		}
	};

	return (
		<LanguageSwitch className="switch-language d-flex flex-row justify-content-center py-3 ">
			<FormGroup className="me-3">
				<Input
					aria-label="Español"
					checked={locale === 'es'}
					className="me-1"
					id="languageES"
					name="language"
					onChange={handleChange}
					type="radio"
					value="es"
				/>
				<Label for="languageES">Español</Label>
			</FormGroup>

			<FormGroup>
				<Input
					aria-label="English"
					checked={locale === BaseComponentValues.locale}
					className="me-1"
					id="languageEn"
					label="English"
					name="language"
					onChange={handleChange}
					type="radio"
					value="en"
				/>
				<Label for="languageEn">English</Label>
			</FormGroup>
		</LanguageSwitch>
	);
};
