import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Search = lazyWithRetry(() => import('./pages/Search'));

export const searchRoutes: { [key: number]: IRouteConfig } = {};

searchRoutes[routesIdMap.Companies] = {
	component: Search,
	exact: true,
	id: routesIdMap.Companies,
	name: 'Search company',
	path: '/company/search',
	permissions: [PermissionsType.Company],
	menuText: 'Search',
	icon: 'search',
};
