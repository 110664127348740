import { createAction } from '@reduxjs/toolkit';

import {
	partnerGroupServiceGetAll,
	partnerGroupServiceGetByCode,
	partnerGroupServiceInsert,
	partnerGroupServiceModify,
	partnerGroupServiceSearch,
} from '../../data/services/PartnerGroupService';
import type { IPartnerGroup } from '../../models/entities/PartnerGroup';
import type {
	IPartnerGroupGetAllParams,
	IPartnerGroupGetByCodeParams,
	IPartnerGroupInsertParams,
	IPartnerGroupModifyParams,
	IPartnerGroupSearchParams,
} from '../../models/serviceParams/PartnerGroupParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves all partner groups.
 *
 * @param {IPartnerGroup[]} payload - The partner groups to be retrieved.
 * @param {IPartnerGroupGetAllParams} params - Parameters for retrieving all partner groups.
 * @returns {Promise<void>} - A promise that resolves to an array of partner groups.
 */
export const partnerGroupGetAll = createAsyncAction<IPartnerGroup[], IPartnerGroupGetAllParams>(
	'partnerGroup/getAll',
	partnerGroupServiceGetAll,
);

/**
 * Retrieves partner groups by their specific code.
 *
 * @param {IPartnerGroup[]} payload - The partner groups to be retrieved.
 * @param {IPartnerGroupGetByCodeParams} params - Parameters to identify the partner groups by code.
 * @returns {Promise<void>} - A promise that resolves to an array of partner groups.
 */
export const partnerGroupGetByCode = createAsyncAction<IPartnerGroup, IPartnerGroupGetByCodeParams>(
	'partnerGroup/getByCode',
	partnerGroupServiceGetByCode,
);

/**
 * Searches for partner groups based on specified parameters.
 *
 * @param {IPartnerGroup[]} payload - The partner groups to be returned based on the search criteria.
 * @param {IPartnerGroupSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of partner groups.
 */
export const partnerGroupSearch = createAsyncAction<IPartnerGroup[], IPartnerGroupSearchParams>(
	'partnerGroup/search',
	partnerGroupServiceSearch,
);

/**
 * Inserts a new partner group.
 *
 * @param {IPartnerGroup} payload - The partner group to be inserted.
 * @param {IPartnerGroupInsertParams} params - Parameters for the insertion of the partner group.
 * @returns {Promise<void>} - A promise that resolves after the partner group is inserted.
 */
export const partnerGroupInsert = createAsyncAction<IPartnerGroup, IPartnerGroupInsertParams>(
	'partnerGroup/insert',
	partnerGroupServiceInsert,
);

/**
 * Modifies an existing partner group.
 *
 * @param {IPartnerGroup} payload - The partner group to be modified.
 * @param {IPartnerGroupModifyParams} params - Parameters for the modification of the partner group.
 * @returns {Promise<void>} - A promise that resolves after the partner group is modified.
 */
export const partnerGroupModify = createAsyncAction<IPartnerGroup, IPartnerGroupModifyParams>(
	'partnerGroup/modify',
	partnerGroupServiceModify,
);

/**
 * Clears the partner group state.
 */
export const partnerGroupClear = createAction('partnerGroup/clear');
