import { createAction } from '@reduxjs/toolkit';

import {
	computerServiceGetByBranch,
	computerServiceGetByDeviceIP,
	computerServiceGetByDeviceName,
	computerServiceSetData,
} from '../../data/services/ComputerService';
import type { IComputer } from '../../models/entities/Computer';
import type {
	IComputerGetByBranchParams,
	IComputerGetByDeviceIpParams,
	IComputerGetByDeviceNameParams,
	IComputerSetDataParams,
} from '../../models/serviceParams/ComputerParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves computers associated with a specific branch.
 *
 * @param {IComputer[]} payload - The computers to be retrieved.
 * @param {IComputerGetByBranchParams} params - Parameters to identify computers in a specific branch.
 * @returns {Promise<void>} - A promise that resolves to an array of computers.
 */
export const computerGetByBranch = createAsyncAction<IComputer[], IComputerGetByBranchParams>(
	'computer/getByBranch',
	computerServiceGetByBranch,
);

/**
 * Retrieves computers by their device IP address.
 *
 * @param {IComputer[]} payload - The computers to be retrieved.
 * @param {IComputerGetByDeviceIpParams} params - Parameters to identify computers by their IP address.
 * @returns {Promise<void>} - A promise that resolves to an array of computers.
 */
export const computerGetByDeviceIp = createAsyncAction<IComputer, IComputerGetByDeviceIpParams>(
	'computer/getByDeviceIp',
	computerServiceGetByDeviceIP,
);

/**
 * Retrieves computers by their device name.
 *
 * @param {IComputer[]} payload - The computers to be retrieved.
 * @param {IComputerGetByDeviceNameParams} params - Parameters to identify computers by their device name.
 * @returns {Promise<void>} - A promise that resolves to an array of computers.
 */
export const computerGetByDeviceName = createAsyncAction<IComputer, IComputerGetByDeviceNameParams>(
	'computer/getByDeviceName',
	computerServiceGetByDeviceName,
);

/**
 * Sets or updates data for a specific computer.
 *
 * @param {IComputer} payload - The computer data to be set or updated.
 * @param {IComputerSetDataParams} params - Parameters detailing the computer data to be set or updated.
 * @returns {Promise<void>} - A promise that resolves after the computer data is set or updated.
 */
export const computerSetData = createAsyncAction<IComputer, IComputerSetDataParams>(
	'computer/setData',
	computerServiceSetData,
);

/**
 * Sets the host name of a computer in the state.
 *
 * @param {string} payload - The host name to be set for the computer.
 */
export const setHostName = createAction<string>('computer/setHostName');
