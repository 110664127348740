export enum RentRateMarginEndPoints {
	/**
	 * @host `/rentRateMargin/search/`
	 */
	SEARCH = '/rentRateMargin/search/',

	/**
	 * @host `/rentRateMargin/insert/`
	 */
	INSERT = '/rentRateMargin/insert/',

	/**
	 * @host `/rentRateMargin/deactivate`
	 */
	DEACTIVATE = '/rentRateMargin/deactivate',
}
