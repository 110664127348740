export enum PreviousRegistrationEndPoints {
	/**
	 * Previous registration search
	 * @host `/vehicle/searchPreviousRegistration/`
	 */
	SEARCH = '/vehicle/searchPreviousRegistration/',
	/**
	 * Previous registration insert
	 * @host `/vehicle/importPreviousRegistration/`
	 */
	IMPORT = '/vehicle/importPreviousRegistration/',
	/**
	 * Previous registration modify
	 * @host `/vehicle/modifyPreviousRegistration`
	 */
	MODIFY = '/vehicle/modifyPreviousRegistration',
	/**
	 * Previous registration register
	 * @host `/vehicle/register`
	 */
	REGISTER = '/vehicle/register',
	/**
	 * Delete previous registration
	 * @host `/vehicle/deletePreviousRegistration`
	 */
	DELETE = '/vehicle/deletePreviousRegistration',
}
