export enum CardEndPoint {
	GET_BY_CUSTOMER = '/cardData/getByCustomer',
	/**
	 * Unlink card
	 * @host `/cardData/Unlink`
	 */
	UNLINK_CARD = '/cardData/Unlink',
	PREFERRED = '/cardData/preferred',
	/**
	 * Link customer card
	 * @host `/cardData/LinkToCustomer`
	 */
	LINK_CARD = '/cardData/LinkToCustomer',

	/**
	 * Link call back payment
	 * @host `/cardData/LinkToCustomerCallBack`
	 */
	LINK_CARD_CALL_BACK = '/cardData/LinkToCustomerCallBack',
}
