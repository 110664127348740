import type { FC } from 'react';
import { useEffect, useState } from 'react';

import { getKeysFromEnum } from '@crac/core/helpers/commons';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IPermissionsToggleParams } from '@crac/core/redux/actions/PermissionsActions';

import { PermissionToolList } from './PermissionToolList';
import { PermissionToolSelect } from './PermissionToolSelect';
import { PermissionToolSwitch } from './PermissionToolSwitch';

export interface PermissionToolProps {
	employeePermissions: string[];
	isEnabled: boolean;
	permissions: string[];
	togglePermissions: (payload: IPermissionsToggleParams) => void;
	updatePermissions: (permissions: string[]) => void;
}

export interface PermissionToolState {
	permissionType: string;
	permissionsByType: string[];
}
export const PermissionTool: FC<PermissionToolProps> = ({
	employeePermissions,
	isEnabled,
	permissions,
	togglePermissions,
	updatePermissions,
}) => {
	const [permissionType, setPermissionType] = useState('booking');
	const [permissionsByType, setPermissionsByType] = useState<string[]>([]);

	const handleChangePermissionType = (permissionType: string) => {
		let permissionsByType = getKeysFromEnum(PermissionsType);

		if (permissionType !== 'all') {
			permissionsByType = permissionsByType.filter((permission) =>
				permission.toLocaleLowerCase().startsWith(permissionType.toLowerCase()),
			);
		}

		setPermissionType(permissionType);
		setPermissionsByType(permissionsByType);
	};

	const handleChangePermission = (event: React.SyntheticEvent<HTMLInputElement>) => {
		const {
			currentTarget: { name, checked },
		} = event;

		let updatePermissionList = permissions;
		if (checked && !updatePermissionList.includes(name)) {
			updatePermissionList = [...updatePermissionList, name];
		} else if (updatePermissionList.includes(name)) {
			updatePermissionList = [...updatePermissionList.filter((permission) => permission !== name)];
		}

		updatePermissions([...updatePermissionList].sort((accumulate, current) => accumulate.localeCompare(current)));
	};

	const handleTogglePermissionTool = (enabled: boolean) => {
		const permissions: string[] = enabled ? employeePermissions : [];
		togglePermissions({ isEnabled: enabled, permissions });
	};

	useEffect(() => {
		handleChangePermissionType('booking');
	}, []);

	return (
		<div className="permission-tool">
			<div className="p-2">
				<PermissionToolSwitch isEnabled={isEnabled} onToggle={handleTogglePermissionTool} />
				{isEnabled ? (
					<PermissionToolSelect onChange={handleChangePermissionType} selected={permissionType} />
				) : null}
			</div>
			{isEnabled && permissions ? (
				<PermissionToolList
					onChange={handleChangePermission}
					permissions={permissionsByType}
					userPermissions={permissions}
				/>
			) : null}
		</div>
	);
};
