import React from 'react';

import type { IBranch } from '@crac/core/models/entities/Branch';
import type { IEmployee } from '@crac/core/models/entities/Employee';
import type { IPartner } from '@crac/core/models/entities/Partner';
import type { IProvider } from '@crac/core/models/entities/Provider';

export interface IAppContext {
	permissions: string[];
	user: IEmployee | IPartner;
	employeeBranch: IBranch;
	employeeProvider: IProvider;
}
export const AppContext = React.createContext<Partial<IAppContext>>({});
