'use client';
import type { FC } from 'react';
import React from 'react';

import { Label as RSLabel } from 'reactstrap';
import type { LabelProps as RSLabelProps } from 'reactstrap/types/lib/Label';

export type LabelProps = RSLabelProps;

export const Label: FC<LabelProps> = ({ children, ...props }) => {
	return <RSLabel {...props}>{children}</RSLabel>;
};
