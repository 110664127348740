import { chargeBackServiceGetReasons } from '../../data/services/ChargebackService';
import type { IChargeBackReason } from '../../models/entities/ChargeBack';
import type { IChargeBackGetReasonsParams } from '../../models/serviceParams/ChargeBackParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves chargeback reasons.
 *
 * @param {IChargeBackReason[]} payload - The chargeback reasons to be retrieved.
 * @param {IChargeBackGetReasonsParams} params - Parameters for identifying the context or criteria to retrieve chargeback reasons.
 * @returns {Promise<void>} - A promise that resolves to an array of chargeback reasons.
 */
export const chargeBackGetReasons = createAsyncAction<IChargeBackReason[], IChargeBackGetReasonsParams>(
	'chargeBack/getReasons',
	chargeBackServiceGetReasons,
);
