import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const ExcessType = lazyWithRetry(() => import('./pages/ExcessType'));
const ExcessTypeNew = lazyWithRetry(() => import('./pages/ExcessTypeNew'));
const ExcessTypeModify = lazyWithRetry(() => import('./pages/ExcessTypeModify'));

export const excessTypeRoutes: { [key: number]: IRouteConfig } = {};

excessTypeRoutes[routesIdMap.WorkshopExcessType] = {
	component: ExcessType,
	exact: true,
	id: routesIdMap.WorkshopExcessType,
	name: 'Excess type',
	path: '/workshop/excess-type',
	icon: 'exclamation-triangle',
	permissions: [PermissionsType.ExcessType],
	childrens: [routesIdMap.WorkshopExcessTypeNew, routesIdMap.WorkshopExcessTypeModify],
	menuText: 'Excess type',
};

excessTypeRoutes[routesIdMap.WorkshopExcessTypeNew] = {
	component: ExcessTypeNew,
	exact: true,
	id: routesIdMap.WorkshopExcessTypeNew,
	name: 'New excess type',
	path: '/workshop/excess-type/new',
	permissions: [PermissionsType.ExcessType],
	isChild: true,
	menuText: 'New excess type',
	icon: 'plus',
};

excessTypeRoutes[routesIdMap.WorkshopExcessTypeModify] = {
	component: ExcessTypeModify,
	exact: true,
	id: routesIdMap.WorkshopExcessTypeModify,
	name: 'Modify excess type',
	path: '/workshop/excess-type/modify',
	permissions: [PermissionsType.ExcessType],
	isChild: true,
	icon: 'pencil',
};
