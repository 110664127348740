import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Services = lazyWithRetry(() => import('./pages/Services'));

export const servicesRoutes: { [key: number]: IRouteConfig } = {};

servicesRoutes[routesIdMap.BookingsNewServices] = {
	component: Services,
	exact: true,
	id: routesIdMap.BookingsNewServices,
	name: 'Booking services',
	path: '/booking/new/services',
	permissions: [PermissionsType.BookingNew],
	icon: 'file-o',
};
