import type { IDepositRate } from '../../models/entities/DepositRate';
import type {
	IDepositRateDeactivateParams,
	IDepositRateInsertParams,
	IDepositRateSearchParams,
} from '../../models/serviceParams/DepositRateParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { DepositRateEndPoints } from '../endPoints/DepositRateEndPoints';

/**
 * Search deposit rates
 */
export const depositRateServiceSearch = (model: IDepositRateSearchParams): Promise<ServiceResponse<IDepositRate[]>> => {
	return Api.get<IDepositRate[], IDepositRateSearchParams>(DepositRateEndPoints.SEARCH, model);
};

/**
 * Insert deposit rates
 */
export const depositRateServiceInsert = (model: IDepositRateInsertParams): Promise<ServiceResponse<IDepositRate[]>> => {
	return Api.post<IDepositRate[], IDepositRateInsertParams>(DepositRateEndPoints.INSERT, model);
};

/**
 * Deactivate deposit rates
 */
export const depositRateServiceDeactivate = (
	model: IDepositRateDeactivateParams,
): Promise<ServiceResponse<IDepositRate[]>> => {
	return Api.post<IDepositRate[], IDepositRateDeactivateParams>(DepositRateEndPoints.DEACTIVATE, model);
};
