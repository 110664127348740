import type { ICustomer } from '../../models/entities/Customer';
import type {
	IDigitizationDigitizeParams,
	IDigitizationRemoveParams,
} from '../../models/serviceParams/DigitizationParams';
import type { ICustomerDigitizationResponse } from '../../models/serviceResponse/CustomerDigitizationResponse';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { DigitizationEndPoints } from '../endPoints/CustomerDigitizationEndPoints';

/**
 * Digitize document
 *  {string} customerCode Customer code
 *  {string} bookingNumber Booking number
 *  {file} file
 * @return {Promise} `DocumentPropType` List document
 */
export const digitizationServiceDigitize = (
	model: IDigitizationDigitizeParams,
): Promise<ServiceResponse<ICustomerDigitizationResponse>> => {
	return Api.post<ICustomerDigitizationResponse, IDigitizationDigitizeParams>(
		DigitizationEndPoints.DIGITIZATION_DIGITIZE,
		model,
	);
};

/**
 * Remove digitization
 * {string} customerCode
 * @param model
 * @returns {Promise<ICustomer>}
 */
export const digitizationServiceRemove = (model: IDigitizationRemoveParams): Promise<ServiceResponse<ICustomer>> => {
	return Api.post<ICustomer, IDigitizationRemoveParams>(DigitizationEndPoints.DIGITIZATION_REMOVE, model);
};
