import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Services = lazyWithRetry(() => import('./pages/Services'));

export const servicesRoutes: { [key: number]: IRouteConfig } = {};

servicesRoutes[routesIdMap.BookingsDropOffServices] = {
	component: Services,
	exact: true,
	id: routesIdMap.BookingsDropOffServices,
	name: 'Services',
	path: '/booking/drop-off/:id/:plateNumber/services',
	permissions: [PermissionsType.BookingDropOffProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: 'sign-out',
};
