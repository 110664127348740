import type { IRentRateFormula } from '../../models/entities/RentRateFormula';
import type {
	IRentRateFormulaDeleteParams,
	IRentRateFormulaInsertParams,
	IRentRateFormulaModifyParams,
	IRentRateFormulasSearchParams,
} from '../../models/serviceParams/RentParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { RentRateFormulaEndPoints } from '../endPoints/RentRateFormulaEndPoints';

export const rentRateFormulaSearchService = (
	model: IRentRateFormulasSearchParams,
): Promise<ServiceResponse<IRentRateFormula[]>> => {
	return Api.get<IRentRateFormula[], IRentRateFormulasSearchParams>(RentRateFormulaEndPoints.SEARCH, model);
};
export const rentRateFormulaInsertService = (
	model: IRentRateFormulaInsertParams,
): Promise<ServiceResponse<IRentRateFormula[]>> => {
	return Api.post<IRentRateFormula[], IRentRateFormulaInsertParams>(RentRateFormulaEndPoints.INSERT, model);
};

export const rentRateFormulaModifyService = (
	model: IRentRateFormulaModifyParams,
): Promise<ServiceResponse<IRentRateFormula[]>> => {
	return Api.post<IRentRateFormula[], IRentRateFormulaModifyParams>(RentRateFormulaEndPoints.MODIFY, model);
};

export const rentRateFormulaDeleteService = (
	model: IRentRateFormulaDeleteParams,
): Promise<ServiceResponse<IRentRateFormula>> => {
	return Api.post<IRentRateFormula, IRentRateFormulaDeleteParams>(RentRateFormulaEndPoints.DELETE, model);
};
