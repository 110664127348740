export enum RegularizationEndPoints {
	/**
	 * Booking regularization get all subject
	 * @host `/bookingregularizationsubject/getall/`
	 */
	GET_ALL_SUBJECT = '/bookingregularizationsubject/getall/',

	/**
	 * Regularization insert
	 * @host `/bookingregularization/insert/`
	 */
	INSERT = '/bookingregularization/insert/',

	/**
	 * Regularization update
	 * @host `/bookingregularization/update/`
	 */
	UPDATE = '/bookingregularization/update/',

	/**
	 * @host `/bookingregularization/getbycode/`
	 */
	GET_BY_CODE = '/bookingregularization/getbycode/',

	/**
	 * @host `/bookingregularization/getpendingbyuser/`
	 */
	GET_PENDING_BY_USER = '/bookingregularization/getpendingbyuser/',

	/**
	 * @host `/bookingregularization/getbybooking/`
	 */
	GET_BY_BOOKING = '/bookingregularization/getbybooking/',
}
