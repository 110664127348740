import { createAction } from '@reduxjs/toolkit';

import {
	rentRateValidationDeleteService,
	rentRateValidationInsertService,
	rentRateValidationModifyService,
	rentRateValidationSearchService,
} from '../../data/services/RentRateValidationService';
import type { IRentRateValidation } from '../../models/entities/RentRateValidation';
import type {
	IRentRateValidationDeleteParams,
	IRentRateValidationSaveParams,
	IRentRateValidationSearchParams,
} from '../../models/serviceParams/RentRateValidationParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for rent rate validations based on specified parameters.
 *
 * @param {IRentRateValidation[]} payload - The rent rate validations to be retrieved.
 * @param {IRentRateValidationSearchParams} params - Parameters for the search of rent rate validations.
 * @returns {Promise<void>} - A promise that resolves to an array of rent rate validations.
 */
export const rentRateValidationSearch = createAsyncAction<IRentRateValidation[], IRentRateValidationSearchParams>(
	'rentRateValidation/search',
	rentRateValidationSearchService,
);

/**
 * Inserts a new rent rate validation.
 *
 * @param {IRentRateValidation} payload - The rent rate validation to be inserted.
 * @param {IRentRateValidationSaveParams} params - Parameters for the insertion of the rent rate validation.
 * @returns {Promise<void>} - A promise that resolves after the rent rate validation is inserted.
 */
export const rentRateValidationInsert = createAsyncAction<IRentRateValidation[], IRentRateValidationSaveParams>(
	'rentRateValidation/insert',
	rentRateValidationInsertService,
);

/**
 * Modifies an existing rent rate validation.
 *
 * @param {IRentRateValidation} payload - The rent rate validation to be modified.
 * @param {IRentRateValidationSaveParams} params - Parameters for the modification of the rent rate validation.
 * @returns {Promise<void>} - A promise that resolves after the rent rate validation is modified.
 */
export const rentRateValidationModify = createAsyncAction<IRentRateValidation[], IRentRateValidationSaveParams>(
	'rentRateValidation/modify',
	rentRateValidationModifyService,
);

/**
 * Deletes a specific rent rate validation.
 *
 * @param {IRentRateValidation} payload - The rent rate validation to be deleted.
 * @param {IRentRateValidationDeleteParams} params - Parameters for the deletion of the rent rate validation.
 * @returns {Promise<void>} - A promise that resolves after the rent rate validation is deleted.
 */
export const rentRateValidationDelete = createAsyncAction<IRentRateValidation, IRentRateValidationDeleteParams>(
	'rentRateValidation/delete',
	rentRateValidationDeleteService,
);

/**
 * Resets the rent rate validation state.
 */
export const rentRateValidationReset = createAction('rentRateValidation/reset');
