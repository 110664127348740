export enum SaleServiceEndpoints {
	/**
	 * Searches saleServices
	 * @host `/saleService/search`
	 */
	SEARCH = '/saleService/search/',

	/**
	 * Inserts margin
	 * @host `/saleService/insert`
	 */
	INSERT = '/saleService/insert/',

	/**
	 * Modifies margin
	 * @host `/saleService/modify`
	 */
	MODIFY = '/saleService/modify/',

	/**
	 * Deletes margin
	 * @host `/saleService/delete`
	 */
	DELETE = '/saleService/delete/',
}
