export enum TurnEndPoints {
	/**
	 * Turn set to call
	 * @host `/turn/settocall/`
	 */
	FINISH_RECEPTION_SET_TURNS_TO_CALL = '/turn/settocall/',

	/**
	 * Turn get next by queue
	 * @host `/turn/getnextturnbyqueue/`
	 */
	GET_NEXT_TURN_BY_QUEUE = '/turn/getnextturnbyqueue/',

	/**
	 * Turn get by id
	 * @host `/turn/getturn/`
	 */
	GET_TURN = '/turn/getturn/',

	/**
	 * Turn get pending by branch
	 * @host `/turn/getpendingbybranch/`
	 */
	GET_TURNS_BY_BRANCH = '/turn/getpendingbybranch/',

	/**
	 * Turn insert
	 * @host `/turn/insert/`
	 */
	INSERT = '/turn/insert/',

	/**
	 * Turn liberate by queue
	 * @host `/turn/liberateturnsbyqueue/`
	 */
	LIBERATE_TURNS_BY_QUEUE = '/turn/liberateturnsbyqueue/',

	/**
	 * Turn remove of TV
	 * @host `'external/removeturn/`
	 */
	REMOVE_TURN_ON_TV = 'external/removeturn/',

	/**
	 * Turn add on TV
	 * @host `'external/addturn/`
	 */
	SHOW_TURN_ON_TV = 'external/addturn/',

	/**
	 * Turn update
	 * @host `/turn/update/`
	 */
	UPDATE = '/turn/update/',

	/**
	 * Turn add on tv
	 * @host `/turn/addturnontv/`
	 */
	ADD_ON_TV = '/turn/addontv/',

	/**
	 * Turn remove on tv
	 * @host `/turn/removeontv/`
	 */
	REMOVE_ON_TV = '/turn/removeontv/',

	/**
	 * Turn get by booking
	 * @host `/turn/getbybooking/`
	 */
	GET_TURN_BY_BOOKING = '/turn/getbybooking/',

	/**
	 * Turn get by checkin
	 * @host `/turn/getbycheckin`
	 */
	GET_TURN_BY_CHECKIN = '/turn/getbycheckin/',

	/**
	 * Turn insert with checkin
	 * @host `/turn/insertwithcheckin`
	 */
	INSERT_TURN_BY_CHECKIN = '/turn/insertwithcheckin/	',
}
