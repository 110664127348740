'use client';
import type { FC } from 'react';
import React from 'react';

import { Form as RSForm } from 'reactstrap';
import type { FormProps as RSFormProps } from 'reactstrap/types/lib/Form';

export type FormProps = RSFormProps;

export const Form: FC<FormProps> = ({ children, ...props }) => {
	return <RSForm {...props}>{children}</RSForm>;
};
