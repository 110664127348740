import { combineReducers } from '@reduxjs/toolkit';

import { currentReducer as Current } from './state/slices/current';
import { detailReducer as Detail } from './state/slices/detail';
import { historyReducer as History } from './state/slices/history';
import { modifyReducer as Modify } from './state/slices/modify';
import { openedBookingsReducer as OpenedBookings } from './state/slices/openedBookings';
import { pendingReducer as Pending } from './state/slices/pending';
import { signReducer as Sign } from './state/slices/sign';
import { spendingReducer as Spending } from './state/slices/spending';
import { spendingTypeReducer as SpendingType } from './state/slices/spendingType';

export const cashReducer = combineReducers({
	Current,
	Detail,
	History,
	Modify,
	OpenedBookings,
	Pending,
	Sign,
	Spending,
	SpendingType,
});
