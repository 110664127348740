'use client';
import type { FC } from 'react';
import React from 'react';

import { Card as RSCard } from 'reactstrap';
import type { CardProps as RSCardProps } from 'reactstrap/types/lib/Card';

export type CardProps = RSCardProps;

export const Card: FC<CardProps> = ({ children, ...props }) => {
	return <RSCard {...props}>{children}</RSCard>;
};
