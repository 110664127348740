import type { IInvoiceLine } from '../../models/entities/InvoiceLine';
import type { IInvoiceLineGetByInvoiceAndVehicleParams } from '../../models/serviceParams/InvoiceLineParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { InvoiceLineEndPoints } from '../endPoints/InvoiceLineEdnPoints';

/**
 * get invoiceLines by invoice code and plate number
 * @param string `invoiceCode`
 * @returns `Array<IInvoiceLine>`
 */
export const invoiceLineServiceGetByInvoiceAndVehicle = (
	model: IInvoiceLineGetByInvoiceAndVehicleParams,
): Promise<ServiceResponse<IInvoiceLine[]>> => {
	return Api.get<IInvoiceLine[], IInvoiceLineGetByInvoiceAndVehicleParams>(
		InvoiceLineEndPoints.GET_BY_INVOICE_AND_VEHICLE,
		model,
	);
};
