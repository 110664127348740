import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const VehicleModel = lazyWithRetry(() => import('~/features/fleet/vehicleModel/pages/VehicleModel'));
const VehicleModelNew = lazyWithRetry(() => import('~/features/fleet/vehicleModel/pages/VehicleModelNew'));
const VehicleModelModify = lazyWithRetry(() => import('~/features/fleet/vehicleModel/pages/VehicleModelModify'));

export const vehicleModelRoutes: { [key: number]: IRouteConfig } = {};

vehicleModelRoutes[routesIdMap.FleetVehicleModel] = {
	component: VehicleModel,
	exact: true,
	id: routesIdMap.FleetVehicleModel,
	name: 'Vehicle models',
	path: '/fleet/model',
	permissions: [PermissionsType.FleetVehicleModel],
	icon: 'car',
	menuText: 'Models',
	childrens: [routesIdMap.FleetVehicleModelNew, routesIdMap.FleetVehicleModelModify],
};

vehicleModelRoutes[routesIdMap.FleetVehicleModelNew] = {
	component: VehicleModelNew,
	exact: true,
	id: routesIdMap.FleetVehicleModelNew,
	name: 'New model',
	path: '/fleet/model/new',
	permissions: [PermissionsType.FleetVehicleModel],
	isChild: true,
	icon: 'plus',
	menuText: 'New model',
};

vehicleModelRoutes[routesIdMap.FleetVehicleModelModify] = {
	component: VehicleModelModify,
	exact: true,
	id: routesIdMap.FleetVehicleModelModify,
	name: 'Modify vehicle model',
	path: '/fleet/model/:brand/:modelCode',
	isChild: true,
	icon: 'pencil',
	permissions: [PermissionsType.FleetVehicleModel],
};
