import type { IRevisionType } from '../../models/entities/RevisionType';
import type {
	IRevisionTypeDeactivateParams,
	IRevisionTypeGetAllParams,
	IRevisionTypeInsertParams,
	IRevisionTypeModifyParams,
	IRevisionTypeReactivateParams,
	IRevisionTypeSearchParams,
} from '../../models/serviceParams/RevisionTypeParams';
import { Api } from '../../modules/shared/api';
import { RevisionTypeEndPoints } from '../endPoints/FleetRevisionTypeEndPoints';

export const revisionTypeServiceSearch = (model: IRevisionTypeSearchParams) => {
	return Api.get<IRevisionType[], IRevisionTypeSearchParams>(RevisionTypeEndPoints.SEARCH, model);
};

export const revisionTypeServiceInsert = (model: IRevisionTypeInsertParams) => {
	return Api.post<IRevisionType, IRevisionTypeInsertParams>(RevisionTypeEndPoints.INSERT, model);
};

export const revisionTypeServiceModify = (model: IRevisionTypeModifyParams) => {
	return Api.post<IRevisionType, IRevisionTypeModifyParams>(RevisionTypeEndPoints.MODIFY, model);
};

export const revisionTypeServiceGetAll = (model?: IRevisionTypeGetAllParams) => {
	return Api.get<IRevisionType[], IRevisionTypeGetAllParams>(RevisionTypeEndPoints.GET_ALL, model);
};

export const revisionTypeServiceDeactivate = (model: IRevisionTypeDeactivateParams) => {
	return Api.post<IRevisionType, IRevisionTypeDeactivateParams>(RevisionTypeEndPoints.DEACTIVATE, model);
};

export const revisionTypeServiceReactivate = (model: IRevisionTypeReactivateParams) => {
	return Api.post<IRevisionType, IRevisionTypeReactivateParams>(RevisionTypeEndPoints.REACTIVATE, model);
};
