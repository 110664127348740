import { spendingTypeServiceGetAll } from '../../data/services/SpendingTypeService';
import type { ISpendingType } from '../../models/entities/SpendingType';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Retrieves all spending types.
 *
 * @param {ISpendingType[]} payload - The spending types to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters, potentially used for filtering or configuration.
 * @returns {Promise<void>} - A promise that resolves to an array of all spending types.
 */
export const spendingTypeGetAll = createAsyncAction<ISpendingType[], IServiceBaseParams | undefined>(
	'spendingType/getAll',
	spendingTypeServiceGetAll,
);
