import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { bookingDetailClear } from '@crac/core/redux/actions/BookingActions';
import { bookingPickUpGetPendingByBranch } from '@crac/core/redux/actions/BookingPickUpActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IBookingReceptionReducerState } from '@crac/core/redux-store/reducersState/booking/ReceptionReducerState';

const initialState: IBookingReceptionReducerState = {
	bookings: [],
	receptionBookingsRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	receptionFinishRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};
const receptionSlice = createSlice({
	name: 'BookingReceptionSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET_PENDING_BY_BRANCH
		builder
			.addCase(bookingPickUpGetPendingByBranch.pending, (state) => {
				return {
					...state,
					receptionBookingsRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingPickUpGetPendingByBranch.fulfilled, (state, action) => {
				return {
					...state,
					bookings: deepCopy(action.payload),
					receptionBookingsRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingPickUpGetPendingByBranch.rejected, (state, action) => {
				return {
					...state,
					receptionBookingsRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR
		 * CLEAR CACHE
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, bookingDetailClear), () => {
			return initialState;
		});
		// FINISH
		/*
		 *No se usa y no tiene implementación
		 *
		 *BookingActionsType.BOOKING_RECEPTION_FINISH_REQUEST_START
		 *BookingActionsType.BOOKING_RECEPTION_FINISH_REQUEST_ERROR
		 *BookingActionsType.BOOKING_RECEPTION_FINISH_REQUEST_SUCCESS
		 *
		 *builder
		 *	.addCase(bookingReceptionFinish.pending, (state) => {
		 *		return {
		 *			...state,
		 *			receptionFinishRequest: {
		 *				inProgress: true,
		 *				messages: [],
		 *				ok: true,
		 *			},
		 *		};
		 *	})
		 *	.addCase(bookingReceptionFinish.fulfilled, (state) => {
		 *		return {
		 *			...state,
		 *			receptionFinishRequest: {
		 *				inProgress: false,
		 *				messages: [],
		 *				ok: true,
		 *			},
		 *		};
		 *	})
		 *	.addCase(bookingReceptionFinish.rejected, (state, action) => {
		 *		return {
		 *			...state,
		 *			receptionFinishRequest: {
		 *				inProgress: false,
		 *				messages: deepCopy(action.payload) || [],
		 *				ok: false,
		 *			},
		 *		};
		 *	});
		 */
	},
});

export const receptionReducer = receptionSlice.reducer;
