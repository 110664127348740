import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { companyClearSearch, companySearch } from '@crac/core/redux/actions/CompanyActions';
import type { ICompanySearchReducerState } from '@crac/core/redux-store/reducersState/company/SearchReducerState';
const initialState: ICompanySearchReducerState = {
	companies: [],
	companySearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const searchSlice = createSlice({
	name: 'CompanySearchSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => initialState);
		// CLEAR SEARCH
		builder.addCase(companyClearSearch, (state) => {
			return {
				...state,
				companies: [],
			};
		});
		// SEARCH
		builder
			.addCase(companySearch.pending, (state) => {
				return {
					...state,
					companySearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companySearch.fulfilled, (state, action) => {
				return {
					...state,
					companies: deepCopy(action.payload),
					companySearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companySearch.rejected, (state, action) => {
				return {
					...state,
					companySearchRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const searchReducer = searchSlice.reducer;
