import {
	extraDriverServiceGetByBooking,
	extraDriverServiceGetRemoveServices,
	extraDriverServiceGetServices,
	extraDriverServiceGetServicesByBooking,
	extraDriverServiceRemove,
	extraDriverServiceSave,
	extraDriverServiceSaveWithServices,
} from '../../data/services/ExtraDriverService';
import type { IExtraDriver } from '../../models/entities/ExtraDriver';
import type { IService } from '../../models/entities/Service';
import type {
	IExtraDriverGetByBookingParams,
	IExtraDriverGetRemoveServicesParams,
	IExtraDriverGetServicesByBookingParams,
	IExtraDriverGetServicesParams,
	IExtraDriverInsertWithServicesParams,
	IExtraDriverRemoveParams,
	IExtraDriverSaveParams,
} from '../../models/serviceParams/ExtraDriverParams';
import type { IExtraDriverSaveWithServicesResponse } from '../../models/serviceResponse/ExtraDriverResponse';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves extra driver details associated with a specific booking.
 *
 * @param {IExtraDriver[]} payload - The extra drivers to be retrieved.
 * @param {IExtraDriverGetByBookingParams} params - Parameters for identifying extra drivers related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of extra drivers.
 */
export const extraDriverGetByBooking = createAsyncAction<IExtraDriver[], IExtraDriverGetByBookingParams>(
	'extraDriver/getByBooking',
	extraDriverServiceGetByBooking,
);

/**
 * Retrieves services associated with extra drivers.
 *
 * @param {IService[]} payload - The services to be retrieved.
 * @param {IExtraDriverGetServicesParams} params - Parameters for identifying services related to extra drivers.
 * @returns {Promise<void>} - A promise that resolves to an array of services.
 */
export const extraDriverGetServices = createAsyncAction<IService[], IExtraDriverGetServicesParams>(
	'extraDriver/getServices',
	extraDriverServiceGetServices,
);

/**
 * Saves details of an extra driver.
 *
 * @param {IExtraDriver} payload - The extra driver to be saved.
 * @param {IExtraDriverSaveParams} params - Parameters for saving the extra driver.
 * @returns {Promise<void>} - A promise that resolves after the extra driver is saved.
 */
export const extraDriverSave = createAsyncAction<IExtraDriver, IExtraDriverSaveParams>(
	'extraDriver/save',
	extraDriverServiceSave,
);

/**
 * Removes an extra driver.
 *
 * @param {IExtraDriver} payload - The extra driver to be removed.
 * @param {IExtraDriverRemoveParams} params - Parameters for removing the extra driver.
 * @returns {Promise<void>} - A promise that resolves after the extra driver is removed.
 */
export const extraDriverRemove = createAsyncAction<IExtraDriver, IExtraDriverRemoveParams>(
	'extraDriver/remove',
	extraDriverServiceRemove,
);

/**
 * Removes services associated with extra drivers.
 *
 * @param {IService[]} payload - The services to be removed.
 * @param {IExtraDriverGetRemoveServicesParams} params - Parameters for removing services related to extra drivers.
 * @returns {Promise<void>} - A promise that resolves after the services are removed.
 */

export const extraDriverRemoveServices = createAsyncAction<IService[], IExtraDriverGetRemoveServicesParams>(
	'extraDriver/removeServices',
	extraDriverServiceGetRemoveServices,
);

/**
 * Clears the extra driver state.
 */
export const extraDriverClear = createSyncAction('extraDriver/clear');

/**
 * Clears the extra driver services state.
 */
export const extraDriverServicesClear = createSyncAction('extraDriver/servicesClear');

/**
 * Retrieves services associated with extra drivers for a specific booking.
 *
 * @param {IService[]} payload - The services to be retrieved.
 * @param {IExtraDriverGetServicesByBookingParams} params - Parameters for identifying services related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of services.
 */
export const extraDriverGetServicesByBooking = createAsyncAction<IService[], IExtraDriverGetServicesByBookingParams>(
	'extraDriver/getServicesByBooking',
	extraDriverServiceGetServicesByBooking,
);

/**
 * Saves an extra driver along with associated services.
 *
 * @param {IExtraDriverSaveWithServicesResponse} payload - The response after saving the extra driver with services, typically including details of the saved entities.
 * @param {IExtraDriverInsertWithServicesParams} params - Parameters for saving the extra driver and associated services, such as driver details and service information.
 * @returns {Promise<void>} - A promise that resolves with the response containing details of the saved extra driver and services.
 */
export const extraDriverSaveWithServices = createAsyncAction<
	IExtraDriverSaveWithServicesResponse,
	IExtraDriverInsertWithServicesParams
>('extraDriver/saveWithServices', async (params: IExtraDriverInsertWithServicesParams) => {
	// Saves the extra driver and adds to booking necessary services
	const saveResponse = await extraDriverServiceSaveWithServices(params);

	if (!saveResponse.ok) {
		return saveResponse;
	}

	// Removes unnecessary services from booking
	const servicesResponse = await extraDriverServiceGetRemoveServices({
		bookingNumber: params.booking.bookingNumber,
	});

	if (!servicesResponse.ok) {
		return saveResponse;
	}

	return saveResponse;
});

/**
 * Retrieves services associated with extra drivers that are marked for removal.
 *
 * @param {IService[]} payload - The services to be retrieved.
 * @param {IExtraDriverGetRemoveServicesParams} params - Parameters for identifying services related to extra drivers that need to be removed, such as specific criteria or identifiers.
 * @returns {Promise<void>} - A promise that resolves to an array of services marked for removal.
 */
export const extraDriverGetRemoveServices = createAsyncAction<IService[], IExtraDriverGetRemoveServicesParams>(
	'extraDriver/getRemoveServices',
	extraDriverServiceGetRemoveServices,
);
