import type { ILockerCell } from '../../models/entities/LockerCell';
import type {
	ILockerCelLineGetByPlateNumberParams,
	ILockerCelLineGetReturnKeysParams,
	ILockerCellCancelParams,
	ILockerCellCloseParams,
	ILockerCellGetByBranchParams,
	ILockerCellGetEmptyByBranchParams,
	ILockerCellLineGetByLockerCellParams,
	ILockerCellOpenExternalParams,
	ILockerCellOpenParams,
} from '../../models/serviceParams/LockersParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { LockerEndPoints } from '../endPoints/LockerEndPoint';

/**
 * Ge lockers by branch
 */
export const lockerServiceGetByBranch = (
	params: ILockerCellGetByBranchParams,
): Promise<ServiceResponse<ILockerCell[]>> => Api.get(LockerEndPoints.LOCKER_GET_BY_BRANCH, params);

/**
 * Get empty locker cell by branch
 */
export const lockerServiceGetEmptyByBranch = (
	params: ILockerCellGetEmptyByBranchParams,
): Promise<ServiceResponse<ILockerCell>> => Api.get(LockerEndPoints.LOCKER_GET_EMPTY_BY_BRANCH, params);

/**
 * Open locker cell
 */
export const lockerServiceOpen = (params: ILockerCellOpenParams): Promise<ServiceResponse<ILockerCell>> => {
	return Api.post(LockerEndPoints.LOCKER_OPEN, params);
};

/**
 * Close locker cell
 */
export const lockerServiceClose = (params: ILockerCellCloseParams): Promise<ServiceResponse<ILockerCell>> =>
	Api.post(LockerEndPoints.LOCKER_CLOSE, params);

/**
 * Cancel locker cell open
 */
export const lockerServiceCancel = (params: ILockerCellCancelParams): Promise<ServiceResponse<ILockerCell>> =>
	Api.post(LockerEndPoints.LOCKER_CANCEL, params);

/**
 * Get return keys by locker id
 */
export const lockerServiceGetReturnKeys = (
	params: ILockerCelLineGetReturnKeysParams,
): Promise<ServiceResponse<ILockerCell[]>> => Api.get(LockerEndPoints.LOCKER_CELL_LINE_GET_RETURN_KEYS, params);

/**
 * Get locker cell lines by plateNumber
 */
export const lockerServiceGetLinesByPlateNumber = (
	params: ILockerCelLineGetByPlateNumberParams,
): Promise<ServiceResponse<ILockerCell[]>> => Api.get(LockerEndPoints.LOCKER_CELL_LINE_GET_BY_PLATE_NUMBER, params);

/**
 * Get locker cell lines by locker cell id
 */
export const lockerServiceCellLineGetByLockerCell = (
	params: ILockerCellLineGetByLockerCellParams,
): Promise<ServiceResponse<ILockerCell[]>> => Api.get(LockerEndPoints.LOCKER_CELL_LINE_GET_BY_LOCKER_CELL, params);

export const lockerServiceCellOpenExternal = (
	params: ILockerCellOpenExternalParams,
): Promise<ServiceResponse<ILockerCell>> => Api.post(LockerEndPoints.LOCKER_OPEN, params);
