import {
	queueServiceDelete,
	queueServiceGetByBranch,
	queueServiceGetById,
	queueServiceInsert,
	queueServiceUpdate,
} from '../../data/services/QueueService';
import type { IQueue } from '../../models/entities/Queue';
import type {
	IQueueDeleteParams,
	IQueueGetByBranchParams,
	IQueueGetByIdParams,
	IQueueInsertParams,
	IQueueUpdateParams,
} from '../../models/serviceParams/QueueParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves a specific queue by its ID.
 *
 * @param {IQueue} payload - The queue to be retrieved.
 * @param {IQueueGetByIdParams} params - Parameters to identify the queue by its ID.
 * @returns {Promise<void>} - A promise that resolves to the queue matching the specified ID.
 */
export const queueGetById = createAsyncAction<IQueue, IQueueGetByIdParams>('queue/getById', queueServiceGetById);

/**
 * Inserts a new queue.
 *
 * @param {IQueue} payload - The queue to be inserted.
 * @param {IQueueInsertParams} params - Parameters for the insertion of the queue.
 * @returns {Promise<void>} - A promise that resolves after the queue is inserted.
 */
export const queueInsert = createAsyncAction<IQueue, IQueueInsertParams>('queue/insert', queueServiceInsert);

/**
 * Updates an existing queue.
 *
 * @param {IQueue} payload - The queue to be updated.
 * @param {IQueueUpdateParams} params - Parameters for the update of the queue.
 * @returns {Promise<void>} - A promise that resolves after the queue is updated.
 */
export const queueUpdate = createAsyncAction<IQueue, IQueueUpdateParams>('queue/update', queueServiceUpdate);

/**
 * Deletes a specific queue.
 *
 * @param {IQueue} payload - The queue to be deleted.
 * @param {IQueueDeleteParams} params - Parameters for the deletion of the queue.
 * @returns {Promise<void>} - A promise that resolves after the queue is deleted.
 */
export const queueDelete = createAsyncAction<IQueue, IQueueDeleteParams>('queue/delete', queueServiceDelete);

/**
 * Retrieves queues associated with a specific branch.
 *
 * @param {IQueue[]} payload - The queues to be retrieved.
 * @param {IQueueGetByBranchParams} params - Parameters to identify queues associated with a particular branch.
 * @returns {Promise<void>} - A promise that resolves to an array of queues for the specified branch.
 */
export const queueGetByBranch = createAsyncAction<IQueue[], IQueueGetByBranchParams>(
	'queue/getByBranch',
	queueServiceGetByBranch,
);
