import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import type { IVehicle } from '@crac/core/modules/vehicle/entities/Vehicle';
import {
	fleetMovementRestrictionRemove,
	fleetMovementRestrictionUpdate,
} from '@crac/core/redux/actions/FleetMovementRestrictionActions';
import type { IFleetMovementRestrictionReducerState } from '@crac/core/redux-store/reducersState/fleet/FleetMovementRestrictionReducerState';

const initialState: IFleetMovementRestrictionReducerState = {
	fleetMovementRestrictionRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	fleetMovementRestrictionSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicles: [],
};

const updateVehicles = (originalVehicles: IVehicle[], updateVehicles: IVehicle[]) => {
	return originalVehicles.map((v1) => updateVehicles.find((v2) => v2.plateNumber === v1.plateNumber) || v1);
};

const movementRestrictionSlice = createSlice({
	name: 'MovementRestriction',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// REMOVE
		builder
			.addCase(fleetMovementRestrictionRemove.pending, (state) => {
				return {
					...state,
					fleetMovementRestrictionRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetMovementRestrictionRemove.fulfilled, (state, action) => {
				return {
					...state,
					fleetMovementRestrictionRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: updateVehicles(state.vehicles, action.payload),
				};
			})
			.addCase(fleetMovementRestrictionRemove.rejected, (state, action) => {
				return {
					...state,
					fleetMovementRestrictionRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// UPDATE
		builder
			.addCase(fleetMovementRestrictionUpdate.pending, (state) => {
				return {
					...state,
					fleetMovementRestrictionRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetMovementRestrictionUpdate.fulfilled, (state, action) => {
				return {
					...state,
					fleetMovementRestrictionRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicles: updateVehicles(state.vehicles, action.payload),
				};
			})
			.addCase(fleetMovementRestrictionUpdate.rejected, (state, action) => {
				return {
					...state,
					fleetMovementRestrictionRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const movementRestrictionReducer = movementRestrictionSlice.reducer;
