import type { FC } from 'react';
import React from 'react';

import { styled } from 'styled-components';

import { Button } from '@crac/components/external/reactstrap/button';
import { ButtonGroup } from '@crac/components/external/reactstrap/buttonGroup';
import { FormGroup } from '@crac/components/external/reactstrap/form/FormGroup';

interface IPermissionToolSwitchProps {
	isEnabled: boolean;
	onToggle: (isEnabled: boolean) => void;
}

const SwitckStyles = styled(FormGroup)`
	margin: 10px auto;
	text-align: center;
`;

export const PermissionToolSwitch: FC<IPermissionToolSwitchProps> = ({ isEnabled, onToggle }) => {
	return (
		<SwitckStyles>
			<ButtonGroup>
				<Button
					active={isEnabled}
					color={isEnabled ? 'primary' : 'secondary'}
					isDisabled={isEnabled}
					onClick={() => onToggle(true)}
				>
					On
				</Button>
				<Button
					active={!isEnabled}
					color={isEnabled ? 'secondary' : 'primary'}
					isDisabled={!isEnabled}
					onClick={() => onToggle(false)}
				>
					Off
				</Button>
			</ButtonGroup>
		</SwitckStyles>
	);
};
