import { documentServiceGetByBooking } from '../../data/services/DocumentService';
import type { IDocument } from '../../models/entities/Document';
import type { IDocumentGetByBookingParams } from '../../models/serviceParams/DocumentsParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves documents associated with a specific booking.
 *
 * @param {IDocument[]} payload - The documents to be retrieved.
 * @param {IDocumentGetByBookingParams} params - Parameters to identify documents related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of documents associated with the booking.
 */
export const documentGetByBooking = createAsyncAction<IDocument[], IDocumentGetByBookingParams>(
	'document/getByBooking',
	documentServiceGetByBooking,
);
