import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	paymentLineClear,
	paymentLineDeleteChargeBack,
	paymentLineGetByCustomer,
	paymentLineSearch,
	paymentLineUpdateChargeBack,
} from '@crac/core/redux/actions/PaymentLineActions';
import type { ICustomerPaymentLinesReducerState } from '@crac/core/redux-store/reducersState/customer/PaymentLinesReducerState';

const initialState: ICustomerPaymentLinesReducerState = {
	paymentLineRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	paymentLines: [],
	paymentLine: undefined,
	paymentLineDeleteChargeBackRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	paymentLineUpdateChargeBackRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const customerPaymentLinesSlice = createSlice({
	name: 'customerPaymentLinesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// LINE_GET_BY_CUSTOMER
		builder
			.addCase(paymentLineGetByCustomer.pending, (state) => {
				return {
					...state,
					paymentLineRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					paymentLines: [],
				};
			})
			.addCase(paymentLineGetByCustomer.fulfilled, (state, action) => {
				return {
					...state,
					paymentLineRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					paymentLines: deepCopy(action.payload),
				};
			})
			.addCase(paymentLineGetByCustomer.rejected, (state, action) => {
				return {
					...state,
					paymentLineRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// UPDATE CHARGE BACK
		builder
			.addCase(paymentLineUpdateChargeBack.pending, (state) => {
				return {
					...state,
					paymentLineUpdateChargeBackRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentLineUpdateChargeBack.fulfilled, (state, action) => {
				return {
					...state,
					paymentLine: action.payload,
					paymentLineUpdateChargeBackRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					paymentLines: [
						...state.paymentLines.filter((line) => line.number !== action.payload.number),
						action.payload,
					],
				};
			})
			.addCase(paymentLineUpdateChargeBack.rejected, (state, action) => {
				return {
					...state,
					paymentLineUpdateChargeBackRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// DELETE CHARGE BACK
		builder
			.addCase(paymentLineDeleteChargeBack.pending, (state) => {
				return {
					...state,
					paymentLineDeleteChargeBackRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentLineDeleteChargeBack.fulfilled, (state, action) => {
				return {
					...state,
					paymentLine: action.payload,
					paymentLineDeleteChargeBackRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					paymentLines: [
						...state.paymentLines.filter((line) => line.number !== action.payload.number),
						action.payload,
					],
				};
			})
			.addCase(paymentLineDeleteChargeBack.rejected, (state, action) => {
				return {
					...state,
					paymentLineDeleteChargeBackRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// PAYMENT LINE SEARCH
		builder.addCase(paymentLineSearch.pending, (state) => {
			return {
				...state,
				paymentLines: [],
				paymentLineRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(paymentLineSearch.rejected, (state, action) => {
			return {
				...state,
				paymentLines: [],
				paymentLineRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(paymentLineSearch.fulfilled, (state, action) => {
			return {
				...state,
				paymentLines: action.payload,
				paymentLineRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});
		/**
		 * CLEAR CACHE
		 * CLEAR
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, paymentLineClear), () => {
			return initialState;
		});
	},
});

export const customerPaymentLinesReducer = customerPaymentLinesSlice.reducer;
