import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const RevisionType = lazyWithRetry(() => import('./pages/RevisionType'));
const RevisionTypeNew = lazyWithRetry(() => import('./pages/RevisionTypeNew'));
const RevisionTypeModify = lazyWithRetry(() => import('./pages/RevisionTypeModify'));

export const revisionTypeRoutes: { [key: number]: IRouteConfig } = {};

// TYPE
revisionTypeRoutes[routesIdMap.WorkshopRevisionType] = {
	component: RevisionType,
	exact: true,
	id: routesIdMap.WorkshopRevisionType,
	name: 'Revision type',
	menuText: 'Type',
	path: '/workshop/revision-type',
	icon: 'check',
	permissions: [PermissionsType.RevisionType],
	childrens: [routesIdMap.WorkshopRevisionTypeNew, routesIdMap.WorkshopRevisionTypeModify],
};

revisionTypeRoutes[routesIdMap.WorkshopRevisionTypeNew] = {
	component: RevisionTypeNew,
	exact: true,
	id: routesIdMap.WorkshopRevisionTypeNew,
	name: 'New revision type',
	path: '/workshop/revision-type/new',
	permissions: [PermissionsType.RevisionType],
	isChild: true,
	menuText: 'New revision type',
	icon: 'plus',
};

revisionTypeRoutes[routesIdMap.WorkshopRevisionTypeModify] = {
	component: RevisionTypeModify,
	exact: true,
	id: routesIdMap.WorkshopRevisionTypeModify,
	name: 'Modify revision type',
	path: '/workshop/revision-type/modify/:code',
	permissions: [PermissionsType.RevisionType],
	isChild: true,
	icon: 'pencil',
};
