import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const CustomerPayment = lazyWithRetry(() => import('./pages/Payment'));

export const paymentRoutes: { [key: number]: IRouteConfig } = {};

paymentRoutes[routesIdMap.CustomersPayments] = {
	component: CustomerPayment,
	exact: true,
	id: routesIdMap.CustomersPayments,
	name: 'Customer payments',
	path: '/customer/payments',
	permissions: [PermissionsType.CustomerDetail],
	icon: 'credit-card',
	menuText: 'Payments',
};
