import { MetaModelEndpoints } from './MetaModelEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IMetaModel } from '../entities/MetaModel';

export interface IMetaModelModifyParams extends IServiceBaseParams, Omit<IMetaModel, 'url'> {}

export const metaModelModifyService = (model: IMetaModelModifyParams) => {
	return Api.post<IMetaModel, IMetaModelModifyParams>(MetaModelEndpoints.MODIFY, model);
};
