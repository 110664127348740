import { SaleServiceEndpoints } from './SaleServiceEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleService } from '../entities/SaleService';

export interface ISaleServiceInsertParams extends IServiceBaseParams {
	name: string;
}

export const saleServiceInsertService = (model: ISaleServiceInsertParams) => {
	return Api.post<ISaleService, ISaleServiceInsertParams>(SaleServiceEndpoints.INSERT, model);
};
