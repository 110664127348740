/* eslint-disable default-param-last */
/* eslint-disable max-params */
import type { SweetAlertInput, SweetAlertOptions, SweetAlertResult } from 'sweetalert2';
import Swal from 'sweetalert2';

export { SweetAlertOptions };

/**
 * https://sweetalert2.github.io/#configuration
 */
const defaultConfig: any = {
	allowEscapeKey: true,
	allowOutsideClick: true,
	backdrop: true,
	buttonsStyling: false,
	cancelButtonText: 'No',
	confirmButtonText: 'Ok',
	customClass: {
		cancelButton: 'btn btn-lg btn-danger',
		confirmButton: 'btn btn-lg btn-primary',
	},
	focusCancel: true,
	reverseButtons: true,
	showCancelButton: false,
	showConfirmButton: true,
	target: '#root',
};

const AlertSucces = Swal.mixin({ icon: 'success' });
const AlertError = Swal.mixin({ icon: 'error' });
const AlertWarning = Swal.mixin({ icon: 'warning' });
const AlertInfo = Swal.mixin({ icon: 'info' });
const AlertQuestion = Swal.mixin({ icon: 'question' });

export const gCSwal = (
	title: string,
	message: typeof defaultConfig.html,
	type: typeof defaultConfig.icon = 'error',
	isConfirm = false,
	config?: SweetAlertOptions,
): Promise<SweetAlertResult> => {
	let model = AlertQuestion;

	switch (type) {
		case 'success':
			model = AlertSucces;
			break;
		case 'error':
			model = AlertError;
			break;
		case 'warning':
			model = AlertWarning;
			break;
		case 'info':
			model = AlertInfo;
			break;
		default:
			model = AlertQuestion;
			break;
	}

	// Do not show alert if session expired
	if (message && message.includes('Session expired')) {
		return Promise.resolve({} as SweetAlertResult<any>);
	}

	return model.fire({
		...defaultConfig,
		...config,
		customClass: config?.customClass ?? defaultConfig.customClass,
		html: message,
		showCancelButton: isConfirm,
		title,
	});
};

const Toast = Swal.mixin({
	/*
	 *OnOpen: (toast) => {
	 *toast.addEventListener('mouseenter', Swal.stopTimer);
	 *toast.addEventListener('mouseleave', Swal.resumeTimer);
	 *},
	 */
	position: 'top-end',
	showConfirmButton: false,
	timer: 3000,
	timerProgressBar: true,
	toast: true,
});

export const gCToast = (
	title: string,
	message?: string | HTMLElement,
	type: typeof defaultConfig.icon = 'success',
	position: typeof defaultConfig.position = 'top-right',
) => {
	// Do not show alert if session expired
	if (typeof message === 'string' && message.includes('Session expired')) {
		return;
	}

	Toast.fire({
		html: message,
		icon: type,
		title,
		position,
		timer: 3000,
	});
};

export const gCSwalInput = (
	title: string,
	confirmButtonText?: string,
	cancelButtonText?: string,
	input?: SweetAlertInput,
	inputPlaceholder?: string,
): Promise<SweetAlertResult> =>
	Swal.fire({
		...defaultConfig,
		cancelButtonText,
		confirmButtonText,
		input,
		inputPlaceholder,
		showCancelButton: true,
		title,
	});
