import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const VehicleGroupIncludedConfiguration = lazyWithRetry(() => import('./pages/VehicleGroupIncludedConfiguration'));
const VehicleGroupIncludedConfigurationNew = lazyWithRetry(
	() => import('./pages/VehicleGroupIncludedConfigurationNew'),
);
const VehicleGroupIncludedConfigurationModify = lazyWithRetry(
	() => import('./pages/VehicleGroupIncludedConfigurationModify'),
);

export const vehicleGroupIncludedConfigurationRoutes: { [key: number]: IRouteConfig } = {};

// VEHICLE GROUP INCLUDED CONFIGURATION
vehicleGroupIncludedConfigurationRoutes[routesIdMap.VehicleGroupIncludedConfiguration] = {
	component: VehicleGroupIncludedConfiguration,
	exact: true,
	id: routesIdMap.VehicleGroupIncludedConfiguration,
	name: 'Vehicle group included configuration',
	menuText: 'Vehicle groups included',
	path: '/pricing/vehicle-group-included-configuration',
	permissions: getPricingPermission([PermissionsType.PricingVehicleGroupIncludedConfiguration]),
	childrens: [routesIdMap.VehicleGroupIncludedConfigurationNew, routesIdMap.VehicleGroupIncludedConfigurationModify],
	icon: 'cog',
};
vehicleGroupIncludedConfigurationRoutes[routesIdMap.VehicleGroupIncludedConfigurationNew] = {
	component: VehicleGroupIncludedConfigurationNew,
	exact: true,
	id: routesIdMap.VehicleGroupIncludedConfigurationNew,
	name: 'New vehicle groups included configuration',
	path: '/pricing/vehicle-group-included-configuration/new',
	permissions: getPricingPermission([PermissionsType.PricingVehicleGroupIncludedConfiguration]),
	isChild: true,
	icon: 'plus',
	menuText: 'New vehicle groups included configuration',
};

vehicleGroupIncludedConfigurationRoutes[routesIdMap.VehicleGroupIncludedConfigurationModify] = {
	component: VehicleGroupIncludedConfigurationModify,
	exact: true,
	id: routesIdMap.VehicleGroupIncludedConfigurationModify,
	name: 'Modify Vehicle group included configuration',
	path: '/pricing/vehicle-group-included-configuration/:id',
	permissions: getPricingPermission([PermissionsType.PricingVehicleGroupIncludedConfiguration]),
	isChild: true,
	icon: 'pencil',
};
