import { createAction } from '@reduxjs/toolkit';

import {
	turnServiceAddOnTV,
	turnServiceGetNextTurnByQueue,
	turnServiceGetTurn,
	turnServiceGetTurnByCheckIn,
	turnServiceGetTurnsByBranch,
	turnServiceInsert,
	turnServiceLiberateTurnsByQueue,
	turnServiceRemoveOnTV,
	turnServiceSetToCall,
	turnServiceUpdate,
} from '../../data/services/TurnService';
import type { ITurn } from '../../models/entities/Turn';
import type {
	ITurnAddOnTVParams,
	ITurnGetNextTurnByQueueParams,
	ITurnGetTurnByCheckInParams,
	ITurnGetTurnParams,
	ITurnGetTurnsByBranchParams,
	ITurnInsertParams,
	ITurnLiberateTurnsByQueueParams,
	ITurnRemoveOnTVParams,
	ITurnSetToCallParams,
	ITurnUpdateParams,
} from '../../models/serviceParams/TurnParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves the next turn in a specific queue.
 *
 * @param {ITurn} payload - The next turn to be retrieved.
 * @param {ITurnGetNextTurnByQueueParams} params - Parameters for identifying the next turn in a queue.
 * @returns {Promise<void>} - A promise that resolves to the next turn in the specified queue.
 */
export const turnGetNextTurnByQueue = createAsyncAction<ITurn, ITurnGetNextTurnByQueueParams>(
	'turn/getNextTurnByQueue',
	turnServiceGetNextTurnByQueue,
);

/**
 * Inserts a new turn.
 *
 * @param {ITurn} payload - The turn to be inserted.
 * @param {ITurnInsertParams} params - Parameters for the insertion of the turn.
 * @returns {Promise<void>} - A promise that resolves after the turn is inserted.
 */
export const turnInsert = createAsyncAction<ITurn, ITurnInsertParams>('turn/insert', turnServiceInsert);

/**
 * Updates an existing turn.
 *
 * @param {ITurn} payload - The turn to be updated.
 * @param {ITurnUpdateParams} params - Parameters for the update of the turn.
 * @returns {Promise<void>} - A promise that resolves after the turn is updated.
 */
export const turnUpdate = createAsyncAction<ITurn, ITurnUpdateParams>('turn/update', turnServiceUpdate);

/**
 * Retrieves a specific turn.
 *
 * @param {ITurn} payload - The turn to be retrieved.
 * @param {ITurnGetTurnParams} params - Parameters for identifying the turn.
 * @returns {Promise<void>} - A promise that resolves to the specified turn.
 */
export const turnGetTurn = createAsyncAction<ITurn, ITurnGetTurnParams>('turn/getTurn', turnServiceGetTurn);

/**
 * Retrieves all turns for a specific branch.
 *
 * @param {ITurn[]} payload - The turns to be retrieved.
 * @param {ITurnGetTurnsByBranchParams} params - Parameters for identifying turns in a specific branch.
 * @returns {Promise<void>} - A promise that resolves to an array of turns.
 */
export const turnGetTurnsByBranch = createAsyncAction<ITurn[], ITurnGetTurnsByBranchParams>(
	'turn/getTurnsByBranch',
	turnServiceGetTurnsByBranch,
);

/**
 * Liberates turns in a specific queue.
 *
 * @param {ITurn} payload - The turn to be liberated.
 * @param {ITurnLiberateTurnsByQueueParams} params - Parameters for liberating turns in a queue.
 * @returns {Promise<void>} - A promise that resolves after the turns are liberated.
 */
export const turnLiberateTurnsByQueue = createAsyncAction<ITurn, ITurnLiberateTurnsByQueueParams>(
	'turn/liberateTurnsByQueue',
	turnServiceLiberateTurnsByQueue,
);

/**
 * Adds a turn to the TV display.
 *
 * @param {boolean} payload - The result of the add operation.
 * @param {ITurnAddOnTVParams} params - Parameters for adding the turn to the TV display.
 * @returns {Promise<void>} - A promise that resolves to a boolean indicating the success of the operation.
 */
export const turnAddOnTV = createAsyncAction<boolean, ITurnAddOnTVParams>('turn/addOnTV', turnServiceAddOnTV);

/**
 * Removes a turn from the TV display.
 *
 * @param {boolean} payload - The result of the remove operation.
 * @param {ITurnRemoveOnTVParams} params - Parameters for removing the turn from the TV display.
 * @returns {Promise<void>} - A promise that resolves to a boolean indicating the success of the operation.
 */
export const turnRemoveOnTV = createAsyncAction<boolean, ITurnRemoveOnTVParams>(
	'turn/removeOnTV',
	turnServiceRemoveOnTV,
);

/**
 * Sets a turn to be called next.
 *
 * @param {ITurn} payload - The turn to be set for calling.
 * @param {ITurnSetToCallParams} params - Parameters for setting the turn to be called.
 * @returns {Promise<void>} - A promise that resolves after the turn is set to be called.
 */
export const turnSetToCall = createAsyncAction<ITurn, ITurnSetToCallParams>('turn/setToCall', turnServiceSetToCall);

/**
 * Clears the turn management state.
 */
export const turnClear = createAction('turn/clear');

/**
 * Updates the list of turns.
 *
 * @param {ITurn} payload - The updated turn information.
 */
export const turnUpdateList = createAction<ITurn>('turn/updateList');

/**
 * Retrieves a turn associated with a specific check-in.
 *
 * @param {ITurn} payload - The turn to be retrieved.
 * @param {ITurnGetTurnByCheckInParams} params - Parameters for identifying the turn related to a check-in.
 * @returns {Promise<void>} - A promise that resolves to the specified turn.
 */
export const turnGetByCheckIn = createAsyncAction<ITurn, ITurnGetTurnByCheckInParams>(
	'turn/getByCheckIn',
	turnServiceGetTurnByCheckIn,
);
