import type { IQueue } from '../../models/entities/Queue';
import type {
	IQueueDeleteParams,
	IQueueGetByBranchParams,
	IQueueGetByIdParams,
	IQueueInsertParams,
	IQueueUpdateParams,
} from '../../models/serviceParams/QueueParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { QueueEndPoints } from '../endPoints/QueueEndPoints';

/**
 * Get queue by ID
 *  {int} id Queue ID
 * @return {Promise} `QueuePropType` Queue object
 */
export const queueServiceGetById = (model: IQueueGetByIdParams): Promise<ServiceResponse<IQueue>> => {
	return Api.get<IQueue, IQueueGetByIdParams>(QueueEndPoints.GET_BY_ID, model);
};

/**
 * Insert queue
 *  {Queue} queue Queue object
 * @return {Promise} `QueuePropType` Queue object
 */
export const queueServiceInsert = (model: IQueueInsertParams): Promise<ServiceResponse<IQueue>> => {
	return Api.post<IQueue, IQueueInsertParams>(QueueEndPoints.INSERT, model);
};

/**
 * Update queue
 *  {Queue} queue Queue object
 * @return {Promise} `QueuePropType` object
 */
export const queueServiceUpdate = (model: IQueueUpdateParams): Promise<ServiceResponse<IQueue>> => {
	return Api.post<IQueue, IQueueUpdateParams>(QueueEndPoints.UPDATE, model);
};

/**
 * Delete queue by ID
 *  {int} id Queue ID
 * @return {Promise} `QueuePropType` object
 */
export const queueServiceDelete = (model: IQueueDeleteParams): Promise<ServiceResponse<IQueue>> => {
	return Api.post<IQueue, IQueueDeleteParams>(QueueEndPoints.DELETE, model);
};

/**
 * Get queue by branch
 *  {string} branchCode Branch code
 * @returns {Promise} `Array<QueuePropType>`
 */
export const queueServiceGetByBranch = (model: IQueueGetByBranchParams): Promise<ServiceResponse<IQueue[]>> => {
	return Api.get<IQueue[], IQueueGetByBranchParams>(QueueEndPoints.GET_BY_BRANCH, model);
};
