const asideMenuCssClasses = [
		'aside-menu-show',
		'aside-menu-sm-show',
		'aside-menu-md-show',
		'aside-menu-lg-show',
		'aside-menu-xl-show',
	],
	checkBreakpoint = (breakpoint: string, list: Array<string>): boolean => {
		return list.indexOf(breakpoint) > -1;
	},
	sidebarCssClasses = ['sidebar-show', 'sidebar-sm-show', 'sidebar-md-show', 'sidebar-lg-show', 'sidebar-xl-show'],
	validBreakpoints = ['sm', 'md', 'lg', 'xl'];

export { asideMenuCssClasses, checkBreakpoint, sidebarCssClasses, validBreakpoints };
