import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Amounts = lazyWithRetry(() => import('./pages/Amounts'));

export const amountsRoutes: { [key: number]: IRouteConfig } = {};

amountsRoutes[routesIdMap.BookingsDetailAmounts] = {
	component: Amounts,
	exact: true,
	id: routesIdMap.BookingsDetailAmounts,
	name: 'Amounts',
	path: '/bookings/detail/:id/amounts',
	permissions: [PermissionsType.BookingDetail],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: 'credit-card',
};

/*
 * amountsRoutes[routesIdMap.BookingsDetailAmountsModify] = {
 * component: Amounts,
 * exact: false,
 * id: routesIdMap.BookingsDetailAmountsModify,
 * name: 'Amount modify',
 * path: '/bookings/detail/:id/amounts/modify',
 * permissions: [PermissionsType.BookingDetail],
 * icon: 'pencil',
 * };
 */
