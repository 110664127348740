export class MessageType {
	code?: string | null;

	field?: string | null;

	message: string;

	constructor(message: string, field?: string | null, code?: string | null) {
		this.field = field;
		this.message = message;
		this.code = code;
	}
}
