import { createAction } from '@reduxjs/toolkit';

import { cityServiceGetByCountryAndPostCode, cityServiceGetByProvince } from '../../data/services/CityService';
import type { ICity } from '../../models/entities/City';
import type {
	ICityGetByCountryAndPostCodeParams,
	ICityGetByProvinceParams,
} from '../../models/serviceParams/CityParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves cities based on the specified province.
 *
 * @param {ICity[]} payload - The cities to be retrieved.
 * @param {ICityGetByProvinceParams} params - Parameters to identify the cities in a specific province.
 * @returns {Promise<void>} - A promise that resolves to an array of cities.
 */
export const cityGetByProvince = createAsyncAction<ICity[], ICityGetByProvinceParams>(
	'city/getByProvince',
	cityServiceGetByProvince,
);

/**
 * Retrieves cities based on the specified country and postal code.
 *
 * @param {ICity[]} payload - The cities to be retrieved.
 * @param {ICityGetByCountryAndPostCodeParams} params - Parameters to identify the cities in a specific country and postal code area.
 * @returns {Promise<void>} - A promise that resolves to an array of cities.
 */
export const cityGetByCountryAndPostCode = createAsyncAction<ICity[], ICityGetByCountryAndPostCodeParams>(
	'city/getByCountryAndPostCode',
	cityServiceGetByCountryAndPostCode,
);

/**
 * Resets the city state.
 */
export const cityReset = createAction('city/reset');

/**
 * Updates the city state with a new array of cities.
 *
 * @param {ICity[]} payload - The new array of cities to update the state.
 */
export const cityUpdateState = createAction<ICity[]>('city/updateState');
