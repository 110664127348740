'use client';
import type { FC } from 'react';
import React from 'react';

import { BreadcrumbItem as RSBreadcrumbItem } from 'reactstrap';
import type { BreadcrumbItemProps as RSBreadcrumbItemProps } from 'reactstrap/types/lib/BreadcrumbItem';

export type BreadcrumbItemProps = RSBreadcrumbItemProps;

export const BreadcrumbItem: FC<BreadcrumbItemProps> = ({ children, ...props }) => {
	return <RSBreadcrumbItem {...props}>{children}</RSBreadcrumbItem>;
};
