import { BrandParkingEndpoints } from './BrandParkingEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBrandParking } from '../entities/BrandParking';

export interface IBrandParkingInsertParams
	extends IServiceBaseParams,
		Omit<IBrandParking, 'id' | 'deactivatedAt' | 'deactivatedBy'> {}

export const brandParkingInsertService = (model: IBrandParkingInsertParams) => {
	return Api.post<IBrandParking, IBrandParkingInsertParams>(BrandParkingEndpoints.INSERT, model);
};
