import type { IRegularizationSubject } from '../../models/entities/RegularizationSubject';
import { Api } from '../../modules/shared/api';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { RegularizationEndPoints } from '../endPoints/RegularizationEndPoints';

/**
 * Get all booking regularization subjects
 * @return {BookingRegularationSubjectPropType} BookingRegularizationSubectPropType
 */
export const regularizationSubjectServiceGetAll = (
	model: IServiceBaseParams,
): Promise<ServiceResponse<IRegularizationSubject[]>> => {
	return Api.get<IRegularizationSubject[], IServiceBaseParams>(RegularizationEndPoints.GET_ALL_SUBJECT, model);
};
