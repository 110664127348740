export enum BookingType {
	DIRECT = 0,
	WALKING = 1,
	PREBOOKING = 2,
	COMPANY = 3,
	FREE = 4,
	TRANSFER = 5,
	GUIDE = 6,
	STAFF = 7,
	SALESPERSON = 8,
	INTERNET = 9,
	GOLDCLUB = 10,
	BROKER = 11,
}
