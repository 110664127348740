import { BrandParkingEndpoints } from './BrandParkingEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBrandParking } from '../entities/BrandParking';

export interface IBrandParkingModifyParams
	extends IServiceBaseParams,
		Omit<IBrandParking, 'deactivatedAt' | 'deactivatedBy'> {}

export const brandParkingModifyService = (model: IBrandParkingModifyParams) => {
	return Api.post<IBrandParking, IBrandParkingModifyParams>(BrandParkingEndpoints.MODIFY, model);
};
