import { createContext, useContext } from 'react';

import type { useSignalR } from '~/features/shared/components/layout/hooks/useSignalR';

type SignalConnectionType = ReturnType<typeof useSignalR>;

export const RacSignalContext = createContext<SignalConnectionType | null>(null);

export const useRacSignalContext = (): SignalConnectionType | null => {
	const context = useContext(RacSignalContext);

	if (!context) {
		return null;
	}

	return context;
};
