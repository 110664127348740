export enum ChoosableServiceConfigurationEndPoints {
	/**
	 * Deactivate choosable service configuration
	 * @host `/choosableServiceConfiguration/deactivate`
	 */
	DEACTIVATE = '/choosableServiceConfiguration/deactivate',
	/**
	 * Get choosable service configuration
	 * @host `/choosableServiceConfiguration/get`
	 */
	INSERT = '/choosableServiceConfiguration/insert',
	/**
	 * Modify choosable service configuration
	 * @host `/choosableServiceConfiguration/modify`
	 */
	MODIFY = '/choosableServiceConfiguration/modify',
	/**
	 * Search choosable service configuration
	 * @host `/choosableServiceConfiguration/search`
	 */
	SEARCH = '/choosableServiceConfiguration/search',
}
