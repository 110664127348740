import { createSlice } from '@reduxjs/toolkit';

import {
	maximumBookingDurationClear,
	maximumBookingDurationDeactivate,
	maximumBookingDurationInsert,
	maximumBookingDurationSearch,
} from '@crac/core/redux/actions/MaximumBookingDurationActions';
import type { IMaximumBookingDurationReducerState } from '@crac/core/redux-store/reducersState/pricing/MaximumBookingDurationReducerState';

const initialState: IMaximumBookingDurationReducerState = {
	maximumBookingDurations: [],
	maximumBookingDurationInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	maximumBookingDurationSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	maximumBookingDurationDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const maximumBookingDurationSlice = createSlice({
	name: 'MaximumBookingDurationSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(maximumBookingDurationClear, (state) => {
			return {
				...state,
				maximumBookingDurations: [],
			};
		});
		// INSERT
		builder
			.addCase(maximumBookingDurationInsert.pending, (state) => {
				return {
					...state,
					maximumBookingDurationInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(maximumBookingDurationInsert.fulfilled, (state) => {
				return {
					...state,
					maximumBookingDurationInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(maximumBookingDurationInsert.rejected, (state, action) => {
				return {
					...state,
					maximumBookingDurationInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SEARCH
		builder
			.addCase(maximumBookingDurationSearch.pending, (state) => {
				return {
					...state,
					maximumBookingDurationSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(maximumBookingDurationSearch.fulfilled, (state, action) => {
				return {
					...state,
					maximumBookingDurationSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					maximumBookingDurations: action.payload,
				};
			})
			.addCase(maximumBookingDurationSearch.rejected, (state, action) => {
				return {
					...state,
					maximumBookingDurationSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DEACTIVATE
		builder
			.addCase(maximumBookingDurationDeactivate.pending, (state) => {
				return {
					...state,
					maximumBookingDurationDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(maximumBookingDurationDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					maximumBookingDurationDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					maximumBookingDurations: [
						...state.maximumBookingDurations.filter(
							({ id }) => !action.payload.map((item) => item.id).includes(id),
						),
						...action.payload,
					],
				};
			})
			.addCase(maximumBookingDurationDeactivate.rejected, (state, action) => {
				return {
					...state,
					maximumBookingDurationDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const maximumBookingDurationReducer = maximumBookingDurationSlice.reducer;
