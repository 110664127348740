export enum NotAllowedMovementEndPoints {
	/**
	 * Search not allowed movements between zones
	 * @host `/notAllowedMovementBetweenZones/search/`
	 */
	SEARCH = '/notAllowedMovementBetweenZones/search/',
	/**
	 * Insert not allowed movements between zones
	 * @host `/notAllowedMovementBetweenZones/insert/`
	 */
	INSERT = '/notAllowedMovementBetweenZones/insert/',
	/**
	 * Modify not allowed movements between zones
	 * @host `/notAllowedMovementBetweenZones/modify/`
	 */
	MODIFY = '/notAllowedMovementBetweenZones/modify/',
	/**
	 * Delete not allowed movements between zones
	 * @host `/notAllowedMovementBetweenZones/delete/`
	 */
	DELETE = '/notAllowedMovementBetweenZones/delete/',
}
