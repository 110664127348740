import type { IPartnerCategory } from '../../models/entities/PartnerCategory';
import type {
	IPartnerCategoryGetAllParams,
	IPartnerCategoryGetByCodeParams,
	IPartnerCategoryInsertParams,
	IPartnerCategoryModifyParams,
	IPartnerCategorySearchParams,
} from '../../models/serviceParams/PartnerCategoryParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { PartnerCategoryEndPoints } from '../endPoints/PartnerCategoryEndPoints';

export const partnerCategoryServiceGetAll = (
	model: IPartnerCategoryGetAllParams,
): Promise<ServiceResponse<IPartnerCategory[]>> => {
	return Api.get<IPartnerCategory[], IPartnerCategoryGetAllParams>(PartnerCategoryEndPoints.GET_ALL, model);
};

export const partnerCategoryServiceGetByCode = (
	model: IPartnerCategoryGetByCodeParams,
): Promise<ServiceResponse<IPartnerCategory>> => {
	return Api.get<IPartnerCategory, IPartnerCategoryGetAllParams>(PartnerCategoryEndPoints.GET_BY_CODE, model);
};

export const partnerCategoryServiceSearch = (
	model: IPartnerCategorySearchParams,
): Promise<ServiceResponse<IPartnerCategory[]>> => {
	return Api.get<IPartnerCategory[], IPartnerCategorySearchParams>(PartnerCategoryEndPoints.SEARCH, model);
};

export const partnerCategoryServiceInsert = (
	model: IPartnerCategoryInsertParams,
): Promise<ServiceResponse<IPartnerCategory[]>> => {
	return Api.post<IPartnerCategory[], IPartnerCategoryInsertParams>(PartnerCategoryEndPoints.INSERT, model);
};

export const partnerCategoryServiceModify = (
	model: IPartnerCategoryModifyParams,
): Promise<ServiceResponse<IPartnerCategory>> => {
	return Api.post<IPartnerCategory, IPartnerCategoryModifyParams>(PartnerCategoryEndPoints.MODIFY, model);
};
