import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const ServiceRateType = lazyWithRetry(() => import('./pages/ServiceRateType'));
const ServiceRateTypeNew = lazyWithRetry(() => import('./pages/ServiceRateTypeNew'));
const ServiceRateTypeModify = lazyWithRetry(() => import('./pages/ServiceRateTypeModify'));

export const serviceRateTypeRoutes: { [key: number]: IRouteConfig } = {};

serviceRateTypeRoutes[routesIdMap.ServiceRateTypes] = {
	component: ServiceRateType,
	exact: true,
	id: routesIdMap.ServiceRateTypes,
	name: 'Service rate types',
	menuText: 'Types',
	path: '/pricing/service-rate-types',
	permissions: getPricingPermission([PermissionsType.PricingServiceRateType]),
	childrens: [routesIdMap.ServiceRateTypesNew, routesIdMap.ServiceRateTypesEdit],
	icon: 'tags',
};
serviceRateTypeRoutes[routesIdMap.ServiceRateTypesEdit] = {
	component: ServiceRateTypeModify,
	exact: true,
	isChild: true,
	id: routesIdMap.ServiceRateTypesEdit,
	name: 'Edit service rate type',
	path: '/pricing/service-rate-types/:id',
	permissions: getPricingPermission([PermissionsType.PricingServiceRateType]),
	icon: 'pencil',
};
serviceRateTypeRoutes[routesIdMap.ServiceRateTypesNew] = {
	component: ServiceRateTypeNew,
	exact: true,
	isChild: true,
	id: routesIdMap.ServiceRateTypesNew,
	name: 'New service rate type',
	menuText: 'New service rate type',
	path: '/pricing/service-rate-types/new',
	permissions: getPricingPermission([PermissionsType.PricingServiceRateType]),
	icon: 'plus',
};
