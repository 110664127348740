import type { FC } from 'react';
import React from 'react';

import { LoginCard, LoginCardBody, LoginStyled } from './Login.styled';

export const LoginContainer: FC<{ children: React.ReactNode; className?: string }> = ({ children, className }) => (
	<LoginStyled className={className}>
		<LoginCard>
			<LoginCardBody>{children}</LoginCardBody>
		</LoginCard>
	</LoginStyled>
);
