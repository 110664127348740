import { createSlice } from '@reduxjs/toolkit';

import {
	metaModelDelete,
	metaModelInsert,
	metaModelModify,
	metaModelSaveImage,
	metaModelSearch,
	metaModelsClear,
} from '@crac/core/modules/fleet/metaModel/state/actions/MetaModelActions';
import type { IMetaModelStateType } from '@crac/core/modules/fleet/metaModel/state/stateType/MetaModelStateType';

const initialState: IMetaModelStateType = {
	metaModels: [],
	metaModelDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	metaModelInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	metaModelModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	metaModelSaveImageRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	metaModelSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const metaModelSlice = createSlice({
	name: 'MetaModelSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		//* SEARCH
		builder.addCase(metaModelSearch.pending, (state) => {
			return {
				...state,
				metaModels: [],
				metaModelSearchRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(metaModelSearch.rejected, (state, action) => {
			return {
				...state,
				metaModelSearchRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(metaModelSearch.fulfilled, (state, action) => {
			return {
				...state,
				metaModels: action.payload,
				metaModelSearchRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* INSERT
		builder.addCase(metaModelInsert.pending, (state) => {
			return {
				...state,
				metaModelInsertRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(metaModelInsert.rejected, (state, action) => {
			return {
				...state,
				metaModelInsertRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(metaModelInsert.fulfilled, (state, action) => {
			return {
				...state,
				metaModels: [...state.metaModels, action.payload],
				metaModelInsertRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* MODIFY
		builder.addCase(metaModelModify.pending, (state) => {
			return {
				...state,
				metaModelModifyRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(metaModelModify.rejected, (state, action) => {
			return {
				...state,
				metaModelModifyRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(metaModelModify.fulfilled, (state, action) => {
			return {
				...state,
				metaModels: state.metaModels.map((metaModel) =>
					metaModel.id === action.payload.id ? action.payload : metaModel,
				),
				metaModelModifyRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* SAVE IMAGE
		builder.addCase(metaModelSaveImage.pending, (state) => {
			return {
				...state,
				metaModelSaveImageRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(metaModelSaveImage.rejected, (state, action) => {
			return {
				...state,
				metaModelSaveImageRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(metaModelSaveImage.fulfilled, (state, action) => {
			return {
				...state,
				metaModels: state.metaModels.map((metaModel) =>
					metaModel.id === action.payload.id ? action.payload : metaModel,
				),
				metaModelSaveImageRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* DELETE
		builder.addCase(metaModelDelete.pending, (state) => {
			return {
				...state,
				metaModelDeleteRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(metaModelDelete.rejected, (state, action) => {
			return {
				...state,
				metaModelDeleteRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(metaModelDelete.fulfilled, (state, action) => {
			return {
				...state,
				metaModels: state.metaModels.filter((metaModel) => metaModel.id !== action.payload.id),
				metaModelDeleteRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* CLEAR
		builder.addCase(metaModelsClear, (state) => {
			return {
				...state,
				metaModels: [],
			};
		});
	},
});

export const metaModelReducer = metaModelSlice.reducer;
