import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { ICity } from '@crac/core/models/entities/City';
import { cityGetByCountryAndPostCode, cityReset, cityUpdateState } from '@crac/core/redux/actions/CityActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { ICityReducerState } from '@crac/core/redux-store/reducersState/city';

export const citiesStorageKey = 'cities';
const { getItem, setItem, removeItem } = managerLocalStorage();

const initialState: ICityReducerState = {
	cities: (getItem(citiesStorageKey) as ICity[]) || [],
	cityGetByCountryAndPostCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const citySlice = createSlice({
	name: 'CitySlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			removeItem(citiesStorageKey);
			return initialState;
		});
		// CITY GET BY POST CODE AND COUNTRY
		builder
			.addCase(cityGetByCountryAndPostCode.pending, (state) => {
				return {
					...state,
					cities: [],
					cityGetByCountryAndPostCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cityGetByCountryAndPostCode.fulfilled, (state, action) => {
				setItem(citiesStorageKey, action.payload);
				return {
					...state,
					cities: deepCopy(action.payload),
					cityGetByCountryAndPostCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cityGetByCountryAndPostCode.rejected, (state, action) => {
				return {
					...state,
					cityGetByCountryAndPostCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CITY RESET
		builder.addCase(cityReset, (state) => {
			return {
				...state,
				cities: [],
			};
		});
		// UPDATE STATE
		builder.addCase(cityUpdateState, (state, action) => {
			return {
				...state,
				cities: action.payload || [],
			};
		});
	},
});

export const cityReducer = citySlice.reducer;
