export enum BookingLineEndPoints {
	/**
	 * Booking line insert
	 * @host `/bookingline/insert/`
	 */
	INSERT = '/bookingline/insert/',

	/**
	 * Bookingline modify
	 * @host `/bookingline/modify/`
	 */
	MODIFY = '/bookingline/modify/',

	/**
	 * Booking line remove
	 * @host `/bookingline/remove/`
	 */
	REMOVE = '/bookingline/remove/',

	/**
	 * Get booking lines
	 * @host `/bookingline/getbybooking/`
	 */
	GET_BY_BOOKING = '/bookingline/getbybooking/',

	/**
	 * Get booking lines
	 * @host `/bookingline/admonModify/`
	 */
	ADMON_MODIFY = '/bookingline/admonModify/',
}
