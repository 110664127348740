import {
	spendingServiceAdd,
	spendingServiceDelete,
	spendingServiceGetByCashLine,
	spendingServiceGetByCode,
} from '../../data/services/SpendingService';
import type { ISpending } from '../../models/entities/Spending';
import type {
	ISpendingAddParams,
	ISpendingDeleteParams,
	ISpendingGetByCashLineParams,
	ISpendingGetByCodeParams,
} from '../../models/serviceParams/SpendingParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Adds a new spending record.
 *
 * @param {ISpending} payload - The spending record to be added.
 * @param {ISpendingAddParams} params - Parameters for adding the spending record.
 * @returns {Promise<void>} - A promise that resolves after the spending record is added.
 */
export const spendingAdd = createAsyncAction<ISpending, ISpendingAddParams>('spending/add', spendingServiceAdd);

/**
 * Deletes a specific spending record.
 *
 * @param {ISpending} payload - The spending record to be deleted.
 * @param {ISpendingDeleteParams} params - Parameters for the deletion of the spending record.
 * @returns {Promise<void>} - A promise that resolves after the spending record is deleted.
 */
export const spendingDelete = createAsyncAction<ISpending, ISpendingDeleteParams>(
	'spending/delete',
	spendingServiceDelete,
);

/**
 * Retrieves a spending record by its code.
 *
 * @param {ISpending} payload - The spending record to be retrieved.
 * @param {ISpendingGetByCodeParams} params - Parameters for identifying the spending record by its code.
 * @returns {Promise<void>} - A promise that resolves to the specified spending record.
 */
export const spendingGetByCode = createAsyncAction<ISpending, ISpendingGetByCodeParams>(
	'spending/getByCode',
	spendingServiceGetByCode,
);

/**
 * Retrieves spending records associated with a specific cash line.
 *
 * @param {ISpending[]} payload - The spending records to be retrieved.
 * @param {ISpendingGetByCashLineParams} params - Parameters to identify spending records related to a specific cash line.
 * @returns {Promise<void>} - A promise that resolves to an array of spending records.
 */
export const spendingGetByCashLine = createAsyncAction<ISpending[], ISpendingGetByCashLineParams>(
	'spending/getByCashLine',
	spendingServiceGetByCashLine,
);
