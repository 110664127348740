import { createSlice } from '@reduxjs/toolkit';

import {
	notAllowedMovementClear,
	notAllowedMovementDelete,
	notAllowedMovementInsert,
	notAllowedMovementModify,
	notAllowedMovementSearch,
	notAllowedMovementSearchByZone,
} from '@crac/core/redux/actions/NotAllowedMovementActions';
import type { INotAllowedMovementReducerState } from '@crac/core/redux-store/reducersState/pricing/NotAllowedMovementReducerState';

const initialState: INotAllowedMovementReducerState = {
	notAllowedMovementDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	notAllowedMovementInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	notAllowedMovementModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	notAllowedMovementSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	notAllowedMovements: [],
	notAllowedMovementsByZone: [],
};

const notAllowedMovementSlice = createSlice({
	name: 'NotAllowedMovementSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(notAllowedMovementClear, () => {
			return initialState;
		});
		// SEARCH
		builder
			.addCase(notAllowedMovementSearch.pending, (state) => {
				return {
					...state,
					notAllowedMovementSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(notAllowedMovementSearch.fulfilled, (state, action) => {
				return {
					...state,
					notAllowedMovementSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					notAllowedMovements: action.payload,
				};
			})
			.addCase(notAllowedMovementSearch.rejected, (state, action) => {
				return {
					...state,
					notAllowedMovementSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SEARCH BY ZONE
		builder
			.addCase(notAllowedMovementSearchByZone.pending, (state) => {
				return {
					...state,
					notAllowedMovementSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(notAllowedMovementSearchByZone.fulfilled, (state, action) => {
				return {
					...state,
					notAllowedMovementSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					notAllowedMovementsByZone: action.payload,
				};
			})
			.addCase(notAllowedMovementSearchByZone.rejected, (state, action) => {
				return {
					...state,
					notAllowedMovementSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(notAllowedMovementInsert.pending, (state) => {
				return {
					...state,
					notAllowedMovementInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(notAllowedMovementInsert.fulfilled, (state) => {
				return {
					...state,
					notAllowedMovementInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(notAllowedMovementInsert.rejected, (state, action) => {
				return {
					...state,
					notAllowedMovementInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(notAllowedMovementModify.pending, (state) => {
				return {
					...state,
					notAllowedMovementModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(notAllowedMovementModify.fulfilled, (state, action) => {
				return {
					...state,
					notAllowedMovementModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					notAllowedMovements: [
						...state.notAllowedMovements.filter(({ id }) => id !== action.payload.id),
						action.payload,
					],
				};
			})
			.addCase(notAllowedMovementModify.rejected, (state, action) => {
				return {
					...state,
					notAllowedMovementModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DELETE
		builder
			.addCase(notAllowedMovementDelete.pending, (state) => {
				return {
					...state,
					notAllowedMovementDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(notAllowedMovementDelete.fulfilled, (state, action) => {
				return {
					...state,
					notAllowedMovementDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					notAllowedMovements: state.notAllowedMovements.filter(
						(move) => JSON.stringify(move) !== JSON.stringify(action.payload),
					),
				};
			})
			.addCase(notAllowedMovementDelete.rejected, (state, action) => {
				return {
					...state,
					notAllowedMovementDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const notAllowedMovementReducer = notAllowedMovementSlice.reducer;
