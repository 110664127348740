import { createSlice } from '@reduxjs/toolkit';

import {
	rentRateTypeInsert,
	rentRateTypeModify,
	rentRateTypeReset,
	rentRateTypeSearch,
} from '@crac/core/redux/actions/RentRateTypeActions';
import type { IRentRateTypeReducerState } from '@crac/core/redux-store/reducersState/pricing/RentRateTypeReducerState';

const initialState: IRentRateTypeReducerState = {
	rentRateType: null,
	rentRateTypeInsertRequest: { inProgress: false, messages: [], ok: true },
	rentRateTypeModifyRequest: { inProgress: false, messages: [], ok: true },
	rentRateTypes: [],
	rentRateTypeSearchRequest: { inProgress: false, messages: [], ok: true },
};

const rentRateTypeSlice = createSlice({
	name: 'rentRateType',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(rentRateTypeReset, () => {
			return initialState;
		});
		builder.addCase(rentRateTypeSearch.pending, (state) => {
			return {
				...state,
				rentRateTypeSearchRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateTypeSearch.rejected, (state, action) => {
			return {
				...state,
				rentRateTypeSearchRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateTypeSearch.fulfilled, (state, action) => {
			return {
				...state,
				rentRateTypeSearchRequest: { inProgress: false, ok: true, messages: [] },
				rentRateTypes: action.payload,
			};
		});
		builder.addCase(rentRateTypeInsert.pending, (state) => {
			return {
				...state,
				rentRateTypeInsertRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateTypeInsert.rejected, (state, action) => {
			return {
				...state,
				rentRateTypeInsertRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateTypeInsert.fulfilled, (state, action) => {
			return {
				...state,
				rentRateTypeInsertRequest: { inProgress: false, ok: true, messages: [] },
				rentRateTypes: [...state.rentRateTypes, action.payload],
			};
		});
		builder.addCase(rentRateTypeModify.pending, (state) => {
			return {
				...state,
				rentRateTypeModifyRequest: { inProgress: true, ok: true, messages: [] },
			};
		});
		builder.addCase(rentRateTypeModify.rejected, (state, action) => {
			return {
				...state,
				rentRateTypeModifyRequest: { inProgress: false, ok: false, messages: action.payload || [] },
			};
		});
		builder.addCase(rentRateTypeModify.fulfilled, (state, action) => {
			return {
				...state,
				rentRateTypeModifyRequest: { inProgress: false, ok: true, messages: [] },
				rentRateTypes: [
					...state.rentRateTypes.filter((rateType) => rateType.code !== action.payload.code),
					action.payload,
				],
			};
		});
	},
});

export const rentRateTypeReducer = rentRateTypeSlice.reducer;
