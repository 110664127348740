import type { IChoosableServiceConfiguration } from '../../models/entities/ChoosableServiceConfiguration';
import type {
	IChoosableServiceConfigurationDeactivateParams,
	IChoosableServiceConfigurationInsertParams,
	IChoosableServiceConfigurationModifyParams,
	IChoosableServiceConfigurationSearchParams,
} from '../../models/serviceParams/ChoosableServiceConfigurationParams';
import { Api } from '../../modules/shared/api';
import { ChoosableServiceConfigurationEndPoints } from '../endPoints/ChoosableServiceConfigurationEndPoints';

/**
 * Search choosable service configuration
 * @param params `IChoosableServiceConfigurationSearchParams`
 * @returns `Promise<ServiceResponse<IChoosableServiceConfiguration[]>>`
 */
export const serviceChoosableServiceConfigurationSearch = (params: IChoosableServiceConfigurationSearchParams) => {
	return Api.get<IChoosableServiceConfiguration[], IChoosableServiceConfigurationSearchParams>(
		ChoosableServiceConfigurationEndPoints.SEARCH,
		params,
	);
};

/**
 * Insert choosable service configuration
 * @param params `IChoosableServiceConfigurationInsertParams`
 * @returns `Promise<ServiceResponse<IChoosableServiceConfiguration[]>>`
 */
export const serviceChoosableServiceConfigurationInsert = (params: IChoosableServiceConfigurationInsertParams) => {
	return Api.post<IChoosableServiceConfiguration[], IChoosableServiceConfigurationInsertParams>(
		ChoosableServiceConfigurationEndPoints.INSERT,
		params,
	);
};

/**
 * Modify choosable service configuration
 * @param params `IChoosableServiceConfigurationModifyParams`
 * @returns `Promise<ServiceResponse<IChoosableServiceConfiguration>>`
 */
export const serviceChoosableServiceConfigurationModify = (params: IChoosableServiceConfigurationModifyParams) => {
	return Api.post<IChoosableServiceConfiguration, IChoosableServiceConfigurationModifyParams>(
		ChoosableServiceConfigurationEndPoints.MODIFY,
		params,
	);
};

/**
 * Deactivate choosable service configuration
 * @param params `IChoosableServiceConfigurationDeactivateParams`
 * @returns `Promise<ServiceResponse<IChoosableServiceConfiguration>>`
 */
export const serviceChoosableServiceConfigurationDeactivate = (
	params: IChoosableServiceConfigurationDeactivateParams,
) => {
	return Api.post<IChoosableServiceConfiguration[], IChoosableServiceConfigurationDeactivateParams>(
		ChoosableServiceConfigurationEndPoints.DEACTIVATE,
		params,
	);
};
