import type { IWorkshopAssignment } from '../../models/entities/WorkshopAssignment';
import type {
	IWorkshopAssignmentDeleteParams,
	IWorkshopAssignmentInsertParams,
	IWorkshopAssignmentModifyParams,
	IWorkshopAssignmentSearchParams,
} from '../../models/serviceParams/WorkshopAssignmentParams';
import { Api } from '../../modules/shared/api';
import { WorkshopAssignmentEndPoints } from '../endPoints/FleetWorkshopAssignmentEndPoints';

export const workshopAssignmentServiceSearch = (model: IWorkshopAssignmentSearchParams) => {
	return Api.get<IWorkshopAssignment[], IWorkshopAssignmentSearchParams>(WorkshopAssignmentEndPoints.SEARCH, model);
};

export const workshopAssignmentServiceInsert = (model: IWorkshopAssignmentInsertParams) => {
	return Api.post<IWorkshopAssignment, IWorkshopAssignmentInsertParams>(WorkshopAssignmentEndPoints.INSERT, model);
};

export const workshopAssignmentServiceModify = (model: IWorkshopAssignmentModifyParams) => {
	return Api.post<IWorkshopAssignment, IWorkshopAssignmentModifyParams>(WorkshopAssignmentEndPoints.MODIFY, model);
};

export const workshopAssignmentServiceDelete = (model: IWorkshopAssignmentDeleteParams) => {
	return Api.post<IWorkshopAssignment, IWorkshopAssignmentDeleteParams>(WorkshopAssignmentEndPoints.DELETE, model);
};
