/* eslint-disable @typescript-eslint/no-unused-vars */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { reducerStorage } from '@crac/core/helpers/reducerStorage';
import type { IVehicleGroupAvailabilityAndPrice } from '@crac/core/models/entities/Availability';
import { UnitTime } from '@crac/core/models/types/UnitTime';
import {
	bookingAvailabilityReset,
	bookingGetAvailability,
	bookingUpdateAvailability,
} from '@crac/core/redux/actions/BookingActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IAvailabilityReducerState } from '@crac/core/redux-store/reducersState/booking/AvailabilityReducerState';

const initialState: IAvailabilityReducerState = {
	availabilityDuration: 0,
	availabilityUnitTime: UnitTime.Day,
	availabilityPaymentDuration: 0,
	availabilityGroups: [],
	availabilityRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const avilabilityStorageKey = 'BOOKING_AVAILABILITY';

const { getStorage, removeStorage, updateStorage } = reducerStorage<
	Omit<IAvailabilityReducerState, 'availabilityRequest'>
>(
	avilabilityStorageKey,
	{
		availabilityGroups: initialState.availabilityGroups,
		availabilityDuration: initialState.availabilityDuration,
		availabilityUnitTime: initialState.availabilityUnitTime,
		availabilityPaymentDuration: initialState.availabilityPaymentDuration,
	},
	'sessionStorage',
);

const availabilitySlice = createSlice({
	name: 'BookingAvailabilitySlice',
	initialState: {
		...initialState,
		...getStorage(),
	},
	reducers: {},
	extraReducers: (builder) => {
		// GET AVAILABILITY
		builder
			.addCase(bookingGetAvailability.pending, (state) => {
				const { availabilityRequest, ...rest } = state;
				return {
					...updateStorage(rest, {
						...rest,
						availabilityDuration: 0,
						availabilityUnitTime: UnitTime.Day,
					}),
					availabilityRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingGetAvailability.fulfilled, (state, { payload }) => {
				const { availabilityRequest, ...rest } = state;
				return {
					...updateStorage(rest, {
						availabilityDuration: payload?.duration as number,
						availabilityGroups: payload?.vehicleGroupsAvailability as IVehicleGroupAvailabilityAndPrice[],
						availabilityUnitTime: payload?.unitTime as number,
						availabilityPaymentDuration: payload?.paymentDuration as number,
					}),
					availabilityRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingGetAvailability.rejected, (state, action) => {
				const { availabilityRequest, ...rest } = state;
				return {
					...updateStorage(rest, {
						...rest,
						availabilityDuration: 0,
						availabilityUnitTime: UnitTime.Day,
					}),
					availabilityRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		/**
		 *<<<<<<< HEAD
		 *=======
		 * UPDATE AVAILABILITY
		 */
		builder.addCase(bookingUpdateAvailability, (state, action) => {
			const { availabilityRequest, ...rest } = state;
			return {
				...updateStorage(rest, {
					...rest,
					availabilityDuration: action.payload.duration,
					availabilityGroups: action.payload.vehicleGroupsAvailability,
					availabilityPaymentDuration: action.payload.paymentDuration,
					availabilityUnitTime: action.payload.unitTime,
				}),
				availabilityRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});
		/**
		 *>>>>>>> develop
		 * CELAR CACHE
		 * RESET
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, bookingAvailabilityReset), () => {
			removeStorage();
			return initialState;
		});
	},
});

export const availabilityReducer = availabilitySlice.reducer;
