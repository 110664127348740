import type { IComputer } from '../../models/entities/Computer';
import type {
	IComputerGetByBranchParams,
	IComputerGetByDeviceIpParams,
	IComputerGetByDeviceNameParams,
	IComputerSetDataParams,
} from '../../models/serviceParams/ComputerParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { ComputerEndPoints } from '../endPoints/ComputerEndPoints';

/**
 * Get by branch
 *  {string} branchCode Branch code
 * @return {Array<Computer>} List Computer
 */
export const computerServiceGetByBranch = (
	model: IComputerGetByBranchParams,
): Promise<ServiceResponse<IComputer[]>> => {
	return Api.get<IComputer[], IComputerGetByBranchParams>(ComputerEndPoints.GET_BY_BRANCH, model);
};

/**
 * Set default printer and pinpad
 *  {string} computerName Computer namne
 *  {number} printerId Printer Id
 *  {number} pinpadId Pinpad Id
 * @return {Promise} Response promise
 */
export const computerServiceSetData = (model: IComputerSetDataParams): Promise<ServiceResponse<IComputer>> => {
	return Api.post<IComputer, IComputerSetDataParams>(ComputerEndPoints.SET_DATA, model);
};

export const computerServiceGetByDeviceIP = (
	model: IComputerGetByDeviceIpParams,
): Promise<ServiceResponse<IComputer>> => {
	return Api.get<IComputer, IComputerGetByDeviceIpParams>(ComputerEndPoints.GET_BY_DEVICEIP, model);
};

export const computerServiceGetByDeviceName = (
	model: IComputerGetByDeviceNameParams,
): Promise<ServiceResponse<IComputer>> => {
	return Api.get<IComputer, IComputerGetByDeviceNameParams>(ComputerEndPoints.GET_BY_DEVICE_NAME, model);
};
