import { createSlice } from '@reduxjs/toolkit';

import {
	oneWayClear,
	oneWayDelete,
	oneWayInsert,
	oneWayModify,
	oneWaySearch,
} from '@crac/core/redux/actions/OneWayActions';
import type { IOneWayReducerState } from '@crac/core/redux-store/reducersState/pricing/OneWayReducerState';

const initialState: IOneWayReducerState = {
	oneWayInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	oneWayModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	oneWays: [],
	oneWaySearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	oneWayDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const oneWaySlice = createSlice({
	name: 'OneWaySlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(oneWaySearch.pending, (state) => {
				return {
					...state,
					oneWaySearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(oneWaySearch.fulfilled, (state, action) => {
				return {
					...state,
					oneWaySearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					oneWays: action.payload,
				};
			})
			.addCase(oneWaySearch.rejected, (state, action) => {
				return {
					...state,
					oneWaySearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(oneWayInsert.pending, (state) => {
				return {
					...state,
					oneWayInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(oneWayInsert.fulfilled, (state) => {
				return {
					...state,
					oneWayInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(oneWayInsert.rejected, (state, action) => {
				return {
					...state,
					oneWayInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(oneWayModify.pending, (state) => {
				return {
					...state,
					oneWayModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(oneWayModify.fulfilled, (state) => {
				return {
					...state,
					oneWayModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(oneWayModify.rejected, (state, action) => {
				return {
					...state,
					oneWayModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DELETE
		builder
			.addCase(oneWayDelete.pending, (state) => {
				return {
					...state,
					oneWayDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(oneWayDelete.fulfilled, (state, action) => {
				return {
					...state,
					oneWayDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					oneWays: state.oneWays.filter(
						({ branch }) =>
							!(branch.from === action.payload.branch.from && branch.to === action.payload.branch.to),
					),
				};
			})
			.addCase(oneWayDelete.rejected, (state, action) => {
				return {
					...state,
					oneWayDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(oneWayClear, () => {
			return initialState;
		});
	},
});

export const oneWayReducer = oneWaySlice.reducer;
