import type { IFleetTransfer } from '../../models/entities/FleetTransfer';
import type {
	IFleetTransferDeactivateParams,
	IFleetTransferExportParams,
	IFleetTransferInsertParams,
	IFleetTransferModifyParams,
	IFleetTransferSearchParams,
} from '../../models/serviceParams/FleetTransferParams';
import { Api } from '../../modules/shared/api';
import { API_ENDPOINT } from '../../modules/shared/api/ApiEndPoint';
import { getToken } from '../../modules/shared/api/helpers';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { FleetTransferEndPoints } from '../endPoints/FleetTransferEndPoints';

export const fleetTransferServiceSearch = (model: IFleetTransferSearchParams) => {
	return Api.get<IFleetTransfer[], IFleetTransferSearchParams>(FleetTransferEndPoints.SEARCH, model);
};

export const fleetTransferServiceInsert = (model: IFleetTransferInsertParams) => {
	return Api.post<IFleetTransfer[], IFleetTransferInsertParams>(FleetTransferEndPoints.INSERT, model);
};

export const fleetTransferServiceDeactivate = (model: IFleetTransferDeactivateParams) => {
	return Api.post<IFleetTransfer[], IFleetTransferDeactivateParams>(FleetTransferEndPoints.DEACTIVATE, model);
};

export const fleetTransferServiceModify = (model: IFleetTransferModifyParams) => {
	return Api.post<IFleetTransfer[], IFleetTransferModifyParams>(FleetTransferEndPoints.MODIFY, model);
};

export const fleetTransferServiceExport = async (model: IFleetTransferExportParams): Promise<ServiceResponse<null>> => {
	const { code } = model;
	const token = model.token ?? getToken();
	const headers = new Headers();
	headers.append('Content-Language', 'en-GB');
	headers.append('Authorization', `Bearer ${token}`);

	try {
		const request = await fetch(`${API_ENDPOINT}${FleetTransferEndPoints.EXPORT}?code=${code}`, { headers });

		if (!request.ok) {
			const jsonResponse = await request.json();
			return {
				ok: false,
				errors: [{ message: jsonResponse.message as string }],
			};
		}

		const response = await request.blob();

		const link = document.createElement('a');

		link.href = window.URL.createObjectURL(response);
		link.download = code;

		document.body.appendChild(link);
		link.click();
		document.body.removeChild(link);

		return { ok: true, errors: [] };
	} catch (error) {
		let msg = 'Document cannot be downloaded';
		if (error instanceof Error) {
			const { message } = error;
			msg = message;
		}

		return {
			ok: false,
			errors: [{ message: msg }],
		};
	}
};
