import type { IExcessType } from '../../models/entities/ExcessType';
import type {
	IExcessTypeDeleteParams,
	IExcessTypeSaveParams,
	IExcessTypeSearchParams,
} from '../../models/serviceParams/ExcessTypeParams';
import { Api } from '../../modules/shared/api';
import { ExcessTypeEndpoints } from '../endPoints/ExcessTypeEndpoints';

export const excessTypeServiceSearch = (model: IExcessTypeSearchParams) => {
	return Api.get<IExcessType[], IExcessTypeSearchParams>(ExcessTypeEndpoints.SEARCH, model);
};

export const excessTypeServiceInsert = (model: IExcessTypeSaveParams) => {
	return Api.post<IExcessType, IExcessTypeSaveParams>(ExcessTypeEndpoints.INSERT, model);
};

export const excessTypeServiceModify = (model: IExcessTypeSaveParams) => {
	return Api.post<IExcessType, IExcessTypeSaveParams>(ExcessTypeEndpoints.MODIFY, model);
};

export const excessTypeServiceDelete = (model: IExcessTypeDeleteParams) => {
	return Api.post<IExcessType, IExcessTypeDeleteParams>(ExcessTypeEndpoints.DELETE, model);
};
