import { useEffect, useState } from 'react';

import { HttpStatusCodeType } from '@crac/core/modules/shared/types/HttpStatusCodeType';

/**
 * Check is recibed a post message with unauthorized status code
 *
 * - This message is sended from the makeRequest function in the core when the request status code is 401
 */
export const useUnAuthorizedRequest = () => {
	const [isUnAuthorized, setIsAuthorized] = useState(false);

	useEffect(() => {
		const handleUnauthorized = (event: MessageEvent) => {
			if (event.data === HttpStatusCodeType.UNAUTHORIZED) {
				setIsAuthorized(true);
			}

			if (event.data === HttpStatusCodeType.OK && isUnAuthorized) {
				setIsAuthorized(false);
			}
		};

		window.addEventListener('message', handleUnauthorized);

		return () => {
			window.removeEventListener('message', handleUnauthorized);
		};
	}, [isUnAuthorized]);

	return { isUnAuthorized };
};
