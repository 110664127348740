'use client';
import type { FC } from 'react';
import React from 'react';

import { ListGroupItem as RSListGroupItem } from 'reactstrap';
import type { ListGroupItemProps as RSListGroupItemProps } from 'reactstrap/types/lib/ListGroupItem';

export type ListGroupItemProps = RSListGroupItemProps & {
	className?: string;
};

export const ListGroupItem: FC<ListGroupItemProps> = ({ children, ...props }) => {
	return <RSListGroupItem {...props}>{children}</RSListGroupItem>;
};
