import type { ITicket, ITicketComment, ITicketFile } from '../../models/entities/Ticket';
import type {
	ITicketManagementAddCommentParams,
	ITicketManagementCloseParams,
	ITicketManagementContactCentreParams,
	ITicketManagementGetByBookingParams,
	ITicketManagementGetByBranchParams,
	ITicketManagementGetByCustomerParams,
	ITicketManagementGetCommentFileParams,
	ITicketManagementNewParams,
} from '../../models/serviceParams/TicketManagementParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { TicketManagementEndPoints } from '../endPoints/TicketManagementEndPoints';

/**
 * Send ticket to Contact Centre
 */
export const ticketManagementServiceContactCentre = (
	model: ITicketManagementContactCentreParams,
): Promise<ServiceResponse<boolean>> => {
	return Api.post<boolean, ITicketManagementContactCentreParams>(TicketManagementEndPoints.CONTACT_CENTRE, model);
};

/**
 * Get ticket by booking
 * @param params ITicketManagementGetByBookingParams
 * @returns ApiDataResponseType<Ticket[]>
 */
export const ticketManagementServiceGetByBooking = (params: ITicketManagementGetByBookingParams) =>
	Api.get<ITicket[], ITicketManagementGetByBookingParams>(TicketManagementEndPoints.GET_BY_BOOKING, params);

/**
 * Get ticket by customer
 * @param params ITicketManagementGetByCustomerParams
 * @returns ApiDataResponseType<Ticket[]>
 */
export const ticketManagementServiceGetByCustomer = (params: ITicketManagementGetByCustomerParams) =>
	Api.get<ITicket[], ITicketManagementGetByCustomerParams>(TicketManagementEndPoints.GET_BY_CUSTOMER, params);

/**
 * Get ticket by branch
 * @param params ITicketManagementGetByBranchParams
 * @returns ApiDataResponseType<Ticket[]>
 */
export const ticketManagementServiceGetByBranch = (params: ITicketManagementGetByBranchParams) =>
	Api.get<ITicket[], ITicketManagementGetByBranchParams>(TicketManagementEndPoints.GET_BY_BRANCH, params);

/**
 * Create new tiket
 * @param params ITicketManagementNewParams
 * @returns ApiDataResponseType<Ticket>
 */
export const ticketManagementServiceNew = (params: ITicketManagementNewParams) =>
	Api.post<ITicket, ITicketManagementNewParams>(TicketManagementEndPoints.NEW, params);

/**
 * Add coment to ticket
 * @param params ITicketManagementAddCommentParams
 * @returns ApiDataResponseType<TicketComment>
 */
export const ticketManagementServiceAddComment = (params: ITicketManagementAddCommentParams) =>
	Api.post<ITicketComment, ITicketManagementAddCommentParams>(TicketManagementEndPoints.ADD_COMMENT, params);

/**
 * Close ticket
 * @param params ITicketManagementCloseParams
 * @returns ApiDataReponseType<boolean>
 */
export const ticketManagementServiceClose = (params: ITicketManagementCloseParams) =>
	Api.post<ITicket, ITicketManagementCloseParams>(TicketManagementEndPoints.CLOSE, params);

/**
 * Get comment files
 * @param params ITicketManagementGetCommentFileParams
 * @return ApiDataResponseType<File>
 */
export const ticketManagementServiceGetCommentFile = (params: ITicketManagementGetCommentFileParams) =>
	Api.get<ITicketFile, ITicketManagementGetCommentFileParams>(TicketManagementEndPoints.GET_COMMENT_FILE, params);
