import { createSlice } from '@reduxjs/toolkit';

import type { IComment } from '@crac/core/models/entities/Comment';
import {
	commentAddToCustomer,
	commentGetByCustomer,
	commentRemoveFromCustomer,
} from '@crac/core/redux/actions/CommentActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { ICommentReducerState } from '@crac/core/redux-store/reducersState/booking/CommentReducerState';

const initialState: ICommentReducerState = {
	comments: [],
	commentsRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	insertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	removeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const commentsSlice = createSlice({
	name: 'CustomerCommentsSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// GET_BY_CUSTOMER
		builder
			.addCase(commentGetByCustomer.pending, (state) => {
				return {
					...state,
					comments: [],
					commentsRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commentGetByCustomer.fulfilled, (state, action) => {
				return {
					...state,
					comments: action.payload,
					commentsRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commentGetByCustomer.rejected, (state, action) => {
				return {
					...state,
					commentsRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// ADD_TO_CUSTOMER
		builder
			.addCase(commentAddToCustomer.pending, (state) => {
				return {
					...state,
					insertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commentAddToCustomer.fulfilled, (state, action) => {
				return {
					...state,
					comments: [...state.comments, action.payload as IComment],
					insertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commentAddToCustomer.rejected, (state, action) => {
				return {
					...state,
					insertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// REMOVE_FROM_CUSTOMER
		builder
			.addCase(commentRemoveFromCustomer.pending, (state) => {
				return {
					...state,
					removeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commentRemoveFromCustomer.fulfilled, (state, action) => {
				return {
					...state,
					comments: state.comments
						? state.comments.filter((comment) => comment.lineNumber !== action.payload.lineNumber)
						: [],
					removeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(commentRemoveFromCustomer.rejected, (state, action) => {
				return {
					...state,
					removeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const commentsReducer = commentsSlice.reducer;
