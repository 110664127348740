import { createSlice } from '@reduxjs/toolkit';

import { kmRateClear, kmRateDeactivate, kmRateInsert, kmRateSearch } from '@crac/core/redux/actions/KmRateActions';
import type { IKmRateReducerState } from '@crac/core/redux-store/reducersState/pricing/KmRateReducerState';
const initialState: IKmRateReducerState = {
	kmRateInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	kmRates: [],
	kmRateSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	kmRateDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const kmRateSlice = createSlice({
	name: 'KmRateSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(kmRateClear, () => {
			return initialState;
		});
		// SEARCH
		builder
			.addCase(kmRateSearch.pending, (state) => {
				return {
					...state,
					kmRateSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(kmRateSearch.fulfilled, (state, action) => {
				return {
					...state,
					kmRateSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					kmRates: action.payload,
				};
			})
			.addCase(kmRateSearch.rejected, (state, action) => {
				return {
					...state,
					kmRateSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(kmRateInsert.pending, (state) => {
				return {
					...state,
					kmRateInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(kmRateInsert.fulfilled, (state) => {
				return {
					...state,
					kmRateInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(kmRateInsert.rejected, (state, action) => {
				return {
					...state,
					kmRateInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DEACTIVATE
		builder
			.addCase(kmRateDeactivate.pending, (state) => {
				return {
					...state,
					kmRateDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(kmRateDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					kmRateDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					kmRates: [
						...state.kmRates.filter(({ id }) => !action.payload.map((item) => item.id).includes(id)),
						...action.payload,
					],
				};
			})
			.addCase(kmRateDeactivate.rejected, (state, action) => {
				return {
					...state,
					kmRateDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const kmRateReducer = kmRateSlice.reducer;
