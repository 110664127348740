import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Vehicle = lazyWithRetry(() => import('./pages/Vehicle'));

export const vehicleRoutes: { [key: number]: IRouteConfig } = {};

vehicleRoutes[routesIdMap.BookingsPickUpVehicle] = {
	component: Vehicle,
	exact: true,
	id: routesIdMap.BookingsPickUpVehicle,
	name: 'Upgrade',
	path: '/booking/pick-up/:id/upgrade',
	permissions: [PermissionsType.BookingPickUpProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: 'sign-in',
};
