import { createSlice } from '@reduxjs/toolkit';

import {
	rentRateMarginDeactivate,
	rentRateMarginInsert,
	rentRateMarginReset,
	rentRateMarginSearch,
} from '@crac/core/redux/actions/RentRateMarginActions';
import type { IRentRateMarginReducerState } from '@crac/core/redux-store/reducersState/pricing/RentRateMarginReducerState';
const initialState: IRentRateMarginReducerState = {
	rentRateMargins: [],
	rentRateMarginInsertRequest: { inProgress: false, ok: true, messages: [] },
	rentRateMarginSearchRequest: { inProgress: false, ok: true, messages: [] },
	rentRateMarginDeactivateRequest: { inProgress: false, ok: true, messages: [] },
};

const rentRateMarginSlice = createSlice({
	name: 'RentRateMarginSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// RESET
		builder.addCase(rentRateMarginReset, (state) => {
			return {
				...state,
				rentRateMargins: [],
			};
		});
		// SEARCH
		builder
			.addCase(rentRateMarginSearch.pending, (state) => {
				return {
					...state,
					rentRateMarginSearchRequest: { inProgress: true, ok: true, messages: [] },
				};
			})
			.addCase(rentRateMarginSearch.fulfilled, (state, action) => {
				return {
					...state,
					rentRateMarginSearchRequest: { inProgress: false, ok: true, messages: [] },
					rentRateMargins: action.payload,
				};
			})
			.addCase(rentRateMarginSearch.rejected, (state, action) => {
				return {
					...state,
					rentRateMarginSearchRequest: { inProgress: false, ok: false, messages: action.payload || [] },
				};
			});
		// INSERT
		builder
			.addCase(rentRateMarginInsert.pending, (state) => {
				return {
					...state,
					rentRateMarginInsertRequest: { inProgress: true, ok: true, messages: [] },
				};
			})
			.addCase(rentRateMarginInsert.fulfilled, (state) => {
				return {
					...state,
					rentRateMarginInsertRequest: { inProgress: false, ok: true, messages: [] },
				};
			})
			.addCase(rentRateMarginInsert.rejected, (state, action) => {
				return {
					...state,
					rentRateMarginInsertRequest: { inProgress: false, ok: false, messages: action.payload || [] },
				};
			});
		// DEACTIVATE
		builder
			.addCase(rentRateMarginDeactivate.pending, (state) => {
				return {
					...state,
					rentRateMarginDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(rentRateMarginDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					rentRateMarginDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					rentRateMargins: [
						...state.rentRateMargins.filter(
							({ id }) => !action.payload.map((item) => item.id).includes(id),
						),
						...action.payload,
					],
				};
			})
			.addCase(rentRateMarginDeactivate.rejected, (state, action) => {
				return {
					...state,
					rentRateMarginDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const rentRateMarginReducer = rentRateMarginSlice.reducer;
