import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Reception = lazyWithRetry(() => import('./pages/Reception'));

export const receptionRoutes: { [key: number]: IRouteConfig } = {};

receptionRoutes[routesIdMap.VehiclesReception] = {
	component: Reception,
	exact: false,
	id: routesIdMap.VehiclesReception,
	name: 'Vehicle reception',
	path: '/vehicle/reception',
	permissions: [PermissionsType.VehicleReception],
	icon: 'sign-in',
	menuText: 'Reception',
};

receptionRoutes[routesIdMap.VehiclesReceptionDetail] = {
	component: Reception,
	exact: true,
	id: routesIdMap.VehiclesReceptionDetail,
	name: 'Detail',
	path: '/vehicle/reception/detail',
	permissions: [PermissionsType.VehicleReception],
	icon: 'sign-in',
};
