import type { IVehicleGroupIncludedConfiguration } from '../../models/entities/VehicleGroupIncludedConfiguration';
import type {
	IVehicleGroupIncludedConfigurationDeactivateParams,
	IVehicleGroupIncludedConfigurationInsertParams,
	IVehicleGroupIncludedConfigurationModifyParams,
	IVehicleGroupIncludedConfigurationSearchParams,
} from '../../models/serviceParams/VehicleGroupIncludedConfigurationParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { VehicleGroupIncludedConfigurationEndPoints } from '../endPoints/VehicleGroupIncludedConfigurationEndPoints';

/**
 * Search vehicleGroupIncludedConfiguration
 */
export const vehicleGroupIncludedConfigurationSearchService = (
	model: IVehicleGroupIncludedConfigurationSearchParams,
): Promise<ServiceResponse<IVehicleGroupIncludedConfiguration[]>> => {
	return Api.get<IVehicleGroupIncludedConfiguration[], IVehicleGroupIncludedConfigurationSearchParams>(
		VehicleGroupIncludedConfigurationEndPoints.SEARCH,
		model,
	);
};

/**
 * New vehicleGroupIncludedConfiguration
 */
export const vehicleGroupIncludedConfigurationInsertService = (
	model: IVehicleGroupIncludedConfigurationInsertParams,
): Promise<ServiceResponse<IVehicleGroupIncludedConfiguration[]>> => {
	return Api.post<IVehicleGroupIncludedConfiguration[], IVehicleGroupIncludedConfigurationInsertParams>(
		VehicleGroupIncludedConfigurationEndPoints.INSERT,
		model,
	);
};

/**
 * Modify vehicleGroupIncludedConfiguration
 */
export const vehicleGroupIncludedConfigurationModifyService = (
	model: IVehicleGroupIncludedConfigurationModifyParams,
): Promise<ServiceResponse<IVehicleGroupIncludedConfiguration>> => {
	return Api.post<IVehicleGroupIncludedConfiguration, IVehicleGroupIncludedConfigurationModifyParams>(
		VehicleGroupIncludedConfigurationEndPoints.MODIFY,
		model,
	);
};

/**
 * Deactivate vehicleGroupIncludedConfiguration
 */
export const vehicleGroupIncludedConfigurationDeactivateService = (
	model: IVehicleGroupIncludedConfigurationDeactivateParams,
): Promise<ServiceResponse<IVehicleGroupIncludedConfiguration[]>> => {
	return Api.post<IVehicleGroupIncludedConfiguration[], IVehicleGroupIncludedConfigurationDeactivateParams>(
		VehicleGroupIncludedConfigurationEndPoints.DEACTIVATE,
		model,
	);
};
