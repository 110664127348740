export enum CustomerRentType {
	/**
	 * No selected any value
	 */
	Normal = 1,
	GoldClub = 2,
	Conflicted = 3,
	Guide = 4,
	Sale = 5,
}
