import { pinpadServiceGetByBranch } from '../../data/services/PinpadService';
import type { IPinpad } from '../../models/entities/Pinpad';
import type { IPinpadGetByBranchParams } from '../../models/serviceParams/PinpadParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves pinpads associated with a specific branch.
 *
 * @param {IPinpad[]} payload - The pinpads to be retrieved.
 * @param {IPinpadGetByBranchParams} params - Parameters to identify pinpads associated with a particular branch.
 * @returns {Promise<void>} - A promise that resolves to an array of pinpads for the specified branch.
 */
export const pinpadGetByBranch = createAsyncAction<IPinpad[], IPinpadGetByBranchParams>(
	'pinpad/getByBranch',
	pinpadServiceGetByBranch,
);
