import { bookingServiceModify } from '../../data/services/BookingModifyService';
import { customerServiceModify, customerServiceSave } from '../../data/services/CustomerService';
import type { IBooking } from '../../models/entities/Booking';
import type { ICustomer } from '../../models/entities/Customer';
import type { IPickUpModifyCustomerAndBooking } from '../../models/serviceParams/PickUpParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import { ServiceResponse } from '../../modules/shared/types/ServiceResponse';

interface IPickUpActionResponse {
	booking?: IBooking | null;
	customer?: ICustomer | null;
}

/**
 * Used when modifying a customer in pick up.
 * Modifies a customer and booking residence data.
 *
 * @param {ICustomer} payload - The customer with modified details.
 * @param {IPickUpModifyCustomerAndBooking} params - Parameters for modifying both the customer and associated booking.
 * @returns {Promise<void>} - A promise that resolves after the customer and booking details are modified.
 */
export const pickUpModifyCustomerAndBooking = createAsyncAction<IPickUpActionResponse, IPickUpModifyCustomerAndBooking>(
	'pickUp/modifyCustomerAndBooking',
	async (params: IPickUpModifyCustomerAndBooking) => {
		const { booking, customer } = params;
		const customerResponse = await customerServiceModify(customer);

		if (!customerResponse.ok) {
			return new ServiceResponse<IPickUpActionResponse>(false, null, customerResponse.errors);
		}

		const bookingResponse = await bookingServiceModify({
			...booking,
			bookingNumber: booking.bookingNumber,
			provisionalAddress: customer.provisionalAddress,
			provisionalAddress2: customer.provisionalAddress2,
			provisionalCity: customer.provisionalCity,
			provisionalPostCode: customer.provisionalPostCode,
			services: undefined,
		});

		if (!bookingResponse.ok) {
			return new ServiceResponse<IPickUpActionResponse>(false, null, bookingResponse.errors);
		}

		return new ServiceResponse<IPickUpActionResponse>(true, {
			booking: bookingResponse.data,
			customer: customerResponse.data,
		});
	},
);

/**
 * Used when creating a new customer in pick up.
 * Creates a customer and modifies booking residence data.
 *
 * @param {ICustomer} payload - The newly created customer.
 * @param {IPickUpCreateCustomerAndModifyBooking} params - Parameters for creating a new customer and modifying the associated booking.
 * @returns {Promise<void>} - A promise that resolves after the new customer is created and the booking details are modified.
 */
export const pickUpCreateCustomerAndModifyBooking = createAsyncAction<
	IPickUpActionResponse,
	IPickUpModifyCustomerAndBooking
>('pickUp/createCustomerAndModifyBooking', async (params: IPickUpModifyCustomerAndBooking) => {
	const { booking, customer } = params;
	const customerResponse = await customerServiceSave(customer);

	if (!customerResponse.ok) {
		return new ServiceResponse<IPickUpActionResponse>(false, null, customerResponse.errors);
	}

	const bookingResponse = await bookingServiceModify({
		...booking,
		bookingNumber: booking.bookingNumber,
		provisionalAddress: customer.provisionalAddress,
		provisionalAddress2: customer.provisionalAddress2,
		provisionalCity: customer.provisionalCity,
		provisionalPostCode: customer.provisionalPostCode,
		services: undefined,
	});

	if (!bookingResponse.ok) {
		return new ServiceResponse<IPickUpActionResponse>(false, null, bookingResponse.errors);
	}

	return new ServiceResponse<IPickUpActionResponse>(true, {
		booking: bookingResponse.data,
		customer: customerResponse.data,
	});
});
