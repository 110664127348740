import { createAction } from '@reduxjs/toolkit';

import {
	bookingLineServiceAdmonModify,
	bookingLineServiceDelete,
	bookingLineServiceGetLinesByBooking,
	bookingLineServiceInsert,
	bookingLineServiceModify,
} from '../../data/services/BookingLineService';
import type { IBookingLine } from '../../models/entities/BookingLine';
import type {
	IBookingLineAdmonModifyListParams,
	IBookingLineGetByBookingParams,
	IBookingLineInsertListParams,
	IBookingLineListModifyParams,
	IBookingLineRemoveParams,
} from '../../models/serviceParams/BookingLineParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Inserts new booking lines.
 *
 * @param {IBookingLine[]} payload - The booking lines to be inserted.
 * @param {IBookingLineInsertListParams} params - Parameters for the insertion of booking lines.
 * @returns {Promise<void>} - A promise that resolves after the booking lines are inserted.
 */
export const bookingLineInsert = createAsyncAction<IBookingLine[], IBookingLineInsertListParams>(
	'bookingLine/insert',
	bookingLineServiceInsert,
);

/**
 * Modifies existing booking lines.
 *
 * @param {IBookingLine[]} payload - The booking lines to be modified.
 * @param {IBookingLineListModifyParams} params - Parameters for the modification of booking lines.
 * @returns {Promise<void>} - A promise that resolves after the booking lines are modified.
 */
export const bookingLineModify = createAsyncAction<IBookingLine[], IBookingLineListModifyParams>(
	'bookingLine/modify',
	bookingLineServiceModify,
);

/**
 * Deletes a specific booking line.
 *
 * @param {number} payload - The ID of the booking line to be deleted.
 * @param {IBookingLineRemoveParams} params - Parameters for the deletion of the booking line.
 * @returns {Promise<void>} - A promise that resolves after the booking line is deleted.
 */
export const bookingLineDelete = createAsyncAction<number, IBookingLineRemoveParams>(
	'bookingLine/delete',
	bookingLineServiceDelete,
);

/**
 * Retrieves booking lines associated with a specific booking.
 *
 * @param {IBookingLine[]} payload - The booking lines to be retrieved.
 * @param {IBookingLineGetByBookingParams} params - Parameters to identify the booking lines associated with a booking.
 * @returns {Promise<void>} - A promise that resolves to an array of booking lines.
 */
export const bookingLineGetByBooking = createAsyncAction<IBookingLine[], IBookingLineGetByBookingParams>(
	'bookingLine/getByBooking',
	bookingLineServiceGetLinesByBooking,
);

/**
 * Modifies booking lines with administrative privileges.
 *
 * @param {IBookingLine[]} payload - The booking lines to be modified.
 * @param {IBookingLineAdmonModifyListParams} params - Administrative parameters for modifying booking lines.
 * @returns {Promise<void>} - A promise that resolves after the booking lines are modified.
 */
export const bookingLineAdmonModify = createAsyncAction<IBookingLine[], IBookingLineAdmonModifyListParams>(
	'bookingLine/admonModify',
	bookingLineServiceAdmonModify,
);

/**
 * Clears errors in booking line state.
 */
export const bookingLineClearErrors = createAction('bookingLine/clearErrors');

/**
 * Clears the booking line state.
 */
export const bookingLineClear = createAction('bookingLine/clear');
