'use client';
import type { FC } from 'react';
import React from 'react';

import { Badge as RSBadge } from 'reactstrap';
import type { BadgeProps as RSBadgeProps } from 'reactstrap/types/lib/Badge';

export type BadgeProps = RSBadgeProps;

export const Badge: FC<BadgeProps> = ({ children, ...props }) => {
	return <RSBadge {...props}> {children}</RSBadge>;
};
