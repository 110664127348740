import {
	fleetPrevisionServiceGetByPrevisionCode,
	fleetPrevisionServiceInsert,
	fleetPrevisionServiceModify,
	fleetPrevisionServiceSearch,
} from '../../data/services/FleetPrevisionService';
import type { IFleetPrevision } from '../../models/entities/FleetPrevision';
import type {
	IFleetPrevisionGetByPrevisionCodeParams,
	IFleetPrevisionInsertArrayParams,
	IFleetPrevisionModifyParams,
	IFleetPrevisionSearchParams,
} from '../../models/serviceParams/FleetPrevisionParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for fleet previsions based on specified parameters.
 *
 * @param {IFleetPrevision[]} payload - The fleet previsions to be returned.
 * @param {IFleetPrevisionSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of fleet previsions.
 */
export const fleetPrevisionSearch = createAsyncAction<IFleetPrevision[], IFleetPrevisionSearchParams>(
	'fleet/prevision/search',
	fleetPrevisionServiceSearch,
);

/**
 * Inserts new fleet previsions into the system.
 *
 * @param {IFleetPrevision[]} payload - The fleet previsions to be inserted.
 * @param {IFleetPrevisionSearchParams} params - Parameters detailing the fleet previsions to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the fleet previsions are successfully inserted.
 */
export const fleetPrevisionInsert = createAsyncAction<IFleetPrevision[], IFleetPrevisionInsertArrayParams>(
	'fleet/prevision/insert',
	fleetPrevisionServiceInsert,
);

/**
 * Modifies existing fleet previsions.
 *
 * @param {IFleetPrevision} payload - The fleet previsions to be modified.
 * @param {IFleetPrevisionModifyParams} params - Parameters detailing the fleet previsions to be modified.
 * @returns {Promise<void>} - A promise that resolves after the fleet previsions are successfully modified.
 */
export const fleetPrevisionModify = createAsyncAction<IFleetPrevision, IFleetPrevisionModifyParams>(
	'fleet/prevision/modify',
	fleetPrevisionServiceModify,
);

/**
 * Clears the search results for fleet previsions.
 */
export const fleetPrevisionClear = createSyncAction('fleet/prevision/clear');

/**
 * Searches for fleet previsions based on specified parameters.
 *
 * @param {IFleetPrevision[]} payload - The fleet previsions to be returned.
 * @param {IFleetPrevisionSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of fleet previsions.
 */
export const fleetPrevisionGetByPrevisionCode = createAsyncAction<
	IFleetPrevision,
	IFleetPrevisionGetByPrevisionCodeParams
>('fleet/prevision/getByPrevisionCode', fleetPrevisionServiceGetByPrevisionCode);

/**
 * Clears the search results for fleet previsions.
 */
export const fleetPrevisionGetByPrevisionCodeClear = createSyncAction('fleet/prevision/getByPrevisionCodeClear');
