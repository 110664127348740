// TODO: Move to @crac/helpers package

import { transliterate } from 'transliteration/dist/node/src/node';
const transliterationOptions = {
	ignore: [
		'á',
		'Á',
		'à',
		'À',
		'ä',
		'Ä',
		'å',
		'Å',
		'æ',
		'Æ',
		'é',
		'É',
		'è',
		'È',
		'ë',
		'Ë',
		'í',
		'Í',
		'ì',
		'Ì',
		'ï',
		'Ï',
		'ó',
		'Ó',
		'ò',
		'Ò',
		'ö',
		'Ö',
		'ø',
		'Ø',
		'ô',
		'Ô',
		'ú',
		'Ú',
		'ü',
		'Ü',
		'ù',
		'Ù',
		'ñ',
		'Ñ',
		'ç',
		'Ç',
	],
};

export const romanize = (text: string): string => transliterate(text, transliterationOptions);
