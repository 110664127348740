import { createSlice } from '@reduxjs/toolkit';

import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import { clearGlobalCache, toggleFavoriteRoute } from '@crac/core/redux/actions/CommonActions';

import { FAVORITE_ROUTES_KEY } from '~/constants/LocalStorageKey';

interface IFavoriteRoutesState {
	favoriteRoutes: number[];
}

const { getItem, setItem } = managerLocalStorage('localStorage');

const initialState: IFavoriteRoutesState = {
	favoriteRoutes: getItem(FAVORITE_ROUTES_KEY) ?? [],
};

const FavoriteRoutesSlice = createSlice({
	name: 'FavoriteRoutes',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(toggleFavoriteRoute, (state, action) => {
			const isFavorite = state.favoriteRoutes.includes(action.payload);

			if (isFavorite) {
				const newRoutes = state.favoriteRoutes.filter((fav) => fav !== action.payload);
				setItem(FAVORITE_ROUTES_KEY, newRoutes);
				return {
					...state,
					favoriteRoutes: newRoutes,
				};
			}
			const newRoutes = [...state.favoriteRoutes, action.payload];
			setItem(FAVORITE_ROUTES_KEY, newRoutes);
			return {
				...state,
				favoriteRoutes: newRoutes,
			};
		});
		builder.addCase(clearGlobalCache, () => {
			return {
				favoriteRoutes: [],
			};
		});
	},
});

export const feedBackReducer = FavoriteRoutesSlice.reducer;
