import {
	linkServiceAddToVehicle,
	linkServiceGetByAccidentPart,
	linkServiceGetByBudget,
	linkServiceGetByFineNumber,
	linkServiceGetByFranchiseLine,
	linkServiceGetById,
	linkServiceGetByInsurance,
	linkServiceGetByInvoice,
	linkServiceGetBySpendingLine,
	linkServiceGetByVehicle,
	linkServiceGetByVehicleLine,
	linkServiceGetFineNotification,
} from '../../data/services/LinkService';
import type { ILink } from '../../models/entities/Link';
import type {
	ILinkAddToVehicleParams,
	ILinkGetByAccidentPartParams,
	ILinkGetByBudgetParams,
	ILinkGetByFineNumberParams,
	ILinkGetByFranchiseLineParams,
	ILinkGetByIdParams,
	ILinkGetByInsuranceParams,
	ILinkGetByInvoiceParams,
	ILinkGetBySpendingLineParams,
	ILinkGetByVehicleLineParams,
	ILinkGetByVehicleParams,
	ILinkGetFineNotificationParams,
} from '../../models/serviceParams/LinkParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves a link by its ID.
 *
 * @param {ILink} payload - The link to be retrieved.
 * @param {ILinkGetByIdParams} params - Parameters to identify the link by its ID.
 * @returns {Promise<void>} - A promise that resolves to the link identified by the given ID.
 */
export const linkGetById = createAsyncAction<ILink, ILinkGetByIdParams>('link/getById', linkServiceGetById);

/**
 * Retrieves links associated with a specific accident part.
 *
 * @param {ILink[]} payload - The links to be retrieved.
 * @param {ILinkGetByAccidentPartParams} params - Parameters to identify links related to a specific accident part.
 * @returns {Promise<void>} - A promise that resolves to an array of links.
 */
export const linkGetByAccidentPart = createAsyncAction<ILink[], ILinkGetByAccidentPartParams>(
	'link/getByAccidentPart',
	linkServiceGetByAccidentPart,
);

/**
 * Retrieves links associated with a specific franchise line.
 *
 * @param {ILink[]} payload - The links to be retrieved.
 * @param {ILinkGetByFranchiseLineParams} params - Parameters to identify links related to a specific franchise line.
 * @returns {Promise<void>} - A promise that resolves to an array of links.
 */
export const linkGetByFranchiseLine = createAsyncAction<ILink[], ILinkGetByFranchiseLineParams>(
	'link/getByFranchiseLine',
	linkServiceGetByFranchiseLine,
);

/**
 * Retrieves links associated with a specific vehicle line.
 *
 * @param {ILink[]} payload - The links to be retrieved.
 * @param {ILinkGetByVehicleLineParams} params - Parameters to identify links related to a specific vehicle line.
 * @returns {Promise<void>} - A promise that resolves to an array of links.
 */
export const linkGetByVehicleLine = createAsyncAction<ILink[], ILinkGetByVehicleLineParams>(
	'link/getByVehicleLine',
	linkServiceGetByVehicleLine,
);

/**
 * Retrieves links associated with a specific spending line.
 *
 * @param {ILink[]} payload - The links to be retrieved.
 * @param {ILinkGetBySpendingLineParams} params - Parameters to identify links related to a specific spending line.
 * @returns {Promise<void>} - A promise that resolves to an array of links.
 */
export const linkGetBySpendingLine = createAsyncAction<ILink[], ILinkGetBySpendingLineParams>(
	'link/getBySpendingLine',
	linkServiceGetBySpendingLine,
);

/**
 * Retrieves links associated with a specific fine number.
 *
 * @param {ILink[]} payload - The links to be retrieved.
 * @param {ILinkGetByFineNumberParams} params - Parameters to identify links related to a specific fine number.
 * @returns {Promise<void>} - A promise that resolves to an array of links.
 */
export const linkGetByFineNumber = createAsyncAction<ILink[], ILinkGetByFineNumberParams>(
	'link/getByFineNumber',
	linkServiceGetByFineNumber,
);

/**
 * Retrieves links associated with a specific fine number.
 *
 * @param {ILink[]} payload - The links to be retrieved.
 * @param {ILinkGetFineNotificationParams} params - Parameters to identify links related to a specific fine number.
 * @returns {Promise<void>} - A promise that resolves to an array of links.
 */
export const linkGetFineNotification = createAsyncAction<ILink[], ILinkGetFineNotificationParams>(
	'link/getFineNotification',
	linkServiceGetFineNotification,
);

/**
 * Retrieves links associated with a specific vehicle.
 * * Don't provide params.type in order to get all types of documents.
 *
 * @param {ILink[]} payload - The links to be retrieved.
 * @param {ILinkGetByVehicleParams} params - Parameters to identify links related to a specific vehicle.
 * @returns {Promise<void>} - A promise that resolves to an array of links.
 */
export const linkGetByVehicle = createAsyncAction<ILink[], ILinkGetByVehicleParams>(
	'link/getByVehicle',
	linkServiceGetByVehicle,
);

/**
 * Retrieves links associated with a specific insurance.
 *
 * @param {ILink[]} payload - The links to be retrieved.
 * @param {ILinkGetByInsuranceParams} params - Parameters to identify links related to a specific insurance.
 * @returns {Promise<void>} - A promise that resolves to an array of links.
 */
export const linkGetByInsurance = createAsyncAction<ILink[], ILinkGetByInsuranceParams>(
	'link/getByInsurance',
	linkServiceGetByInsurance,
);

/**
 * Clears the link state.
 */
export const linkClear = createSyncAction('link/clear');

/**
 * Retrieves links associated with a specific invoice.
 *
 * @param {ILink[]} payload - The links to be retrieved.
 * @param {ILinkGetByBudgetParams} params - Parameters to identify links related to a specific invoice.
 * @returns {Promise<void>} - A promise that resolves to an array of links.
 */
export const linkGetByBudget = createAsyncAction<ILink, ILinkGetByBudgetParams>(
	'link/getByBudget',
	linkServiceGetByBudget,
);

/**
 * Clears the documentLinks
 */
export const linkDocumentClear = createSyncAction('link/documentClear');

/**
 * Retrieves links associated with a specific invoice.
 *
 * @param {ILink[]} payload - The links to be retrieved.
 * @param {ILinkGetByInvoiceParams} params - Parameters to identify links related to a specific invoice.
 * @returns {Promise<void>} - A promise that resolves to an array of links.
 */
export const linkGetByInvoice = createAsyncAction<ILink[], ILinkGetByInvoiceParams>(
	'link/getByInvoice',
	linkServiceGetByInvoice,
);

/**
 * Adds vehicle documentation
 * @param {ILinkAddToVehicleParams} ILinkAddToVehicleParams The documents to be inserted.
 * @returns {Promise<ServiceResponse<void>>} `Promise<ServiceResponse<void>>` - A promise that resolves when the documents has been inserted.
 */
export const linkAddToVehicle = createAsyncAction<ILink[], ILinkAddToVehicleParams>(
	'link/addToVehicle',
	linkServiceAddToVehicle,
);
