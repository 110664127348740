import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const DashBoard = lazyWithRetry(() => import('./pages/DashBoard'));

export const dashboardRoutes: { [key: number]: IRouteConfig } = {};

dashboardRoutes[routesIdMap.Dashboard] = {
	component: DashBoard,
	exact: true,
	id: routesIdMap.Dashboard,
	name: 'Home',
	path: '/',
	permissions: [PermissionsType.Access],
	icon: 'home',
};
