import { useCallback, useEffect, useState } from 'react';

import type { IEmployeeLogInParams } from '@crac/core/models/serviceParams/EmployeeParams';
import { employeeLogIn } from '@crac/core/redux/actions/EmployeeActions';

import { useEmployeeState } from '~/features/employee/state/selectors';
import { useAlertMessage } from '~/features/shared/hooks/useAlertMessage';
import { useBindAndMemoizeActions } from '~/features/shared/hooks/useBindAndMemoizeActions';

const actions = {
	employeeLogIn,
};

/**
 * Manage employee login
 * - Only manage employee login and check if the login is success or has errors
 */
export const useEmployeeLogin = () => {
	const { showAlert } = useAlertMessage();

	// STATE
	const [isLoginSubmitted, setIsLoginSubmitted] = useState(false);
	const [isLoginSuccess, setIsLoginSuccess] = useState(false);

	const { employeeLogIn } = useBindAndMemoizeActions(actions);
	const { login, employee } = useEmployeeState();

	/**
	 * handle login form
	 */
	const handleLogin = useCallback(
		(values: IEmployeeLogInParams) => {
			employeeLogIn({ password: values.password, userName: values.userName, isPublic: true });
			setIsLoginSubmitted(true);
		},
		[employeeLogIn],
	);

	// CHECK SUCCESS LOGIN
	useEffect(() => {
		const successLogin = isLoginSubmitted && !login.inProgress && login.ok && employee;
		if (!successLogin) {
			return;
		}

		setIsLoginSubmitted(false);
		setIsLoginSuccess(true);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [employee, isLoginSubmitted, login]);

	// CHECK ERROR LOGIN
	useEffect(() => {
		if (!isLoginSubmitted || login.inProgress || login.ok) {
			return;
		}

		if (login.messages.length > 0) {
			const [error] = login.messages;
			showAlert({ message: error.message });
		}

		setIsLoginSubmitted(false);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isLoginSubmitted, login.inProgress, login]);

	return { handleLogin, isLoginSuccess, isLoading: login.inProgress, employee, isLoginSubmitted };
};
