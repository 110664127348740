import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { IProvince } from '@crac/core/models/entities/Province';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	provinceGetByCountryAndPostCode,
	provinceReset,
	provinceUpdateState,
} from '@crac/core/redux/actions/ProvinceActions';
import type { IProvinceReducerState } from '@crac/core/redux-store/reducersState/province';
export const provincesStorageKey = 'provinces';
const { getItem, setItem, removeItem } = managerLocalStorage();

const initialState: IProvinceReducerState = {
	provinceGetByCountryAndPostCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	provinces: (getItem(provincesStorageKey) as IProvince[]) || [],
};

const provinceSlice = createSlice({
	name: 'ProvinceSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			removeItem(provincesStorageKey);
			return initialState;
		});
		// PROVINCE GET BY COUNTRY AND POSTCODE
		builder
			.addCase(provinceGetByCountryAndPostCode.pending, (state) => {
				return {
					...state,
					provinceGetByCountryAndPostCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					provinces: [],
				};
			})
			.addCase(provinceGetByCountryAndPostCode.fulfilled, (state, action) => {
				setItem(provincesStorageKey, action.payload);
				return {
					...state,
					provinceGetByCountryAndPostCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					provinces: deepCopy(action.payload),
				};
			})
			.addCase(provinceGetByCountryAndPostCode.rejected, (state, action) => {
				return {
					...state,
					provinceGetByCountryAndPostCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// RESET
		builder.addCase(provinceReset, (state) => {
			return {
				...state,
				provinces: [],
			};
		});
		// UPDATE STATE
		builder.addCase(provinceUpdateState, (state, action) => {
			return {
				...state,
				provinces: action.payload,
			};
		});
	},
});

export const provinceReducer = provinceSlice.reducer;
