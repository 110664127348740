import type { IPinpad } from '../../models/entities/Pinpad';
import type { IPinpadGetByBranchParams } from '../../models/serviceParams/PinpadParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { PinpadEndPoints } from '../endPoints/PinpadEndPoints';

/**
 * Get by branch
 *  {string} branchCode Branch code
 * @return {Array<Pinpad>} List Pinpad
 */
export const pinpadServiceGetByBranch = (model: IPinpadGetByBranchParams): Promise<ServiceResponse<IPinpad[]>> => {
	return Api.get<IPinpad[], IPinpadGetByBranchParams>(PinpadEndPoints.GET_BY_BRANCH, model);
};
