import { createAction } from '@reduxjs/toolkit';

import {
	digitizationServiceDigitize,
	digitizationServiceRemove,
} from '../../data/services/CustomerDigitizationService';
import type { ICustomer } from '../../models/entities/Customer';
import type { IDigitization } from '../../models/entities/Digitization';
import type {
	IDigitizationDigitizeParams,
	IDigitizationRemoveParams,
} from '../../models/serviceParams/DigitizationParams';
import type { ICustomerDigitizationResponse } from '../../models/serviceResponse/CustomerDigitizationResponse';
import type { CustomerDocumentType } from '../../models/types/CustomerDocumentType';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Digitizes customer documents and adds them to the customer's profile.
 *
 * @param {ICustomerDigitizationResponse} payload - The response after digitizing the customer documents.
 * @param {IDigitizationDigitizeParams} params - Parameters detailing the customer and the documents to be digitized.
 * @returns {Promise<void>} - A promise that resolves with the details of the digitization process.
 */
export const customerDigitizationDigitize = createAsyncAction<
	ICustomerDigitizationResponse,
	IDigitizationDigitizeParams
>('customerDigitization/digitize', digitizationServiceDigitize);

/**
 * Removes digitized documents from a customer's profile.
 *
 * @param {ICustomer} payload - The customer whose digitized documents are to be removed.
 * @param {IDigitizationRemoveParams} params - Parameters detailing the customer and the documents to be removed.
 * @returns {Promise<void>} - A promise that resolves after the documents are removed.
 */
export const customerDigitizationRemove = createAsyncAction<ICustomer, IDigitizationRemoveParams>(
	'customerDigitization/remove',
	digitizationServiceRemove,
);

/**
 * Clears the customer digitization state.
 */
export const customerDigitizationClear = createAction('customerDigitization/clear');

/**
 * Sets the document type for the customer digitization process.
 *
 * @param {CustomerDocumentType | null} payload - The document type to be set for digitization.
 */
export const customerDigitizationSetDocumentType = createAction<CustomerDocumentType | null>(
	'customerDigitization/setDocumentType',
);

/**
 * Resets the state of the digitized customer.
 */
export const customerDigitizationResetDigitizedCustomer = createAction('customerDigitization/resetDigitizedCustomer');

/**
 * Resets any errors related to customer digitization.
 */
export const customerDigitizationResetErrors = createAction('customerDigitization/resetErrors');

/**
 * Updates the state with new digitizations.
 *
 * @param {IDigitization[]} payload - The new array of digitizations to update the state.
 */
export const customerDigitizationUpdateDigitizations = createAction<IDigitization[]>(
	'customerDigitization/updateDigitizations',
);
