import {
	fineReasonServiceInsert,
	fineReasonServiceModify,
	fineReasonServiceSearch,
} from '../../data/services/FineReasonService';
import type { IFineReason } from '../../models/entities/FineReason';
import type {
	IFineReasonInsertParams,
	IFineReasonModifyParams,
	IFineReasonSearchParams,
} from '../../models/serviceParams/FineReasonParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Clears the fine reason data from the state.
 */
export const fleetFineReasonClear = createSyncAction('fleet/fineReasonClear');

/**
 * Searches for fine reasons based on search parameters.
 * @param {IFineReason[]} payload `IFineReason[]` The payload for the action.
 * @param {IFineReasonSearchParams} IFineReasonSearchParams The parameters for retrieving fine reasons.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of fine reasons.
 */
export const fleetFineReasonSearch = createAsyncAction<IFineReason[], IFineReasonSearchParams>(
	'fleet/fineReason/search',
	fineReasonServiceSearch,
);

/**
 * Inserts a new fine reason into the fleet.
 * @param {IFineReason} payload `IFineReason` The fine reason to be inserted.
 * @param {IFineReasonInsertParams} IFineReasonInsertParams The parameters for inserting a new fine reason.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the fine reason has been inserted.
 */
export const fleetFineReasonInsert = createAsyncAction<IFineReason, IFineReasonInsertParams>(
	'fleet/fineReason/insert',
	fineReasonServiceInsert,
);

/**
 * Modifies an existing fine reason in the fleet.
 * @param {IFineReason} payload `IFineReason` The fine reason to be modified.
 * @param {IFineReasonModifyParams} IFineReasonModifyParams The parameters for modifying an existing fine reason.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the fine reason has been modified.
 */
export const fleetFineReasonModify = createAsyncAction<IFineReason, IFineReasonModifyParams>(
	'fleet/fineReason/modify',
	fineReasonServiceModify,
);
