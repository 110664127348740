import { combineReducers } from '@reduxjs/toolkit';

import { brandParkingReducer as BrandParking } from './brandParkings/state/slice';
import { connectedVehiclesReducer as ConnectedVehicles } from './connectedVehicles/state/connectedVehiclesSlice';
import { documentationReducer as Documentation } from './documentation/state/slice';
import { fineReducer as Fine } from './fine/state/slice';
import { fineReasonReducer as FineReason } from './fineReason/state/slice';
import { fineTransmitterReducer as FineTransmitter } from './fineTransmitter/state/slice';
import { insuranceRateReducer as InsuranceRate } from './insuranceRate/state/slice';
import { metaModelReducer as MetaModel } from './metaModel/state/slice';
import { movementRestrictionReducer as MovementRestriction } from './movementRestriction/state/slice';
import { previousRegistrationReducer as PreviousRegistration } from './previousRegistration/state/slice';
import { previsionReducer as Prevision } from './prevision/state/slice';
import { saleMarginsReducer as SaleMargin } from './saleMargins/state/slice';
import { saleOrderReducer as SaleOrder } from './saleOrder/state/slice';
import { saleServiceReducer as SaleService } from './saleService/state/slice';
import { saleServiceRateReducer as SaleServiceRate } from './saleServiceRate/state/slice';
import { transferReducer as Transfer } from './transfer/state/slice';
import { vehicleBrandReducer as VehicleBrand } from './vehicleBrand/state/slice';
import { vehicleColorReducer as VehicleColor } from './vehicleColor/state/slice';
import { vehicleGroupReducer as VehicleGroup } from './vehicleGroup/state/slice';
import { vehicleModelReducer as VehicleModel } from './vehicleModel/state/slice';
import { vehicleModifyReducer as VehicleModify } from './vehicleModify/state/slice';

export const fleetReducer = combineReducers({
	BrandParking,
	ConnectedVehicles,
	Documentation,
	Fine,
	FineReason,
	FineTransmitter,
	InsuranceRate,
	MetaModel,
	MovementRestriction,
	PreviousRegistration,
	Prevision,
	SaleMargin,
	SaleOrder,
	SaleService,
	SaleServiceRate,
	Transfer,
	VehicleBrand,
	VehicleColor,
	VehicleGroup,
	VehicleModel,
	VehicleModify,
});
