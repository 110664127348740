import {
	fineServiceGetByBooking,
	fineServiceGetById,
	fineServiceGetByPlateNumber,
	fineServiceInsert,
	fineServiceModify,
} from '../../data/services/FineService';
import type { IFine } from '../../models/entities/Fine';
import type {
	IFineGetByBookingParams,
	IFineGetByIdParams,
	IFineGetByPlateNumberParams,
	IFineInsertParams,
	IFineModifyParams,
} from '../../models/serviceParams/FineParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Inserts a new fine.
 *
 * @param {IFine} payload - The fine to be inserted.
 * @param {IFineInsertParams} params - Parameters detailing the fine to be inserted.
 * @returns {Promise<void>} - A promise that resolves after the fine is successfully inserted.
 */
export const fineInsert = createAsyncAction<IFine, IFineInsertParams>('fine/insert', fineServiceInsert);

/**
 * Modifies an existing fine.
 *
 * @param {IFine} payload - The fine to be modified.
 * @param {IFineModifyParams} params - Parameters detailing the modifications to be made on the fine.
 * @returns {Promise<void>} - A promise that resolves after the fine is successfully modified.
 */
export const fineModify = createAsyncAction<IFine, IFineModifyParams>('fine/modify', fineServiceModify);

/**
 * Retrieves fines associated with a specific booking.
 *
 * @param {IFine[]} payload - The fines to be retrieved.
 * @param {IFineGetByBookingParams} params - Parameters to identify fines related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of fines associated with the booking.
 */
export const fineGetByBooking = createAsyncAction<IFine[], IFineGetByBookingParams>(
	'fine/getByBooking',
	fineServiceGetByBooking,
);

/**
 * Retrieves fines associated with a specific plate number.
 *
 * @param {IFine[]} payload - The fines to be retrieved.
 * @param {IFineGetByPlateNumberParams} params - Parameters to identify fines related to a specific plate number.
 * @returns {Promise<void>} - A promise that resolves to an array of fines associated with the plate number.
 */
export const fineGetByPlateNumber = createAsyncAction<IFine[], IFineGetByPlateNumberParams>(
	'fine/getByPlateNumber',
	fineServiceGetByPlateNumber,
);

/**
 * Retrieves a fine by its ID.
 *
 * @param {IFine} payload - The fine to be retrieved.
 * @param {IFineGetByIdParams} params - Parameters to identify the fine by its ID.
 * @returns {Promise<void>} - A promise that resolves to the fine identified by the given ID.
 */
export const fineGetById = createAsyncAction<IFine, IFineGetByIdParams>('fine/getById', fineServiceGetById);
