import { bookingsRoutes } from './bookings/routes';
import { commentsRoutes } from './comments/routes';
import { couponsRoutes } from './coupons/routes';
import { dataRoutes } from './data/routes';
import { overViewRoutes } from './overView/routes';
import { pendingsRoutes } from './pendings/routes';

export const detailRoutes = {
	...bookingsRoutes,
	...commentsRoutes,
	...couponsRoutes,
	...dataRoutes,
	...overViewRoutes,
	...pendingsRoutes,
};
