import type { IInvoiceableServiceConfiguration } from '../../models/entities/InvoiceableServiceConfiguration';
import type {
	IInvoiceableServiceConfigurationDeactivateParams,
	IInvoiceableServiceConfigurationInsertParams,
	IInvoiceableServiceConfigurationModifyParams,
	IInvoiceableServiceConfigurationSearchParams,
} from '../../models/serviceParams/InvoiceableServiceConfigurationParams';
import { Api } from '../../modules/shared/api';
import { InvoiceableServiceConfigurationEndPoints } from '../endPoints/InvoiceableServiceConfigurationEndPoints';

/**
 * Search invoiceable service configuration
 * @param params `IServiceInvoiceableConfigurationSearchParams`
 * @returns `Promise<ServiceResponse<IServiceInvoiceableConfiguration[]>>`
 */
export const serviceInvoiceableServiceConfigurationSearch = (params: IInvoiceableServiceConfigurationSearchParams) => {
	return Api.get<IInvoiceableServiceConfiguration[], IInvoiceableServiceConfigurationSearchParams>(
		InvoiceableServiceConfigurationEndPoints.SEARCH,
		params,
	);
};

/**
 * Insert service invoiceable configuration
 * @param params `IInvoiceableServiceConfigurationInsertParams`
 * @returns `Promise<ServiceResponse<IInvoiceableServiceConfiguration[]>>`
 */
export const serviceInvoiceableServiceConfigurationInsert = (params: IInvoiceableServiceConfigurationInsertParams) => {
	return Api.post<IInvoiceableServiceConfiguration[], IInvoiceableServiceConfigurationInsertParams>(
		InvoiceableServiceConfigurationEndPoints.INSERT,
		params,
	);
};

/**
 * Modify service invoiceable configuration
 * @param params `IInvoiceableServiceConfigurationModifyParams`
 * @returns `Promise<ServiceResponse<IInvoiceableServiceConfiguration>>`
 */
export const serviceInvoiceableServiceConfigurationModify = (params: IInvoiceableServiceConfigurationModifyParams) => {
	return Api.post<IInvoiceableServiceConfiguration, IInvoiceableServiceConfigurationModifyParams>(
		InvoiceableServiceConfigurationEndPoints.MODIFY,
		params,
	);
};

/**
 * Deactivate service invoiceable configuration
 * @param params `IInvoiceableServiceConfigurationDeactivateParams`
 * @returns `Promise<ServiceResponse<IInvoiceableServiceConfiguration[]>>`
 */
export const serviceInvoiceableServiceConfigurationDeactivate = (
	params: IInvoiceableServiceConfigurationDeactivateParams,
) => {
	return Api.post<IInvoiceableServiceConfiguration[], IInvoiceableServiceConfigurationDeactivateParams>(
		InvoiceableServiceConfigurationEndPoints.DEACTIVATE,
		params,
	);
};
