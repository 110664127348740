import { createAction } from '@reduxjs/toolkit';

import {
	availabilityConfigDeleteService,
	availabilityConfigInsertService,
	availabilityConfigModifyService,
	availabilityConfigSearchService,
} from '../../data/services/AvailabilityConfigurationService';
import type { IAvailabilityGroup } from '../../models/entities/Availability';
import type {
	IAvailabilityConfigurationDeleteParams,
	IAvailabilityConfigurationInsertParams,
	IAvailabilityConfigurationSearchParams,
} from '../../models/serviceParams/AvailabilityConfigurationParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for availabilityConfig based on the provided search parameters.
 *
 * @params {IAvailabilityGroup[]} payload - The availability config to be returned.
 * @param {IAvailabilityConfigurationSearchParams} params - The search parameters.
 * @returns {Promise<void>} - A promise that resolves to an array of availabilityConfig.
 */
export const availabilityConfigSearch = createAsyncAction<IAvailabilityGroup[], IAvailabilityConfigurationSearchParams>(
	'availabilityConfig/search',
	availabilityConfigSearchService,
);

/**
 * Modifies existing availability configurations.
 *
 * @param {IAvailabilityGroup[]} payload - The updated availability configurations.
 * @param {IAvailabilityConfigurationInsertParams} params - The parameters for modification.
 * @returns {Promise<void>} - A promise that resolves after the modification process.
 */
export const availabilityConfigModify = createAsyncAction<IAvailabilityGroup[], IAvailabilityConfigurationInsertParams>(
	'availabilityConfig/modify',
	availabilityConfigModifyService,
);

/**
 * Modifies the availability assistance configurations.
 *
 * @param {IAvailabilityGroup[]} payload - The updated availability assistance configurations.
 * @param {IAvailabilityConfigurationInsertParams} params - Parameters for the modification.
 * @returns {Promise<void>} - A promise that resolves after the modification process.
 */

export const availabilityAssistModify = createAsyncAction<IAvailabilityGroup[], IAvailabilityConfigurationInsertParams>(
	'availabilityAssist/modify',
	availabilityConfigModifyService,
);

/**
 * Deletes a specific availability configuration.
 *
 * @param {IAvailabilityGroup} payload - The availability configuration to be deleted.
 * @param {IAvailabilityConfigurationDeleteParams} params - The parameters for deletion.
 * @returns {Promise<void>} - A promise that resolves after the deletion process.
 */
export const availabilityConfigDelete = createAsyncAction<IAvailabilityGroup, IAvailabilityConfigurationDeleteParams>(
	'availabilityConfig/delete',
	availabilityConfigDeleteService,
);

/**
 * Deletes a specific availability assistance configuration.
 *
 * @param {IAvailabilityGroup} payload - The availability assistance configuration to be deleted.
 * @param {IAvailabilityConfigurationDeleteParams} params - The parameters for deletion.
 * @returns {Promise<void>} - A promise that resolves after the deletion process.
 */
export const availabilityAssistDelete = createAsyncAction<IAvailabilityGroup, IAvailabilityConfigurationDeleteParams>(
	'availabilityAssist/delete',
	availabilityConfigDeleteService,
);

/**
 * Clears the availabilityConfig state.
 */
export const availabilityConfigClear = createAction('availabilityConfig/clear');

/**
 * Searches for availability assistance configurations based on provided parameters.
 *
 * @param {IAvailabilityGroup[]} payload - The availability assistance configurations to be returned.
 * @param {IAvailabilityConfigurationSearchParams} params - The search parameters.
 * @returns {Promise<void>} - A promise that resolves to an array of availability assistance configurations.
 */
export const availabilityAssistSearch = createAsyncAction<IAvailabilityGroup[], IAvailabilityConfigurationSearchParams>(
	'availabilityAssist/search',
	availabilityConfigSearchService,
);

/**
 * Inserts new availability configurations.
 *
 * @param {IAvailabilityGroup[]} payload - The new availability configurations to be inserted.
 * @param {IAvailabilityConfigurationInsertParams} params - The parameters for insertion.
 * @returns {Promise<void>} - A promise that resolves after the insertion process.
 */
export const availabilityConfigInsert = createAsyncAction<IAvailabilityGroup[], IAvailabilityConfigurationInsertParams>(
	'availabilityConfig/insert',
	availabilityConfigInsertService,
);

/**
 * Clears the availabilityAssist state.
 */
export const availabilityAssistClear = createAction('availabilityAssist/clear');
