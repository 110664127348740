import type { ITaxOffice } from '../../models/entities/TaxOffice';
import { Api } from '../../modules/shared/api';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { TaxOfficeEndPoints } from '../endPoints/TaxOfficeEndPoints';

/**
 * Get tax offices
 * @return {Promise} `Array<TaxOfficePropType>`
 */
export const taxOfficeServiceGetAll = (model: IServiceBaseParams): Promise<ServiceResponse<ITaxOffice[]>> => {
	return Api.get<ITaxOffice[], IServiceBaseParams>(TaxOfficeEndPoints.GET_ALL, model);
};
