import {
	supplierServiceGetAll,
	supplierServiceGetByCode,
	supplierServiceInsertCarrier,
	supplierServiceInsertWorkshop,
	supplierServiceModifyWorkshop,
	supplierServiceSearch,
} from '../../data/services/SupplierService';
import type { ISupplier } from '../../models/entities/Supplier';
import type {
	ISupplierGetAllParams,
	ISupplierGetByCodeParams,
	ISupplierInsertCarrierParams,
	ISupplierInsertWorkshopParams,
	ISupplierModifyWorkshopParams,
	ISupplierSearchParams,
} from '../../models/serviceParams/SupplierParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves a supplier by its code.
 *
 * @param {ISupplier} payload - The supplier to be retrieved.
 * @param {ISupplierGetByCodeParams} params - Parameters for identifying the supplier by its code.
 * @returns {Promise<void>} - A promise that resolves to the specified supplier.
 */
export const supplierGetByCode = createAsyncAction<ISupplier, ISupplierGetByCodeParams>(
	'supplier/getByCode',
	supplierServiceGetByCode,
);

/**
 * Retrieves all suppliers.
 *
 * @param {ISupplier[]} payload - The suppliers to be retrieved.
 * @param {ISupplierGetAllParams} params - Base service parameters, potentially used for filtering or configuration.
 * @returns {Promise<void>} - A promise that resolves to an array of all suppliers.
 */
export const supplierGetAll = createAsyncAction<ISupplier[], ISupplierGetAllParams>(
	'supplier/getAll',
	supplierServiceGetAll,
);

export const supplierSearch = createAsyncAction<ISupplier[], ISupplierSearchParams>(
	'supplier/search',
	supplierServiceSearch,
);

export const supplierInsertWorkshop = createAsyncAction<ISupplier, ISupplierInsertWorkshopParams>(
	'supplier/insertWorkshop',
	supplierServiceInsertWorkshop,
);

export const supplierModifyWorkshop = createAsyncAction<ISupplier, ISupplierModifyWorkshopParams>(
	'supplier/modifyWorkshop',
	supplierServiceModifyWorkshop,
);

export const supplierInsertCarrier = createAsyncAction<ISupplier, ISupplierInsertCarrierParams>(
	'supplier/insertCarrier',
	supplierServiceInsertCarrier,
);

export const supplierClear = createSyncAction('supplier/clear');
