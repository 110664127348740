import { taxOfficeServiceGetAll } from '../../data/services/TaxOfficeService';
import type { ITaxOffice } from '../../models/entities/TaxOffice';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Retrieves all tax offices.
 *
 * @param {ITaxOffice[]} payload - The tax offices to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters, potentially used for filtering or configuration.
 * @returns {Promise<void>} - A promise that resolves to an array of all tax offices.
 */
export const taxOfficeGetAll = createAsyncAction<ITaxOffice[], IServiceBaseParams>(
	'taxOffice/getAll',
	taxOfficeServiceGetAll,
);
