import {
	revisionTypeServiceDeactivate,
	revisionTypeServiceGetAll,
	revisionTypeServiceInsert,
	revisionTypeServiceModify,
	revisionTypeServiceReactivate,
	revisionTypeServiceSearch,
} from '../../data/services/RevisionTypeService';
import type { IRevisionType } from '../../models/entities/RevisionType';
import type {
	IRevisionTypeDeactivateParams,
	IRevisionTypeGetAllParams,
	IRevisionTypeInsertParams,
	IRevisionTypeModifyParams,
	IRevisionTypeReactivateParams,
	IRevisionTypeSearchParams,
} from '../../models/serviceParams/RevisionTypeParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

export const revisionTypeSearch = createAsyncAction<IRevisionType[], IRevisionTypeSearchParams>(
	'workshop/revisionType/search',
	revisionTypeServiceSearch,
);

export const revisionTypeClear = createSyncAction('workshop/revisionType/clear');

export const revisionTypeInsert = createAsyncAction<IRevisionType, IRevisionTypeInsertParams>(
	'workshop/revisionType/insert',
	revisionTypeServiceInsert,
);

export const revisionTypeModify = createAsyncAction<IRevisionType, IRevisionTypeModifyParams>(
	'workshop/revisionType/modify',
	revisionTypeServiceModify,
);

export const revisionTypeGetAll = createAsyncAction<IRevisionType[], IRevisionTypeGetAllParams>(
	'workshop/revisionType/getAll',
	revisionTypeServiceGetAll,
);

export const revisionTypeDeactivate = createAsyncAction<IRevisionType, IRevisionTypeDeactivateParams>(
	'workshop/revisionType/deactivate',
	revisionTypeServiceDeactivate,
);

export const revisionTypeReactivate = createAsyncAction<IRevisionType, IRevisionTypeReactivateParams>(
	'workshop/revisionType/reactivate',
	revisionTypeServiceReactivate,
);
