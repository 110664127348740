import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const ChoosableServiceConfiguration = lazyWithRetry(() => import('./pages/ChoosableServiceConfiguration'));
const ChoosableServiceConfigurationNew = lazyWithRetry(() => import('./pages/ChoosableServiceConfigurationNew'));
const ChoosableServiceConfigurationModify = lazyWithRetry(() => import('./pages/ChoosableServiceConfigurationModify'));

export const choosableServiceConfigurationRoutes: { [key: number]: IRouteConfig } = {};

// CHOOSABLE SERVICES
choosableServiceConfigurationRoutes[routesIdMap.PricingChoosableServiceConfiguration] = {
	component: ChoosableServiceConfiguration,
	exact: true,
	id: routesIdMap.PricingChoosableServiceConfiguration,
	name: 'Choosable services configuration',
	menuText: 'Choosable services',
	path: '/pricing/choosable-services',
	permissions: getPricingPermission([PermissionsType.PricingChoosableServiceConfiguration]),
	childrens: [
		routesIdMap.PricingChoosableServiceConfigurationModify,
		routesIdMap.PricingChoosableServiceConfigurationNew,
	],
	icon: 'check-square-o',
};

choosableServiceConfigurationRoutes[routesIdMap.PricingChoosableServiceConfigurationNew] = {
	component: ChoosableServiceConfigurationNew,
	exact: true,
	id: routesIdMap.PricingChoosableServiceConfigurationNew,
	name: 'New choosable service',
	path: '/pricing/choosable-services/new',
	permissions: getPricingPermission([PermissionsType.PricingChoosableServiceConfiguration]),
	menuText: 'New choosable service',
	icon: 'plus',
};

choosableServiceConfigurationRoutes[routesIdMap.PricingChoosableServiceConfigurationModify] = {
	component: ChoosableServiceConfigurationModify,
	exact: true,
	id: routesIdMap.PricingChoosableServiceConfigurationModify,
	name: 'Choosable services modify',
	path: '/pricing/choosable-services/:id',
	permissions: getPricingPermission([PermissionsType.PricingChoosableServiceConfiguration]),
	icon: 'pencil',
};
