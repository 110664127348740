import { createSlice } from '@reduxjs/toolkit';

import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { employeeLoginAndLoadCachedData } from '@crac/core/redux/actions/EmployeeActions';
import {
	excessRateClear,
	excessRateDelete,
	excessRateGetAll,
	excessRateInsert,
	excessRateModify,
	excessRateSearch,
} from '@crac/core/redux/actions/ExcessRateActions';
import type { IExcessRateReducerState } from '@crac/core/redux-store/reducersState/excessRate/ExcessRateReducerState';

const excessRatesStorageKey = 'excessRatesPerProvider';
const { setItem, getItem, removeItem } = managerLocalStorage();

const initialState: IExcessRateReducerState = {
	excessRates: [],
	excessRatesPerProvider: getItem(excessRatesStorageKey) || [],
	excessRatesPerProviderRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	excessRateInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	excessRateSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	excessRateModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	excessRateDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const excessRateSlice = createSlice({
	name: 'ExcessRate',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		/* SEARCH */
		builder
			.addCase(excessRateSearch.pending, (state) => {
				return {
					...state,
					excessRateSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessRateSearch.fulfilled, (state, action) => {
				return {
					...state,
					excessRateSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					excessRates: action.payload,
				};
			})
			.addCase(excessRateSearch.rejected, (state, action) => {
				return {
					...state,
					excessRateSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/* CLEAR SEARCH */
		builder.addCase(excessRateClear, (state) => {
			return {
				...state,
				excessRates: [],
			};
		});
		/* GET ALL */
		builder
			.addCase(excessRateGetAll.pending, (state) => {
				return {
					...state,
					excessRatesPerProviderRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessRateGetAll.fulfilled, (state, action) => {
				setItem(excessRatesStorageKey, action.payload);
				return {
					...state,
					excessRatesPerProvider: action.payload,
					excessRatesPerProviderRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessRateGetAll.rejected, (state, action) => {
				return {
					...state,
					excessRatesPerProviderRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(employeeLoginAndLoadCachedData.fulfilled, (state, action) => {
				setItem(excessRatesStorageKey, action.payload.excessRates);
				return {
					...state,
					excessRatesPerProvider: action.payload.excessRates,
				};
			});
		/* INSERT */
		builder
			.addCase(excessRateInsert.pending, (state) => {
				return {
					...state,
					excessRateInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessRateInsert.fulfilled, (state, action) => {
				return {
					...state,
					excessRates: [...state.excessRates, action.payload],
					excessRateInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessRateInsert.rejected, (state, action) => {
				return {
					...state,
					excessRateInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/* MODIFY */
		builder
			.addCase(excessRateModify.pending, (state) => {
				return {
					...state,
					excessRateModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessRateModify.fulfilled, (state, action) => {
				const { code, vehicleGroup, vehicleModel } = action.payload;
				return {
					...state,
					excessRates: state.excessRates.map((excess) =>
						excess.code === code &&
						excess.vehicleGroup === vehicleGroup &&
						excess.vehicleModel === vehicleModel
							? action.payload
							: excess,
					),
					excessRateModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessRateModify.rejected, (state, action) => {
				return {
					...state,
					excessRateModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/* DELETE */
		builder
			.addCase(excessRateDelete.pending, (state) => {
				return {
					...state,
					excessRateDeleteRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessRateDelete.fulfilled, (state, action) => {
				const { code, vehicleGroup, vehicleModel } = action.payload;
				return {
					...state,
					excessRates: state.excessRates.filter(
						(excess) =>
							!(
								excess.code === code &&
								excess.vehicleGroup === vehicleGroup &&
								excess.vehicleModel === vehicleModel
							),
					),
					excessRateDeleteRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(excessRateDelete.rejected, (state, action) => {
				return {
					...state,
					excessRateDeleteRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, (state) => {
			removeItem(excessRatesStorageKey);
			return {
				...state,
				excessRatesPerProvider: [],
			};
		});
	},
});

export const excessRateReducer = excessRateSlice.reducer;
