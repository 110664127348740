import type { IGroup, IVehicleGroup, IVehicleGroupByProvider } from '../../models/entities/VehicleGroup';
import type {
	IVehicleGroupDeleteParams,
	IVehicleGroupGetByBranchParams,
	IVehicleGroupInsertParams,
	IVehicleGroupSearchParams,
} from '../../models/serviceParams/VehicleGroupParams';
import { Api } from '../../modules/shared/api';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import { VehicleGroupEndPoints } from '../endPoints/VehicleGroupEndPoints';

/**
 * Get by branch
 */
export const vehicleGroupServiceGetByBranch = (model: IVehicleGroupGetByBranchParams) =>
	Api.get<IGroup[], IVehicleGroupGetByBranchParams>(VehicleGroupEndPoints.GET_BY_BRANCH, model);

/**
 * Get by provider
 */
export const vehicleGroupServiceGetByProvider = (model: IServiceBaseParams) =>
	Api.get<IVehicleGroupByProvider[], IServiceBaseParams>(VehicleGroupEndPoints.GET_BY_PROVIDER, model);

/**
 * Search vehicle groups
 * @params IVehicleGroupSearchParams
 * @returns {Array} IVehicleGroup[]
 */
export const vehicleGroupServiceSearch = (model: IVehicleGroupSearchParams) =>
	Api.get<IVehicleGroup[], IVehicleGroupSearchParams>(VehicleGroupEndPoints.SEARCH, model);

/**
 * Insert vehicle group
 * @params IVehicleGroupInsertParams
 * @returns {Object} IVehicleGroup
 */
export const vehicleGroupServiceInsert = (model: IVehicleGroupInsertParams) =>
	Api.post<IVehicleGroup, IVehicleGroupInsertParams>(VehicleGroupEndPoints.INSERT, model);

/**
 * Modify vehicle group
 * @params IVehicleGroupInsertParams
 * @returns {Object} IVehicleGroup
 */
export const vehicleGroupServiceModify = (model: IVehicleGroupInsertParams) =>
	Api.post<IVehicleGroup, IVehicleGroupInsertParams>(VehicleGroupEndPoints.MODIFY, model);

/**
 * Delete vehicle group
 * @params IVehicleGroupDeleteParams
 * @returns {Object} IVehicleGroup
 */
export const vehicleGroupServiceDelete = (model: IVehicleGroupDeleteParams) =>
	Api.post<IVehicleGroup, IVehicleGroupDeleteParams>(VehicleGroupEndPoints.DELETE, model);
