import { createSlice } from '@reduxjs/toolkit';

import { cashLineSign } from '@crac/core/redux/actions/CashLineActions';
import type { ICashLineSignReducerState } from '@crac/core/redux-store/reducersState/cash/SignReducerState';
const initialState: ICashLineSignReducerState = {
	sign: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const signSlice = createSlice({
	name: 'CashLineSignSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder
			.addCase(cashLineSign.pending, (state) => {
				return {
					...state,
					sign: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cashLineSign.fulfilled, (state) => {
				return {
					...state,
					sign: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cashLineSign.rejected, (state, action) => {
				return {
					...state,
					sign: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const signReducer = signSlice.reducer;
