import { createAction } from '@reduxjs/toolkit';

import {
	notAllowedMovementServiceDelete,
	notAllowedMovementServiceInsert,
	notAllowedMovementServiceModify,
	notAllowedMovementServiceSearch,
} from '../../data/services/NotAllowedMovementService';
import type { INotAllowedMovement } from '../../models/entities/NotAllowedMovement';
import type {
	INotAllowedMovementDeleteParams,
	INotAllowedMovementInsertParams,
	INotAllowedMovementModifyParams,
	INotAllowedMovementSearchParams,
} from '../../models/serviceParams/NotAllowedMovementParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for not allowed movements based on specified parameters.
 *
 * @param {INotAllowedMovement[]} payload - The not allowed movements to be returned.
 * @param {INotAllowedMovementSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of not allowed movements.
 */
export const notAllowedMovementSearch = createAsyncAction<INotAllowedMovement[], INotAllowedMovementSearchParams>(
	'notAllowedMovement/search',
	notAllowedMovementServiceSearch,
);

/**
 * Inserts new not allowed movements.
 *
 * @param {INotAllowedMovement[]} payload - The not allowed movements to be inserted.
 * @param {INotAllowedMovementInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the not allowed movements are inserted.
 */
export const notAllowedMovementInsert = createAsyncAction<INotAllowedMovement[], INotAllowedMovementInsertParams>(
	'notAllowedMovement/insert',
	notAllowedMovementServiceInsert,
);

/**
 * Modifies an existing not allowed movement.
 *
 * @param {INotAllowedMovement} payload - The not allowed movement to be modified.
 * @param {INotAllowedMovementModifyParams} params - Parameters for the modification.
 * @returns {Promise<void>} - A promise that resolves after the not allowed movement is modified.
 */
export const notAllowedMovementModify = createAsyncAction<INotAllowedMovement, INotAllowedMovementModifyParams>(
	'notAllowedMovement/modify',
	notAllowedMovementServiceModify,
);

/**
 * Deletes a specific not allowed movement.
 *
 * @param {INotAllowedMovement} payload - The not allowed movement to be deleted.
 * @param {INotAllowedMovementDeleteParams} params - Parameters for the deletion.
 * @returns {Promise<void>} - A promise that resolves after the not allowed movement is deleted.
 */
export const notAllowedMovementDelete = createAsyncAction<INotAllowedMovement, INotAllowedMovementDeleteParams>(
	'notAllowedMovement/delete',
	notAllowedMovementServiceDelete,
);

/**
 * Searches for not allowed movements by zone based on specified parameters.
 *
 * @param {INotAllowedMovement[]} payload - The not allowed movements to be returned for a specific zone.
 * @param {INotAllowedMovementSearchParams} params - Parameters for the search by zone.
 * @returns {Promise<void>} - A promise that resolves to an array of not allowed movements for a specific zone.
 */
export const notAllowedMovementSearchByZone = createAsyncAction<INotAllowedMovement[], INotAllowedMovementSearchParams>(
	'notAllowedMovement/searchByZone',
	notAllowedMovementServiceSearch,
);

/**
 * Clears the not allowed movement state.
 */
export const notAllowedMovementClear = createAction('notAllowedMovement/clear');
