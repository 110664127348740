import { servicePriceServiceGet } from '../../data/services/ServicePriceService';
import type { IServicePrice } from '../../models/entities/ServicePrice';
import type { IServicePriceGetParams } from '../../models/serviceParams/ServicePriceParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves all service prices based on specified parameters.
 *
 * @param {IServicePrice[]} payload - The service prices to be retrieved.
 * @param {IServicePriceGetParams} params - Parameters for the retrieval of service prices, which can include filters or other criteria.
 * @returns {Promise<void>} - A promise that resolves to an array of service prices.
 */
export const servicePriceGetAll = createAsyncAction<IServicePrice[], IServicePriceGetParams>(
	'servicePrice/getAll',
	servicePriceServiceGet,
);
