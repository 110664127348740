import { createSlice } from '@reduxjs/toolkit';

import {
	bookingInsertCallBack,
	bookingInsertClear,
	bookingInsertClearErrors,
	bookingInsertExternal,
} from '@crac/core/redux/actions/BookingActions';
import type { IBookingInsertExternalReducerState } from '@crac/core/redux-store/reducersState/booking/InsertReducerState';

const initialState: IBookingInsertExternalReducerState = {
	booking: null,
	bookingInsertCallBackRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	bookingInsertExternalRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	transaction: null,
};

const insertSlice = createSlice({
	name: 'BookingInsertSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// BOOKING_INSERT_CLEAR
		builder.addCase(bookingInsertClear, (state) => {
			return {
				...state,
				booking: null,
				bookingInsertCallBackRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				transaction: null,
			};
		});
		// BOOKING_INSERT_CLEAR_ERRORS
		builder.addCase(bookingInsertClearErrors, (state) => {
			return {
				...state,
				bookingInsertCallBackRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				bookingInsertExternalRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});
		// BOOKING_INSERT_EXTERNAL
		builder
			.addCase(bookingInsertExternal.pending, (state) => {
				return {
					...state,
					bookingInsertExternalRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					transaction: null,
				};
			})
			.addCase(bookingInsertExternal.fulfilled, (state, action) => {
				return {
					...state,
					booking: action.payload.booking,
					bookingInsertExternalRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					transaction: action.payload.transaction,
				};
			})
			.addCase(bookingInsertExternal.rejected, (state, action) => {
				return {
					...state,
					booking: null,
					bookingInsertExternalRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
					transaction: null,
				};
			});
		// BOOKING_INSERT_CALLBACK
		builder
			.addCase(bookingInsertCallBack.pending, (state) => {
				return {
					...state,
					bookingInsertCallBackRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingInsertCallBack.fulfilled, (state, action) => {
				return {
					...state,
					booking: action.payload.booking,
					bookingInsertCallBackRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					transaction: action.payload.transaction,
				};
			})
			.addCase(bookingInsertCallBack.rejected, (state, action) => {
				return {
					...state,
					booking: null,
					bookingInsertCallBackRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
					transaction: null,
				};
			});
	},
});

export const insertReducer = insertSlice.reducer;
