import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Groups = lazyWithRetry(() => import('./pages/Groups'));

export const groupsRoutes: { [key: number]: IRouteConfig } = {};

groupsRoutes[routesIdMap.BookingsNewGroups] = {
	component: Groups,
	exact: true,
	id: routesIdMap.BookingsNewGroups,
	name: 'Booking groups',
	path: '/booking/new/groups',
	permissions: [PermissionsType.BookingNew],
	icon: 'file-o',
};
