import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const Dashboard = lazyWithRetry(() => import('./pages/Dashboard'));

export const dashboardRoutes: { [key: number]: IRouteConfig } = {};

dashboardRoutes[routesIdMap.PricingDashboard] = {
	component: Dashboard,
	exact: true,
	id: routesIdMap.PricingDashboard,
	name: 'Pricing',
	path: '/pricing',
	permissions: getPricingPermission(),
	icon: 'area-chart',
	menuText: 'Pricing',
};
