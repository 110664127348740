import type { IConnectedCallStatus, IIncomingCallStatus } from '../../models/entities/CallLogs';
import type {
	IConnectedCallStatusAddParams,
	IIncomingCallStatusAddParams,
} from '../../models/serviceParams/CallLogParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { CallLogEndPoints } from '../endPoints/CallLogEndPoint';

export const callLogServiceConnectedCallStatusAdd = (
	params: IConnectedCallStatusAddParams,
): Promise<ServiceResponse<IConnectedCallStatus>> => {
	return Api.post<IConnectedCallStatus, IConnectedCallStatusAddParams>(
		CallLogEndPoints.CONNECTED_CALL_STATUS_ADD,
		params,
	);
};
export const callLogServiceIncomingCallStatusAdd = (
	params: IIncomingCallStatusAddParams,
): Promise<ServiceResponse<IIncomingCallStatus>> => {
	return Api.post<IIncomingCallStatus, IIncomingCallStatusAddParams>(
		CallLogEndPoints.INCOMING_CALL_STATUS_ADD,
		params,
	);
};
