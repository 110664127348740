'use client';
import type { FC } from 'react';
import React from 'react';

import { Nav as RSNav } from 'reactstrap';
import type { NavProps as RSNavProps } from 'reactstrap/types/lib/Nav';

export type NavProps = RSNavProps;

export const Nav: FC<NavProps> = ({ children, ...props }): JSX.Element => {
	return <RSNav {...props}>{children}</RSNav>;
};
