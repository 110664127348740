import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Documents = lazyWithRetry(() => import('./pages/Documents'));
const DocumentsAdd = lazyWithRetry(() => import('./pages/DocumentsAdd'));

export const documentsRoutes: { [key: number]: IRouteConfig } = {};

documentsRoutes[routesIdMap.VehiclesDetailDocuments] = {
	component: Documents,
	exact: true,
	id: routesIdMap.VehiclesDetailDocuments,
	name: 'Documentation',
	path: '/vehicles/detail/:id/documentation',
	permissions: [PermissionsType.VehicleDetail],
	childrens: [routesIdMap.VehiclesDetailDocumentsAdd],
	icon: 'file-text-o',
};

documentsRoutes[routesIdMap.VehiclesDetailDocumentsAdd] = {
	component: DocumentsAdd,
	exact: true,
	id: routesIdMap.VehiclesDetailDocumentsAdd,
	name: 'Add documents',
	path: '/vehicles/detail/:id/documentation/add',
	permissions: [PermissionsType.VehicleDetail, PermissionsType.Fleet],
	isChild: true,
	icon: 'plus',
};
