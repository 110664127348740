import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const OverView = lazyWithRetry(() => import('./pages/OverView'));

export const overViewRoutes: { [key: number]: IRouteConfig } = {};

overViewRoutes[routesIdMap.CustomersDetailOverviews] = {
	component: OverView,
	exact: true,
	id: routesIdMap.CustomersDetailOverviews,
	name: 'Overviews',
	path: '/customers/detail/:id/overviews',
	permissions: [PermissionsType.CustomerDetail],
	icon: 'id-card-o',
};
