import type { IAccident } from '../../models/entities/Accident';
import type {
	IAccidentDeleteParams,
	IAccidentGetByAccidentPartParams,
	IAccidentGetByBookingParams,
	IAccidentGetByVehicleParams,
	IAccidentInsertParams,
	IAccidentModifyParams,
	IAccidentSearchParams,
} from '../../models/serviceParams/AccidentParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { AccidentEndPoints } from '../endPoints/AccidentEndPoints';

/**
 * GetByBooking
 * @param {string} bookingNumber Accident bookingNumber
 * @return {Promise<ServiceResponse<Accident[]>>} `Promise<ServiceResponse<Accident[]>>`
 */
export const accidentServiceGetByBooking = (model: IAccidentGetByBookingParams) =>
	Api.get<IAccident[], IAccidentGetByBookingParams>(AccidentEndPoints.GET_BY_BOOKING, model);

/**
 * GetByVehicle
 * @param {string} plateNumber Accident plateNumber
 * @return {Promise<ServiceResponse<Accident[]>>} `Promise<ServiceResponse<Accident[]>>`
 */

/**
 * Retrieves a list of accidents associated with a specific vehicle.
 *
 * @param model - The parameters for retrieving accidents by vehicle.
 * @returns A promise that resolves to an array of accidents.
 */
export const accidentServiceGetByVehicle = (model: IAccidentGetByVehicleParams) =>
	Api.get<IAccident[], IAccidentGetByVehicleParams>(AccidentEndPoints.GET_BY_VEHICLE, model);

/**
 * Retrieves accidents by accident part.
 *
 * @param model - The parameters for retrieving accidents by accident part.
 * @returns A promise that resolves to an array of accidents.
 */
export const accidentServiceGetByAccidentPart = (model: IAccidentGetByAccidentPartParams) =>
	Api.get<IAccident[], IAccidentGetByAccidentPartParams>(AccidentEndPoints.GET_BY_ACCIDENT_PART, model);

export const accidentServiceSearch = (model: IAccidentSearchParams): Promise<ServiceResponse<IAccident[]>> =>
	Api.get<IAccident[], IAccidentSearchParams>(AccidentEndPoints.SEARCH, model);

export const accidentServiceInsert = (model: IAccidentInsertParams) =>
	Api.post<IAccident, IAccidentInsertParams>(AccidentEndPoints.INSERT, model);

export const accidentServiceModify = (model: IAccidentModifyParams) =>
	Api.post<IAccident, IAccidentModifyParams>(AccidentEndPoints.MODIFY, model);

export const accidentServiceDelete = (model: IAccidentDeleteParams) =>
	Api.post<IAccident, IAccidentDeleteParams>(AccidentEndPoints.DELETE, model);
