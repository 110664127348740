import { createAction } from '@reduxjs/toolkit';

import {
	maximumBookingDurationServiceDeactivate,
	maximumBookingDurationServiceInsert,
	maximumBookingDurationServiceSearch,
} from '../../data/services/BookingDaysService';
import type { IMaximumBookingDuration } from '../../models/entities/MaximumBookingDuration';
import type {
	IMaximumBookingDurationDeactivateParams,
	IMaximumBookingDurationInsertParams,
	IMaximumBookingDurationSearchParams,
} from '../../models/serviceParams/MaximumBookingDurationParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for maximum booking durations based on specified parameters.
 *
 * @param {IMaximumBookingDuration[]} payload - The maximum booking durations to be returned.
 * @param {IMaximumBookingDurationSearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of maximum booking durations.
 */
export const maximumBookingDurationSearch = createAsyncAction<
	IMaximumBookingDuration[],
	IMaximumBookingDurationSearchParams
>('maximumBookingDuration/search', maximumBookingDurationServiceSearch);

/**
 * Inserts new maximum booking durations.
 *
 * @param {IMaximumBookingDuration[]} payload - The maximum booking durations to be inserted.
 * @param {IMaximumBookingDurationInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the maximum booking durations are inserted.
 */
export const maximumBookingDurationInsert = createAsyncAction<
	IMaximumBookingDuration[],
	IMaximumBookingDurationInsertParams
>('maximumBookingDuration/insert', maximumBookingDurationServiceInsert);

/**
 * Deactivates specific maximum booking durations.
 *
 * @param {IMaximumBookingDuration[]} payload - The maximum booking durations to be deactivated.
 * @param {IMaximumBookingDurationDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the maximum booking durations are deactivated.
 */
export const maximumBookingDurationDeactivate = createAsyncAction<
	IMaximumBookingDuration[],
	IMaximumBookingDurationDeactivateParams
>('maximumBookingDuration/deactivate', maximumBookingDurationServiceDeactivate);

/**
 * Clears the maximum booking duration state.
 */
export const maximumBookingDurationClear = createAction('maximumBookingDuration/clear');
