import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Availability = lazyWithRetry(() => import('./pages/Availability'));

export const availabilityRoutes: { [key: number]: IRouteConfig } = {};

availabilityRoutes[routesIdMap.BookingsNewAvailability] = {
	component: Availability,
	exact: true,
	id: routesIdMap.BookingsNewAvailability,
	name: 'Booking availability',
	path: '/booking/new/availability',
	permissions: [PermissionsType.BookingNew],
	icon: 'file-o',
};
