import { createAction } from '@reduxjs/toolkit';

import { providerServiceGetAll, providerServiceGetByBranch } from '../../data/services/ProviderService';
import type { IProvider } from '../../models/entities/Provider';
import type { IProviderGetByBranchParams } from '../../models/serviceParams/ProviderParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Retrieves all providers.
 *
 * @param {IProvider[]} payload - The providers to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters for the retrieval.
 * @returns {Promise<void>} - A promise that resolves to an array of all providers.
 */
export const providerGetAll = createAsyncAction<IProvider[], IServiceBaseParams>(
	'provider/getAll',
	providerServiceGetAll,
);

/**
 * Retrieves a provider associated with a specific branch.
 *
 * @param {IProvider} payload - The provider to be retrieved.
 * @param {IProviderGetByBranchParams} params - Parameters to identify the provider associated with a particular branch.
 * @returns {Promise<void>} - A promise that resolves to the provider for the specified branch.
 */
export const providerGetByBranch = createAsyncAction<IProvider, IProviderGetByBranchParams>(
	'provider/getByBranch',
	providerServiceGetByBranch,
);

/**
 * Sets the current provider in the state.
 *
 * @param {IProvider} payload - The provider to set as current in the state.
 */
export const providerSetCurrent = createAction<IProvider>('provider/setCurrent');
