import { createSlice } from '@reduxjs/toolkit';

import {
	bookingExtend,
	bookingExtendAvailabilityReset,
	bookingGetExtendAvailability,
} from '@crac/core/redux/actions/BookingModifyActions';
import type { IExtendReducerState } from '@crac/core/redux-store/reducersState/booking/ExtendReducerState';

const initialState: IExtendReducerState = {
	booking: null,
	bookingLines: [],
	extendAvailabilityRequest: { inProgress: false, messages: [], ok: true },
	extendConfirmRequest: { inProgress: false, messages: [], ok: true },
	transaction: null,
};

const extendSlice = createSlice({
	name: 'ExtendSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// BOOKING_EXTEND_AVAILABILITY_RESET
		builder.addCase(bookingExtendAvailabilityReset, () => initialState);
		// BOOKING_GET_EXTEND_AVAILABILITY
		builder
			.addCase(bookingGetExtendAvailability.pending, () => {
				return {
					...initialState,
				};
			})
			.addCase(bookingGetExtendAvailability.fulfilled, (state, action) => {
				return {
					...state,
					bookingLines: action.payload,
					extendAvailabilityRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingGetExtendAvailability.rejected, (state, action) => {
				return {
					...state,
					extendAvailabilityRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// BOOKING_EXTEND
		builder
			.addCase(bookingExtend.pending, (state) => {
				return {
					...state,
					booking: null,
					extendConfirmRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					transaction: null,
				};
			})
			.addCase(bookingExtend.fulfilled, (state, action) => {
				return {
					...state,
					booking: action.payload?.booking,
					extendConfirmRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					transaction: action.payload?.transaction,
				};
			})
			.addCase(bookingExtend.rejected, (state, action) => {
				return {
					...state,
					extendConfirmRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const extendReducer = extendSlice.reducer;
