export enum VendorEndPoints {
	/**
	 * Vendor search
	 * @host `/vendor/search/`
	 */
	SEARCH = '/vendor/search/',

	/**
	 * Vendor get by code
	 * @host `/vendor/getbycode/`
	 */
	GET_BY_CODE = '/vendor/getbycode/',
}
