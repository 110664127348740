import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { cashLineClear, cashLineGetPending } from '@crac/core/redux/actions/CashLineActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { ICashLinePendingReducerState } from '@crac/core/redux-store/reducersState/cash/PendingReducerState';
const initialState: ICashLinePendingReducerState = {
	cashLines: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const pendingSlice = createSlice({
	name: 'CashLinePendingSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CASH_LINE_GET_PENDING
		builder
			.addCase(cashLineGetPending.pending, (state) => {
				return {
					...state,
					cashLines: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cashLineGetPending.fulfilled, (state, action) => {
				return {
					...state,
					cashLines: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cashLineGetPending.rejected, (state, action) => {
				return {
					...state,
					cashLines: {
						data: [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR CACHE
		 * CASH LINE CLEAR
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, cashLineClear), () => {
			return initialState;
		});
	},
});

export const pendingReducer = pendingSlice.reducer;
