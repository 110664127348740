export enum VehicleGroupOrderAndUpgradeEndPoints {
	/**
	 * VehicleGroupOrderAndUpgrade search
	 * @host `/vehiclegrouporderandupgrade/search`
	 */
	SEARCH = '/vehiclegrouporderandupgrade/search',

	/**
	 * VehicleGroupOrderAndUpgrade insert
	 * @host `/vehiclegrouporderandupgrade/search`
	 */
	INSERT = '/vehiclegrouporderandupgrade/insert',

	/**
	 * VehicleGroupOrderAndUpgrade modify
	 * @host `/vehiclegrouporderandupgrade/modify`
	 */
	MODIFY = '/vehiclegrouporderandupgrade/modify',

	/**
	 * VehicleGroupOrderAndUpgrade delete
	 * @host `/vehiclegrouporderandupgrade/delete`
	 */
	DELETE = '/vehiclegrouporderandupgrade/delete',
}
