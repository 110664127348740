export enum AccidentEndPoints {
	/**
	 * @host `/accident/getbybooking/`
	 */
	GET_BY_BOOKING = '/accident/getbybooking/',

	/**
	 * @host `/accident/getbyvehicle/`
	 */
	GET_BY_VEHICLE = '/accident/getbyvehicle/',

	/**
	 * @host `/accident/getbyaccidentpart/`
	 */
	GET_BY_ACCIDENT_PART = '/accident/getbyaccidentpart/',
	/**
	 * @host `accident/search/`
	 */
	SEARCH = '/accident/search/',
	/**
	 * @host `accident/insert/`
	 */
	INSERT = '/accident/insert/',
	/**
	 * @host `accident/modify/`
	 */
	MODIFY = '/accident/modify/',
	/**
	 * @host `accident/delete/`
	 */
	DELETE = '/accident/delete/',
}
