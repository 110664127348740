import { createSlice } from '@reduxjs/toolkit';

import { serviceRateClear, serviceRateSearch, serviceRatesInsert } from '@crac/core/redux/actions/ServiceRateActions';
import type { IServiceRateReducerState } from '@crac/core/redux-store/reducersState/pricing/ServiceRateReducerState';

const initialState: IServiceRateReducerState = {
	serviceRates: [],
	serviceRatesSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	serviceRatesInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const serviceRateSlice = createSlice({
	name: 'serviceRate',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(serviceRateClear, (state) => {
			return {
				...state,
				serviceRates: [],
			};
		});
		builder.addCase(serviceRateSearch.pending, (state) => {
			return {
				...state,
				serviceRatesSearchRequest: {
					ok: true,
					inProgress: true,
					messages: [],
				},
			};
		});
		builder.addCase(serviceRateSearch.rejected, (state, action) => {
			return {
				...state,
				serviceRatesSearchRequest: {
					ok: false,
					inProgress: false,
					messages: action.payload || [],
				},
			};
		});
		builder.addCase(serviceRateSearch.fulfilled, (state, action) => {
			return {
				...state,
				serviceRatesSearchRequest: {
					ok: true,
					inProgress: false,
					messages: [],
				},
				serviceRates: action.payload,
			};
		});
		builder.addCase(serviceRatesInsert.pending, (state) => {
			return {
				...state,
				serviceRatesInsertRequest: {
					ok: true,
					inProgress: true,
					messages: [],
				},
			};
		});
		builder.addCase(serviceRatesInsert.rejected, (state, action) => {
			return {
				...state,
				serviceRatesInsertRequest: {
					ok: false,
					inProgress: false,
					messages: action.payload || [],
				},
			};
		});
		builder.addCase(serviceRatesInsert.fulfilled, (state) => {
			return {
				...state,
				serviceRatesInsertRequest: {
					ok: true,
					inProgress: false,
					messages: [],
				},
			};
		});
	},
});

export const serviceRateReducer = serviceRateSlice.reducer;
