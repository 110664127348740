import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const VehicleBrand = lazyWithRetry(() => import('~/features/fleet/vehicleBrand/pages/VehicleBrand'));
const VehicleBrandNew = lazyWithRetry(() => import('~/features/fleet/vehicleBrand/pages/VehicleBrandNew'));
const VehicleBrandModify = lazyWithRetry(() => import('~/features/fleet/vehicleBrand/pages/VehicleBrandModify'));

export const vehicleBrandRoutes: { [key: number]: IRouteConfig } = {};

vehicleBrandRoutes[routesIdMap.FleetVehicleBrand] = {
	component: VehicleBrand,
	exact: true,
	id: routesIdMap.FleetVehicleBrand,
	name: 'Vehicle brand',
	path: '/fleet/vehicle-brand',
	permissions: [PermissionsType.FleetVehicleBrand],
	icon: 'registered',
	menuText: 'Brands',
	childrens: [routesIdMap.FleetVehicleBrandNew, routesIdMap.FleetVehicleBrandModify],
};

vehicleBrandRoutes[routesIdMap.FleetVehicleBrandNew] = {
	component: VehicleBrandNew,
	exact: true,
	id: routesIdMap.FleetVehicleBrandNew,
	name: 'New vehicle brand',
	path: '/fleet/vehicle-brand/new',
	permissions: [PermissionsType.FleetVehicleBrand],
	isChild: true,
	icon: 'plus',
	menuText: 'New vehicle brand',
};

vehicleBrandRoutes[routesIdMap.FleetVehicleBrandModify] = {
	component: VehicleBrandModify,
	exact: true,
	id: routesIdMap.FleetVehicleBrandModify,
	name: 'Modify vehicle brand',
	path: '/fleet/vehicle-brand/modify/:brand',
	permissions: [PermissionsType.FleetVehicleBrand],
	isChild: true,
	icon: 'pencil',
};
