import { dashboardRoutes } from './dashboard/routes';
import { detailRoutes } from './detail/routes';
import { newRoutes } from './new/routes';
import { paymentRoutes } from './payment/routes';
import { searchRoutes } from './search/routes';

export const customerRoutes = {
	...dashboardRoutes,
	...detailRoutes,
	...newRoutes,
	...paymentRoutes,
	...searchRoutes,
};
