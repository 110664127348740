import { HttpStatusCodeType } from './HttpStatusCodeType';
import type { MessageType } from './MessageType';

/**
 * This class only can use by ApiService
 */
export class ServiceResponse<T> {
	ok: boolean;

	errors: Array<MessageType>;

	data?: T | null;

	status?: number;

	constructor(
		ok: boolean,
		data: T | null = null,
		errors: Array<MessageType> = [],
		status: HttpStatusCodeType = HttpStatusCodeType.OK,
	) {
		this.ok = ok;
		this.errors = errors;
		this.data = data;
		this.status = status;
	}
}
