import type { IBookingLine } from '../../models/entities/BookingLine';
import type {
	IBookingLineAdmonModifyListParams,
	IBookingLineGetByBookingParams,
	IBookingLineInsertListParams,
	IBookingLineListModifyParams,
	IBookingLineRemoveParams,
} from '../../models/serviceParams/BookingLineParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { BookingLineEndPoints } from '../endPoints/BookingLineEndPoints';

/**
 * Insert booking line
 *  {string} BookingLineList contract number
 * @return {Promise<BookingLineType>} `BookingLineType`
 */
export const bookingLineServiceInsert = (
	model: IBookingLineInsertListParams,
): Promise<ServiceResponse<IBookingLine[]>> => {
	return Api.post<IBookingLine[], IBookingLineInsertListParams>(BookingLineEndPoints.INSERT, model);
};

/**
 * Remove booking line
 *  {string} bookingLine contract number
 * @return {Promise<BookingLineType>} `BookingLineType`
 */
export const bookingLineServiceDelete = (model: IBookingLineRemoveParams): Promise<ServiceResponse<number>> => {
	return Api.post<number, IBookingLineRemoveParams>(BookingLineEndPoints.REMOVE, model);
};

/**
 * Modify booking line
 *  {String} bookingNumber Booking number
 *  {String} key line number
 *  {String} invoiceTo invoice to code
 *  {Boolean} invoiceToAgency
 */
export const bookingLineServiceModify = (
	model: IBookingLineListModifyParams,
): Promise<ServiceResponse<IBookingLine[]>> => {
	return Api.post<IBookingLine[], IBookingLineListModifyParams>(BookingLineEndPoints.MODIFY, model);
};

/**
 * Insert booking line
 *  {string} bookingNumber booking number
 * @return {Array<BookingLineType>} `BookingLineType`
 */
export const bookingLineServiceGetLinesByBooking = (
	model: IBookingLineGetByBookingParams,
): Promise<ServiceResponse<IBookingLine[]>> => {
	return Api.get<IBookingLine[], IBookingLineGetByBookingParams>(BookingLineEndPoints.GET_BY_BOOKING, model);
};

export const bookingLineServiceAdmonModify = (
	model: IBookingLineAdmonModifyListParams,
): Promise<ServiceResponse<IBookingLine[]>> => {
	return Api.post<IBookingLine[], IBookingLineAdmonModifyListParams>(BookingLineEndPoints.ADMON_MODIFY, model);
};
