import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const CustomerNew = lazyWithRetry(() => import('./pages/New'));

export const newRoutes: { [key: number]: IRouteConfig } = {};

newRoutes[routesIdMap.CustomersNew] = {
	component: CustomerNew,
	exact: true,
	id: routesIdMap.CustomersNew,
	name: 'New customer',
	path: '/customer/new',
	permissions: [PermissionsType.CustomerNew],
	icon: 'plus',
	menuText: 'New',
};
