export enum CompanyEndPoints {
	/**
	 * Company get by code
	 * @host `/company/getbycode/`
	 */
	GET_BY_CODE = '/company/getbycode/',

	/**
	 * Company insert
	 * @host `/company/insert/`
	 */
	INSERT = '/company/insert/',

	/**
	 * Company modify
	 * @host `/company/modify/`
	 */
	MODIFY = '/company/modify/',

	/**
	 * Company search
	 * @host `/company/search/`
	 */
	SEARCH = '/company/search/',
	/**
	 * Company validate data by validation type
	 * @host `/company/validatedata`
	 */
	VALIDATE_DATA = '/company/validatedata/',
}
