import type { IInsurance } from '../../models/entities/Insurance';
import type {
	IInsuranceGetByPlateNumberParams,
	IInsuranceImportParams,
	IInsuranceSendRegistrationRequestParams,
} from '../../models/serviceParams/InsuranceParams';
import { Api } from '../../modules/shared/api';
import { InsuranceEndPoints } from '../endPoints/InsuranceEndpoints';

/**
 * GetByPlateNumber
 * @return {Promise<ServiceResponse<Insurance[]>>} `Promise<ServiceResponse<Insurance[]>>`
 */
export const insuranceServiceGetByPlateNumber = (model: IInsuranceGetByPlateNumberParams) =>
	Api.get<IInsurance[], IInsuranceGetByPlateNumberParams>(InsuranceEndPoints.GET_BY_PLATE_NUMBER, model);

/**
 * Allows uploading insurance files
 * @param {IInsuranceImportParams} model
 * @returns {Promise<ServiceResponse<boolean>>} `Promise<ServiceResponse<boolean>>`
 */
export const insuranceServiceImport = (model: IInsuranceImportParams) =>
	Api.post<boolean, IInsuranceImportParams>(InsuranceEndPoints.IMPORT, model);

/**
 * Requests the insurance registration of an array of vehicles
 * @param {IInsuranceSendRegistrationRequestParams} model
 * @returns {Promise<ServiceResponse<boolean>>} `Promise<ServiceResponse<boolean>>`
 */
export const insuranceServiceSendRegistrationRequest = (model: IInsuranceSendRegistrationRequestParams) =>
	Api.post<boolean, IInsuranceSendRegistrationRequestParams>(InsuranceEndPoints.SEND_REGISTRATION_REQUEST, model);
