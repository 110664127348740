import React, { useEffect } from 'react';

import { sidebarPSToggle, sidebarToggle } from '../layout-helper';

export const SidebarMinimizer = (): JSX.Element => {
	useEffect(() => {
		const isMinimized = document.body.classList.contains('sidebar-minimized');
		sidebarPSToggle(!isMinimized);
	}, []);

	return <button className="sidebar-minimizer" onClick={(): void => sidebarToggle()} type="button" />;
};
