export enum InsuranceEndPoints {
	/**
	 * @host `/insurance/getByPlateNumber/`
	 */
	GET_BY_PLATE_NUMBER = '/insurance/getByPlateNumber/',

	/**
	 * @host `/insurance/import`
	 */
	IMPORT = '/insurance/import',

	/**
	 * @host `/insurance/sendRegistrationRequest`
	 */
	SEND_REGISTRATION_REQUEST = '/insurance/sendRegistrationRequest',
}
