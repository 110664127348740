import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const PackageConfiguration = lazyWithRetry(() => import('./pages/PackageConfiguration'));
const PackageConfigurationNew = lazyWithRetry(() => import('./pages/PackageConfigurationNew'));
const PackageConfigurationModify = lazyWithRetry(() => import('./pages/PackageConfigurationModify'));

export const packageConfigurationRoutes: { [key: number]: IRouteConfig } = {};

// PACKAGE CONFIGURATION
packageConfigurationRoutes[routesIdMap.PackageConfiguration] = {
	component: PackageConfiguration,
	exact: true,
	id: routesIdMap.PackageConfiguration,
	name: 'Package configurations',
	menuText: 'Packages',
	path: '/pricing/package-configuration',
	permissions: getPricingPermission([PermissionsType.PricingPackageConfiguration]),
	childrens: [routesIdMap.PackageConfigurationNew, routesIdMap.PackageConfigurationModify],
	icon: 'cubes',
};

packageConfigurationRoutes[routesIdMap.PackageConfigurationNew] = {
	component: PackageConfigurationNew,
	exact: true,
	id: routesIdMap.PackageConfigurationNew,
	name: 'New package configuration',
	path: '/pricing/package-configuration/new',
	permissions: getPricingPermission([PermissionsType.PricingPackageConfiguration]),
	isChild: true,
	icon: 'plus',
	menuText: 'New partner package configuration',
};

packageConfigurationRoutes[routesIdMap.PackageConfigurationModify] = {
	component: PackageConfigurationModify,
	exact: true,
	id: routesIdMap.PackageConfigurationModify,
	name: 'Package configuration modify',
	path: '/pricing/package-configuration/:id',
	permissions: getPricingPermission([PermissionsType.PricingPackageConfiguration]),
	isChild: true,
	icon: 'pencil',
};
