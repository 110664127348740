import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import {
	bookingCanDoDirectPickUp,
	bookingDetailClear,
	bookingDoDirectPickUp,
	bookingSignContract,
} from '@crac/core/redux/actions/BookingActions';
import {
	bookingPickUpGetPendingByBranch,
	bookingPickUpStartContract,
	bookingRegisterContract,
} from '@crac/core/redux/actions/BookingPickUpActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IPickUpReducerState } from '@crac/core/redux-store/reducersState/booking/PickUpReducerState';

const initialState: IPickUpReducerState = {
	booking: null,
	bookingCanDoDirectPickUpRequest: { inProgress: false, messages: [], ok: true },
	bookingDoDirectPickUpRequest: { inProgress: false, messages: [], ok: true },
	bookings: [],
	bookingsPickUpRequest: { inProgress: false, messages: [], ok: true },
	canDoDirectPickUp: true,
	pickUpRegisterRequest: { inProgress: false, messages: [], ok: true },
	pickUpStartRequest: { inProgress: false, messages: [], ok: true },
	signContractRequest: { inProgress: false, messages: [], ok: true },
};

const pickUpSlice = createSlice({
	name: 'BookingPickUpSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET_PENDING_BY_BRANCH
		builder
			.addCase(bookingPickUpGetPendingByBranch.pending, (state) => {
				return {
					...state,
					bookingsPickUpRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingPickUpGetPendingByBranch.fulfilled, (state, action) => {
				return {
					...state,
					bookings: deepCopy(action.payload),

					bookingsPickUpRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingPickUpGetPendingByBranch.rejected, (state, action) => {
				return {
					...state,
					bookingsPickUpRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// PICKUP_START_CONTRACT
		builder
			.addCase(bookingPickUpStartContract.pending, (state) => {
				return {
					...state,
					pickUpStartRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingPickUpStartContract.fulfilled, (state) => {
				return {
					...state,
					pickUpStartRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingPickUpStartContract.rejected, (state, action) => {
				return {
					...state,
					pickUpStartRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// REGISTER_CONTRACT
		builder
			.addCase(bookingRegisterContract.pending, (state) => {
				return {
					...state,
					pickUpRegisterRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingRegisterContract.fulfilled, (state) => {
				return {
					...state,
					pickUpRegisterRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingRegisterContract.rejected, (state, action) => {
				return {
					...state,
					pickUpRegisterRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// CAN DO DIRECT PICKUP
		builder
			.addCase(bookingCanDoDirectPickUp.pending, (state) => {
				return {
					...state,
					bookingCanDoDirectPickUpRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingCanDoDirectPickUp.fulfilled, (state, action) => {
				return {
					...state,
					bookingCanDoDirectPickUpRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					canDoDirectPickUp: action.payload,
				};
			})
			.addCase(bookingCanDoDirectPickUp.rejected, (state, action) => {
				return {
					...state,
					bookingCanDoDirectPickUpRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
					canDoDirectPickUp: false,
				};
			});
		// DO DIRECT PICKUP
		builder
			.addCase(bookingDoDirectPickUp.pending, (state) => {
				return {
					...state,
					bookingDoDirectPickUpRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingDoDirectPickUp.fulfilled, (state) => {
				return {
					...state,
					bookingDoDirectPickUpRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingDoDirectPickUp.rejected, (state, action) => {
				return {
					...state,
					bookingDoDirectPickUpRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// SIGN CONTRACT
		builder
			.addCase(bookingSignContract.pending, (state) => {
				return {
					...state,
					signContractRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingSignContract.fulfilled, (state) => {
				return {
					...state,
					signContractRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bookingSignContract.rejected, (state, action) => {
				return {
					...state,
					signContractRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR
		 * CLEAR CACHE
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, bookingDetailClear), () => {
			return initialState;
		});
	},
});

export const pickUpReducer = pickUpSlice.reducer;
