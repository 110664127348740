import type { IPreauthorizationRate } from '../../models/entities/PreauthorizationRate';
import type {
	IPreauthorizationRateDeactivateParams,
	IPreauthorizationRateInsertParams,
	IPreauthorizationRateSearchParams,
} from '../../models/serviceParams/PreauthorizationRateParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { PreauthorizationRateEndPoints } from '../endPoints/PreauthorizationRateEndPoints';

/**
 * Search preauthorization rates
 * @param model IPreauthorizationRateSearchParams
 * @returns IPreauthorizationRate[]
 */
export const preauthorizationRateServiceSearch = (
	model: IPreauthorizationRateSearchParams,
): Promise<ServiceResponse<IPreauthorizationRate[]>> => {
	return Api.get<IPreauthorizationRate[], IPreauthorizationRateSearchParams>(
		PreauthorizationRateEndPoints.SEARCH,
		model,
	);
};
/**
 * Insert preauthorization rates
 * @param model IPreauthorizationRateInsertParams
 * @returns IPreauthorizationRate[]
 */
export const preauthorizationRateServiceInsert = (
	model: IPreauthorizationRateInsertParams,
): Promise<ServiceResponse<IPreauthorizationRate[]>> => {
	return Api.post<IPreauthorizationRate[], IPreauthorizationRateInsertParams>(
		PreauthorizationRateEndPoints.INSERT,
		model,
	);
};

/**
 * Deactivate preauthorization rates
 * @param model IPreauthorizationRateDeactivateParams
 * @returns IPreauthorizationRate[]
 */
export const preauthorizationRateServiceDeactivate = (
	model: IPreauthorizationRateDeactivateParams,
): Promise<ServiceResponse<IPreauthorizationRate[]>> => {
	return Api.post<IPreauthorizationRate[], IPreauthorizationRateDeactivateParams>(
		PreauthorizationRateEndPoints.DEACTIVATE,
		model,
	);
};
