import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { vehicleLinesGetByBooking, vehicleLinesReset } from '@crac/core/redux/actions/VehicleLineActions';
import type { IVehicleLinesReducerState } from '@crac/core/redux-store/reducersState/vehicleLines';
const initialState: IVehicleLinesReducerState = {
	lastVehicleLine: { data: null, inProgress: false, messages: [], ok: true },
	vehicleLines: [],
	vehicleLinesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleLineGetExternalRepairsByVehicleRequest: { inProgress: false, messages: [], ok: true },
};

const vehicleLinesSlice = createSlice({
	name: 'VehicleLinesSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET_BY_BOOKING
		builder
			.addCase(vehicleLinesGetByBooking.pending, (state) => {
				return {
					...state,
					vehicleLinesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleLinesGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					vehicleLines: deepCopy(action.payload),
					vehicleLinesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleLinesGetByBooking.rejected, (state, action) => {
				return {
					...state,
					vehicleLinesRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR
		 * VEHICLE LINES RESET
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, vehicleLinesReset), () => {
			return initialState;
		});
	},
});

export const vehicleLinesReducer = vehicleLinesSlice.reducer;
