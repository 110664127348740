import { createAction } from '@reduxjs/toolkit';

import {
	serviceRateSearchService,
	serviceRateTypeInsertService,
	serviceRateTypeModifyService,
	serviceRateTypeSearchService,
	serviceRatesInsertService,
} from '../../data/services/ServiceRateService';
import type { IServiceRate } from '../../models/entities/ServiceRate';
import type { IServiceRateType } from '../../models/entities/ServiceRateType';
import type {
	IServiceRateTypeInsertParams,
	IServiceRateTypesSearchParams,
	IServiceRatesInsertParams,
	IServiceRatesSearchParams,
} from '../../models/serviceParams/ServiceRateParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for service rates based on specified parameters.
 *
 * @param {IServiceRate[]} payload - The service rates to be retrieved.
 * @param {IServiceRatesSearchParams} params - Parameters for the search of service rates.
 * @returns {Promise<void>} - A promise that resolves to an array of service rates.
 */
export const serviceRateSearch = createAsyncAction<IServiceRate[], IServiceRatesSearchParams>(
	'serviceRate/search',
	serviceRateSearchService,
);

/**
 * Inserts new service rates.
 *
 * @param {IServiceRate[]} payload - The service rates to be inserted.
 * @param {IServiceRatesInsertParams} params - Parameters for the insertion of service rates.
 * @returns {Promise<void>} - A promise that resolves after the service rates are inserted.
 */
export const serviceRatesInsert = createAsyncAction<IServiceRate[], IServiceRatesInsertParams>(
	'serviceRate/insert',
	serviceRatesInsertService,
);

/**
 * Clears the service rate state.
 */
export const serviceRateClear = createAction('serviceRate/clear');

/**
 * Searches for service rate types based on specified parameters.
 *
 * @param {IServiceRateType[]} payload - The service rate types to be retrieved.
 * @param {IServiceRateTypesSearchParams} params - Parameters for the search of service rate types.
 * @returns {Promise<void>} - A promise that resolves to an array of service rate types.
 */
export const serviceRateTypesSearch = createAsyncAction<IServiceRateType[], IServiceRateTypesSearchParams>(
	'serviceRate/typeSearch',
	serviceRateTypeSearchService,
);

/**
 * Inserts a new service rate type.
 *
 * @param {IServiceRateType} payload - The service rate type to be inserted.
 * @param {IServiceRateTypeInsertParams} params - Parameters for the insertion of the service rate type.
 * @returns {Promise<void>} - A promise that resolves after the service rate type is inserted.
 */
export const serviceRateTypeInsert = createAsyncAction<IServiceRateType, IServiceRateTypeInsertParams>(
	'serviceRate/typeInsert',
	serviceRateTypeInsertService,
);

/**
 * Modifies an existing service rate type.
 *
 * @param {IServiceRateType} payload - The service rate type to be modified.
 * @param {IServiceRateTypeInsertParams} params - Parameters for the modification of the service rate type.
 * @returns {Promise<void>} - A promise that resolves after the service rate type is modified.
 */
export const serviceRateTypeModify = createAsyncAction<IServiceRateType, IServiceRateTypeInsertParams>(
	'serviceRate/typeModify',
	serviceRateTypeModifyService,
);

/**
 * Clears the service rate type state.
 */
export const serviceRateTypeClear = createAction('serviceRate/typeClear');
