import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { vendorClear, vendorGetByCode, vendorSearch } from '@crac/core/redux/actions/VendorActions';
import type { IVendorReducerState } from '@crac/core/redux-store/reducersState/vendor';

const initialState: IVendorReducerState = {
	vendor: null,
	vendorRequest: { inProgress: false, messages: [], ok: true },
	vendorSearchRequest: { inProgress: false, messages: [], ok: true },
	vendors: [],
};

const vendorSlice = createSlice({
	name: 'VendorSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(vendorSearch.pending, (state) => {
				return {
					...state,
					vendorSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vendorSearch.fulfilled, (state, action) => {
				return {
					...state,
					vendorSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vendors: deepCopy(action.payload),
				};
			})
			.addCase(vendorSearch.rejected, (state, action) => {
				return {
					...state,
					vendorSearchRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// GET BY CODE
		builder
			.addCase(vendorGetByCode.pending, (state) => {
				return {
					...state,
					vendor: null,
					vendorRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vendorGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					vendor: action.payload,
					vendorRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vendorGetByCode.rejected, (state, action) => {
				return {
					...state,
					vendorRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * CLEAR
		 * CLEAR CACHE
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, vendorClear), () => initialState);
	},
});

export const vendorReducer = vendorSlice.reducer;
