import type { IBudget } from '../../models/entities/Budget';
import type { ILink } from '../../models/entities/Link';
import type {
	IBudgetChangeWorkTypeParams,
	IBudgetGetByPlateNumberParams,
	IBudgetGetByVehicleAndIdParams,
	IBudgetGetByVehicleLineParams,
	IBudgetGetLinkByIdParams,
	IBudgetImportParams,
	IBudgetRejectParams,
} from '../../models/serviceParams/BudgetParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { BudgetEndPoints } from '../endPoints/BudgetEndPoints';

/**
 * Get budgets by plateNumber
 * @param {string} plateNumber Vehicle plateNumber
 * @return {Promise<ApiDataResponseType<Budget[]>>} `Promise<ApiDataResponseType<Budget[]>>`
 */
export const budgetServiceGetByPlateNumber = (
	model: IBudgetGetByPlateNumberParams,
): Promise<ServiceResponse<IBudget[]>> => {
	return Api.get<IBudget[], IBudgetGetByPlateNumberParams>(BudgetEndPoints.GET_BY_PLATE_NUMBER, model);
};

/**
 * get budgets by plate number and budget code
 * @param string `plateNumber`
 * @param string `budgetCode`
 * @returns `Array<IBudget>`
 */
export const budgetServiceGetByVehicleAndId = (
	model: IBudgetGetByVehicleAndIdParams,
): Promise<ServiceResponse<IBudget>> => {
	return Api.get<IBudget, IBudgetGetByVehicleAndIdParams>(BudgetEndPoints.GET_BY_VEHICLE_AND_ID, model);
};

export const budgetServiceGetLinkById = (model: IBudgetGetLinkByIdParams): Promise<ServiceResponse<ILink>> => {
	return Api.get<ILink, IBudgetGetLinkByIdParams>(BudgetEndPoints.GET_BY_VEHICLE_AND_ID, model);
};

export const budgetServiceImport = (model: IBudgetImportParams): Promise<ServiceResponse<IBudget>> => {
	return Api.post<IBudget, IBudgetImportParams>(BudgetEndPoints.IMPORT, model);
};

/**
 * Get budgets by the vehicle line movement
 * @param number `vehicleLine`
 * @returns `IBudget[]`
 */
export const budgetServiceGetByVehicleLine = (
	model: IBudgetGetByVehicleLineParams,
): Promise<ServiceResponse<IBudget[]>> => {
	return Api.get<IBudget[], IBudgetGetByVehicleLineParams>(BudgetEndPoints.GET_BY_VEHICLE_LINE, model);
};

/**
 * change workType of the budget
 * @param `budgetId` number
 * @returns IBudget
 */
export const budgetServiceChangeWorkType = (model: IBudgetChangeWorkTypeParams): Promise<ServiceResponse<IBudget>> => {
	return Api.post<IBudget, IBudgetChangeWorkTypeParams>(BudgetEndPoints.CHANGE_WORK_TYPE, model);
};

/**
 * reject budget
 * @param {number} `budgetId` number
 * @returns IBudget
 */
export const budgetServiceReject = (model: IBudgetRejectParams): Promise<ServiceResponse<IBudget>> => {
	return Api.post<IBudget, IBudgetRejectParams>(BudgetEndPoints.REJECT, model);
};
