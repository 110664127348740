export enum FranchiseEndPoints {
	/**
	 * Frranchise add link
	 * @host `/franchiseline/addlink/`
	 */
	ADD_LINK = '/franchiseline/addlink/',

	/**
	 * Frranchise line get by booking
	 * @host `/franchiseline/getbybooking/`
	 */
	FRANCHISE_LINE_GET_BY_BOOKING = '/franchiseline/getbybooking/',

	/**
	 * Frranchise line get by vehicle
	 * @host `/franchiseline/getbyvehicle/`
	 */
	FRANCHISE_LINE_GET_BY_PLATENUMBER = '/franchiseline/getbyvehicle/',

	/**
	 * Frranchise line insert
	 * @host `/franchiseline/insert/`
	 */
	FRANCHISE_LINE_INSERT = '/franchiseline/insert/',

	/**
	 * Frranchise line remove
	 * @host `/franchiseline/remove/`
	 */
	FRANCHISE_LINE_REMOVE = '/franchiseline/remove/',

	/**
	 * Frranchise line repair
	 * @host `/franchiseline/repair/`
	 */
	FRANCHISE_LINE_REPAIR = '/franchiseline/repair/',

	/**
	 * Frranchise line get all
	 * @host `/franchise/getall/`
	 */
	GET_ALL = '/franchise/getall/',

	/**
	 * Frranchise line get by plate number
	 * @host `/franchise/getfranchisesbyplatenumber/`
	 */
	GET_BY_PLATE_NUMBER = '/franchise/getfranchisesbyplatenumber/',

	/**
	 * Franchise line search by vehicle to repair
	 * @host `/franchiseLine/getToRepairByVehicle/`
	 */
	SEARCH = '/franchiseLine/getToRepairByVehicle/',
}
