import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Dashboard = lazyWithRetry(() => import('./pages/Dashboard'));

export const dashboardRoutes: { [key: number]: IRouteConfig } = {};

dashboardRoutes[routesIdMap.ManagementDashboard] = {
	component: Dashboard,
	exact: true,
	id: routesIdMap.ManagementDashboard,
	name: 'Management dashboard',
	path: '/management',
	permissions: [PermissionsType.Management],
	icon: 'cogs',
	menuText: 'Management',
};
