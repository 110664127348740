import type { FC } from 'react';
import React, { useEffect } from 'react';

import { styled } from 'styled-components';

import { API_ENDPOINT, isProduction } from '@crac/core/modules/shared/api/ApiEndPoint';

import type { IBaseComponentProps } from '../../../types/IComponentBaseProps';
import { ReactGenericPortal } from '../genericPortal';

interface IDevelopToolbarProps extends IBaseComponentProps {
	portalId?: string;
	className?: string;
}

const ToolbarContainer = styled.div`
	width: 100%;
	height: 34px;
	display: flex;
	justify-content: center;
	align-items: center;
	background: red;
	color: #fff;
	position: fixed;
	top: 0;
	font-size: 12px;
	z-index: 999;
`;

export const DevelopToolbar: FC<IDevelopToolbarProps> = ({ portalId, className }) => {
	const APIENDPOINT = API_ENDPOINT.replace('https://', '').replace('/api', '');
	useEffect(() => {
		document.body.style.paddingTop = isProduction ? '0px' : '34px';
	}, []);

	if (isProduction) {
		return null;
	}

	const text = isProduction
		? `Currently API requests go to the PRODUCTION environment- ${APIENDPOINT}`
		: `Currently API requests go to the DEVELOPMENT environment - ${APIENDPOINT}`;

	const renderToolBar = (
		<ToolbarContainer className={className} data-testid="developToolbar">
			<i className={`fa fa-fw fa-${isProduction ? 'info-circle' : 'flask'}`} /> {text}
		</ToolbarContainer>
	);

	if (portalId) {
		return <ReactGenericPortal portalId={portalId}>{renderToolBar}</ReactGenericPortal>;
	}

	return renderToolBar;
};
