import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Search = lazyWithRetry(() => import('./pages/Search'));
const PickUpSearch = lazyWithRetry(() => import('./pages/PickUpSearch'));

export const searchRoutes: { [key: number]: IRouteConfig } = {};

searchRoutes[routesIdMap.Bookings] = {
	component: Search,
	exact: true,
	id: routesIdMap.Bookings,
	isRoot: true,
	name: 'Search booking',
	path: '/booking/search',
	permissions: [PermissionsType.BookingSearch],
	icon: 'search',
	menuText: 'Search',
};

searchRoutes[routesIdMap.BookingPickUpSearch] = {
	component: PickUpSearch,
	exact: true,
	id: routesIdMap.BookingPickUpSearch,
	name: 'Search pick up',
	path: '/booking/pickup-search',
	permissions: [PermissionsType.BookingNewExternal],
	icon: 'search',
	menuText: 'Search pick up',
};
