import { createSlice } from '@reduxjs/toolkit';

import {
	saleMarginDeactivate,
	saleMarginGetMargin,
	saleMarginInsert,
	saleMarginReactivate,
	saleMarginSearch,
	saleMarginsClear,
} from '@crac/core/modules/fleet/saleMargin/state/actions/SaleMarginActions';
import type { ISaleMarginStateType } from '@crac/core/modules/fleet/saleMargin/state/stateType/SaleMarginStateType';

const initialState: ISaleMarginStateType = {
	saleMargin: undefined,
	saleMargins: [],
	saleMarginDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleMarginGetMarginRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleMarginInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleMarginReactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	saleMarginSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const saleMarginsSlice = createSlice({
	name: 'SaleMarginsSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		//* SEARCH
		builder.addCase(saleMarginSearch.pending, (state) => {
			return {
				...state,
				saleMargins: [],
				saleMarginSearchRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleMarginSearch.rejected, (state, action) => {
			return {
				...state,
				saleMarginSearchRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleMarginSearch.fulfilled, (state, action) => {
			return {
				...state,
				saleMargins: action.payload,
				saleMarginSearchRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* GET MARGIN
		builder.addCase(saleMarginGetMargin.pending, (state) => {
			return {
				...state,
				saleMargin: undefined,
				saleMarginGetMarginRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleMarginGetMargin.rejected, (state, action) => {
			return {
				...state,
				saleMarginGetMarginRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleMarginGetMargin.fulfilled, (state, action) => {
			return {
				...state,
				saleMargin: action.payload,
				saleMarginGetMarginRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* INSERT
		builder.addCase(saleMarginInsert.pending, (state) => {
			return {
				...state,
				saleMarginInsertRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleMarginInsert.rejected, (state, action) => {
			return {
				...state,
				saleMarginInsertRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleMarginInsert.fulfilled, (state, action) => {
			return {
				...state,
				saleMargins: [...state.saleMargins, ...action.payload],
				saleMarginInsertRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* REACTIVATE
		builder.addCase(saleMarginReactivate.pending, (state) => {
			return {
				...state,
				saleMarginReactivateRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleMarginReactivate.rejected, (state, action) => {
			return {
				...state,
				saleMarginReactivateRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleMarginReactivate.fulfilled, (state, action) => {
			const existingMargins = state.saleMargins.filter(
				(margin) => !action.payload.some((newMargin) => newMargin.id === margin.id),
			);

			return {
				...state,
				saleMargins: [...existingMargins, ...action.payload],
				saleMarginReactivateRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* DEACTIVATE
		builder.addCase(saleMarginDeactivate.pending, (state) => {
			return {
				...state,
				saleMarginDeactivateRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(saleMarginDeactivate.rejected, (state, action) => {
			return {
				...state,
				saleMarginDeactivateRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(saleMarginDeactivate.fulfilled, (state, action) => {
			const existingMargins = state.saleMargins.filter(
				(margin) => !action.payload.some((newMargin) => newMargin.id === margin.id),
			);

			return {
				...state,
				saleMargins: [...existingMargins, ...action.payload],
				saleMarginDeactivateRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});

		//* CLEAR
		builder.addCase(saleMarginsClear, (state) => {
			return {
				...state,
				saleMargins: [],
			};
		});
	},
});

export const saleMarginsReducer = saleMarginsSlice.reducer;
