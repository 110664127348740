import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const RevisionCost = lazyWithRetry(() => import('./pages/RevisionCost'));
const RevisionCostLink = lazyWithRetry(() => import('./pages/RevisionCostLink'));

export const revisionCostRoutes: { [key: number]: IRouteConfig } = {};

revisionCostRoutes[routesIdMap.WorkshopRevisionCost] = {
	component: RevisionCost,
	exact: true,
	id: routesIdMap.WorkshopRevisionCost,
	name: 'Revision',
	menuText: 'Cost',
	path: '/workshop/revision-cost',
	icon: 'pencil-square-o',
	permissions: [PermissionsType.RevisionCost],
};

revisionCostRoutes[routesIdMap.WorkshopRevisionCostLink] = {
	component: RevisionCostLink,
	exact: true,
	id: routesIdMap.WorkshopRevisionCostLink,
	name: 'Revision Link',
	path: '/workshop/revision-cost/link/:movement/:plateNumber',
	permissions: [PermissionsType.RevisionCost],
	icon: 'pencil-square-o',
};
