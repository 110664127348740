import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { IPaymentTerm } from '@crac/core/models/entities/PaymentTerm';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { employeeLoginAndLoadCachedData } from '@crac/core/redux/actions/EmployeeActions';
import { paymentTermGetAll } from '@crac/core/redux/actions/PaymentTermActions';
import type { IPaymentTermReducerState } from '@crac/core/redux-store/reducersState/paymentTerm';
const { getItem, setItem, removeItem } = managerLocalStorage();

const PAYMENT_TERM_GET_ALL_KEY = 'paymentTerms';

const initialState: IPaymentTermReducerState = {
	paymentTerms: (getItem(PAYMENT_TERM_GET_ALL_KEY) as IPaymentTerm[]) || [],
	paymentTermGetAllRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const paymentTermSlice = createSlice({
	name: 'PaymentTermSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			removeItem(PAYMENT_TERM_GET_ALL_KEY);
			return { ...initialState, paymentTerms: [] };
		});
		// GET ALL
		builder
			.addCase(paymentTermGetAll.pending, (state) => {
				return {
					...state,
					paymentTerms: [],
					paymentTermGetAllRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentTermGetAll.fulfilled, (state, action) => {
				setItem(PAYMENT_TERM_GET_ALL_KEY, action.payload);
				return {
					...state,
					paymentTerms: deepCopy(action.payload),
					paymentTermGetAllRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(paymentTermGetAll.rejected, (state, action) => {
				return {
					...state,
					paymentTermGetAllRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(employeeLoginAndLoadCachedData.fulfilled, (state, action) => {
				setItem(PAYMENT_TERM_GET_ALL_KEY, action.payload.paymentTerms);
				return {
					...state,
					paymentTerms: deepCopy(action.payload.paymentTerms),
				};
			});
	},
});

export const paymentTermReducer = paymentTermSlice.reducer;
