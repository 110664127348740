import { API_CONTENT_ENDPOINT, API_ENDPOINT } from './ApiEndPoint';
import { getFormData, getHeaders, getQueryString, sendRequestStatusPostMessage } from './helpers';
import { InvoiceableServiceConfigurationEndPoints } from '../../../data/endPoints/InvoiceableServiceConfigurationEndPoints';
import { CreationMethod } from '../types/CreationMethod';
import { HttpMethod } from '../types/HttpMethodsType';
import type { HttpMethodType } from '../types/HttpMethodsType';
import { HttpStatusCodeType } from '../types/HttpStatusCodeType';
import type { IServiceBaseParams } from '../types/ServiceBaseParams';
import { ServiceResponse } from '../types/ServiceResponse';

/**
 * Generate request to API using data params
 * @param method HttpMethodType request method
 * @param endpoint Current api endpoint
 * @param data Data params
 * @returns ServiceResponse<T>
 */
export const makeRequest = async <T, P extends IServiceBaseParams | undefined>(
	method: HttpMethodType,
	endpoint: string,
	data?: P,
): Promise<ServiceResponse<T>> => {
	let body = null;
	let finalEndPoint = endpoint.slice();

	if ([HttpMethod.GET, HttpMethod.HEAD].includes(method)) {
		finalEndPoint = `${finalEndPoint}${getQueryString(getFormData(data))}`;
	} else {
		body = getFormData(data);
	}

	try {
		const requestConfig: RequestInit = {
			body,
			headers: getHeaders<P>(method, data),
			method,
		};

		/*
		 *  SI CREATION METHOD ES WEB/APP USAMOS CONTENT_API
		 * ! La linea 47 es temporal, para que no cambie de api la petición al pasar el creationMethod WEB/APP
		 * TODO: Ver si se le podría pasar el endpoint a utilizar a cada aplicación para no tener que meter más condiciones temporales
		 */
		let ENDPOINT = API_ENDPOINT;
		if (
			!finalEndPoint.includes(InvoiceableServiceConfigurationEndPoints.SEARCH) &&
			data &&
			data.creationMethod &&
			[CreationMethod.WEB, CreationMethod.APP].includes(data.creationMethod)
		) {
			ENDPOINT = API_CONTENT_ENDPOINT;
		}

		const response = await fetch(`${ENDPOINT}${finalEndPoint}`, requestConfig);
		sendRequestStatusPostMessage(response.status);

		if (response.status === HttpStatusCodeType.UNAUTHORIZED) {
			return new ServiceResponse<T>(
				false,
				null,
				[
					{
						message: 'Session expired, you must access again with your password',
						code: response.status.toString(),
						field: 'Exception',
					},
				],
				HttpStatusCodeType.UNAUTHORIZED,
			);
		}
		const apiResponse: ServiceResponse<T> = (await response.json()) as ServiceResponse<T>;

		return new ServiceResponse<T>(
			apiResponse.ok,
			apiResponse.data as T,
			apiResponse.errors,
			response.status as HttpStatusCodeType,
		);
	} catch (error) {
		let msg = 'Ha ocurrido un error';
		if (error instanceof Error) {
			const { message } = error;
			msg = message;
		}
		return new ServiceResponse<T>(
			false,
			null,
			[{ message: msg, code: null, field: 'ApiService Exception' }],
			HttpStatusCodeType.INTERNAL_SERVER_ERROR,
		);
	}
};
