import { createSlice } from '@reduxjs/toolkit';

import {
	discountCouponClear,
	discountCouponDeactivate,
	discountCouponInsert,
	discountCouponSearch,
} from '@crac/core/redux/actions/DiscountCouponActions';
import type { IDiscountCouponReducerState } from '@crac/core/redux-store/reducersState/pricing/DiscountCouponReducerState';

const initialState: IDiscountCouponReducerState = {
	discountCouponInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	discountCoupons: [],
	discountCouponSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	discountCouponDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const discountCouponSlice = createSlice({
	name: 'DiscountCouponSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// SEARCH
		builder
			.addCase(discountCouponSearch.pending, (state) => {
				return {
					...state,
					discountCouponSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(discountCouponSearch.fulfilled, (state, action) => {
				return {
					...state,
					discountCouponSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					discountCoupons: action.payload,
				};
			})
			.addCase(discountCouponSearch.rejected, (state, action) => {
				return {
					...state,
					discountCouponSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(discountCouponInsert.pending, (state) => {
				return {
					...state,
					discountCouponInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(discountCouponInsert.fulfilled, (state, action) => {
				return {
					...state,
					discountCouponInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					discountCoupons: state.discountCoupons
						? [...state.discountCoupons, ...action.payload]
						: action.payload,
				};
			})
			.addCase(discountCouponInsert.rejected, (state, action) => {
				return {
					...state,
					discountCouponInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(discountCouponClear, (state) => {
			return {
				...state,
				discountCoupons: [],
			};
		});
		// DEACTIVATE
		builder
			.addCase(discountCouponDeactivate.pending, (state) => {
				return {
					...state,
					discountCouponDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(discountCouponDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					discountCouponDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					discountCoupons: [
						...state.discountCoupons.filter(
							({ id }) => !action.payload.map((stopSale) => stopSale.id).includes(id),
						),
						...action.payload,
					],
				};
			})
			.addCase(discountCouponDeactivate.rejected, (state, action) => {
				return {
					...state,
					discountCouponDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const discountCouponReducer = discountCouponSlice.reducer;
