/**
 * Country static code
 *
 * Use for fallback and validation where dont has country code
 */
export const CountryCodesWithValidation = {
	ES: 'ES',
	GR: 'GR',
	IT: 'IT',
	PT: 'PT',
};

export const GENERIC_CUSTOMER_CODE = '100000';

// One hour equals that in days
export const ONE_HOUR_EQUALS_DAY = 0.041666666666667;
