export enum CashLineEndPoints {
	/**
	 * Cash line get current
	 * @host `/cashline/getcurrent/`
	 */
	GET_CURRENT = '/cashline/getcurrent/',

	/**
	 * Cash line get by day
	 * @host `/cashline/getbyday/`
	 */
	GET_BY_DAY = '/cashline/getbyday/',

	/**
	 * Cash line get pending
	 * @host `/cashline/getpending/`
	 */
	GET_PENDING = '/cashline/getpending/',

	/**
	 * Cash line get by code
	 * @host `/cashline/getbycode/`
	 */
	GET_BY_CODE = '/cashline/getbycode/',

	/**
	 * Cash line modify
	 * @host `/cashline/modify/`
	 */
	MODIFY = '/cashline/modify/',

	/**
	 * Cash line sign
	 * @host `/cashline/sign/`
	 */
	SIGN = '/cashline/sign/',
}
