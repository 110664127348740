import { createAction } from '@reduxjs/toolkit';

import type { ILoaderParams } from '../../modules/shared/types/LoaderParams';

/**
 * Clears the global cache.
 */
export const clearGlobalCache = createAction('common/clearCache');

/**
 * Action creator for userUnauthorized.
 * This action is dispatched when the user is unauthorized.
 */
export const userUnauthorized = createAction('common/unauthorized');

/**
 * Sets the locale value.
 *
 * @param locale - The locale value to set.
 */
export const setLocale = createAction<string>('common/setLocale');

/**
 * Action creator for activating the loader.
 */
export const activeLoader = createAction('common/activeLoader', (payload?: ILoaderParams) => ({ payload }));

/**
 * Disables the loader.
 */
export const disableLoader = createAction('common/disableLoader');

/**
 * Toggle favorite route
 */
export const toggleFavoriteRoute = createAction<number>('commons/toggleFavoriteRoute');
