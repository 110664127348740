export enum ProvinceEndPoints {
	/**
	 * Get provinces by country code
	 * @host `/province/getbycountrycode/`
	 */
	GET_BY_COUNTRY = '/province/getbycountrycode/',

	/**
	 * Get provinces by post code and country
	 * @host `/province/getbypostcodeandcountry/`
	 */
	GET_BY_COUNTRY_AND_POSTCODE = '/province/getbypostcodeandcountry/',
	/**
	 * Get province by code
	 * @host `/province/getbycode/`
	 */
	GET_BY_CODE = '/province/getbycode/',
}
