import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const RentRateType = lazyWithRetry(() => import('./pages/RentRateType'));
const RentRateTypeNew = lazyWithRetry(() => import('./pages/RentRateTypeNew'));
const RentRateTypeEdit = lazyWithRetry(() => import('./pages/RentRateTypeEdit'));

export const rentRateTypeRoutes: { [key: number]: IRouteConfig } = {};

// RENT RATE TYPES
rentRateTypeRoutes[routesIdMap.RentRateTypes] = {
	component: RentRateType,
	exact: true,
	id: routesIdMap.RentRateTypes,
	menuText: 'Types',
	name: 'Rent rate types',
	path: '/pricing/rent-rate-types',
	permissions: getPricingPermission([PermissionsType.PricingRentRateType]),
	childrens: [routesIdMap.RentRateTypeNew, routesIdMap.RentRateTypeEdit],
	icon: 'tags',
};

rentRateTypeRoutes[routesIdMap.RentRateTypeNew] = {
	component: RentRateTypeNew,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateTypeNew,
	name: 'New rent rate type',
	path: '/pricing/rent-rate-types/new',
	permissions: getPricingPermission([PermissionsType.PricingRentRateType]),
	menuText: 'New rent rate type',
	icon: 'plus',
};

rentRateTypeRoutes[routesIdMap.RentRateTypeEdit] = {
	component: RentRateTypeEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateTypeEdit,
	name: 'Edit rent rate types',
	path: '/pricing/rent-rate-types/modify/:id',
	permissions: getPricingPermission([PermissionsType.PricingRentRateType]),
	icon: 'pencil',
};
