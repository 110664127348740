import { vehicleServiceSearch } from './VehicleService';
import type {
	IFleetPreviousRegistrationDeleteParams,
	IFleetPreviousRegistrationImportParams,
	IFleetPreviousRegistrationModifyParams,
	IFleetPreviousRegistrationRegisterParams,
} from '../../models/serviceParams/FleetPreviousRegistrationParams';
import type { IVehicleSearchParams } from '../../models/serviceParams/VehicleParams';
import { Api } from '../../modules/shared/api';
import type { IVehicle } from '../../modules/vehicle/entities/Vehicle';
import { PreviousRegistrationEndPoints } from '../endPoints/PreviousRegistrationEndPoints';

export const fleetPreviousRegistrationServiceSearch = (model: IVehicleSearchParams) => {
	return vehicleServiceSearch(model);
};

export const fleetPreviousRegistrationServiceImport = (model: IFleetPreviousRegistrationImportParams) => {
	return Api.post<IVehicle[], IFleetPreviousRegistrationImportParams>(PreviousRegistrationEndPoints.IMPORT, model);
};

export const fleetPreviousRegistrationServiceModify = (model: IFleetPreviousRegistrationModifyParams) => {
	return Api.post<IVehicle, IFleetPreviousRegistrationModifyParams>(PreviousRegistrationEndPoints.MODIFY, model);
};

export const fleetPreviousRegistrationServiceRegister = (model: IFleetPreviousRegistrationRegisterParams) => {
	return Api.post<IVehicle[], IFleetPreviousRegistrationRegisterParams>(
		PreviousRegistrationEndPoints.REGISTER,
		model,
	);
};

export const fleetPreviousRegistrationServiceDelete = (model: IFleetPreviousRegistrationDeleteParams) => {
	return Api.post<IVehicle[], IFleetPreviousRegistrationDeleteParams>(PreviousRegistrationEndPoints.DELETE, model);
};
