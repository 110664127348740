export enum VehicleGroupEndPoints {
	/**
	 * VehicleGroup Get by branch
	 * @host `/vehiclegroup/getbybranch/`
	 */
	GET_BY_BRANCH = '/vehiclegroup/getbybranch/',

	/**
	 * VehicleGroup Get by provider
	 * @host `/vehiclegroup/getbyprovider/`
	 */
	GET_BY_PROVIDER = '/vehiclegroup/getbyprovider/',
	/**
	 * VehicleGroup search
	 * @host `/vehiclegroup/search/`
	 */
	SEARCH = '/vehiclegroup/search/',
	/**
	 * VehicleGroup insert
	 * @host `/vehiclegroup/insert/`
	 */
	INSERT = '/vehiclegroup/insert/',
	/**
	 * VehicleGroup modify
	 * @host `/vehiclegroup/modify/`
	 */
	MODIFY = '/vehiclegroup/modify/',
	/**
	 * VehicleGroup delete
	 * @host `/vehiclegroup/delete/`
	 */
	DELETE = '/vehiclegroup/delete/',
}
