import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Damages = lazyWithRetry(() => import('./pages/Damages'));

export const damagesRoutes: { [key: number]: IRouteConfig } = {};

damagesRoutes[routesIdMap.VehiclesDetailDamages] = {
	component: Damages,
	exact: true,
	id: routesIdMap.VehiclesDetailDamages,
	name: 'Damages',
	path: '/vehicles/detail/:id/damages',
	permissions: [PermissionsType.VehicleDetail],
	icon: 'bolt',
};

damagesRoutes[routesIdMap.VehiclesDetailDamagesDetail] = {
	component: Damages,
	exact: true,
	id: routesIdMap.VehiclesDetailDamagesDetail,
	name: 'Damage detail',
	path: '/vehicles/detail/:id/damages/:line/:document?',
	permissions: [PermissionsType.VehicleDetail],
	icon: 'bolt',
};
