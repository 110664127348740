import type { IFranchise, IFranchisePerProvider } from '../../models/entities/Franchise';
import type { IFranchiseGetByPlateNumberParams } from '../../models/serviceParams/FranchiseParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { FranchiseEndPoints } from '../endPoints/FranchiseEndPoints';

export const franchiseServiceGetByPlateNumber = (
	model: IFranchiseGetByPlateNumberParams,
): Promise<ServiceResponse<IFranchise[]>> => {
	return Api.get<IFranchise[], IFranchiseGetByPlateNumberParams>(FranchiseEndPoints.GET_BY_PLATE_NUMBER, model);
};

export const franchiseServiceGetAll = (): Promise<ServiceResponse<IFranchisePerProvider[]>> => {
	return Api.get<IFranchisePerProvider[], undefined>(FranchiseEndPoints.GET_ALL);
};
