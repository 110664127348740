/* eslint-disable react/jsx-fragments */
/* eslint-disable react/jsx-no-useless-fragment */
import type { FC } from 'react';
import * as React from 'react';

import classNames from 'classnames';

interface IFormFielLabelProps {
	label?: (value?: string | undefined) => string | JSX.Element | string;
	htmlFor?: string | undefined;
	className?: string;
}

export const FormFieldLabel: FC<IFormFielLabelProps> = ({ label, className, htmlFor }) => {
	if (!label) {
		return null;
	}

	if (label.constructor.name === 'Function') {
		const labelValue = label();
		return (
			<label className={classNames(className)} htmlFor={htmlFor}>
				{labelValue}
			</label>
		);
	}

	if (typeof label === 'string') {
		return (
			<label className={classNames('mb-1', className)} htmlFor={htmlFor}>
				<React.Fragment>{label}</React.Fragment>
			</label>
		);
	}

	return null;
};
