import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const OneWay = lazyWithRetry(() => import('./pages/OneWay'));
const OneWayNew = lazyWithRetry(() => import('./pages/OneWayNew'));
const OneWayEdit = lazyWithRetry(() => import('./pages/OneWayEdit'));

export const oneWayRoutes: { [key: number]: IRouteConfig } = {};

oneWayRoutes[routesIdMap.OneWay] = {
	component: OneWay,
	exact: true,
	id: routesIdMap.OneWay,
	name: 'One way',
	path: '/pricing/one-way',
	permissions: getPricingPermission([PermissionsType.PricingOneWay]),
	childrens: [routesIdMap.OneWayNew, routesIdMap.OneWayEdit],
	icon: 'arrow-right',
	menuText: 'One way',
};

oneWayRoutes[routesIdMap.OneWayNew] = {
	component: OneWayNew,
	exact: true,
	isChild: true,
	id: routesIdMap.OneWayNew,
	name: 'New one way',
	path: '/pricing/one-way/new',
	permissions: getPricingPermission([PermissionsType.PricingOneWay]),
	icon: 'plus',
	menuText: 'New one way',
};

oneWayRoutes[routesIdMap.OneWayEdit] = {
	component: OneWayEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.OneWayEdit,
	name: 'Modify one way',
	path: '/pricing/one-way/modify',
	permissions: getPricingPermission([PermissionsType.PricingOneWay]),
	icon: 'pencil',
};
