import { createSlice } from '@reduxjs/toolkit';

import { zoneGetAll } from '@crac/core/redux/actions/ZoneActions';
import type { IZoneReducerState } from '@crac/core/redux-store/reducersState/zone';

const initialState: IZoneReducerState = {
	zones: [],
	zoneRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const zoneSlice = createSlice({
	name: 'ZoneSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET ALL
		builder
			.addCase(zoneGetAll.pending, (state) => {
				return {
					...state,
					zoneRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(zoneGetAll.fulfilled, (state, action) => {
				return {
					...state,
					zoneRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					zones: action.payload,
				};
			})
			.addCase(zoneGetAll.rejected, (state, action) => {
				return {
					...state,
					zoneRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const zoneReducer = zoneSlice.reducer;
