export enum FineEndPoints {
	/**
	 * Fine search
	 * @host `/fine/search/`
	 */
	SEARCH = '/fine/search/',

	/**
	 * Fine insert
	 * @host `/fine/insert/`
	 */
	INSERT = '/fine/insert/',

	/**
	 * Fine modify
	 * @host `/fine/modify/`
	 */
	MODIFY = '/fine/modify/',

	/**
	 * Fine get by contract
	 * @host `/fine/getbybooking/`
	 */
	GET_BY_BOOKING = '/fine/getbybooking/',

	/**
	 * Fine get by platenumber
	 * @host `/fine/getbyplatenumber/`
	 */
	GET_BY_PLATENUMBER = '/fine/getbyplatenumber/',

	/**
	 * Fine get by id
	 * @host `/fine/v/`
	 */
	GET_BY_ID = '/fine/getbyid/',
}
