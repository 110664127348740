import type { IIncludedServiceConfiguration } from '../../models/entities/IncludedServiceConfiguration';
import type {
	IIncludedServiceConfigurationDeactivateParams,
	IIncludedServiceConfigurationInsertParams,
	IIncludedServiceConfigurationModifyParams,
	IIncludedServiceConfigurationSearchParams,
} from '../../models/serviceParams/IncludedServiceConfigurationParams';
import { Api } from '../../modules/shared/api';
import { IncludedServiceConfigurationEndPoints } from '../endPoints/IncludedServiceConfigurationEndPoints';

/**
 * Search included service configuration
 * @param params `IIncludedServiceConfigurationSearchParams`
 * @returns `Promise<ServiceResponse<IIncludedServiceConfiguration[]>>`
 */
export const serviceIncludedServiceConfigurationSearch = (params: IIncludedServiceConfigurationSearchParams) => {
	return Api.get<IIncludedServiceConfiguration[], IIncludedServiceConfigurationSearchParams>(
		IncludedServiceConfigurationEndPoints.SEARCH,
		params,
	);
};

/**
 * Insert included service configuration
 * @param params `IIncludedServiceConfigurationInsertParams`
 * @returns `Promise<ServiceResponse<IIncludedServiceConfiguration[]>>`
 */
export const serviceIncludedServiceConfigurationInsert = (params: IIncludedServiceConfigurationInsertParams) => {
	return Api.post<IIncludedServiceConfiguration[], IIncludedServiceConfigurationInsertParams>(
		IncludedServiceConfigurationEndPoints.INSERT,
		params,
	);
};

/**
 * Modify included service configuration
 * @param params `IIncludedServiceConfigurationModifyParams`
 * @returns `Promise<ServiceResponse<IIncludedServiceConfiguration>>`
 */
export const serviceIncludedServiceConfigurationModify = (params: IIncludedServiceConfigurationModifyParams) => {
	return Api.post<IIncludedServiceConfiguration, IIncludedServiceConfigurationModifyParams>(
		IncludedServiceConfigurationEndPoints.MODIFY,
		params,
	);
};

/**
 * Deactivate included service configuration
 * @param params `IIncludedServiceConfigurationDeactivateParams`
 * @returns `Promise<ServiceResponse<IIncludedServiceConfiguration>>`
 */
export const serviceIncludedServiceConfigurationDeactivate = (
	params: IIncludedServiceConfigurationDeactivateParams,
) => {
	return Api.post<IIncludedServiceConfiguration[], IIncludedServiceConfigurationDeactivateParams>(
		IncludedServiceConfigurationEndPoints.DEACTIVATE,
		params,
	);
};
