import { SaleMarginEndPoints } from './SaleMarginEndpoints';
import { Api } from '../../../../modules/shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleMargin } from '../entities/SaleMargin';

export interface ISaleMarginNew extends Partial<ISaleMargin> {
	company: string;
	margin: number;
	kmFrom: number;
	kmTo: number;
	quoteFrom: string;
}

export interface ISaleMarginInsertParams extends IServiceBaseParams {
	saleMargins: ISaleMarginNew[];
}

export const saleMarginInsertService = (model: ISaleMarginInsertParams) => {
	return Api.post<ISaleMargin[], ISaleMarginInsertParams>(SaleMarginEndPoints.INSERT, model);
};
