import { createSyncAction } from '../../../../shared/state/createAction';
import { createAsyncAction } from '../../../../shared/state/createAsyncAction';
import { ServiceResponse } from '../../../../shared/types/ServiceResponse';
import type { IMetaModel } from '../../entities/MetaModel';
import type { IMetaModelDeleteParams } from '../../services/MetaModelDeleteService';
import { metaModelDeleteService } from '../../services/MetaModelDeleteService';
import { type IMetaModelInsertParams, metaModelInsertService } from '../../services/MetaModelInsertService';
import { type IMetaModelModifyParams, metaModelModifyService } from '../../services/MetaModelModifyService';
import type { IMetaModelSaveImageParams } from '../../services/MetaModelSaveImageService';
import { metaModelSaveImageService } from '../../services/MetaModelSaveImageService';
import { type IMetaModelSearchParams, metaModelSearchService } from '../../services/MetaModelSearchService';

//* SEARCH
export const metaModelSearch = createAsyncAction<IMetaModel[], IMetaModelSearchParams>(
	'metaModel/search',
	metaModelSearchService,
);

interface IMetaModelInsertAndSaveImageParams extends IMetaModelInsertParams {
	image?: string;
}
//* INSERT
export const metaModelInsert = createAsyncAction<IMetaModel, IMetaModelInsertAndSaveImageParams>(
	'metaModel/insert',
	async (params: IMetaModelInsertAndSaveImageParams) => {
		const { brand, name, image } = params;

		const insertResponse = await metaModelInsertService({ brand, name });

		if (!insertResponse.ok || !insertResponse.data) {
			return new ServiceResponse<IMetaModel>(false, null, insertResponse.errors);
		}

		if (image) {
			const imageResponse = await metaModelSaveImageService({ id: insertResponse.data.id, image });

			if (!imageResponse.ok || !imageResponse.data) {
				return new ServiceResponse<IMetaModel>(false, null, imageResponse.errors);
			}

			return new ServiceResponse<IMetaModel>(true, imageResponse.data);
		}

		return new ServiceResponse<IMetaModel>(true, insertResponse.data);
	},
);

interface IMetaModelModifyAndSaveImageParams extends IMetaModelModifyParams {
	image?: string;
	modifyOnlyImage?: boolean;
}
//* MODIFY
export const metaModelModify = createAsyncAction<IMetaModel, IMetaModelModifyAndSaveImageParams>(
	'metaModel/modify',
	async (params: IMetaModelModifyAndSaveImageParams) => {
		const { brand, name, id, image, modifyOnlyImage } = params;

		// Only modifies image
		if (modifyOnlyImage && image) {
			const imageResponse = await metaModelSaveImageService({ id, image });

			if (!imageResponse.ok || !imageResponse.data) {
				return new ServiceResponse<IMetaModel>(false, null, imageResponse.errors);
			}

			return new ServiceResponse<IMetaModel>(true, imageResponse.data);
		}

		// Modifies metaModel
		const modifyResponse = await metaModelModifyService({ id, brand, name });

		if (!modifyResponse.ok || !modifyResponse.data) {
			return new ServiceResponse<IMetaModel>(false, null, modifyResponse.errors);
		}

		// Modifies image
		if (image) {
			const imageResponse = await metaModelSaveImageService({ id, image });

			if (!imageResponse.ok || !imageResponse.data) {
				return new ServiceResponse<IMetaModel>(false, null, imageResponse.errors);
			}

			return new ServiceResponse<IMetaModel>(true, imageResponse.data);
		}

		return new ServiceResponse<IMetaModel>(true, modifyResponse.data);
	},
);

//* SAVE IMAGE
export const metaModelSaveImage = createAsyncAction<IMetaModel, IMetaModelSaveImageParams>(
	'metaModel/saveImage',
	metaModelSaveImageService,
);

//* DELETE
export const metaModelDelete = createAsyncAction<IMetaModel, IMetaModelDeleteParams>(
	'metaModel/delete',
	metaModelDeleteService,
);

//* CLEAR
export const metaModelsClear = createSyncAction('metaModels/clear');
