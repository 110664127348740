export enum BranchEndPoints {
	/**
	 * Get all branches
	 * @host `/branch/getall/`
	 */
	GET_ALL = '/branch/getall/',

	/**
	 * Get all branch by provider
	 * @host `/branch/getbyprovider/`
	 */
	GET_BY_PROVIDER = '/branch/getbyprovider/',

	/**
	 * Get all branch by allowed by user
	 * @host `/branch/getallowedbyuser/`
	 */
	GET_ALLOWED_BY_USER = '/branch/getallowedbyuser/',

	/**
	 * Get currwent branch by user
	 * @host `/branch/getcurrentbyuser/`
	 */
	GET_CURRENT_BY_USER = '/branch/getcurrentbyuser/',

	/**
	 * Get branch by code
	 * @host `/branch/getbycode/`
	 */
	GET_BY_CODE = '/branch/getbycode/',

	/**
	 * Get branch by partner
	 * @host `/branch/getbypartnercodeandtype/`
	 */
	GET_BY_PARTNER_CODE_AND_TYPE = '/branch/getbypartnercodeandtype/',
	/**
	 *
	 */
	GET_BY_COUNTRY_AND_POSTCODE = '/branch/getbycountryandpostcode/',
	/**
	 * Get branches with availability
	 */
	GET_NEARBY_BRANCHES_WITH_AVAILABILITY = '/branch/getNearbyBranchesWithAvailability',
}
