import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const InsuranceRateSearch = lazyWithRetry(() => import('./pages/InsuranceRateSearch'));
const InsuranceRateNew = lazyWithRetry(() => import('./pages/InsuranceRateNew'));
const InsuranceRateModify = lazyWithRetry(() => import('./pages/InsuranceRateModify'));

export const insuranceRateRoutes: { [key: number]: IRouteConfig } = {};

insuranceRateRoutes[routesIdMap.FleetInsuranceRate] = {
	component: InsuranceRateSearch,
	exact: true,
	id: routesIdMap.FleetInsuranceRate,
	name: 'Insurance rate',
	path: '/fleet/insurance-rate',
	permissions: [PermissionsType.InsuranceRate],
	childrens: [routesIdMap.FleetInsuranceRateNew, routesIdMap.FleetInsuranceRateModify],
	icon: 'shield',
	menuText: 'Insurance rate',
};

insuranceRateRoutes[routesIdMap.FleetInsuranceRateNew] = {
	component: InsuranceRateNew,
	exact: true,
	id: routesIdMap.FleetInsuranceRateNew,
	name: 'New insurance rate',
	path: '/fleet/insurance-rate/new',
	permissions: [PermissionsType.InsuranceRate],
	isChild: true,
	icon: 'plus',
	menuText: 'New insurance rate',
};

insuranceRateRoutes[routesIdMap.FleetInsuranceRateModify] = {
	component: InsuranceRateModify,
	exact: true,
	id: routesIdMap.FleetInsuranceRateModify,
	name: 'Modify insurance rate',
	path: '/fleet/insurance-rate/modify/:id',
	permissions: [PermissionsType.InsuranceRate],
	icon: 'pencil',
};
