export enum PaymentEndPoints {
	/**
	 * Payment line get by booking
	 * @host `/paymentline/getbybooking/`
	 */
	PAYMENT_LINE_GET_BY_BOOKING = '/paymentline/getbybooking/',

	/**
	 * Payment line get by customer
	 * @host `/paymentline/getbycustomer/`
	 */
	PAYMENT_LINE_GET_BY_CUSTOMER = '/paymentline/getbycustomer/',

	/**
	 * Payment line insert
	 * @host `/paymentline/insert/`
	 */
	PAYMENT_LINE_ADD = '/paymentline/insert/',

	/**
	 * Payment line remove
	 * @host `paymentline/remove/`
	 */
	PAYMENT_LINE_REMOVE = '/paymentline/remove/',

	/**
	 * Payment line search
	 * @host `/paymentline/search/`
	 */
	PAYMENT_LINE_SEARCH = '/paymentline/search/',

	/**
	 * Update charge back data
	 * @host `/paymentline/updateChargeBack/`
	 */
	UPDATE_CHARGE_BACK = '/paymentline/updateChargeBack/',
	/**
	 * Update charge back data
	 * @host `/paymentline/deleteChargeBack/`
	 */
	DELETE_CHARGE_BACK = '/paymentline/deleteChargeBack/',
}
