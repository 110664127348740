import type { IInsuranceRate } from '../../models/entities/InsuranceRate';
import type {
	IInsuranceRateDeactivateParams,
	IInsuranceRateInsertParams,
	IInsuranceRateModifyParams,
	IInsuranceRateSearchParams,
} from '../../models/serviceParams/InsuranceRateParams';
import { Api } from '../../modules/shared/api';
import { InsuranceRateEndPoints } from '../endPoints/InsuranceRateEndPoints';

export const insuranceRateServiceSearch = (model: IInsuranceRateSearchParams) => {
	return Api.get<IInsuranceRate[], IInsuranceRateSearchParams>(InsuranceRateEndPoints.SEARCH, model);
};

export const insuranceRateServiceInsert = (model: IInsuranceRateInsertParams) => {
	return Api.post<IInsuranceRate, IInsuranceRateInsertParams>(InsuranceRateEndPoints.INSERT, model);
};

export const insuranceRateServiceModify = (model: IInsuranceRateModifyParams) => {
	return Api.post<IInsuranceRate, IInsuranceRateModifyParams>(InsuranceRateEndPoints.MODIFY, model);
};

export const insuranceRateServiceDeactivate = (model: IInsuranceRateDeactivateParams) => {
	return Api.post<IInsuranceRate, IInsuranceRateDeactivateParams>(InsuranceRateEndPoints.DEACTIVATE, model);
};
