export enum VehicleGroupAvailabilityEndPoints {
	/**
	 * VehicelGroupAvailability Get Availability
	 * @host `/vehiclegroupavailability/getavailability/`
	 */
	GET_AVAILABILITY = '/vehiclegroupavailability/getavailability/',

	/**
	 * VehicelGroupAvailability get price
	 * @host `/vehicelgroupavailability/getprice/`
	 */
	GET_PRICE = '/vehiclegroupavailability/getprice/',

	/**
	 * AvailabilityConfiguration search
	 * @host `/availabilityconfiguration/search/`
	 */
	SEARCH = '/availabilityconfiguration/search',

	/**
	 * AvailabilityConfiguration new
	 * @host `/availabilityconfiguration/insert`
	 */
	INSERT = '/availabilityconfiguration/insert',

	/**
	 * AvailabilityConfiguration modify
	 * @host `/availabilityconfiguration/modify`
	 */
	MODIFY = '/availabilityconfiguration/modify',

	/**
	 * AvailabilityConfiguration delete
	 * @host `/availabilityconfiguration/delete
	 */
	DELETE = '/availabilityconfiguration/delete',
}
