import type { FC } from 'react';
import React from 'react';

import { DropdownToggle as RSDropdownToggle } from 'reactstrap';
import type { DropdownToggleProps as RSDropdownToggleProps } from 'reactstrap/types/lib/DropdownToggle';

export type DropdownToggleProps = RSDropdownToggleProps;

export const DropdownToggle: FC<DropdownToggleProps> = ({ children, ...props }) => {
	return <RSDropdownToggle {...props}>{children}</RSDropdownToggle>;
};
