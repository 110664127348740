import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { managerLocalStorage } from '@crac/core/helpers/reducerStorage';
import type { ITaxOffice } from '@crac/core/models/entities/TaxOffice';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { employeeLoginAndLoadCachedData } from '@crac/core/redux/actions/EmployeeActions';
import { taxOfficeGetAll } from '@crac/core/redux/actions/TaxOfficeActions';
import type { ITaxOfficeReducerState } from '@crac/core/redux-store/reducersState/taxOffice';
export const taxOfficesStorageKey = 'taxOffices';
const { getItem, setItem, removeItem } = managerLocalStorage();

const initialState: ITaxOfficeReducerState = {
	taxOffices: (getItem(taxOfficesStorageKey) as ITaxOffice[]) || [],
	taxOfficesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const taxOfficeSlice = createSlice({
	name: 'TaxOfficeSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, (state) => {
			removeItem(taxOfficesStorageKey);
			return { ...state, taxOffices: [] };
		});

		// GET ALL
		builder
			.addCase(taxOfficeGetAll.pending, (state) => {
				return {
					...state,
					taxOfficesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(taxOfficeGetAll.rejected, (state, action) => {
				return {
					...state,
					taxOfficesRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			})
			.addCase(taxOfficeGetAll.fulfilled, (state, action) => {
				setItem(taxOfficesStorageKey, action.payload);

				return {
					...state,
					taxOffices: deepCopy(action.payload),
					taxOfficesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(employeeLoginAndLoadCachedData.fulfilled, (state, action) => {
				setItem(taxOfficesStorageKey, action.payload.taxOffices);

				return {
					...state,
					taxOffices: deepCopy(action.payload.taxOffices),
				};
			});
	},
});

export const taxOfficesReducer = taxOfficeSlice.reducer;
