import { createAction } from '@reduxjs/toolkit';

import {
	stopSaleServiceDeactivate,
	stopSaleServiceInsert,
	stopSaleServiceSearch,
} from '../../data/services/StopSaleService';
import type { IStopSale } from '../../models/entities/StopSale';
import type {
	IStopSalesDeactivateParams,
	IStopSalesInsertParams,
	IStopSalesSearchParams,
} from '../../models/serviceParams/StopSaleParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for stop sales based on specified parameters.
 *
 * @param {IStopSale[]} payload - The stop sales to be retrieved.
 * @param {IStopSalesSearchParams} params - Parameters for the search of stop sales.
 * @returns {Promise<void>} - A promise that resolves to an array of stop sales.
 */
export const stopSalesSearch = createAsyncAction<IStopSale[], IStopSalesSearchParams>(
	'stopSale/search',
	stopSaleServiceSearch,
);

/**
 * Inserts new stop sale records.
 *
 * @param {IStopSale[]} payload - The stop sale records to be inserted.
 * @param {IStopSalesInsertParams} params - Parameters for the insertion of stop sale records.
 * @returns {Promise<void>} - A promise that resolves after the stop sale records are inserted.
 */
export const stopSalesInsert = createAsyncAction<IStopSale[], IStopSalesInsertParams>(
	'stopSale/insert',
	stopSaleServiceInsert,
);

/**
 * Deactivates specific stop sale records.
 *
 * @param {IStopSale[]} payload - The stop sale records to be deactivated.
 * @param {IStopSalesDeactivateParams} params - Parameters for the deactivation of stop sale records.
 * @returns {Promise<void>} - A promise that resolves after the stop sale records are deactivated.
 */
export const stopSalesDeactivate = createAsyncAction<IStopSale[], IStopSalesDeactivateParams>(
	'stopSale/deactivate',
	stopSaleServiceDeactivate,
);

/**
 * Clears the stop sale state.
 */
export const stopSaleClear = createAction('stopSale/clear');
