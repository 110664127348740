import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { bookingDetailClear } from '@crac/core/redux/actions/BookingActions';
import {
	extraDriverClear,
	extraDriverGetByBooking,
	extraDriverGetServices,
	extraDriverGetServicesByBooking,
	extraDriverRemove,
	extraDriverSave,
} from '@crac/core/redux/actions/ExtraDriverActions';
import type { IExtraDriverReducerState } from '@crac/core/redux-store/reducersState/extraDriver';

const initialState: IExtraDriverReducerState = {
	bookingRequiredServices: [],
	bookingRequiredServicesRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	extraDriver: {
		data: null,
		inProgress: false,
		messages: [],
		ok: true,
	},
	extraDriverRemoveRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	extraDriverSave: {
		data: null,
		inProgress: false,
		messages: [],
		ok: true,
	},
	extraDriverServices: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
	extraDrivers: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const extraDriversSlice = createSlice({
	name: 'ExtraDriverSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// EXTRA_DRIVER_GET_BY_BOOKING
		builder
			.addCase(extraDriverGetByBooking.pending, (state) => {
				return {
					...state,
					extraDrivers: {
						data: state.extraDrivers.data,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(extraDriverGetByBooking.fulfilled, (state, action) => {
				return {
					...state,
					extraDrivers: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(extraDriverGetByBooking.rejected, (state, action) => {
				return {
					...state,
					extraDrivers: {
						data: state.extraDrivers.data,
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// EXTRA_DRIVER_GET_SERVICES
		builder
			.addCase(extraDriverGetServices.pending, (state) => {
				return {
					...state,
					extraDriverServices: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(extraDriverGetServices.fulfilled, (state, action) => {
				return {
					...state,
					extraDriverServices: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(extraDriverGetServices.rejected, (state, action) => {
				return {
					...state,
					extraDriverServices: {
						data: state.extraDriverServices ? state.extraDriverServices.data : [],
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// EXTRA_DRIVER_SAVE
		builder
			.addCase(extraDriverSave.pending, (state) => {
				return {
					...state,
					extraDriverSave: {
						data: null,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(extraDriverSave.fulfilled, (state, action) => {
				return {
					...state,
					extraDriverSave: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(extraDriverSave.rejected, (state, action) => {
				return {
					...state,
					extraDriverSave: {
						data: null,
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// EXTRA_DRIVER_REMOVE
		builder
			.addCase(extraDriverRemove.pending, (state) => {
				return {
					...state,
					extraDriverRemoveRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(extraDriverRemove.fulfilled, (state, action) => {
				return {
					...state,
					extraDriverRemoveRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					extraDrivers: {
						data: state.extraDrivers.data.filter(
							(driver) => driver.extraDriverNumber !== action.payload.extraDriverNumber,
						),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(extraDriverRemove.rejected, (state, action) => {
				return {
					...state,
					extraDriverRemoveRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// REQUIRED BOOKING SERVICES
		builder
			.addCase(extraDriverGetServicesByBooking.pending, (state) => {
				return {
					...state,
					bookingRequiredServicesRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(extraDriverGetServicesByBooking.fulfilled, (state, action) => {
				return {
					...state,
					bookingRequiredServices: action.payload,
					bookingRequiredServicesRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(extraDriverGetServicesByBooking.rejected, (state, action) => {
				return {
					...state,
					bookingRequiredServicesRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/**
		 * EXTRA_DRIVER_CLEAR
		 * BOOKING_CLEAR
		 */
		builder.addMatcher(isAnyOf(extraDriverClear, bookingDetailClear), () => initialState);
	},
});

export const extraDriversReducer = extraDriversSlice.reducer;
