import { createSlice } from '@reduxjs/toolkit';

import type { IInsuranceRate } from '@crac/core/models/entities/InsuranceRate';
import {
	insuranceRateClear,
	insuranceRateDeactivate,
	insuranceRateInsert,
	insuranceRateModify,
	insuranceRateSearch,
} from '@crac/core/redux/actions/InsuranceRateActions';
import type { IInsuranceRateReducerState } from '@crac/core/redux-store/reducersState/InsuranceRate';

const initialState: IInsuranceRateReducerState = {
	insuranceRateInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	insuranceRates: [],
	insuranceRateSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	insuranceRateModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	insuranceRateDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const insuranceRateSlice = createSlice({
	name: 'InsuranceRate',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		/* SEARCH */
		builder
			.addCase(insuranceRateSearch.pending, (state) => {
				return {
					...state,
					insuranceRateSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(insuranceRateSearch.fulfilled, (state, action) => {
				return {
					...state,
					insuranceRateSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					insuranceRates: [...action.payload].sort(
						(a1: IInsuranceRate, b1: IInsuranceRate) => (b1.id ?? 0) - (a1.id ?? 0),
					),
				};
			})
			.addCase(insuranceRateSearch.rejected, (state, action) => {
				return {
					...state,
					insuranceRateSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/* CLEAR SEARCH */
		builder.addCase(insuranceRateClear, (state) => {
			return {
				...state,
				insuranceRates: [],
			};
		});
		/* INSERT */
		builder
			.addCase(insuranceRateInsert.pending, (state) => {
				return {
					...state,
					insuranceRateInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(insuranceRateInsert.fulfilled, (state) => {
				return {
					...state,
					insuranceRateInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(insuranceRateInsert.rejected, (state, action) => {
				return {
					...state,
					insuranceRateInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/* MODIFY */
		builder
			.addCase(insuranceRateModify.pending, (state) => {
				return {
					...state,
					insuranceRateModifyRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(insuranceRateModify.fulfilled, (state, action) => {
				const { id } = action.payload as IInsuranceRate;
				const insuranceRates = state.insuranceRates?.map((insurance) =>
					insurance.id === id ? { ...action.payload } : insurance,
				);
				return {
					...state,
					insuranceRates,
					insuranceRateModifyRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(insuranceRateModify.rejected, (state, action) => {
				return {
					...state,
					insuranceRateModifyRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		/* DEACTIVATE */
		builder
			.addCase(insuranceRateDeactivate.pending, (state) => {
				return {
					...state,
					insuranceRateDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(insuranceRateDeactivate.fulfilled, (state, action) => {
				const { id } = action.payload as IInsuranceRate;
				const insuranceRates = state.insuranceRates?.map((insurance) =>
					insurance.id === id ? { ...action.payload } : insurance,
				);
				return {
					...state,
					insuranceRates,
					insuranceRateDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(insuranceRateDeactivate.rejected, (state, action) => {
				return {
					...state,
					insuranceRateDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const insuranceRateReducer = insuranceRateSlice.reducer;
