import { createSlice } from '@reduxjs/toolkit';

import { bankGetAll } from '@crac/core/redux/actions/BankActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { IBankReducerState } from '@crac/core/redux-store/reducersState/bank';
const initialState: IBankReducerState = {
	banks: [],
	banksRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const bankSlice = createSlice({
	name: 'BankSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => {
			return initialState;
		});
		// GET ALL
		builder
			.addCase(bankGetAll.pending, (state) => {
				return {
					...state,
					banksRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bankGetAll.fulfilled, (state, action) => {
				return {
					...state,
					banks: action.payload,
					banksRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(bankGetAll.rejected, (state, action) => {
				return {
					...state,
					banksRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const bankReducer = bankSlice.reducer;
