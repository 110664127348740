export enum PostCodeEndPoints {
	/**
	 * PostCode get by province
	 * @host `/postcode/getbyprovince`
	 */
	GET_BY_PROVINCE = '/postcode/getbyprovince',

	/**
	 * PostCode get by code
	 * @host `/postcode/getbycode`
	 */
	GET_BY_POSTAL_CODE = '/postcode/getbycode',

	/**
	 * PostCode get by country and text
	 * @host `/postcode/getbycountryandtext`
	 */
	GET_BY_COUNTRY_AND_TEXT = '/postcode/getbycountryandtext',
}
