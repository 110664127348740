import { createSlice } from '@reduxjs/toolkit';

import {
	invoiceClear,
	invoiceGetByPlateNumber,
	invoiceGetByVehicleAndCode,
} from '@crac/core/redux/actions/InvoiceActions';
import type { IInvoiceReducerState } from '@crac/core/redux-store/reducersState/invoice';
const initialState: IInvoiceReducerState = {
	invoiceGetByPlateNumberRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	invoiceGetByVehicleAndCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	invoices: [],
	invoice: undefined,
};

const invoiceSlice = createSlice({
	name: 'InvoiceSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY PLATE NUMBER
		builder
			.addCase(invoiceGetByPlateNumber.pending, (state) => {
				return {
					...state,
					invoiceGetByPlateNumberRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(invoiceGetByPlateNumber.fulfilled, (state, action) => {
				return {
					...state,
					invoiceGetByPlateNumberRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					invoices: action.payload,
				};
			})
			.addCase(invoiceGetByPlateNumber.rejected, (state, action) => {
				return {
					...state,
					invoiceGetByPlateNumberRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY VEHICLE AND CODE
		builder
			.addCase(invoiceGetByVehicleAndCode.pending, (state) => {
				return {
					...state,
					invoiceGetByVehicleAndCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(invoiceGetByVehicleAndCode.fulfilled, (state, action) => {
				return {
					...state,
					invoiceGetByVehicleAndCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					invoice: action.payload,
				};
			})
			.addCase(invoiceGetByVehicleAndCode.rejected, (state, action) => {
				return {
					...state,
					invoiceGetByVehicleAndCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CLEAR
		builder.addCase(invoiceClear, (state) => {
			return {
				...state,
				invoices: [],
				invoice: undefined,
			};
		});
	},
});

export const invoiceReducer = invoiceSlice.reducer;
