import { combineReducers } from '@reduxjs/toolkit';

import { accidentsReducer as Accidents } from './detail/accidents/state/slice';
import { commentsReducer as Comments } from './detail/comments/state/slice';
import { detailReducer as Detail } from './detail/detail/state/slice';
import { documentsReducer as Documents } from './detail/documents/state/slice';
import { extendReducer as Extend } from './detail/extend/state/slice';
import { extraDriversReducer as ExtraDrivers } from './detail/extraDrivers/state/slice';
import { tollsReducer as Tolls } from './detail/tolls/state/slice';
import { vehicleChangeReducer as VehicleChange } from './detail/vehicles/state/slices/vehicleChange';
import { vehicleLinesReducer as VehicleLines } from './detail/vehicles/state/slices/vehicleLines';
import { dropOffReducer as DropOff } from './dropOff/state/slice';
import { availabilityReducer as Availability } from './new/availability/state/slice';
import { insertReducer as Insert } from './new/state/slices/insert';
import { newReducer as New } from './new/state/slices/new';
import { pickUpReducer as PickUp } from './pickUp/state/slice';
import { receptionReducer as Reception } from './reception/state/slices/reception';
import { turnsReducer as Turns } from './reception/state/slices/turns';
import { regularizationsReducer as Regularizations } from './regularizations/state/slices/regularizations';
import { regularizationSubjectsReducer as RegularizationSubjects } from './regularizations/state/slices/regularizationSubjects';
import { searchReducer as Search } from './search/state/slice';
import { linesReducer as Lines } from './shared/state/slices/lines';
import { paymentLinesReducer as PaymentLines } from './shared/state/slices/paymentLines';

export const bookingReducer = combineReducers({
	Accidents,
	Availability,
	Comments,
	Detail,
	Documents,
	DropOff,
	Extend,
	ExtraDrivers,
	Insert,
	Lines,
	New,
	PaymentLines,
	PickUp,
	Reception,
	Regularizations,
	RegularizationSubjects,
	Search,
	Tolls,
	Turns,
	VehicleChange,
	VehicleLines,
});
