export enum ComputerEndPoints {
	/**
	 * Computer get all by branch
	 * @host `/computer/getbybranch/`
	 */
	GET_BY_BRANCH = '/computer/getbybranch/',

	/**
	 * Computer get by IP
	 * @host `/computer/getbydeviceip/`
	 */
	GET_BY_DEVICEIP = '/computer/getbydeviceip/',

	/**
	 * Computer get by device name
	 * @host `/computer/getbydevicename`
	 */
	GET_BY_DEVICE_NAME = '/computer/getbydevicename/',

	/**
	 * Computer set data
	 * @host `/computer/setdata/`
	 */
	SET_DATA = '/computer/setdata/',
}
