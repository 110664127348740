/* eslint-disable max-lines-per-function */
import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	fleetVehicleModifyChangeAgreedRepurchaseDate,
	fleetVehicleModifyChangeDeFleetDate,
	fleetVehicleModifyChangeSaleAmount,
} from '@crac/core/redux/actions/FleetVehicleModifyActions';
import { linkAddToVehicle, linkGetByInsurance, linkGetByVehicle } from '@crac/core/redux/actions/LinkActions';
import {
	vehicleAssignTag,
	vehicleBlock,
	vehicleChangeCarrier,
	vehicleChangeEndAmortizationDate,
	vehicleChangeLastRevisionKm,
	vehicleGetByPlateNumber,
	vehicleLock,
	vehicleUnlock,
	vehicleUpdateKeysLocation,
	vehicleUpdateOBE,
} from '@crac/core/redux/actions/VehicleActions';
import type { IVehicleDetailReducerState } from '@crac/core/redux-store/reducersState/vehicle/DetailReducerState';

const initialState: IVehicleDetailReducerState = {
	assignTag: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	block: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	load: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	lock: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	unlock: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	updateObe: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicle: null,
	vehicleLine: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
	updateKeysLocation: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	changeLastRevisionKm: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	changeEndAmortizationDate: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	documentLinks: [],
	documentLinksRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	documentLinksInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	insuranceLinks: [],
	insuranceLinksRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	changeCarrierRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	changeSaleAmountRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const detailSlice = createSlice({
	name: 'VehicleDetailSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR CACHE
		builder.addCase(clearGlobalCache, () => initialState);
		// VEHICLE UPDATE OBE
		builder
			.addCase(vehicleUpdateOBE.pending, (state) => {
				return {
					...state,
					updateObe: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleUpdateOBE.fulfilled, (state, action) => {
				return {
					...state,
					updateObe: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicle: deepCopy(action.payload),
				};
			})
			.addCase(vehicleUpdateOBE.rejected, (state, action) => {
				return {
					...state,
					updateObe: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// VEHICLE ASSIGN TAG
		builder
			.addCase(vehicleAssignTag.pending, (state) => {
				return {
					...state,
					assignTag: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleAssignTag.fulfilled, (state, action) => {
				return {
					...state,
					assignTag: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicle: deepCopy(action.payload[0]),
				};
			})
			.addCase(vehicleAssignTag.rejected, (state, action) => {
				return {
					...state,
					assignTag: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// VEHICLE BLOCK VEHICLE STATE
		builder
			.addCase(vehicleBlock.pending, (state) => {
				return {
					...state,
					block: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleBlock.fulfilled, (state, action) => {
				return {
					...state,
					block: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicle: deepCopy(action.payload),
				};
			})
			.addCase(vehicleBlock.rejected, (state, action) => {
				return {
					...state,
					block: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// GET BY PLATE
		builder
			.addCase(vehicleGetByPlateNumber.pending, () => {
				return {
					...initialState,
					load: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					vehicle: null,
				};
			})
			.addCase(vehicleGetByPlateNumber.fulfilled, (state, action) => {
				return {
					...state,
					load: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicle: deepCopy(action.payload),
				};
			})
			.addCase(vehicleGetByPlateNumber.rejected, (state, action) => {
				return {
					...state,
					load: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// VEHICLE UNLOCK STATE
		builder
			.addCase(vehicleUnlock.pending, (state) => {
				return {
					...state,
					unlock: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleUnlock.fulfilled, (state) => {
				return {
					...state,
					unlock: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleUnlock.rejected, (state, action) => {
				return {
					...state,
					unlock: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// VEHICLE LOCK STATE
		builder
			.addCase(vehicleLock.pending, (state) => {
				return {
					...state,
					lock: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleLock.fulfilled, (state) => {
				return {
					...state,
					lock: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleLock.rejected, (state, action) => {
				return {
					...state,
					lock: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// VEHICLE UPDATE KEYS LOCATION
		builder
			.addCase(vehicleUpdateKeysLocation.pending, (state) => {
				return {
					...state,
					updateKeysLocation: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleUpdateKeysLocation.fulfilled, (state, action) => {
				return {
					...state,
					updateKeysLocation: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicle: deepCopy(action.payload),
				};
			})
			.addCase(vehicleUpdateKeysLocation.rejected, (state, action) => {
				return {
					...state,
					updateKeysLocation: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		builder
			.addCase(vehicleChangeLastRevisionKm.pending, (state) => {
				return {
					...state,
					changeLastRevisionKm: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleChangeLastRevisionKm.rejected, (state, action) => {
				return {
					...state,
					changeLastRevisionKm: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(vehicleChangeLastRevisionKm.fulfilled, (state, action) => {
				return {
					...state,
					changeLastRevisionKm: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicle: action.payload,
				};
			});
		// GET VEHICLE DOCUMENTS
		builder
			.addCase(linkGetByVehicle.pending, (state) => {
				return {
					...state,
					documentLinks: [],
					documentLinksRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByVehicle.fulfilled, (state, action) => {
				return {
					...state,
					documentLinks: action.payload,
					documentLinksRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByVehicle.rejected, (state, action) => {
				return {
					...state,
					documentLinks: [],
					documentLinksRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// ADD VEHICLE DOCUMENTS
		builder
			.addCase(linkAddToVehicle.pending, (state) => {
				return {
					...state,
					documentLinksInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkAddToVehicle.fulfilled, (state, action) => {
				return {
					...state,
					documentLinks: [...(state.documentLinks || []), ...action.payload],
					documentLinksInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkAddToVehicle.rejected, (state, action) => {
				return {
					...state,
					documentLinksInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET VEHICLE INSURANCES
		builder
			.addCase(linkGetByInsurance.pending, (state) => {
				return {
					...state,
					insuranceLinks: [],
					insuranceLinksRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByInsurance.fulfilled, (state, action) => {
				return {
					...state,
					insuranceLinks: action.payload,
					insuranceLinksRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByInsurance.rejected, (state, action) => {
				return {
					...state,
					insuranceLinks: [],
					insuranceLinksRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// UPDATE CHANGE END AMORTIZATION DATE
		builder
			.addCase(vehicleChangeEndAmortizationDate.pending, (state) => {
				return {
					...state,
					changeEndAmortizationDate: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleChangeEndAmortizationDate.fulfilled, (state, action) => {
				return {
					...state,
					changeEndAmortizationDate: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicle: action.payload[0],
				};
			})
			.addCase(vehicleChangeEndAmortizationDate.rejected, (state, action) => {
				return {
					...state,
					changeEndAmortizationDate: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CHANGE CARRIER
		builder
			.addCase(vehicleChangeCarrier.pending, (state) => {
				return {
					...state,
					changeCarrierRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleChangeCarrier.rejected, (state, action) => {
				return {
					...state,
					changeCarrierRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(vehicleChangeCarrier.fulfilled, (state, action) => {
				return {
					...state,
					changeCarrierRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicle: action.payload[0],
				};
			});

		// CHANGE SALE AMOUNT
		builder
			.addCase(fleetVehicleModifyChangeSaleAmount.pending, (state) => {
				return {
					...state,
					changeSaleAmountRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeSaleAmount.rejected, (state, action) => {
				return {
					...state,
					changeSaleAmountRequest: {
						inProgress: false,
						messages: action.payload ?? [],
						ok: false,
					},
				};
			})
			.addCase(fleetVehicleModifyChangeSaleAmount.fulfilled, (state, action) => {
				return {
					...state,
					changeSaleAmountRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					vehicle: action.payload[0],
				};
			});
		// UPDATE CHANGE AGREED REPURCHASE DATE
		builder.addMatcher(
			isAnyOf(
				fleetVehicleModifyChangeAgreedRepurchaseDate.fulfilled,
				fleetVehicleModifyChangeDeFleetDate.fulfilled,
			),
			(state, action) => {
				return {
					...state,
					vehicle: action.payload[0],
				};
			},
		);
	},
});

export const detailReducer = detailSlice.reducer;
