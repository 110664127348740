export enum FleetTransferEndPoints {
	/**
	 * Fleet transfer search
	 * @host `/fleetTransfer/search/`
	 */
	SEARCH = '/fleetTransfer/search/',
	/**
	 * Fleet transfer insert
	 * @host `/fleetTransfer/insert/`
	 */
	INSERT = '/fleetTransfer/insert/',
	/**
	 * Fleet transfer deactivate
	 * @host `/fleetTransfer/deactivate/`
	 */
	DEACTIVATE = '/fleetTransfer/deactivate/',
	/**
	 * Fleet transfer modify
	 * @host `/fleetTransfer/modify/`
	 */
	MODIFY = '/fleetTransfer/modify/',
	/**
	 * Fleet transfer export
	 * @host `/fleetTransfer/export/`
	 */
	EXPORT = '/fleetTransfer/export/',
}
