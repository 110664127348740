export enum BrandParkingEndpoints {
	/**
	 * Searches brandParkings
	 * @host `/brandParking/search`
	 */
	SEARCH = '/brandParking/search/',
	/**
	 * Inserts brandParking
	 * @host `/brandParking/insert`
	 */
	INSERT = '/brandParking/insert/',
	/**
	 * Modifies brandParking
	 * @host `/brandParking/modify`
	 */
	MODIFY = '/brandParking/modify/',
	/**
	 * Deactivates brandParking
	 * @host `/brandParking/deactivate`
	 */
	DEACTIVATE = '/brandParking/deactivate/',
	/**
	 * Activates brandParking
	 * @host `/brandParking/activate`
	 */
	ACTIVATE = '/brandParking/activate/',
}
