import { combineReducers } from '@reduxjs/toolkit';

import { commentsReducer as Comments } from './detail/state/slices/commentsSlice';
import { detailReducer as Detail } from './detail/state/slices/detailSlice';
import { newReducer as New } from './new/state/slice';
import { searchReducer as Search } from './search/state/slice';

export const companyReducer = combineReducers({
	Comments,
	Detail,
	New,
	Search,
});
