import type { IToll } from '../../models/entities/Toll';
import type { ITollGetByBookingParams, ITollGetByPlateNumberParams } from '../../models/serviceParams/TollParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { TollEndPoints } from '../endPoints/TollEndPoints';

/**
 * GetByBooking
 * @param {string} bookingNumber Toll bookingNumber
 * @return {Promise<ServiceResponse<Toll[]>>} `Promise<ServiceResponse<Toll[]>>`
 */
export const tollServiceGetByBooking = (model: ITollGetByBookingParams): Promise<ServiceResponse<IToll[]>> => {
	return Api.get<IToll[], ITollGetByBookingParams>(TollEndPoints.GET_BY_BOOKING, model);
};

/**
 * GetByPlateNumber
 * @param {string} plateNUmber Toll plateNumber
 * @return {Promise<ServiceResponse<Toll[]>>} `Promise<ServiceResponse<Toll[]>>`
 */
export const tollServiceGetByPlateNumber = (model: ITollGetByPlateNumberParams): Promise<ServiceResponse<IToll[]>> => {
	return Api.get<IToll[], ITollGetByPlateNumberParams>(TollEndPoints.GET_BY_PLATENUMBER, model);
};
