export enum MetaModelEndpoints {
	/**
	 * Searches metaModels
	 * @host `/metaModel/search`
	 */
	SEARCH = '/metaModel/search/',
	/**
	 * Inserts metaModel
	 * @host `/metaModel/insert`
	 */
	INSERT = '/metaModel/insert/',
	/**
	 * Modifies metaModel
	 * @host `/metaModel/modify`
	 */
	MODIFY = '/metaModel/modify/',
	/**
	 * Saves metaModel's image
	 * @host `/metaModel/saveImage`
	 */
	SAVE_IMAGE = '/metaModel/saveImage/',
	/**
	 * Deletes metaModel
	 * @host `/metaModel/delete`
	 */
	DELETE = '/metaModel/delete/',
}
