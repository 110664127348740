import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const RentRateMargin = lazyWithRetry(() => import('./pages/RentRateMargin'));
const RentRateMarginNew = lazyWithRetry(() => import('./pages/RentRateMarginNew'));
const RentRateMarginEdit = lazyWithRetry(() => import('./pages/RentRateMarginEdit'));

export const rentRateMarginRoutes: { [key: number]: IRouteConfig } = {};

// MARGIN
rentRateMarginRoutes[routesIdMap.RentRateMargin] = {
	component: RentRateMargin,
	exact: true,
	id: routesIdMap.RentRateMargin,
	menuText: 'Margins',
	name: 'Rent Rate Margin',
	path: '/pricing/rent-rate-margin/',
	permissions: getPricingPermission([PermissionsType.PricingRentRateMargin]),
	childrens: [routesIdMap.RentRateMarginNew, routesIdMap.RentRateMarginEdit],
	icon: 'bar-chart',
};

rentRateMarginRoutes[routesIdMap.RentRateMarginNew] = {
	component: RentRateMarginNew,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateMarginNew,
	name: 'New rent rate margin',
	path: '/pricing/rent-rate-margin/new',
	permissions: getPricingPermission([PermissionsType.PricingRentRateMargin]),
	icon: 'plus',
	menuText: 'New rent rate margin',
};

rentRateMarginRoutes[routesIdMap.RentRateMarginEdit] = {
	component: RentRateMarginEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateMarginEdit,
	name: 'New rent rate margin',
	path: '/pricing/rent-rate-margin/modify/:id',
	permissions: getPricingPermission([PermissionsType.PricingRentRateMargin]),
	icon: 'pencil',
};
