import { createSlice } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { vehicleGetKeychainsLabels, vehicleKeychainsLabelsClear } from '@crac/core/redux/actions/VehicleActions';
import type { IKeychainsLabelsReducerState } from '@crac/core/redux-store/reducersState/vehicle/KeychainsLabelsReducerState';

const initialState: IKeychainsLabelsReducerState = {
	selectedList: [],
	vehicles: {
		data: [],
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const keyChainsLabelSlice = createSlice({
	name: 'VehicleKeyChainsLabelSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		/* CHAINS LABELS CLEAR */
		builder.addCase(vehicleKeychainsLabelsClear, () => {
			return initialState;
		});
		/* GET KEY CHAINS LABELS */
		builder
			.addCase(vehicleGetKeychainsLabels.pending, (state) => {
				return {
					...state,
					vehicles: {
						data: [],
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGetKeychainsLabels.fulfilled, (state, action) => {
				return {
					...state,
					vehicles: {
						data: deepCopy(action.payload),
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(vehicleGetKeychainsLabels.rejected, (state, action) => {
				return {
					...state,
					vehicles: {
						data: state.vehicles ? state.vehicles.data : [],
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
	},
});

export const keyChainsLabelReducer = keyChainsLabelSlice.reducer;
