import { isServerSideRendering } from '@crac/core/helpers/ssr';

const elClassList: DOMTokenList | null = isServerSideRendering ? null : document.body.classList;

//  Sidebar perfect scrollbar
export const sidebarPSToggle = (toggle: boolean): void => {
	const sidebar = isServerSideRendering ? document.querySelector('.sidebar-nav') : undefined;

	if (sidebar) {
		if (toggle) {
			sidebar.classList.add('ps');
			sidebar.classList.add('ps-container');
			sidebar.classList.add('ps--active-y');
		} else {
			sidebar.classList.remove('ps');
			sidebar.classList.remove('ps-container');
			sidebar.classList.remove('ps--active-y');
		}
	}
};

const toggleClass = (className: string, force: boolean): void | boolean => {
	if (!elClassList) {
		return false;
	}

	if (force === true) {
		elClassList.add(className);
	} else if (force === false) {
		elClassList.remove(className);
	} else {
		elClassList.toggle(className);
	}

	return elClassList.contains(className);
};

const sidebarMinimize = (force: boolean): void | boolean => {
	// Return this.elClassList.toggle('sidebar-minimized', force);
	return toggleClass('sidebar-minimized', force);
};

const brandMinimize = (force: boolean): void | boolean => {
	// This.elClassList.toggle('brand-minimized', force);
	toggleClass('brand-minimized', force);
};

export const sidebarToggle = (toggle?: boolean): void => {
	if (elClassList) {
		const minimize = toggle ? toggle : !elClassList.contains('sidebar-minimized');
		brandMinimize(minimize);
		sidebarMinimize(minimize);
		/* Remove PS on sidebar minimized*/
		sidebarPSToggle(!minimize);
	}
};

export const checkBreakpoint = (breakpoint: string, list: string[]): boolean => {
	return list.indexOf(breakpoint) > -1;
};

export const validBreakpoints = ['sm', 'md', 'lg', 'xl'];

export const sidebarCssClasses = [
	'sidebar-show',
	'sidebar-sm-show',
	'sidebar-md-show',
	'sidebar-lg-show',
	'sidebar-xl-show',
];

export const asideMenuCssClasses: string[] = [
	'aside-menu-show',
	'aside-menu-sm-show',
	'aside-menu-md-show',
	'aside-menu-lg-show',
	'aside-menu-xl-show',
];
