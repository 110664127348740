import { useAppSelector } from '~/redux/hooks';

export const useCashCurrentSelector = () => useAppSelector((state) => state.Cash.Current);

export const useCashDetailSelector = () => useAppSelector((state) => state.Cash.Detail);

export const useCashHistorySelector = () => useAppSelector((state) => state.Cash.History);

export const useCashModifySelector = () => useAppSelector((state) => state.Cash.Modify);

export const useCashPendingSelector = () => useAppSelector((state) => state.Cash.Pending);

export const useCashSignSelector = () => useAppSelector((state) => state.Cash.Sign);

export const useCashSpendingSelector = () => useAppSelector((state) => state.Cash.Spending);

export const useCashSpendingTypeSelector = () => useAppSelector((state) => state.Cash.SpendingType);

export const useCashOpenedBookingsSelector = () => useAppSelector((state) => state.Cash.OpenedBookings);
