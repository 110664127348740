import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const ServiceRate = lazyWithRetry(() => import('./pages/ServiceRate'));
const ServiceRateNew = lazyWithRetry(() => import('./pages/ServiceRateNew'));

export const serviceRateRoutes: { [key: number]: IRouteConfig } = {};

// RATES
serviceRateRoutes[routesIdMap.ServiceRates] = {
	component: ServiceRate,
	exact: true,
	id: routesIdMap.ServiceRates,
	name: 'Service rates',
	menuText: 'Rates',
	path: '/pricing/service-rates',
	permissions: getPricingPermission([PermissionsType.PricingServiceRate]),
	childrens: [routesIdMap.ServiceRatesNew, routesIdMap.ServiceRatesNewFrom],
	icon: 'list',
};
serviceRateRoutes[routesIdMap.ServiceRatesNew] = {
	component: ServiceRateNew,
	exact: true,
	isChild: true,
	id: routesIdMap.ServiceRatesNew,
	name: 'New service rate',
	path: '/pricing/service-rates/new',
	permissions: getPricingPermission([PermissionsType.PricingServiceRate]),
	menuText: 'New service rate',
	icon: 'plus',
};
serviceRateRoutes[routesIdMap.ServiceRatesNewFrom] = {
	component: ServiceRateNew,
	exact: true,
	isChild: true,
	id: routesIdMap.ServiceRatesNewFrom,
	name: 'New service rate',
	path: '/pricing/service-rates/newfrom/:id',
	permissions: getPricingPermission([PermissionsType.PricingServiceRate]),
	icon: 'plus',
};
