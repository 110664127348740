import { BrandParkingEndpoints } from './BrandParkingEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { IBrandParking } from '../entities/BrandParking';

export interface IBrandParkingActivateParams extends IServiceBaseParams {
	id: number;
}

export const brandParkingActivateService = (model: IBrandParkingActivateParams) => {
	return Api.post<IBrandParking, IBrandParkingActivateParams>(BrandParkingEndpoints.ACTIVATE, model);
};
