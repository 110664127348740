import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const CustomerSearch = lazyWithRetry(() => import('~/features/customer/search/pages/Search'));

export const searchRoutes: { [key: number]: IRouteConfig } = {};

searchRoutes[routesIdMap.Customers] = {
	component: CustomerSearch,
	exact: true,
	id: routesIdMap.Customers,
	name: 'Search customer',
	path: '/customer/search',
	permissions: [PermissionsType.Customer],
	icon: 'search',
	menuText: 'Search',
};
