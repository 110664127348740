export enum RevisionCostEndPoints {
	/**
	 * Search revision costs
	 * @host `/revisionCost/search`
	 */
	SEARCH = '/revisionCost/search',
	/**
	 * Save revision costs
	 * @host `/revisionCost/save`
	 */
	SAVE = '/revisionCost/save',
}
