import { createAction } from '@reduxjs/toolkit';

import {
	occupancyServiceDeactivate,
	occupancyServiceInsert,
	occupancyServiceSearch,
} from '../../data/services/OccupancyService';
import type { IOccupancy } from '../../models/entities/Occupancy';
import type {
	IOccupancyDeactivateParams,
	IOccupancyInsertParams,
	IOccupancySearchParams,
} from '../../models/serviceParams/OccupancyParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for occupancy records based on specified parameters.
 *
 * @param {IOccupancy[]} payload - The occupancy records to be retrieved.
 * @param {IOccupancySearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of occupancy records.
 */
export const occupancySearch = createAsyncAction<IOccupancy[], IOccupancySearchParams>(
	'occupancy/search',
	occupancyServiceSearch,
);

/**
 * Inserts new occupancy records.
 *
 * @param {IOccupancy[]} payload - The occupancy records to be inserted.
 * @param {IOccupancyInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the occupancy records are inserted.
 */
export const occupancyInsert = createAsyncAction<IOccupancy[], IOccupancyInsertParams>(
	'occupancy/insert',
	occupancyServiceInsert,
);

/**
 * Deactivates specific occupancy records.
 *
 * @param {IOccupancy[]} payload - The occupancy records to be deactivated.
 * @param {IOccupancyDeactivateParams} params - Parameters for the deactivation.
 * @returns {Promise<void>} - A promise that resolves after the occupancy records are deactivated.
 */
export const occupancyDeactivate = createAsyncAction<IOccupancy[], IOccupancyDeactivateParams>(
	'occupancy/deactivate',
	occupancyServiceDeactivate,
);

/**
 * Clears the occupancy state.
 */
export const occupancyClear = createAction('occupancy/clear');
