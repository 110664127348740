import { DateTime } from 'luxon';

import type { IEmployee } from '@crac/core/models/entities/Employee';
import type { ITurn } from '@crac/core/models/entities/Turn';

export const setOfficeDateTime = (turns: ITurn[], employee: IEmployee): ITurn[] => {
	const { userName } = employee;
	return turns
		.filter((turn) => turn.busDateTime && turn.busUser.toUpperCase() === userName.toUpperCase())
		.map((item) => ({
			...item,
			officeDateTime: DateTime.utc().toJSON() as string,
		}));
};
