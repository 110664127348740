import { createAction } from '@reduxjs/toolkit';

import {
	fleetVehicleColorServiceDelete,
	fleetVehicleColorServiceInsert,
	fleetVehicleColorServiceModify,
	fleetVehicleColorServiceSearch,
} from '../../data/services/FleetVehicleColorService';
import type { IVehicleColor } from '../../models/entities/VehicleColor';
import type {
	IFleetVehicleColorDeleteParams,
	IFleetVehicleColorInsertParams,
	IFleetVehicleColorModifyParams,
	IFleetVehicleColorSearchParams,
} from '../../models/serviceParams/FleetVehicleColorParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Clears the vehicle brand data from the state.
 */
export const fleetVehicleColorClear = createAction('fleet/vehicleColorClear');

/**
 * Searches for vehicle colors based on search parameters.
 * @param {IVehicleColor[]} payload `IVehicleColor[]` The payload for the action, typically search results.
 * @param {IFleetVehicleColorSearchParams} IFleetVehicleColorSearchParams The parameters used for searching vehicle colors.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves to an array of vehicle colors based on the search criteria.
 */
export const fleetVehicleColorSearch = createAsyncAction<IVehicleColor[], IFleetVehicleColorSearchParams>(
	'fleet/vehicleColor/search',
	fleetVehicleColorServiceSearch,
);

/**
 * Inserts a new vehicle color into the fleet.
 * @param {IVehicleColor} payload `IVehicleColor` The vehicle color to be inserted.
 * @param {IFleetVehicleColorInsertParams} IFleetVehicleColorInsertParams The parameters for inserting a new vehicle color.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the vehicle color has been inserted.
 */
export const fleetVehicleColorInsert = createAsyncAction<IVehicleColor, IFleetVehicleColorInsertParams>(
	'fleet/vehicleColor/insert',
	fleetVehicleColorServiceInsert,
);

/**
 * Modifies an existing vehicle color in the fleet.
 * @param {IVehicleColor} payload `IVehicleColor` The vehicle color to be modified.
 * @param {IFleetVehicleColorModifyParams} IFleetVehicleColorModifyParams The parameters for modifying an existing vehicle color.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the vehicle color has been modified.
 */
export const fleetVehicleColorModify = createAsyncAction<IVehicleColor, IFleetVehicleColorModifyParams>(
	'fleet/vehicleColor/modify',
	fleetVehicleColorServiceModify,
);

/**
 * Deletes a vehicle color from the fleet.
 * @param {IVehicleColor} payload `IVehicleColor` The vehicle color to be deleted.
 * @param {IFleetVehicleColorDeleteParams} IFleetVehicleColorDeleteParams The parameters for deleting a vehicle color.
 * @returns {Promise<void>} `Promise<void>` - A promise that resolves when the vehicle color has been deleted.
 */
export const fleetVehicleColorDelete = createAsyncAction<IVehicleColor, IFleetVehicleColorDeleteParams>(
	'fleet/vehicleColor/delete',
	fleetVehicleColorServiceDelete,
);
