import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Queues = lazyWithRetry(() => import('./pages/Queues'));

export const queuesRoutes: { [key: number]: IRouteConfig } = {};

queuesRoutes[routesIdMap.ManagementQueues] = {
	component: Queues,
	exact: true,
	id: routesIdMap.ManagementQueues,
	name: 'Queues',
	path: '/management/queues',
	permissions: [PermissionsType.ManagementQueues],
	icon: 'users',
	menuText: 'Queues',
};

queuesRoutes[routesIdMap.ManagementQueuesNew] = {
	component: Queues,
	exact: true,
	id: routesIdMap.ManagementQueuesNew,
	name: 'New queue',
	path: '/management/queues/new',
	permissions: [PermissionsType.ManagementQueues],
	icon: 'plus',
	menuText: 'New queue',
};

queuesRoutes[routesIdMap.ManagementQueuesDetail] = {
	component: Queues,
	exact: true,
	id: routesIdMap.ManagementQueuesDetail,
	name: 'Queues',
	path: '/management/queues/:id',
	permissions: [PermissionsType.ManagementQueues],
	icon: 'users',
};
