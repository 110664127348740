import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	companyClear,
	companyGetByCode,
	companyInsert,
	companyInvoiceToGetByCode,
	companyModify,
	companyValidateData,
} from '@crac/core/redux/actions/CompanyActions';
import type { ICompanyDetailReducerState } from '@crac/core/redux-store/reducersState/company/DetailReducerState';

const initialState: ICompanyDetailReducerState = {
	company: null,
	companyInvoiceTo: null,
	companyGetByCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	companyInvoiceToGetByCodeRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	companySaveRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	companyValidationDataRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const detailSlice = createSlice({
	name: 'CompanyDetail',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// INVOICE TO GET BY CODE
		builder
			.addCase(companyInvoiceToGetByCode.pending, (state) => {
				return {
					...state,
					companyInvoiceToGetByCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					companyInvoiceTo: null,
				};
			})
			.addCase(companyInvoiceToGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					companyInvoiceTo: deepCopy(action.payload),
					companyInvoiceToGetByCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyInvoiceToGetByCode.rejected, (state, action) => {
				return {
					...state,
					companyInvoiceToGetByCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// MODIFY
		builder
			.addCase(companyModify.pending, (state) => {
				return {
					...state,
					companySaveRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyModify.fulfilled, (state, action) => {
				return {
					...state,
					company: deepCopy(action.payload),
					companySaveRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyModify.rejected, (state, action) => {
				return {
					...state,
					companySaveRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// VALIATION
		builder
			.addCase(companyValidateData.pending, (state) => {
				return {
					...state,
					companyValidationDataRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyValidateData.fulfilled, (state) => {
				return {
					...state,
					companyValidationDataRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(companyValidateData.rejected, (state, action) => {
				return {
					...state,
					companyValidationDataRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// GET BY CODE
		builder
			.addCase(companyGetByCode.pending, (state) => {
				return {
					...state,
					companyGetByCodeRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
					company: null,
				};
			})
			.addCase(companyGetByCode.rejected, (state, action) => {
				return {
					...state,
					companyGetByCodeRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			})
			.addCase(companyGetByCode.fulfilled, (state, action) => {
				return {
					...state,
					companyGetByCodeRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					company: action.payload,
				};
			});

		builder.addCase(companyInsert.pending, (state) => {
			return {
				...state,
				companySaveRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(companyInsert.rejected, (state, action) => {
			return {
				...state,
				companySaveRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(companyInsert.fulfilled, (state, action) => {
			return {
				...state,
				companySaveRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				company: action.payload,
				companyInvoiceTo: action.payload,
			};
		});

		/**
		 * CLEAR CACHE
		 * CLEAR
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, companyClear), () => initialState);
	},
});

export const detailReducer = detailSlice.reducer;
