import type { ComponentType } from 'react';
import { lazy } from 'react';

type ComponentPromise<T = any> = Promise<{ default: ComponentType<T> }>;
type ComponentTypeWithLazyLoad = () => ComponentPromise;

/**
 * Try to load a component and retry if it fails.
 * https://raphael-leger.medium.com/react-webpack-chunkloaderror-loading-chunk-x-failed-ac385bd110e0
 */
export const lazyWithRetry = (
	componentImport: ComponentTypeWithLazyLoad,
): React.LazyExoticComponent<React.ComponentType<any>> => {
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	return lazy(async () => {
		const pageHasAlreadyBeenForceRefreshed = JSON.parse(
			window.localStorage.getItem('page-has-been-force-refreshed') || 'false',
		);

		try {
			const component = await componentImport();

			window.localStorage.setItem('page-has-been-force-refreshed', 'false');

			return component;
		} catch (error) {
			if (!pageHasAlreadyBeenForceRefreshed) {
				/*
				 * Assuming that the user is not on the latest version of the application.
				 * Let's refresh the page immediately.
				 */
				window.localStorage.setItem('page-has-been-force-refreshed', 'true');
				if (window && window.caches) {
					window.caches.keys().then((cacheNames) => {
						cacheNames.forEach((cacheName) => {
							window.caches.delete(cacheName);
						});
					});
				}

				return window.location.reload();
			}

			/*
			 * The page has already been reloaded
			 * Assuming that user is already using the latest version of the application.
			 * Let's let the application crash and raise the error.
			 */
			// eslint-disable-next-line no-console
			console.log('error', error);
			throw error;
		}
	});
};
