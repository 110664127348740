/* eslint-disable require-await */
import { gCSwal } from '@crac/components/external/sweet-alert';
import type { IRequestState } from '@crac/core/modules/shared/types/RequestStateType';

export const checkRequestHasErrors = async (requests: IRequestState[]): Promise<boolean> => {
	const requestWidthError = requests.filter((req) => !req.ok);

	const showError = async (req: IRequestState): Promise<void> => {
		await gCSwal('Error!', req.messages.map((msg) => msg.message).join(', '));
	};

	if (requestWidthError.length > 0) {
		const next = ([request]: IRequestState[]) => {
			if (request) {
				showError(request).then(() => {
					next(requestWidthError.splice(0, 1));
				});
			}
		};

		next(requestWidthError.splice(0, 1));

		if (requestWidthError.length === 0) {
			return true;
		}
	}

	return false;
};
