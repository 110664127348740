import { createAction } from '@reduxjs/toolkit';

import {
	rentRateTypeInsertService,
	rentRateTypeModifyService,
	rentRateTypeSearchService,
} from '../../data/services/RentRateTypeService';
import type { IRentRateType } from '../../models/entities/RentRateType';
import type { IRentRateTypeInsertParams, IRentRateTypesSearchParams } from '../../models/serviceParams/RentParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for rent rate types based on specified parameters.
 *
 * @param {IRentRateType[]} payload - The rent rate types to be retrieved.
 * @param {IRentRateTypesSearchParams} params - Parameters for the search of rent rate types.
 * @returns {Promise<void>} - A promise that resolves to an array of rent rate types.
 */
export const rentRateTypeSearch = createAsyncAction<IRentRateType[], IRentRateTypesSearchParams>(
	'rentRateType/search',
	rentRateTypeSearchService,
);

/**
 * Inserts a new rent rate type.
 *
 * @param {IRentRateType} payload - The rent rate type to be inserted.
 * @param {IRentRateTypeInsertParams} params - Parameters for the insertion of the rent rate type.
 * @returns {Promise<void>} - A promise that resolves after the rent rate type is inserted.
 */
export const rentRateTypeInsert = createAsyncAction<IRentRateType, IRentRateTypeInsertParams>(
	'rentRateType/insert',
	rentRateTypeInsertService,
);

/**
 * Modifies an existing rent rate type.
 *
 * @param {IRentRateType} payload - The rent rate type to be modified.
 * @param {IRentRateTypeInsertParams} params - Parameters for the modification of the rent rate type.
 * @returns {Promise<void>} - A promise that resolves after the rent rate type is modified.
 */
export const rentRateTypeModify = createAsyncAction<IRentRateType, IRentRateTypeInsertParams>(
	'rentRateType/modify',
	rentRateTypeModifyService,
);

/**
 * Resets the rent rate type state.
 */
export const rentRateTypeReset = createAction('rentRateType/reset');
