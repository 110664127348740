import { css, styled } from 'styled-components';

import { Button } from '../../../external/reactstrap/button';
import { Col } from '../../../external/reactstrap/col';
import { colors } from '../../../theme/global-style';

const noneTransform = css`
	-webkit-transform: translate3d(0);
	-moz-transform: translate3d(0);
	-ms-transform: translate3d(0);
	-o-transform: translate3d(0);
	transform: translate3d(0);
`;

const mediaBreakpointDownsm = css`
	@media (max-width: 576px) {
		width: 40px;
		height: 40px;
		right: 0;
		top: 0;
		left: auto;
		border-radius: 50%;
		z-index: 1100;
	}
`;

export const GcModal = styled.div<{ $maxIndex?: boolean }>`
	position: fixed;
	left: 0px;
	bottom: 0px;
	top: 0px;
	right: 0px;
	z-index: ${(props) => (props.$maxIndex ? 99999 : 1040)};
	${noneTransform}

	transition: all 1.5s ease-in-out;
	opacity: 0;
`;

export const GcModalOverlay = styled.div`
	position: fixed;
	left: 0px;
	bottom: 0px;
	top: 0px;
	right: 0px;
	background-color: rgba(256, 256, 256, 0.6);
	z-index: 1041;
	${noneTransform}
`;

export const GcModalClose = styled(Button)`
	position: absolute;
	width: 45px;
	height: 40px;
	background: ${colors.blue};
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	left: -45px;
	top: 10px;
	cursor: pointer;
	z-index: 1043;
	${mediaBreakpointDownsm}

	&:hover {
		background: lighten(${colors.blue}, 10%);
	}
`;
export const GcModalWraper = styled(Col)`
	background: #ffffff;
	z-index: 1042;
	height: 100vh;
	box-shadow: -2px 1px 6px 1px rgba(0, 30, 150, 0.2);
`;
export const GcModalContent = styled.div`
	height: 100%;
	position: relative;
	overflow-y: auto;
	z-index: 1044;

	&-inner {
	}
`;
