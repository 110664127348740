import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const PreauthorizationRate = lazyWithRetry(() => import('./pages/PreauthorizationRate'));
const PreauthorizationRateNew = lazyWithRetry(() => import('./pages/PreauthorizationRateNew'));
const PreauthorizationRateEdit = lazyWithRetry(() => import('./pages/PreauthorizationRateEdit'));

export const preauthorizationRateRoutes: { [key: number]: IRouteConfig } = {};

preauthorizationRateRoutes[routesIdMap.PreauthorizationRate] = {
	component: PreauthorizationRate,
	exact: true,
	id: routesIdMap.PreauthorizationRate,
	name: 'Preauthorization rates',
	menuText: 'Preauthorization',
	path: '/pricing/preauthorization-rate',
	permissions: getPricingPermission([PermissionsType.PricingPreauthorizationRate]),
	childrens: [routesIdMap.PreauthorizationRateNew, routesIdMap.PreauthorizationRateEdit],
	icon: 'file-powerpoint-o',
};

preauthorizationRateRoutes[routesIdMap.PreauthorizationRateNew] = {
	component: PreauthorizationRateNew,
	exact: true,
	isChild: true,
	id: routesIdMap.PreauthorizationRateNew,
	name: 'Preauthorization rate new',
	path: '/pricing/preauthorization-rate/new',
	permissions: getPricingPermission([PermissionsType.PricingPreauthorizationRate]),
	icon: 'plus',
	menuText: 'New preauthorization rate',
};

preauthorizationRateRoutes[routesIdMap.PreauthorizationRateEdit] = {
	component: PreauthorizationRateEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.PreauthorizationRateEdit,
	name: 'Preauthorization rate modify',
	path: '/pricing/preauthorization-rate/modify/:id',
	permissions: getPricingPermission([PermissionsType.PricingPreauthorizationRate]),
	icon: 'pencil',
};
