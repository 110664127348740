import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Customer = lazyWithRetry(() => import('./pages/Customer'));

export const customerRoutes: { [key: number]: IRouteConfig } = {};

customerRoutes[routesIdMap.BookingsNewCustomer] = {
	component: Customer,
	exact: true,
	id: routesIdMap.BookingsNewCustomer,
	name: 'Booking customer',
	path: '/booking/new/customer',
	permissions: [PermissionsType.BookingNew],
	icon: 'file-o',
};

customerRoutes[routesIdMap.BookingsNewCustomerSearch] = {
	component: Customer,
	exact: true,
	id: routesIdMap.BookingsNewCustomerSearch,
	name: 'Booking customer search',
	path: '/booking/new/customer-search',
	permissions: [PermissionsType.BookingNew],
	icon: 'file-o',
};

customerRoutes[routesIdMap.BookingsNewCustomerNew] = {
	component: Customer,
	exact: true,
	id: routesIdMap.BookingsNewCustomerNew,
	name: 'Booking customer new',
	path: '/booking/new/customer-new',
	permissions: [PermissionsType.BookingNew],
	icon: 'file-o',
};

customerRoutes[routesIdMap.BookingsNewCustomerGeneric] = {
	component: Customer,
	exact: true,
	id: routesIdMap.BookingsNewCustomerGeneric,
	name: 'Booking customer generic',
	path: '/booking/new/customer-generic',
	permissions: [PermissionsType.BookingNew],
	icon: 'file-o',
};
