import type { FC } from 'react';
import { useState } from 'react';

import { Link } from 'react-router-dom';

import { Dropdown, DropdownMenu, DropdownToggle } from '@crac/components/external/reactstrap/dropdown';
import type { IEmployee } from '@crac/core/models/entities/Employee';

import { routesIdMap } from '~/config/routesIdMap';
import { routes } from '~/features/routes';

interface IHeaderDropdownProps {
	employee: IEmployee;
}

export const HeaderDropdown: FC<IHeaderDropdownProps> = ({ employee }) => {
	const [dropdownOpen, setDropdownOpen] = useState(false);

	const toggle = (): void => {
		setDropdownOpen(!dropdownOpen);
	};

	return (
		<Dropdown isOpen={dropdownOpen} nav tag="div" toggle={toggle}>
			<DropdownToggle nav>
				<strong>
					<i className="fa fa-fw fa-user-o" />{' '}
					<span className="d-none d-sm-inline-block">
						{`${employee.name} (${employee.branchCode})`.toUpperCase()}
					</span>
				</strong>
			</DropdownToggle>
			<DropdownMenu>
				<Link className="dropdown-item" to={routes[routesIdMap.ChangePassword]?.path || '/'}>
					<i className="fa fa-fw fa-key" /> Change password
				</Link>
				{employee.allowedBranches.length > 1 ? (
					<Link className="dropdown-item" to={routes[routesIdMap.ChangeBranch]?.path || '/'}>
						<i className="fa fa-fw fa-home" /> Change branch
					</Link>
				) : null}
				<Link className="dropdown-item" to="/login">
					<i className="fa fa-fw fa-sign-out" /> Logout
				</Link>
			</DropdownMenu>
		</Dropdown>
	);
};
