import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import {
	cashLineClear,
	cashLineGetCurrent,
	cashLineModify,
	cashLineSign,
} from '@crac/core/redux/actions/CashLineActions';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import type { ICashLineReducerState } from '@crac/core/redux-store/reducersState/cash/CashReducerState';

const initialState: ICashLineReducerState = {
	cashLine: {
		data: undefined,
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const currentSlice = createSlice({
	name: 'CashLineCurrentSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CASH_LINE_GET_CURRENT
		builder
			.addCase(cashLineGetCurrent.pending, (state) => {
				return {
					...state,
					cashLine: {
						data: undefined,
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cashLineGetCurrent.fulfilled, (state, action) => {
				return {
					...state,
					cashLine: {
						data: deepCopy(action.payload) as any,
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(cashLineGetCurrent.rejected, (state, action) => {
				return {
					...state,
					cashLine: {
						data: undefined,
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// CASH_LINE_MODIFY
		builder.addCase(cashLineModify.fulfilled, (state, action) => {
			return {
				...state,
				cashLine: {
					data: deepCopy(action.payload),
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});
		// CASH_LINE_SIGN
		builder.addCase(cashLineSign.fulfilled, (state, action) => {
			return {
				...state,
				cashLine: {
					data: deepCopy(action.payload),
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});
		/**
		 * CLEAR CACHE
		 * CASH LINE CLEAR
		 */
		builder.addMatcher(isAnyOf(clearGlobalCache, cashLineClear), () => {
			return initialState;
		});
	},
});

export const currentReducer = currentSlice.reducer;
