import type { IDamageRepairCost } from '../../models/entities/DamageRepairCost';
import type { IDamageRepairCostInsertParams } from '../../models/serviceParams/DamageRepairCostParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { DamageRepairCostEndPoints } from '../endPoints/DamageRepairCostEndPoints';

export const damageRepairCostServiceInsert = (
	model: IDamageRepairCostInsertParams,
): Promise<ServiceResponse<IDamageRepairCost>> => {
	return Api.post<IDamageRepairCost, IDamageRepairCostInsertParams>(DamageRepairCostEndPoints.SAVE, model);
};
