export enum CountryEndPoints {
	/**
	 * Get country by code
	 * @host `/country/getbycode/`
	 */
	GET_BY_CODE = '/country/getbycode/',

	/**
	 * Get all country
	 * @host `/country/getbybooking/`
	 */
	GET_ALL = '/country/getall/',
}
