export enum RentRateFormulaEndPoints {
	/**
	 * @host `/rentRateFormula/search/`
	 */
	SEARCH = '/rentRateFormula/search/',

	/**
	 * @host `/rentRateFormula/insert/`
	 */
	INSERT = '/rentRateFormula/insert/',

	/**
	 * @host `/rentRateFormula/modify/`
	 */
	MODIFY = '/rentRateFormula/modify/',

	/**
	 * @host `/rentRateFormula/delete/`
	 */
	DELETE = '/rentRateFormula/delete/',
}
