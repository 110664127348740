import type { IPackageConfiguration } from '../../models/entities/PackageConfiguration';
import type {
	IPackageConfigurationDeactivateParams,
	IPackageConfigurationInsertParams,
	IPackageConfigurationModifyParams,
	IPackageConfigurationSearchParams,
} from '../../models/serviceParams/PackageConfigurationParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { PackageConfigurationEndPoints } from '../endPoints/PackageConfigurationEndPoints';

/**
 * Search package configurations
 * @param model IPackageConfigurationSearchParams
 * @returns IPackageConfiguration[]
 */
export const packageConfigurationServiceSearch = (
	model: IPackageConfigurationSearchParams,
): Promise<ServiceResponse<IPackageConfiguration[]>> => {
	return Api.get<IPackageConfiguration[], IPackageConfigurationSearchParams>(
		PackageConfigurationEndPoints.SEARCH,
		model,
	);
};

/**
 * Insert package configuration
 * @param model IPackageConfigurationInsertParams
 * @returns IPackageConfiguration[]
 */
export const packageConfigurationServiceInsert = (
	model: IPackageConfigurationInsertParams,
): Promise<ServiceResponse<IPackageConfiguration[]>> => {
	return Api.post<IPackageConfiguration[], IPackageConfigurationInsertParams>(
		PackageConfigurationEndPoints.INSERT,
		model,
	);
};

/**
 * Modify package configuration
 * @param model IPackageConfigurationModifyParams
 * @returns IPackageConfiguration
 */
export const packageConfigurationServiceModify = (
	model: IPackageConfigurationModifyParams,
): Promise<ServiceResponse<IPackageConfiguration>> => {
	return Api.post<IPackageConfiguration, IPackageConfigurationModifyParams>(
		PackageConfigurationEndPoints.MODIFY,
		model,
	);
};

/**
 * Deactivate package configuration
 * @param model IPackageConfigurationDeactivateParams
 * @returns IPackageConfiguration[]
 */
export const packageConfigurationServiceDeactivate = (
	model: IPackageConfigurationDeactivateParams,
): Promise<ServiceResponse<IPackageConfiguration[]>> => {
	return Api.post<IPackageConfiguration[], IPackageConfigurationDeactivateParams>(
		PackageConfigurationEndPoints.DEACTIVATE,
		model,
	);
};
