import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import { franchiseLineAddLinks } from '@crac/core/redux/actions/FranchiseLineActions';
import {
	linkClear,
	linkDocumentClear,
	linkGetByBudget,
	linkGetByFranchiseLine,
	linkGetByInvoice,
	linkGetByVehicle,
} from '@crac/core/redux/actions/LinkActions';
import type { ILinkReducerState } from '@crac/core/redux-store/reducersState/link';

const initialState: ILinkReducerState = {
	linkAddRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	links: [],
	documentLinks: [],
	linksRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const linkSlice = createSlice({
	name: 'LinkSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// GET BY FRANCHISE LINE
		builder
			.addCase(linkGetByFranchiseLine.pending, (state) => {
				return {
					...state,
					linksRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByFranchiseLine.fulfilled, (state, action) => {
				return {
					...state,
					links: deepCopy(action.payload),
					linksRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(linkGetByFranchiseLine.rejected, (state, action) => {
				return {
					...state,
					linksRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: true,
					},
				};
			});

		// ADD LINKS
		builder
			.addCase(franchiseLineAddLinks.pending, (state) => {
				return {
					...state,
					linkAddRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(franchiseLineAddLinks.fulfilled, (state, action) => {
				return {
					...state,
					linkAddRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					links: [...state.links, ...action.payload],
				};
			})
			.addCase(franchiseLineAddLinks.rejected, (state, action) => {
				return {
					...state,
					linkAddRequest: {
						inProgress: false,
						messages: deepCopy(action.payload) || [],
						ok: false,
					},
				};
			});
		// CLEAR BUDGET OR INVOICE LINKS
		builder.addCase(linkDocumentClear, (state) => {
			return {
				...state,
				documentLinks: [],
			};
		});
		// GET BY INVOICE FULFILLED
		builder.addCase(linkGetByInvoice.fulfilled, (state, action) => {
			return {
				...state,
				links: deepCopy(action.payload),
				linksRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});
		// GET BY INVOICE BUDGET
		builder.addCase(linkGetByBudget.fulfilled, (state, action) => {
			return {
				...state,
				documentLinks: action.payload ? [action.payload] : [],
				linksRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
			};
		});
		/* GET VEHICLE PROVISIONAL DOCUMENTATION */
		builder.addCase(linkGetByVehicle.fulfilled, (state, action) => {
			return {
				...state,
				linksRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				links: action.payload,
			};
		});

		/*
		 * GET BY INVOICE
		 * GET BY BUDGET
		 * GET VEHICLE PROVISIONAL DOCUMENTATION
		 */
		builder
			.addMatcher(
				isAnyOf(linkGetByInvoice.pending, linkGetByBudget.pending, linkGetByVehicle.pending),
				(state) => {
					return {
						...state,
						linksRequest: {
							inProgress: true,
							messages: [],
							ok: true,
						},
					};
				},
			)
			.addMatcher(
				isAnyOf(linkGetByInvoice.rejected, linkGetByBudget.rejected, linkGetByVehicle.rejected),
				(state, action) => {
					return {
						...state,
						linksRequest: {
							inProgress: false,
							messages: deepCopy(action.payload) || [],
							ok: true,
						},
					};
				},
			);
		/**
		 * CLEAR
		 * CLEAR CACHE
		 */
		builder.addMatcher(isAnyOf(linkClear, clearGlobalCache), (state) => {
			return {
				...state,
				links: [],
			};
		});
	},
});

export const linkReducer = linkSlice.reducer;
