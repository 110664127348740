import {
	oneWayDeleteService,
	oneWayServiceInsert,
	oneWayServiceModify,
	oneWayServiceSearch,
} from '../../data/services/OneWayService';
import type { IOneWay } from '../../models/entities/OneWay';
import type {
	IOneWayDeleteParams,
	IOneWayInsertParams,
	IOneWayModifyParams,
	IOneWaySearchParams,
} from '../../models/serviceParams/OneWayParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Searches for one-way records based on specified parameters.
 *
 * @param {IOneWay[]} payload - The one-way records to be retrieved.
 * @param {IOneWaySearchParams} params - Parameters for the search.
 * @returns {Promise<void>} - A promise that resolves to an array of one-way records.
 */
export const oneWaySearch = createAsyncAction<IOneWay[], IOneWaySearchParams>('oneWay/search', oneWayServiceSearch);

/**
 * Inserts new one-way records.
 *
 * @param {IOneWay[]} payload - The one-way records to be inserted.
 * @param {IOneWayInsertParams} params - Parameters for the insertion.
 * @returns {Promise<void>} - A promise that resolves after the one-way records are inserted.
 */
export const oneWayInsert = createAsyncAction<IOneWay[], IOneWayInsertParams>('oneWay/insert', oneWayServiceInsert);

/**
 * Modifies an existing one-way record.
 *
 * @param {IOneWay} payload - The one-way record to be modified.
 * @param {IOneWayModifyParams} params - Parameters for the modification.
 * @returns {Promise<void>} - A promise that resolves after the one-way record is modified.
 */
export const oneWayModify = createAsyncAction<IOneWay, IOneWayModifyParams>('oneWay/modify', oneWayServiceModify);

/**
 * Deletes a specific one-way record.
 *
 * @param {IOneWay} payload - The one-way record to be deleted.
 * @param {IOneWayDeleteParams} params - Parameters for the deletion.
 * @returns {Promise<void>} - A promise that resolves after the one-way record is deleted.
 */
export const oneWayDelete = createAsyncAction<IOneWay, IOneWayDeleteParams>('oneWay/delete', oneWayDeleteService);

/**
 * Clears the one-way state.
 *
 */
export const oneWayClear = createSyncAction('oneWay/clear');
