import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const RentRateFormula = lazyWithRetry(() => import('./pages/RentRateFormula'));
const RentRateFormulaNew = lazyWithRetry(() => import('./pages/RentRateFormulaNew'));
const RentRateFormulaEdit = lazyWithRetry(() => import('./pages/RentRateFormulaEdit'));

export const rentRateFormulaRoutes: { [key: number]: IRouteConfig } = {};

// FORMULAS
rentRateFormulaRoutes[routesIdMap.RentRateFormulas] = {
	component: RentRateFormula,
	exact: true,
	id: routesIdMap.RentRateFormulas,
	menuText: 'Formulas',
	name: 'Rent rate formulas',
	path: '/pricing/rent-rate-formulas',
	permissions: getPricingPermission([PermissionsType.PricingRentRateFormula]),
	childrens: [routesIdMap.RentRateFormulaNew, routesIdMap.RentRateFormulaEdit],
	icon: 'subscript',
};

rentRateFormulaRoutes[routesIdMap.RentRateFormulaNew] = {
	component: RentRateFormulaNew,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateFormulaNew,
	name: 'New rent rate formula',
	path: '/pricing/rent-rate-formulas/new',
	permissions: getPricingPermission([PermissionsType.PricingRentRateFormula]),
	icon: 'plus',
	menuText: 'New formula',
};

rentRateFormulaRoutes[routesIdMap.RentRateFormulaEdit] = {
	component: RentRateFormulaEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.RentRateFormulaEdit,
	name: 'Edit rent rate formula',
	path: '/pricing/rent-rate-formulas/modify/:id',
	permissions: getPricingPermission([PermissionsType.PricingRentRateFormula]),
	icon: 'pencil',
};
