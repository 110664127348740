import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Regularizations = lazyWithRetry(() => import('./pages/Regularizations'));

export const regularizationsRoutes: { [key: number]: IRouteConfig } = {};

regularizationsRoutes[routesIdMap.BookingRegularizations] = {
	component: Regularizations,
	exact: true,
	id: routesIdMap.BookingRegularizations,
	name: 'Regularizations',
	path: '/booking/regularizations',
	permissions: [PermissionsType.BookingRegularization],
	icon: 'check-square-o',
	menuText: 'Regularizations',
};
