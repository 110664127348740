import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const SaleService = lazyWithRetry(() => import('./pages/SaleService'));
const SaleServiceNew = lazyWithRetry(() => import('./pages/SaleServiceNew'));
const SaleServiceModify = lazyWithRetry(() => import('./pages/SaleServiceModify'));

export const saleServiceRoutes: { [key: number]: IRouteConfig } = {};

saleServiceRoutes[routesIdMap.FleetSaleService] = {
	component: SaleService,
	exact: true,
	id: routesIdMap.FleetSaleService,
	name: 'Sale services',
	menuText: 'Sale services',
	path: '/fleet/sale-services',
	permissions: [PermissionsType.SaleService],
	childrens: [routesIdMap.FleetSaleServiceNew, routesIdMap.FleetSaleServiceModify],
	icon: 'plus-circle',
};

saleServiceRoutes[routesIdMap.FleetSaleServiceNew] = {
	component: SaleServiceNew,
	exact: true,
	id: routesIdMap.FleetSaleServiceNew,
	name: 'New sale service',
	menuText: 'New sale service',
	path: '/fleet/sale-services/new',
	permissions: [PermissionsType.SaleService],
	childrens: [],
	icon: 'plus',
	isChild: true,
};

saleServiceRoutes[routesIdMap.FleetSaleServiceModify] = {
	component: SaleServiceModify,
	exact: true,
	id: routesIdMap.FleetSaleServiceModify,
	name: 'Modify sale service',
	path: '/fleet/sale-services/:id',
	permissions: [PermissionsType.SaleService],
	childrens: [],
	icon: 'pencil',
	isChild: true,
};
