import type { FC } from 'react';
import React, { useEffect } from 'react';

import { Login } from '@crac/components/intranet/shared/login';

import { useEmployeeLogin } from '../hooks/useEmployeeLogin';
import { useLoadCachedData } from '../hooks/useLoadCachedData';

type LoginFormProps = Partial<React.ComponentProps<typeof Login>> & {
	onLoginSuccess?: () => void;
};

export const LoginForm: FC<LoginFormProps> = ({ onLoginSuccess, ...props }) => {
	// Manage employee login
	const { handleLogin, isLoading, employee, isLoginSubmitted } = useEmployeeLogin();

	// Load cache data
	useLoadCachedData(employee, isLoginSubmitted);

	/**
	 * Dispatch success login
	 */
	useEffect(() => {
		if (!onLoginSuccess || !isLoginSubmitted || isLoading || !employee) {
			return;
		}

		onLoginSuccess();
	}, [employee, isLoading, isLoginSubmitted, onLoginSuccess]);

	/**
	 * Submit login form
	 * @param values employee form value
	 */
	const handleSubmit: LoginFormProps['onSubmit'] = (values) => {
		if (!values.password || !values.userName) {
			return;
		}

		handleLogin({ password: values.password, userName: values.userName, isPublic: true });
	};

	return (
		<Login
			inputs={{
				inputPassword: {
					name: 'password',
					placeholder: 'Password',
				},
				inputUserName: {
					name: 'userName',
					placeholder: 'User name',
				},
			}}
			isLoading={isLoading}
			onSubmit={handleSubmit}
			submitButton={{
				block: true,
			}}
			subtitle="Log in to your account"
			{...props}
		/>
	);
};
