import type { IVehicleColor } from '../../models/entities/VehicleColor';
import type {
	IFleetVehicleColorDeleteParams,
	IFleetVehicleColorInsertParams,
	IFleetVehicleColorModifyParams,
	IFleetVehicleColorSearchParams,
} from '../../models/serviceParams/FleetVehicleColorParams';
import { Api } from '../../modules/shared/api';
import { VehicleColorEndPoints } from '../endPoints/VehicleColorEndPoints';

export const fleetVehicleColorServiceSearch = (model: IFleetVehicleColorSearchParams) => {
	return Api.get<IVehicleColor[], IFleetVehicleColorSearchParams>(VehicleColorEndPoints.SEARCH, model);
};

export const fleetVehicleColorServiceInsert = (model: IFleetVehicleColorInsertParams) => {
	return Api.post<IVehicleColor, IFleetVehicleColorInsertParams>(VehicleColorEndPoints.INSERT, model);
};

export const fleetVehicleColorServiceModify = (model: IFleetVehicleColorModifyParams) => {
	return Api.post<IVehicleColor, IFleetVehicleColorModifyParams>(VehicleColorEndPoints.MODIFY, model);
};

export const fleetVehicleColorServiceDelete = (model: IFleetVehicleColorDeleteParams) => {
	return Api.post<IVehicleColor, IFleetVehicleColorDeleteParams>(VehicleColorEndPoints.DELETE, model);
};
