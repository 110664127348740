import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const CustomerDetailData = lazyWithRetry(() => import('./pages/Detail'));

export const dataRoutes: { [key: number]: IRouteConfig } = {};

dataRoutes[routesIdMap.CustomersDetail] = {
	component: CustomerDetailData,
	exact: true,
	id: routesIdMap.CustomersDetail,
	name: 'Detail',
	path: '/customers/detail/:id',
	permissions: [PermissionsType.CustomerDetail],
	icon: 'info-circle',
};
