import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Accident = lazyWithRetry(() => import('./pages/Accident'));
const AccidentNew = lazyWithRetry(() => import('./pages/AccidentNew'));
const AccidentModify = lazyWithRetry(() => import('./pages/AccidentModify'));

export const accidentRoutes: { [key: number]: IRouteConfig } = {};

accidentRoutes[routesIdMap.WorkshopAccident] = {
	component: Accident,
	exact: true,
	id: routesIdMap.WorkshopAccident,
	name: 'Accident',
	path: '/workshop/accident',
	icon: 'medkit',
	permissions: [PermissionsType.AccidentManagement],
	menuText: 'Accident',
};

accidentRoutes[routesIdMap.WorkshopAccidentNew] = {
	component: AccidentNew,
	exact: true,
	id: routesIdMap.WorkshopAccidentNew,
	name: 'New accident',
	path: '/workshop/accident/new',
	permissions: [PermissionsType.AccidentManagement],
	menuText: 'New accident',
	icon: 'plus',
};

accidentRoutes[routesIdMap.WorkshopAccidentModify] = {
	component: AccidentModify,
	exact: true,
	id: routesIdMap.WorkshopAccidentModify,
	name: 'Modify accident',
	path: '/workshop/accident/:documentNumber/:plateNumber/:key',
	permissions: [PermissionsType.AccidentManagement],
	icon: 'pencil',
};
