import type { IGroupOrderAndUpgrade } from '../../models/entities/VehicleGroupOrderAndUpgrade';
import type {
	IVehicleGroupOrderAndUpgradeDeleteParams,
	IVehicleGroupOrderAndUpgradeInsertParams,
	IVehicleGroupOrderAndUpgradeSearchParams,
} from '../../models/serviceParams/VehicleGroupOrderAndUpgradeParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { VehicleGroupOrderAndUpgradeEndPoints } from '../endPoints/VehicleGroupOrderAndUpgradeEndPoints';

/**
 * Search Group upgrade
 */
export const vehicleGroupOrderAndUpgradeSearchService = (
	model: IVehicleGroupOrderAndUpgradeSearchParams,
): Promise<ServiceResponse<IGroupOrderAndUpgrade[]>> => {
	return Api.get<IGroupOrderAndUpgrade[], IVehicleGroupOrderAndUpgradeSearchParams>(
		VehicleGroupOrderAndUpgradeEndPoints.SEARCH,
		model,
	);
};

/**
 * New Group upgrade
 */
export const vehicleGroupOrderAndUpgradeInsertService = (
	model: IVehicleGroupOrderAndUpgradeInsertParams,
): Promise<ServiceResponse<IGroupOrderAndUpgrade[]>> => {
	return Api.post<IGroupOrderAndUpgrade[], IVehicleGroupOrderAndUpgradeInsertParams>(
		VehicleGroupOrderAndUpgradeEndPoints.INSERT,
		model,
	);
};

/**
 * Modify Group upgrade
 */
export const vehicleGroupOrderAndUpgradeModifyService = (
	model: IVehicleGroupOrderAndUpgradeInsertParams,
): Promise<ServiceResponse<IGroupOrderAndUpgrade[]>> => {
	return Api.post<IGroupOrderAndUpgrade[], IVehicleGroupOrderAndUpgradeInsertParams>(
		VehicleGroupOrderAndUpgradeEndPoints.MODIFY,
		model,
	);
};

/**
 * Delete Group upgrade
 */
export const vehicleGroupOrderAndUpgradeDeleteService = (
	model: IVehicleGroupOrderAndUpgradeDeleteParams,
): Promise<ServiceResponse<IGroupOrderAndUpgrade>> => {
	return Api.post<IGroupOrderAndUpgrade, IVehicleGroupOrderAndUpgradeDeleteParams>(
		VehicleGroupOrderAndUpgradeEndPoints.DELETE,
		model,
	);
};
