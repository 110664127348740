import { createSlice } from '@reduxjs/toolkit';

import { clearGlobalCache } from '@crac/core/redux/actions/CommonActions';
import {
	vehicleGroupOrderAndUpgradeAssistDelete,
	vehicleGroupOrderAndUpgradeAssistModify,
	vehicleGroupOrderAndUpgradeAssistSearch,
	vehicleGroupOrderAndUpgradeClear,
	vehicleGroupOrderAndUpgradeDelete,
	vehicleGroupOrderAndUpgradeInsert,
	vehicleGroupOrderAndUpgradeModify,
	vehicleGroupOrderAndUpgradeSearch,
} from '@crac/core/redux/actions/VehicleGroupOrderAndUpgradeActions';
import type { IVehicleGroupOrderAndUpgradeReducerState } from '@crac/core/redux-store/reducersState/pricing/VehicleGroupOrderAndUpgradeReducerState';

const initialState: IVehicleGroupOrderAndUpgradeReducerState = {
	vehicleGroupsOrderAndUpgrade: [],
	vehicleGroupOrderAndUpgradeDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupOrderAndUpgradeInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupOrderAndUpgradeModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupOrderAndUpgradeSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupOrderAndUpgradeAssistSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupOrderAndUpgradeAssistDeleteRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	vehicleGroupOrderAndUpgradeAssistModifyRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	assistantGroupsOrderAndUpgrade: [],
};

const vehicleGroupOrderAndUpgradeSlice = createSlice({
	name: 'vehicleGroupOrderAndUpgrade',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(clearGlobalCache, () => {
			return {
				...initialState,
				vehicleGroupsOrderAndUpgrade: [],
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeClear, () => {
			return {
				...initialState,
				vehicleGroupsOrderAndUpgrade: [],
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeSearch.pending, (state) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeSearchRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeSearch.rejected, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeSearchRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeSearch.fulfilled, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeSearchRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				vehicleGroupsOrderAndUpgrade: action.payload,
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeInsert.pending, (state) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeInsertRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeInsert.rejected, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeInsertRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeInsert.fulfilled, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeInsertRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				assistantGroupsOrderAndUpgrade: state.assistantGroupsOrderAndUpgrade
					? [...state.assistantGroupsOrderAndUpgrade, ...action.payload].sort(
							(acc, curr) => acc.order - curr.order,
						)
					: action.payload,
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeModify.pending, (state) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeModifyRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeModify.rejected, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeModifyRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeModify.fulfilled, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeModifyRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				vehicleGroupsOrderAndUpgrade: state.vehicleGroupsOrderAndUpgrade
					? [
							...state.vehicleGroupsOrderAndUpgrade.filter(
								(group) => group.vehicleGroupDelivered !== action.payload[0].vehicleGroupDelivered,
							),
							...action.payload,
						].sort((acc, curr) => acc.order - curr.order)
					: action.payload,
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeDelete.pending, (state) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeDeleteRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeDelete.rejected, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeDeleteRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeDelete.fulfilled, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeDeleteRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				vehicleGroupsOrderAndUpgrade: state.vehicleGroupsOrderAndUpgrade
					? state.vehicleGroupsOrderAndUpgrade.filter(
							(group) => group.vehicleGroupDelivered !== action.payload.vehicleGroupDelivered,
						)
					: [],
			};
		});
		// ASSIST
		builder.addCase(vehicleGroupOrderAndUpgradeAssistSearch.pending, (state) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeAssistSearchRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeAssistSearch.rejected, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeAssistSearchRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeAssistSearch.fulfilled, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeAssistSearchRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				assistantGroupsOrderAndUpgrade: action.payload,
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeAssistModify.pending, (state) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeAssistModifyRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeAssistModify.rejected, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeAssistModifyRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeAssistModify.fulfilled, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeAssistModifyRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				assistantGroupsOrderAndUpgrade: state.assistantGroupsOrderAndUpgrade
					? [
							...state.assistantGroupsOrderAndUpgrade.filter(
								(group) => group.vehicleGroupDelivered !== action.payload[0].vehicleGroupDelivered,
							),
							...action.payload,
						].sort((acc, curr) => acc.order - curr.order)
					: action.payload,
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeAssistDelete.pending, (state) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeAssistDeleteRequest: {
					inProgress: true,
					messages: [],
					ok: true,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeAssistDelete.rejected, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeAssistDeleteRequest: {
					inProgress: false,
					messages: action.payload || [],
					ok: false,
				},
			};
		});
		builder.addCase(vehicleGroupOrderAndUpgradeAssistDelete.fulfilled, (state, action) => {
			return {
				...state,
				vehicleGroupOrderAndUpgradeAssistDeleteRequest: {
					inProgress: false,
					messages: [],
					ok: true,
				},
				assistantGroupsOrderAndUpgrade: state.assistantGroupsOrderAndUpgrade
					? state.assistantGroupsOrderAndUpgrade.filter(
							(group) => group.vehicleGroupDelivered !== action.payload.vehicleGroupDelivered,
						)
					: [],
			};
		});
	},
});

export const vehicleGroupOrderAndUpgradeReducer = vehicleGroupOrderAndUpgradeSlice.reducer;
