import { SaleMarginEndPoints } from './SaleMarginEndpoints';
import { Api } from '../../../shared/api';
import type { IServiceBaseParams } from '../../../shared/types/ServiceBaseParams';
import type { ISaleMargin } from '../entities/SaleMargin';

export interface ISaleMarginGetMarginParams extends IServiceBaseParams {
	company: string;
	quote: string;
	branch?: string;
	brand?: string;
	color?: string;
	country?: string;
	frameNumber?: string;
	group?: string;
	km?: string;
	model?: string;
	plateNumber?: string;
	registration?: string;
}

export const saleMarginGetMarginService = (model: ISaleMarginGetMarginParams) => {
	return Api.get<ISaleMargin, ISaleMarginGetMarginParams>(SaleMarginEndPoints.GET_MARGIN, model);
};
