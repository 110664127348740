import type { IExpedient } from '../../models/entities/Expedient';
import type {
	IExpedientAddToVehicleParams,
	IExpedientGetByVehicleParams,
} from '../../models/serviceParams/ExpedientParams';
import { Api } from '../../modules/shared/api';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { ExpedientEndPoints } from '../endPoints/ExpedientEndPoints';

/**
 * Get list fine by contract
 *  {string} message Comment message
 *  {string} plateNumber Plate number
 */
export const expedientServiceAddToVehicle = (
	model: IExpedientAddToVehicleParams,
): Promise<ServiceResponse<IExpedient>> => {
	return Api.post<IExpedient, IExpedientAddToVehicleParams>(ExpedientEndPoints.ADD, model);
};

export const expedientServiceGetByVehicle = (model: IExpedientGetByVehicleParams) => {
	return Api.get<IExpedient[], IExpedientGetByVehicleParams>(ExpedientEndPoints.GET_BY_VEHICLE, model);
};
