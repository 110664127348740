export enum VehicleEndPoints {
	/**
	 * Vehicle search
	 * @host `/vehicle/search/`
	 */
	SEARCH = '/vehicle/search/',

	/**
	 * Vehicle get by plate number
	 * @host `/vehicle/getbyplatenumber/`
	 */
	GET_BY_PLATE = '/vehicle/getbyplatenumber/',

	/**
	 * Vehicle get ready by branch
	 * @host `/vehicle/getreadybycurrentbranch/`
	 */
	GET_READY_BY_CURRENT_BRANCH = '/vehicle/getreadybycurrentbranch/',

	/**
	 * Vehicle update OBE
	 * @host `/vehicle/updateobe/`
	 */
	UPDATE_OBE = '/vehicle/updateobe/',

	/**
	 * Vehicle assign Tag
	 * @host `/vehicle/assignTag/`
	 */
	ASSIGN_TAG = '/vehicle/assignTag/',

	/**
	 * Vehicle reception
	 * @host `/vehicle/reception/`
	 */
	RECEPTION = '/vehicle/reception/',

	/**
	 * VEhicle return
	 * @host `/vehicle/return/`
	 */
	RETURN = '/vehicle/return/',

	/**
	 * Vehicle get PDF file
	 * @host `/vehicle/getpdffile/`
	 */
	GET_KEY_CHAINS_LABELS_GETPDF = '/vehicle/getpdffile/',

	/**
	 * Vehicle change state
	 * @host `/vehicle/changestate/`
	 */
	CHANGE_STATE = '/vehicle/changestate/',

	/**
	 * Vehicle get by state
	 * @host `/vehicle/getvehiclestates`
	 */
	GET_STATES = '/vehicle/getvehiclestates/',

	/**
	 * Vehicle get by contract
	 * @host `/vehicle/getbycontract`
	 */
	GET_LINES_BY_CONTRACT = '/vehicle/getbycontract/',

	/**
	 * Vehicle line get by booking number
	 * @host `/vehicleline/getbybookingnumber`
	 */
	GET_VEHICLE_LINES_BY_BOOKING = '/vehicleline/getbybookingnumber/',

	/**
	 * Vehicle line get by vehicle
	 * @host `/vehicleline/getbyvehicle/`
	 */
	GET_VEHICLE_LINES_BY_VEHICLE = '/vehicleline/getbyvehicle/',

	/**
	 * Vehicle line get last by vehicle
	 */
	GET_LAST_VEHICLE_LINE_BY_VEHICLE = '/vehicleline/getlastbyvehicle',

	/**
	 * get vehicle movements by plateNumber
	 * @host `/vehicleLine/getExternalRepairsByVehicle`
	 */
	GET_EXTERNAL_REPAIRS_BY_VEHICLE = '/vehicleLine/getExternalRepairsByVehicle',

	/**
	 * Vehicle preparation
	 * @host `/vehicle/preparation`
	 */
	PREPARATION = '/vehicle/preparation',

	/**
	 * Vehicle block
	 * @host `/vehicle/block`
	 */
	BLOCK_VEHICLE = '/vehicle/block',
	/**
	 * Vehicle Open
	 * @host `/vehicle/open`
	 */
	OPEN = '/vehicle/open',
	/**
	 * Vehicle close
	 * @host `/vehicle/close`
	 */
	CLOSE = '/vehicle/close',

	/**
	 * Vehicle Open
	 * @host `/vehicle/unlock`
	 */
	UNLOCK = '/vehicle/unlock',
	UNLOCK_FITUR = '/vehicle/unlockFitur',
	/**
	 * Vehicle close
	 * @host `/vehicle/lock`
	 */
	LOCK = '/vehicle/lock',
	LOCK_FITUR = '/vehicle/lockFitur',
	SEARCH_FLEET = '/vehicle/searchFleet',

	/**
	 * Change vehicle group
	 * @host `/vehicle/changeVehicleGroup`
	 */
	CHANGE_VEHICLE_GROUP = '/vehicle/changeVehicleGroup',

	/**
	 * Change defleet date
	 * @host `/vehicle/changeExpectedDefleetDate`
	 */
	CHANGE_DEFLEET_DATE = '/vehicle/changeExpectedDefleetDate',

	/**
	 * Change agreed repurchase date
	 * @host `/vehicle/changeAgreedRepurchaseDate`
	 */
	CHANGE_AGREED_REPURCHASE_DATE = '/vehicle/changeAgreedRepurchaseDate',

	/**
	 * Change end amortization date
	 * @host `/vehicle/changeAmortizationEndDate`
	 */
	CHANGE_END_AMORTIZATION_DATE = '/vehicle/changeAmortizationEndDate',

	/**
	 * Change next ITV date
	 * @host `/vehicle/changeNextITVDate`
	 */
	CHANGE_NEXT_ITV_DATE = '/vehicle/changeNextITVDate',

	/**
	 * Change rent start date
	 * @host `/vehicle/changeRentStartDate`
	 */
	CHANGE_RENT_START_DATE = '/vehicle/changeRentStartDate',

	/**
	 * Change rent end date
	 * @host `/vehicle/changeRentEndDate`
	 */
	CHANGE_RENT_END_DATE = '/vehicle/changeRentEndDate',

	/**
	 * Change unregister traffic date
	 * @host `/vehicle/changeUnregisterTrafficDate`
	 */
	CHANGE_UNREGISTER_TRAFFIC_DATE = '/vehicle/changeUnregisterTrafficDate',

	/**
	 * Update keys location
	 * @host `/vehicle/updateKeysLocation`
	 */
	UPDATE_KEYS_LOCATION = '/vehicle/updateKeysLocation',

	/**
	 * Set vehicle revisions
	 * @host `/vehicle/revision`
	 */
	REVISION = '/vehicle/revision',

	/**
	 * Change last revision km
	 * @host `/vehicle/changeLastRevisionKm`
	 */
	CHANGE_LAST_REVISION_KM = '/vehicle/changeLastRevisionKm',

	/**
	 * Change fleet typology
	 * @host `/vehicle/changeFleetTypology`
	 */
	CHANGE_FLEET_TYPOLOGY = '/vehicle/changeFleetTypology',

	/**
	 * Change carrier
	 * @host `/vehicle/changeCarrier`
	 */
	CHANGE_CARRIER = '/vehicle/changeCarrier',

	/**
	 * Change registration date
	 * @host `/vehicle/changeRegistrationDate`
	 */
	CHANGE_REGISTRATION_DATE = '/vehicle/changeRegistrationDate',

	/**
	 * Change brand parking
	 * @host `/vehicle/changeBrandParking`
	 */
	CHANGE_BRAND_PARKING = '/vehicle/changeBrandParking',

	/**
	 * Change sale amount
	 * @host `/vehicle/changeSaleAmount`
	 */
	CHANGE_SALE_AMOUNT = '/vehicle/changeSaleAmount',
}
