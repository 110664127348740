import type { IRepairType } from '../../models/entities/RepairType';
import { Api } from '../../modules/shared/api';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';
import type { ServiceResponse } from '../../modules/shared/types/ServiceResponse';
import { RepairTypeEndPoints } from '../endPoints/RepairTypeEndPoints';

/**
 * Get all spending types
 * @return {Promise} `Array<RepairTypePropType>`
 */
export const repairTypeServiceGetAll = (model: IServiceBaseParams): Promise<ServiceResponse<IRepairType[]>> => {
	return Api.get<IRepairType[], IServiceBaseParams>(RepairTypeEndPoints.GET_ALL, model);
};
