import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

import { getPricingPermission } from '../utils/helpers';

const MaximumExcessRate = lazyWithRetry(() => import('./pages/MaximumExcessRate'));
const MaximumExcessRateNew = lazyWithRetry(() => import('./pages/MaximumExcessRateNew'));
const MaximumExcessRateEdit = lazyWithRetry(() => import('./pages/MaximumExcessRateEdit'));

export const maximumExcessRateRoutes: { [key: number]: IRouteConfig } = {};

// EXCESS RATE
maximumExcessRateRoutes[routesIdMap.MaximumExcessRate] = {
	component: MaximumExcessRate,
	exact: true,
	id: routesIdMap.MaximumExcessRate,
	name: 'Maximum excess rates',
	menuText: 'Excess',
	path: '/pricing/excess-rate',
	permissions: getPricingPermission([PermissionsType.PricingMaximumExcessRate]),
	childrens: [routesIdMap.MaximumExcessRateNew, routesIdMap.MaximumExcessRateEdit],
	icon: 'bolt',
};

maximumExcessRateRoutes[routesIdMap.MaximumExcessRateNew] = {
	component: MaximumExcessRateNew,
	exact: true,
	isChild: true,
	id: routesIdMap.MaximumExcessRateNew,
	name: 'New maximum excess rate',
	path: '/pricing/excess-rate/new',
	permissions: getPricingPermission([PermissionsType.PricingMaximumExcessRate]),
	icon: 'plus',
	menuText: 'New maximum excess rate',
};

maximumExcessRateRoutes[routesIdMap.MaximumExcessRateEdit] = {
	component: MaximumExcessRateEdit,
	exact: true,
	isChild: true,
	id: routesIdMap.MaximumExcessRateEdit,
	name: 'Maximum excess rate modify',
	path: '/pricing/excess-rate/modify/:id',
	permissions: getPricingPermission([PermissionsType.PricingMaximumExcessRate]),
	icon: 'pencil',
};
