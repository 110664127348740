import { customerRoutes } from './customer/routes';
import { extraDriversRoutes } from './extraDrivers/routes';
import { invoiceToRoutes } from './invoiceTo/routes';
import { paymentRoutes } from './payment/routes';
import { registerContractRoutes } from './registerContract/routes';
import { searchRoutes } from './search/routes';
import { servicesRoutes } from './services/routes';
import { startRoutes } from './start/routes';
import { vehicleRoutes } from './vehicle/routes';

export const pickUpRoutes = {
	...customerRoutes,
	...extraDriversRoutes,
	...invoiceToRoutes,
	...paymentRoutes,
	...registerContractRoutes,
	...searchRoutes,
	...servicesRoutes,
	...startRoutes,
	...vehicleRoutes,
};
