import { createSlice } from '@reduxjs/toolkit';

import {
	preauthorizationRateClear,
	preauthorizationRateDeactivate,
	preauthorizationRateInsert,
	preauthorizationRateSearch,
} from '@crac/core/redux/actions/PreauthorizationRateActions';
import type { IPreauthorizationRateReducerState } from '@crac/core/redux-store/reducersState/pricing/PreauthorizationRateReducerState';
const initialState: IPreauthorizationRateReducerState = {
	preauthorizationRateInsertRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	preauthorizationRates: [],
	preauthorizationRateSearchRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
	preauthorizationRateDeactivateRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const preauthorizationRateSlice = createSlice({
	name: 'PreauthorizationRateSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CLEAR
		builder.addCase(preauthorizationRateClear, () => {
			return initialState;
		});
		// SEARCH
		builder
			.addCase(preauthorizationRateSearch.pending, (state) => {
				return {
					...state,
					preauthorizationRateSearchRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(preauthorizationRateSearch.fulfilled, (state, action) => {
				return {
					...state,
					preauthorizationRateSearchRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					preauthorizationRates: action.payload,
				};
			})
			.addCase(preauthorizationRateSearch.rejected, (state, action) => {
				return {
					...state,
					preauthorizationRateSearchRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// INSERT
		builder
			.addCase(preauthorizationRateInsert.pending, (state) => {
				return {
					...state,
					preauthorizationRateInsertRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(preauthorizationRateInsert.fulfilled, (state) => {
				return {
					...state,
					preauthorizationRateInsertRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(preauthorizationRateInsert.rejected, (state, action) => {
				return {
					...state,
					preauthorizationRateInsertRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// DEACTIVATE
		builder
			.addCase(preauthorizationRateDeactivate.pending, (state) => {
				return {
					...state,
					preauthorizationRateDeactivateRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(preauthorizationRateDeactivate.fulfilled, (state, action) => {
				return {
					...state,
					preauthorizationRateDeactivateRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
					preauthorizationRates: [
						...state.preauthorizationRates.filter(
							({ id }) => !action.payload.map((item) => item.id).includes(id),
						),
						...action.payload,
					],
				};
			})
			.addCase(preauthorizationRateDeactivate.rejected, (state, action) => {
				return {
					...state,
					preauthorizationRateDeactivateRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
	},
});

export const preauthorizationRateReducer = preauthorizationRateSlice.reducer;
