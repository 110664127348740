import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import { deepCopy } from '@crac/core/helpers/commons';
import {
	customerBlock,
	customerClearSearch,
	customerSearch,
	customerSearchByField,
} from '@crac/core/redux/actions/CustomerActions';
import type { ICustomerSearchReducerState } from '@crac/core/redux-store/reducersState/customer/SearchReducerState';
const initialState: ICustomerSearchReducerState = {
	customers: [],
	customersRequest: {
		inProgress: false,
		messages: [],
		ok: true,
	},
};

const searchSlice = createSlice({
	name: 'CustomerSearchSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// CUSTOMER_SEARCH
		builder
			.addCase(customerSearch.pending, (state) => {
				return {
					...state,
					customers: [],
					customersRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerSearch.fulfilled, (state, action) => {
				return {
					...state,
					customers: deepCopy(action.payload),
					customersRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerSearch.rejected, (state, action) => {
				return {
					...state,
					customersRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// BLOCK
		builder
			.addCase(customerBlock.pending, (state) => {
				return {
					...state,
					customersRequest: {
						inProgress: true,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerBlock.fulfilled, (state, action) => {
				return {
					...state,
					customers: state.customers.map((customer) =>
						customer.code === action.payload.code ? action.payload : customer,
					),
					customersRequest: {
						inProgress: false,
						messages: [],
						ok: true,
					},
				};
			})
			.addCase(customerBlock.rejected, (state, action) => {
				return {
					...state,
					customersRequest: {
						inProgress: false,
						messages: action.payload || [],
						ok: false,
					},
				};
			});
		// SEARCH BY FIELD
		builder
			.addCase(customerSearchByField.pending, (state) => {
				return {
					...state,
					customersRequest: {
						inProgress: true,
						ok: true,
						messages: [],
					},
				};
			})
			.addCase(customerSearchByField.fulfilled, (state, action) => {
				return {
					...state,
					customers: action.payload,
					customersRequest: {
						inProgress: false,
						ok: true,
						messages: [],
					},
				};
			})
			.addCase(customerSearchByField.rejected, (state, action) => {
				return {
					...state,
					customersRequest: {
						inProgress: false,
						ok: false,
						messages: action.payload || [],
					},
				};
			});

		/**
		 * CLEAR_CACHE
		 * CLEAR
		 */
		builder.addMatcher(isAnyOf(customerClearSearch, customerClearSearch), () => {
			return initialState;
		});
	},
});

export const searchReducer = searchSlice.reducer;
