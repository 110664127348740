import {
	vehicleLineServiceGetByBooking,
	vehicleLineServiceGetByVehicle,
	vehicleLineServiceGetExternalRepairsByVehicle,
	vehicleLineServiceGetLastByVehicle,
} from '../../data/services/VehicleLineService';
import type { IVehicleLine } from '../../models/entities/VehicleLine';
import type {
	IVehicleLineGetByBookingParams,
	IVehicleLineGetByVehicleParams,
	IVehicleLineGetExternalRepairsByVehicleParams,
	IVehicleLineGetLastByVehicleParams,
} from '../../models/serviceParams/VehicleLineParams';
import { createSyncAction } from '../../modules/shared/state/createAction';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves vehicle lines associated with a specific booking.
 *
 * @param {IVehicleLine[]} payload - The vehicle lines to be retrieved.
 * @param {IVehicleLineGetByBookingParams} params - Parameters for identifying vehicle lines related to a specific booking.
 * @returns {Promise<void>} - A promise that resolves to an array of vehicle lines.
 */
export const vehicleLinesGetByBooking = createAsyncAction<IVehicleLine[], IVehicleLineGetByBookingParams>(
	'vehicleLine/getByBooking',
	vehicleLineServiceGetByBooking,
);

/**
 * Retrieves vehicle lines associated with a specific vehicle.
 *
 * @param {IVehicleLine[]} payload - The vehicle lines to be retrieved.
 * @param {IVehicleLineGetByVehicleParams} params - Parameters for identifying vehicle lines related to a specific vehicle.
 * @returns {Promise<void>} - A promise that resolves to an array of vehicle lines.
 */
export const vehicleLinesGetByVehicle = createAsyncAction<IVehicleLine[], IVehicleLineGetByVehicleParams>(
	'vehicleLine/getByVehicle',
	vehicleLineServiceGetByVehicle,
);

/**
 * Retrieves the last vehicle line associated with a specific vehicle.
 *
 * @param {IVehicleLine} payload - The last vehicle line to be retrieved.
 * @param {IVehicleLineGetLastByVehicleParams} params - Parameters for identifying the last vehicle line related to a specific vehicle.
 * @returns {Promise<void>} - A promise that resolves to the last vehicle line.
 */
export const vehicleLinesGetLastByVehicle = createAsyncAction<IVehicleLine, IVehicleLineGetLastByVehicleParams>(
	'vehicleLine/getLastByVehicle',
	vehicleLineServiceGetLastByVehicle,
);

/**
 * Resets the vehicle line state.
 */
export const vehicleLinesReset = createSyncAction('vehicleLine/reset');

export const vehicleLineGetExternalRepairsByVehicle = createAsyncAction<
	IVehicleLine[],
	IVehicleLineGetExternalRepairsByVehicleParams
>('vehicleLine/GetExternalRepairsByVehicle', vehicleLineServiceGetExternalRepairsByVehicle);
