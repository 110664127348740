/* eslint-disable class-methods-use-this */
import React from 'react';

import classNames from 'classnames';
import { NavLink } from 'react-router-dom';

import { sidebarCssClasses } from '@crac/core/helpers/classes';

import { SidebarFooter } from './SidebarFooter';
import { SidebarForm } from './SidebarForm';
import { SidebarHeader } from './SidebarHeader';
import { SidebarMinimizer } from './SidebarMinimizer';
import type { IBadgeItemProps, IClassItem, INavItem, ISidebarProps } from './types';
import { Badge } from '../../../../external/reactstrap/badge';
import { Nav } from '../../../../external/reactstrap/nav/Nav';
import { NavItem } from '../../../../external/reactstrap/nav/NavItem';
// eslint-disable-next-line import/order
import { NavLink as RsNavLink } from '../../../../external/reactstrap/nav/NavLink';

// TODO: SEARCH OtHER OPTIONS import OutsideClickHandler from 'react-outside-click-handler';

import { sidebarToggle } from '../layout-helper';
export type { INavItem, INavegationConfig } from './types';

class AppSidebarContent extends React.Component<ISidebarProps> {
	constructor(props: ISidebarProps) {
		super(props);

		this.state = {};
		this.handleClickOut = this.handleClickOut.bind(this);
		this.isActiveRoute = this.isActiveRoute.bind(this);
		this.title = this.title.bind(this);
		this.navLink = this.navLink.bind(this);
		this.navItem = this.navItem.bind(this);
		this.navDropdown = this.navDropdown.bind(this);
	}

	componentDidMount(): void {
		const { offCanvas, compact, minimized, fixed, display } = this.props;
		this.isOffCanvas(offCanvas);
		this.isCompact(compact);
		this.isMinimized(minimized);
		this.isFixed(fixed);
		this.displayBreakpoint(display);
	}

	displayBreakpoint = (display?: string): void => {
		const cssTemplate = `sidebar-${display}-show`;
		const [sidebarCssClasses$] = sidebarCssClasses;
		let [cssClass] = sidebarCssClasses$;

		if (display && sidebarCssClasses.indexOf(cssTemplate) > -1) {
			cssClass = cssTemplate;
		}
		document.body.classList.add(cssClass);
	};

	isFixed = (fixed?: boolean): void => {
		if (fixed) {
			document.body.classList.add('sidebar-fixed');
		}
	};

	isMinimized = (minimized?: boolean): void => {
		sidebarToggle(minimized);
	};

	isOffCanvas = (offCanvas?: boolean): void => {
		if (offCanvas) {
			document.body.classList.add('sidebar-off-canvas');
		}
	};

	isCompact = (compact?: boolean): void => {
		if (compact) {
			document.body.classList.add('sidebar-compact');
		}
	};

	hideMobile = (): void => {
		if (document.body.classList.contains('sidebar-show')) {
			document.body.classList.remove('sidebar-show');
		}
	};

	handleClickOut(event: React.MouseEvent<HTMLElement>): void {
		const element = event.target as HTMLElement;

		if (typeof window !== 'undefined' && document.body.classList.contains('sidebar-show')) {
			if (!element.closest('[data-sidebar-toggler]')) {
				this.hideMobile();
			}
		}
	}

	// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
	handleClick = (event: any): void => {
		event.preventDefault();
		event.target.parentElement.classList.toggle('open');
	};

	isActiveRoute(routeName?: string): boolean {
		const { location } = this.props;

		if (!routeName) {
			return false;
		}

		return location.pathname.indexOf((routeName as string).toLocaleLowerCase()) > -1;
	}

	// Badge addon to NavItem
	badge = (item: IBadgeItemProps): JSX.Element | null => {
		if (item) {
			const classes = classNames(item.class);
			return (
				<Badge className={classes} color={item.variant}>
					{item.text}
				</Badge>
			);
		}

		return null;
	};

	// Simple wrapper for nav-title item
	wrapper = (item: INavItem): string | React.ReactNode => {
		return item.wrapper && item.wrapper.element
			? React.createElement(item.wrapper.element, item.wrapper.attributes, item.name)
			: item.name;
	};

	// Nav list section title
	title(item: INavItem, key: number): JSX.Element {
		const classes = classNames('nav-title', item.class);
		return (
			<li className={classes} key={key}>
				{this.wrapper(item)}{' '}
			</li>
		);
	}

	// Nav list divider
	divider = (item: INavItem, key: number): JSX.Element => {
		const classes = classNames('divider', item.class);
		return <li className={classes} key={key} />;
	};

	isExternal = (url: string): boolean => {
		const link = url ? url.substring(0, 4) : '';
		return link === 'http';
	};

	// Nav link
	navLink(item: INavItem, key: number, classes: IClassItem): JSX.Element {
		const url = item.url ? item.url : '';
		return (
			<NavItem className={classes.item} key={key}>
				{this.isExternal(url) ? (
					<RsNavLink active className={classes.link} href={url}>
						<>
							<i className={classes.icon} />
							{item.name}
							{item.badge ? this.badge(item.badge) : null}
						</>
					</RsNavLink>
				) : (
					<NavLink className={({ isActive }) => `${classes.link} ${isActive ? 'active' : ''}`} to={url}>
						<>
							<i className={classes.icon} />
							{item.name}
							{item.badge ? this.badge(item.badge) : null}
						</>
					</NavLink>
				)}
			</NavItem>
		);
	}

	// Nav item with nav link
	navItem(item: INavItem, key: number): JSX.Element {
		const classes: IClassItem = {
			icon: classNames(item.icon),
			item: classNames(item.class),
			link: classNames('nav-link', item.variant ? `nav-link-${item.variant}` : ''),
		};

		return this.navLink(item, key, classes);
	}

	// Nav type
	navType = (item: INavItem, idx: number): JSX.Element => {
		if (item.title) {
			return this.title(item, idx);
		} else if (item.divider) {
			return this.divider(item, idx);
		} else if (item.children) {
			return this.navDropdown(item, idx);
		}

		return this.navItem(item, idx);
	};

	// Nav dropdown
	navDropdown(item: INavItem, key: number): JSX.Element {
		return (
			<li className={`nav-item nav-dropdown ${this.isActiveRoute(item.name) ? 'open' : ''}`} key={key}>
				<a className="nav-link nav-dropdown-toggle" href="#" onClick={this.handleClick.bind(this)}>
					<i className={item.icon} />
					{item.name}
				</a>

				<ul className="nav-dropdown-items">{this.navList(item.children as INavItem[])}</ul>
			</li>
		);
	}

	// Nav list
	navList = (items: INavItem[]): JSX.Element[] => {
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		return items.map((item, index) => this.navType(item, index));
	};

	render(): JSX.Element {
		const { renderSidebarHeader, items } = this.props;
		return (
			// <OutsideClickHandler onOutsideClick={this.handleClickOut}>
			<div className="sidebar">
				{renderSidebarHeader ? (
					<SidebarHeader className="p-0 d-block d-sm-none">{renderSidebarHeader()}</SidebarHeader>
				) : null}
				<SidebarForm />
				<nav className="scrollbar-container sidebar-nav">
					<Nav>{this.navList(items)}</Nav>
				</nav>
				<SidebarFooter />
				<SidebarMinimizer />
			</div>
			// </OutsideClickHandler>
		);
	}
}

export const AppSidebar = AppSidebarContent;
