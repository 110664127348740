export enum PartnerGroupEndPoints {
	/**
	 * Get partnerCategory by code
	 * @host `/partnerCategory/getByCode`
	 */
	GET_BY_CODE = '/partnerGroup/getByCode',
	/**
	 * Get all partnerGroup
	 * @host `/partnerGroup/getAll`
	 */
	GET_ALL = '/partnerGroup/getAll',
	/**
	 * Search partnerGroup
	 * @host `/partnerGroup/search`
	 */
	SEARCH = '/partnerGroup/search',
	/**
	 * Insert partnerGroup
	 * @host `/partnerGroup/insert`
	 */
	INSERT = '/partnerGroup/insert',
	/**
	 * Modify partnerGroup
	 * @host `/partnerGroup/modify`
	 */
	MODIFY = '/partnerGroup/modify',
}
