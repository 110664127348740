import React from 'react';

import { createPortal } from 'react-dom';

interface IReactGenericPortalProps {
	portalId: string;
	className?: string;
	children: React.ReactNode;
}

export class ReactGenericPortal extends React.PureComponent<IReactGenericPortalProps> {
	constructor(props: IReactGenericPortalProps) {
		super(props);

		this.el = document.createElement('div');
		const { portalId } = this.props;

		this.portalContainer = document.getElementById(portalId) as HTMLElement;

		if (!this.portalContainer) {
			// eslint-disable-next-line no-console
			console.warn(`No existe el element con ID "${portalId}". No se ha podido crear el portal`);
		}
	}

	componentDidMount(): void {
		const { className } = this.props;
		if (className) {
			this.el.classList.add(className);
		}

		if (this.portalContainer) {
			this.portalContainer.appendChild(this.el);
		}
	}

	componentWillUnmount(): void {
		if (this.portalContainer) {
			this.portalContainer.removeChild(this.el);
		}
	}

	private el: HTMLElement;

	private portalContainer: HTMLElement;

	render(): React.ReactPortal {
		const { children } = this.props;
		return createPortal(children, this.el);
	}
}
