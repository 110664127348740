import { regularizationSubjectServiceGetAll } from '../../data/services/BookingRegularizationSubjectService';
import type { IRegularizationSubject } from '../../models/entities/RegularizationSubject';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';
import type { IServiceBaseParams } from '../../modules/shared/types/ServiceBaseParams';

/**
 * Retrieves all regularization subjects.
 *
 * @param {IRegularizationSubject[]} payload - The regularization subjects to be retrieved.
 * @param {IServiceBaseParams} params - Base service parameters for the retrieval.
 * @returns {Promise<void>} - A promise that resolves to an array of all regularization subjects.
 */
export const regularizationSubjectGetAll = createAsyncAction<IRegularizationSubject[], IServiceBaseParams>(
	'regularizationSubject/getAll',
	regularizationSubjectServiceGetAll,
);
