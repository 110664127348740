export enum ExtraDriverEndPoints {
	/**
	 * @host `/extradriver/getbybooking/`
	 */
	GET_BY_BOOKING = '/extradriver/getbybooking/',

	/**
	 * @host `/extradriver/getservices/`
	 */
	GET_SERVICES = '/extradriver/getservices/',

	/**
	 * @host `/extradriver/save/`
	 */
	SAVE = '/extradriver/save/',

	/**
	 * @host `/extradriver/remove/`
	 */
	REMOVE = '/extradriver/remove/',

	/**
	 * @host `/extradriver/getservicesbybooking/`
	 */
	GET_SERVICES_BY_BOOKING = '/extradriver/getservicesbybooking/',

	/**
	 * @host `/extradriver/removeservices/`
	 */
	GET_SERVICES_REMOVE_SERVICES = '/extradriver/removeservices/',
	/**
	 * @host `/extradriver/getremoveservicesbybooking/`
	 */
	GET_SERVICES_REMOVE_SERVICES_BY_BOOKING = '/extradriver/getremoveservicesbybooking',
}
