import { postCodeServiceGetByCountryAndText } from '../../data/services/PostCodeService';
import type { IPostCode } from '../../models/entities/PostCode';
import type { IPostCodeGetByCountryAndTextParams } from '../../models/serviceParams/PostCodeParams';
import { createAsyncAction } from '../../modules/shared/state/createAsyncAction';

/**
 * Retrieves postal codes based on a specific country and text criteria.
 *
 * @param {IPostCode[]} payload - The postal codes to be retrieved.
 * @param {IPostCodeGetByCountryAndTextParams} params - Parameters to identify postal codes based on a country and additional text criteria.
 * @returns {Promise<void>} - A promise that resolves to an array of postal codes matching the specified criteria.
 */
export const postCodeGetByCountryAndText = createAsyncAction<IPostCode[], IPostCodeGetByCountryAndTextParams>(
	'postCode/getByCountryAndText',
	postCodeServiceGetByCountryAndText,
);
