export enum VehicleBrandEndPoints {
	/**
	 * Vehicle brand search
	 * @host `/vehicleBrand/search/`
	 */
	SEARCH = '/vehicleBrand/search/',
	/**
	 * Vehicle brand insert
	 * @host `/vehicleBrand/insert/`
	 */
	INSERT = '/vehicleBrand/insert/',
	/**
	 * Vehicle brand modify
	 * @host `/vehicleBrand/modify/`
	 */
	MODIFY = '/vehicleBrand/modify/',
	/**
	 * Vehicle brand delete
	 * @host `/vehicleBrand/delete/`
	 */
	DELETE = '/vehicleBrand/delete/',
}
