import { createSlice } from '@reduxjs/toolkit';

import {
	rentRateFormulaDelete,
	rentRateFormulaInsert,
	rentRateFormulaModify,
	rentRateFormulasReset,
	rentRateFormulasSearch,
} from '@crac/core/redux/actions/RentRateFormulaActions';
import type { IRentRateFormulaReducerState } from '@crac/core/redux-store/reducersState/pricing/RentRateFormulaReducerState';

const initialState: IRentRateFormulaReducerState = {
	rentRateFormula: null,
	rentRateFormulaDeleteRequest: { inProgress: false, messages: [], ok: true },
	rentRateFormulaInsertRequest: { inProgress: false, messages: [], ok: true },
	rentRateFormulaModifyRequest: { inProgress: false, messages: [], ok: true },
	rentRateFormulas: [],
	rentRateFormulaSearchRequest: { inProgress: false, messages: [], ok: true },
};

const rentRateFormulaSlice = createSlice({
	name: 'RentRateFormulaSlice',
	initialState,
	reducers: {},
	extraReducers: (builder) => {
		// RESET
		builder.addCase(rentRateFormulasReset, () => {
			return initialState;
		});
		// SEARCH
		builder
			.addCase(rentRateFormulasSearch.pending, (state) => {
				return {
					...state,
					rentRateFormulaSearchRequest: { inProgress: true, ok: true, messages: [] },
				};
			})
			.addCase(rentRateFormulasSearch.fulfilled, (state, action) => {
				return {
					...state,
					rentRateFormulaSearchRequest: { inProgress: false, ok: true, messages: [] },
					rentRateFormulas: action.payload,
				};
			})
			.addCase(rentRateFormulasSearch.rejected, (state, action) => {
				return {
					...state,
					rentRateFormulaSearchRequest: { inProgress: false, ok: false, messages: action.payload || [] },
				};
			});
		// INSERT
		builder
			.addCase(rentRateFormulaInsert.pending, (state) => {
				return {
					...state,
					rentRateFormulaInsertRequest: { inProgress: true, ok: true, messages: [] },
				};
			})
			.addCase(rentRateFormulaInsert.fulfilled, (state) => {
				return {
					...state,
					rentRateFormulaInsertRequest: { inProgress: false, ok: true, messages: [] },
				};
			})
			.addCase(rentRateFormulaInsert.rejected, (state, action) => {
				return {
					...state,
					rentRateFormulaInsertRequest: { inProgress: false, ok: false, messages: action.payload || [] },
				};
			});
		// MODIFY
		builder
			.addCase(rentRateFormulaModify.pending, (state) => {
				return {
					...state,
					rentRateFormulaModifyRequest: { inProgress: true, ok: true, messages: [] },
				};
			})
			.addCase(rentRateFormulaModify.fulfilled, (state) => {
				return {
					...state,
					rentRateFormulaModifyRequest: { inProgress: false, ok: true, messages: [] },
				};
			})
			.addCase(rentRateFormulaModify.rejected, (state, action) => {
				return {
					...state,
					rentRateFormulaModifyRequest: { inProgress: false, ok: false, messages: action.payload || [] },
				};
			});
		// DELETE
		builder
			.addCase(rentRateFormulaDelete.pending, (state) => {
				return {
					...state,
					rentRateFormulaDeleteRequest: { inProgress: true, ok: true, messages: [] },
				};
			})
			.addCase(rentRateFormulaDelete.fulfilled, (state, action) => {
				return {
					...state,
					rentRateFormulaDeleteRequest: { inProgress: false, ok: true, messages: [] },
					rentRateFormulas: [...state.rentRateFormulas.filter((formula) => formula.id !== action.payload.id)],
				};
			})
			.addCase(rentRateFormulaDelete.rejected, (state, action) => {
				return {
					...state,
					rentRateFormulaDeleteRequest: { inProgress: false, ok: false, messages: action.payload || [] },
				};
			});
	},
});

export const rentRateFormulaReducer = rentRateFormulaSlice.reducer;
