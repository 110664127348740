import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const ChangeBranch = lazyWithRetry(() => import('./pages/ChangeBranch'));
const ChangePassword = lazyWithRetry(() => import('./pages/ChangePassword'));

export const employeeRoutes: { [key: number]: IRouteConfig } = {};

employeeRoutes[routesIdMap.ChangeBranch] = {
	component: ChangeBranch,
	exact: true,
	id: routesIdMap.ChangeBranch,
	name: 'Home',
	path: '/change-branch',
	permissions: [PermissionsType.Access],
	icon: 'home',
};

employeeRoutes[routesIdMap.ChangePassword] = {
	component: ChangePassword,
	exact: true,
	id: routesIdMap.ChangePassword,
	name: 'Home',
	path: '/change-password',
	permissions: [PermissionsType.Access],
	icon: 'key',
};
