import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const DamageRepair = lazyWithRetry(() => import('./pages/DamageRepair'));

export const damageRepairRoutes: { [key: number]: IRouteConfig } = {};

damageRepairRoutes[routesIdMap.VehiclesDetailDamageRepair] = {
	component: DamageRepair,
	exact: true,
	id: routesIdMap.VehiclesDetailDamageRepair,
	name: 'Damage repair',
	path: '/vehicles/detail/:id/damage-repair',
	permissions: [PermissionsType.DamageRepairCost],
	icon: 'wrench',
};
