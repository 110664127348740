/* eslint-disable class-methods-use-this */
import React, { Component } from 'react';

import classNames from 'classnames';

import { asideMenuCssClasses, checkBreakpoint, validBreakpoints } from '@crac/core/helpers/classes';
import { toggleClasses } from '@crac/core/helpers/toggle-classes';

import { AsideStyles } from './styles';

type AsideProps = {
	children?: React.ReactNode;
	className?: string;
	display?: string;
	fixed?: boolean;
	offCanvas?: boolean;
};

export class Aside extends Component<AsideProps> {
	static defaultProps = {
		display: '',
		fixed: false,
		offCanvas: false,
	};

	constructor(props: AsideProps) {
		super(props);
		this.state = {};
	}

	componentDidMount(): void {
		const { display, offCanvas, fixed } = this.props;
		this.isFixed(Boolean(fixed));
		this.isOffCanvas(Boolean(offCanvas));
		this.displayBreakpoint(display);
	}

	isFixed = (fixed: boolean): void => {
		if (fixed) {
			document.body.classList.add('aside-menu-fixed');
		}
	};

	isOffCanvas = (offCanvas: boolean): void => {
		if (offCanvas) {
			document.body.classList.add('aside-menu-off-canvas');
		}
	};

	displayBreakpoint = (display?: string): void => {
		if (display && checkBreakpoint(display, validBreakpoints)) {
			const cssClass = `aside-menu-${display}-show`;
			toggleClasses(cssClass, asideMenuCssClasses, true);
		}
	};

	/*
	 * HandleCloseASide = (): void => {
	 * 	const { display } = this.props;
	 * 	let [cssClass] = asideMenuCssClasses;
	 * 	if (display && checkBreakpoint(cssClass, validBreakpoints)) {
	 * 		cssClass = `aside-menu-${display}-show`;
	 * 	}
	 * 	toggleClasses(cssClass, asideMenuCssClasses, false);
	 * };
	 */

	render(): JSX.Element | null {
		const { children, className } = this.props;
		return (
			// <OutsideClickHandler onOutsideClick={this.handleCloseASide}>
			<AsideStyles className={classNames(className, 'aside-menu')}>{children}</AsideStyles>
			// </OutsideClickHandler>
		);
	}
}
