export enum EmployeeEndPoints {
	/**
	 * Employee change current branch
	 * @host `/employee/changecurrentbranch`
	 */
	CHANGE_BRANCH = '/employee/changecurrentbranch',

	/**
	 * Employee change password
	 * @host `/employee/changepassword/`
	 */
	CHANGE_PASSWORD = '/employee/changepassword/',

	/**
	 * Employee login
	 * @host `/employee/login/`
	 */
	LOGIN = '/employee/login/',

	/**
	 * Employee logout
	 * @host `/employee/logout/`
	 */
	LOGOUT = '/employee/logout/',

	/**
	 * Employee refresh token
	 * @host `/employee/refreshtoken`
	 */
	REFRESH_TOKEN = '/employee/refreshtoken',
}
