import type { IBooking } from '@crac/core/models/entities/Booking';
import { PermissionsType } from '@crac/core/models/types/PermissionsType';
import type { IRouteConfig } from '@crac/core/models/types/RouteConfig';

import { routesIdMap } from '~/config/routesIdMap';
import { bookingDetailLoader } from '~/features/booking/shared/utils/bookingDetailLoader';
import { lazyWithRetry } from '~/features/shared/helpers/LazyLoadRetry';

const Excess = lazyWithRetry(() => import('./pages/Excess'));

export const excessRoutes: { [key: number]: IRouteConfig } = {};

excessRoutes[routesIdMap.BookingsDropOffExcess] = {
	component: Excess,
	exact: true,
	id: routesIdMap.BookingsDropOffExcess,
	name: 'Franchises',
	path: '/booking/drop-off/:id/:plateNumber/franchises',
	permissions: [PermissionsType.BookingDropOffProcess],
	loader: ({ params }): Promise<IBooking | null | undefined> => {
		return bookingDetailLoader(params?.id || '');
	},
	icon: 'sign-out',
};
