export enum ExcessRateEndpoints {
	/**
	 * ExcessRate search
	 * @host `/excessRate/search/`
	 */
	SEARCH = '/excessRate/search/',

	/**
	 * ExcessRate insert
	 * @host `/excessRate/insert/`
	 */
	INSERT = '/excessRate/insert/',

	/**
	 * ExcessRate modify
	 * @host `/excessRate/modify/`
	 */
	MODIFY = '/excessRate/modify/',
	/**
	 * ExcessRate delete
	 * @host `/excessRate/delete/`
	 */
	DELETE = '/excessRate/delete/',
	/**
	 * ExcessRate get all
	 * @host `/excessRate/getAll/`
	 */
	GET_ALL = '/excessRate/getAll/',
}
